import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const boardingResourceDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  color: z.string(),
  description: z.string().optional(),
});

type BoardingResourceDataV0 = z.infer<typeof boardingResourceDataV0Schema>;

const boardingResourceV0Schema = baseRecordSchema.merge(
  boardingResourceDataV0Schema
);

type BoardingResourceV0 = z.infer<typeof boardingResourceV0Schema>;

const normalizeBoardingResourceV0 = (data: any): BoardingResource => {
  try {
    const boardingResourceV0: BoardingResourceV0 =
      boardingResourceV0Schema.parse(data);

    const { ...rest } = boardingResourceV0;

    // here it's same object cause current v is 0
    const boardingResource: BoardingResource = {
      ...rest,
    };
    return boardingResource;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const BOARDING_RESOURCE_VERSION = "0";

export type BoardingResourceData = BoardingResourceDataV0;

export type BoardingResource = BaseRecord & BoardingResourceData;

export const boardingResourceDataSchema = boardingResourceDataV0Schema;

export const boardingResourceSchema = boardingResourceV0Schema;

export const normalizeBoardingResource = (data: any): BoardingResource => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeBoardingResourceV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
