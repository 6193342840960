import { z } from "zod";

// t("PENDING")
// t("PROCESSING")
// t("RETRY")
// t("SUCCESS")
// t("ERROR")

export const paymentStatusEnum = z.enum([
  "PENDING",
  "PROCESSING",
  "RETRY",
  "SUCCESS",
  "ERROR",
]);

export type PaymentStatus = z.infer<typeof paymentStatusEnum>;
