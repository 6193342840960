import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Inventory } from "features/admin";
import { Navigate, Route } from "react-router-dom";
import { ItemsRoutes } from "./Items/ItemsRoutes";
import { PurchaseOrdersRoutes } from "./PurchaseOrders/PurchaseOrdersRoutes";
import { TransactionsRoutes } from "./Transactions/TransactionsRoutes";

export const InventoryRoutes = (
  <Route
    path="inventory"
    element={
      <ProtectedRouteWrapper
        conditions={["do", OrgPermissions.INVENTORY_ACCESS]}
        element={<Inventory />}
      />
    }
  >
    <Route index element={<Navigate to="inventory-items" replace />} />
    {/* <Route path="dashboard" element={<Dashboard />} /> */}
    {ItemsRoutes}
    {TransactionsRoutes}
    {PurchaseOrdersRoutes}
    {/* <Route path="orders" element={<Orders />} /> */}
  </Route>
);
