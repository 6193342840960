import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface CancelButtonProps {
  f?: Function;
}

export const CancelButton = ({ f }: CancelButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button variant="outline" onClick={() => (f ? f : navigate(-1))}>
      {t("CANCEL")}
    </Button>
  );
};
