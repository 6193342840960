import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Products } from "features/admin/catalog/components";
import {
  Categories,
  Products as ChildProducts,
} from "features/admin/catalog/components/Products/components";
import {
  AddCategory,
  EditCategory,
} from "features/admin/catalog/components/Products/components/Categories/components";
import {
  AddProduct,
  EditProduct,
} from "features/admin/catalog/components/Products/components/Products/components";

import { Navigate, Outlet, Route } from "react-router-dom";

export const ProductsRoutes = (
  <Route path="products" element={<Products />}>
    <Route index element={<Navigate to="products" replace />} />
    <Route path="products" element={<Outlet />}>
      <Route index element={<ChildProducts />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PRODUCTS_CUD]}
            element={<AddProduct />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PRODUCTS_CUD]}
            element={<EditProduct />}
          />
        }
      />
    </Route>
    <Route path="categories" element={<Outlet />}>
      <Route index element={<Categories />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PRODUCTS_CUD]}
            element={<AddCategory />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PRODUCTS_CUD]}
            element={<EditCategory />}
          />
        }
      />
    </Route>
  </Route>
);
