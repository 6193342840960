import { z } from "zod";
import { UnableToNormalizeData } from "../../../types-schemas/errors";

export const patientWeightSchema = z.object({
  v: z.number().nonnegative(), // value
  id: z.string(),
  a: z.string(), // author
  d: z.string().optional(), // description
  t: z.number().nonnegative().int(), // time
});

export type PatientWeight = z.infer<typeof patientWeightSchema>;

export const normalizepatientWeight = (data: any): PatientWeight => {
  try {
    return patientWeightSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
