const randomString = (length: number) => {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

const randomStringNoDigits = (length: number) => {
  const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

const randomStringUppercaseNo0 = (length: number) => {
  const chars = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

const newId6 = () => randomString(6);
const newId20 = () => randomString(20);
const newId36 = () => randomString(36);
const newIdNoDigits20 = () => randomStringNoDigits(20);
const newIdUpperNo016 = () => randomStringUppercaseNo0(16);

export const id_util = {
  newId20,
  newIdNoDigits20,
  newIdUpperNo016,
  newId36,
  newId6,
  randomString,
  randomStringUppercaseNo0,
};
