import {
  AppointmentType,
  AppointmentTypeData,
  APPOINTMENT_TYPE_VERSION,
  errorResult,
  normalizeAppointmentType,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization appointmentType

interface AddAppointmentType {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: AppointmentTypeData;
  }): Promise<Result<AppointmentType | null>>;
}

const addAppointmentType: AddAppointmentType = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new appointmentType id
    const newAppointmentTypeRef = doc(
      collection(db, "organizations", organizationId, "appointment_types")
    );

    const newAppointmentType: AppointmentType = normalizeAppointmentType({
      ...data,
      id: newAppointmentTypeRef.id,
      authorId,
      authorName,
      version: APPOINTMENT_TYPE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newAppointmentTypeRef, newAppointmentType);

    // t("APPOINTMENT_TYPE_CREATED")
    const successMessage = "APPOINTMENT_TYPE_CREATED";
    return successResult({
      message: successMessage,
      payload: newAppointmentType,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetAppointmentType {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<AppointmentType | null>>;
}

const getAppointmentType: GetAppointmentType = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const appointmentTypeDocRef = doc(
      db,
      "organizations",
      organizationId,
      "appointment_types",
      id
    );
    const appointmentTypeDocSnapshot = await getDoc(appointmentTypeDocRef);
    if (appointmentTypeDocSnapshot.exists()) {
      try {
        const data: unknown = appointmentTypeDocSnapshot.data();
        const appointmentType: AppointmentType = normalizeAppointmentType(data);
        // t("APPOINTMENT_TYPE_FOUND")
        const successMessage = "APPOINTMENT_TYPE_FOUND";
        return successResult({
          message: successMessage,
          payload: appointmentType,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("APPOINTMENT_TYPE_NOT_FOUND")
      const errorMessage = "APPOINTMENT_TYPE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditAppointmentType {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: AppointmentTypeData;
  }): Promise<Result<AppointmentType | null>>;
}

const editAppointmentType: EditAppointmentType = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const appointmentType = (
      await getAppointmentType({ db, organizationId, id })
    ).payload;

    if (!appointmentType) {
      // t("APPOINTMENT_TYPE_NOT_FOUND")
      const errorMessage = "APPOINTMENT_TYPE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = appointmentType;
      const updatedAppointmentType: AppointmentType = normalizeAppointmentType({
        ...data,
        id,
        authorId,
        authorName,
        version: APPOINTMENT_TYPE_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      const appointmentTypeRef = doc(
        db,
        "organizations",
        organizationId,
        "appointment_types",
        id
      );

      await setDoc(appointmentTypeRef, updatedAppointmentType);

      // t("APPOINTMENT_TYPE_UPDATED")
      const successMessage = "APPOINTMENT_TYPE_UPDATED";
      // console.log(successMessage, updatedAppointmentType);
      return successResult({
        message: successMessage,
        payload: updatedAppointmentType,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteAppointmentType {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteAppointmentType: DeleteAppointmentType = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "appointment_types",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("APPOINTMENT_TYPE_DELETED")
      const successMessage = "APPOINTMENT_TYPE_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveAppointmentType: EditAppointmentType = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: AppointmentTypeData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editAppointmentType({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("APPOINTMENT_TYPE_ARCHIVED")
    const successMessage = "APPOINTMENT_TYPE_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveAppointmentType: EditAppointmentType = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: AppointmentTypeData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editAppointmentType({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("APPOINTMENT_TYPE_DEARCHIVED")
    const successMessage = "APPOINTMENT_TYPE_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization appointmentTypes listener
interface GetAppointmentTypesListenerCallback {
  (appointmentTypes: AppointmentType[]): void;
}
interface GetAppointmentTypesListener {
  db: Firestore;
  organizationId: string;
  callback: GetAppointmentTypesListenerCallback;
}

const getAppointmentTypesListener = ({
  db,
  organizationId,
  callback,
}: GetAppointmentTypesListener): Unsubscribe => {
  try {
    // console.log("getAppointmentTypesListener: new listener");
    const appointmentTypesQuery = query(
      collection(db, "organizations", organizationId, "appointment_types"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(appointmentTypesQuery, (querySnapshot) => {
      const appointmentTypes: AppointmentType[] = [];
      querySnapshot.forEach((doc) => {
        try {
          appointmentTypes.push(normalizeAppointmentType(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(appointmentTypes);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const appointmentTypes = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getAppointmentTypesListener: (
      callback: GetAppointmentTypesListenerCallback
    ) =>
      getAppointmentTypesListener({
        db,
        organizationId,
        callback,
      }),
    getAppointmentType: (id: string) =>
      getAppointmentType({
        db,
        organizationId,
        id,
      }),
    addAppointmentType: (data: AppointmentTypeData) =>
      addAppointmentType({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editAppointmentType: (id: string, data: AppointmentTypeData) =>
      editAppointmentType({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteAppointmentType: (id: string) =>
      deleteAppointmentType({
        db,
        organizationId,
        id,
      }),
    archiveAppointmentType: (id: string, data: AppointmentTypeData) =>
      archiveAppointmentType({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveAppointmentType: (id: string, data: AppointmentTypeData) =>
      dearchiveAppointmentType({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
