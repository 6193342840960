import { Card, Stack, Tabs, Text } from "@mantine/core";
import { Patient } from "beitary-shared";
import { useTranslation } from "react-i18next";
import { Appointments, Files, History, Tasks } from "./components";
import { Reminders } from "./components/Reminders";

export interface PatientTabsProps {
  patient: Patient;
}

export const PatientTabs = ({ patient }: PatientTabsProps) => {
  const { t } = useTranslation();

  // keep mounted false to prevent prematurate data fetching
  // and refresh tab on select
  return (
    <Tabs defaultValue="history" keepMounted={false}>
      <Stack>
        <Card withBorder radius="xs" p={0}>
          <Tabs.List>
            <Tabs.Tab value="history">
              <Text p="xs">{t("HISTORY")}</Text>
            </Tabs.Tab>
            <Tabs.Tab value="appointments">
              <Text p="xs">{t("APPOINTMENTS")}</Text>
            </Tabs.Tab>
            <Tabs.Tab value="reminders">
              <Text p="xs">{t("REMINDERS")}</Text>
            </Tabs.Tab>
            <Tabs.Tab value="tasks">
              <Text p="xs">{t("TASKS")}</Text>
            </Tabs.Tab>
            <Tabs.Tab value="files">
              <Text p="xs">{t("FILES")}</Text>
            </Tabs.Tab>
          </Tabs.List>
        </Card>

        <Tabs.Panel value="history">
          <History patientId={patient.id} clientId={patient.ownerId} />
        </Tabs.Panel>
        {/* <Tabs.Panel value="tasks">TODO add tasks</Tabs.Panel> */}
        <Tabs.Panel value="appointments">
          <Appointments
            patientId={patient.id}
            clientId={patient.ownerId}
            clientName={patient.ownerName}
            patientName={patient.name}
            patientPictureURL={patient.thumbURL}
            patientSex={patient.sex}
            patientSpecies={patient.species}
          />
        </Tabs.Panel>
        <Tabs.Panel value="reminders">
          <Reminders
            clientId={patient.ownerId}
            clientName={patient.ownerName}
            patientId={patient.id}
            patientName={patient.name}
          />
        </Tabs.Panel>
        <Tabs.Panel value="tasks">
          <Tasks
            clientId={patient.ownerId}
            clientName={patient.ownerName}
            patientId={patient.id}
            patientName={patient.name}
          />
        </Tabs.Panel>
        <Tabs.Panel value="files">
          <Files patientId={patient.id} clientId={patient.ownerId} />
        </Tabs.Panel>
      </Stack>
    </Tabs>
  );
};
