import { zodResolver } from "@mantine/form";
import { emailAppointmentNotificationTemplateDataSchema } from "beitary-shared";
import { z } from "zod";

const emailAppointmentNotificationTemplateFormSchema =
  emailAppointmentNotificationTemplateDataSchema;

export type EmailAppointmentNotificationTemplateFormValues = z.infer<
  typeof emailAppointmentNotificationTemplateFormSchema
>;

export const rules = zodResolver(
  emailAppointmentNotificationTemplateFormSchema
);
