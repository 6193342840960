import { z } from "zod";

// t("INVENTORY_COUNT")
// t("INVENTORY_SURPLUS")
// t("INVENTORY_DEFICIT")
// t("RETURN_CUSTOMER")
// t("RETURN_SUPPLIER")
// t("PRODUCT_ADMINISTRED")
// t("PRODUCT_DEADMINISTRED")
// t("PURCHASE")
// t("PURCHASE_CANCELED")
// t("INVOICE")
// t("ADJUSTMENT_IN")
// t("ADJUSTMENT_OUT")
// t("DAMAGED")
// t("EXPIRED")
// t("STOLEN")

export const inventoryTransactionTypeEnum = z.enum([
  "INVENTORY_COUNT", // + TODO remove? idk i think this is for initial count
  "INVENTORY_SURPLUS", // + this means we did inventory and found x=20 where it's 15 in system, so surplus +5
  "INVENTORY_DEFICIT", // -this means we did inventory and found x=20 where it's 25 in system, so deficit -5
  "RETURN_CUSTOMER", // +
  "RETURN_SUPPLIER", // -
  "PRODUCT_ADMINISTRED", // -
  "PRODUCT_DEADMINISTRED", // +
  "PURCHASE", // +
  "PURCHASE_CANCELED", // -
  "INVOICE", // -
  "ADJUSTMENT_IN", // +
  "ADJUSTMENT_OUT", // -
  "DAMAGED", // -
  "EXPIRED", // -
  "STOLEN", // -
]);
export type InventoryTransactionType = z.infer<
  typeof inventoryTransactionTypeEnum
>;
