import {
  errorResult,
  id_util,
  normalizePatient,
  Patient,
  PatientData,
  PATIENT_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import { PatientsMap } from "features/Clients/Clients.slice";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

// add organization patient

interface AddPatient {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: PatientData;
  }): Promise<Result<string | null>>;
}

const addPatient: AddPatient = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    const newPatientId = id_util.newIdUpperNo016();
    const newPatientRef = doc(
      db,
      "organizations",
      organizationId,
      "patients",
      newPatientId
    );

    const docPath = newPatientRef.path;

    const newPatient: Patient = normalizePatient({
      ...data,
      id: newPatientRef.id,
      authorId,
      authorName,
      version: PATIENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newPatientRef, newPatient);
    // t("PATIENT_CREATED")
    const successMessage = "PATIENT_CREATED";
    return successResult({
      message: successMessage,
      payload: docPath,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetPatient {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Patient | null>>;
}

const getPatient: GetPatient = async ({ db, organizationId, id }) => {
  try {
    const patientDocRef = doc(
      db,
      "organizations",
      organizationId,
      "patients",
      id
    );
    const patientDocSnapshot = await getDoc(patientDocRef);
    if (patientDocSnapshot.exists()) {
      try {
        const data: unknown = patientDocSnapshot.data();
        const patient: Patient = normalizePatient(data);
        const successMessage = "Patient found!";
        return successResult({
          message: successMessage,
          payload: patient,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "Patient not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// interface GetPatients {
//   ({
//     db,
//     organizationId,
//     ownerId,
//     nameStartsWith,
//   }: {
//     db: Firestore;
//     organizationId: string;
//     ownerId?: string;
//     nameStartsWith?: string;
//   }): Promise<Result<Patient[] | null>>;
// }

// const getPatients: GetPatients = async ({
//   db,
//   organizationId,
//   ownerId,
//   nameStartsWith,
// }) => {
//   try {
//     let patientsQuery = query(
//       collection(db, "organizations", organizationId, "patients")
//     );
//     if (ownerId) {
//       patientsQuery = query(patientsQuery, where("ownerId", "==", ownerId));
//     }
//     if (nameStartsWith) {
//       patientsQuery = query(patientsQuery, where("name", ">=", nameStartsWith));
//     }

//     patientsQuery = query(patientsQuery, orderBy("name"), limit(5));

//     const querySnapshot = await getDocs(patientsQuery);

//     const patients: Patient[] = [];
//     querySnapshot.forEach((doc) => {
//       patients.push(normalizePatient(doc.data()));
//     });
//     const successMessage = "Patients found!";
//     return successResult({
//       message: successMessage,
//       payload: patients,
//     });
//   } catch (err: any) {
//     console.log(err);
//     return errorResult({ message: err.message });
//   }
// };

// interface EditPatient {
//   ({
//     db,
//     organizationId,
//     authorId,
//     authorName,
//     source,
//     data,
//   }: {
//     db: Firestore;
//     organizationId: string;
//     authorId: string;
//     authorName: string;
//     source: Source;
//     data: Patient;
//   }): Promise<Result<boolean | null>>;
// }

// const editPatient: EditPatient = async ({
//   db,
//   organizationId,
//   authorId,
//   authorName,
//   source,
//   data,
// }) => {
//   try {
//     const updatedPatient: Patient = normalizePatient({
//       ...data,
//       lastUpdatedAt: tu.getCurrentDateTime(),
//     });

//     const patientRef = doc(
//       db,
//       "organizations",
//       organizationId,
//       "patients",
//       data.id
//     );

//     await setDoc(patientRef, updatedPatient);

//     const successMessage = "Patient updated!";
//     // console.log(successMessage, updatedPatient);
//     return successResult({
//       message: successMessage,
//       payload: true,
//     });
//   } catch (err: any) {
//     console.log(err.message);
//     return errorResult({ message: err.message });
//   }
// };

interface UpdatePatient {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<PatientData>;
  }): Promise<Result<string | null>>;
}

const updatePatient: UpdatePatient = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "patients", id);

    const docPath = docRef.path;

    const updates: Partial<Patient> = {
      ...data,
      authorId,
      authorName,
      version: PATIENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("PATIENT_UPDATED")
    const successMessage = "PATIENT_UPDATED";

    return successResult({
      message: successMessage,
      payload: docPath,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization patients listener
interface GetPatientsListenerCallback {
  (patients: PatientsMap): void;
}
interface GetPatientsListener {
  db: Firestore;
  organizationId: string;
  callback: GetPatientsListenerCallback;
}

const getPatientsListener = ({
  db,
  organizationId,
  callback,
}: GetPatientsListener): Unsubscribe => {
  try {
    // console.log("getPatientsListener: new listener");
    const patientsQuery = query(
      collection(db, "organizations", organizationId, "patients")
    );
    return onSnapshot(patientsQuery, (querySnapshot) => {
      const patientsMap: PatientsMap = {};
      querySnapshot.forEach((doc) => {
        try {
          const patient = normalizePatient(doc.data());
          patientsMap[patient.id] = patient;
        } catch (error) {
          console.log(error);
        }
      });
      callback(patientsMap);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface DeletePatient {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deletePatient: DeletePatient = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "patients", id);

    try {
      await deleteDoc(docRef);
      // t("PATIENT_DELETED")
      const successMessage = "PATIENT_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const patients = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getPatientsListener: (callback: GetPatientsListenerCallback) =>
      getPatientsListener({
        db,
        organizationId,
        callback,
      }),
    getPatient: (id: string) =>
      getPatient({
        db,
        organizationId,
        id,
      }),
    addPatient: (data: PatientData) =>
      addPatient({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),

    updatePatient: (id: string, data: Partial<PatientData>) =>
      updatePatient({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deletePatient: (id: string) => deletePatient({ db, id, organizationId }),
    archivePatient: (id: string) =>
      updatePatient({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchivePatient: (id: string) =>
      updatePatient({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),

    // editPatient: (data: Patient) =>
    //   editPatient({
    //     db,
    //     organizationId,
    //     authorId,
    //     authorName,
    //     source,
    //     data,
    //   }),
  };
};
