import { zodResolver } from "@mantine/form";
import { treatmentSchema } from "beitary-shared";
import { z } from "zod";

const adminBundleTxFormSchema = treatmentSchema.merge(
  z.object({
    products: z.array(treatmentSchema),
  })
);

export type AdminBundleTxFormValues = z.infer<typeof adminBundleTxFormSchema>;

export const rules = zodResolver(adminBundleTxFormSchema);
