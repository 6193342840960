import { Avatar, Group, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveClients } from "features/Clients/Clients.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ClientSelectProps {
  required?: boolean;
  disabled?: boolean;
  onChange: ({
    id,
    name,
    smsNotif,
    emailNotif,
  }: {
    id: string;
    name: string;
    smsNotif?: boolean;
    emailNotif?: boolean;
  }) => void;
  //onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const ClientSelect = (props: ClientSelectProps) => {
  const { t } = useTranslation();
  const clients = useAppSelector(selectActiveClients);

  const onSelectChange = (value?: string) => {
    if (value) {
      const client = clients.find((client) => client.id === value);
      if (client) {
        props.onChange({
          id: value,
          name: client.name,
          emailNotif: client.emailNotif,
          smsNotif: client.smsNotif,
        });
      }
    }
  };

  const data = clients.map((client) => ({
    value: client.id,
    label: client.name,
    thumbURL: client.thumbURL,
    description: client.email,
  }));

  return (
    <BSelect
      {...props}
      onChange={onSelectChange}
      itemComponent={SelectItem}
      label={t("CLIENT")}
      nothingFound="NOTHING_FOUND"
      data={data}
      limit={20}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  thumbURL: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ thumbURL, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={thumbURL} radius="xl" />

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);
