import {
  ActionIcon,
  Box,
  Button,
  Group,
  Menu,
  Modal,
  Paper,
  Radio,
  Stack,
  Text,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  IconCircleX,
  IconDotsVertical,
  IconExternalLink,
  IconEye,
  IconTarget,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  ImagingDiagnosticItem,
  loadingInfoNotification,
  tu,
} from "beitary-shared";
import { BSimpleDICOMViewer } from "components";
import { ClientSelect, PatientSelect } from "features/Clients/components";
import { selectActiveConsultations } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface ImagingDiagnosticItemMenuProps {
  item: ImagingDiagnosticItem;
}

export const ImagingDiagnosticItemMenu = ({
  item,
}: ImagingDiagnosticItemMenuProps) => {
  const { t } = useTranslation();
  const db = useDBServices();
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  const [fileOpened, setFileOpened] = useState(false);
  const [filename, setFilename] = useState<string | undefined>(undefined);

  // checkedin time modal
  const [openedConsultationMapping, setOpenedConsultationMapping] =
    useState(false);
  const [mappedConsultationId, setMappedConsultationId] = useState<
    string | undefined
  >(undefined);

  const [itemData, setItemData] = useState<{
    clientId?: string;
    clientName?: string;
    patientId?: string;
    patientName?: string;
  }>({});

  const [submitState, setSubmitState] = useSubmitState();
  const [submitCancelState, setSubmitCancelState] = useSubmitState();

  const consultations = useAppSelector(selectActiveConsultations).filter(
    (c) => c.patientId === itemData.patientId
  );

  const submitMappedConsultationId = async () => {
    const { clientId, clientName, patientId, patientName } = itemData;
    const bfileId = item.bfileId;
    if (
      !mappedConsultationId ||
      !clientId ||
      !clientName ||
      !patientId ||
      !patientName ||
      !bfileId
    )
      return;
    const notificationId = `update-${item.id}`;
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("WAITING_FOR_SERVER_RESPONSE"),
        title: t("UPDATE_DIAGNOSTIC_ITEM"),
      })
    );
    setSubmitState("pending-response");
    const result =
      await db.imagingDiagnosticItemsDBService.updateMappedConsultation({
        consultationId: mappedConsultationId,
        id: item.id,
        clientId,
        clientName,
        patientId,
        patientName,
      });
    if (result.type === "success") {
      const result2 = await db.filesDBService.updateBFile(
        {
          consultationId: mappedConsultationId,
          clientId,
          patientId,
        },
        bfileId
      );
      setSubmitState(result2.type);

      updateNotification({
        ...getNotificationByResultType(result2.type)({
          message: t(result.message),
        }),
        id: notificationId,
      });
      if (result2.type === "success") setOpenedConsultationMapping(false);
    } else {
      setSubmitState(result.type);

      updateNotification({
        ...getNotificationByResultType(result.type)({
          message: t(result.message),
        }),
        id: notificationId,
      });
    }
  };

  const cancelItem = async (id: string) => {
    const notificationId = `cancel-${item.id}`;
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("WAITING_FOR_SERVER_RESPONSE"),
        title: t("UPDATE_DIAGNOSTIC_ITEM"),
      })
    );
    setSubmitCancelState("pending-response");
    const result =
      await db.imagingDiagnosticItemsDBService.cancelImagingDiagnosticItem({
        id: item.id,
      });
    setSubmitState(result.type);

    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const confirm = () => {
    submitMappedConsultationId();
    setOpened(false);
  };

  const closeAndReset = () => {
    setOpenedConsultationMapping(false);
    setItemData({});
    setMappedConsultationId(undefined);
  };

  // made this instead of component cauz kept closing whenever clicking outside menu
  const ConsultationMapping = (
    <Modal
      title={t("MAP_ORPHANED_IMAGING_INTEGRATION_RESULT")}
      size="xl"
      opened={openedConsultationMapping}
      centered
      onClose={closeAndReset}
      closeOnClickOutside={false}
      overlayProps={{
        color: "white",
        opacity: 0.55,
        blur: 3,
      }}
    >
      <Box p="xl">
        <Group grow>
          <ClientSelect
            required
            onChange={({ id, name }) => {
              setItemData({
                clientId: id,
                clientName: name,
                patientId: undefined,
                patientName: undefined,
              });
            }}
          />

          <PatientSelect
            required
            ownerId={itemData.clientId}
            onChange={({ id, name }) => {
              const updatedData = {
                ...itemData,
                patientId: id,
                patientName: name,
              };
              setItemData(updatedData);
            }}
          />
        </Group>
        {consultations.length > 0 && (
          <Radio.Group
            w="100%"
            my="xl"
            value={mappedConsultationId}
            onChange={setMappedConsultationId}
            name="mapped-consultations"
            label={t("CONSULTATIONS")}
            withAsterisk
          >
            <Group mt="xs">
              {consultations.map((c) => (
                <Radio
                  value={c.id}
                  label={
                    <Paper p="xl" withBorder miw={200}>
                      <Stack>
                        <Text>
                          {t("TITLE")}: {c.title ?? ""}
                        </Text>
                        <Text>
                          {t("CHECK_IN")}:{" "}
                          {tu.getDateAndTimeString(c.checkedInTime)}
                        </Text>
                      </Stack>
                    </Paper>
                  }
                />
              ))}
            </Group>
          </Radio.Group>
        )}

        <Group mt="xl" position="right">
          <Button
            onClick={confirm}
            loading={submitState === "pending-response"}
            disabled={!mappedConsultationId}
          >
            {t("CONFIRM")}
          </Button>
          <Button
            variant="outline"
            onClick={() => setOpenedConsultationMapping(false)}
          >
            {t("CANCEL")}
          </Button>
        </Group>
      </Box>
    </Modal>
  );

  const getMenuItems = () => {
    const menuItems: ReactNode[] = [];

    menuItems.push(
      <Menu.Item
        icon={<IconExternalLink size={14} />}
        disabled={!item.consultationId}
        onClick={() => navigate(`/consultations/${item.consultationId}`)}
      >
        {t("VIEW_CONSULTATION")}
      </Menu.Item>
    );

    menuItems.push(
      <Menu.Item
        icon={<IconEye size={14} />}
        disabled={!item.bfileId}
        onClick={() => setFileOpened(true)}
      >
        {t("VIEW_RESULTS")}
      </Menu.Item>
    );

    menuItems.push(
      <Menu.Item
        icon={<IconTarget size={14} />}
        onClick={() => setOpenedConsultationMapping(true)}
        disabled={
          item.status !== "ORPHANED" && item.status !== "ORPHANED_MAPPED"
        }
      >
        {t("MAP_TO_CONSULTATION")}
      </Menu.Item>
    );

    menuItems.push(
      <Menu.Item
        icon={<IconCircleX size={14} />}
        onClick={() => cancelItem(item.id)}
        disabled={
          submitCancelState === "pending-response" || // TODO prolly make loaging icon/button?
          (item.status !== "PREPARED" && item.status !== "PENDING")
        }
      >
        {t("CANCEL")}
      </Menu.Item>
    );

    return menuItems;
  };

  return (
    <div>
      <Modal.Root
        size="lg"
        opened={fileOpened}
        onClose={() => setFileOpened(false)}
        closeOnClickOutside={false}
        fullScreen
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{filename ?? t("FILE")}</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body w={"100%"} h={"100%"}>
            <BSimpleDICOMViewer
              fileId={item.bfileId ?? ""}
              setFilename={(n) => {
                setFilename(n);
              }}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Menu
        withinPortal
        position="bottom-end"
        opened={opened}
        onChange={setOpened}
      >
        <Menu.Target>
          <ActionIcon
          // color={theme.primaryColor}
          >
            <IconDotsVertical size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>{getMenuItems()}</Menu.Dropdown>
      </Menu>
      {ConsultationMapping}
    </div>
  );
};
