import {
  Divider,
  Group,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  MemberRole,
  OrgPermission,
  orgPermission,
  OrgPermissions,
  PermissionStatus,
  Result,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { MemberRoleFormValues, rules } from "./MemberRoleForm.rules";

export interface MemberRoleFormProps {
  memberRole?: MemberRole;
}

// TODO include remaining features and update
export const MemberRoleForm = ({ memberRole }: MemberRoleFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editMemberRole, addMemberRole } =
    useDBServices().organizationMembersDBService.memberRoles;

  const form = useForm<MemberRoleFormValues>({
    initialValues: memberRole ? { ...memberRole } : undefined,
    validate: rules,
  });

  const permsEnum = orgPermission.enum;
  const adminPermissions: {
    value: string;
    label: string;
    description?: string;
  }[] = [
    // {
    //   label: t("ANNOUNCEMENTS_CUD_PERMISSION"),
    //   value: permsEnum.ANNOUNCEMENTS_CUD,
    //   description: t("ANNOUNCEMENTS_CUD_PERMISSION_DESCRIPTION"),
    // },
    {
      label: t("MEMBERS_CUD_PERMISSION"),
      value: permsEnum.MEMBERS_CUD,
      description: t("MEMBERS_CUD_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("CLINIC_SETTINGS_U_PERMISSION"),
      value: permsEnum.CLINIC_SETTINGS_CUD,
      description: t("CLINIC_SETTINGS_U_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("DIAGNOSES_U_PERMISSION"),
      value: permsEnum.DIAGNOSES_CUD,
      description: t("DIAGNOSES_U_PERMISSION_DESCRIPTION"),
    },
    // {
    //   label: t("FORMS_CU_PERMISSION"),
    //   value: permsEnum.FORMS_CU,
    //   description: t("FORMS_CU_PERMISSION_DESCRIPTION"),
    // },
    // {
    //   label: t("INTEGRATIONS_ACCESS_PERMISSION"),
    //   value: permsEnum.INTEGRATIONS_ACCESS,
    //   description: t("INTEGRATIONS_ACCESS_PERMISSION_DESCRIPTION"),
    // },
    {
      label: t("INVENTORY_ACCESS_PERMISSION"),
      value: permsEnum.INVENTORY_ACCESS,
      description: t("INVENTORY_ACCESS_PERMISSION_DESCRIPTION"),
    },
    // {
    //   label: t("MESSAGING_ACCESS_PERMISSION"),
    //   value: permsEnum.MESSAGING_ACCESS,
    //   description: t("MESSAGING_ACCESS_PERMISSION_DESCRIPTION"),
    // },
    {
      label: t("PHYSICAL_EXAM_TEMPLATES_CUD_PERMISSION"),
      value: permsEnum.PHYSICAL_EXAM_TEMPLATES_CUD,
      description: t("PHYSICAL_EXAM_TEMPLATES_CUD_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("PRODUCTS_CUD_PERMISSION"),
      value: permsEnum.PRODUCTS_CUD,
      description: t("PRODUCTS_CUD_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("CLIENT_COMMUNICATION_PERMISSION"),
      value: permsEnum.CLIENT_COMMUNICATION,
      description: t("CLIENT_COMMUNICATION_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("ROLES_CUD_PERMISSION"),
      value: permsEnum.ROLES_CUD,
      description: t("ROLES_CUD_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("SCHEDULE_CUD_PERMISSION"),
      value: permsEnum.SCHEDULE_CUD,
      description: t("SCHEDULE_CUD_PERMISSION_DESCRIPTION"),
    },
  ];

  const invoicingPermissions: {
    value: string;
    label: string;
    description?: string;
  }[] = [
    {
      label: t("INVOICE_DELETE_PERMISSION"),
      value: permsEnum.INVOICE_DELETE,
      description: t("INVOICE_DELETE_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("ITEM_PRICE_UPDATE_DISCOUNTS_APPLY_TO_INVOICE_PERMISSION"),
      value: permsEnum.ITEM_PRICE_UPDATE_DISCOUNTS_APPLY_TO_INVOICE,
      description: t(
        "ITEM_PRICE_UPDATE_DISCOUNTS_APPLY_TO_INVOICE_PERMISSION_DESCRIPTION"
      ),
    },
    // {
    //   label: t("INVOICES_UNLICK_REFUNC_RETURN_PERMISSION"),
    //   value: permsEnum.INVOICES_UNLICK_REFUNC_RETURN,
    //   description: t("INVOICES_UNLICK_REFUNC_RETURN_PERMISSION_DESCRIPTION"),
    // },
    // {
    //   label: t("PAYMENTS_UD_VOID_PERMISSION"),
    //   value: permsEnum.PAYMENTS_UD_VOID,
    //   description: t("PAYMENTS_UD_VOID_PERMISSION_DESCRIPTION"),
    // },
  ];

  const recordsPermissions: {
    value: string;
    label: string;
    description?: string;
  }[] = [
    {
      label: t("CONSULTATION_DELETE_UPDATE_DATE_PERMISSION"),
      value: permsEnum.CONSULTATION_DELETE_UPDATE_DATE,
      description: t("CONSULTATION_DELETE_UPDATE_DATE_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("CHRONIC_CONDITION_D_UPDATE_NOTES_PERMISSION"),
      value: permsEnum.CHRONIC_CONDITION_D_UPDATE_NOTES,
      description: t("CHRONIC_CONDITION_D_UPDATE_NOTES_PERMISSION_DESCRIPTION"),
    },
    // {
    //   label: t("NOTES_D_PERMISSION"),
    //   value: permsEnum.NOTES_D,
    //   description: t("NOTES_D_PERMISSION_DESCRIPTION"),
    // },
    {
      label: t("PATIENTS_CLIENTS_CUD"),
      value: permsEnum.PATIENTS_CLIENTS_CUD,
      description: t("PATIENTS_CLIENTS_CUD_PERMISSION_DESCRIPTION"),
    },
    // {
    //   label: t("PRESCRIPTIONS_REFILLS_CUD_PERMISSION"),
    //   value: permsEnum.PRESCRIPTIONS_REFILLS_CUD,
    //   description: t("PRESCRIPTIONS_REFILLS_CUD_PERMISSION_DESCRIPTION"),
    // },
    {
      label: t("CONSULTATIONS_UNLOCK_PERMISSION"),
      value: permsEnum.CONSULTATIONS_UNLOCK,
      description: t("CONSULTATIONS_UNLOCK_PERMISSION_DESCRIPTION"),
    },
    // {
    //   label: t("VACCINATIONS_UPDATE_DATE_PERMISSION"),
    //   value: permsEnum.VACCINATIONS_UPDATE_DATE,
    //   description: t("VACCINATIONS_UPDATE_DATE_PERMISSION_DESCRIPTION"),
    // },
  ];

  const reportsPermissions: {
    value: string;
    label: string;
    description?: string;
  }[] = [
    {
      label: t("FINANCIAL_ACCESS_PERMISSION"),
      value: permsEnum.FINANCIAL_ACCESS,
      description: t("FINANCIAL_ACCESS_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("MEDICATION_ACCESS_PERMISSION"),
      value: permsEnum.MEDICATION_ACCESS,
      description: t("MEDICATION_ACCESS_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("CLIENTS_PATIENTS_ACCESS_PERMISSION"),
      value: permsEnum.CLIENTS_PATIENTS_ACCESS,
      description: t("CLIENTS_PATIENTS_ACCESS_PERMISSION_DESCRIPTION"),
    },
    {
      label: t("PRACTICE_MANAGEMENT_ACCESS_PERMISSION"),
      value: permsEnum.PRACTICE_MANAGEMENT_ACCESS,
      description: t("PRACTICE_MANAGEMENT_ACCESS_PERMISSION_DESCRIPTION"),
    },
  ];

  const submit = async (values: MemberRoleFormValues) => {
    const notificationId = "submit-inventory-item-memberRole";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(memberRole ? "UPDATE_MEMBER_ROLE" : "ADD_MEMBER_ROLE"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (memberRole) {
      result = await editMemberRole(memberRole.id, values);
    } else {
      result = await addMemberRole(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            memberRole ? (
              <Text weight={500}>{t("EDIT_MEMBER_ROLE")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_MEMBER_ROLE")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              minLength={1}
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />{" "}
            <Textarea
              label={t("DESCRIPTION")}
              {...form.getInputProps("description")}
            />
            <Switch.Group
              value={Object.keys(form.values.permissions ?? {}).filter(
                (v) => form.values?.permissions[v as OrgPermission] === "1"
              )}
              onChange={(values: string[]) => {
                const evs = Object.values(OrgPermissions);
                const permissions: { [key: string]: PermissionStatus } = {};

                for (const ev of evs) {
                  permissions[ev] = values.includes(ev) ? "1" : "0";
                }
                form.setFieldValue("permissions", permissions);
              }}
            >
              <div>
                <Text mt="xl">{t("PERMISSIONS")}</Text>
                <Divider />
              </div>
              <Group mt="xl" grow align="flex-start">
                <Stack>
                  <Text weight={500}>{t("ADMIN_PERMISSIONS")}</Text>
                  <Stack ml="xl">
                    {adminPermissions.map((p) => (
                      <Switch
                        value={p.value}
                        label={p.label}
                        description={p.description}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Stack>
                  <Text weight={500}>{t("INVOICING_PERMISSIONS")}</Text>
                  <Stack ml="xl">
                    {invoicingPermissions.map((p) => (
                      <Switch
                        value={p.value}
                        label={p.label}
                        description={p.description}
                      />
                    ))}
                  </Stack>
                  <Text weight={500} mt="xl">
                    {t("RECORS_PERMISSIONS")}
                  </Text>
                  <Stack ml="xl">
                    {recordsPermissions.map((p) => (
                      <Switch
                        value={p.value}
                        label={p.label}
                        description={p.description}
                      />
                    ))}
                  </Stack>
                  <Text weight={500} mt="xl">
                    {t("REPORTS_PERMISSIONS")}
                  </Text>
                  <Stack ml="xl">
                    {reportsPermissions.map((p) => (
                      <Switch
                        value={p.value}
                        label={p.label}
                        description={p.description}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Group>
            </Switch.Group>
          </Stack>
        </BBox>

        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
