import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const creditRecordDataV0Schema = z.object({
  clientId: z.string(),
  balance: z.number(), // removed non negative to allow functions to not throw errors
  // and be able to fix it by other means
  payments: z.record(z.string(), z.number()),
});

type CreditRecordDataV0 = z.infer<typeof creditRecordDataV0Schema>;

const creditRecordV0Schema = baseRecordSchema.merge(creditRecordDataV0Schema);

type CreditRecordV0 = z.infer<typeof creditRecordV0Schema>;

const normalizeCreditRecordV0 = (data: any): CreditRecord => {
  try {
    const creditRecordV0: CreditRecordV0 = creditRecordV0Schema.parse(data);

    const { ...rest } = creditRecordV0;

    // here it's same object cause current v is 0
    const creditRecord: CreditRecord = {
      ...rest,
    };
    return creditRecord;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const CREDIT_RECORD_VERSION = "0";

export type CreditRecordData = CreditRecordDataV0;

export type CreditRecord = BaseRecord & CreditRecordData;

export const creditRecordDataSchema = creditRecordDataV0Schema;

export const creditRecordSchema = creditRecordV0Schema;

export const normalizeCreditRecord = (data: any): CreditRecord => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeCreditRecordV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
