import { z } from "zod";

// t("BOOKED_APT")
// t("CHECKED_IN_APT")
// t("CHECKED_OUT_APT")
// t("CANCELED_APT")
// t("NO_SHOW_APT")
export const appointmentStatusEnum = z.enum([
  "BOOKED", // doctor accepted appointment request
  "CHECKED_IN", // client arrived
  "CHECKED_OUT", // client left
  "CANCELED", // appointment canceled
  "NO_SHOW", // client didnt show up
]);

export type AppointmentStatus = z.infer<typeof appointmentStatusEnum>;
