import { z } from "zod";

// t("CASH")
// t("CREDIT_CARD")
// t("CHECK")
// t("CREDIT_BALANCE")
// t("BANK_TRANSFER")

export const paymentMethodsEnum = z.enum([
  "CASH",
  "CREDIT_CARD",
  "CHECK",
  "CREDIT_BALANCE",
  "BANK_TRANSFER",
]);

export type PaymentMethod = z.infer<typeof paymentMethodsEnum>;
