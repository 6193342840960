import {
  ActionIcon,
  Button,
  Checkbox,
  Container,
  Group,
  Input,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import {
  Client,
  ClientData,
  errorNotification,
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  normalizeClient,
  obju,
  phone_utils,
} from "beitary-shared";
import {
  BBox,
  BThumbnailInput,
  ContactInfoTypeSelect,
  CountryCodeSelect,
  MoveBackButton,
  SaveButton,
} from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadThumbnail } from "hooks/useThumbnailUpload";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditClientFormValues, rules } from "./EditClientForm.rules";

export interface EditClientFormProps {
  client: Client;
}

export const EditClientForm = ({ client }: EditClientFormProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const [file, setFile] = useState<File | null>(null);
  const [fileUpload] = useUploadThumbnail();
  const db = useDBServices().clientsDBService;

  const form = useForm<EditClientFormValues>({
    initialValues: { ...normalizeClient(client) },
    validate: rules,
  });

  const submit = async (values: EditClientFormValues) => {
    const {
      name,
      phoneNumber,
      additionalInfo,
      alert,
      discountId,
      emailNotif,
      thumbURL,
      smsNotif,
      address_countryCode,
      phoneNumber_countryCode,
      address_Line1,
      address_Line2,
      address_city,
      address_postalCode,
      address_stateOrProvince,
      additionalContactInfo,
      email,
      taxID,
    } = values;

    const parsedPhoneNumber =
      phoneNumber && phoneNumber_countryCode
        ? phone_utils.parse(phoneNumber, phoneNumber_countryCode)
        : undefined;

    const notificationId = "submit-edit-client";

    if (parsedPhoneNumber === null) {
      showNotification(
        errorNotification({
          id: notificationId,
          message: t("ERROR"),
          title: t("INVALID_PHONE_NUMBER"),
        })
      );
      return;
    }

    const clientData: Partial<ClientData> = {
      name,
      phoneNumber: parsedPhoneNumber,
      address_countryCode,
      phoneNumber_countryCode,
      additionalContactInfo,
      additionalInfo,
      address_city,
      address_Line1,
      address_Line2,
      address_postalCode,
      address_stateOrProvince,
      alert,
      smsNotif,
      thumbURL,
      emailNotif,
      discountId,
      email,
      taxID,
    };

    obju.removeUndefined(clientData);

    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATING_CLIENT"),
      })
    );

    // good
    // console.log(patientDataSchema.safeParse(patientData));

    setSubmitState("pending-response");
    const result = await db.updateClient(client.id, clientData);

    if (result.type === "success") {
      if (result.payload) {
        if (file) {
          await fileUpload.submitThumbnail(file, result.payload);
        }
      }
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(
        result.type === "error" ? "error" : "success"
      )({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Container fluid>
        <Stack spacing="xl">
          <Group mt="xl">
            <MoveBackButton />
          </Group>
          <BBox header={<Text weight={500}>{t("EDIT_CLIENT")}</Text>}>
            <Stack p="xl">
              <BThumbnailInput
                currentImgSrc={client?.thumbURL}
                file={file}
                setFile={(v) => {
                  console.log(v);

                  if (!v) {
                    fileUpload.clearThumbnail();
                  } else {
                    fileUpload.thumbnailReady();
                  }
                  setFile(v);
                }}
              />
              <TextInput
                required
                label={t("NAME")}
                {...form.getInputProps("name")}
              />
              <TextInput label={t("TAX_ID")} {...form.getInputProps("taxID")} />
              <Group grow>
                <TextInput
                  label={t("PRIMARY_MOBILE_PHONE_NUMBER")}
                  {...form.getInputProps("phoneNumber")}
                />
                <CountryCodeSelect
                  label={t("PHONE_COUNTRY")}
                  {...form.getInputProps("phoneNumber_countryCode")}
                />
              </Group>
              <TextInput
                type="email"
                label={t("PRIMARY_EMAIL")}
                {...form.getInputProps("email")}
                onChange={(e) => {
                  const v = e.target.value === "" ? undefined : e.target.value;
                  console.log(v);

                  form.setFieldValue("email", v);
                }}
              />
              <Input.Wrapper label={t("ADDITIONAL_CONTACT_INFO")}>
                <Stack pt="md">
                  {form.values.additionalContactInfo &&
                    form.values.additionalContactInfo.map((v, index) => (
                      <Group key={v.id}>
                        <ContactInfoTypeSelect
                          {...form.getInputProps(
                            `additionalContactInfo.${index}.type`
                          )}
                        />
                        <TextInput
                          {...form.getInputProps(
                            `additionalContactInfo.${index}.description`
                          )}
                        />
                        <ActionIcon
                          onClick={() =>
                            form.setFieldValue(
                              "additionalContactInfo",
                              (form.values.additionalContactInfo ?? []).filter(
                                (elem) => elem.id !== v.id
                              )
                            )
                          }
                        >
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>
                    ))}
                  <Group>
                    <Button
                      onClick={() => {
                        form.values.additionalContactInfo
                          ? form.setFieldValue("additionalContactInfo", [
                              ...form.values.additionalContactInfo,
                              {
                                type: "PHONE_NUMBER",
                                description: "",
                                id: id_util.newId20(),
                              },
                            ])
                          : form.setFieldValue("additionalContactInfo", [
                              {
                                type: "PHONE_NUMBER",
                                description: "",
                                id: id_util.newId20(),
                              },
                            ]);
                      }}
                    >
                      {t("ADD_CONTACT_INFO")}
                    </Button>
                  </Group>
                </Stack>
              </Input.Wrapper>
              <Group grow align="flex-start" spacing="xl">
                <TextInput
                  label={t("ADDRESS_LINE_1")}
                  {...form.getInputProps("address_Line1")}
                />
                <TextInput
                  label={t("ADDRESS_LINE_2")}
                  {...form.getInputProps("address_Line2")}
                />
              </Group>
              <Group grow align="flex-start" spacing="xl">
                <TextInput
                  label={t("ADDRESS_STATE_OR_PROVINCE")}
                  {...form.getInputProps("address_stateOrProvince")}
                />
                <TextInput
                  label={t("ADDRESS_CITY")}
                  {...form.getInputProps("address_city")}
                />

                <TextInput
                  label={t("ADDRESS_POSTAL_CODE")}
                  {...form.getInputProps("address_postalCode")}
                />
                <CountryCodeSelect
                  label={t("COUNTRY")}
                  {...form.getInputProps("address_countryCode")}
                />
              </Group>
              <TextInput label={t("ALERT")} {...form.getInputProps("alert")} />
              <Input.Wrapper label={t("NOTIFICATIONS")}>
                <Stack p="md" spacing="xs">
                  <Checkbox
                    label={t("EMAIL")}
                    {...form.getInputProps("emailNotif", {
                      type: "checkbox",
                    })}
                  />
                  <Checkbox
                    label={t("SMS")}
                    {...form.getInputProps("smsNotif", {
                      type: "checkbox",
                    })}
                  />
                </Stack>
              </Input.Wrapper>
              <Textarea
                label={t("ADDITIONAL_INFORMATION")}
                {...form.getInputProps("additionalInfo")}
              />
            </Stack>
          </BBox>
          <Group position="right">
            <SaveButton state={submitState} canSave={form.isValid()} />
          </Group>
        </Stack>
      </Container>
    </form>
  );
};
