import { z } from "zod";
import { languagesEnum, timezoneEnum } from "../../../types-schemas/common";
import { currencyEnum } from "../../../types-schemas/common/currencies/Currency/Currency";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { orgClosureSchema } from "../OrgClosure";
import { orgHoursSlotSchema } from "../OrgHoursSlot";
import { organizationStatusEnum } from "../OrganizationStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

// this is for client facing info
const organizationDataV0Schema = z.object({
  status: organizationStatusEnum, // TODO: update stuff based on this
  taxID: z.string(),
  name: z.string(),
  email: z.string().email(),
  description: z.string().optional(),
  googleMapsLink: z.string().optional(),
  thumbURL: z.string().url().optional(),
  phoneNumber: z.string(),
  phoneNumber_countryCode: z.string(),
  // address
  address_Line1: z.string(),
  address_Line2: z.string().optional(),
  address_city: z.string(),
  address_stateOrProvince: z.string().optional(),
  address_postalCode: z.string(),
  address_countryCode: z.string(),
  tags: z.array(z.string()).max(10).optional(), // array of tags ids/strings
  averageRating: z.number().min(0).max(5).optional(),
  ratingsCount: z.number().nonnegative().optional(),
  language: languagesEnum.default("FR"),
  currency: currencyEnum.default("TND"),
  timeZone: timezoneEnum.default("Africa/Tunis"),
  // dateFormat: dateFormatEnum.default("YYYY-MM-DD HH:mm:ss"),
  // t("C")
  // t("F")
  temperatureUnit: z.enum(["C", "F"]).default("C"),
  officeHours: z.array(orgHoursSlotSchema).optional(),
  breakHours: z.array(orgHoursSlotSchema).optional(),
  closures: z.array(orgClosureSchema).optional(),
  advancedSettings: z.object({
    enableWhiteBoard: z.boolean().optional(),
    enableBoarding: z.boolean().optional(),
    sendEmailAptNotif: z.boolean().optional(),
    sendSMSAptNotif: z.boolean().optional(),
    sendEmailReminderNotif: z.boolean().optional(),
    sendSMSReminderNotif: z.boolean().optional(),
    sendEmailNotifOnClientCreate: z.boolean().optional(),
    sendSMSNotifOnClientCreate: z.boolean().optional(),
    sendEmailNotifOnPatientCreate: z.boolean().optional(),
    sendSMSNotifOnPatientCreate: z.boolean().optional(),
  }),
});

type OrganizationDataV0 = z.infer<typeof organizationDataV0Schema>;

const organizationV0Schema = baseRecordSchema.merge(organizationDataV0Schema);

type OrganizationV0 = z.infer<typeof organizationV0Schema>;

const normalizeOrganizationV0 = (data: any): Organization => {
  try {
    const organizationV0: OrganizationV0 = organizationV0Schema.parse(data);

    const { ...rest } = organizationV0;

    // here it's same object cause current v is 0
    const organization: Organization = {
      ...rest,
    };
    return organization;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const ORGANIZATION_VERSION = "0";

export type OrganizationData = OrganizationDataV0;

export type Organization = BaseRecord & OrganizationData;

export const organizationDataSchema = organizationDataV0Schema;

export const organizationSchema = organizationV0Schema;

export const normalizeOrganization = (data: any): Organization => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeOrganizationV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
