import { invoiceStatusEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InvoiceStatusSelectProps {
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  canCheckOut: boolean;
  disabled?: boolean;
  label?: string;
  isRefund?: boolean;
}

export const InvoiceStatusSelect = (props: InvoiceStatusSelectProps) => {
  const { t } = useTranslation();
  const data = invoiceStatusEnum.options
    .filter((o) => (props.isRefund ? o !== "CHARGES_COMPLETE" : !!o))
    .map((o) => ({
      value: o,
      label: t(o),
      disabled: !props.canCheckOut && o === "CHECKED_OUT",
    }));

  return (
    <BSelect
      {...props}
      required
      data={data}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
