import {
  Anchor,
  Avatar,
  Button,
  Group,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { OrgPermissions, Patient, tu } from "beitary-shared";
import { AlertTooltip, BBox, BSexIcon } from "components";
import { selectPatients } from "features/Clients/Clients.slice";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export interface ClientPatientsProps {
  clientId: string;
}

export const ClientPatients = ({ clientId }: ClientPatientsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getRow = (patient: Patient) => {
    const { weights, sex, species, breed, color, dateOfBirth } = patient;

    const sexIcon = <BSexIcon sex={sex} />;

    const weightStr =
      weights && weights.length > 0
        ? [...weights].sort((a, b) => b.t - a.t).at(0)?.v + " " + t("kg")
        : undefined;

    const speciesStr = t(species);

    // const dateOfBirthStr = tu.getDateString(dateOfBirth);

    let ageStr = "";
    const [years, months, days] = tu.getAgeInYMD(dateOfBirth);
    if (years !== 0) ageStr += `${years} ${t("YEARS")} `;
    if (months !== 0) ageStr += `${months} ${t("MONTHS")} `;
    if (days !== 0) ageStr += `${days} ${t("DAYS")} `;

    const infoElms = [
      weightStr,
      sexIcon,
      speciesStr,
      breed,
      color,
      // dateOfBirthStr,
      ageStr,
    ];

    let info = infoElms
      .filter((e) => !!e)
      .map((e, i) => (
        <Text size="sm" key={i}>
          {e}
        </Text>
      ));
    info = info
      .map((e, i) =>
        i < info.length - 1
          ? [
              e,
              <Text size="sm" key={`.${i}`}>
                .
              </Text>,
            ]
          : [e]
      )
      .flat();

    //  border: `1px solid ${theme.colors.gray[3]}`
    return (
      <td>
        <Group p={6}>
          <Avatar src={patient.thumbURL} size={40} radius="xl">
            <IconPaw size={25} />
          </Avatar>
          <div>
            <Group spacing={4}>
              <Anchor
                color="dark"
                weight={500}
                lineClamp={1}
                component={Link}
                size="sm"
                to={`/clients/${clientId}/patients/${patient.id}`}
              >
                {patient.name} {<IconExternalLink size={12} />}
              </Anchor>
              {patient.alert && (
                <AlertTooltip alert={patient.alert} size={16} />
              )}
            </Group>
            <Group spacing="xs" align="flex-start">
              {info}
            </Group>
          </div>
        </Group>
      </td>
    );
  };

  const patients = useAppSelector(selectPatients).filter(
    (p) => p.ownerId === clientId
  );

  const rows = patients.map((patient) => (
    <tr key={patient.id}>{getRow(patient)}</tr>
  ));

  return (
    <BBox
      header={
        <Group position="apart">
          <Group>
            <Text weight={500}>{t("PATIENTS")}</Text>
          </Group>
          <BCan I="do" a={OrgPermissions.PATIENTS_CLIENTS_CUD}>
            <Button
              onClick={() => navigate(`/clients/${clientId}/patients/new`)}
            >
              {t("ADD_PATIENT")}
            </Button>
          </BCan>
        </Group>
      }
    >
      <ScrollArea>
        <Table>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </BBox>
  );
};
