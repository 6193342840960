import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  Bundle,
  Category,
  ImagingIntegrationProduct,
  Product,
} from "beitary-shared";
interface CatalogState {
  products: Product[];
  productCategories: Category[];
  bundles: Bundle[];
  imagingIntegrationProducts: ImagingIntegrationProduct[];
}

const KEY = "catalog";

const initialState: CatalogState = {
  products: [],
  productCategories: [],
  bundles: [],
  imagingIntegrationProducts: [],
};

export const catalogSlice = createSlice({
  name: KEY,
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    setProductCategories: (state, action: PayloadAction<Category[]>) => {
      state.productCategories = action.payload;
    },
    setBundles: (state, action: PayloadAction<Bundle[]>) => {
      state.bundles = action.payload;
    },
    setImagingIntegrationProducts: (
      state,
      action: PayloadAction<ImagingIntegrationProduct[]>
    ) => {
      state.imagingIntegrationProducts = action.payload;
    },
  },
});

export const {
  setProducts,
  setBundles,
  setProductCategories,
  setImagingIntegrationProducts,
} = catalogSlice.actions;

export const selectProducts = (state: RootState) => state.catalog.products;
export const selectImagingIntegrationProducts = (state: RootState) =>
  state.catalog.imagingIntegrationProducts;
export const selectInventoryItemLinkedProducts = (
  state: RootState,
  id?: string
) => state.catalog.products.filter((p) => p.linkedInventoryItemId === id);

export const selectActiveProducts = (state: RootState) =>
  state.catalog.products.filter((o) => o.status === "ACTIVE");

export const selectProductCategories = (state: RootState) =>
  state.catalog.productCategories;

export const selectActiveProductCategories = (state: RootState) =>
  state.catalog.productCategories.filter((o) => o.status === "ACTIVE");

export const selectBundles = (state: RootState) => state.catalog.bundles;

export const selectActiveBundles = (state: RootState) =>
  state.catalog.bundles.filter((o) => o.status === "ACTIVE");

export const catalogReducer = catalogSlice.reducer;
