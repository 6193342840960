import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const vitalsEntryDataV0Schema = z.object({
  status: genericStatusEnum,
  consultationId: z.string(),
  temp: z.number().optional(),
  pulse: z.number().positive().optional(),
  resp: z.number().positive().optional(),
  capRefTime: z.string().optional(),
  mucusColor: z.string().optional(),
  mentation: z.string().optional(),
  notes: z.string().optional(),
});

type VitalsEntryDataV0 = z.infer<typeof vitalsEntryDataV0Schema>;

const vitalsEntryV0Schema = baseRecordSchema.merge(vitalsEntryDataV0Schema);

type VitalsEntryV0 = z.infer<typeof vitalsEntryV0Schema>;

const normalizeVitalsEntryV0 = (data: any): VitalsEntry => {
  try {
    const vitalsEntryV0: VitalsEntryV0 = vitalsEntryV0Schema.parse(data);

    const { ...rest } = vitalsEntryV0;

    // here it's same object cause current v is 0
    const vitalsEntry: VitalsEntry = {
      ...rest,
    };
    return vitalsEntry;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const VITALS_ENTRY_VERSION = "0";

export type VitalsEntryData = VitalsEntryDataV0;

export type VitalsEntry = BaseRecord & VitalsEntryData;

export const vitalsEntryDataSchema = vitalsEntryDataV0Schema;

export const vitalsEntrySchema = vitalsEntryV0Schema;

export const normalizeVitalsEntry = (data: any): VitalsEntry => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeVitalsEntryV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
