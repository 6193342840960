import {
  ActionIcon,
  Button,
  Collapse,
  Group,
  NumberInput,
  Stack,
  Switch,
  Table,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  Bundle,
  getNotificationByResultType,
  loadingInfoNotification,
  Product,
  Result,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { BMoneyInput } from "components/BMoneyInput";
import { MoveBackButton } from "components/MoveBackButton";
import { selectActiveProducts } from "features/admin/catalog/Catalog.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";
import { ProductMultiSelect } from "../../../Products/components";
import { CategorySelect } from "../CategorySelect";
import { BundleFormValues, rules } from "./BundleForm.rules";

export interface BundleFormProps {
  bundle?: Bundle;
}

type Elm = {
  min: number;
  max: number;
} & Product;

export const BundleForm = ({ bundle }: BundleFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editBundle, addBundle } = useDBServices().catalogDBService.bundles;
  const products = useAppSelector(selectActiveProducts);

  const form = useForm<BundleFormValues>({
    initialValues: bundle ? { ...bundle } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");
  // if (!form.values.products) form.setFieldValue("products", []);
  if (!form.values.taxRate) form.setFieldValue("taxRate", 0.19);

  const submit = async (values: BundleFormValues) => {
    const notificationId = "submit-catalog-bundle";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(bundle ? "UPDATE_BUNDLE" : "ADD_BUNDLE"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<Bundle | null>;
    if (bundle) {
      result = await editBundle(bundle.id, values);
    } else {
      result = await addBundle(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const [selectedProductsIds, setSelectedProductsIds] = useState<
    string[] | undefined
  >(undefined);

  const [selectedProducts, setSelectedProducts] = useState<Product[]>([
    ...products.filter(
      (p) => form.values.products?.find((o) => o.id === p.id) !== undefined
    ),
  ]);

  const addProducts = () => {
    if (selectedProductsIds) {
      const productsIdsToAdd = selectedProductsIds.filter(
        (id) => !form.values?.products?.find((p) => p.id === id)
      );
      form.setFieldValue("products", [
        ...(form.values.products ?? []),
        ...productsIdsToAdd.map((p) => ({ id: p, min: 1, max: 1 })),
      ]);

      const prods = products.filter((p) => productsIdsToAdd.includes(p.id));
      if (prods) setSelectedProducts([...selectedProducts, ...prods]);
    }
  };

  const deleteProduct = (id: string) => {
    form.setFieldValue("products", [
      ...(form.values.products.filter((p) => p.id !== id) ?? []),
    ]);
    setSelectedProducts(selectedProducts.filter((p) => p.id !== id));
  };

  // console.log(form.values);

  const minTotalEstiamtedCharges =
    form.values.products?.reduce((sum: number, p) => {
      const prod = products.find((i) => i.id === p.id);
      if (!prod) return sum;
      const { totalValueWithTaxes } = mu.calculateItemValueAndTaxes({
        qty: p.min,
        taxRate: prod.taxRate,
        totalDiscount: 0,
        unitValue: prod.unitSellingPrice,
      });

      sum += totalValueWithTaxes;
      return sum;
    }, 0) ?? 0;

  const maxTotalEstiamtedCharges =
    form.values.products?.reduce((sum: number, p) => {
      const prod = products.find((i) => i.id === p.id);
      if (!prod) return sum;
      const { totalValueWithTaxes } = mu.calculateItemValueAndTaxes({
        qty: p.max,
        taxRate: prod.taxRate,
        totalDiscount: 0,
        unitValue: prod.unitSellingPrice,
      });

      sum += totalValueWithTaxes;
      return sum;
    }, 0) ?? 0;

  const elements = (
    form.values.products?.reduce((arr: Elm[], p) => {
      const product = products.find((o) => o.id === p.id);
      if (product)
        arr.push({
          ...product,
          min: p.min,
          max: p.max,
        });
      return arr;
    }, []) ?? []
  ).sort((a, b) => b.name.localeCompare(a.name));

  const rows = elements.map((element) => {
    const calcMin = mu.calculateItemValueAndTaxes({
      qty: element.min,
      taxRate: element.taxRate,
      totalDiscount: 0,
      unitValue: element.unitSellingPrice,
    });

    const calcMax = mu.calculateItemValueAndTaxes({
      qty: element.max,
      taxRate: element.taxRate,
      totalDiscount: 0,
      unitValue: element.unitSellingPrice,
    });

    return (
      <tr key={element.id}>
        <td>
          <Text>{element.name}</Text>
        </td>
        <td>
          {element.dose && element.ccSolventUnit && (
            <Text>
              {element.dose * element.min}-{element.dose * element.max}{" "}
              {t(element.ccSolventUnit)}
            </Text>
          )}
        </td>
        <td>
          <NumberInput
            value={element.min}
            onChange={(v) => {
              if (v !== "") {
                const item = form.values?.products?.find(
                  (p) => p.id === element.id
                ) ?? { id: element.id, min: 1, max: 1 };
                const values = form.values?.products?.filter(
                  (p) => p.id !== element.id
                );

                form.setFieldValue("products", [
                  ...values,
                  {
                    ...item,
                    min: v,
                  },
                ]);
              }
            }}
            min={0}
            max={Math.max(
              0,
              form.values?.products?.find((p) => p.id === element.id)?.max ?? 0
            )}
          />
        </td>
        <td>
          <NumberInput
            value={element.max}
            onChange={(v) => {
              if (v !== "") {
                const item = form.values?.products?.find(
                  (p) => p.id === element.id
                ) ?? { id: element.id, min: 1, max: 1 };
                const values = form.values?.products?.filter(
                  (p) => p.id !== element.id
                );

                form.setFieldValue("products", [
                  ...values,
                  {
                    ...item,
                    max: v,
                  },
                ]);
              }
            }}
            min={Math.max(
              1,
              form.values?.products?.find((p) => p.id === element.id)?.min ?? 1
            )}
          />
        </td>
        <td>
          <Text>
            {mu.toD(calcMin.totalValueWithTaxes)}-
            {mu.toD(calcMax.totalValueWithTaxes)}
          </Text>
        </td>
        <td>
          <ActionIcon
            onClick={() => deleteProduct(element.id)}
            disabled={submitState === "pending-response"}
          >
            <IconTrash size={18} />
          </ActionIcon>
        </td>
      </tr>
    );
  });

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            bundle ? (
              <Text weight={500}>{t("EDIT_BUNDLE")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_BUNDLE")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              required
              placeholder={t("ENTER_NAME")}
              label={t("NAME")}
              {...form.getInputProps("name")}
            />
            <TextInput
              placeholder={t("BUNDLE_CODE_PLACEHOLDER")}
              label={t("CODE")}
              {...form.getInputProps("code")}
            />
            <Group align="flex-end">
              <ProductMultiSelect
                required
                onChange={(v) => setSelectedProductsIds(v)}
              />
              <Button onClick={addProducts}>{t("ADD_PRODUCTS")}</Button>
            </Group>
            <Table></Table>
            <Table>
              <thead>
                <tr>
                  <th>{t("NAME")}</th>
                  <th>{t("DOSE")}</th>
                  <th>{t("MIN_QUATNITY")}</th>
                  <th>{t("MAX_QUATNITY")}</th>
                  <th>{t("PRICE")}</th>
                  <th>{t("ACTIONS")}</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            {form.values.hasFixedPrice ? (
              <Text align="right" color="dimmed">
                {t("BUNDLE_HAS_FIXED_PRICE")}
              </Text>
            ) : (
              <Text align="right">
                {t("TOTAL_ESTIMATED_CHARGES") + ": "}
                <Text span weight={500}>
                  {mu.toD(minTotalEstiamtedCharges) +
                    " - " +
                    mu.toD(maxTotalEstiamtedCharges)}
                </Text>
              </Text>
            )}
            <Textarea
              placeholder={t("ENTER_NOTES")}
              label={t("NOTES")}
              {...form.getInputProps("notes")}
            />
          </Stack>
        </BBox>
        <BBox
          header={
            <Group position="apart">
              <Text weight={500}>{t("FIXED_PRICE_BUNDLE")}</Text>
              <Switch
                {...form.getInputProps("hasFixedPrice", { type: "checkbox" })}
              />
            </Group>
          }
        >
          <Collapse in={form.values.hasFixedPrice ?? false} p="xl">
            <Stack>
              <BMoneyInput
                required={form.values.hasFixedPrice}
                label={t("BUNDLE_PRICE_NO_TAXES")}
                {...form.getInputProps("unitSellingPrice")}
                min={0}
              />
              <BMoneyInput
                required={form.values.hasFixedPrice}
                label={t("BUNDLE_PRICE")}
                {...form.getInputProps("unitSellingPrice")}
                min={0}
                value={mu.multiply({
                  amount: form.values.unitSellingPrice ?? 0,
                  multiplier: 1 * (1 + form.values.taxRate),
                })}
                onChange={(v) => {
                  if (v !== "") {
                    form.setFieldValue(
                      "unitSellingPrice",
                      mu.multiply({
                        amount: v,
                        multiplier: 1 / (1 + form.values.taxRate),
                      })
                    );
                  }
                }}
              />
              <NumberInput
                required
                label={t("TAXES")}
                description={t("%")}
                {...form.getInputProps("taxRate")}
                value={form.values.taxRate * 100}
                onChange={(v) => {
                  if (v !== "")
                    form.setFieldValue(
                      "taxRate",
                      Number(Math.round(parseFloat(v / 100 + "e4")) + "e-4")
                    );
                }}
                min={0}
                precision={2}
              />
              <CategorySelect
                required={form.values.hasFixedPrice}
                {...form.getInputProps("categoryId")}
              />
            </Stack>
          </Collapse>
        </BBox>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
