import { Container, Group, Stack, Title } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BoardingAppointment, tu } from "beitary-shared";
import { BDateInput, MoveBackButton } from "components";
import { selectBoardingResources } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentModal, CalendarView } from "./components";

interface ViewProps {
  selectedDate: number;
  setSelectedDate: Function;
  appointments: BoardingAppointment[]; // weekAppointments
}

export const View = ({
  selectedDate,
  setSelectedDate,
  appointments,
}: ViewProps) => {
  const { t } = useTranslation();

  const boardingResources = useAppSelector(selectBoardingResources);

  const weekAppointmentsByResource = useMemo(() => {
    const ids = boardingResources.map((r) => r.id);

    const appointmentsByResourceMap: { [x: string]: BoardingAppointment[] } =
      {};
    ids.forEach((id) => (appointmentsByResourceMap[id] = []));

    // Loop through each appointment and assign it to the corresponding resource
    appointments.forEach((appointment) => {
      appointmentsByResourceMap[appointment.boardingResourceId].push(
        appointment
      );
    });

    const appointmentsByResourceMapWithLayers: {
      [x: string]: BoardingAppointment[][];
    } = {};
    ids.forEach((id) => (appointmentsByResourceMapWithLayers[id] = []));

    Object.keys(appointmentsByResourceMap).forEach((key) => {
      const arr = appointmentsByResourceMap[key];

      const layers: (BoardingAppointment & { layer: number })[][] = [];

      const sorted = arr.sort((a, b) => a.from - b.from);
      sorted.forEach((i, index) => {
        let newItem = { ...i, layer: 0 };
        if (index === 0) {
          layers.push([newItem]);
          return;
        }
        let k = 0;
        while (k < Object.keys(layers).length) {
          const layer = layers[k];
          let last = layer ? layer[layer.length - 1] : null;
          if (last && newItem.layer === last.layer && newItem.from < last.to) {
            newItem = { ...newItem, layer: newItem.layer + 1 };
          } else {
            k = Object.keys(layers).length;
          }
          k++;
        }
        const layerNumber = newItem.layer;
        if (!layers[layerNumber]) {
          layers[layerNumber] = [newItem];
        } else {
          layers[layerNumber].push(newItem);
        }
      });
      appointmentsByResourceMapWithLayers[key] = layers;
    });

    return appointmentsByResourceMapWithLayers;
  }, [appointments, boardingResources]);

  return (
    <Container fluid mt="xl">
      <MoveBackButton />
      <Title mt="xl" weight={500} order={3}>
        {t("BOARDING_APPOINTMENTS")}
      </Title>

      <Stack mt="xl">
        <Group position="apart" align="flex-end">
          <Group align="flex-end" mb="xs">
            <BDateInput
              label={t("SELECT_DATE")}
              onChange={(v) =>
                v &&
                setSelectedDate(
                  tu.getDateValueAtHMSM(tu.getDate(v), 0, 0, 0, 0)
                )
              }
              value={selectedDate}
            />
            <AppointmentModal />
          </Group>
        </Group>

        <CalendarView
          weekAppointments={weekAppointmentsByResource}
          selectedDate={selectedDate}
        />
      </Stack>
    </Container>
  );
};
