import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Prescription, tu } from "beitary-shared";
import { AsyncActionIcon, TableWithSearchAndSort } from "components";
import { selectActiveConsultationPrescriptions } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type PrescriptionsTableItem = Prescription & { date: string };
interface PrescriptionsTableProps {
  disabled: boolean;
}

export const PrescriptionsTable = ({ disabled }: PrescriptionsTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const prescriptions: PrescriptionsTableItem[] = useAppSelector(
    selectActiveConsultationPrescriptions
  ).map((p) => ({ ...p, date: tu.getLocaleDateStringFromEpoch(p.createdAt) }));

  const { deletePrescription } = useDBServices().prescriptionsDBService;

  const fields: {
    key: keyof PrescriptionsTableItem;
    label: string;
  }[] = [
    { key: "date", label: t("DATE") },
    { key: "prescribingDrName", label: t("PRESCRIBING_DOCTOR") },
  ];

  const getRow = (prescription: PrescriptionsTableItem) => (
    <tr key={prescription.id}>
      <td>
        <Text miw={200}>{prescription.date}</Text>
      </td>
      <td>
        <Text>{prescription.prescribingDrName}</Text>
      </td>
      <td>
        <Group>
          <ActionIcon onClick={() => navigate(`${prescription.id}/view`)}>
            <IconEye size={18} />
          </ActionIcon>
          <BCan I="am" a="DOCTOR">
            <ActionIcon
              disabled={disabled}
              onClick={() => navigate(`${prescription.id}/edit`)}
            >
              <IconEdit size={18} />
            </ActionIcon>
            <AsyncActionIcon
              disabled={disabled}
              icon={<IconTrash size={18} />}
              f={async () => deletePrescription(prescription.id)}
            />
          </BCan>
        </Group>
      </td>
    </tr>
  );
  return (
    <TableWithSearchAndSort
      data={prescriptions}
      fields={fields}
      getRow={getRow}
      hasControlsColumn
    />
  );
};
