import { Box, Stack } from "@mantine/core";
import { PatientWeight, tu } from "beitary-shared";
import { BChartsOption } from "components/charts/bcharts";
import { BLineChart } from "components/charts/components";
import { useTranslation } from "react-i18next";

export interface ChartViewProps {
  weights: PatientWeight[];
  patientDOB: number;
}

export const ChartView = ({ weights, patientDOB }: ChartViewProps) => {
  const { t } = useTranslation();
  // const [opened, setOpened] = useState(false);

  const data = weights.map((w) => [w.t, w.v]);

  console.log(data);

  const option: BChartsOption = {
    xAxis: {
      axisLabel: {
        formatter: (v: number) => {
          console.log(v);

          let ageStr = "";
          const [years, months, days] = tu.getAgeInYMD(patientDOB, v);
          if (years !== 0) ageStr += `${years} ${t("YEARS_SHORT")} `;
          if (months !== 0) ageStr += `${months} ${t("MONTHS_SHORT")} `;
          if (days !== 0) ageStr += `${days} ${t("DAYS_SHORT")} `;
          return ageStr;
        },

        fontSize: 10,
      },
      type: "time",
      data: weights.map((w) => w.t),
    },
    yAxis: {
      axisLabel: { formatter: (v: number) => `${v} ${t("kg")}` },
    },
    series: [
      {
        data,
        type: "line",
        smooth: true,
      },
    ],
  };

  return (
    <Stack>
      {/* <Group>
        <Modal
          size="xl"
          opened={opened}
          onClose={() => setOpened(false)}
          title={t("EDIT_APPOINTMENT")}
          closeOnClickOutside={false}
          overlayProps={{
            color: "white",
            opacity: 0.55,
            blur: 3,
          }}
        >
          <Box w="100%" h="80vh">
            <BLineChart option={option} />
          </Box>
        </Modal>
        <ActionIcon onClick={() => setOpened(true)}>
          <IconZoomIn size={18} />
        </ActionIcon>
      </Group> */}
      <Box w="100%" h={400}>
        <BLineChart option={option} />
      </Box>
    </Stack>
  );
};
