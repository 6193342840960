import { MultiSelect, SelectItem } from "@mantine/core";
import { FC, ReactNode, useState } from "react";

export interface BMultiSelectProps {
  data: (string | SelectItem)[];
  required?: boolean;
  onChange?: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  label?: ReactNode;
  description?: ReactNode;
  placeholder?: string;
  nothingFound?: ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  limit?: number | null;
  maxSelectedValues?: number;
  filter?: (value: string, selected: boolean, item: SelectItem) => boolean;
  itemComponent?: FC<any>;
}

export const BMultiSelect = ({
  data,
  required,
  onChange,
  error,
  value,
  disabled,
  label,
  description,
  placeholder,
  nothingFound,
  readOnly,
  maxSelectedValues,
  limit,
  filter,
  itemComponent,
}: BMultiSelectProps) => {
  const [selectedValue, setSelectedValue] = useState<string[] | undefined>(
    value
  );

  const onSelectChange = (value: string[] | undefined) => {
    onChange && onChange(value);
    setSelectedValue(value);
  };

  return (
    <MultiSelect
      withinPortal
      disabled={disabled}
      required={required}
      error={error}
      label={label}
      limit={limit === null ? undefined : limit === undefined ? 20 : limit}
      description={description}
      placeholder={placeholder}
      searchable
      clearable={!required}
      onChange={onSelectChange}
      value={selectedValue}
      nothingFound={nothingFound}
      readOnly={readOnly}
      maxSelectedValues={maxSelectedValues}
      filter={filter}
      itemComponent={itemComponent}
      data={data}
    />
  );
};
