import { PrecriptionForm } from "../PrecriptionForm";

export interface AddPrescriptionProps {
  consultationId: string;
  clientId: string;
  clientName: string;
  patientId: string;
  patientName: string;
  disabled: boolean;
}

export const AddPrescription = (props: AddPrescriptionProps) => {
  return <PrecriptionForm {...props} />;
};
