import { z } from "zod";

// t("mn")
// t("h")
// t("d")
// t("w")
// t("m")
// t("y")
export const dueTimeOptionsEnum = z.enum(["mn", "h", "d", "w", "m", "y"]);
export type DueTimeOptions = z.infer<typeof dueTimeOptionsEnum>;
