import { createStyles, Tooltip } from "@mantine/core";
import { IconInfoCircleFilled } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  icon: {
    color: theme.colors.red[5],
  },
}));

export interface AlertTooltipProps {
  alert: string;
  size?: number;
}

export const AlertTooltip = ({ alert, size }: AlertTooltipProps) => {
  const { classes } = useStyles();

  return (
    <Tooltip label={alert}>
      <IconInfoCircleFilled stroke={1.5} className={classes.icon} size={size} />
    </Tooltip>
  );
};
