import { errorResult, Result, Source, successResult } from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";

interface AddSerialNumberAndDateIssued {
  ({
    organizationId,
    docId,
    type,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    docId: string;
    authorId: string;
    authorName: string;
    type: aSNIDType;
  }): Promise<Result<aSNIDOnCallResponse | null>>;
}

// TODO shared
type aSNIDType = "invoice" | "payment" | "refund-invoice" | "refund";

type aSNIDOnCallData = {
  authorId: string;
  authorName: string;
  path: string;
  organizationId: string;
  type: aSNIDType;
};

type aSNIDOnCallResponse = {
  serialNumber: string;
};

const addSerialNumberAndDateIssued: AddSerialNumberAndDateIssued = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
  type,
}) => {
  const aSNIDOnCallResponseOnCall = callable<
    aSNIDOnCallData,
    aSNIDOnCallResponse
  >(fcts, "callable-addSerialNumberAndDateIssued");

  let path: string;

  switch (type) {
    case "invoice":
    case "refund-invoice":
      path = `organizations/${organizationId}/invoices/${docId}`;
      break;

    case "payment":
    case "refund":
      path = `organizations/${organizationId}/payments/${docId}`;
      break;

    default:
      throw new Error("TYPE_NOT_SUPPORTED");
  }
  try {
    const { data } = await aSNIDOnCallResponseOnCall({
      path,
      organizationId,
      type,
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const utils = (props: {
  callable: typeof httpsCallable;
  fcts: Functions;
  organizationId: string;
  authorId: string;
  authorName: string;
  source: Source;
}) => {
  return {
    addSerialNumberAndDateIssued: (docId: string, docType: aSNIDType) =>
      addSerialNumberAndDateIssued({
        ...props,
        docId,
        type: docType,
      }),
  };
};
