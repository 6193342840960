import { zodResolver } from "@mantine/form";
import { imagingIntegrationProductDataSchema } from "beitary-shared";
import { z } from "zod";

const bTRDLProductFormSchema = imagingIntegrationProductDataSchema.extend({
  unitSellingPrice: z.number().int().nonnegative(),
  taxRate: z.number().nonnegative().default(0.19),
  categoryId: z.string(),
});

export type BTRDLProductFormValues = z.infer<typeof bTRDLProductFormSchema>;

export const rules = zodResolver(bTRDLProductFormSchema);
