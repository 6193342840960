import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const diagnosisDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  code: z.string().optional(),
  hasDischInstr: z.boolean().optional(),
  dischargeInstructions: z.string().optional(),
});

type DiagnosisDataV0 = z.infer<typeof diagnosisDataV0Schema>;

const diagnosisV0Schema = baseRecordSchema.merge(diagnosisDataV0Schema);

type DiagnosisV0 = z.infer<typeof diagnosisV0Schema>;

const normalizeDiagnosisV0 = (data: any): Diagnosis => {
  try {
    const diagnosisV0: DiagnosisV0 = diagnosisV0Schema.parse(data);

    const { ...rest } = diagnosisV0;

    // here it's same object cause current v is 0
    const diagnosis: Diagnosis = {
      ...rest,
    };
    return diagnosis;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const DIAGNOSIS_VERSION = "0";

export type DiagnosisData = DiagnosisDataV0;

export type Diagnosis = BaseRecord & DiagnosisData;

export const diagnosisDataSchema = diagnosisDataV0Schema;

export const diagnosisSchema = diagnosisV0Schema;

export const normalizeDiagnosis = (data: any): Diagnosis => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeDiagnosisV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
