import {
  errorResult,
  InventoryItem,
  InventoryItemData,
  INVENTORY_ITEM_VERSION,
  normalizeInventoryItem,
  obju,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";
import { inventory } from "../inventory";

// add organization inventoryItem

interface AddInventoryItem {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: InventoryItemData;
  }): Promise<Result<InventoryItem | null>>;
}

const addInventoryItem: AddInventoryItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new inventoryItem id
    const newInventoryItemRef = doc(
      collection(db, "organizations", organizationId, "inventory_items")
    );

    // clean data from undefined fields
    obju.removeUndefined(data);

    const newInventoryItem: InventoryItem = normalizeInventoryItem({
      ...data,
      id: newInventoryItemRef.id,
      authorId,
      authorName,
      version: INVENTORY_ITEM_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    // console.log(newInventoryItem);

    await setDoc(newInventoryItemRef, newInventoryItem);

    if (data.initialCount !== 0) {
      await inventory(
        db,
        organizationId,
        authorId,
        authorName,
        source
      ).inventoryTransactions.addInventoryTransaction({
        costPerUnit: data.costPerUnitOfMeasurement,
        date: tu.getCurrentDateTime(),
        invItemId: newInventoryItem.id,
        invItemName: newInventoryItem.name,
        qty: newInventoryItem.initialCount,
        sourceType: "COUNT",
        state: "PENDING",
        taxRate: newInventoryItem.taxRate,
        type: "INVENTORY_COUNT",
      });
    }

    // t("INVENTORY_ITEM_CREATED")
    const successMessage = "INVENTORY_ITEM_CREATED";
    return successResult({
      message: successMessage,
      payload: newInventoryItem,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetInventoryItem {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<InventoryItem | null>>;
}

const getInventoryItem: GetInventoryItem = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const inventoryItemDocRef = doc(
      db,
      "organizations",
      organizationId,
      "inventory_items",
      id
    );
    const inventoryItemDocSnapshot = await getDoc(inventoryItemDocRef);
    if (inventoryItemDocSnapshot.exists()) {
      try {
        const data: unknown = inventoryItemDocSnapshot.data();
        const inventoryItem: InventoryItem = normalizeInventoryItem(data);
        // t("INVENTORY_ITEM_FOUND")
        const successMessage = "INVENTORY_ITEM_FOUND";
        return successResult({
          message: successMessage,
          payload: inventoryItem,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("INVENTORY_ITEM_NOT_FOUND")
      const errorMessage = "INVENTORY_ITEM_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditInventoryItem {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: InventoryItemData;
  }): Promise<Result<InventoryItem | null>>;
}

const editInventoryItem: EditInventoryItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const inventoryItem = (await getInventoryItem({ db, organizationId, id }))
      .payload;

    if (!inventoryItem) {
      // t("INVENTORY_ITEM_NOT_FOUND")
      const errorMessage = "INVENTORY_ITEM_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = inventoryItem;

      // clean data from undefined fields
      obju.removeUndefined(data);

      const updatedInventoryItem: InventoryItem = normalizeInventoryItem({
        ...data,
        id,
        authorId,
        authorName,
        version: INVENTORY_ITEM_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      console.log(updatedInventoryItem);

      const inventoryItemRef = doc(
        db,
        "organizations",
        organizationId,
        "inventory_items",
        id
      );

      await setDoc(inventoryItemRef, updatedInventoryItem);

      // t("INVENTORY_ITEM_UPDATED")
      const successMessage = "INVENTORY_ITEM_UPDATED";
      // console.log(successMessage, updatedInventoryItem);
      return successResult({
        message: successMessage,
        payload: updatedInventoryItem,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface UpdateInventoryItem {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<InventoryItemData>;
  }): Promise<Result<boolean | null>>;
}

const updateInventoryItem: UpdateInventoryItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "inventory_items",
      id
    );

    // clean data from undefined fields
    obju.removeUndefined(data);

    const updates: Partial<InventoryItem> = {
      ...data,
      authorId,
      authorName,
      version: INVENTORY_ITEM_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("INVENTORY_ITEM_UPDATED")
    const successMessage = "INVENTORY_ITEM_UPDATED";
    // console.log(successMessage, updatedInventoryItem);
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteInventoryItem {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteInventoryItem: DeleteInventoryItem = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "inventory_items",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("INVENTORY_DELETED")
      const successMessage = "INVENTORY_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveInventoryItem: EditInventoryItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: InventoryItemData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editInventoryItem({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("INVENTORY_ITEM_ARCHIVED")
    const successMessage = "INVENTORY_ITEM_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveInventoryItem: EditInventoryItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: InventoryItemData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editInventoryItem({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("INVENTORY_ITEM_DEARCHIVED")
    const successMessage = "INVENTORY_ITEM_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization inventoryItems listener
interface GetInventoryItemsListenerCallback {
  (inventoryItems: InventoryItem[]): void;
}
interface GetInventoryItemsListener {
  db: Firestore;
  organizationId: string;
  callback: GetInventoryItemsListenerCallback;
}

const getInventoryItemsListener = ({
  db,
  organizationId,
  callback,
}: GetInventoryItemsListener): Unsubscribe => {
  try {
    // console.log("getInventoryItemsListener: new listener");
    const inventoryItemsQuery = query(
      collection(db, "organizations", organizationId, "inventory_items"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(inventoryItemsQuery, (querySnapshot) => {
      const inventoryItems: InventoryItem[] = [];
      querySnapshot.forEach((doc) => {
        try {
          inventoryItems.push(normalizeInventoryItem(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(inventoryItems);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const inventoryItems = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getInventoryItemsListener: (callback: GetInventoryItemsListenerCallback) =>
      getInventoryItemsListener({
        db,
        organizationId,
        callback,
      }),
    getInventoryItem: (id: string) =>
      getInventoryItem({
        db,
        organizationId,
        id,
      }),
    addInventoryItem: (data: InventoryItemData) =>
      addInventoryItem({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editInventoryItem: (id: string, data: InventoryItemData) =>
      editInventoryItem({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    updateInventoryItem: (id: string, data: Partial<InventoryItemData>) =>
      updateInventoryItem({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteInventoryItem: (id: string) =>
      deleteInventoryItem({
        db,
        organizationId,
        id,
      }),
    archiveInventoryItem: (id: string, data: InventoryItemData) =>
      archiveInventoryItem({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveInventoryItem: (id: string, data: InventoryItemData) =>
      dearchiveInventoryItem({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
