import { Badge, Box, Stack, Text, useMantineTheme } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { Appointment, tu } from "beitary-shared";
import { selectActiveAppointmentTypes } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentCard } from "../AppointmentCard";
import { EmptySlotCard } from "../EmptySlotCard";

export interface ColumnProps {
  appointments: Appointment[];
  dateTime: number;
  nColumns: number;
  startTime: number;
  endTime: number;
}

export const Column = ({
  appointments,
  dateTime,
  nColumns,
  startTime,
  endTime,
}: ColumnProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const appointmentTypes = useAppSelector(selectActiveAppointmentTypes);

  const x = useMemo(() => (endTime - startTime) * 60, [startTime, endTime]);

  const getEmptySlotsColumn = useCallback(() => {
    const items: ReactNode[] = [];
    for (let i = 0; i < x; i = i + 15) {
      items.push(
        <EmptySlotCard
          defaultTime={dateTime + (startTime * 60 + i) * 60 * 1000}
        />
      );
    }
    return items;
  }, [dateTime, startTime, x]);

  const layers = useMemo(() => {
    const layers: (Appointment & { layer: number })[][] = [];

    const sorted = appointments.sort((a, b) => a.time - b.time);
    sorted.forEach((i, index) => {
      let newItem = { ...i, layer: 0 };
      if (index === 0) {
        layers.push([newItem]);
        return;
      }
      let k = 0;
      while (k < Object.keys(layers).length) {
        const layer = layers[k];
        let last = layer ? layer[layer.length - 1] : null;
        if (
          last &&
          newItem.layer === last.layer &&
          newItem.time < last.time + last.duration * 60 * 1000
        ) {
          newItem = { ...newItem, layer: newItem.layer + 1 };
        } else {
          k = Object.keys(layers).length;
        }
        k++;
      }
      const layerNumber = newItem.layer;
      if (!layers[layerNumber]) {
        layers[layerNumber] = [newItem];
      } else {
        layers[layerNumber].push(newItem);
      }
    });
    return layers;
  }, [appointments]);

  const date = useMemo(() => {
    return appointments[0]?.time
      ? tu.getDate(appointments[0].time)
      : tu.getCurrentDate();
  }, [appointments]);

  const calculatePosition = (time: number) => {
    const p =
      80 + (time - tu.getDateValueAtHMSM(date, startTime, 0, 0, 0)) / 15000; // 80 = header height

    return p;
  };

  const getAppointmentCard = (appointment: Appointment, lIndex: number) => {
    const color =
      appointmentTypes.find((t) => t.id === appointment.appointmentTypeId)
        ?.color ?? "white";
    return (
      <AppointmentCard
        appointment={appointment}
        color={color}
        topPosition={calculatePosition(appointment.time)}
        lIndex={lIndex}
        lLength={layers.length}
      />
    );
  };

  return (
    <Stack
      spacing={0}
      sx={{ width: `calc((100% - 80px)/${nColumns})`, position: "relative" }}
    >
      <Box
        h={80}
        w="100%"
        sx={{
          border: `1px solid ${theme.colors.cyan[2]}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <Text>{tu.getDateString(dateTime)}</Text>
          <Badge sx={{ textTransform: "lowercase" }}>
            <Text>
              {appointments.length} {t("APPOINTMENTS")}
            </Text>
          </Badge>
        </div>
      </Box>
      {getEmptySlotsColumn()}
      {layers.map((layer, lIndex) => (
        <div>{layer.map((a, i) => getAppointmentCard(a, lIndex))}</div>
      ))}
    </Stack>
  );
};
