import {
  errorResult,
  GPLOnCallData,
  GPLOnCallResponse,
  Result,
  Source,
  successResult,
} from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";

interface GetPatientLifeCycle {
  (props: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    authorId: string;
    authorName: string;
    patientId: string;
    source: Source;
  }): Promise<Result<GPLOnCallResponse | null>>; // TODO SHARED
} /// AMOnCallResponse

const getPatientLifeCycle: GetPatientLifeCycle = async ({
  organizationId,
  callable,
  fcts,
  patientId,
}) => {
  const getPatientLifeCycleOnCall = callable<GPLOnCallData, GPLOnCallResponse>(
    fcts,
    "callable-patient_getPatientLifecycle"
  );
  try {
    const r = await getPatientLifeCycleOnCall({
      organizationId,
      patient: patientId,
    });

    return successResult({
      message: "success",
      payload: r.data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

export const patient = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getPatientLifeCycle: (patientId: string) =>
      getPatientLifeCycle({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        source,
        patientId,
      }),
  };
};
