import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Category, OrgPermissions } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectProductCategories } from "features/admin/catalog/Catalog.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type CategoriesTableItem = Category & {
  actions?: undefined;
};

export const CategoriesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableItems = useAppSelector(selectProductCategories);

  const {
    deleteProductCategory,
    archiveProductCategory,
    dearchiveProductCategory,
  } = useDBServices().catalogDBService.productCategories;

  const columns: BDataTableColumn<CategoriesTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "notes",
      title: <Text>{t("NOTES")}</Text>,
      sortable: true,
    },

    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (category) => (
        <BCan I="do" a={OrgPermissions.PRODUCTS_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${category.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {category.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () => dearchiveProductCategory(category.id, category)}
                item={category}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () => archiveProductCategory(category.id, category)}
                item={category}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteProductCategory(category.id)}
              item={category}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
