import { Container, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

export const View = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Title mt="xl" mb="xl" weight={500} order={3}>
        {t("DIAGNOSES")}
      </Title>

      <Outlet />
    </Container>
  );
};
