import { Box, Stack, Text, useMantineTheme } from "@mantine/core";
import { id_util, tu } from "beitary-shared";
import { ReactNode } from "react";
import { EmptySlotCard } from "../EmptySlotCard";

export interface ColumnProps {
  layers: { resourceId: string; nOfLayers: number }[];
  dateTime: number;
  nColumns: number;
  columnNumber: number;
  selectedDate: number;
}

export const Column = ({
  layers,
  dateTime,
  nColumns,
  columnNumber,
  selectedDate,
}: ColumnProps) => {
  const theme = useMantineTheme();

  const getEmptySlotsColumn = (dateTime: number) => {
    const items: ReactNode[] = [];
    for (let i = 0; i < layers.length; i++) {
      items.push(
        <EmptySlotCard
          key={id_util.newId20()}
          defaultTime={dateTime + (columnNumber * 24 + 12) * 60 * 60 * 1000} // noon
          height={120 * Math.max(layers[i].nOfLayers, 1)}
          resourceId={layers[i].resourceId}
        />
      );
    }
    return items;
  };

  return (
    <Stack spacing={0} sx={{ width: `calc((100% - 160px)/${nColumns})` }}>
      <Box
        h={80}
        w="100%"
        sx={{
          border: `1px solid ${theme.colors.cyan[2]}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <Text>{tu.getDateString(dateTime)}</Text>
          {/* <Badge sx={{ textTransform: "lowercase" }}>
            <Text>
              {totalApts} {t("APPOINTMENTS")}
            </Text>
          </Badge> */}
        </div>
      </Box>
      {getEmptySlotsColumn(tu.getStartOfWeek(tu.getDate(selectedDate)))}
    </Stack>
  );
};
