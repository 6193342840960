import { Stack } from "@mantine/core";
import { Consultation } from "beitary-shared";
import { useEffect } from "react";
import { TxHistory, TxOrder, TxPlan } from "./components";

interface PlanProps {
  consultation: Consultation;
}

export const Plan = ({ consultation }: PlanProps) => {
  useEffect(() => {}, [consultation]);

  return (
    <Stack>
      <TxPlan consultation={consultation} />
      <TxOrder consultation={consultation} />
      <TxHistory consultation={consultation} />
    </Stack>
  );
};
