import { zodResolver } from "@mantine/form";
import { treatmentSchema } from "beitary-shared";
import { z } from "zod";

const bundleTxFormSchema = treatmentSchema.merge(
  z.object({
    products: z.array(treatmentSchema),
  })
);

export type BundleTxFormValues = z.infer<typeof bundleTxFormSchema>;

export const rules = zodResolver(bundleTxFormSchema);
