import { useAppSelector } from "app/hooks";
import { BSelect } from "components/BSelect";
import {
  selectActiveAppointmentTypes,
  selectActiveServiceProviders,
} from "features/admin/schedule-settings/ScheduleSettings.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface AppointmentTypeSelectProps {
  required?: boolean;
  onChange: (id: string, name: string, duration: number) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  serviceProviderId?: string;
}

export const AppointmentTypeSelect = ({
  onChange,
  serviceProviderId,
  ...props
}: AppointmentTypeSelectProps) => {
  const { t } = useTranslation();
  const providerServices =
    useAppSelector(selectActiveServiceProviders).find(
      (p) => p.id === serviceProviderId
    )?.servicesIds ?? [];
  const appointmentTypes = useAppSelector(selectActiveAppointmentTypes).filter(
    (t) => providerServices.includes(t.id)
  );
  const data = appointmentTypes.map((o) => ({ value: o.id, label: o.name }));

  return (
    <BSelect
      {...props}
      label={t("SERVICES_OFFERED")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
      onChange={(v) => {
        if (v) {
          const aptType = appointmentTypes.find((t) => t.id === v);
          const name = aptType?.name ?? "";
          const duration = aptType?.duration ?? 30;
          onChange(v, name, duration);
        }
      }}
    />
  );
};
