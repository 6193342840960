import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Bundle, OrgPermissions } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectBundles } from "features/admin/catalog/Catalog.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type BundlesTableItem = Bundle & {
  nameAndCode: string;
  actions?: undefined;
};

export const BundlesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const bundles = useAppSelector(selectBundles);

  const tableItems: BundlesTableItem[] = bundles.map((bundle) => ({
    ...bundle,
    nameAndCode: bundle.name.concat(bundle.code ?? ""),
  }));

  const { deleteBundle, archiveBundle, dearchiveBundle } =
    useDBServices().catalogDBService.bundles;

  const columns: BDataTableColumn<BundlesTableItem>[] = [
    {
      accessor: "nameAndCode",
      title: <Text>{t("BUNDLE")}</Text>,
      sortable: true,
      render: ({ name, code }) => (
        <>
          <Text>{name}</Text>
          {code && (
            <Text size="sm" color="dimmed">
              {code}
            </Text>
          )}
        </>
      ),
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (bundle) => (
        <BCan I="do" a={OrgPermissions.PRODUCTS_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${bundle.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {bundle.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () => dearchiveBundle(bundle.id, bundle)}
                item={bundle}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () => archiveBundle(bundle.id, bundle)}
                item={bundle}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteBundle(bundle.id, bundle)}
              item={bundle}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
