import { z } from "zod";

// by default:
// can read stuff unless specified
// can update stuff unless specified
// can delete stuff unless specified
// can create stuff unless specified
// can access stuff unless specified
// not here: if is doctor: can CUD what requires a doctor
export enum OrgPermissions {
  // admin
  ANNOUNCEMENTS_CUD = "a",
  MEMBERS_CUD = "b",
  CLINIC_SETTINGS_CUD = "c",
  DIAGNOSES_CUD = "d",
  FORMS_CU = "e",
  INTEGRATIONS_ACCESS = "f",
  INVENTORY_ACCESS = "g",
  MESSAGING_ACCESS = "h",
  PHYSICAL_EXAM_TEMPLATES_CUD = "i",
  PRODUCTS_CUD = "j",
  CLIENT_COMMUNICATION = "k",
  ROLES_CUD = "l",
  SCHEDULE_CUD = "m",

  // invoicing
  ITEM_PRICE_UPDATE_DISCOUNTS_APPLY_TO_INVOICE = "n",
  INVOICES_UNLICK_REFUNC_RETURN = "o",
  PAYMENTS_UD_VOID = "p",
  INVOICE_DELETE = "A",

  //records
  CHRONIC_CONDITION_D_UPDATE_NOTES = "q", // doctor can CUD
  NOTES_D = "r", // can be updated by owned if consultation not locked
  PATIENTS_CLIENTS_CUD = "s", // (create, update, transfer, delete, basically any change to the patients/clients collection)
  PRESCRIPTIONS_REFILLS_CUD = "t", //  doctor can CUD, (create, update, delete, refills...)
  CONSULTATIONS_UNLOCK = "u",
  VACCINATIONS_UPDATE_DATE = "v", // doctor can CUD
  CONSULTATION_DELETE_UPDATE_DATE = "B",

  // reports
  FINANCIAL_ACCESS = "w",
  MEDICATION_ACCESS = "x",
  CLIENTS_PATIENTS_ACCESS = "y",
  PRACTICE_MANAGEMENT_ACCESS = "z",
}

export const orgPermission = z.nativeEnum(OrgPermissions);

export type OrgPermission = z.infer<typeof orgPermission>;
