import { BoardingAppointment, tu } from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { View } from "./Boarding.view";

export const Container = () => {
  const db = useDBServices().boardingAppointmentsDBService;

  const [selectedDate, setSelectedDate] = useState(
    tu.getStartOfDate(new Date())
  );

  // TODO use undef, null, Appts[] and show loading, error...
  const [appts, setAppts] = useState<BoardingAppointment[]>([]);

  useEffect(() => {
    const getAppointmentsListenerUnsubscribe =
      db.getBoardingAppointmentsForWeekListener({
        callback: (a) =>
          setAppts(
            a.map((i) => ({
              ...i,
              from: tu.cleanTimeSecondsAndMillis(i.from),
              to: tu.cleanTimeSecondsAndMillis(i.to),
            })) // this is to keep things tidy in ui
          ),
        day: selectedDate,
      });

    return () => {
      getAppointmentsListenerUnsubscribe();
    };
  }, [selectedDate, db]);

  return (
    <View
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      appointments={appts}
    />
  );
};
