import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Members as ParentMembers } from "features/admin/members";
import { MemberRoles, Members } from "features/admin/members/components";
import {
  AddMemberRole,
  EditMemberRole,
} from "features/admin/members/components/MemberRoles/components";
import {
  AddMember,
  EditMember,
} from "features/admin/members/components/Members/components";
import { Navigate, Outlet, Route } from "react-router-dom";

export const MembersRoutes = (
  <Route path="members" element={<ParentMembers />}>
    <Route index element={<Navigate to="members" replace />} />

    <Route path="members" element={<Outlet />}>
      <Route index element={<Members />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEMBERS_CUD]}
            element={<AddMember />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEMBERS_CUD]}
            element={<EditMember />}
          />
        }
      />
    </Route>

    <Route path="member-roles" element={<Outlet />}>
      <Route index element={<MemberRoles />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.ROLES_CUD]}
            element={<AddMemberRole />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.ROLES_CUD]}
            element={<EditMemberRole />}
          />
        }
      />
    </Route>
  </Route>
);
