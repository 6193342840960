import {
  errorResult,
  normalizeVitalsEntry,
  Result,
  Source,
  successResult,
  tu,
  VitalsEntry,
  VitalsEntryData,
  VITALS_ENTRY_VERSION,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

// add organization consultation

interface AddVitalsEntry {
  ({
    db,
    organizationId,
    consultationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: VitalsEntryData;
  }): Promise<Result<VitalsEntry | null>>;
}

const addVitalsEntry: AddVitalsEntry = async ({
  db,
  organizationId,
  consultationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new consultation id
    const newRef = doc(
      collection(
        db,
        "organizations",
        organizationId,
        "consultations",
        consultationId,
        "vitals"
      )
    );

    const newObj: VitalsEntry = normalizeVitalsEntry({
      ...data,
      id: newRef.id,
      authorId,
      authorName,
      version: VITALS_ENTRY_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newRef, newObj);

    // t("VITALS_ENTRY_CREATED")
    const successMessage = "VITALS_ENTRY_CREATED";
    return successResult({
      message: successMessage,
      payload: newObj,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface UpdateVitalsEntry {
  ({
    db,
    organizationId,
    consultationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<VitalsEntryData>;
  }): Promise<Result<Partial<VitalsEntryData> | null>>;
}

const updateVitalsEntry: UpdateVitalsEntry = async ({
  db,
  organizationId,
  consultationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId,
      "vitals",
      id
    );

    const updates: Partial<VitalsEntry> = {
      ...data,
      authorId,
      authorName,
      version: VITALS_ENTRY_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("VITALS_ENTRY_UPDATED")
    const successMessage = "VITALS_ENTRY_UPDATED";

    return successResult({
      message: successMessage,
      payload: updates,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteVitalsEntry {
  ({
    db,
    organizationId,
    consultationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteVitalsEntry: DeleteVitalsEntry = async ({
  db,
  organizationId,
  consultationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId,
      "vitals",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("VITALS_ENTRY_DELETED")
      const successMessage = "VITALS_ENTRY_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// get organization vitals listener
interface GetVitalsEntrysListenerCallback {
  (vitals: VitalsEntry[]): void;
}
interface GetVitalsEntrysListener {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  callback: GetVitalsEntrysListenerCallback;
}

const getVitalsEntrysListener = ({
  db,
  organizationId,
  consultationId,
  callback,
}: GetVitalsEntrysListener): Unsubscribe => {
  try {
    // console.log("getVitalsEntrysListener: new listener");
    const newQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "consultations",
        consultationId,
        "vitals"
      ),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(newQuery, (querySnapshot) => {
      const vitals: VitalsEntry[] = [];
      querySnapshot.forEach((doc) => {
        try {
          vitals.push(normalizeVitalsEntry(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(vitals);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const vitals = ({
  authorId,
  authorName,
  consultationId,
  db,
  organizationId,
  source,
}: {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  authorId: string;
  authorName: string;
  source: Source;
}) => {
  return {
    getVitalsEntrysListener: (callback: GetVitalsEntrysListenerCallback) =>
      getVitalsEntrysListener({
        db,
        organizationId,
        consultationId,
        callback,
      }),
    addVitalsEntry: (data: VitalsEntryData) =>
      addVitalsEntry({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        data,
      }),
    updateVitalsEntry: (id: string, data: Partial<VitalsEntryData>) =>
      updateVitalsEntry({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteVitalsEntry: (id: string) =>
      deleteVitalsEntry({
        db,
        organizationId,
        consultationId,
        id,
      }),
  };
};
