import { DischargeInstructionForm } from "../DischargeInstructionForm";

export interface AddDischargeInstructionProps {
  consultationId: string;
  disabled: boolean;
}

export const AddDischargeInstruction = ({
  consultationId,
  disabled,
}: AddDischargeInstructionProps) => {
  return (
    <DischargeInstructionForm
      consultationId={consultationId}
      disabled={disabled}
    />
  );
};
