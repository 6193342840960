import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { documentTypesEnum } from "../../../common/documents";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { paymentMethodsEnum } from "../../../finance";

/**
 * schema and types versions
 * from first to last
 */

// v0
const appointmentCheckoutSubmissionDataV0Schema = z.object({
  checkoutTime: z.number().int(),
  invoices: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      amount: z.number().nonnegative(),
      date: z.number().int(),
    })
  ),
  credits: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      amount: z.number(),
      date: z.number().int(),
    })
  ),
  collected: z.number().nonnegative(),
  change: z.number(),
  paymentType: paymentMethodsEnum,
  saveChangeAsCredit: z.boolean(),
  emailDocuments: z.boolean(),
  printDocuments: z.boolean(),
  documents: z.array(
    z.object({
      type: documentTypesEnum,
      id: z.string(),
      name: z.string(),
    })
  ),
});

type AppointmentCheckoutSubmissionDataV0 = z.infer<
  typeof appointmentCheckoutSubmissionDataV0Schema
>;

const appointmentCheckoutSubmissionV0Schema = baseRecordSchema.merge(
  appointmentCheckoutSubmissionDataV0Schema
);

type AppointmentCheckoutSubmissionV0 = z.infer<
  typeof appointmentCheckoutSubmissionV0Schema
>;

const normalizeAppointmentCheckoutSubmissionV0 = (
  data: any
): AppointmentCheckoutSubmission => {
  try {
    const appointmentCheckoutSubmissionV0: AppointmentCheckoutSubmissionV0 =
      appointmentCheckoutSubmissionV0Schema.parse(data);

    const { ...rest } = appointmentCheckoutSubmissionV0;

    // here it's same object cause current v is 0
    const appointmentCheckoutSubmission: AppointmentCheckoutSubmission = {
      ...rest,
    };
    return appointmentCheckoutSubmission;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const APPOINTMENT_CHECKOUT_SUBMISSION_VERSION = "0";

export type AppointmentCheckoutSubmissionData =
  AppointmentCheckoutSubmissionDataV0;

export type AppointmentCheckoutSubmission = BaseRecord &
  AppointmentCheckoutSubmissionData;

export const appointmentCheckoutSubmissionDataSchema =
  appointmentCheckoutSubmissionDataV0Schema;

export const appointmentCheckoutSubmissionSchema =
  appointmentCheckoutSubmissionV0Schema;

export const normalizeAppointmentCheckoutSubmission = (
  data: any
): AppointmentCheckoutSubmission => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeAppointmentCheckoutSubmissionV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
