import { ActionIcon, Box, Center, Collapse, Group, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { Patient } from "beitary-shared";
import { BAdvancedRadioGroup, BBox } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddPatientWeightModal } from "../AddPatientWeightModal";
import { ListView } from "./components";
import { ChartView } from "./components/ChartView";

export interface PatientWeightsProps {
  patient: Patient;
}

export const PatientWeights = ({ patient }: PatientWeightsProps) => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState<string>("list");
  const [opened, setOpened] = useState(false);

  const weights = [...(patient.weights ?? [])].sort((a, b) => a.t - b.t);

  return (
    <BBox
      header={
        <Group position="apart">
          <Text>{t("WEIGHTS")}</Text>
          <Group>
            <AddPatientWeightModal patient={patient} />
            {weights.length > 0 && (
              <Group>
                <BAdvancedRadioGroup
                  data={[
                    {
                      label: <Center h={25}>{t("LIST")}</Center>,
                      value: "list",
                    },
                    {
                      label: <Center h={25}>{t("CHART")}</Center>,
                      value: "chart",
                    },
                  ]}
                  value={display}
                  onChange={(v) => setDisplay(v)}
                />
                <ActionIcon onClick={() => setOpened(!opened)}>
                  <IconChevronDown
                    style={{
                      transform: opened ? "rotate(180deg)" : "none",
                      transitionDuration: "200ms",
                    }}
                  />
                </ActionIcon>
              </Group>
            )}
          </Group>
        </Group>
      }
    >
      {weights.length > 0 && (
        <Collapse in={opened} transitionDuration={400}>
          <Box p="xl">
            {display === "list" ? (
              <ListView weights={weights} patient={patient} />
            ) : (
              <ChartView weights={weights} patientDOB={patient.dateOfBirth} />
            )}
          </Box>
        </Collapse>
      )}
    </BBox>
  );
};
