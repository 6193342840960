import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectPhysicalExamTemplates } from "features/admin/templates/Templates.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PhysicalExamTemplateForm } from "../PhysicalExamTemplateForm";

export const EditPhysicalExamTemplate = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const physicalExamTemplate = useAppSelector(selectPhysicalExamTemplates)
    .filter((physicalExamTemplate) => physicalExamTemplate.id === id)
    .at(0);

  if (!physicalExamTemplate) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return (
    <PhysicalExamTemplateForm physicalExamTemplate={physicalExamTemplate} />
  );
};
