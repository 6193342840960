import { Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconBodyScan, IconStack2 } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { obju, Product, PRODUCT_VERSION } from "beitary-shared";
import { BMultiSelect } from "components";
import {
  selectActiveBundles,
  selectActiveProducts,
  selectProductCategories,
} from "features/admin/catalog/Catalog.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ProductMultiSelectProps {
  required?: boolean;
  onChange?: (items: SelectedItem[]) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

type SelectedItem = {
  fixedPriceBundleId?: string;
  fixedPriceBundleName?: string;
  isFixedPriceBundle?: boolean;
  isIntegrationProduct?: boolean;
  code?: string;
  group?: string;
} & Product;

export const ProductMultiSelect = ({
  onChange,
  ...props
}: ProductMultiSelectProps) => {
  const { t } = useTranslation();
  const categories = useAppSelector(selectProductCategories);
  const products = useAppSelector(selectActiveProducts);
  const bundles = useAppSelector(selectActiveBundles);

  const data = products
    .map((o) => ({
      value: o.id,
      label: o.name,
      code: o.code,
      isBundle: false,
      isIntegrationProduct:
        o.integration && o.integrationProductId && o.integrationType
          ? true
          : false,
      group: t(categories.find((c) => c.id === o.categoryId)?.name ?? "OTHER"),
    }))
    .concat(
      bundles.map((o) => ({
        value: o.id,
        label: o.name,
        code: o.code,
        isBundle: true,
        group: t("BUNDLE"),
        isIntegrationProduct: false,
      }))
    );

  const onSelectedItemsChange = (value: string[] | undefined) => {
    const selectedBundles = bundles.filter((b) => value?.includes(b.id));
    const selectedProducts = products.filter((p) => value?.includes(p.id));

    const bundleProducts: SelectedItem[] = selectedBundles
      .map((b) => {
        const toReturn: SelectedItem[] = [];

        const {
          authorId,
          authorName,
          createdAt,
          id,
          lastUpdatedAt,
          name,
          taxRate,
          unitSellingPrice,
          categoryId,
          hasFixedPrice,
        } = b;
        if (hasFixedPrice && unitSellingPrice && categoryId) {
          const fpbProduct: SelectedItem = {
            authorId,
            authorName,
            categoryId,
            createdAt,
            id,
            lastUpdatedAt,
            name,
            source: "WEB",
            status: "ACTIVE",
            taxRate,
            type: "OTHER",
            unitSellingPrice,
            version: PRODUCT_VERSION,
            isFixedPriceBundle: true,
            fixedPriceBundleId: id,
            fixedPriceBundleName: name,
          };

          obju.removeUndefined(fpbProduct);
          toReturn.push(fpbProduct);
        }

        const bundleProducst = products
          .filter((p) => b.products.map((p) => p.id).includes(p.id))
          .map((p) => {
            const dose =
              p.dose ?? 1 * (b.products.find((pr) => pr.id === p.id)?.min ?? 1);

            let item: SelectedItem = {
              ...p,
              dose,
            };

            if (b.hasFixedPrice && b.id && b.name) {
              item["fixedPriceBundleId"] = b.id;
              item["fixedPriceBundleName"] = b.name;
            }
            obju.removeUndefined(item);
            return item;
          });
        toReturn.push(...bundleProducst);
        return toReturn;
      })
      .flat();

    const uniqueIds = new Set<string>();

    // Use the filter method to create a new array with unique IDs
    const uniqueArrayOfObjects = bundleProducts
      .concat(selectedProducts)
      .filter((obj) => {
        if (!uniqueIds.has(obj.id)) {
          uniqueIds.add(obj.id);
          return true;
        }
        return false;
      });
    console.log(uniqueArrayOfObjects);
    onChange && onChange(uniqueArrayOfObjects);
  };

  return (
    <BMultiSelect
      {...props}
      itemComponent={SelectItem}
      filter={(value, selected, item) =>
        !selected &&
        (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.code?.toLowerCase().includes(value.toLowerCase().trim()))
      }
      nothingFound={t("NOTHING_FOUND")}
      data={data}
      onChange={onSelectedItemsChange}
      limit={20}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  code?: string;
  isBundle?: boolean;
  isIntegrationProduct?: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  (
    { code, label, isBundle, isIntegrationProduct, ...others }: ItemProps,
    ref
  ) => (
    <div ref={ref} {...others}>
      <Stack>
        <div>
          <Group>
            <Text>{label}</Text>
            {isBundle && (
              <ThemeIcon color="gray" size="xs" variant="outline">
                <IconStack2 />
              </ThemeIcon>
            )}
            {isIntegrationProduct && (
              <ThemeIcon color="gray" size="xs" variant="outline">
                <IconBodyScan />
              </ThemeIcon>
            )}
          </Group>
          {code && (
            <Text size="xs" color="dimmed">
              {code}
            </Text>
          )}
        </div>
      </Stack>
    </div>
  )
);
