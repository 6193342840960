import {
  AppointmentRequest,
  AppointmentRequestData,
  APPOINTMENT_REQUEST_VERSION,
  errorResult,
  normalizeAppointmentRequest,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  where,
} from "firebase/firestore";

interface GetAppointmentRequest {
  ({ db, id }: { db: Firestore; id: string }): Promise<
    Result<AppointmentRequest | null>
  >;
}

const getAppointmentRequest: GetAppointmentRequest = async ({ db, id }) => {
  try {
    const dococRef = doc(db, "appointment_requests", id);
    const docSnapshot = await getDoc(dococRef);
    if (docSnapshot.exists()) {
      try {
        const data: unknown = docSnapshot.data();
        const appointmentRequest: AppointmentRequest =
          normalizeAppointmentRequest(data);
        const successMessage = "AppointmentRequest found!";
        return successResult({
          message: successMessage,
          payload: appointmentRequest,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "AppointmentRequest not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditAppointmentRequest {
  ({
    db,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: AppointmentRequestData;
  }): Promise<Result<AppointmentRequest | null>>;
}

const editAppointmentRequest: EditAppointmentRequest = async ({
  db,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    console.log(id);
    console.log(data);
    const appointmentRequest = (await getAppointmentRequest({ db, id }))
      .payload;

    if (!appointmentRequest) {
      const errorMessage = "AppointmentRequest not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = appointmentRequest;
      const updatedAppointmentRequest: AppointmentRequest =
        normalizeAppointmentRequest({
          ...data,
          id,
          authorId,
          authorName,
          version: APPOINTMENT_REQUEST_VERSION,
          source,
          createdAt,
          lastUpdatedAt: tu.getCurrentDateTime(),
        });

      const appointmentRequestRef = doc(db, "appointment_requests", id);

      await setDoc(appointmentRequestRef, updatedAppointmentRequest);

      const successMessage = "AppointmentRequest updated!";
      // console.log(successMessage, updatedAppointmentRequest);
      return successResult({
        message: successMessage,
        payload: updatedAppointmentRequest,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization appointmentRequests listener
interface GetAppointmentRequestsListenerCallback {
  (appointmentRequests: AppointmentRequest[]): void;
}
interface GetAppointmentRequestsListener {
  db: Firestore;
  organizationId: string;
  callback: GetAppointmentRequestsListenerCallback;
}

const getAppointmentRequestsListener = ({
  db,
  organizationId,
  callback,
}: GetAppointmentRequestsListener): Unsubscribe => {
  try {
    // console.log("getAppointmentRequestsListener: new listener");
    const appointmentRequestsQuery = query(
      collection(db, "appointment_requests"),
      where("organizationId", "==", organizationId),
      where("status", "==", "PENDING")
    );
    return onSnapshot(appointmentRequestsQuery, (querySnapshot) => {
      const appointmentRequests: AppointmentRequest[] = [];
      querySnapshot.forEach((doc) => {
        appointmentRequests.push(normalizeAppointmentRequest(doc.data()));
      });
      callback(appointmentRequests);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const appointmentRequests = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getAppointmentRequestsListener: (
      callback: GetAppointmentRequestsListenerCallback
    ) =>
      getAppointmentRequestsListener({
        db,
        organizationId,
        callback,
      }),
    acceptAppointmentRequest: (id: string, data: AppointmentRequestData) =>
      editAppointmentRequest({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          ...data,
          status: "ACCEPTED",
        },
      }),
    cancelAppointmentRequest: (id: string, data: AppointmentRequestData) =>
      editAppointmentRequest({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          ...data,
          status: "DECLINED",
        },
      }),
  };
};
