import { Box, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import { Appointment, id_util, tu } from "beitary-shared";
import { ReactNode, useMemo } from "react";
import { Column } from "./components";

export interface CalendarViewProps {
  dayAppointments: Appointment[];
  weekAppointments: Appointment[][];
  viewType: string;
  selectedDate: number;
  startTime: number;
  endTime: number;
}

export const CalendarView = ({
  dayAppointments,
  weekAppointments,
  viewType,
  selectedDate,
  startTime,
  endTime,
}: CalendarViewProps) => {
  const theme = useMantineTheme();

  const x = useMemo(() => (endTime - startTime) * 60, [startTime, endTime]);

  const getTimeColumn = () => {
    const items: ReactNode[] = [
      <Box
        h={80}
        w={80}
        sx={{
          border: `1px solid ${theme.colors.cyan[2]}`,
          justifyItems: "center",
        }}
      ></Box>,
    ];
    for (let i = 0; i < x; i = i + 30) {
      items.push(
        <Box
          key={id_util.newId20()}
          h={120}
          w={80}
          sx={{
            border: `1px solid ${theme.colors.cyan[2]}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text color="dimmed">
            {tu.getHoursAndMinutesString(
              tu.getDateValueAtHMSM(new Date(), startTime, 0, 0, 0).valueOf() +
                i * 1000 * 60
            )}
          </Text>
        </Box>
      );
    }
    return (
      <Stack spacing={0} w={80}>
        {items}
      </Stack>
    );
  };

  return (
    <Group miw={"100%"} bg="white" align="start" spacing={0}>
      {getTimeColumn()}
      {viewType === "day" ? (
        <Column
          appointments={dayAppointments}
          dateTime={selectedDate}
          nColumns={1}
          startTime={startTime}
          endTime={endTime}
        />
      ) : (
        weekAppointments.map((apts, i) => (
          <Column
            appointments={apts}
            nColumns={weekAppointments.length}
            dateTime={tu.getDateTime(
              tu.getDate(
                tu.getStartOfWeek(tu.getDate(selectedDate)) +
                  i * 24 * 60 * 60 * 1000
              )
            )}
            startTime={startTime}
            endTime={endTime}
          />
        ))
      )}
    </Group>
  );
};
