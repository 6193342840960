import { zodResolver } from "@mantine/form";
import { dischargeInstructionDataSchema } from "beitary-shared";
import { z } from "zod";

const dischargeInstructionFormSchema = dischargeInstructionDataSchema;

export type DischargeInstructionFormValues = z.infer<
  typeof dischargeInstructionFormSchema
>;

export const rules = zodResolver(dischargeInstructionFormSchema);
