import { z } from "zod";
import { sourceEnum } from "../../../types-schemas/common";
import { UnableToNormalizeData } from "../UnableToNormalizeData";

export const errorLogSchema = z.object({
  id: z.string(),
  error: z.string(),
  componentStack: z.string().optional(),
  data: z.any().optional(),
  time: z.number(),
  source: sourceEnum,
  appVersion: z.string(),
});

export type ErrorLog = z.infer<typeof errorLogSchema>;

export const normalizeErrorLog = (data: any): ErrorLog => {
  try {
    return errorLogSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};
