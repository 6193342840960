import { Card, Text, useMantineTheme } from "@mantine/core";

export interface ConsultationNotesCardProps {
  notes: string;
}

export const ConsultationNotesCard = ({
  notes,
}: ConsultationNotesCardProps) => {
  const theme = useMantineTheme();
  return (
    <Card
      mt="xl"
      bg="red.1"
      sx={{
        borderLeft: `10px solid ${theme.colors.red[5]}`,
      }}
    >
      {notes.split(/[\r\n]+/).map((s, i) => (
        <Text key={i}>{s}</Text>
      ))}
    </Card>
  );
};
