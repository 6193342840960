import { MantineThemeOverride } from "@mantine/core";

export const mainTheme: MantineThemeOverride = {
  // colorScheme: 'light',
  // colors: {
  //   // Add your color
  //   'deep-blue': ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
  //   // or replace default theme color
  //   blue: ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
  // },
  // shadows: {
  //   // other shadows (xs, sm, lg) will be merged from default theme
  //   md: '1px 1px 3px rgba(0,0,0,.25)',
  //   xl: '5px 5px 3px rgba(0,0,0,.25)',
  // },
  // headings: {
  //   fontFamily: 'Roboto, sans-serif',
  //   sizes: {
  //     h1: { fontSize: 30 },
  //   },
  // },
  colorScheme: "light",
  colors: {
    light_blue: [
      "#FFFFFF",
      "#FDFDFF",
      "#FBFBFF",
      "#F9F9FF",
      "#F7F7FF",
      "#F5F5FF",
      "#F3F3FF",
      "#F1F1FF",
      "#EFEFFF",
      "#EDEDFD",
    ],
  },
  primaryColor: "cyan",
};

export const mainThemeMobile: MantineThemeOverride = {
  // colorScheme: 'light',
  // colors: {
  //   // Add your color
  //   'deep-blue': ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
  //   // or replace default theme color
  //   blue: ['#E9EDFC', '#C1CCF6', '#99ABF0' /* ... */],
  // },
  // shadows: {
  //   // other shadows (xs, sm, lg) will be merged from default theme
  //   md: '1px 1px 3px rgba(0,0,0,.25)',
  //   xl: '5px 5px 3px rgba(0,0,0,.25)',
  // },
  // headings: {
  //   fontFamily: 'Roboto, sans-serif',
  //   sizes: {
  //     h1: { fontSize: 30 },
  //   },
  // },
  colorScheme: "light",
  colors: {
    light_blue: [
      "#FFFFFF",
      "#FDFDFF",
      "#FBFBFF",
      "#F9F9FF",
      "#F7F7FF",
      "#F5F5FF",
      "#F3F3FF",
      "#F1F1FF",
      "#EFEFFF",
      "#EDEDFD",
    ],
  },
  primaryColor: "cyan",
  fontSizes: {
    xs: "0.5rem",
    sm: "0.625rem",
    md: "0.75rem",
    lg: "0.875rem",
    xl: "1rem",
  },
  headings: {
    // properties for all headings
    // fontWeight: 400,
    // fontFamily: "Roboto",

    // properties for individual headings, all of them are optional
    sizes: {
      h1: { fontSize: "1.5rem", lineHeight: 1.25 },
      h2: { fontSize: "1.25rem", lineHeight: 1.25 },
      h3: { fontSize: "1.125rem", lineHeight: 1.25 },
      h4: { fontSize: "1rem", lineHeight: 1.5 },
      h5: { fontSize: "0.875rem", lineHeight: 1.5 },
      h6: { fontSize: "0.75rem", lineHeight: 1.5 },
    },
  },
  components: {
    Button: {
      defaultProps: {
        size: "xs",
      },
    },
    TextInput: {
      defaultProps: {
        size: "xs",
      },
    },
    Textarea: {
      defaultProps: {
        size: "xs",
      },
    },
    NumberInput: {
      defaultProps: {
        size: "xs",
      },
    },
    PasswordInput: {
      defaultProps: {
        size: "xs",
      },
    },
    Select: {
      defaultProps: {
        size: "xs",
      },
    },
    MultiSelect: {
      defaultProps: {
        size: "xs",
      },
    },
    Badge: {
      defaultProps: {
        size: "xs",
      },
    },
  },
};
