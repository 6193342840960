import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Diagnosis } from "beitary-shared";
interface DiagnosesState {
  diagnoses: Diagnosis[];
}

const initialState: DiagnosesState = {
  diagnoses: [],
};

export const diagnosesSlice = createSlice({
  name: "diagnoses",
  initialState,
  reducers: {
    setDiagnoses: (state, action: PayloadAction<Diagnosis[]>) => {
      state.diagnoses = action.payload;
    },
  },
});

export const { setDiagnoses } = diagnosesSlice.actions;

export const selectDiagnoses = (state: RootState) => state.diagnoses.diagnoses;

export const selectActiveDiagnoses = (state: RootState) =>
  state.diagnoses.diagnoses.filter((d) => d.status === "ACTIVE");

export const diagnosesReducer = diagnosesSlice.reducer;
