import { showNotification, updateNotification } from "@mantine/notifications";
import {
  AccountCustomClaims,
  errorNotification,
  loadingInfoNotification,
  Result,
  successNotification,
} from "beitary-shared";
import { t } from "i18next";
import { AuthService } from "services/auth/AuthService";

export interface AuthAPISignIn {
  email: string;
  password: string;
}

const signIn = async ({ email, password }: AuthAPISignIn): Promise<void> => {
  const notificationId = "signin";
  showNotification(
    loadingInfoNotification({
      id: notificationId,
      message: t("Waiting for server response"),
      title: "Signing in",
    })
  );
  var result = await AuthService.signIn(email, password);
  if (result.type === "success") {
    updateNotification({
      id: notificationId,
      ...successNotification({
        id: notificationId,
        message: `Welcome back, ${result.payload}!`,
        title: "Signed in!",
      }),
    });
  } else if (result.type === "error") {
    updateNotification({
      id: notificationId,
      ...errorNotification({
        id: notificationId,
        message: result.message,
        title: "Error!",
      }),
    });
  }
};

const signInWithToken = async (
  token: string
): Promise<Result<(AccountCustomClaims & { name: string }) | null>> => {
  return await AuthService.signInWithToken(token);
};

const signOut = async (): Promise<Result<boolean | null>> => {
  return await AuthService.signOut();
};

const authListener = AuthService.authListener;

const sendEmailVerifLink = AuthService.sendEmailVerifLink;

export const AuthenticationAPI = {
  signIn,
  signOut,
  authListener,
  sendEmailVerifLink,
  signInWithToken,
};
