import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
import { genericStatusEnum } from "../../common/status/GenericStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const appointmentTypeDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  color: z.string(),
  duration: z.number().positive().int(),
});

type AppointmentTypeDataV0 = z.infer<typeof appointmentTypeDataV0Schema>;

const appointmentTypeV0Schema = baseRecordSchema.merge(
  appointmentTypeDataV0Schema
);

type AppointmentTypeV0 = z.infer<typeof appointmentTypeV0Schema>;

const normalizeAppointmentTypeV0 = (data: any): AppointmentType => {
  try {
    const appointmentTypeV0: AppointmentTypeV0 =
      appointmentTypeV0Schema.parse(data);

    const { ...rest } = appointmentTypeV0;

    // here it's same object cause current v is 0
    const appointmentType: AppointmentType = {
      ...rest,
    };
    return appointmentType;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const APPOINTMENT_TYPE_VERSION = "0";

export type AppointmentTypeData = AppointmentTypeDataV0;

export type AppointmentType = BaseRecord & AppointmentTypeData;

export const appointmentTypeDataSchema = appointmentTypeDataV0Schema;

export const appointmentTypeSchema = appointmentTypeV0Schema;

export const normalizeAppointmentType = (data: any): AppointmentType => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeAppointmentTypeV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
