import { errorResult, Result, Source, successResult } from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";
import {
  ReportQueryBody,
  ReportQueryBodyWithDates,
  ReportQueryResponse,
} from "./interfaces";

interface GetReport<T, V> {
  (props: {
    callable: typeof httpsCallable<T, V>;
    fcts: Functions;
    queryProps: T;
  }): Promise<Result<V | null>>;
}

const clientsCounts: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const report_clientsCountsOnCall = callable(
    fcts,
    "callable-reports_clientsCounts"
  );
  try {
    const r = await report_clientsCountsOnCall(queryProps);

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const patientsCounts: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const report_patientsCountsOnCall = callable(
    fcts,
    "callable-reports_patientsCounts"
  );
  try {
    const r = await report_patientsCountsOnCall(queryProps);

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const invoiceLineItemsReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_invoiceLineItems");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const invoicesSummaryReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_invoices");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const accountsReceivableReport: GetReport<
  ReportQueryBody,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_accountsReveivable");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const consultationsReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_consultations");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const controlledDrugsReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_controlledDrugs");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const financesSummaryPerClientReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_financesSummaryPerClient");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const inventoryItemsBelowOrAtReorderPointReport: GetReport<
  ReportQueryBody,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(
    fcts,
    "callable-reports_inventoryItemsBelowOrAtReorderPoint"
  );
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const inventoryItemsReport: GetReport<
  ReportQueryBody,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_inventoryItems");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const inventoryTransactionsByItemByType: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(
    fcts,
    "callable-reports_inventoryTransactionsByItemByType"
  );
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const paymentsReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_payments");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

///
const remindersSnapshotsReport: GetReport<
  ReportQueryBody,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_remindersSnapshots");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const treatmentSnapshotsReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_treatmentSnapshots");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};
const treatmentsAdministredReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_treatmentsAdministered");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};
const treatmentSnapshotsByProductReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_treatmentSnapshotsByProduct");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};
const vaccinesAdministredReport: GetReport<
  ReportQueryBodyWithDates,
  ReportQueryResponse
> = async ({ queryProps, callable, fcts }) => {
  const onCall = callable(fcts, "callable-reports_vaccinesAdministred");
  try {
    const r = await onCall({
      ...queryProps,
    });

    console.log(r);

    return successResult({
      message: "success",
      payload: { data: JSON.parse(r.data.data) },
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

export const reports = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    clientsCounts: (args: { start: number; end: number }) =>
      clientsCounts({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    patientsCounts: (args: { start: number; end: number }) =>
      patientsCounts({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    invoiceLineItemsReport: (args: { start: number; end: number }) =>
      invoiceLineItemsReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    invoicesSummaryReport: (args: { start: number; end: number }) =>
      invoicesSummaryReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    accountsReceivableReport: () =>
      accountsReceivableReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
        },
      }),
    consultationsReport: (args: { start: number; end: number }) =>
      consultationsReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    controlledDrugsReport: (args: { start: number; end: number }) =>
      controlledDrugsReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    financesSummaryPerClientReport: (args: { start: number; end: number }) =>
      financesSummaryPerClientReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    inventoryItemsBelowOrAtReorderPointReport: () =>
      inventoryItemsBelowOrAtReorderPointReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
        },
      }),
    inventoryItemsReport: () =>
      inventoryItemsReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
        },
      }),
    inventoryTransactionsByItemByType: (args: { start: number; end: number }) =>
      inventoryTransactionsByItemByType({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    paymentsReport: (args: { start: number; end: number }) =>
      paymentsReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),

    remindersSnapshotsReport: () =>
      remindersSnapshotsReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
        },
      }),
    treatmentSnapshotsReport: (args: { start: number; end: number }) =>
      treatmentSnapshotsReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    treatmentsAdministredReport: (args: { start: number; end: number }) =>
      treatmentsAdministredReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    treatmentSnapshotsByProductReport: (args: { start: number; end: number }) =>
      treatmentSnapshotsByProductReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
    vaccinesAdministredReport: (args: { start: number; end: number }) =>
      vaccinesAdministredReport({
        callable,
        fcts,
        queryProps: {
          organizationId,
          ...args,
        },
      }),
  };
};
