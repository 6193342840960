import { Container, Tabs, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const View = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("catalog/bundles")
    ? "bundles"
    : path.includes("catalog/imaging-integration-products")
    ? "imaging-integration-products"
    : "products";

  return (
    <Container fluid>
      <Title mt="xl" weight={500} order={3}>
        {t("CATALOG")}
      </Title>
      <Tabs
        mt="xl"
        value={activeTab}
        onTabChange={(value) => {
          navigate(`${value}`);
        }}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value="products">{t("PRODUCTS")}</Tabs.Tab>
          <Tabs.Tab value="bundles">{t("BUNDLES")}</Tabs.Tab>
          <Tabs.Tab value="imaging-integration-products">
            {t("IMAGING_INTEGRATION_PRODUCTS")}
          </Tabs.Tab>
        </Tabs.List>
        <Outlet />
      </Tabs>
    </Container>
  );
};
