import { Button, Modal } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReminderSnapshotForm } from "../ReminderSnapshotForm";

export interface ReminderSnapshotModalProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
}

export const ReminderSnapshotModal = (props: ReminderSnapshotModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("NEW_REMINDER")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <ReminderSnapshotForm {...props} />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("ADD_REMINDER")}</Button>
    </>
  );
};
