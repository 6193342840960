import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Image,
  Progress,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
} from "@mantine/core";
import { FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import {
  IconCircleCheck,
  IconDownload,
  IconExternalLink,
  IconTrash,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { BFileCategory } from "beitary-shared";
import {
  BBox,
  BDropzone,
  BSimpleDICOMViewerModal,
  DeleteConfirmationModal,
} from "components";
import { selectActiveConsultationFiles } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useUploadFile } from "hooks/useUploadFile";
import { BCan } from "permissions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface DocumentsPanelProps {
  title: string;
  clientId: string;
  patientId: string;
  consultationId: string;
  filesCategory: BFileCategory;
}

// TODO fix me!!
export const DocumentsPanel = ({
  title,
  clientId,
  patientId,
  filesCategory,
  consultationId,
}: DocumentsPanelProps) => {
  const { t } = useTranslation();

  const panelFiles = useAppSelector(selectActiveConsultationFiles).filter(
    (f) => f.fileCategory === filesCategory
  );

  const { deleteBFile } = useDBServices().filesDBService;

  const [includeInMedicalRecord, setIncludeInMedicalRecord] =
    useState<boolean>(true);
  const [comment, setComment] = useState<string | undefined>();

  const [file, setFile] = useState<FileWithPath | undefined>();

  console.log(file);

  const [{ fileProgress, submitFile, submitFileState, fileReady, clearFile }] =
    useUploadFile({
      filesCategory,
      clientId,
      comment,
      consultationId,
      includeInMedicalRecord,
      shareWithClient: includeInMedicalRecord,
      patientId,
    });

  useEffect(() => {
    if (file) {
      fileReady();
    } else {
      clearFile();
    }
  }, [clearFile, file, fileReady]);

  return (
    <BBox header={<Text weight={500}>{title}</Text>}>
      <Group grow p="xl" align="flex-start">
        <Stack>
          <BDropzone
            line1={t("DZ_LINE_1")}
            line2={t("DZ_LINE_@")}
            dzProps={{
              accept: [
                // docs
                MIME_TYPES.csv,
                MIME_TYPES.docx,
                MIME_TYPES.xlsx,
                MIME_TYPES.pdf,
                MIME_TYPES.doc,
                MIME_TYPES.xls,
                MIME_TYPES.docx,
                MIME_TYPES.ppt,
                MIME_TYPES.pptx,
                // img
                MIME_TYPES.png,
                MIME_TYPES.jpeg,
                MIME_TYPES.webp,
                MIME_TYPES.avif,
                MIME_TYPES.gif,
                // vid
                MIME_TYPES.mp4,
                // other
                MIME_TYPES.zip,
                "application/x-zip-compressed",
              ],
              onDrop: (files) => {
                const f = files.at(0);
                if (f) {
                  setFile(f);
                  //fileReady();
                } else {
                  setFile(undefined);
                  // clearFile();
                }
              },

              loading: submitFileState === "pending-response",
              maxFiles: 1,
              // maxSize: 10 * 1024 * 1024,
            }}
          />
          <Group grow>
            {file && fileProgress && (
              <Stack>
                <Card withBorder>
                  <Group position="apart">
                    <Text w={240} truncate>
                      {file.name}
                    </Text>
                    <Text w={80}>{`${(file.size / (1024 * 1024)).toFixed(
                      2
                    )} mb`}</Text>
                    <Box w={160}>
                      {(fileProgress.status === "in-progress" ||
                        fileProgress.status === "success") && (
                        <Progress
                          value={fileProgress.progressRate}
                          striped
                          animate={fileProgress.status === "in-progress"}
                          w={150}
                        />
                      )}
                    </Box>
                    <Box w={30}>
                      {fileProgress.status === "success" && (
                        <ThemeIcon color="green" size={24} radius="xl">
                          <IconCircleCheck size={16} />
                        </ThemeIcon>
                      )}
                      {fileProgress.status === "ready" && (
                        <ActionIcon
                          onClick={() => {
                            setFile(undefined);
                            // clearFile();
                          }}
                        >
                          <IconTrash size={18} />
                        </ActionIcon>
                      )}
                    </Box>
                  </Group>
                  <Group grow mt="xl" mb="xl">
                    <Textarea
                      label={t("COMMENT")}
                      onChange={(e) => {
                        setComment(e.currentTarget.value);
                      }}
                      value={comment}
                    />
                  </Group>
                  <Checkbox
                    label={t("INCLUDE_IN_MEDICAL_RECORD")}
                    onChange={(e) => {
                      setIncludeInMedicalRecord(e.currentTarget.checked);
                    }}
                    checked={includeInMedicalRecord}
                  />
                </Card>
                <Group position="right">
                  {fileProgress.status === "ready" && (
                    <Button onClick={() => submitFile(file)}>
                      {t("UPLOAD_FILE")}
                    </Button>
                  )}
                </Group>
              </Stack>
            )}
          </Group>

          {panelFiles.map((file) => (
            <Card withBorder>
              <Group position="apart">
                <Group position="left">
                  <Text w={240} truncate>
                    {file.originalName}
                  </Text>
                  <Text w={80}>{`${(
                    Number(file.fileSize) /
                    (1024 * 1024)
                  ).toFixed(2)} mb`}</Text>
                  <Text truncate>{file.comment}</Text>
                </Group>
                <Group>
                  <BCan I="delete" this={{ ...file, _typeof: "BFile" }}>
                    <DeleteConfirmationModal
                      f={async () => deleteBFile(file.id)}
                      item={{
                        id: file.id,
                        name: file.originalName,
                      }}
                    />
                  </BCan>
                  <Anchor
                    color="dark"
                    weight={500}
                    href={file.dlURL}
                    target="_blank"
                  >
                    {file.isDICOM && file.dlURL ? (
                      <IconDownload size={12} />
                    ) : (
                      <IconExternalLink size={12} />
                    )}
                  </Anchor>
                  {file.isDICOM && file.dlURL && (
                    <BSimpleDICOMViewerModal file={file} fileId={file.id} />
                  )}
                </Group>
              </Group>
              {file.thumbURL && (
                <Image
                  p="xl"
                  maw={300}
                  mx="auto"
                  radius="md"
                  src={file.thumbURL}
                />
              )}
            </Card>
          ))}
        </Stack>
        {/* <Stack>
          <Input.Wrapper label={t("NOTES")}>
            <BTextEditor />
          </Input.Wrapper>
          <Group position="right">
            <Button
              // onClick={addNote}
              // disabled={note.length === 0 || note === "<p></p>"}
              // loading={addNoteState === "pending-response"}
              leftIcon={<IconPlus size={18} />}
            >
              {t("ADD_NOTE")}
            </Button>
          </Group>
        </Stack> */}
      </Group>
    </BBox>
  );
};
