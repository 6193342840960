import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { ScheduleSettings } from "features/admin/schedule-settings";
import {
  AppointmentTypes,
  BoardingResources,
  ServiceProviders,
} from "features/admin/schedule-settings/components";
import {
  AddAppointmentType,
  EditAppointmentType,
} from "features/admin/schedule-settings/components/AppointmentTypes/components";
import {
  AddBoardingResource,
  EditBoardingResource,
} from "features/admin/schedule-settings/components/BoardingResources/components";
import { AddServiceProvider } from "features/admin/schedule-settings/components/ServiceProviders/components/AddServiceProvider";
import { EditServiceProvider } from "features/admin/schedule-settings/components/ServiceProviders/components/EditServiceProvider";
import { Navigate, Outlet, Route } from "react-router-dom";

export const ScheduleSettingsRoutes = (
  <Route path="schedule-settings" element={<ScheduleSettings />}>
    <Route index element={<Navigate to="appointment-types" replace />} />

    <Route path="appointment-types" element={<Outlet />}>
      <Route index element={<AppointmentTypes />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<AddAppointmentType />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<EditAppointmentType />}
          />
        }
      />
    </Route>

    <Route path="service-providers" element={<Outlet />}>
      <Route index element={<ServiceProviders />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<AddServiceProvider />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<EditServiceProvider />}
          />
        }
      />
    </Route>

    <Route path="boarding-resources" element={<Outlet />}>
      <Route index element={<BoardingResources />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<AddBoardingResource />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<EditBoardingResource />}
          />
        }
      />
    </Route>

    {/* <Route path="email-templates" element={<Outlet />}>
      <Route index element={<EmailAppointmentNotificationTemplates />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<AddEmailAppointmentNotificationTemplate />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<EditEmailAppointmentNotificationTemplate />}
          />
        }
      />
    </Route>

    <Route path="sms-templates" element={<Outlet />}>
      <Route index element={<SMSAppointmentNotificationTemplates />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<AddSMSAppointmentNotificationTemplate />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.SCHEDULE_CUD]}
            element={<EditSMSAppointmentNotificationTemplate />}
          />
        }
      />
    </Route> */}
  </Route>
);
