import { zodResolver } from "@mantine/form";
import { serviceProviderDataSchema } from "beitary-shared";
import { z } from "zod";

const serviceProviderFormSchema = serviceProviderDataSchema;

export type ServiceProviderFormValues = z.infer<
  typeof serviceProviderFormSchema
>;

export const rules = zodResolver(serviceProviderFormSchema);
