import { NavLink, Paper, SimpleGrid, Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface ReportsProps {}

export const Reports = () => {
  const { t } = useTranslation();

  const links: { label: string; link: string }[] = [
    {
      label: t("END_OF_DAY_REPORT"),
      link: "end-of-day-report",
    },
    {
      label: t("CLIENTS_PATIENTS_REPORT"),
      link: "clients-patients-report",
    },
    {
      label: t("INVOICES_SUMMARY_REPORT"),
      link: "invoices-summary-report",
    },
    {
      label: t("ACCOUNTS_RECEIVABLE_REPORT"),
      link: "accounts-receivable-report",
    },
    {
      label: t("CONSULTATIONS_REPORT"),
      link: "consultations-report",
    },
    {
      label: t("FINANCES_SUMMARY_PER_CLIENT_REPORT"),
      link: "finances-summary-per-client-report",
    },
    {
      label: t("INVENTORY_ITEMS_REPORT"),
      link: "inventory-items-report",
    },
    {
      label: t("CONTROLLED_DRUGS_REPORT"),
      link: "controlled-drugs-report",
    },
    {
      label: t("INVENTORY_ITEMS_BELOW_OR_AT_REORDER_POINT_REPORT"),
      link: "inventory-items-reporder-point-report",
    },
    {
      label: t("INVENTORY_ITEMS_USAGE_REPORT"),
      link: "inventory-items-usage-report",
    },
    {
      label: t("PRODUCTS_REPORT"),
      link: "products-report",
    },
    {
      label: t("PRODUCTS_USAGE_REPORT"),
      link: "products-usage-report",
    },
    {
      label: t("PAYMENTS_REPORT"),
      link: "payments-report",
    },
    {
      label: t("REMINDERS_REPORT"),
      link: "reminders-report",
    },
    {
      label: t("INVOICE_LINE_ITEMS_REPORT"),
      link: "invoice-line-items-report",
    },
    {
      label: t("VACCINE_REPORT"),
      link: "vaccine-report",
    },
  ];

  return (
    <Stack>
      <SimpleGrid cols={2}>
        {links.map((l, index) => (
          <Paper key={index}>
            <NavLink
              p="md"
              component={Link}
              label={t(l.label)}
              to={l.link}
              key={l.label}
            />
          </Paper>
        ))}
      </SimpleGrid>
    </Stack>
  );
};
