import { useAppSelector } from "app/hooks";
import { selectIsLocked } from "features/Authentication/AuthenticationSlice";
import { SigninPinForm } from "features/Authentication/components";
import { ReactNode } from "react";

interface AppWrapperProps {
  children: ReactNode;
}

// use this for all app-level listeners... Needs user to be authenticated
// TODO might need a wrapper for each api? to avoid refetching all if only one changed??
export const AppScreenLock = ({ children }: AppWrapperProps) => {
  const isLcoked = useAppSelector(selectIsLocked);

  if (isLcoked) return <SigninPinForm />;

  return <>{children}</>;
};
