import {
  Anchor,
  Avatar,
  Badge,
  createStyles,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Appointment, tu } from "beitary-shared";
import { BBox, TableWithSearchAndSort } from "components";
import {
  selectActiveAppointmentTypes,
  selectActiveServiceProviders,
} from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EditAppointmentModal } from "../EditAppointmentModal";

type AppointmentsTableItem = Appointment & {
  patientClientName: string;
  date: string;
  aptTypeColor: string;
  servProvColor: string;
};

const useStyles = createStyles((theme) => ({
  tr: {
    // backgroundColor: theme.white,
    // borderRadius: theme.radius.xs,
    // margin: theme.spacing.xl,
  },
}));

interface AppointmentsTableProps {
  appointments: Appointment[];
}

export const AppointmentsTable = ({ appointments }: AppointmentsTableProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const appointmentTypes = useAppSelector(selectActiveAppointmentTypes);
  const serviceProviders = useAppSelector(selectActiveServiceProviders);

  const tableAppointments: AppointmentsTableItem[] = appointments.map(
    (appointment) => ({
      ...appointment,
      patientClientName: appointment.patientName + appointment.clientName,
      date: `${tu.getDateAndTimeString(
        appointment.time
      )} - ${tu.getDateAndTimeString(
        appointment.time + appointment.duration * 60 * 1000
      )}`,
      aptTypeColor:
        appointmentTypes.find((t) => t.id === appointment.appointmentTypeId)
          ?.color ?? "white",
      servProvColor:
        serviceProviders.find((p) => p.id === appointment.serviceProviderId)
          ?.color ?? "white",
    })
  );

  const fields: {
    key: keyof AppointmentsTableItem;
    label: string;
  }[] = [
    { key: "date", label: t("TIME") },
    { key: "patientClientName", label: t("PATIENT") },
    { key: "status", label: t("STATUS") },
    { key: "serviceProviderName", label: t("SERVICE_PROVIDER") },
    { key: "reasonForConsultation", label: t("REASON_FOR_CONSULTATION") },
  ];

  const getRow = (appointment: AppointmentsTableItem) => (
    <tr key={appointment.id} className={classes.tr}>
      <td>
        <Group
          sx={{
            borderLeft: `6px solid ${appointment.aptTypeColor}`,
            borderRadius: 4,
          }}
        >
          <Stack ml="xs" align="center" spacing={0}>
            <Text>{`${tu.getHoursAndMinutesString(appointment.time)}`}</Text>
            <Text>{"|"}</Text>
            <Text>{`${tu.getHoursAndMinutesString(
              appointment.time + appointment.duration * 60 * 1000
            )}`}</Text>
          </Stack>
        </Group>
      </td>
      <td>
        <Group>
          <Avatar src={appointment.patientPictureURL} size={50} radius="xl">
            <IconPaw size={30} />
          </Avatar>
          <div>
            <Anchor
              color="dark"
              weight={500}
              lineClamp={1}
              component={Link}
              to={`/clients/${appointment.clientId}/patients/${appointment.patientId}`}
            >
              {appointment.patientName} {<IconExternalLink size={12} />}
            </Anchor>
            <Anchor
              color="dark"
              weight={400}
              lineClamp={1}
              component={Link}
              to={`/clients/${appointment.clientId}`}
            >
              {appointment.clientName} {<IconExternalLink size={12} />}
            </Anchor>
          </div>
        </Group>
      </td>
      <td>
        <Group>
          <Badge size="lg">{t(appointment.status + "_APT")}</Badge>
        </Group>
      </td>
      <td>
        <Badge c={appointment.servProvColor}>
          <Text>{appointment.serviceProviderName}</Text>
        </Badge>
      </td>
      <td>
        <Text>{appointment.reasonForConsultation}</Text>
      </td>
      <td>
        <EditAppointmentModal appointment={appointment} />
      </td>
    </tr>
  );
  return (
    <BBox p="xl" bgcs={0}>
      <TableWithSearchAndSort
        data={tableAppointments}
        fields={fields}
        getRow={getRow}
        hasControlsColumn
      />
    </BBox>
  );
};
