import { Button, Group, Stack, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { InvoiceLineItem } from "beitary-shared";
import { BBox } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InvoiceLineItemsTable } from "../InvoiceLineItemsTable";

export interface ConsultationChargesPanelProps {
  charges: InvoiceLineItem[];
  disabled: boolean;
}

export const ConsultationChargesPanel = ({
  charges,
  disabled,
}: ConsultationChargesPanelProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (charges.length === 0) return <Text>{t("NOTHING_FOUND")}</Text>;

  const patientName = charges.find((i) => !!i.patientName)?.patientName;

  const bundleCharges = charges
    .filter((c) => c.bundleId)
    .sort((a, b) => (b.createdAt < a.createdAt ? 1 : -1))
    .sort((a, b) => b.bundleId?.localeCompare(a.bundleId ?? "") ?? 0);

  const nonBundleCharges = charges.filter((c) => !c.bundleId);

  const treatments = nonBundleCharges.filter(
    (c) => c.parentType === "TREATMENT"
  );
  const vaccines = nonBundleCharges.filter((c) => c.parentType === "VACCINE");
  const prescriptions = nonBundleCharges.filter(
    (c) => c.parentType === "PRESCRIPTION"
  );
  const services = nonBundleCharges.filter((c) => c.parentType === "SERVICE");

  return (
    <BBox
      header={
        <Group position="apart">
          <Text>
            {t("CHARGES_FROM")}{" "}
            <Text span color="cyan">
              {patientName ?? ""}
            </Text>
          </Text>
          <Button
            leftIcon={<IconExternalLink size={16} />}
            onClick={() =>
              navigate(
                `/consultations/${charges.at(0)?.consultationId}/charges`
              )
            }
          >
            {t("VIEW_CONSULTATION")}
          </Button>
        </Group>
      }
    >
      <Stack p="xl">
        {bundleCharges.length > 0 && (
          <InvoiceLineItemsTable
            disabled={disabled}
            key={`bundles`}
            charges={bundleCharges}
            title={t("BUNDLES")}
          />
        )}
        {treatments.length > 0 && (
          <InvoiceLineItemsTable
            disabled={disabled}
            key={`treatments`}
            charges={treatments}
            title={t("TREATMENTS")}
          />
        )}
        {vaccines.length > 0 && (
          <InvoiceLineItemsTable
            disabled={disabled}
            key={`vaccines`}
            charges={vaccines}
            title={t("VACCINES")}
          />
        )}
        {services.length > 0 && (
          <InvoiceLineItemsTable
            disabled={disabled}
            key={`services`}
            charges={services}
            title={t("SERVICES")}
          />
        )}
        {prescriptions.length > 0 && (
          <InvoiceLineItemsTable
            disabled={disabled}
            key={`prescriptions`}
            charges={prescriptions}
            title={t("PRESCRIPTIONS")}
          />
        )}
      </Stack>
    </BBox>
  );
};
