import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  IconEdit,
  IconHandStop,
  IconStack2,
  IconVaccine,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Treatment, tu } from "beitary-shared";
import { AsyncActionIcon, BBox, BNotFound } from "components";
import { selectConsTxs } from "features/whiteboard/Whiteboard.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export interface ConsultationTreatmentsProps {}

export type ConsultationTreatmentsItem = Treatment & {
  category: "STAT" | "PRN" | "OVERDUE" | "CURRENT" | "UPCOMING";
};

export const ConsultationTreatments = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const consTx = useAppSelector(selectConsTxs).find((c) => c.id === id);
  const navigate = useNavigate();
  const treatmentsDBService = useDBServices().consultationsDBService.treatments;

  const [refreshCount, setRefreshCount] = useState(0);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      // Update state to trigger a component refresh
      setRefreshCount((prevCount) => prevCount + 1);
    }, 1 * 60 * 1000); // Refresh every 1 minutes (1 * 60 seconds * 1000 milliseconds)

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(refreshInterval);
    };
  }, []);
  console.log(refreshCount);
  if (!consTx) return <BNotFound />;

  const administerTreatment = async (id: string) => {
    if (disabled) return;
    await treatmentsDBService(consTx.id).administerTreatment(id);
  };

  const stopTreatment = async (id: string) => {
    if (disabled) return;
    await treatmentsDBService(consTx.id).stopTreatment(id);
  };

  const stopFPBTreatments = async (id: string) => {
    if (disabled) return;

    const txIds = [...consTx.PRN, ...consTx.STAT, ...consTx.SCHEDULED]
      .filter((t) => t.fixedPriceBundleId === id)
      .map((t) => t.id);

    await treatmentsDBService(consTx.id).stopTreatments(txIds);
    return;
  };

  const administerFPBTreatments = async (id: string) => {
    if (disabled) return;
    const txIds = [...consTx.PRN, ...consTx.STAT, ...consTx.SCHEDULED]
      .filter((t) => t.fixedPriceBundleId === id)
      .map((t) => t.id);
    await treatmentsDBService(consTx.id).administerTreatments(txIds);
  };

  const overdue = consTx?.SCHEDULED.filter(
    ({ timeDue }) =>
      timeDue && (tu.getCurrentDateTime() - timeDue) / (60 * 1000) > 5
  );
  const current = consTx?.SCHEDULED.filter(
    ({ timeDue }) =>
      timeDue &&
      (tu.getCurrentDateTime() - timeDue) / (60 * 1000) <= 5 &&
      timeDue &&
      (tu.getCurrentDateTime() - timeDue) / (60 * 1000) >= -5
  );
  const upcoming = consTx?.SCHEDULED.filter(
    ({ timeDue }) =>
      timeDue && (tu.getCurrentDateTime() - timeDue) / (60 * 1000) < -5
  );

  const disabled = consTx.status === "LOCKED";

  const getComponent = (treatment: Treatment) => (
    <Paper p="lg" radius="sm" withBorder miw={180}>
      <Group spacing={4}>
        <Text size="md">{treatment.productSnapshot.name}</Text>
        {treatment.fixedPriceBundleId === treatment.id && (
          <ThemeIcon color="gray" size="xs" variant="outline">
            <IconStack2 />
          </ThemeIcon>
        )}
        {treatment.type === "SCHEDULED" && treatment.timeDue && (
          <Text color="dimmed" size="sm">
            {tu.getDateAndTimeString(treatment.timeDue)}
          </Text>
        )}{" "}
        <Badge color="cyan" size="md" sx={{ textTransform: "lowercase" }}>
          {treatment.qty} {treatment.unit && `(${t(treatment.unit)})`}
        </Badge>
      </Group>

      {treatment.adminRoutes && (
        <Text>{treatment.adminRoutes?.map((r) => r).join(" - ")}</Text>
      )}
      {treatment.productSnapshot.adminInstr && (
        <Text>{HTMLReactParser(treatment.productSnapshot.adminInstr)}</Text>
      )}

      <Group position="apart" mt="lg">
        {treatment.type === "PRN" && treatment.priority && (
          <Avatar
            src={null}
            variant="filled"
            color="cyan"
            radius="xl"
            size="sm"
          >
            {treatment.priority}
          </Avatar>
        )}
        <Box></Box>
        <Group w={120}>
          {(treatment.fixedPriceBundleId === treatment.id ||
            !treatment.fixedPriceBundleId) && (
            <>
              <AsyncActionIcon
                disabled={disabled}
                icon={<IconVaccine size={18} />}
                f={async () =>
                  treatment.fixedPriceBundleId === treatment.id
                    ? administerFPBTreatments(treatment.id)
                    : administerTreatment(treatment.id)
                }
              />

              <ActionIcon
                disabled={disabled}
                onClick={() =>
                  navigate(
                    treatment.fixedPriceBundleId === treatment.id
                      ? `bundle-treatments/${treatment.id}/edit`
                      : `treatments/${treatment.id}/edit`
                  )
                }
              >
                <IconEdit size={18} />
              </ActionIcon>

              <AsyncActionIcon
                disabled={disabled}
                icon={<IconHandStop size={18} />}
                f={async () =>
                  treatment.fixedPriceBundleId === treatment.id
                    ? stopFPBTreatments(treatment.id)
                    : stopTreatment(treatment.id)
                }
              />
            </>
          )}
        </Group>
      </Group>
    </Paper>
  );

  return (
    <BBox>
      <Group p="xl" align="flex-start" grow mih="60vh">
        <Stack>
          <Badge color="red" size="lg" sx={{ textTransform: "capitalize" }}>
            {t("STAT")}
          </Badge>
          {consTx.STAT.map((treatment) => getComponent(treatment))}
        </Stack>
        <Stack>
          <Badge color="violet" size="lg" sx={{ textTransform: "capitalize" }}>
            {t("PRN")}
          </Badge>
          {[...consTx.PRN]
            .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
            .map((treatment) => getComponent(treatment))}
        </Stack>
        <Stack>
          <Badge color="orange" size="lg" sx={{ textTransform: "capitalize" }}>
            {t("OVERDUE")}
          </Badge>
          {overdue.map((treatment) => getComponent(treatment))}
        </Stack>
        <Stack>
          <Badge color="green" size="lg" sx={{ textTransform: "capitalize" }}>
            {t("CURRENT")}
          </Badge>
          {current.map((treatment) => getComponent(treatment))}
        </Stack>
        <Stack>
          <Badge color="blue" size="lg" sx={{ textTransform: "capitalize" }}>
            {t("UPCOMING")}
          </Badge>
          {upcoming.map((treatment) => getComponent(treatment))}
        </Stack>
      </Group>
    </BBox>
  );
};

// <>
//   {" "}
//   <Group>
//     <Group
//       pr="lg"
//       position="apart"
//       sx={{
//         flexGrow: 1, // idk why but it's working
//       }}
//     >
//       {/* TODO: make text w change with screen size? prolly not needed*/}
//       <Group w={400} mr="xl" ml={0} p={0}>
//         <Text>{treatment.productSnapshot.name}</Text>
//         {treatment.bundleId === treatment.id && (
//           <ThemeIcon color="gray" size="xs" variant="outline">
//             <IconStack2 />
//           </ThemeIcon>
//         )}
//       </Group>
//       <Tooltip label={t("QUANTITY/DOSE")}>
//         <Text w={100}>
//           {treatment.qty}{" "}
//           {treatment.unit && `(${t(treatment.unit)})`}
//         </Text>
//       </Tooltip>
//       <Box w={150}></Box>

//       <Group w={120}>
//         {(treatment.bundleId === treatment.id ||
//           !treatment.bundleId) && (
//           <>
//             <AsyncActionIcon
//               disabled={disabled}
//               icon={<IconVaccine size={18} />}
//               f={async () =>
//                 treatment.bundleId === treatment.id
//                   ? administerFPBTreatments(treatment.id)
//                   : administerTreatment(treatment.id)
//               }
//             />

//             <ActionIcon
//               disabled={disabled}
//               onClick={() =>
//                 navigate(
//                   treatment.bundleId === treatment.id
//                     ? `bundle-treatments/${treatment.id}/edit`
//                     : `treatments/${treatment.id}/edit`
//                 )
//               }
//             >
//               <IconEdit size={18} />
//             </ActionIcon>

//             <AsyncActionIcon
//               disabled={disabled}
//               icon={<IconHandStop size={18} />}
//               f={async () =>
//                 treatment.bundleId === treatment.id
//                   ? stopFPBTreatments(treatment.id)
//                   : stopTreatment(treatment.id)
//               }
//             />
//           </>
//         )}
//       </Group>
//     </Group>
//   </Group>
//   <SimpleGrid cols={3}>
//     <div>
//       <Text color="cyan">{t("ADMINISTRATION_INSTRUCTIONS")}</Text>
//       <Text>{HTMLReactParser(treatment.adminInstr ?? "-")}</Text>
//     </div>
//     <div>
//       <Text color="cyan">{t("ADMINISTRATION_ROUTES")}</Text>
//       <Text>
//         {treatment.adminRoutes
//           ? treatment.adminRoutes.map((r) => r).join(" - ")
//           : "-"}
//       </Text>
//     </div>
//     {treatment.type === "PRN" && (
//       <div>
//         <Text color="cyan">{t("PRIORITY")}</Text>
//         <Text>{treatment.priority ?? "-"}</Text>
//       </div>
//     )}
//     {treatment.type === "SCHEDULED" && (
//       <div>
//         <Text color="cyan">{t("TIME_DUE")}</Text>
//         <Text>
//           {treatment.timeDue
//             ? tu.getDateAndTimeString(treatment.timeDue)
//             : "-"}
//         </Text>
//       </div>
//     )}
//     <div>
//       <Text color="cyan">{t("SERIAL_NUMBER")}</Text>
//       <Text>{treatment.serialNumber ?? "-"}</Text>
//     </div>
//     <div>
//       <Text color="cyan">{t("LOT_NUMBER")}</Text>
//       <Text>{treatment.lotNumber ?? "-"}</Text>
//     </div>
//     <div>
//       <Text color="cyan">{t("LOT_EXPIRATION_DATE")}</Text>
//       <Text>
//         {treatment.lotExpirationDate
//           ? tu.getDateString(treatment.lotExpirationDate)
//           : "-"}
//       </Text>
//     </div>
//   </SimpleGrid>
// </>
