import { z } from "zod";

export const appointmentRequestStatusEnum = z.enum([
  "PENDING",
  "DECLINED",
  "ACCEPTED",
]);

export type AppointmentRequestStatus = z.infer<
  typeof appointmentRequestStatusEnum
>;
