import { RemindersSnapshotsTable } from "features/reminders-snapshots/components";

export interface RemindersProps {
  clientId: string;
  clientName: string;
}

export const Reminders = ({ clientId, clientName }: RemindersProps) => {
  return (
    <RemindersSnapshotsTable
      clientId={clientId}
      clientName={clientName}
      weekOnly={false}
    />
  );
};
