import { patientExternalIdTypeEnum } from "beitary-shared";
import { BSelect } from "components/BSelect";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface PatientExternalIdSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const PatientExternalIdSelect = (
  props: PatientExternalIdSelectProps
) => {
  const { t } = useTranslation();

  const data = patientExternalIdTypeEnum.options.map((o) => ({
    value: o,
    label: t(o),
  }));

  return <BSelect {...props} data={data} nothingFound={t("NOTHING_FOUND")} />;
};
