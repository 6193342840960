import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { appointmentNotificationTemplateTypeEnum } from "./AppointmentNotificationTemplate.type";
import { appointmentNotificationTimeUnitSchema } from "./AppointmentNotificationTimeUnit";

/**
 * schema and types versions
 * from first to last
 */

// v0

const smsAppointmentNotificationTemplateDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  type: appointmentNotificationTemplateTypeEnum.default("APT_BOOKED"),
  aptTypeIds: z.array(z.string()),
  notifications: z
    .array(appointmentNotificationTimeUnitSchema)
    .max(5)
    .optional(),
  notificationContent: z.string(),
});

type SMSAppointmentNotificationTemplateDataV0 = z.infer<
  typeof smsAppointmentNotificationTemplateDataV0Schema
>;

const smsAppointmentNotificationTemplateV0Schema = baseRecordSchema.merge(
  smsAppointmentNotificationTemplateDataV0Schema
);

type SMSAppointmentNotificationTemplateV0 = z.infer<
  typeof smsAppointmentNotificationTemplateV0Schema
>;

const normalizeSMSAppointmentNotificationTemplateV0 = (
  data: any
): SMSAppointmentNotificationTemplate => {
  try {
    const smsAppointmentNotificationTemplateV0: SMSAppointmentNotificationTemplateV0 =
      smsAppointmentNotificationTemplateV0Schema.parse(data);

    const { ...rest } = smsAppointmentNotificationTemplateV0;

    // here it's same object cause current v is 0
    const smsAppointmentNotificationTemplate: SMSAppointmentNotificationTemplate =
      {
        ...rest,
      };
    return smsAppointmentNotificationTemplate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const SMS_APPOINTMENT_NOTIFICATION_TEMPLATE_VERSION = "0";

export type SMSAppointmentNotificationTemplateData =
  SMSAppointmentNotificationTemplateDataV0;

export type SMSAppointmentNotificationTemplate = BaseRecord &
  SMSAppointmentNotificationTemplateData;

export const smsAppointmentNotificationTemplateDataSchema =
  smsAppointmentNotificationTemplateDataV0Schema;

export const smsAppointmentNotificationTemplateSchema =
  smsAppointmentNotificationTemplateV0Schema;

export const normalizeSMSAppointmentNotificationTemplate = (
  data: any
): SMSAppointmentNotificationTemplate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeSMSAppointmentNotificationTemplateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
