import { Button, Center } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import { useSubmitState } from "hooks/useSubmitState";

export interface BButtonDownloadProps {
  f: Function;
  disabled?: boolean;
}

export const BButtonDownload = ({ f, disabled }: BButtonDownloadProps) => {
  const [submitState, setSubmitState] = useSubmitState();

  const submit = async () => {
    setSubmitState("pending-response");
    await f();
    setSubmitState("success");
  };

  return (
    <Button
      variant="outline"
      loading={submitState === "pending-response"}
      onClick={submit}
      disabled={disabled}
    >
      <Center>
        <IconDownload size={18} />
      </Center>
    </Button>
  );
};
