import { Button, ButtonProps, DefaultMantineColor } from "@mantine/core";
import { useSubmitState } from "hooks/useSubmitState";
import { ReactNode } from "react";

export interface AsyncActionButtonProps {
  f: Function;
  child: ReactNode;
  leftIcon?: ReactNode;
  variant?: ButtonProps["variant"];
  color?: DefaultMantineColor;
  disabled?: boolean;
  size?: ButtonProps["size"];
  compact?: ButtonProps["compact"];
  fullWidth?: boolean;
}

export const AsyncActionButton = ({
  f,
  child,
  leftIcon,
  variant,
  color,
  disabled,
  size,
  compact,
  fullWidth,
}: AsyncActionButtonProps) => {
  const [submitState, setSubmitState] = useSubmitState();

  const submit = async () => {
    setSubmitState("pending-response");
    await f();
    setSubmitState("success");
  };

  return (
    <Button
      leftIcon={leftIcon}
      loading={submitState === "pending-response"}
      onClick={submit}
      variant={variant}
      color={color}
      disabled={disabled}
      size={size}
      compact={compact}
      fullWidth={fullWidth}
    >
      {child}
    </Button>
  );
};
