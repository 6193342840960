import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const bundleDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  code: z.string().optional(),
  notes: z.string().optional(),
  products: z
    .array(
      z.object({
        id: z.string(),
        min: z.number().nonnegative(),
        max: z.number().positive(),
      })
    )
    .min(1),
  hasFixedPrice: z.boolean().optional(),
  unitSellingPrice: z.number().int().optional(),
  taxRate: z.number().nonnegative().default(0.19),
  categoryId: z.string().optional(),
});

type BundleDataV0 = z.infer<typeof bundleDataV0Schema>;

const bundleV0Schema = baseRecordSchema.merge(bundleDataV0Schema);

type BundleV0 = z.infer<typeof bundleV0Schema>;

const normalizeBundleV0 = (data: any): Bundle => {
  try {
    const bundleV0: BundleV0 = bundleV0Schema.parse(data);

    const { ...rest } = bundleV0;

    // here it's same object cause current v is 0
    const bundle: Bundle = {
      ...rest,
    };
    return bundle;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const BUNDLE_VERSION = "0";

export type BundleData = BundleDataV0;

export type Bundle = BaseRecord & BundleData;

export const bundleDataSchema = bundleDataV0Schema;

export const bundleSchema = bundleV0Schema;

export const normalizeBundle = (data: any): Bundle => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeBundleV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
