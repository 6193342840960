import {
  ActionIcon,
  Button,
  Group,
  Modal,
  NumberInput,
  Stack,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCircleCheck, IconEdit } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  obju,
  PurchaseOrderItemData,
} from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface PurchaseOrderItemQtyOrderedUpdateModalProps {
  itemId: string;
  purchaseOrderId: string;
  orderedQty: number;
}

export const PurchaseOrderItemQtyOrderedUpdateModal = ({
  itemId,
  orderedQty,
  purchaseOrderId,
}: PurchaseOrderItemQtyOrderedUpdateModalProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();

  const { updatePurchaseOrderItem } =
    useDBServices().inventoryDBService.purchaseOrders;

  const [opened, setOpened] = useState(false);

  const [newOrderedQty, setNewOrderedQty] = useState(orderedQty);

  const submit = async () => {
    const data: Partial<PurchaseOrderItemData> = {
      qtyOrdered: newOrderedQty,
    };

    obju.removeUndefined(data);

    const notificationId = id_util.newId6();
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_PURCHASE_ORDER_ITEM"),
      })
    );
    setSubmitState("pending-response");
    const result = await updatePurchaseOrderItem({
      purchaseOrderId,
      data,
      id: itemId,
    });

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <>
      <Modal
        size="lg"
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("UPDATE")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack p="xl">
          <NumberInput
            label={t("QUANTITY_ORDERED")}
            min={0}
            precision={2}
            value={newOrderedQty}
            onChange={(v) => {
              if (v) setNewOrderedQty(v);
            }}
          />
          <Group position="right">
            <Button
              loading={submitState === "pending-response"}
              color={"green"}
              leftIcon={<IconCircleCheck size={18} />}
              onClick={submit}
            >
              {t("SAVE")}
            </Button>
          </Group>
        </Stack>
      </Modal>
      <ActionIcon onClick={() => setOpened(true)}>
        <IconEdit size={18} />
      </ActionIcon>
    </>
  );
};
