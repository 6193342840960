import { z } from "zod";

// must have space between the two cause
// split to extract day/hour formats
// check utils
export const dateFormatEnum = z.enum([
  "YYYY-MM-DD HH:mm:ss",
  "DD-MM-YYYY HH:mm:ss",
  "MM-DD-YYYY HH:mm:ss",
  "YYYY-MM-DD hh:mm:ss a",
  "DD-MM-YYYY hh:mm:ss a",
  "MM-DD-YYYY hh:mm:ss a",
]);

export type DateFormat = z.infer<typeof dateFormatEnum>;
