import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectImagingIntegrationProducts } from "features/admin/catalog/Catalog.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BTRDLProductForm } from "../BTRDLProductForm";

export const EditBTRDLProduct = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const product = useAppSelector(selectImagingIntegrationProducts)
    .filter((product) => product.id === id)
    .at(0);

  if (!product) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <BTRDLProductForm product={product} />;
};
