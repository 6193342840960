import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Member, OrgPermissions } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectMembers } from "features/admin/members/Members.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type MembersTableItem = Member & {
  actions?: undefined;
};

export const MembersTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const members = useAppSelector(selectMembers);

  const tableItems: MembersTableItem[] = members.map((member) => ({
    ...member,
  }));

  const { deleteMember, archiveMember, dearchiveMember } =
    useDBServices().organizationMembersDBService.members;

  const columns: BDataTableColumn<MembersTableItem>[] = [
    { accessor: "name", title: <Text>{t("NAME")}</Text>, sortable: true },
    {
      accessor: "initials",
      title: <Text>{t("INITIALS")}</Text>,
      sortable: true,
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (member) => (
        <BCan I="do" a={OrgPermissions.MEMBERS_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${member.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {member.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () => dearchiveMember(member.id, member)}
                item={member}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () => archiveMember(member.id, member)}
                item={member}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteMember(member.id)}
              item={member}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
