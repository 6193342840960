import { z } from "zod";
import { organizationSchema, UnableToNormalizeData } from "../../types-schemas";

export const gMOOnCallDataSchema = z.object({
  memberId: z.string().optional(),
});

export type GMOOnCallData = z.infer<typeof gMOOnCallDataSchema>;

export const normalizeGMOOnCallData = (data: any): GMOOnCallData => {
  try {
    return gMOOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const gMOOnCallResponseSchema = z.object({
  contentType: z.enum(["application/json"]), // idk wtf this is, maybe no need for this at all
  content: z.array(organizationSchema),
});

export type GMOOnCallResponse = z.infer<typeof gMOOnCallResponseSchema>;

export const normalizeGMOOnCallResponse = (data: any): GMOOnCallResponse => {
  try {
    return gMOOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
