import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Bundles } from "features/admin/catalog/components";
import {
  AddBundle,
  EditBundle,
} from "features/admin/catalog/components/Bundles/components";
import { Outlet, Route } from "react-router-dom";

export const BundlesRoutes = (
  <Route path="bundles" element={<Outlet />}>
    <Route index element={<Bundles />} />
    <Route
      path="new"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.PRODUCTS_CUD]}
          element={<AddBundle />}
        />
      }
    />
    <Route
      path="edit/:id"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.PRODUCTS_CUD]}
          element={<EditBundle />}
        />
      }
    />
  </Route>
);
