import {
  Group,
  ScrollArea,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import {
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconSelector,
} from "@tabler/icons-react";
import { id_util } from "beitary-shared";
import { ReactNode, useEffect, useState } from "react";

export interface TableWithSearchAndSortProps<T> {
  data: T[];
  fields: { key: keyof T; label: ReactNode }[];
  getRow: (item: T) => React.ReactNode;
  hasControlsColumn?: boolean;
  extraControl?: React.ReactNode;
  minWidth?: number | string;
  minHeight?: number | string;
}

// TODO: Add pagination
/**
 * A table with search and sort functionality for all kind of data.
 */
export const TableWithSearchAndSort = <T,>({
  data,
  fields,
  getRow,
  hasControlsColumn,
  extraControl,
  minWidth,
  minHeight,
}: TableWithSearchAndSortProps<T>) => {
  const [search, setSearch] = useState("");
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<keyof T | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  useEffect(() => {
    setSortedData(data); // this is cauz otherwise table won't refresh if data changes
  }, [data]);

  const keys = fields.map((field) => field.key);

  const setSorting = (field: keyof T) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }, keys));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(
        data,
        { sortBy, reversed: reverseSortDirection, search: value },
        keys
      )
    );
  };

  const rows = sortedData.map(getRow);

  return (
    <ScrollArea sx={{ minWidth, minHeight }}>
      <Group mb="md">
        <TextInput
          //placeholder={t("SEARCH_BY_ANY_FIELD")} TODO
          icon={<IconSearch size={14} stroke={1.5} />}
          value={search}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1 }} // make the input fill the remaining space
        />
        {extraControl}
      </Group>
      <Table horizontalSpacing="md" verticalSpacing="xs">
        <thead>
          <tr>
            {fields.map((field) => (
              <Th
                sorted={sortBy === field.key}
                reversed={reverseSortDirection}
                onSort={() => setSorting(field.key)}
                key={id_util.randomString(6)}
              >
                {field.label}
              </Th>
            ))}
            {hasControlsColumn && <th></th>}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td
                colSpan={hasControlsColumn ? 1 + fields.length : fields.length}
              ></td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th>
      <UnstyledButton onClick={onSort}>
        <Group position="apart">
          <Text weight={500} size="sm">
            {children}
          </Text>
          <Icon size={14} stroke={1.5} />
        </Group>
      </UnstyledButton>
    </th>
  );
}

const filterData = <T,>(data: T[], search: string, keys: (keyof T)[]) => {
  const query = search.toLowerCase().trim();
  console.log(data);
  console.log(query);

  return data.filter((item) =>
    keys.some((key) => `${item[key]}`.toLowerCase().includes(query))
  );
};

const sortData = <T,>(
  data: T[],
  payload: { sortBy: keyof T | null; reversed: boolean; search: string },
  keys: (keyof T)[]
) => {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search, keys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return `${b[sortBy]}`.toString().localeCompare(`${a[sortBy]}`);
      }

      return `${a[sortBy]}`.localeCompare(`${b[sortBy]}`); // `${}` is used to convert anything to strings
    }),
    payload.search,
    keys
  );
};
