import {
  errorResult,
  normalizeServiceProvider,
  Result,
  ServiceProvider,
  ServiceProviderData,
  SERVICE_PROVIDER_VERSION,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization serviceProvider

interface AddServiceProvider {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: ServiceProviderData;
  }): Promise<Result<ServiceProvider | null>>;
}

const addServiceProvider: AddServiceProvider = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new serviceProvider id
    const newServiceProviderRef = doc(
      collection(db, "organizations", organizationId, "service_providers")
    );

    const newServiceProvider: ServiceProvider = normalizeServiceProvider({
      ...data,
      id: newServiceProviderRef.id,
      authorId,
      authorName,
      version: SERVICE_PROVIDER_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newServiceProviderRef, newServiceProvider);

    // t("SERVICE_PROVIDER_CREATED")
    const successMessage = "SERVICE_PROVIDER_CREATED";
    return successResult({
      message: successMessage,
      payload: newServiceProvider,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetServiceProvider {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<ServiceProvider | null>>;
}

const getServiceProvider: GetServiceProvider = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const serviceProviderDocRef = doc(
      db,
      "organizations",
      organizationId,
      "service_providers",
      id
    );
    const serviceProviderDocSnapshot = await getDoc(serviceProviderDocRef);
    if (serviceProviderDocSnapshot.exists()) {
      try {
        const data: unknown = serviceProviderDocSnapshot.data();
        const serviceProvider: ServiceProvider = normalizeServiceProvider(data);
        // t("SERVICE_PROVIDER_FOUND")
        const successMessage = "SERVICE_PROVIDER_FOUND";
        return successResult({
          message: successMessage,
          payload: serviceProvider,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("SERVICE_PROVIDER_NOT_FOUND")
      const errorMessage = "SERVICE_PROVIDER_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditServiceProvider {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: ServiceProviderData;
  }): Promise<Result<ServiceProvider | null>>;
}

const editServiceProvider: EditServiceProvider = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const serviceProvider = (
      await getServiceProvider({ db, organizationId, id })
    ).payload;

    if (!serviceProvider) {
      // t("SERVICE_PROVIDER_NOT_FOUND")
      const errorMessage = "SERVICE_PROVIDER_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = serviceProvider;
      const updatedServiceProvider: ServiceProvider = normalizeServiceProvider({
        ...data,
        id,
        authorId,
        authorName,
        version: SERVICE_PROVIDER_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      const serviceProviderRef = doc(
        db,
        "organizations",
        organizationId,
        "service_providers",
        id
      );

      await setDoc(serviceProviderRef, updatedServiceProvider);

      // t("SERVICE_PROVIDER_UPDATED")
      const successMessage = "SERVICE_PROVIDER_UPDATED";
      // console.log(successMessage, updatedServiceProvider);
      return successResult({
        message: successMessage,
        payload: updatedServiceProvider,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteServiceProvider {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteServiceProvider: DeleteServiceProvider = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "service_providers",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("SERVICE_PROVIDER_DELETED")
      const successMessage = "SERVICE_PROVIDER_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveServiceProvider: EditServiceProvider = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: ServiceProviderData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editServiceProvider({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("SERVICE_PROVIDER_ARCHIVED")
    const successMessage = "SERVICE_PROVIDER_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveServiceProvider: EditServiceProvider = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: ServiceProviderData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editServiceProvider({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("SERVICE_PROVIDER_DEARCHIVED")
    const successMessage = "SERVICE_PROVIDER_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization serviceProviders listener
interface GetServiceProvidersListenerCallback {
  (serviceProviders: ServiceProvider[]): void;
}
interface GetServiceProvidersListener {
  db: Firestore;
  organizationId: string;
  callback: GetServiceProvidersListenerCallback;
}

const getServiceProvidersListener = ({
  db,
  organizationId,
  callback,
}: GetServiceProvidersListener): Unsubscribe => {
  try {
    // console.log("getServiceProvidersListener: new listener");
    const serviceProvidersQuery = query(
      collection(db, "organizations", organizationId, "service_providers"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(serviceProvidersQuery, (querySnapshot) => {
      const serviceProviders: ServiceProvider[] = [];
      querySnapshot.forEach((doc) => {
        try {
          serviceProviders.push(normalizeServiceProvider(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(serviceProviders);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const serviceProviders = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getServiceProvidersListener: (
      callback: GetServiceProvidersListenerCallback
    ) =>
      getServiceProvidersListener({
        db,
        organizationId,
        callback,
      }),
    getServiceProvider: (id: string) =>
      getServiceProvider({
        db,
        organizationId,
        id,
      }),
    addServiceProvider: (data: ServiceProviderData) =>
      addServiceProvider({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editServiceProvider: (id: string, data: ServiceProviderData) =>
      editServiceProvider({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteServiceProvider: (id: string) =>
      deleteServiceProvider({
        db,
        organizationId,
        id,
      }),
    archiveServiceProvider: (id: string, data: ServiceProviderData) =>
      archiveServiceProvider({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveServiceProvider: (id: string, data: ServiceProviderData) =>
      dearchiveServiceProvider({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
