import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectMemberRoles } from "features/admin/members/Members.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { MemberRoleForm } from "../MemberRoleForm";

export const EditMemberRole = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const memberRole = useAppSelector(selectMemberRoles)
    .filter((memberRole) => memberRole.id === id)
    .at(0);

  if (!memberRole) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <MemberRoleForm memberRole={memberRole} />;
};
