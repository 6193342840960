import { z } from "zod";
import { UnableToNormalizeData } from "../../types-schemas";

export const gPDFOnCallDataSchema = z.object({
  docId: z.string().optional(),
  organizationId: z.string().optional(),
  consultationId: z.string().optional(),
  clientId: z.string().optional(),
  patientId: z.string().optional(),
  authorId: z.string(),
  authorName: z.string(),
  action: z.enum(["SEND_EMAIL", "RETURN_DL_URL", "RETURN_BASE64_STRING"]),
});

export type GPDFOnCallData = z.infer<typeof gPDFOnCallDataSchema>;

export const normalizeGPDFOnCallData = (data: any): GPDFOnCallData => {
  try {
    return gPDFOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const gPDFOnCallResponseSchema = z.object({
  contentType: z.enum(["application/pdf", "text/uri-list"]),
  content: z.string(),
});

export type GPDFOnCallResponse = z.infer<typeof gPDFOnCallResponseSchema>;

export const normalizeGPDFOnCallResponse = (data: any): GPDFOnCallResponse => {
  try {
    return gPDFOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
