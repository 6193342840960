import { Group, Loader, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export interface BLoaderProps {
  size?: number;
}

export const BLoader = ({ size }: BLoaderProps) => {
  const { t } = useTranslation();

  return (
    <Group>
      <Text size={size}>{t("LOADING")}</Text>
      <Loader size={size} />
    </Group>
  );
};
