//import { useTranslation } from "react-i18next";

import {
  Badge,
  Box,
  Group,
  ScrollArea,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { Appointment, PatientSex, PatientSpecies, tu } from "beitary-shared";
import { BBox } from "components";
import {
  selectActiveAppointmentTypes,
  selectActiveServiceProviders,
} from "features/admin/schedule-settings/ScheduleSettings.slice";
import { AppointmentModal } from "features/schedule/appointments/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type AppointmentsTableItem = Appointment & {
  patientPatientName: string;
  date: string;
  aptTypeColor: string;
  servProvColor: string;
};
export interface PatientUpcomingAppointmentsProps {
  clientId: string;
  clientName: string;
  patientId: string;
  patientName: string;
  patientSex: PatientSex;
  patientSpecies: PatientSpecies;
  patientPictureURL?: string;
}

export const PatientUpcomingAppointments = ({
  patientId,
  clientId,
  clientName,
  patientName,
  patientPictureURL,
  patientSex,
  patientSpecies,
}: PatientUpcomingAppointmentsProps) => {
  const { t } = useTranslation();
  const appointmentTypes = useAppSelector(selectActiveAppointmentTypes);
  const serviceProviders = useAppSelector(selectActiveServiceProviders);

  const db = useDBServices().appointmentsDBService;

  const [appointments, setAppointments] = useState<
    undefined | null | Appointment[]
  >(undefined);

  console.log(appointments);

  useEffect(() => {
    const getDiagnosesListenerUnsubscribe =
      db.getClientUpcomingAppointmentsListener({
        clientId,
        patientId,
        callback: setAppointments,
      });

    return () => {
      getDiagnosesListenerUnsubscribe();
    };
  }, [patientId, clientId, db]);

  const tableAppointments: AppointmentsTableItem[] = (appointments ?? []).map(
    (appointment) => ({
      ...appointment,
      patientPatientName: appointment.patientName + appointment.patientName,
      date: `${tu.getDateAndTimeString(
        appointment.time
      )} - ${tu.getDateAndTimeString(
        appointment.time + appointment.duration * 60 * 1000
      )}`,
      aptTypeColor:
        appointmentTypes.find((t) => t.id === appointment.appointmentTypeId)
          ?.color ?? "white",
      servProvColor:
        serviceProviders.find((p) => p.id === appointment.serviceProviderId)
          ?.color ?? "white",
    })
  );

  console.log(tableAppointments);

  const getRow = (appointment: AppointmentsTableItem) => {
    return (
      <tr key={appointment.id}>
        <td>
          <Group
            sx={{
              borderLeft: `6px solid ${appointment.aptTypeColor}`,
              borderRadius: 4,
            }}
          >
            <Stack ml="xs" align="left" spacing={0}>
              <Text size="sm">{`${tu.getDateString(appointment.time)}`}</Text>
              <Group>
                <Text size="xs" color="dimmed">
                  {`${tu.getHoursAndMinutesString(appointment.time)}`} -{" "}
                  {`${tu.getHoursAndMinutesString(
                    appointment.time + appointment.duration * 60 * 1000
                  )}`}
                </Text>
              </Group>
            </Stack>
          </Group>
        </td>

        <td>
          <Badge c={appointment.servProvColor}>
            <Text>{appointment.serviceProviderName}</Text>
          </Badge>
        </td>
        <td>
          <Text>{appointment.reasonForConsultation}</Text>
        </td>
      </tr>
    );
  };

  const rows = tableAppointments.map((a) => getRow(a));
  console.log(rows);

  return (
    <BBox
      header={
        <Group position="apart">
          <Group>
            <Text weight={500}>{t("UPCOMING_APPOINTMENTS")}</Text>
          </Group>
          <AppointmentModal
            clientId={clientId}
            clientName={clientName}
            patientId={patientId}
            patientName={patientName}
            patientPictureURL={patientPictureURL}
            patientSex={patientSex}
            patientSpecies={patientSpecies}
          />
        </Group>
      }
    >
      {" "}
      <Box p="xl">
        {appointments === undefined && <Text>{t("LOADING")}</Text>}
        {appointments === null && <Text>{t("ERROR")}</Text>}
        {appointments?.length === 0 && (
          <Text>{t("NO_UPCOMING_APPOINTMENTS")}</Text>
        )}
      </Box>
      {appointments?.length !== 0 && (
        <ScrollArea>
          <Table>
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
      )}
    </BBox>
  );
};
