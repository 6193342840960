import { z } from "zod";
import { inventoryItemUnitTypeEnum } from "../InventoryItemUnitType";

// using same stuff here cauz didnt want to refactor everything
// also was showing only few stuff but i rekon we need more types
// so using same as inv item

export const productConcentrationSolventUnitTypeEnum =
  inventoryItemUnitTypeEnum;

export type ProductConcentrationSolventUnitType = z.infer<
  typeof productConcentrationSolventUnitTypeEnum
>;
