import { z } from "zod";

// t("EXCELLENT")
// t("GOOD")
// t("GUARDED")
// t("POOR")
// t("GRAVE")
export const consultationPrognosisEnum = z.enum([
  "EXCELLENT",
  "GOOD",
  "GUARDED",
  "POOR",
  "GRAVE",
]);
export type ConsultationPrognosis = z.infer<typeof consultationPrognosisEnum>;
