import { z } from "zod";
import { timezoneEnum } from "../../time/TimeZone/TimeZone";

export const countrySchema = z.object({
  name: z.string(),
  phonecode: z.string(),
  isoCode: z.string(),
  flag: z.string(),
  currency: z.string(),
  latitude: z.string(),
  longitude: z.string(),
  timezones: timezoneEnum.optional(),
  // timezones: timeZoneSchema.optional(),
});

export type Country = z.infer<typeof countrySchema>;
