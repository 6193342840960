import { money_utils } from "beitary-shared";
import { org_params_util } from "./org_params_utils";

const currency = org_params_util.getOrgParams().currency;

const toD = (amount: number) => {
  return money_utils.toD({ amount, c: currency });
};

const getPercentage = ({
  amount,
  percentage2p,
}: {
  amount: number;
  percentage2p: number;
}): number => {
  return money_utils.getPercentage({ amount, percentage2p, c: currency });
};

export const mu = {
  toD,
  getPercentage,
  multiply: money_utils.multiply,
  calculateItemValueAndTaxes: money_utils.calculateItemValueAndTaxes,
};
