import { zodResolver } from "@mantine/form";
import { consultationDataSchema } from "beitary-shared";
import { z } from "zod";

const subjectiveFormSchema = consultationDataSchema.pick({
  reasonForConsultation: true,
  organizationName: true,
  history: true,
});

export type SubjectiveFormValues = z.infer<typeof subjectiveFormSchema>;

export const rules = zodResolver(subjectiveFormSchema);
