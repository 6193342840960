import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Consultation, Treatment } from "beitary-shared";

export type ConsTx = Consultation & {
  STAT: Treatment[];
  PRN: Treatment[];
  SCHEDULED: Treatment[];
};

interface WhiteboardState {
  consTxs: ConsTx[];
  //txs: Treatment[];
}

const initialState: WhiteboardState = {
  consTxs: [],
  // txs: [],
};

export const whiteboardSlice = createSlice({
  name: "whiteboard",
  initialState,
  reducers: {
    setConsTxs: (state, action: PayloadAction<ConsTx[]>) => {
      state.consTxs = action.payload;
    },
    // setTxs: (state, action: PayloadAction<Treatment[]>) => {
    //   state.txs = action.payload;
    // },
  },
});

export const { setConsTxs } = whiteboardSlice.actions;

export const selectConsTxs = (state: RootState) => state.whiteboard.consTxs;
// export const selectTxs = (state: RootState) => state.whiteboard.consTxs;

export const whiteboardReducer = whiteboardSlice.reducer;
