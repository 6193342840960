import { useAppDispatch, useAppSelector } from "app/hooks";
import { Consultation, Treatment, TreatmentType } from "beitary-shared";
import { selectCurrentConsultations } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { setConsTxs } from "./Whiteboard.slice";
import { View } from "./Whiteboard.view";

export type ConsTx = Consultation & {
  STAT: Treatment[];
  PRN: Treatment[];
  SCHEDULED: Treatment[];
};

export const Container = () => {
  const consultations = useAppSelector(selectCurrentConsultations);

  const db = useDBServices().consultationsDBService;
  const dispatch = useAppDispatch();

  const [consTx, setConsTx] = useState<ConsTx[]>([]);
  // const [treatments, setTreatments] = useState<Treatment[]>([]);

  useEffect(() => {
    dispatch(setConsTxs(consTx));
  }, [consTx, dispatch]);

  // useEffect(() => {
  //   dispatch(setTxs(treatments));
  // }, [treatments, dispatch]);

  const getTxs = (txs: Treatment[], type: TreatmentType) => {
    return txs
      .filter((t) => t.type === type)
      .sort((a, b) => a.createdAt - b.createdAt)
      .sort((a, b) => {
        if (a.id && b.id && a.fixedPriceBundleId === b.fixedPriceBundleId) {
          if (a.id === a.fixedPriceBundleId && b.id !== b.fixedPriceBundleId) {
            return -1; // a comes before b
          } else if (
            a.id !== a.fixedPriceBundleId &&
            b.id === b.fixedPriceBundleId
          ) {
            return 1; // b comes before a
          } else {
            return 0; // order is irrelevant
          }
        } else {
          return a.fixedPriceBundleId === undefined
            ? 1
            : b.fixedPriceBundleId === undefined
            ? -1
            : a.fixedPriceBundleId.localeCompare(b.fixedPriceBundleId);
        }
      });
  };

  useEffect(() => {
    const listeners = consultations.map((c) =>
      db.treatments(c.id).getTreatmentsListener((txs) => {
        // setTreatments(txs);
        setConsTx((old) => [
          ...old.filter((o) => o.id !== c.id),
          {
            ...c,
            PRN: getTxs(txs, "PRN"),
            STAT: getTxs(txs, "STAT"),
            SCHEDULED: getTxs(txs, "SCHEDULED"),
          },
        ]);
      }, "ORDERED")
    );

    // console.log(selectedDate);

    return () => {
      listeners.forEach((listener) => listener());
    };
  }, [consultations, db]);

  return <View consTx={consTx} />;
};
