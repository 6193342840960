import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { inventoryTransactionStateEnum } from "./InventoryTransactionState";
import { inventoryTransactionTypeEnum } from "./InventoryTransactionType";

/**
 * schema and types versions
 * from first to last
 */

// v0

// t("INVOICE")
// t("PURCHASE_ORDER")
// t("COUNT")
// t("MANUAL")
const inventoryTransactionDataV0Schema = z.object({
  state: inventoryTransactionStateEnum,
  attempts: z.number().optional(), // this is for trigger function
  type: inventoryTransactionTypeEnum,
  prodId: z.string().min(1).optional(),
  prodName: z.string().min(1).optional(),
  invItemId: z.string().min(1),
  invItemName: z.string().min(1),
  taxRate: z.number().nonnegative().default(0),
  costPerUnit: z.number().int(), // no tax, used for positive qty added to inv to calculate
  // inventory value using FIFO or CMUP at the end of month/year mainly
  qty: z.number(),
  date: z.number().positive(),
  sourceId: z.string().optional(), // id of the purchase or der or invoice...
  sourceType: z.enum([
    "INVOICE",
    "TREATMENT",
    "PURCHASE_ORDER",
    "COUNT",
    "MANUAL",
  ]),
  notes: z.string().optional(),
});

type InventoryTransactionDataV0 = z.infer<
  typeof inventoryTransactionDataV0Schema
>;

const inventoryTransactionV0Schema = baseRecordSchema.merge(
  inventoryTransactionDataV0Schema
);

type InventoryTransactionV0 = z.infer<typeof inventoryTransactionV0Schema>;

const normalizeInventoryTransactionV0 = (data: any): InventoryTransaction => {
  try {
    const inventoryTransactionV0: InventoryTransactionV0 =
      inventoryTransactionV0Schema.parse(data);

    const { ...rest } = inventoryTransactionV0;

    // here it's same object cause current v is 0
    const inventoryTransaction: InventoryTransaction = {
      ...rest,
    };
    return inventoryTransaction;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVENTORY_TRANSACTION_VERSION = "0";

export type InventoryTransactionData = InventoryTransactionDataV0;

export type InventoryTransaction = BaseRecord & InventoryTransactionData;

export const inventoryTransactionDataSchema = inventoryTransactionDataV0Schema;

export const inventoryTransactionSchema = inventoryTransactionV0Schema;

export const normalizeInventoryTransaction = (
  data: any
): InventoryTransaction => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInventoryTransactionV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
