import { z } from "zod";

// t("MONDAY")
// t("TUESDAY")
// t("WEDNESDAY")
// t("THURSDAY")
// t("FRIDAY")
// t("SATURDAY")
// t("SUNDAY")
export const dayOfTheWeekEnum = z.enum([
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
]);

export type DayOfTheWeek = z.infer<typeof dayOfTheWeekEnum>;
