import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { inventoryItems } from "./items";
import { inventoryItemLocations } from "./locations";
import { purchaseOrders } from "./purchase-order";
import { inventoryItemSuppliers } from "./suppliers";
import { inventoryTransactions } from "./transactions";

export const inventory = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    inventoryItemLocations: inventoryItemLocations(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    inventoryItemSuppliers: inventoryItemSuppliers(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    inventoryItems: inventoryItems(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    inventoryTransactions: inventoryTransactions(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    purchaseOrders: purchaseOrders(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
  };
};
