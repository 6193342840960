import {
  Badge,
  Checkbox,
  Divider,
  Group,
  Input,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  Appointment,
  AppointmentData,
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
  PatientSex,
  PatientSpecies,
  Result,
  tu,
} from "beitary-shared";
import {
  AppointmentTypeSelect,
  AsyncActionButton,
  BDateAndTimeInput,
  SaveButton,
  ServiceProviderSelect,
} from "components";
import { ClientSelect, PatientSelect } from "features/Clients/components";
import { QuickClient } from "features/Clients/components/Client/components";
import { QuickPatient } from "features/Clients/components/Client/components/Patient/components";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { AppointmentLocationSelect } from "../AppointmentLocationSelect";
import { ClientInfo } from "../ClientInfo";
import { PatientInfo } from "../PatientInfo";
import { AppointmentFormValues, rules } from "./AppointmentForm.rules";

export interface AppointmentFormProps {
  appointment?: Appointment;
  patientId?: string;
  patientName?: string;
  patientSex?: PatientSex;
  patientSpecies?: PatientSpecies;
  patientPictureURL?: string;
  clientId?: string;
  clientName?: string;
  emailNotif?: boolean;
  smsNotif?: boolean;
  defaultTime?: number;
}

export const AppointmentForm = ({
  appointment,
  clientId,
  patientId,
  clientName,
  patientName,
  patientPictureURL,
  patientSex,
  patientSpecies,
  defaultTime,
  emailNotif,
  smsNotif,
}: AppointmentFormProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const org = useAppSelector(selectOrganization);
  const db = useDBServices().appointmentsDBService;

  const form = useForm<AppointmentFormValues>({
    initialValues: appointment ? { ...appointment } : undefined,
    validate: rules,
  });

  if (!form.values.patientId && patientId && patientName)
    form.setValues({
      patientId,
      patientName,
      patientPictureURL,
      patientSex,
      patientSpecies,
    });
  if (!form.values.time) {
    if (defaultTime) {
      form.setValues({
        time: defaultTime,
      });
    } else {
      const currentDate = tu.getCurrentDate();
      form.setValues({
        time: tu.getDateValueAtHMSM(
          currentDate,
          currentDate.getHours(),
          currentDate.getMinutes(),
          0,
          0
        ),
      });
    }
  }
  if (!form.values.clientId && clientId && clientName)
    form.setValues({
      clientId,
      clientName,
      emailNotif,
      smsNotif,
    });

  if (!org) return <Text>{t("ORGANIZATION_NOT_FOUND")}</Text>;
  console.log(form.values);

  const submit = async (values: AppointmentFormValues) => {
    const {
      clientId,
      clientName,
      duration,
      location,
      patientId,
      patientName,
      patientSex,
      patientSpecies,
      reasonForConsultation,
      serviceProviderId,
      serviceProviderName,
      time,
      appointmentTypeId,
      appointmentTypeName,
      emailNotif,
      patientPictureURL,
      supervisingDoctorId,
      supervisingDoctorName,
      smsNotif,
    } = values;
    const notificationId = "submit-appointment";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(appointment ? "UPDATE_APPOINTMENT" : "ADD_APPOINTMENT"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;

    const newData: AppointmentData = {
      clientId,
      clientName,
      duration,
      location,
      organizationId: org.id,
      organizationName: org.name,
      patientId,
      patientName,
      patientSex,
      patientSpecies,
      reasonForConsultation,
      recurring: "NO", // TODO fix?
      serviceProviderId,
      serviceProviderName,
      status: "BOOKED",
      time,
      appointmentTypeId,
      appointmentTypeName,
      emailNotif,
      patientPictureURL,
      smsNotif,
      supervisingDoctorId,
      supervisingDoctorName,
      // emailNotif1DayDone: false,
      // emailNotif2HoursDone: false,
      // smsNotif1DayDone: false,
      // smsNotif2DaysDone: false,
      // smsNotif2HoursDone: false,
    };

    obju.removeUndefined(newData);

    console.log(newData);

    setSubmitState("success");
    if (appointment) {
      result = await db.editAppointment(appointment.id, {
        ...appointment,
        ...newData,
      });
    } else {
      result = await db.addAppointment({
        data: {
          ...newData,
        },
      });
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  // TODO also what to do about when changein service provider and srvices

  // console.log(form.values);

  const getButtons = (appointment: Appointment) => {
    switch (appointment.status) {
      case "BOOKED":
        return [
          <AsyncActionButton
            f={async () => db.checkInAppointment(appointment.id)}
            child={t("CHECK_IN")}
            color="cyan"
            compact
          />,
          <AsyncActionButton
            f={async () => db.cancelAppointment(appointment.id)}
            child={t("CANCEL")}
            color="red"
            variant={"outline"}
            compact
          />,
          <AsyncActionButton
            f={async () => db.noShowAppointment(appointment.id)}
            child={t("NO_SHOW")}
            color="blue"
            variant={"outline"}
            compact
          />,
        ];

      case "CANCELED":
        return [
          <AsyncActionButton
            f={async () => db.rescheduleAppointment(appointment.id)}
            child={t("RESCHEDULE")}
            color="cyan"
            compact
          />,
        ];

      case "CHECKED_IN":
        return [
          <AsyncActionButton
            f={async () => db.checkOutAppointment(appointment.id)}
            child={t("CHECK_OUT")}
            color="cyan"
            compact
          />,
          <AsyncActionButton
            f={async () => db.undoCheckinAppointment(appointment.id)}
            child={t("UNDO_CHECK_IN")}
            color="red"
            variant={"outline"}
            compact
          />,
        ];

      case "CHECKED_OUT":
        return [
          <AsyncActionButton
            f={async () => db.undoCheckoutAppointment(appointment.id)}
            child={t("UNDO_CHECK_OUT")}
            color="red"
            variant={"outline"}
            compact
          />,
        ];

      case "NO_SHOW":
        return [
          <AsyncActionButton
            f={async () => db.undoNoShowAppointment(appointment.id)}
            child={t("UNDO_NO_SHOW")}
            color="blue"
            compact
          />,
        ];

      default:
        return <></>;
    }
  };

  return (
    <Stack p="xl">
      {appointment && (
        <div>
          <Group position="apart">
            <Badge size="lg">{t(appointment.status + "_APT")}</Badge>
            <Group position="right">{getButtons(appointment)}</Group>
          </Group>
          <Divider mt="sm" />
        </div>
      )}
      {!appointment && (
        <Group>
          <QuickClient />

          <QuickPatient
            clientId={form.values.clientId}
            clientName={form.values.clientName}
            disabled={!form.values.clientId}
          />
        </Group>
      )}
      <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
        <Stack>
          <Group align="flex-end" grow>
            {appointment ? (
              <>
                <ClientInfo
                  clientId={appointment.clientId}
                  clientName={appointment.clientName}
                />
                <PatientInfo
                  patientId={appointment.patientId}
                  patientName={appointment.patientName}
                />
              </>
            ) : (
              <>
                <ClientSelect
                  required
                  {...form.getInputProps("clientId")}
                  onChange={({ id, name, emailNotif, smsNotif }) => {
                    form.setValues({
                      clientId: id,
                      clientName: name,
                      smsNotif,
                      emailNotif,
                      patientId: undefined,
                    });
                    // form.setFieldValue("clientId", id);
                    // form.setFieldValue("clientName", name);
                    // form.setFieldValue("smsNotif", true);
                    // form.setFieldValue("emailNotif", emailNotif);
                    // // delete patient fields to avoid submitting patient fro wrong client
                    // form.setFieldValue("patientId", ""); // TODO find a better way to do this

                    //clearFormPet(id, name);
                  }}
                />

                <PatientSelect
                  required
                  ownerId={form.getInputProps("clientId").value}
                  {...form.getInputProps("patientId")}
                  onChange={({ id, name, thumbURL, sex, species }) => {
                    form.setFieldValue("patientId", id);
                    if (thumbURL)
                      form.setFieldValue("patientPictureURL", thumbURL);
                    form.setFieldValue("patientName", name);
                    form.setFieldValue("patientSex", sex);
                    form.setFieldValue("patientSpecies", species);
                  }}
                />
              </>
            )}
          </Group>
          <TextInput
            mt="sm"
            required
            label={t("REASON_FOR_CONSULTATION")}
            placeholder="Fever"
            {...form.getInputProps("reasonForConsultation")}
          />
          <Group grow>
            <ServiceProviderSelect
              required
              {...form.getInputProps("serviceProviderId")}
              onChange={({
                id,
                name,
                supervisingDoctorId,
                supervisingDoctorName,
              }) => {
                form.setFieldValue("serviceProviderId", id);
                form.setFieldValue("serviceProviderName", name);
                form.setFieldValue("supervisingDoctorId", supervisingDoctorId);
                form.setFieldValue(
                  "supervisingDoctorName",
                  supervisingDoctorName
                );
              }}
            />
            <AppointmentTypeSelect
              required
              serviceProviderId={form.values.serviceProviderId}
              {...form.getInputProps("appointmentTypeId")}
              onChange={(id, name, d) => {
                form.setFieldValue("appointmentTypeId", id);
                form.setFieldValue("appointmentTypeName", name);
                form.setFieldValue("duration", d);
              }}
            />
          </Group>
          <AppointmentLocationSelect
            required
            {...form.getInputProps("location")}
          />
          <Group grow>
            <BDateAndTimeInput
              required
              label={t("DATE_AND_TIME")}
              minDateTime={tu.getCurrentDateTime()}
              {...form.getInputProps("time")}
              onChange={(v) => {
                if (v) {
                  const calc = v - (v % (1000 * 60 * 5));
                  form.setFieldValue("time", calc);
                }
              }}
            />

            <NumberInput
              required
              min={1}
              label={t("DURATION(MINUTES)")}
              {...form.getInputProps("duration")}
            />
          </Group>
          <Input.Wrapper label={t("NOTIFICATIONS")}>
            <Stack p="md" spacing="xs">
              <Checkbox
                label={t("EMAIL")}
                {...form.getInputProps("emailNotif", {
                  type: "checkbox",
                })}
              />
              <Checkbox
                label={t("SMS")}
                {...form.getInputProps("smsNotif", {
                  type: "checkbox",
                })}
              />
            </Stack>
          </Input.Wrapper>
          {/* <Input.Wrapper mt="sm" label={t("TIMESLOT")} required></Input.Wrapper> */}

          <Group position="right">
            <SaveButton canSave={form.isValid()} state={submitState} />
          </Group>
        </Stack>
      </form>
    </Stack>
  );
};
