import {
  errorResult,
  GHOnHTTPRequestData,
  normalizeGHOnHTTPRequestResponse,
  successResult,
} from "beitary-shared";
import { cfAPI } from "services/firebase/FirebaseService";

// const signup = async (data: SUOnHTTPRequestData) => {
//   try {
//     const result = await cfAPI.post(`/auth/signup`, data);
//     const response = normalizeSUOnHTTPRequestResponse(result.data);
//     return successResult({ message: "SIGNED_UP", payload: response });
//   } catch (error: any) {
//     console.log(error);

//     return errorResult({ message: error.message });
//   }
// };

// TODO move to shared!!!

const getHelp = async (data: GHOnHTTPRequestData) => {
  try {
    const result = await cfAPI.post(`/help`, data);
    const response = normalizeGHOnHTTPRequestResponse(result.data);
    return successResult({ message: "HELP_SUCCESS", payload: response });
  } catch (error: any) {
    console.log(error);

    return errorResult({ message: error.message });
  }
};

export const api = {
  // signup,
  getHelp,
};
