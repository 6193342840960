import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Group,
  Loader,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { IconExternalLink, IconRefresh } from "@tabler/icons-react";
import { BFile, id_util } from "beitary-shared";
import { BBox, BLoader } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface DownloadHistoryProps {}

export const DownloadHistory = () => {
  const { t } = useTranslation();
  const db = useDBServices().filesDBService;
  // const [submitState, setSubmitState] = useSubmitState();

  const [startAfterTime, setStartAfterTime] = useState<number | undefined>(
    undefined
  );
  const [startBeforeTime, setStartBeforeTime] = useState<number | undefined>(
    undefined
  );

  const [files, setFiles] = useState<undefined | BFile[] | null>(undefined);

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [refresh, setRefresh] = useState(id_util.newId36());

  const handleNextPage = () => {
    fetchFiles({ startAfterTime });
  };

  const handlePreviousPage = () => {
    fetchFiles({ startBeforeTime });
  };

  interface fetchTasksProps {
    startAfterTime?: number;
    startBeforeTime?: number;
    startAtTime?: number;
  }

  const fetchFiles = useCallback(
    async ({
      startAfterTime,
      startBeforeTime,
      startAtTime,
    }: fetchTasksProps) => {
      setRefreshing(true);
      const props = {
        startAfterTime,
        startBeforeTime,
        startAtTime,
      };
      const result = await db.getReportingBFiles(props);

      setRefreshing(false);
      // ... handle pagination ...
      if (result.payload && result.payload.length > 0) {
        setStartAfterTime(result.payload[result.payload.length - 1].createdAt);
        setStartBeforeTime(result.payload[0].createdAt);
        setFiles(result.payload);
      } else {
        // // No more data to fetch
        if (startAfterTime) setStartAfterTime(undefined);
        if (startBeforeTime) setStartBeforeTime(undefined);
      }
    },
    [db]
  );

  useEffect(() => {
    fetchFiles({});
  }, [fetchFiles, refresh]);

  const rows = useMemo(
    () =>
      (files ?? []).map((file) => (
        <tr key={file.id}>
          <td>
            <Group position="apart" p="xs">
              <Group position="left">
                <Text truncate>{file.originalName}</Text>
                <Text w={80}>{`${(
                  Number(file.fileSize) /
                  (1024 * 1024)
                ).toFixed(2)} mb`}</Text>
                <Text truncate>{file.comment}</Text>
              </Group>
              <Anchor
                color="dark"
                weight={500}
                href={file.dlURL}
                download={file.originalName}
                target="_blank"
              >
                {<IconExternalLink size={12} />}
              </Anchor>
            </Group>
          </td>
        </tr>
      )),
    [files]
  );

  return (
    <Stack>
      {files === undefined && <BLoader />}
      {files === null && <Text>{t("ERROR")}</Text>}
      {/* {files?.length === 0 && <Text>{t("NO_FILES_FOUND")}</Text>} */}
      {files && (
        <BBox
          header={
            <Group position="apart">
              <Text>{t("REPORTS_DOWNLOADED")}</Text>

              <Group>
                <ActionIcon
                  onClick={() => {
                    setRefresh(id_util.newId36());
                  }}
                >
                  {refreshing ? (
                    <Loader size="xs" />
                  ) : (
                    <IconRefresh size={18} />
                  )}
                </ActionIcon>
              </Group>
            </Group>
          }
        >
          <Box p="xl">
            <Table>
              <tbody>{rows}</tbody>
            </Table>
            <Group position="right" mt="xl">
              <Button onClick={handlePreviousPage} disabled={!startBeforeTime}>
                {t("PREVIOUS")}
              </Button>
              <Button onClick={handleNextPage} disabled={!startAfterTime}>
                {t("NEXT")}
              </Button>
            </Group>
          </Box>
        </BBox>
      )}
    </Stack>
  );
};
