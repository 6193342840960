import { Badge, Divider, Group, Stack, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  BoardingAppointment,
  BoardingAppointmentData,
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  obju,
  PatientSex,
  PatientSpecies,
  Result,
  tu,
} from "beitary-shared";
import { AsyncActionButton, BDateAndTimeInput, SaveButton } from "components";
import { ClientSelect, PatientSelect } from "features/Clients/components";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { BoardingResourceSelect } from "../BoardingResourceSelect";
import { ClientInfo } from "../ClientInfo";
import { PatientInfo } from "../PatientInfo";
import { AppointmentFormValues, rules } from "./AppointmentForm.rules";

export interface AppointmentFormProps {
  appointment?: BoardingAppointment;
  patientId?: string;
  patientName?: string;
  patientSex?: PatientSex;
  patientSpecies?: PatientSpecies;
  patientPictureURL?: string;
  clientId?: string;
  clientName?: string;
  defaultTime?: number;
  resourceId?: string;
}

export const AppointmentForm = ({
  appointment,
  clientId,
  patientId,
  clientName,
  patientName,
  patientPictureURL,
  patientSex,
  patientSpecies,
  defaultTime,
  resourceId,
}: AppointmentFormProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const org = useAppSelector(selectOrganization);
  const db = useDBServices().boardingAppointmentsDBService;

  const form = useForm<AppointmentFormValues>({
    initialValues: appointment ? { ...appointment } : undefined,
    validate: rules,
  });

  if (!form.values.patientId && patientId && patientName)
    form.setValues({
      patientId,
      patientName,
      patientPictureURL,
      patientSex,
      patientSpecies,
    });
  if (!form.values.boardingResourceId && resourceId)
    form.setValues({
      boardingResourceId: resourceId,
    });
  if (!form.values.from) {
    if (defaultTime) {
      form.setValues({
        from: defaultTime,
      });
    } else {
      form.setValues({
        from: tu.getCurrentDateTime(),
      });
    }
  }

  if (!form.values.clientId && clientId && clientName)
    form.setValues({ clientId, clientName });

  if (!org) return <Text>{t("ORGANIZATION_NOT_FOUND")}</Text>;

  const submit = async (values: AppointmentFormValues) => {
    const {
      clientId,
      clientName,
      from,
      to,
      patientId,
      patientName,
      patientSex,
      patientSpecies,
      boardingResourceId,
      patientPictureURL,
      notes,
    } = values;
    const notificationId = "submit-boarding-appointment";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(
          appointment
            ? "UPDATE_BOARDING_APPOINTMENT"
            : "ADD_BOARDING_APPOINTMENT"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;

    const newData: BoardingAppointmentData = {
      clientId,
      clientName,
      organizationId: org.id,
      organizationName: org.name,
      patientId,
      patientName,
      patientSex,
      patientSpecies,
      status: "BOOKED",
      boardingResourceId,
      from: tu.cleanTimeSecondsAndMillis(from),
      to: tu.cleanTimeSecondsAndMillis(to),
      patientPictureURL,
      yearWeeksCovered: tu.getYearWeeksCoveredStrings({
        from: tu.cleanTimeSecondsAndMillis(from),
        to: tu.cleanTimeSecondsAndMillis(to),
      }),
      notes,
    };

    obju.removeUndefined(newData);

    if (appointment) {
      result = await db.editBoardingAppointment(appointment.id, {
        ...appointment,
        ...newData,
      });
    } else {
      result = await db.addBoardingAppointment({
        data: {
          ...newData,
        },
      });
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const getButtons = (appointment: BoardingAppointment) => {
    switch (appointment.status) {
      case "BOOKED":
        return [
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.checkInBoardingAppointment(appointment.id)}
            child={t("CHECK_IN")}
            color="cyan"
            compact
          />,
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.cancelBoardingAppointment(appointment.id)}
            child={t("CANCEL")}
            color="red"
            variant={"outline"}
            compact
          />,
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.noShowBoardingAppointment(appointment.id)}
            child={t("NO_SHOW")}
            color="blue"
            variant={"outline"}
            compact
          />,
        ];

      case "CANCELED":
        return [
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.rescheduleBoardingAppointment(appointment.id)}
            child={t("RESCHEDULE")}
            color="cyan"
            compact
          />,
        ];

      case "CHECKED_IN":
        return [
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.checkOutBoardingAppointment(appointment.id)}
            child={t("CHECK_OUT")}
            color="cyan"
            compact
          />,
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.undoCheckinBoardingAppointment(appointment.id)}
            child={t("UNDO_CHECK_IN")}
            color="red"
            variant={"outline"}
            compact
          />,
        ];

      case "CHECKED_OUT":
        return [
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.undoCheckoutBoardingAppointment(appointment.id)}
            child={t("UNDO_CHECK_OUT")}
            color="red"
            variant={"outline"}
            compact
          />,
        ];

      case "NO_SHOW":
        return [
          <AsyncActionButton
            key={id_util.newId20()}
            f={async () => db.undoNoShowBoardingAppointment(appointment.id)}
            child={t("UNDO_NO_SHOW")}
            color="blue"
            compact
          />,
        ];

      default:
        return <></>;
    }
  };

  return (
    <Stack p="xl">
      {appointment && (
        <div>
          <Group position="apart">
            <Badge size="lg">{t(appointment.status + "_APT")}</Badge>
            <Group position="right">{getButtons(appointment)}</Group>
          </Group>
          <Divider mt="sm" />
        </div>
      )}
      <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
        <Stack>
          <Group align="flex-start" grow>
            {appointment ? (
              <>
                <ClientInfo
                  clientId={appointment.clientId}
                  clientName={appointment.clientName}
                />
                <PatientInfo
                  patientId={appointment.patientId}
                  patientName={appointment.patientName}
                />
              </>
            ) : (
              <>
                <ClientSelect
                  required
                  {...form.getInputProps("clientId")}
                  onChange={({ id, name }) => {
                    form.setValues({
                      clientId: id,
                      clientName: name,
                      patientId: undefined,
                    });
                  }}
                />

                <PatientSelect
                  required
                  ownerId={form.getInputProps("clientId").value}
                  {...form.getInputProps("patientId")}
                  onChange={({ id, name, thumbURL, sex, species }) => {
                    form.setFieldValue("patientId", id);
                    if (thumbURL)
                      form.setFieldValue("patientPictureURL", thumbURL);
                    form.setFieldValue("patientName", name);
                    form.setFieldValue("patientSex", sex);
                    form.setFieldValue("patientSpecies", species);
                  }}
                />
              </>
            )}
          </Group>
          <BoardingResourceSelect
            required
            {...form.getInputProps("boardingResourceId")}
          />
          <Group grow>
            <BDateAndTimeInput
              required
              label={t("CHECK_IN_TIME")}
              minDateTime={tu.cleanTimeSecondsAndMillis(
                tu.getCurrentDateTime()
              )}
              {...form.getInputProps("from")}
            />

            <BDateAndTimeInput
              required
              label={t("CHECK_OUT_TIME")}
              minDateTime={tu.cleanTimeSecondsAndMillis(
                tu.getCurrentDateTime()
              )}
              {...form.getInputProps("to")}
            />
          </Group>
          <Textarea
            label={t("NOTES")}
            minRows={4}
            {...form.getInputProps("NOTES")}
          />

          <Group position="right">
            <SaveButton canSave={form.isValid()} state={submitState} />
          </Group>
        </Stack>
      </form>
    </Stack>
  );
};
