import { ActionIcon, Group, Menu, Stack } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconDotsVertical, IconReceiptRefund } from "@tabler/icons-react";
import {
  errorNotification,
  Payment,
  successNotification,
  tu,
} from "beitary-shared";
import {
  BButtonDownload,
  BButtonEmail,
  BButtonPrint,
  BButtonStamp,
} from "components";
import { useCServices } from "hooks/useCService/useCService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { file_util } from "utils/file_util";
import { RefundPreviousPaymentForm } from "../RefundPreviousPaymentForm";

export interface PaymentMenuProps {
  payment: Payment;
}

export const PaymentMenu = ({ payment }: PaymentMenuProps) => {
  const { t } = useTranslation();

  const { id, type } = payment;

  const [formOpened, setFormOpened] = useState(false);

  const cservice = useCServices();

  const printPaymentReceipt = async () => {
    const result = await cservice.paymentsCService.getPaymentReceiptPDFFileURL(
      id
    );

    if (result.type === "success") {
      if (result.payload) {
        file_util.printFileFromURL({ url: result.payload.content });
      }
    } else {
      showNotification(
        errorNotification({
          id: "printPaymentReceipt-notif",
          message: t("ERROR_RETRIEVING_FILE"),
        })
      );
    }
  };

  const downloadPaymentReceipt = async () => {
    const result = await cservice.paymentsCService.getPaymentReceiptPDFFileURL(
      id
    );

    if (result.type === "success") {
      if (result.payload) {
        // Set the file name of the PDF
        const fileName = `${payment.clientName}_${t(
          "REFUND_RECEIPT"
        )}_${tu.getYYYYMMDDString(tu.getCurrentDateTime())}.pdf`;

        file_util.saveFileFromURL({ url: result.payload.content, fileName });
      }
    } else {
      showNotification(
        errorNotification({
          id: "doanloadInvoice-notif",
          message: t("ERROR_RETRIEVING_FILE"),
        })
      );
    }
  };

  const emailPaymentReceipt = async () => {
    const result = await cservice.paymentsCService.sendPaymentReceiptViaEmail(
      id
    );
    if (result.type === "success") {
      showNotification(
        successNotification({
          id: "emailPaymentReceipt-notif",
          message: t("EMAIL_QUEUED_FOR_DELIVERY"),
        })
      );
    } else {
      showNotification(
        errorNotification({
          id: "emailPaymentReceipt-notif",
          message: t("ERROR_QUEUING_EMAIL_FOR_DELIVERY"),
        })
      );
    }
  };

  const finalizePayment = async () => {
    const f =
      type === "CREDIT_BALANCE_PAYMENT" || type === "INVOICE_PAYMENT"
        ? cservice.paymentsCService.addPaymentSerialNumberAndDateIssued(id)
        : cservice.paymentsCService.addRefundSerialNumberAndDateIssued(id);

    const result = await f;
    if (result.type === "success") {
      showNotification(
        successNotification({
          id: "finalizeInvoice-notif",
          message: t("FINALIZE_INVOICE"),
        })
      );
    } else {
      showNotification(
        errorNotification({
          id: "xxxInvoice-notif",
          message: t("ERROR_FINALIZE_INVOICE"),
        })
      );
    }
  };

  return (
    <>
      <RefundPreviousPaymentForm
        parentPayment={payment}
        formOpened={formOpened}
        setFormOpened={setFormOpened}
      />
      <Menu withinPortal>
        <Menu.Target>
          <ActionIcon
          // color={theme.primaryColor}
          >
            <IconDotsVertical size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown p="xl">
          <Stack>
            <Group>
              <BButtonEmail f={async () => emailPaymentReceipt()} />
              <BButtonDownload f={async () => downloadPaymentReceipt()} />
              <BButtonPrint f={async () => printPaymentReceipt()} />
              <BButtonStamp
                disabled={
                  payment.status !== "SUCCESS" ||
                  !!payment.serialNumber ||
                  !!payment.dateIssued
                }
                f={async () => finalizePayment()}
              />
            </Group>
            <Menu.Item
              disabled={payment.totalPaymentAmount <= 0}
              onClick={() => {
                setFormOpened(true);
              }}
              icon={<IconReceiptRefund size={18} />}
            >
              {t("CREATE_REFUND")}
            </Menu.Item>
          </Stack>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
