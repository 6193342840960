import { MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import { store } from "app/store";
import { tu } from "beitary-shared";
import { AppShell } from "components/layout/AppShell";
import { selectClinicAppSettings } from "features/admin/clinic-settings/ClinicSettings.slice";
import { AppScreenLock } from "features/AppScreenLock/AppScreenLock";
import { AppWrapper } from "features/AppWrapper/AppWrapper";
import { Authentication } from "features/Authentication";
import { BSettingsListener } from "features/BSettingsListener/BSettingsListener";
import { HelpFloatingButton } from "features/help/components";
import { useIsMobile } from "hooks/useIsMobile";
import { RouterConfig } from "navigation/RouterConfig";
import { AbilityProvider } from "permissions";
import { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { mainTheme, mainThemeMobile } from "styles/themes/mainTheme";

const settings = store.getState().clinicSettings.appSettings;
const { locale } = tu.applyTimeConfig(settings.timezone, settings.language);

const MyApp = () => {
  const { i18n } = useTranslation();
  const isMobile = useIsMobile();
  console.log("isMobile: ", isMobile);

  const language = useAppSelector(selectClinicAppSettings)?.language ?? "FR";

  useEffect(() => {
    const changeLanguageHandler = (lang: string) => {
      i18n.changeLanguage(lang);
    };

    // console.log(`new lang: ${language}`);

    changeLanguageHandler(language);
  }, [language, i18n]);

  // TODO issue of refreshing listeners even when orgId doesnt change
  // is that useDBService used in app wrapper listens touser changes
  // as well, solution is to make one for reads and one for writes
  return (
    <MantineProvider
      theme={isMobile ? mainThemeMobile : mainTheme}
      withGlobalStyles
      withNormalizeCSS
    >
      <DatesProvider settings={{ locale, firstDayOfWeek: 1 }}>
        <ModalsProvider>
          <Notifications pr={60} />
          <BSettingsListener />
          <Authentication>
            <AbilityProvider>
              <AppWrapper>
                <AppScreenLock>
                  <AppShell>
                    <HelpFloatingButton />
                    <RouterConfig />
                  </AppShell>
                </AppScreenLock>
              </AppWrapper>
            </AbilityProvider>
          </Authentication>{" "}
        </ModalsProvider>
      </DatesProvider>
    </MantineProvider>
  );
};

export const App = withTranslation()(MyApp);
