import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { patientSexEnum, patientSpeciesEnum } from "../../../patients";
import { appointmentLocationEnum } from "../AppointmentLocation";
import { appointmentRequestStatusEnum } from "../AppointmentRequestStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const appointmentRequestDataV0Schema = z.object({
  status: appointmentRequestStatusEnum,
  location: appointmentLocationEnum,
  reasonForConsultation: z.string(),
  organizationId: z.string(),
  organizationName: z.string(),
  organizationLogoURL: z.string().optional(),
  clientId: z.string(),
  clientName: z.string(),
  doctorId: z.string().optional(),
  doctorName: z.string().optional(),
  patientId: z.string(),
  patientName: z.string(),
  patientSpecies: patientSpeciesEnum,
  patientSex: patientSexEnum,
  patientPictureURL: z.string().url().optional(),
  timeSlots: z.array(z.object({ from: z.number(), to: z.number() })).optional(),
});

type AppointmentRequestDataV0 = z.infer<typeof appointmentRequestDataV0Schema>;

const appointmentRequestV0Schema = baseRecordSchema.merge(
  appointmentRequestDataV0Schema
);

type AppointmentRequestV0 = z.infer<typeof appointmentRequestV0Schema>;

const normalizeAppointmentRequestV0 = (data: any): AppointmentRequest => {
  try {
    const appointmentRequestV0: AppointmentRequestV0 =
      appointmentRequestV0Schema.parse(data);

    const { ...rest } = appointmentRequestV0;

    // here it's same object cause current v is 0
    const appointmentRequest: AppointmentRequest = {
      ...rest,
    };
    return appointmentRequest;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const APPOINTMENT_REQUEST_VERSION = "0";

export type AppointmentRequestData = AppointmentRequestDataV0;

export type AppointmentRequest = BaseRecord & AppointmentRequestData;

export const appointmentRequestDataSchema = appointmentRequestDataV0Schema;

export const appointmentRequestSchema = appointmentRequestV0Schema;

export const normalizeAppointmentRequest = (data: any): AppointmentRequest => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeAppointmentRequestV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
