import { Catalog } from "features/admin";
import { Navigate, Route } from "react-router-dom";
import { BundlesRoutes } from "./bundles/BundlesRoutes";
import { ImagingIntegrationProductsRoutes } from "./imaging-integration-products/ImagingIntegrationProductsRoutes";
import { ProductsRoutes } from "./products/ProductsRoutes";

export const CatalogRoutes = (
  <Route path="catalog" element={<Catalog />}>
    <Route index element={<Navigate to="products" replace />} />
    {ProductsRoutes}
    {BundlesRoutes}
    {ImagingIntegrationProductsRoutes}
  </Route>
);
