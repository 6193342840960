import { inventoryItemUnitOfMeasurementEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InventoryItemUnitOfMeasurementSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const InventoryItemUnitOfMeasurementSelect = (
  props: InventoryItemUnitOfMeasurementSelectProps
) => {
  const { t } = useTranslation();

  const data = inventoryItemUnitOfMeasurementEnum.options.map((o) => ({
    value: o,
    label: t(o),
  }));

  return (
    <BSelect
      {...props}
      clearable={true}
      data={data}
      label={t("UNIT_OF_MEASUREMENT")}
      placeholder={t("UNIT_OF_MEASUREMENT_PLACEHOLDER")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
