import { ActionIcon, Divider, Group, Modal, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconDotsVertical } from "@tabler/icons-react";
import {
  errorNotification,
  Payment,
  successNotification,
  tu,
} from "beitary-shared";
import {
  BButtonDownload,
  BButtonEmail,
  BButtonPrint,
  BButtonStamp,
} from "components";
import { useCServices } from "hooks/useCService/useCService";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { file_util } from "utils/file_util";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";

export interface RefundSuccessModalProps {
  payment: Payment;
  hideActionIcon?: boolean;
  resetForm?: Function;
}

export const RefundSuccessModal = ({
  payment,
  hideActionIcon,
  resetForm,
}: RefundSuccessModalProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);

  const cservice = useCServices();

  const currency = org_params_util.getOrgParams().currency;

  const { id, totalPaymentAmount, paymentMethod, lastUpdatedAt } = payment;

  const printPaymentReceipt = async () => {
    const result = await cservice.paymentsCService.getPaymentReceiptPDFFileURL(
      id
    );

    if (result.type === "success") {
      if (result.payload) {
        file_util.printFileFromURL({ url: result.payload.content });
      }
    } else {
      showNotification(
        errorNotification({
          id: "printPaymentReceipt-notif",
          message: t("ERROR_RETRIEVING_FILE"),
        })
      );
    }
  };

  const downloadPaymentReceipt = async () => {
    const result = await cservice.paymentsCService.getPaymentReceiptPDFFileURL(
      id
    );

    if (result.type === "success") {
      if (result.payload) {
        // Set the file name of the PDF
        const fileName = `${payment.clientName}_${t(
          "REFUND_RECEIPT"
        )}_${tu.getYYYYMMDDString(tu.getCurrentDateTime())}.pdf`;

        file_util.saveFileFromURL({ url: result.payload.content, fileName });
      }
    } else {
      showNotification(
        errorNotification({
          id: "doanloadInvoice-notif",
          message: t("ERROR_RETRIEVING_FILE"),
        })
      );
    }
  };

  const emailPaymentReceipt = async () => {
    const result = await cservice.paymentsCService.sendPaymentReceiptViaEmail(
      id
    );
    if (result.type === "success") {
      showNotification(
        successNotification({
          id: "emailPaymentReceipt-notif",
          message: t("EMAIL_QUEUED_FOR_DELIVERY"),
        })
      );
    } else {
      showNotification(
        errorNotification({
          id: "emailPaymentReceipt-notif",
          message: t("ERROR_QUEUING_EMAIL_FOR_DELIVERY"),
        })
      );
    }
  };

  const finalizePayment = async () => {
    const result =
      await cservice.paymentsCService.addRefundSerialNumberAndDateIssued(id);
    if (result.type === "success") {
      showNotification(
        successNotification({
          id: "finalizeInvoice-notif",
          message: t("FINALIZE_INVOICE"),
        })
      );
    } else {
      showNotification(
        errorNotification({
          id: "xxxInvoice-notif",
          message: t("ERROR_FINALIZE_INVOICE"),
        })
      );
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        size="md"
        centered
        onClose={() => {
          setOpened(false);
          resetForm && resetForm();
        }}
        closeOnClickOutside={false}
        title={<Text color="green">{t("REFUND_SUCCESSFUL")}</Text>}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack p="xl">
          <Text>
            {t("DATE")}:
            <Text span ml="sm" color="dimmed">
              {tu.getDateAndTimeString(lastUpdatedAt)}
            </Text>
          </Text>
          <Divider />
          <Stack>
            <Text>
              {t("AMOUNT_REFUNDED")}:
              <Text span ml="sm" color="green">
                {mu.toD(-totalPaymentAmount)} {t(currency)}
              </Text>
            </Text>
            <Text>
              {t("REFUND_METHOD")}:
              <Text span ml="sm" color="cyan">
                {t(paymentMethod)}
              </Text>
            </Text>
          </Stack>
          <Divider />
          <Group>
            <BButtonEmail
              disabled={!payment.serialNumber || !payment.dateIssued}
              f={async () => emailPaymentReceipt()}
            />
            <BButtonDownload
              disabled={!payment.serialNumber || !payment.dateIssued}
              f={async () => downloadPaymentReceipt()}
            />
            <BButtonPrint
              disabled={!payment.serialNumber || !payment.dateIssued}
              f={async () => printPaymentReceipt()}
            />
            <BButtonStamp
              disabled={
                payment.status !== "SUCCESS" ||
                !!payment.serialNumber ||
                !!payment.dateIssued
              }
              f={async () => finalizePayment()}
            />
          </Group>
        </Stack>
      </Modal>
      {!hideActionIcon && (
        <ActionIcon onClick={() => setOpened(!opened)}>
          <IconDotsVertical />
        </ActionIcon>
      )}
    </>
  );
};
