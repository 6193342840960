const getCopyTranslation = (language: string) => {
  switch (language) {
    case "EN":
      return "(copy)";
    case "FR":
      return "(copie)";
    case "AR":
      return "(نسخة)";
    default:
      return "(copy)";
  }
};

const getConsultationTranslation = (language: string) => {
  switch (language) {
    case "EN":
      return "Consultation";
    case "FR":
      return "Consultation";
    case "AR":
      return "Consultation";
    default:
      return "Consultation";
  }
};

export const i18n_utils = {
  getCopyTranslation,
  getConsultationTranslation,
};
