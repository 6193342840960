import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { Invoice } from "beitary-shared";
import { selectClients } from "features/Clients/Clients.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaymentForm, RefundPaymentForm } from "./components";

interface PaymentProps {
  isRefund?: boolean;
}

export const Payment = ({ isRefund }: PaymentProps) => {
  const { t } = useTranslation();

  const { clientId } = useParams();

  const [invoices, setInvoices] = useState<Invoice[] | undefined>(undefined);

  const client = useAppSelector(selectClients).find((c) => c.id === clientId);

  const db = useDBServices();

  useEffect(() => {
    const listener = clientId
      ? db.invoicesDBService.invoices.getClientNonPaidInvoicesListener(
          clientId,
          setInvoices
        )
      : () => null;

    return () => {
      listener();
    };
  }, [clientId, db]);

  // console.log(invoices);

  if (invoices === undefined || client === undefined) {
    return <Text>{t("LOADING")}</Text>;
  } else if (client === null) {
    return <Text>{t("NOT_FOUND")}</Text>;
  } else {
    return isRefund ? (
      <RefundPaymentForm invoices={invoices} client={client} />
    ) : (
      <PaymentForm invoices={invoices} client={client} />
    );
  }
};
