import {
  Button,
  Container,
  Group,
  Modal,
  Stack,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
  Payment,
  PaymentData,
} from "beitary-shared";
import { BMoneyInput } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentMethodSelect } from "../PaymentMethodSelect";
import { RefundSuccessModal } from "../RefundSuccessModal";
import {
  RefundPreviousPaymentFormValues,
  rules,
} from "./RefundPreviousPaymentForm.rules";

export interface RefundPreviousPaymentFormProps {
  parentPayment: Payment;
  formOpened: boolean;
  setFormOpened: Function;
}

export const RefundPreviousPaymentForm = ({
  parentPayment,
  formOpened,
  setFormOpened,
}: RefundPreviousPaymentFormProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const { addPayment, getPaymentListener } =
    useDBServices().invoicesDBService.payments;

  const [paymentResult, setPaymentResult] = useState<
    Payment | null | undefined
  >(undefined);

  const [paymentResultId, setPaymentResultId] = useState<
    string | null | undefined
  >(undefined);

  const notificationId = "submit-payment";

  useEffect(() => {
    const updatePaymentResult = (payment: Payment | null) => {
      if (payment && payment.status === "ERROR") {
        updateNotification({
          ...getNotificationByResultType("error")({
            message: t(payment.note ?? ""),
            title: t("CHECK_OUT_REFUND"),
          }),
          id: notificationId,
        });
        setSubmitState("error");
      } else if (payment && payment.status === "SUCCESS") {
        updateNotification({
          ...getNotificationByResultType("success")({
            message: t("REFUND_PROCESSED"),
            title: t("CHECK_OUT_REFUND"),
          }),
          id: notificationId,
        });
        setSubmitState("success");
      } else if (payment && payment.status === "PROCESSING") {
        updateNotification({
          ...loadingInfoNotification({
            id: notificationId,
            message: t("REFUND_PROCESSING"),
            title: t("CHECK_OUT_REFUND"),
          }),
          id: notificationId,
        });
        setSubmitState("success");
      }

      setPaymentResult(payment);
    };

    const listener = paymentResultId
      ? getPaymentListener(paymentResultId, updatePaymentResult)
      : () => null;

    return () => {
      listener();
    };
  }, [paymentResultId, getPaymentListener, setSubmitState, t]);

  const form = useForm<RefundPreviousPaymentFormValues>({
    initialValues: {
      type: "PAYMENT_REFUND",
      creditAmount: 0,
      change: 0,
      totalPaymentAmount: 0,
      paymentMethod: "CASH",
      payToCreditBalance: false,
      clientId: parentPayment.clientId,
      clientName: parentPayment.clientName,
      status: "PENDING",
      invoices: {},
      parentId: parentPayment.id,
      parentSerialNumber: parentPayment.serialNumber,
      totalInvoicesPaymentAmounts: 0,
    },
    validate: rules,
  });

  const disbleCheckout =
    submitState === "success" || form.values.totalPaymentAmount === 0;

  const resetForm = () => {
    setSubmitState("not-submitted");
    form.reset();
    setPaymentResult(undefined);
    setPaymentResultId(undefined);
  };

  const submit = async (values: RefundPreviousPaymentFormValues) => {
    const newPayment: PaymentData = {
      ...values,
      totalPaymentAmount: -values.totalPaymentAmount,
      creditAmount:
        values.paymentMethod === "CREDIT_BALANCE"
          ? values.totalPaymentAmount
          : 0,
      change:
        values.paymentMethod !== "CREDIT_BALANCE"
          ? values.totalPaymentAmount
          : 0,
      payToCreditBalance: values.paymentMethod === "CREDIT_BALANCE",
    };

    obju.removeUndefined(newPayment);
    console.log("newPayment");
    console.log(newPayment);

    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("CHECK_OUT_REFUND"),
      })
    );
    setSubmitState("pending-response");
    const result = await addPayment(newPayment);
    // console.log("result");
    // console.log(result);

    if (result.type === "success") {
      resetForm();
      setFormOpened(false);
      setPaymentResultId(result.payload?.id);
    } else {
      setSubmitState("error");
      setPaymentResultId(null);
      updateNotification({
        ...getNotificationByResultType(result.type)({
          message: t(result.message),
        }),
        id: notificationId,
      });
    }
  };

  return (
    <>
      <Modal
        size="xl"
        opened={formOpened}
        onClose={() => {
          resetForm();
          setFormOpened(false);
        }}
        title={t("PAYMENT_REFUND")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
          <Container fluid>
            <Stack
              p="xl"
              sx={{
                flexGrow: 5,
              }}
            >
              <PaymentMethodSelect
                disableCredit={false}
                {...form.getInputProps("paymentMethod")}
              />
              <BMoneyInput
                required
                min={0}
                max={parentPayment.totalPaymentAmount}
                label={t("REFUND_AMOUNT")}
                {...form.getInputProps("totalPaymentAmount")}
                onChange={(v) => {
                  if (v === "") {
                    form.setFieldValue(`totalPaymentAmount`, 0);
                  } else if (v <= parentPayment.totalPaymentAmount) {
                    form.setFieldValue(`totalPaymentAmount`, v);
                  } else {
                    form.setFieldValue(
                      `totalPaymentAmount`,
                      parentPayment.totalPaymentAmount
                    );
                  }
                }}
              />
              <Textarea
                mt="xl"
                label={t("REFUND_NOTE")}
                placeholder={t("ADD_A_NOTE")}
                {...form.getInputProps("note")}
              />
              <Group position="right">
                <Button
                  disabled={disbleCheckout}
                  loading={submitState === "pending-response"}
                  type="submit"
                >
                  {t("CHECK_OUT_REFUND")}
                </Button>
              </Group>
            </Stack>
          </Container>
        </form>
      </Modal>
      {paymentResult && paymentResult.status === "SUCCESS" && (
        <RefundSuccessModal
          payment={paymentResult}
          hideActionIcon
          resetForm={resetForm}
        />
      )}
    </>
  );
};
