import { z } from "zod";
import { sourceEnum, UnableToNormalizeData } from "../../types-schemas";

export const gICOnCallDataSchema = z.object({
  accountId: z.string(),
  organizationId: z.string(),
  authorName: z.string(),
  authorId: z.string(),
  source: sourceEnum,
});

export type GICOnCallData = z.infer<typeof gICOnCallDataSchema>;

export const normalizeGICOnCallData = (data: any): GICOnCallData => {
  try {
    return gICOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const gICOnCallResponseSchema = z.object({
  contentType: z.enum(["application/json"]),
  content: z.string(),
});

export type GICOnCallResponse = z.infer<typeof gICOnCallResponseSchema>;

export const normalizeGICOnCallResponse = (data: any): GICOnCallResponse => {
  try {
    return gICOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
