import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { paymentMethodsEnum } from "./Payment.method";
import { paymentStatusEnum } from "./Payment.status";
import { paymentTypeEnum } from "./Payment.type";

/**
 * schema and types versions
 * from first to last
 */

// v0

export const paymentInvoiceDataSchema = z.object({
  invoiceId: z.string(),
  serialNumber: z.string().optional(),
  dateIssued: z.number().int().positive().optional(),
  paymentAmount: z.number().int(),
  total: z.number().int(),
  paid: z.number().int(), // amount previously paid
  refunded: z.number().int(),
  balanceDue: z.number().int(),
  createdAt: z.number().int(),
});

export type PaymentInvoiceData = z.infer<typeof paymentInvoiceDataSchema>;

const paymentDataV0Schema = z.object({
  status: paymentStatusEnum,
  type: paymentTypeEnum,
  dateIssued: z.number().int().positive().optional(),
  serialNumber: z.string().optional(),
  parentId: z.string().optional(), // for refund payments
  parentSerialNumber: z.string().optional(), // for refund payments
  invoices: z.record(paymentInvoiceDataSchema),
  clientId: z.string(),
  clientName: z.string(),
  paymentMethod: paymentMethodsEnum,
  totalPaymentAmount: z.number().int(),
  totalInvoicesPaymentAmounts: z.number().int(),
  change: z.number().int(),
  creditAmount: z.number().int(),
  payToCreditBalance: z.boolean(),
  note: z.string().optional(),
});

type PaymentDataV0 = z.infer<typeof paymentDataV0Schema>;

const paymentV0Schema = baseRecordSchema.merge(paymentDataV0Schema);

type PaymentV0 = z.infer<typeof paymentV0Schema>;

const normalizePaymentV0 = (data: any): Payment => {
  try {
    const paymentV0: PaymentV0 = paymentV0Schema.parse(data);

    const { ...rest } = paymentV0;

    // here it's same object cause current v is 0
    const payment: Payment = {
      ...rest,
    };
    return payment;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PAYMENT_VERSION = "0";

export type PaymentData = PaymentDataV0;

export type Payment = BaseRecord & PaymentData;

export const paymentDataSchema = paymentDataV0Schema;

export const paymentSchema = paymentV0Schema;

export const normalizePayment = (data: any): Payment => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizePaymentV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
