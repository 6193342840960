import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const Items = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("inventory-items/suppliers")
    ? "suppliers"
    : path.includes("inventory-items/locations")
    ? "locations"
    : "items";

  return (
    <Tabs
      mt="xl"
      value={activeTab}
      onTabChange={(value) => {
        navigate(`${value}`);
      }}
      variant="pills"
      radius="xs"
    >
      <Tabs.List mb="xl">
        <Tabs.Tab value="items">{t("ITEMS")}</Tabs.Tab>
        <Tabs.Tab value="suppliers">{t("SUPPLIERS")}</Tabs.Tab>
        <Tabs.Tab value="locations">{t("LOCATIONS")}</Tabs.Tab>
      </Tabs.List>
      <Outlet />
    </Tabs>
  );
};
