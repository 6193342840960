import { z } from "zod";
import { genericStatusEnum } from "../../../../types-schemas/common/status/GenericStatus";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { reminderNotificationSchema } from "./Reminder.notification";

/**
 * schema and types versions
 * from first to last
 */

// v0

export const reminderDueDateTimeUnitEnum = z.enum([
  "DAY",
  "WEEK",
  "MONTH",
  "YEAR",
]);

const reminderDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  triggerProductId: z.string(),
  dueIn: z.number().int().positive().min(1),
  timeUnit: reminderDueDateTimeUnitEnum,
  autocompleteProductIds: z.array(z.string()).min(1).max(5),
  notifications: z.array(reminderNotificationSchema).max(5).optional(),
});

type ReminderDataV0 = z.infer<typeof reminderDataV0Schema>;

const reminderV0Schema = baseRecordSchema.merge(reminderDataV0Schema);

type ReminderV0 = z.infer<typeof reminderV0Schema>;

const normalizeReminderV0 = (data: any): Reminder => {
  try {
    const reminderV0: ReminderV0 = reminderV0Schema.parse(data);

    const { ...rest } = reminderV0;

    // here it's same object cause current v is 0
    const reminder: Reminder = {
      ...rest,
    };
    return reminder;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const REMINDER_VERSION = "0";

export type ReminderData = ReminderDataV0;

export type Reminder = BaseRecord & ReminderData;

export const reminderDataSchema = reminderDataV0Schema;

export const reminderSchema = reminderV0Schema;

export const normalizeReminder = (data: any): Reminder => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeReminderV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
