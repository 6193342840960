import { Avatar, Group, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveDoctors } from "features/admin/members/Members.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface DoctorSelectProps {
  required?: boolean;
  onChange: ({ id, name }: { id: string; name: string }) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  label?: string;
}

export const DoctorSelect = ({ label, ...props }: DoctorSelectProps) => {
  const { t } = useTranslation();
  const doctors = useAppSelector(selectActiveDoctors);

  const onSelectChange = (value?: string) => {
    if (value) {
      const doctor = doctors.find((o) => o.id === value);
      if (doctor) {
        props.onChange({
          id: value,
          name: doctor.name,
        });
      }
    }
  };

  const data = doctors.map((o) => ({
    value: o.id,
    label: o.name,
    thumbURL: o.thumbURL,
  }));

  return (
    <BSelect
      {...props}
      itemComponent={SelectItem}
      data={data}
      onChange={onSelectChange}
      label={label ?? t("SUPERVISING_DOCTOR")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  thumbURL: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ thumbURL, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={thumbURL} radius="xl" />

        <Text size="sm">{label}</Text>
      </Group>
    </div>
  )
);
