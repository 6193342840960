import {
  Box,
  Divider,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BoardingAppointment, id_util, tu } from "beitary-shared";
import { selectBoardingResources } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { ReactNode } from "react";
import { Column, Row } from "./components";

export interface CalendarViewProps {
  weekAppointments: { [x: string]: BoardingAppointment[][] };
  selectedDate: number;
}

export const CalendarView = ({
  weekAppointments,
  selectedDate,
}: CalendarViewProps) => {
  const theme = useMantineTheme();

  const boardingResources = useAppSelector(selectBoardingResources);
  const ids = boardingResources.map((r) => r.id);

  const layersPerResource = boardingResources.map((resource) => ({
    resourceId: resource.id,
    nOfLayers: weekAppointments[resource.id]?.length ?? 0,
  }));

  const getResourcesColumn = () => {
    const items: ReactNode[] = [
      <Box
        key={id_util.newId20()}
        h={80}
        w={160}
        sx={{
          border: `1px solid ${theme.colors.cyan[2]}`,
          justifyItems: "center",
        }}
      ></Box>,
    ];
    layersPerResource.forEach((l) => {
      const resource = boardingResources.find((r) => r.id === l.resourceId);

      if (resource)
        items.push(
          <Box
            key={id_util.newId20()}
            h={120 * Math.max(l.nOfLayers, 1)}
            w={160}
            sx={{
              border: `1px solid ${theme.colors.cyan[2]}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Group h="100%" w="100%" spacing={0}>
              <Divider
                orientation="vertical"
                size={12}
                maw="12px"
                color={resource.color}
                m={12}
                sx={{
                  borderRadius: 5,
                }}
              />
              <Text truncate>{resource.name}</Text>
            </Group>
          </Box>
        );
    });
    return (
      <Stack spacing={0} w={160}>
        {items}
      </Stack>
    );
  };

  // rendering these here cauz position absolute relatively to the calendar
  const getLayers = () => {
    const items: ReactNode[] = [];

    const allLayers: BoardingAppointment[][] = [];
    const colors: { [x: number]: string } = {};

    ids.forEach((id) => {
      const color = boardingResources.find((r) => r.id === id)?.color ?? "grey";
      for (
        let colIndex = allLayers.length;
        colIndex < allLayers.length + weekAppointments[id]?.length ?? 0;
        colIndex++
      ) {
        colors[colIndex] = color;
      }
      const arrs =
        weekAppointments[id]?.length > 0 ? weekAppointments[id] : [[]];
      allLayers.push(...arrs);
    });

    allLayers.forEach((apts2, index) => {
      items.push(
        <Row
          key={id_util.newId20()}
          appointments={apts2}
          index={index}
          selectedDate={selectedDate}
          color={colors[index] ?? "grey"}
        />
      );
    });

    return items;
  };

  return (
    <Group
      miw={"100%"}
      bg="white"
      align="start"
      spacing={0}
      sx={{ position: "relative" }}
    >
      {getResourcesColumn()}
      {[0, 1, 2, 3, 4, 5, 6].map((i) => (
        <Column
          key={id_util.newId20()}
          layers={layersPerResource}
          selectedDate={selectedDate}
          columnNumber={i}
          nColumns={7}
          // totalApts={0} // todo fix
          dateTime={tu.getDateTime(
            tu.getDate(
              tu.getStartOfWeek(tu.getDate(selectedDate)) +
                i * 24 * 60 * 60 * 1000
            )
          )}
        />
      ))}
      {getLayers()}
    </Group>
  );
};
