import { ActionIcon, Modal } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Appointment } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentForm } from "../AppointmentForm";

export interface EditAppointmentModalProps {
  appointment: Appointment;
}

export const EditAppointmentModal = ({
  appointment,
}: EditAppointmentModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="xl"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("EDIT_APPOINTMENT")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div>
          <AppointmentForm appointment={appointment} />
        </div>
      </Modal>
      <ActionIcon onClick={() => setOpened(true)}>
        <IconEdit size={18} />
      </ActionIcon>
    </>
  );
};
