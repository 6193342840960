import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const MoveBackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button variant="outline" onClick={() => navigate(-1)}>
      {t("MOVE_BACK")}
    </Button>
  );
};
