import { z } from "zod";

/**
 * read r
 * write w: can also read
 */
export const authorizationValues = ["r", "w", "n"] as const;

export const authorizationEnum = z.enum(authorizationValues);

export type AuthorizationEnum = z.infer<typeof authorizationEnum>;
