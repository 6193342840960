import {
  errorResult,
  GICOnCallData,
  GICOnCallResponse,
  obju,
  Result,
  Source,
  successResult,
  VUEPOnCallData,
  VUEPOnCallResponse,
} from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";

interface GlobalImportClient {
  ({
    organizationId,
    authorId,
    authorName,
    accountId,
    source,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    authorId: string;
    authorName: string;
    accountId: string;
    source: Source;
  }): Promise<Result<GICOnCallResponse | null>>;
}

const globalImportClient: GlobalImportClient = async ({
  organizationId,
  authorId,
  authorName,
  accountId,
  source,
  callable,
  fcts,
}) => {
  const globalImportClientOnCall = callable<GICOnCallData, GICOnCallResponse>(
    fcts,
    "callable-globalImportClientOnCall"
  );
  try {
    const { data } = await globalImportClientOnCall({
      organizationId,
      authorId,
      authorName,
      accountId,
      source,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

interface ValidateClientEmailAndPhoneNumber {
  (props: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    authorId: string;
    authorName: string;
    phoneNumber: string;
    email?: string;
    source: Source;
  }): Promise<Result<VUEPOnCallResponse | null>>;
}

const validateClientEmailAndPhoneNumber: ValidateClientEmailAndPhoneNumber =
  async ({
    organizationId,
    authorId,
    authorName,
    phoneNumber,
    email,
    source,
    callable,
    fcts,
  }) => {
    const validateUserEmailPhoneOnCall = callable<
      VUEPOnCallData,
      VUEPOnCallResponse
    >(fcts, "callable-validateUserEmailPhoneOnCall");

    const callData = {
      phoneNumber,
      email,
    };
    obju.removeUndefined(callData); // added this cauz undefined mail was being
    // received as null and cauzing issues in cloud functions

    try {
      const { data } = await validateUserEmailPhoneOnCall({
        organizationId,
        authorId,
        authorName,
        data: callData,
        source,
      });

      return successResult({
        message: "success",
        payload: data,
      });
    } catch (err: any) {
      console.error("Error:", err);
      return errorResult({ message: err.message });
    }
  };

// we inject dependencies to improve testability
export const clients = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    globalImportClient: (accountId: string) =>
      globalImportClient({
        callable,
        fcts,
        organizationId,
        accountId,
        authorId,
        authorName,
        source,
      }),
    validateClientEmailAndPhoneNumber: (data: {
      phoneNumber: string;
      email?: string;
    }) =>
      validateClientEmailAndPhoneNumber({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        source,
        ...data,
      }),
  };
};
