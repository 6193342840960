import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Modal,
  NumberInput,
  Stack,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconCircleCheck, IconTransferIn } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  id_util,
  InventoryTransactionData,
  loadingInfoNotification,
  PurchaseOrderItem,
  PurchaseOrderItemData,
  tu,
} from "beitary-shared";
import { BMoneyInput } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface PurchaseOrderItemReceiveModalProps {
  purchaseOrderId: string;
  purchaseOrderItem: PurchaseOrderItem;
}

export const PurchaseOrderItemReceiveModal = ({
  purchaseOrderId,
  purchaseOrderItem,
}: PurchaseOrderItemReceiveModalProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();

  const { purchaseOrders, inventoryItems, inventoryTransactions } =
    useDBServices().inventoryDBService;

  const { updatePurchaseOrderItem, addPurchaseOrderItems } = purchaseOrders;
  const { updateInventoryItem } = inventoryItems;
  const { addInventoryTransaction } = inventoryTransactions;

  const [opened, setOpened] = useState(false);

  const [receivedQty, setReceivedQty] = useState(0);
  const [newCostPerUOM, setNewCostPerUOM] = useState(
    purchaseOrderItem.costPerUnitOfMeasurement
  );
  const [createNewItemWithDifference, setCreateNewItemWithDifference] =
    useState(false);

  const submit = async () => {
    setSubmitState("pending-response");

    const notificationId = id_util.newId6();
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_PURCHASE_ORDER_ITEM"),
      })
    );

    // update purchase order
    const result = await updatePurchaseOrderItem({
      purchaseOrderId,
      data: {
        qtyReceived: receivedQty,
        costPerUnitOfMeasurement: newCostPerUOM,
        qtyOrdered: receivedQty,
        status: "RECEIVED",
      },
      id: purchaseOrderItem.id,
    });

    // create new item with remaining qty
    if (canNewItem && createNewItemWithDifference) {
      const newPOI: PurchaseOrderItemData = {
        ...purchaseOrderItem,
        qtyOrdered: purchaseOrderItem.qtyOrdered - receivedQty,
        costPerUnitOfMeasurement: newCostPerUOM,
      };
      await addPurchaseOrderItems({
        dataArr: [newPOI],
        purchaseOrderId,
      });
    }

    // update inv item price
    if (purchaseOrderItem.costPerUnitOfMeasurement !== newCostPerUOM) {
      await updateInventoryItem(purchaseOrderItem.inventoryItemId, {
        costPerUnitOfMeasurement: Math.floor(newCostPerUOM),
      });
    }

    // create transaction
    const trans: InventoryTransactionData = {
      costPerUnit: Math.floor(newCostPerUOM),
      qty: receivedQty * purchaseOrderItem.inventoryItemUnits,
      date: tu.getCurrentDateTime(),
      invItemId: purchaseOrderItem.inventoryItemId,
      invItemName: purchaseOrderItem.inventoryItemName,
      sourceType: "PURCHASE_ORDER",
      state: "PENDING",
      taxRate: purchaseOrderItem.taxRate,
      type: "PURCHASE",
      sourceId: purchaseOrderId,
    };

    await addInventoryTransaction(trans);

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const canNewItem =
    receivedQty < purchaseOrderItem.qtyOrdered && receivedQty > 0;

  return (
    <>
      <Modal
        size="lg"
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("RECEIVE_INVENTORY_ITEM")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack p="xl">
          <Group>
            <NumberInput
              disabled
              label={t("QUANTITY_ORDERED")}
              precision={2}
              value={purchaseOrderItem.qtyOrdered}
            />
            <NumberInput
              label={t("QUANTITY_RECEIVED")}
              min={0}
              precision={2}
              value={receivedQty}
              max={purchaseOrderItem.qtyOrdered}
              onChange={(v) => {
                if (v) setReceivedQty(v);
              }}
            />
          </Group>

          <BMoneyInput
            label={t("COST_PER_UNIT_OF_MEASUREMENT_NO_TAXES")}
            value={newCostPerUOM}
            onChange={(v) => v !== "" && setNewCostPerUOM(v)}
          />
          {
            <Checkbox
              disabled={!canNewItem}
              label={t("CREATE_NEW_ITEM_WITH_DIFFERENCE_QTY")}
              checked={createNewItemWithDifference}
              onChange={(e) =>
                setCreateNewItemWithDifference(e.currentTarget.checked)
              }
            />
          }
          <Group position="right">
            <Button
              disabled={receivedQty === 0}
              loading={submitState === "pending-response"}
              color={"green"}
              leftIcon={<IconCircleCheck size={18} />}
              onClick={submit}
            >
              {t("SAVE")}
            </Button>
          </Group>
        </Stack>
      </Modal>
      <ActionIcon onClick={() => setOpened(true)}>
        <IconTransferIn size={18} />
      </ActionIcon>
    </>
  );
};
