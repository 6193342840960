import {
  Alert,
  Avatar,
  Badge,
  Button,
  Container,
  Flex,
  Group,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import { IconAlertCircle, IconPaw } from "@tabler/icons-react";
import { OrgPermissions, Patient, tu } from "beitary-shared";
import {
  AlertTooltip,
  ArchiveConfirmationModal,
  BBox,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
  MoveBackButton,
} from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PatientTabs, PatientUpcomingAppointments } from "./components";
import { Actions } from "./components/Actions";
import { PatientWeights } from "./components/PatientWeights";

interface WrapperProps {
  patient: Patient;
}

export const Wrapper = ({ patient }: WrapperProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deletePatient, archivePatient, dearchivePatient } =
    useDBServices().patientsDBService;

  return (
    <Container fluid>
      <Stack mt="xl">
        <Group mb="md">
          <MoveBackButton />
        </Group>

        <Flex
          mih={50}
          gap="md"
          justify="flex-start"
          align="flex-start"
          direction={{ base: "column", md: "row" }}
        >
          <Stack
            sx={{
              flex: 4,
              width: "100%",
            }}
          >
            <BBox
              header={
                <Group position="apart">
                  <Group spacing={4}>
                    <Avatar
                      src={patient.thumbURL}
                      size={40}
                      radius="xl"
                      mr="xs"
                    >
                      <IconPaw size={25} />
                    </Avatar>
                    <Text weight={500}>{patient.name}</Text>
                    {patient.alert && (
                      <AlertTooltip alert={patient.alert} size={20} />
                    )}
                    <Badge size="lg" radius="xs" ml="xl">
                      {t(patient.status)}
                    </Badge>
                  </Group>
                  <Group>
                    <BCan I="do" a={OrgPermissions.PATIENTS_CLIENTS_CUD}>
                      <Button
                        onClick={() =>
                          navigate(
                            `/clients/${patient.ownerId}/patients/edit/${patient.id}`
                          )
                        }
                      >
                        {t("EDIT")}
                      </Button>
                      <DeleteConfirmationModal
                        button
                        f={async () => deletePatient(patient.id)}
                        item={patient}
                      />
                      {patient.status === "ACTIVE" && (
                        <ArchiveConfirmationModal
                          button
                          f={async () => archivePatient(patient.id)}
                          item={patient}
                        />
                      )}
                      {patient.status === "ARCHIVED" && (
                        <DearchiveConfirmationModal
                          button
                          f={async () => dearchivePatient(patient.id)}
                          item={patient}
                        />
                      )}
                    </BCan>
                  </Group>
                </Group>
              }
            >
              <SimpleGrid p="xl" cols={3}>
                <div>
                  <Text color="cyan">{t("CLIENT")}</Text>
                  <Text>{patient.ownerName}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("SEX")}</Text>
                  <Text>{t(patient.sex)}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("DATE_OF_BIRTH")}</Text>
                  <Text>{tu.getDateString(patient.dateOfBirth) ?? "-"}</Text>
                </div>
                {patient.status === "DECEASED" && patient.dateOfDeath && (
                  <div>
                    <Text color="cyan">{t("DATE_OF_DEATH")}</Text>
                    <Text>{tu.getDateString(patient.dateOfDeath) ?? "-"}</Text>
                  </div>
                )}
                <div>
                  <Text color="cyan">{t("SPECIES")}</Text>
                  <Text>{t(patient.species)}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("BREED")}</Text>
                  <Text>{patient.breed ?? "-"}</Text>
                </div>{" "}
                <div>
                  <Text color="cyan">{t("COLOR")}</Text>
                  <Text>{patient.color ?? "-"}</Text>
                </div>
                {patient.alert && (
                  <div>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      color="red.5"
                      variant="filled"
                    >
                      {patient.alert}
                    </Alert>
                  </div>
                )}
              </SimpleGrid>
            </BBox>
            <PatientWeights patient={patient} />
            <PatientTabs patient={patient} />
          </Stack>
          <Stack
            sx={{
              flex: 2,
              width: "100%",
            }}
          >
            <Actions patient={patient} />
            <PatientUpcomingAppointments
              patientId={patient.id}
              clientId={patient.ownerId}
              clientName={patient.ownerName}
              patientName={patient.name}
              patientPictureURL={patient.thumbURL}
              patientSex={patient.sex}
              patientSpecies={patient.species}
            />
          </Stack>
        </Flex>
      </Stack>
    </Container>
  );
};
