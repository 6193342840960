import {
  Box,
  Checkbox,
  Group,
  NumberInput,
  Radio,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getAdditionalTaxes,
  getNotificationByResultType,
  InvoiceData,
  InvoiceLineItem,
  loadingInfoNotification,
  normalizeInvoiceLineItem,
  obju,
} from "beitary-shared";
import { SaveButton } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";
import { RefundInvoiceFormValues, rules } from "./RefundInvoiceForm.rules";

export interface RefundInvoiceFormProps {
  items: InvoiceLineItem[];
  parentInvoiceId: string;
  parentInvoiceSerialNumber?: string;
  clientId: string;
  clientName: string;
  maxRefundAmount: number;
}

export const RefundInvoiceForm = ({
  items,
  parentInvoiceSerialNumber,
  parentInvoiceId,
  clientId,
  clientName,
  maxRefundAmount,
}: RefundInvoiceFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useSubmitState();
  const db = useDBServices();
  const { currency, countryCode } = org_params_util.getOrgParams();

  const calculatePrice = (item: InvoiceLineItem) => {
    const isFromFixedPriceBundle =
      item.bundleId !== undefined && item.bundleId !== item.id;
    const calcs = mu.calculateItemValueAndTaxes({
      qty: item.qty,
      taxRate: item.taxRate,
      totalDiscount: item.discount ?? 0,
      unitValue: isFromFixedPriceBundle ? 0 : item.pUnitSellingPrice,
    });

    console.log(calcs);

    return -calcs.totalValueWithTaxes;
  };

  const form = useForm<RefundInvoiceFormValues>({
    initialValues: {
      items: items.map((i) => ({
        ...i,
        pUnitSellingPrice: -i.pUnitSellingPrice,
        qty: i.qty,
        selected: false,
      })),
    },
    validate: rules,
  });

  const maxQuantities: { [id: string]: number } = (items ?? []).reduce(
    (acc: { [id: string]: number }, item) => {
      acc[item.id] = item.qty;
      return acc;
    },
    {}
  );

  const pricesSum = (form.values.items ?? [])
    .filter((i) => i.selected)
    .reduce((acc: number, item) => {
      return acc + calculatePrice(item);
    }, 0);

  const submit = async (values: RefundInvoiceFormValues) => {
    console.log(values);

    const notificationId = "new-refund-invoice";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("ADD_REFUND_INVOICE"),
      })
    );
    setSubmitState("pending-response");

    const itemsToSubmit = values.items
      .filter((i) => i.selected)
      .filter((i) => i.qty !== 0)
      .map((i) => {
        const newI: InvoiceLineItem = normalizeInvoiceLineItem({
          ...i,
          parentType: "RETURN",
        }); // this is to remove the "selected" field
        obju.removeUndefined(newI);
        return newI;
      });

    const invoicesDBService = db.invoicesDBService.invoices;

    const newinvoiceData: InvoiceData = {
      type: "REFUND_INVOICE",
      parentSerialNumber: parentInvoiceSerialNumber,
      parentId: parentInvoiceId,
      clientId: clientId,
      clientName: clientName,
      status: "ACTIVE",
      currency,
      additionalTaxes: getAdditionalTaxes(countryCode),
      lineItems: {},
      payments: {},
      refunds: {},
      refunded: 0,
      balanceDue: 0,
      discount: 0,
      paid: 0,
      subtotal: 0,
      taxes: 0,
      total: 0,
    };
    obju.removeUndefined(newinvoiceData);

    const r = await invoicesDBService.addInvoice(newinvoiceData, itemsToSubmit);

    if (r?.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(r.type)({
        message: t(r.message),
      }),
      id: notificationId,
    });

    if (r.type === "success" && r.payload) {
      navigate(`/invoices/edit/${r.payload.id}`);
    }
  };
  console.log(form.values);

  const rows = form.values.items.map((element, index) => (
    <tr key={index}>
      <td>
        <Checkbox
          {...form.getInputProps(`items.${index}.selected`, {
            type: "checkbox",
          })}
        />
      </td>
      <td>
        <Text>{element.pName}</Text>
      </td>
      <td>
        <NumberInput
          disabled={!element.selected}
          min={0}
          max={Math.min(maxQuantities[element.id])}
          precision={2}
          removeTrailingZeros
          value={element.qty}
          onChange={(v) => {
            if (v !== "") {
              form.setFieldValue(`items.${index}.qty`, v);
            }
          }}
        />
      </td>
      <td>{mu.toD(calculatePrice(element))}</td>
      <td>
        {element.pLinkedInventoryItemId && (
          <Radio.Group
            required={true}
            {...form.getInputProps(`items.${index}.returnToInventory`)}
            value={
              form.values.items[index].returnToInventory === true ? "YES" : "NO"
            }
            onChange={(v) => {
              form.setFieldValue(
                `items.${index}.returnToInventory`,
                v === "YES" ? true : false
              );
            }}
          >
            <Group>
              <Radio
                value="YES"
                label={t("YES")}
                disabled={!element.selected}
              />
              <Radio value="NO" label={t("NO")} disabled={!element.selected} />
            </Group>
          </Radio.Group>
        )}
      </td>
    </tr>
  ));

  console.log(form.isValid());
  console.log(pricesSum <= maxRefundAmount);
  console.log(pricesSum > 0);
  console.log(form.isValid() && pricesSum > 0 && pricesSum <= maxRefundAmount);

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack mt="xl">
        {rows.length > 0 && (
          <Box pl="xl" pr="xl">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>{t("DESCRIPTION")}</th>
                  <th>{t("QTY")}</th>
                  <th>{t("PRICE")}</th>
                  <th>{t("RETURN_TO_INVENTORY")}</th>
                </tr>
              </thead>
              <tbody>
                {rows}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <Text weight="bold">{t("TOTAL")}</Text>
                  </td>
                  <td>{mu.toD(pricesSum)}</td>
                </tr>
              </tbody>
            </Table>
          </Box>
        )}
        <Group position="apart">
          <Text size="sm" ml="xl">
            {t("MAXIMUM_REFUNDABLE_AMOUNT")}: {mu.toD(maxRefundAmount)}
          </Text>
          <SaveButton
            state={submitState}
            canSave={
              form.isValid() && pricesSum > 0 && pricesSum <= maxRefundAmount
            }
          />
        </Group>
      </Stack>
    </form>
  );
};
