import { ActionIcon, Modal } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import { BFile } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BSimpleDICOMViewer } from "./BSimpleDICOMViewer";

export interface BSimpleDICOMViewerModalProps {
  fileId: string;
  file?: BFile;
}

export const BSimpleDICOMViewerModal = ({
  fileId,
  file,
}: BSimpleDICOMViewerModalProps) => {
  const { t } = useTranslation();

  const [filename, setFilename] = useState<string | undefined>(undefined);

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal.Root
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        fullScreen
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>{filename ?? t("FILE")}</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body w={"100%"} h={"100%"}>
            <BSimpleDICOMViewer
              fileId={fileId}
              file={file}
              setFilename={(n) => {
                setFilename(n);
              }}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <ActionIcon onClick={() => setOpened(true)}>
        <IconEye size={18} />
      </ActionIcon>
    </>
  );
};
