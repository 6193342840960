import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import {
  inventoryItemUnitOfMeasurementEnum,
  inventoryItemUnitTypeEnum,
} from "../../common";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { purchaseOrderItemStatusEnum } from "./PurchaseOrder.item.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const purchaseOrderItemDataV0Schema = z.object({
  status: purchaseOrderItemStatusEnum,
  inventoryItemId: z.string(),
  inventoryItemName: z.string(),
  inventoryItemUnits: z.number().nonnegative(),
  inventoryItemUnitType: inventoryItemUnitTypeEnum.optional(),
  inventoryItemUnitOfMesurement: inventoryItemUnitOfMeasurementEnum.optional(),
  qtyOrdered: z.number().nonnegative(), // qty of UOM
  qtyReceived: z.number().nonnegative(),
  costPerUnitOfMeasurement: z.number().nonnegative().int(),
  taxRate: z.number().nonnegative().default(0),
});

type PurchaseOrderItemDataV0 = z.infer<typeof purchaseOrderItemDataV0Schema>;

const purchaseOrderItemV0Schema = baseRecordSchema.merge(
  purchaseOrderItemDataV0Schema
);

type PurchaseOrderItemV0 = z.infer<typeof purchaseOrderItemV0Schema>;

const normalizePurchaseOrderItemV0 = (data: any): PurchaseOrderItem => {
  try {
    const purchaseOrderItemV0: PurchaseOrderItemV0 =
      purchaseOrderItemV0Schema.parse(data);

    const { ...rest } = purchaseOrderItemV0;

    // here it's same object cause current v is 0
    const purchaseOrderItem: PurchaseOrderItem = {
      ...rest,
    };
    return purchaseOrderItem;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PURCHASE_ORDER_ITEM_VERSION = "0";

export type PurchaseOrderItemData = PurchaseOrderItemDataV0;

export type PurchaseOrderItem = BaseRecord & PurchaseOrderItemData;

export const purchaseOrderItemDataSchema = purchaseOrderItemDataV0Schema;

export const purchaseOrderItemSchema = purchaseOrderItemV0Schema;

export const normalizePurchaseOrderItem = (data: any): PurchaseOrderItem => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizePurchaseOrderItemV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
