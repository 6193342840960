import {
  errorResult,
  ImagingIntegrationProduct,
  ImagingIntegrationProductData,
  IMAGING_INTEGRATION_PRODUCT_VERSION,
  normalizeImagingIntegrationProduct,
  ProductData,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";
import { products } from "../products";

// add organization imagingIntegrationProduct

interface AddImagingIntegrationProduct {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: ImagingIntegrationProductData;
    mappedProductData: Pick<
      ProductData,
      "categoryId" | "name" | "unitSellingPrice" | "taxRate" | "integration"
    >;
  }): Promise<Result<string | null>>;
}

const addImagingIntegrationProduct: AddImagingIntegrationProduct = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
  mappedProductData,
}) => {
  try {
    // create a new ref to get a new imagingIntegrationProduct id
    const newImagingIntegrationProductRef = doc(
      collection(
        db,
        "organizations",
        organizationId,
        "imaging_integration_products"
      )
    );

    const newImagingIntegrationProduct: ImagingIntegrationProduct =
      normalizeImagingIntegrationProduct({
        ...data,
        id: newImagingIntegrationProductRef.id,
        authorId,
        authorName,
        version: IMAGING_INTEGRATION_PRODUCT_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

    await setDoc(newImagingIntegrationProductRef, newImagingIntegrationProduct);

    // mapped product
    const mpd: ProductData = {
      ...mappedProductData,
      integrationType: "IMAGING_INTEGRATION",
      integrationProductId: newImagingIntegrationProductRef.id,
      status: "ACTIVE",
      type: "SERVICE",
    };

    await products(db, organizationId, authorId, authorName, source).addProduct(
      mpd,
      newImagingIntegrationProduct.id
    );

    // t("IMAGING_INTEGRATION_PRODUCT_CREATED")
    const successMessage = "IMAGING_INTEGRATION_PRODUCT_CREATED";
    return successResult({
      message: successMessage,
      payload: newImagingIntegrationProductRef.id,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetImagingIntegrationProduct {
  (props: { db: Firestore; organizationId: string; id: string }): Promise<
    Result<ImagingIntegrationProduct | null>
  >;
}

const getImagingIntegrationProduct: GetImagingIntegrationProduct = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const imagingIntegrationProductDocRef = doc(
      db,
      "organizations",
      organizationId,
      "imaging_integration_products",
      id
    );
    const imagingIntegrationProductDocSnapshot = await getDoc(
      imagingIntegrationProductDocRef
    );
    if (imagingIntegrationProductDocSnapshot.exists()) {
      try {
        const data: unknown = imagingIntegrationProductDocSnapshot.data();
        const imagingIntegrationProduct: ImagingIntegrationProduct =
          normalizeImagingIntegrationProduct(data);
        // t("IMAGING_INTEGRATION_PRODUCT_FOUND")
        const successMessage = "IMAGING_INTEGRATION_PRODUCT_FOUND";
        return successResult({
          message: successMessage,
          payload: imagingIntegrationProduct,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("IMAGING_INTEGRATION_PRODUCT_NOT_FOUND")
      const errorMessage = "IMAGING_INTEGRATION_PRODUCT_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateImagingIntegrationProduct {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<ImagingIntegrationProductData>;
  }): Promise<Result<string | null>>;
}

const updateImagingIntegrationProduct: UpdateImagingIntegrationProduct =
  async ({ db, organizationId, authorId, authorName, id, source, data }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "imaging_integration_products",
        id
      );

      const updates: Partial<ImagingIntegrationProduct> = {
        ...data,
        authorId,
        authorName,
        version: IMAGING_INTEGRATION_PRODUCT_VERSION,
        source,
        lastUpdatedAt: tu.getCurrentDateTime(),
      };

      await updateDoc(docRef, updates);

      // t("IMAGING_INTEGRATION_PRODUCT_UPDATED")
      const successMessage = "IMAGING_INTEGRATION_PRODUCT_UPDATED";
      // console.log(successMessage, updatedImagingIntegrationProduct);
      return successResult({
        message: successMessage,
        payload: docRef.id,
      });
    } catch (err: any) {
      console.log(err.message);
      return errorResult({ message: err.message });
    }
  };

interface DeleteImagingIntegrationProduct {
  ({
    db,
    organizationId,
    id,
    authorId,
    authorName,
    source,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteImagingIntegrationProduct: DeleteImagingIntegrationProduct =
  async ({ db, organizationId, id, authorId, authorName, source }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "imaging_integration_products",
        id
      );

      try {
        await deleteDoc(docRef);
        await products(
          db,
          organizationId,
          authorId,
          authorName,
          source
        ).deleteProduct(docRef.id);
        // t("IMAGING_INTEGRATION_PRODUCT_DELETED")
        const successMessage = "IMAGING_INTEGRATION_PRODUCT_DELETED";
        return successResult({
          message: successMessage,
          payload: true,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } catch (err: any) {
      console.log(err);
      return errorResult({ message: err.message });
    }
  };

// get organization imagingIntegrationProducts listener
interface GetImagingIntegrationProductsListenerCallback {
  (imagingIntegrationProducts: ImagingIntegrationProduct[]): void;
}
interface GetImagingIntegrationProductsListener {
  db: Firestore;
  organizationId: string;
  callback: GetImagingIntegrationProductsListenerCallback;
}

const getImagingIntegrationProductsListener = ({
  db,
  organizationId,
  callback,
}: GetImagingIntegrationProductsListener): Unsubscribe => {
  try {
    // console.log("getImagingIntegrationProductsListener: new listener");
    const imagingIntegrationProductsQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "imaging_integration_products"
      ),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(imagingIntegrationProductsQuery, (querySnapshot) => {
      const imagingIntegrationProducts: ImagingIntegrationProduct[] = [];
      querySnapshot.forEach((doc) => {
        try {
          imagingIntegrationProducts.push(
            normalizeImagingIntegrationProduct(doc.data())
          );
        } catch (err) {
          console.log(err);
        }
      });
      callback(imagingIntegrationProducts);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const imagingIntegrationProducts = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getImagingIntegrationProductsListener: (
      callback: GetImagingIntegrationProductsListenerCallback
    ) =>
      getImagingIntegrationProductsListener({
        db,
        organizationId,
        callback,
      }),
    getImagingIntegrationProduct: (id: string) =>
      getImagingIntegrationProduct({
        db,
        organizationId,
        id,
      }),
    addImagingIntegrationProduct: (
      data: ImagingIntegrationProductData,
      mappedProductData: Pick<
        ProductData,
        "categoryId" | "name" | "unitSellingPrice" | "taxRate" | "integration"
      >
    ) =>
      addImagingIntegrationProduct({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
        mappedProductData,
      }),
    deleteImagingIntegrationProduct: (id: string) =>
      deleteImagingIntegrationProduct({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        id,
      }),
    updateImagingIntegrationProduct: (
      id: string,
      data: Partial<ImagingIntegrationProduct>
    ) =>
      updateImagingIntegrationProduct({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    archiveImagingIntegrationProduct: (id: string) =>
      updateImagingIntegrationProduct({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchiveImagingIntegrationProduct: (id: string) =>
      updateImagingIntegrationProduct({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
