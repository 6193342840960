import { Stack } from "@mantine/core";
import { Consultation } from "beitary-shared";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { AssessmentForm, DocumentsPanel } from "./components";

interface AssessmentProps {
  consultation: Consultation;
}

export const Assessment = ({ consultation }: AssessmentProps) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <BCan I="am" a="DOCTOR">
        <AssessmentForm consultation={consultation} />
      </BCan>
      <DocumentsPanel
        title={t("LABORATORY_FILES")}
        filesCategory="LAB_REPORT"
        clientId={consultation.clientId}
        consultationId={consultation.id}
        patientId={consultation.patientId}
      />
      <DocumentsPanel
        title={t("IMAGING_FILES")}
        filesCategory="IMAGING"
        clientId={consultation.clientId}
        consultationId={consultation.id}
        patientId={consultation.patientId}
      />
      <DocumentsPanel
        title={t("OTHER_DOCUMENTS")}
        filesCategory="DOC_FILE"
        clientId={consultation.clientId}
        consultationId={consultation.id}
        patientId={consultation.patientId}
      />
    </Stack>
  );
};
