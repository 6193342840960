import {
  ActionIcon,
  Button,
  Group,
  Input,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  normalizeReminder,
  Reminder,
  reminderDueDateTimeUnitEnum,
  reminderNotificationTimeUnitEnum,
  Result,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import {
  ProductMultiSelect,
  ProductSelect,
} from "features/admin/catalog/components/Products/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { ReminderFormValues, rules } from "./ReminderForm.rules";

export interface ReminderFormProps {
  reminder?: Reminder;
}

export const ReminderForm = ({ reminder }: ReminderFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { updateReminder, addReminder } =
    useDBServices().clientCommunication.reminders;

  const form = useForm<ReminderFormValues>({
    initialValues: reminder ? normalizeReminder(reminder) : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: ReminderFormValues) => {
    const notificationId = "submit-reminder";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("ADD_REMINDER")
        // t("UPDATE_REMINDER")
        title: t(reminder ? "UPDATE_REMINDER" : "ADD_REMINDER"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (reminder) {
      result = await updateReminder(reminder.id, values);
    } else {
      result = await addReminder(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            <Group position="apart">
              <Text weight={500}>{t("TRIGGER_PRODUCT")}</Text>
            </Group>
          }
        >
          <Stack p="xl">
            <ProductSelect
              required
              label={t("TRIGGER_PRODUCT")}
              {...form.getInputProps("triggerProductId")}
            />
          </Stack>
        </BBox>

        <BBox
          header={
            reminder ? (
              <Text weight={500}>{t("EDIT_REMINDER")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_REMINDER")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />
            <Group>
              <NumberInput
                required
                min={1}
                label={t("DUE_IN")}
                {...form.getInputProps("dueIn")}
              />
              <Select
                required
                label={t("TIME_UNIT")}
                data={reminderDueDateTimeUnitEnum.options.map((o) => ({
                  value: o,
                  label: t(o),
                }))}
                {...form.getInputProps("timeUnit")}
              />
            </Group>
            <ProductMultiSelect
              required
              label={t("AUTO_COMPLETE_PRODUCTS")}
              {...form.getInputProps("autocompleteProductIds")}
            />

            <Input.Wrapper label={t("NOTIFICATIONS")}>
              <Stack pt="md">
                {form.values.notifications &&
                  form.values.notifications.map((v, index) => (
                    <Group key={v.id}>
                      <NumberInput
                        required
                        min={1}
                        label={t("SEND_IN")}
                        {...form.getInputProps(`notifications.${index}.sendIn`)}
                      />
                      <Select
                        required
                        label={t("TIME_UNIT")}
                        data={reminderNotificationTimeUnitEnum.options.map(
                          (o) => ({
                            value: o,
                            label: t(o),
                          })
                        )}
                        {...form.getInputProps(
                          `notifications.${index}.timeUnit`
                        )}
                      />
                      <Select
                        required
                        label={t("BEFORE_AFTER")}
                        data={["BEFORE", "AFTER"].map((o) => ({
                          value: o,
                          label: t(o),
                        }))}
                        {...form.getInputProps(
                          `notifications.${index}.beforeAfter`
                        )}
                      />
                      <ActionIcon
                        onClick={() =>
                          form.setFieldValue(
                            "notifications",
                            (form.values.notifications ?? []).filter(
                              (elem) => elem.id !== v.id
                            )
                          )
                        }
                      >
                        <IconTrash size={18} />
                      </ActionIcon>
                    </Group>
                  ))}
                <Group>
                  <Button
                    onClick={() => {
                      form.values.notifications
                        ? form.values.notifications.length < 5 &&
                          form.setFieldValue("notifications", [
                            ...form.values.notifications,
                            {
                              id: id_util.newId20(),
                              beforeAfter: "BEFORE",
                              sendIn: 1,
                              timeUnit: "MONTH",
                            },
                          ])
                        : form.setFieldValue("notifications", [
                            {
                              id: id_util.newId20(),
                              beforeAfter: "BEFORE",
                              sendIn: 1,
                              timeUnit: "MONTH",
                            },
                          ]);
                    }}
                  >
                    {t("ADD_NOTIFICATION")}
                  </Button>
                </Group>
              </Stack>
            </Input.Wrapper>
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
