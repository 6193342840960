import {
  ActionIcon,
  Divider,
  Group,
  NumberInput,
  Stack,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  InventoryItem,
  loadingInfoNotification,
  obju,
  Product,
  Result,
} from "beitary-shared";
import { BBox, BMoneyInput, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { selectInventoryItemLinkedProducts } from "features/admin/catalog/Catalog.slice";
import { ProductForm } from "features/admin/catalog/components/Products/components/Products/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mu } from "utils/money_utils";
import { InventoryItemLocationsMultiSelect } from "../InventoryItemLocationsMultiSelect";
import { InventoryItemSupplierSelect } from "../InventoryItemSupplierSelect";
import { InventoryItemUnitOfMeasurementSelect } from "../InventoryItemUnitOfMeasurementSelect";
import { InventoryItemUnitTypeSelect } from "../InventoryItemUnitTypeSelect";
import { ItemFormValues, rules } from "./ItemForm.rules";

export interface ItemFormProps {
  item?: InventoryItem;
  nested?: boolean;
  partialProduct?: Partial<Product>;
}

export const ItemForm = ({ item, nested, partialProduct }: ItemFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editInventoryItem, addInventoryItem } =
    useDBServices().inventoryDBService.inventoryItems;

  const navigate = useNavigate();

  const linked = useAppSelector((state) =>
    selectInventoryItemLinkedProducts(state, item?.id ?? "")
  );

  const form = useForm<ItemFormValues>({
    initialValues: item ? { ...item } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");
  if (form.values.currentQuantity === undefined)
    form.setFieldValue("currentQuantity", 0);
  if (form.values.taxRate === undefined) form.setFieldValue("taxRate", 0.19);
  if (partialProduct?.name && !form.values.name)
    form.setFieldValue("name", partialProduct.name);
  if (partialProduct?.code && !form.values.code)
    form.setFieldValue("code", partialProduct.code);
  if (partialProduct?.unitSellingPrice && !form.values.costPerUnitOfMeasurement)
    form.setFieldValue(
      "costPerUnitOfMeasurement",
      partialProduct.unitSellingPrice
    );

  const submit = async (values: ItemFormValues) => {
    obju.removeUndefined(values);
    const notificationId = "submit-inventory-item-item";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(item ? "UPDATE_ITEM" : "ADD_ITEM"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<InventoryItem | null>;
    if (item) {
      result = await editInventoryItem(item.id, values);
    } else {
      result = await addInventoryItem(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <Stack>
      <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
        <Stack>
          {!nested && (
            <Group>
              <MoveBackButton />
            </Group>
          )}
          <BBox
            header={
              item ? (
                <Text weight={500}>{t("EDIT_ITEM")}</Text>
              ) : (
                <Text weight={500}>{t("NEW_ITEM")}</Text>
              )
            }
          >
            <Stack p="xl">
              <Group grow align="flex-start" spacing="xl">
                <Stack>
                  <TextInput
                    required
                    placeholder={t("ENTER_NAME")}
                    label={t("NAME")}
                    {...form.getInputProps("name")}
                  />
                  <InventoryItemSupplierSelect
                    required
                    {...form.getInputProps("supplierId")}
                    onChange={(v) => {
                      v
                        ? form.setValues({
                            supplierId: v.id,
                          })
                        : form.setValues({
                            supplierId: undefined,
                          });
                    }}
                  />
                </Stack>
                <Stack>
                  <TextInput
                    placeholder={t("ITEM_CODE_PLACEHOLDER")}
                    label={t("CODE")}
                    {...form.getInputProps("code")}
                  />
                  <TextInput
                    placeholder={t("SUPPIER_ITEM_CODE_PLACEHOLDER")}
                    label={t("SUPPIER_ITEM_CODE")}
                    {...form.getInputProps("supplierItemCode")}
                  />
                  <InventoryItemLocationsMultiSelect
                    {...form.getInputProps("locationsIds")}
                  />
                </Stack>
              </Group>
              <Textarea
                placeholder={t("ENTER_NOTES")}
                label={t("NOTES")}
                {...form.getInputProps("notes")}
              />
              <Divider />
              <Group grow spacing="xl" align="flex-end">
                <Text align="right">{"#1"}</Text>
                <InventoryItemUnitOfMeasurementSelect
                  {...form.getInputProps("unitOfMeasurement")}
                />
                <Text align="right">{t("CONTAINS")}</Text>
                <NumberInput
                  required={!!form.values.unitType}
                  label={t("UNITS")}
                  {...form.getInputProps("units")}
                  min={1}
                />
                <InventoryItemUnitTypeSelect
                  required={!!form.values.units}
                  {...form.getInputProps("unitType")}
                />
              </Group>
              <Divider />
              <Group grow align="flex-end" spacing="xl">
                <BMoneyInput
                  required
                  label={t("COST_PER_UNIT_OF_MEASUREMENT_NO_TAXES")}
                  {...form.getInputProps("costPerUnitOfMeasurement")}
                  min={0}
                />
                <BMoneyInput
                  required
                  label={t("COST_PER_UNIT_OF_MEASUREMENT")}
                  {...form.getInputProps("costPerUnitOfMeasurement")}
                  value={mu.multiply({
                    amount: form.values.costPerUnitOfMeasurement ?? 0,
                    multiplier: 1 * (1 + form.values.taxRate),
                  })}
                  onChange={(v) => {
                    if (v !== "") {
                      form.setFieldValue(
                        "costPerUnitOfMeasurement",
                        mu.multiply({
                          amount: v,
                          multiplier: 1 / (1 + form.values.taxRate),
                        })
                      );
                    }
                  }}
                  min={0}
                />
                <NumberInput
                  required
                  label={t("TAXES")}
                  description={t("%")}
                  {...form.getInputProps("taxRate")}
                  value={form.values.taxRate * 100}
                  onChange={(v) => {
                    console.log(v);

                    if (v !== "") {
                      form.setFieldValue(
                        "taxRate",
                        Number(Math.round(parseFloat(v / 100 + "e4")) + "e-4")
                      );
                    }
                  }}
                  min={0}
                  precision={2}
                />
                <NumberInput
                  label={t("ORDER_QUANTITY")}
                  placeholder={t("ORDER_QUANTITY_PLACEHOLDER")}
                  {...form.getInputProps("orderQuantity")}
                  min={0}
                />
              </Group>
              <Divider />
              <Group grow align="flex-end" spacing="xl">
                <NumberInput
                  required
                  disabled={item !== undefined}
                  placeholder={t("INITIAL_COUNT_PLACEHOLDER")}
                  label={t("INITIAL_COUNT")}
                  {...form.getInputProps("initialCount")}
                  onChange={(v) => {
                    if (v !== "") {
                      form.setFieldValue("initialCount", v);
                    }
                  }}
                  min={0}
                />
                <NumberInput
                  label={t("MIN_QUANTITY")}
                  placeholder={t("MIN_QUANTITY_PLACEHOLDER")}
                  {...form.getInputProps("minQuantity")}
                  min={0}
                />
                <NumberInput
                  label={t("MAX_QUANTITY")}
                  placeholder={t("MAX_QUANTITY_PLACEHOLDER")}
                  {...form.getInputProps("maxQuantity")}
                  min={0}
                />
                <NumberInput
                  label={t("REORDER_POINT")}
                  placeholder={t("REORDER_POINT_PLACEHOLDER")}
                  {...form.getInputProps("reorderPoint")}
                  min={0}
                />
              </Group>
            </Stack>
          </BBox>
          <Group position="right">
            <SaveButton state={submitState} canSave={form.isValid()} />
          </Group>
        </Stack>
      </form>
      {!nested && (
        <BBox
          header={
            <Group>
              <Text weight={500}>{t("LINKED_PRODUCTS")}</Text>
            </Group>
          }
        >
          <Stack p="xl">
            <Tabs defaultValue={item ? "current" : "new"}>
              <Tabs.List>
                {item && (
                  <Tabs.Tab value="current">{t("CURRENT_PRODUCTS")}</Tabs.Tab>
                )}
                <Tabs.Tab value="new">{t("NEW_PRODUCT")}</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="current" pt="xs">
                <Stack>
                  {linked.map((i) => (
                    <Group position="apart">
                      <Text>{i.name}</Text>
                      <ActionIcon
                        onClick={() =>
                          navigate(
                            `/admin/catalog/products/products/edit/${i.id}`
                          )
                        }
                      >
                        <IconEdit size={18} />
                      </ActionIcon>
                    </Group>
                  ))}
                </Stack>
              </Tabs.Panel>

              <Tabs.Panel value="new" pt="xs">
                <ProductForm invItem={item} nested />
              </Tabs.Panel>
            </Tabs>
          </Stack>
        </BBox>
      )}
    </Stack>
  );
};
