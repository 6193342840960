import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { noteSchema } from "../../notes";
import { patientSexEnum, patientSpeciesEnum } from "../../patients";
import { consultationPrognosisEnum } from "./Consultation.prognosis";
import { consultationStatusEnum } from "./Consultation.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const consultationDataV0Schema = z.object({
  status: consultationStatusEnum,
  invoiceId: z.string().optional(),
  clientId: z.string(),
  clientName: z.string(),
  organizationId: z.string(),
  organizationName: z.string(),
  patientId: z.string(),
  patientName: z.string(),
  patientSex: patientSexEnum,
  patientPictureURL: z.string().optional(),
  patientSpecies: patientSpeciesEnum,

  examTemplateId: z.string().optional(),
  // summary
  title: z.string().optional(),
  patientLocation: z.string().optional(),

  serviceProviderId: z.string().optional(),
  serviceProviderName: z.string().optional(),
  supervisingDoctorId: z.string().optional(),
  supervisingDoctorName: z.string().optional(),
  membersIds: z.array(z.string()).optional(),
  notes: z.string().optional(),

  activityLog: z.array(z.string()).optional(),
  // doctorName: z.string().optional(),
  //patientWeight: z.number().positive().optional(),
  // estimatesIDs: z.array(z.string()).optional() I guess it's better to have the estimate has the id of the
  // consultation and query them on demand
  // activityLog:????
  checkedInTime: z.number().nonnegative(),

  // clinical notes
  // subjective
  reasonForConsultation: z.string().optional(),
  history: z.string().optional(),
  currentMedicationsAndSupplements: z.string().optional(),

  // assessment
  problems: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        dischInstr: z.string().optional(),
      })
    )
    .optional(),
  diagnoses: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        dischInstr: z.string().optional(),
      })
    )
    .optional(),
  suspicions: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        dischInstr: z.string().optional(),
      })
    )
    .optional(),
  chronicConditions: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        dischInstr: z.string().optional(),
      })
    )
    .optional(),
  assessment: z.string().optional(),
  prognosisFrom: consultationPrognosisEnum.optional(),
  prognosisTo: consultationPrognosisEnum.optional(),

  // plan
  recommendations: z.string().optional(),
  planNotes: z.record(noteSchema).optional(),

  addendumNotes: z.record(noteSchema).optional(),

  labDocsNotes: z.record(noteSchema).optional(),
  imagingDocsNotes: z.record(noteSchema).optional(),
  otherDocsNotes: z.record(noteSchema).optional(),
  formsNotes: z.record(noteSchema).optional(),
});

type ConsultationDataV0 = z.infer<typeof consultationDataV0Schema>;

const consultationV0Schema = baseRecordSchema.merge(consultationDataV0Schema);

type ConsultationV0 = z.infer<typeof consultationV0Schema>;

const normalizeConsultationV0 = (data: any): Consultation => {
  try {
    const consultationV0: ConsultationV0 = consultationV0Schema.parse(data);

    const { ...rest } = consultationV0;

    // here it's same object cause current v is 0
    const consultation: Consultation = {
      ...rest,
    };
    return consultation;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const CONSULTATION_VERSION = "0";

export type ConsultationData = ConsultationDataV0;

export type Consultation = BaseRecord & ConsultationData;

export const consultationDataSchema = consultationDataV0Schema;

export const consultationSchema = consultationV0Schema;

export const normalizeConsultation = (data: any): Consultation => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeConsultationV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
