// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
// Import charts, all with Chart suffix
import { LineChart } from "echarts/charts";
// import components, all suffixed with Component
import {
  DatasetComponent,
  GridComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { SVGRenderer } from "echarts/renderers";

import type { BarSeriesOption, LineSeriesOption } from "echarts/charts";
import type {
  DatasetComponentOption,
  GridComponentOption,
  // The component option types are defined with the ComponentOption suffix
  TitleComponentOption,
  TooltipComponentOption,
} from "echarts/components";
import type { ComposeOption } from "echarts/core";

import macarons from "styles/themes/bcharts/macarons.json";
import walden from "styles/themes/bcharts/walden.json";
import westeros from "styles/themes/bcharts/westeros.json";

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  SVGRenderer,
  DatasetComponent,
]);

echarts.registerTheme("westeros", westeros); // TODO https://echarts.apache.org/en/theme-builder.html
echarts.registerTheme("walden", walden);
echarts.registerTheme("macarons", macarons);

export type BChartsOption = ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
>;

export const bcharts = echarts;

export const BChart = ReactEChartsCore;
