import {
  Divider,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  InventoryItemSupplier,
  loadingInfoNotification,
  Result,
} from "beitary-shared";
import { BBox, CountryCodeSelect, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { rules, SupplierFormValues } from "./SupplierForm.rules";

export interface SupplierFormProps {
  supplier?: InventoryItemSupplier;
}

export const SupplierForm = ({ supplier }: SupplierFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editInventoryItemSupplier, addInventoryItemSupplier } =
    useDBServices().inventoryDBService.inventoryItemSuppliers;

  const form = useForm<SupplierFormValues>({
    initialValues: supplier ? { ...supplier } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: SupplierFormValues) => {
    const notificationId = "submit-inventory-item-supplier";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(supplier ? "UPDATE_SUPPLIER" : "ADD_SUPPLIER"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<InventoryItemSupplier | null>;
    if (supplier) {
      result = await editInventoryItemSupplier(supplier.id, values);
    } else {
      result = await addInventoryItemSupplier(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <Stack>
      <Group>
        <MoveBackButton />
      </Group>
      <BBox
        header={
          supplier ? (
            <Text weight={500}>{t("EDIT_SUPPLIER")}</Text>
          ) : (
            <Text weight={500}>{t("NEW_SUPPLIER")}</Text>
          )
        }
      >
        <form
          id="supplier-form"
          onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}
        >
          <Stack p="xl" spacing="xl">
            <Group grow align="flex-start" spacing="xl">
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("NAME")}
                  {...form.getInputProps("name")}
                />

                <TextInput
                  label={t("REPRESENTATIVE_PHONENUMBER")}
                  {...form.getInputProps("representativePhoneNumber")}
                />
              </Stack>
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  label={t("REPRESENTATIVE_NAME")}
                  {...form.getInputProps("representativeName")}
                />
                <TextInput
                  type="email"
                  label={t("REPRESENTATIVE_EMAIL")}
                  {...form.getInputProps("representativeEmail")}
                />
              </Stack>
            </Group>

            <Textarea
              label={t("DESCRIPTION")}
              {...form.getInputProps("description")}
            />

            <Divider />

            <Group grow align="flex-start" spacing="xl">
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("ADDRESS_LINE_1")}
                  {...form.getInputProps("address_Line1")}
                />
                <TextInput
                  label={t("ADDRESS_STATE_OR_PROVINCE")}
                  {...form.getInputProps("address_stateOrProvince")}
                />
              </Stack>
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("ADDRESS_CITY")}
                  {...form.getInputProps("address_city")}
                />
                <TextInput
                  label={t("ADDRESS_LINE_2")}
                  {...form.getInputProps("address_Line2")}
                />
              </Stack>
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("ADDRESS_POSTAL_CODE")}
                  {...form.getInputProps("address_postalCode")}
                />
                <CountryCodeSelect
                  required
                  {...form.getInputProps("address_countryCode")}
                />
              </Stack>
            </Group>
          </Stack>
        </form>
      </BBox>
      <Group position="right">
        <SaveButton
          state={submitState}
          canSave={form.isValid()}
          formId="supplier-form"
        />
      </Group>
    </Stack>
  );
};
