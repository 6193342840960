import { zodResolver } from "@mantine/form";
import { appointmentTypeDataSchema } from "beitary-shared";
import { z } from "zod";

const appointmentTypeFormSchema = appointmentTypeDataSchema;

export type AppointmentTypeFormValues = z.infer<
  typeof appointmentTypeFormSchema
>;

export const rules = zodResolver(appointmentTypeFormSchema);
