import * as z from "zod";
import { UnableToNormalizeData } from "../../../types-schemas";

// Define schemas for each type

export const SNoteSchema = z.object({
  id: z.string(),
  content: z.string(),
  authorName: z.string(),
  lifecycleDate: z.number(),
});

export type SNote = z.infer<typeof SNoteSchema>;

export const normalizeSNote = (data: any): SNote => {
  try {
    return SNoteSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const STreatmentSchema = z.object({
  id: z.string(),
  consultationId: z.string(),
  serialNumber: z.string().optional(),
  lotNumber: z.string().optional(),
  manufacturer: z.string().optional(),
  productName: z.string(),
  productType: z.string(),
  medicalRecordNotes: z.array(SNoteSchema).optional(),
  lotExpirationDate: z.number().optional(),
  qty: z.number(),
  unit: z.string().optional(),
  timeDue: z.number().optional(),
  adminBy: z.string().optional(),
  lifecycleDate: z.number(),
});

export type STreatment = z.infer<typeof STreatmentSchema>;

export const normalizeSTreatment = (data: any): STreatment => {
  try {
    return STreatmentSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const SVitalsSchema = z.object({
  id: z.string(),
  consultationId: z.string(),
  temp: z.number().optional(),
  pulse: z.number().optional(),
  resp: z.number().optional(),
  capRefTime: z.string().optional(),
  mucusColor: z.string().optional(),
  mentation: z.string().optional(),
  notes: z.string().optional(),
  lifecycleDate: z.number(),
});

export type SVitals = z.infer<typeof SVitalsSchema>;

export const normalizeSVitals = (data: any): SVitals => {
  try {
    return SVitalsSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const SPhysicalExamSchema = z.object({
  id: z.string(),
  consultationId: z.string(),
  supervisingDoctorName: z.string().optional(),
  fields: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      values: z.array(z.string()),
    })
  ),
  lifecycleDate: z.number(),
});

export type SPhysicalExam = z.infer<typeof SPhysicalExamSchema>;

export const normalizeSPhysicalExam = (data: any): SPhysicalExam => {
  try {
    return SPhysicalExamSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const SPrescriptionItemSchema = z.object({
  name: z.string(),
  isChronic: z.boolean(),
  qty: z.number(),
  nOfRefills: z.number().optional(),
  validUntil: z.number().optional(),
  directions: z.string().optional(),
});

export const SPrescriptionSchema = z.object({
  id: z.string(),
  consultationId: z.string().optional(),
  prescribingDrName: z.string(),
  lifecycleDate: z.number(),
  items: z.array(SPrescriptionItemSchema),
});

export type SPrescription = z.infer<typeof SPrescriptionSchema>;

export const normalizeSPrescription = (data: any): SPrescription => {
  try {
    return SPrescriptionSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const SDischargeInstructionSchema = z.object({
  id: z.string(),
  authorName: z.string(),
  consultationId: z.string(),
  title: z.string(),
  content: z.string(),
  lifecycleDate: z.number(),
});

export type SDischargeInstruction = z.infer<typeof SDischargeInstructionSchema>;

export const normalizeSDischargeInstruction = (
  data: any
): SDischargeInstruction => {
  try {
    return SDischargeInstructionSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const SFileSchema = z.object({
  id: z.string(),
  consultationId: z.string().optional(),
  fileCategory: z.string(),
  dlURL: z.string().optional(),
  thumbURL: z.string().optional(),
  fileSize: z.number(),
  contentType: z.string(),
  originalName: z.string(),
  comment: z.string().optional(),
  lifecycleDate: z.number(),
});

export type SFile = z.infer<typeof SFileSchema>;

export const normalizeSFile = (data: any): SFile => {
  try {
    return SFileSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const SConsultationSchema = z.object({
  id: z.string(),
  status: z.string(),
  title: z.string().optional(),
  notes: z.string().optional(),
  reasonForConsultation: z.string().optional(),
  history: z.string().optional(),
  currentMedicationsAndSupplements: z.string().optional(),
  problems: z.array(z.string()).optional(),
  suspicions: z.array(z.string()).optional(),
  diagnoses: z.array(z.string()).optional(),
  chronicConditions: z.array(z.string()).optional(),
  assessment: z.string().optional(),
  prognosisFrom: z.string().optional(),
  prognosisTo: z.string().optional(),
  recommendations: z.string().optional(),
  planNotes: z.array(SNoteSchema).optional(),
  addendumNotes: z.array(SNoteSchema).optional(),
  labDocsNotes: z.array(SNoteSchema).optional(),
  imagingDocsNotes: z.array(SNoteSchema).optional(),
  otherDocsNotes: z.array(SNoteSchema).optional(),
  formsNotes: z.array(SNoteSchema).optional(),
  treatments: z.array(STreatmentSchema),
  prescriptions: z.array(SPrescriptionSchema),
  vitals: z.array(SVitalsSchema),
  physicalExams: z.array(SPhysicalExamSchema),
  dischargeInstructions: z.array(SDischargeInstructionSchema),
  files: z.array(SFileSchema),
  lifecycleDate: z.number(),
});

export type SConsultation = z.infer<typeof SConsultationSchema>;

export const normalizeSConsultation = (data: any): SConsultation => {
  try {
    return SConsultationSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const gPLOnCallDataSchema = z.object({
  patient: z.string(),
  organizationId: z.string(),
});

export type GPLOnCallData = z.infer<typeof gPLOnCallDataSchema>;

export const normalizeGPLOnCallData = (data: any): GPLOnCallData => {
  try {
    return gPLOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const gPLOnCallResponseSchema = z.object({
  sConsultations: z.array(SConsultationSchema),
  sFiles_no_consultation: z.array(SFileSchema),
  sPrescriptions_no_consultation: z.array(SPrescriptionSchema),
});

export type GPLOnCallResponse = z.infer<typeof gPLOnCallResponseSchema>;

export const normalizeGPLOnCallResponse = (data: any): GPLOnCallResponse => {
  try {
    return gPLOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
