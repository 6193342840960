import { zodResolver } from "@mantine/form";
import { consultationDataSchema } from "beitary-shared";
import { z } from "zod";

const summaryFormSchema = consultationDataSchema.pick({
  title: true,
  notes: true,
  patientWeight: true,
  membersIds: true,
  supervisingDoctorId: true,
  supervisingDoctorName: true,
  status: true,
});

export type SummaryFormValues = z.infer<typeof summaryFormSchema>;

export const rules = zodResolver(summaryFormSchema);
