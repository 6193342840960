import { zodResolver } from "@mantine/form";
import { memberRoleDataSchema } from "beitary-shared";
import { z } from "zod";

const memberRoleFormSchema = memberRoleDataSchema;
//   .omit({ permissions: true })
//   .merge(
//     z.object({
//       adminPermissions: z.array(orgPermission).optional(),
//       invoicingPermissions: z.array(orgPermission).optional(),
//       recordsPermissions: z.array(orgPermission).optional(),
//       reportsPermissions: z.array(orgPermission).optional(),
//     })
//   );

export type MemberRoleFormValues = z.infer<typeof memberRoleFormSchema>;

export const rules = zodResolver(memberRoleFormSchema);
