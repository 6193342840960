import { zodResolver } from "@mantine/form";
import { purchaseOrderDataSchema } from "beitary-shared";
import { z } from "zod";

const purchaseOrderFormSchema = purchaseOrderDataSchema
  .extend({ includeBelowReorderPoint: z.boolean().optional() })
  .strip();

export type PurchaseOrderFormValues = z.infer<typeof purchaseOrderFormSchema>;

export const rules = zodResolver(purchaseOrderFormSchema);
