import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const clinicLocationDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string().min(1),
  notes: z.string().optional(),
});

type ClinicLocationDataV0 = z.infer<typeof clinicLocationDataV0Schema>;

const clinicLocationV0Schema = baseRecordSchema.merge(
  clinicLocationDataV0Schema
);

type ClinicLocationV0 = z.infer<typeof clinicLocationV0Schema>;

const normalizeClinicLocationV0 = (data: any): ClinicLocation => {
  try {
    const clinicLocationV0: ClinicLocationV0 =
      clinicLocationV0Schema.parse(data);

    const { ...rest } = clinicLocationV0;

    // here it's same object cause current v is 0
    const clinicLocation: ClinicLocation = {
      ...rest,
    };
    return clinicLocation;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const CLINIC_LOCATION_VERSION = "0";

export type ClinicLocationData = ClinicLocationDataV0;

export type ClinicLocation = BaseRecord & ClinicLocationData;

export const clinicLocationDataSchema = clinicLocationDataV0Schema;

export const clinicLocationSchema = clinicLocationV0Schema;

export const normalizeClinicLocation = (data: any): ClinicLocation => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeClinicLocationV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
