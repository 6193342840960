import { Box, Button, Group, Text } from "@mantine/core";
import { OrgPermissions } from "beitary-shared";
import { BBox } from "components";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ProductsTable } from "./components";

export const Products = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BBox
      header={
        <Group position="apart">
          <Text size="sm" weight={500}>
            {t("PRODUCTS_LIST")}
          </Text>
          <BCan I="do" a={OrgPermissions.PRODUCTS_CUD}>
            <Button onClick={() => navigate("new")}>{t("ADD_NEW")}</Button>
          </BCan>
        </Group>
      }
    >
      <Box p="xl">
        <ProductsTable />
      </Box>
    </BBox>
  );
};
