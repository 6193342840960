import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

// inspiration: https://stripe.com/docs/api/mailTemplates/object

const mailTemplateDataV0Schema = z.object({
  subject: z.string(),
  html: z.string(),
  id: z.string(),
  attachments: z
    .array(
      z.object({
        filename: z.string().optional(),
        content: z.string().optional(),
        path: z.string().optional(),
        href: z.string().optional(),
        contentType: z.string().optional(),
        encoding: z.string().optional(),
      })
    )
    .optional(),
});

type MailTemplateDataV0 = z.infer<typeof mailTemplateDataV0Schema>;

const mailTemplateV0Schema = baseRecordSchema.merge(mailTemplateDataV0Schema);

type MailTemplateV0 = z.infer<typeof mailTemplateV0Schema>;

const normalizeMailTemplateV0 = (data: any): MailTemplate => {
  try {
    const mailTemplateV0: MailTemplateV0 = mailTemplateV0Schema.parse(data);

    const { ...rest } = mailTemplateV0;

    // here it's same object cause current v is 0
    const mailTemplate: MailTemplate = {
      ...rest,
    };
    return mailTemplate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const MAIL_TEMPLATE_VERSION = "0";

export type MailTemplateData = MailTemplateDataV0;

export type MailTemplate = BaseRecord & MailTemplateData;

export const mailTemplateDataSchema = mailTemplateDataV0Schema;

export const mailTemplateSchema = mailTemplateV0Schema;

export const normalizeMailTemplate = (data: any): MailTemplate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeMailTemplateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
