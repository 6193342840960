import {
  Anchor,
  Avatar,
  Badge,
  Card,
  Divider,
  Group,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Consultation, tu } from "beitary-shared";
import { AlertTooltip, BSexIcon } from "components";
import { selectPatients } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface ImportantInfoCardProps {
  consultation: Consultation;
}

export const ImportantInfoCard = ({ consultation }: ImportantInfoCardProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const { patientId, status, checkedInTime } = consultation;

  const patient = useAppSelector(selectPatients).find(
    (p) => p.id === patientId
  );

  if (!patient) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  const { weights, sex, species, breed, color, dateOfBirth } = patient;

  const sexIcon = <BSexIcon sex={sex} />;

  const weightStr =
    weights && weights.length > 0
      ? [...weights].sort((a, b) => b.t - a.t).at(0)?.v + " " + t("kg")
      : undefined;

  const speciesStr = t(species);

  const dateOfBirthStr = tu.getDateString(dateOfBirth);

  let ageStr = "";
  const [years, months, days] = tu.getAgeInYMD(dateOfBirth);
  if (years !== 0) ageStr += `${years} ${t("YEARS")} `;
  if (months !== 0) ageStr += `${months} ${t("MONTHS")} `;
  if (days !== 0) ageStr += `${days} ${t("DAYS")} `;

  const infoElms = [
    weightStr,
    sexIcon,
    speciesStr,
    breed,
    color,
    dateOfBirthStr,
    ageStr,
  ];

  let info = infoElms
    .filter((e) => !!e)
    .map((e, i) => (
      <Text size="sm" key={i}>
        {e}
      </Text>
    ));
  info = info
    .map((e, i) =>
      i < info.length - 1 ? [e, <Text key={`.${i}`}>.</Text>] : [e]
    )
    .flat();

  return (
    <Card sx={{ border: `1px solid ${theme.colors.gray[3]}` }}>
      <Group>
        <Badge size="lg">{t(status)}</Badge>

        <Divider orientation="vertical" />

        <Avatar src={patient.thumbURL} size={50} radius="xl">
          <IconPaw size={30} />
        </Avatar>
        <div>
          <Group spacing={4}>
            <Anchor
              color="dark"
              weight={500}
              lineClamp={1}
              component={Link}
              to={`/clients/${patient.ownerId}/patients/${patient.id}`}
            >
              {patient.name} {<IconExternalLink size={12} />}
            </Anchor>
            {patient.alert && <AlertTooltip alert={patient.alert} size={16} />}
          </Group>

          <Anchor
            color="dark"
            size="sm"
            weight={400}
            lineClamp={1}
            component={Link}
            to={`/clients/${patient.ownerId}`}
          >
            {patient.ownerName} {<IconExternalLink size={12} />}
          </Anchor>
        </div>
        <Divider orientation="vertical" />

        <div>
          <Text size="xs">{t("CHECKED_IN")}:</Text>
          <Text color="dimmed" size="xs">
            {tu.getDateAndTimeString(checkedInTime)}
          </Text>
        </div>
        <Divider orientation="vertical" />
        <Group spacing="xs">{info}</Group>
        <Divider orientation="vertical" />
        <Anchor
          color="dark"
          weight={400}
          lineClamp={1}
          component={Link}
          to={`/consultations/${consultation.id}/plan`}
        >
          {<IconExternalLink size={12} />}
        </Anchor>
      </Group>
    </Card>
  );
};
