import { z } from "zod";
import { productSchema } from "../../../types-schemas/catalog";
import {
  productAdministrationRouteEnum,
  productConcentrationSolventUnitTypeEnum,
} from "../../../types-schemas/common";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { noteSchema } from "../../notes";
import { treatmentStatusSchema } from "./Treatment.status";
import { treatmentTypeSchema } from "./Treatment.type";

/**
 * schema and types versions
 * from first to last
 */

// v0

const treatmentDataV0Schema = z.object({
  consultationId: z.string(),
  status: treatmentStatusSchema,
  type: treatmentTypeSchema,
  priority: z.number().nonnegative().optional(),
  qty: z.number().positive(),
  unit: productConcentrationSolventUnitTypeEnum.optional(),
  isFixedPriceBundle: z.boolean().optional(),
  fixedPriceBundleId: z.string().optional(),
  fixedPriceBundleName: z.string().optional(),
  serialNumber: z.string().optional(),
  lotNumber: z.string().optional(),
  manufacturer: z.string().optional(),
  lotExpirationDate: z.number().positive().optional(),
  timeDue: z.number().positive().optional(),
  dateAdministred: z.number().positive().optional(),
  adminBy: z.string().optional(),
  adminRoutes: z.array(productAdministrationRouteEnum).optional(),
  medicalRecordNotes: z.record(noteSchema).optional(),
  productSnapshot: productSchema,
  invoiceId: z.string().optional(),
  adminInstr: z.string().optional(),
  administerWithoutIntegration: z.boolean().optional(),
});

type TreatmentDataV0 = z.infer<typeof treatmentDataV0Schema>;

const treatmentV0Schema = baseRecordSchema.merge(treatmentDataV0Schema);

type TreatmentV0 = z.infer<typeof treatmentV0Schema>;

const normalizeTreatmentV0 = (data: any): Treatment => {
  try {
    const treatmentV0: TreatmentV0 = treatmentV0Schema.parse(data);

    const { ...rest } = treatmentV0;

    // here it's same object cause current v is 0
    const treatment: Treatment = {
      ...rest,
    };
    return treatment;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const TREATMENT_VERSION = "0";

export type TreatmentData = TreatmentDataV0;

export type Treatment = BaseRecord & TreatmentData;

export const treatmentDataSchema = treatmentDataV0Schema;

export const treatmentSchema = treatmentV0Schema;

export const normalizeTreatment = (data: any): Treatment => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeTreatmentV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
