import { Center, Container, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export interface GlobalErrorFallBackComponentProps {
  error: string;
}

export const GlobalErrorFallBackComponent = ({
  error,
}: GlobalErrorFallBackComponentProps) => {
  const { t } = useTranslation();

  return (
    <Container fluid h="100vh" bg="red.1">
      <Center h="85%">
        <Stack align="center" m="xl" p="xl">
          <Text weight="bold" size="xl" color="red">
            {t("SOMETHING_WENT_WRONG")}
          </Text>
          <Text m="xl">{error}</Text>
        </Stack>
      </Center>
    </Container>
  );
};
