import { BChart, bcharts, BChartsOption } from "components/charts/bcharts";

export interface BLineChartProps {
  option: BChartsOption;
  height?: number;
  width?: number;
}

export const BLineChart = ({ option, width, height }: BLineChartProps) => {
  return (
    <BChart
      echarts={bcharts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"walden"}
      //onChartReady={this.onChartReadyCallback}
      //onEvents={EventsDict}
      //opts={{}}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  );
};
