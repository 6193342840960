import {
  Box,
  Button,
  Container,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconDownload,
  IconPaw,
  IconUser,
} from "@tabler/icons-react";
import { bFileCategoriesEnum, errorNotification, tu } from "beitary-shared";
import { BBox, BDateAndTimeInput, MoveBackButton } from "components";
import { useCServices } from "hooks/useCService/useCService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadFile } from "hooks/useUploadFile";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { utils, write, writeFile } from "xlsx";

export interface ClientsPatientsCountsProps {}

export const ClientsPatientsCounts = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [submitState, setSubmitState] = useSubmitState();

  const [{ submitFile, submitFileState }] = useUploadFile({
    filesCategory: bFileCategoriesEnum.Values.REPORT,
  });

  const [clientsData, setClientsData] = useState<any>([]); // TODO fix
  const [patientsData, setPatientsData] = useState<any>([]); // TODO fix

  const [start, setStart] = useState<number>(
    tu.getStartOfDate(tu.getCurrentDate())
  );
  const [end, setEnd] = useState<number>(
    tu.getStartOfDate(tu.getCurrentDate()) + 1000 * 60 * 60 * 24 - 1
  );

  const clientsQuery = useCServices().reports.clientsCounts;
  const patientsQuery = useCServices().reports.patientsCounts;

  const query = async () => {
    setSubmitState("pending-response");
    const clientsResult = await clientsQuery({
      start,
      end,
    });
    const patientsResult = await patientsQuery({
      start,
      end,
    });
    if (clientsResult.type === "error" || patientsResult.type === "error") {
      showNotification(
        errorNotification({
          message: t("ERROR_GENERATING_REPORT"),
        })
      );
      setSubmitState("error");
      return;
    }
    if (clientsResult.type === "success" && clientsResult.payload)
      setClientsData(clientsResult.payload.data);
    if (patientsResult.type === "success" && patientsResult.payload)
      setPatientsData(patientsResult.payload.data);
    setSubmitState("success");
  };

  const clientsAdded = useMemo(
    () => clientsData.filter((i: any) => i.operation === "CREATE"),
    [clientsData]
  );
  const clientsDeleted = useMemo(
    () => clientsData.filter((i: any) => i.operation === "DELETE"),
    [clientsData]
  );

  const patientsAdded = useMemo(
    () => patientsData.filter((i: any) => i.operation === "CREATE"),
    [patientsData]
  );
  const patientsDeleted = useMemo(
    () => patientsData.filter((i: any) => i.operation === "DELETE"),
    [patientsData]
  );

  const downloadXslx = async () => {
    const summarySheet = utils.json_to_sheet([
      {
        date_from: tu.getDateAndTimeString(start),
        date_to: tu.getDateAndTimeString(end),
        clients_added_count: clientsAdded.length,
        clients_deleted_count: clientsDeleted.length,
        patients_added_count: patientsAdded.length,
        patients_deleted_count: patientsDeleted.length,
      },
    ]);
    const clientsSheet = utils.json_to_sheet(clientsData);
    const patientsSheet = utils.json_to_sheet(patientsData);

    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, summarySheet, t("SUMMARY"));
    utils.book_append_sheet(workBook, clientsSheet, t("CLIENTS"));
    utils.book_append_sheet(workBook, patientsSheet, t("PATIENTS"));

    const buf = write(workBook, { bookType: "xlsx", type: "buffer" });

    const title = `${t("CLIENTS_PATIENTS_COUNTS")} ${tu.getYYYYMMDDString(
      tu.getCurrentDateTime()
    )} (${tu.getYYYYMMDDString(start)} - ${tu.getYYYYMMDDString(end)}).xlsx`;

    const file = new File([buf], title, { type: "application/octet-stream" });

    await submitFile(file);

    writeFile(workBook, title);
  };

  return (
    <Container fluid>
      <Stack spacing="xl">
        <Group mt="xl">
          <MoveBackButton />
        </Group>

        <BBox header={<Text weight={500}>{t("CLIENTS_PATIENTS_COUNTS")}</Text>}>
          <Stack p="xl">
            <Group position="apart" align="flex-end">
              <Group>
                <BDateAndTimeInput
                  label={t("DATE_FROM")}
                  maxDateTime={end}
                  onChange={(v) => {
                    if (v !== undefined) setStart(v);
                  }}
                  value={start}
                />
                <BDateAndTimeInput
                  label={t("DATE_TO")}
                  maxDateTime={tu.getCurrentDateTime()}
                  minDateTime={start}
                  onChange={(v) => {
                    if (v !== undefined) setEnd(v);
                  }}
                  value={end}
                />
              </Group>
              <Group>
                <Button
                  onClick={query}
                  loading={submitState === "pending-response"}
                >
                  {t("GENERATE_REPORT")}
                </Button>
                <Button
                  variant="outline"
                  loading={submitFileState === "pending-response"}
                  leftIcon={<IconDownload size={18} />}
                  disabled={submitState !== "success"}
                  onClick={downloadXslx}
                >
                  {t("DOWNLOAD")}
                </Button>
              </Group>
            </Group>

            <Box mt="md" mb="md">
              {submitState === "success" && (
                <Stack>
                  <Group grow mt="xl" align="flex-start">
                    <Paper withBorder p="md" radius="md">
                      <Group position="apart">
                        <Group spacing="xs">
                          <Text color="dimmed" weight="bold">
                            {t("CLIENTS_ADDED")}
                          </Text>
                          <IconUser stroke={2} color={theme.colors.cyan[5]} />
                        </Group>
                        <Group spacing="xs">
                          <Text size="xl" weight="bold">
                            {clientsAdded.length}
                          </Text>
                          <IconArrowUpRight
                            stroke={2.5}
                            color="green"
                            size={32}
                          />
                        </Group>
                      </Group>
                      <Divider mt="sm" />
                      <Stack mt="xl">
                        {clientsAdded.map((i: any) => (
                          <Text>{i.name}</Text>
                        ))}
                      </Stack>
                    </Paper>

                    <Paper withBorder p="md" radius="md">
                      <Group position="apart">
                        <Group spacing="xs">
                          <Text color="dimmed" weight="bold">
                            {t("CLIENTS_DELETED")}
                          </Text>
                          <IconUser stroke={2} color={theme.colors.cyan[5]} />
                        </Group>
                        <Group spacing="xs">
                          <Text size="xl" weight="bold">
                            {clientsDeleted.length}
                          </Text>
                          <IconArrowDownRight
                            stroke={2.5}
                            color="red"
                            size={32}
                          />
                        </Group>
                      </Group>
                      <Divider mt="sm" />
                      <Stack mt="xl">
                        {clientsDeleted.map((i: any) => (
                          <Text>{i.name}</Text>
                        ))}
                      </Stack>
                    </Paper>

                    <Paper withBorder p="md" radius="md">
                      <Group position="apart">
                        <Group spacing="xs">
                          <Text color="dimmed" weight="bold">
                            {t("PATIENTS_ADDED")}
                          </Text>
                          <IconPaw stroke={2} color={theme.colors.cyan[5]} />
                        </Group>
                        <Group spacing="xs">
                          <Text size="xl" weight="bold">
                            {patientsAdded.length}
                          </Text>
                          <IconArrowUpRight
                            stroke={2.5}
                            color="green"
                            size={32}
                          />
                        </Group>
                      </Group>
                      <Divider mt="sm" />
                      <Stack mt="xl">
                        {patientsAdded.map((i: any) => (
                          <Text>
                            {i.name} - {t(i.species)} - {i.breed} - {t(i.sex)}
                          </Text>
                        ))}
                      </Stack>
                    </Paper>

                    <Paper withBorder p="md" radius="md">
                      <Group position="apart">
                        <Group spacing="xs">
                          <Text color="dimmed" weight="bold">
                            {t("PATIENTS_DELETED")}
                          </Text>
                          <IconPaw stroke={2} color={theme.colors.cyan[5]} />
                        </Group>
                        <Group spacing="xs">
                          <Text size="xl" weight="bold">
                            {patientsDeleted.length}
                          </Text>
                          <IconArrowDownRight
                            stroke={2.5}
                            color="red"
                            size={32}
                          />
                        </Group>
                      </Group>
                      <Divider mt="sm" />
                      <Stack mt="xl">
                        {patientsDeleted.map((i: any) => (
                          <Text>
                            {i.name} - {t(i.species)} - {i.breed} - {t(i.sex)}
                          </Text>
                        ))}
                      </Stack>
                    </Paper>
                  </Group>
                  <Text mt="xl">
                    {clientsData.length + patientsData.length}{" "}
                    {t("ITEMS_FOUND")}
                  </Text>
                </Stack>
              )}
            </Box>
          </Stack>
        </BBox>
      </Stack>
    </Container>
  );
};
