import { Button, Modal } from "@mantine/core";
import { PatientSex, PatientSpecies } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentForm } from "../AppointmentForm";

export interface AppointmentModalProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
  patientSex?: PatientSex;
  patientSpecies?: PatientSpecies;
  patientPictureURL?: string;
  defaultTime?: number;
}

export const AppointmentModal = (props: AppointmentModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("NEW_RESERVATION")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <AppointmentForm {...props} />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("ADD_APPOINTMNET")}</Button>
    </>
  );
};
