import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectSMSReminderNotificationTemplates } from "features/admin/client-communication/ClientCommunication.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SMSTemplateForm } from "../SMSTemplateForm";

export const EditSMSTemplate = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const smsTemplate = useAppSelector(selectSMSReminderNotificationTemplates)
    .filter((smsTemplate) => smsTemplate.id === id)
    .at(0);

  if (!smsTemplate) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <SMSTemplateForm smsTemplate={smsTemplate} />;
};
