import { Box, Text } from "@mantine/core";
import { SConsultation, SFile, SPrescription } from "beitary-shared";
import { BLoader } from "components";
import Fuse, {
  FuseResult,
  FuseResultMatch,
  IFuseOptions,
  RangeTuple,
} from "fuse.js";
import { useCServices } from "hooks/useCService/useCService";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientLifecycle } from "../PatientLifecycle/PatientLifecycle";

export interface PatientLifecycleSearchProps {
  patientId: string;
  searchString: string;
}

type SortedItem = {
  item: SConsultation | SFile | SPrescription;
  type: "consultation" | "file" | "prescription";
};

export const PatientLifecycleSearch = ({
  patientId,
  searchString,
}: PatientLifecycleSearchProps) => {
  const { t } = useTranslation();
  const serv = useCServices().patientsCService;

  // const [lifecycle, setLifecycle] = useState<
  //   GPLOnCallResponse | undefined | null
  // >(undefined);
  const [lifecycleSorted, setLifecycleSorted] = useState<
    SortedItem[] | undefined | null
  >(undefined);
  const [lifecycleSortedWithHighlights, setLifecycleSortedWithHighlights] =
    useState<SortedItem[]>([]);

  const pQuery = useCallback(async () => {
    console.log(
      "Started recording -------------------------------------------8->"
    );
    const startTime = performance.now(); // Record start time
    const result = await serv.getPatientLifeCycle(patientId);
    const endTime = performance.now(); // Record end time
    const elapsedTime = (endTime - startTime) / 1000; // Calculate elapsed time in seconds
    console.log("Response received in", elapsedTime, "seconds");
    console.log(result);
    if (result.payload) {
      const sortedHistory: SortedItem[] = [
        ...(result.payload.sConsultations.map((i) => {
          const item: SortedItem = {
            type: "consultation",
            item: i,
          };
          return item;
        }) ?? []),
        ...(result.payload.sFiles_no_consultation.map((i) => {
          const item: SortedItem = {
            type: "file",
            item: i,
          };
          return item;
        }) ?? []),
        ...(result.payload.sPrescriptions_no_consultation.map((i) => {
          const item: SortedItem = {
            type: "prescription",
            item: i,
          };
          return item;
        }) ?? []),
      ].sort((a, b) => b.item.lifecycleDate - a.item.lifecycleDate);
      setLifecycleSorted(sortedHistory);
    } else {
      setLifecycleSorted(null);
    }
  }, [patientId, serv]);

  useEffect(() => {
    pQuery();
  }, [pQuery, serv]);

  useEffect(() => {
    const search = () => {
      if (
        !lifecycleSorted ||
        (searchString.length < 3 && searchString.length > 0)
      )
        return;
      if (searchString.length === 0) {
        setLifecycleSortedWithHighlights(lifecycleSorted);
        return;
      }

      const searchOptions: IFuseOptions<SortedItem> = {
        keys: [
          "item.title",
          "item.notes",
          "item.reasonForConsultation",
          "item.history",
          "item.currentMedicationsAndSupplements",
          "item.problems",
          "item.suspicions",
          "item.diagnoses",
          "item.chronicConditions",
          "item.assessment",
          "item.prognosisFrom",
          "item.prognosisTo",
          "item.recommendations",
          "item.planNotes.content",
          "item.addendumNotes.content",
          "item.labDocsNotes.content",
          "item.imagingDocsNotes.content",
          "item.otherDocsNotes.content",
          "item.formsNotes.content",

          // treatments
          "item.treatments.serialNumber",
          "item.treatments.lotNumber",
          "item.treatments.manufacturer",
          "item.treatments.medicalRecordNotes.content",
          "item.treatments.productName",
          "item.treatments.medicalRecordNotes.content",

          // vitals
          "item.vitals.capRefTime",
          "item.vitals.mentation",
          "item.vitals.mucusColor",
          "item.vitals.notes",

          // physicalEcam
          "item.physicalExams.supervisingDoctorName",
          "item.physicalExams.fields.name",
          "item.physicalExams.fields.values",

          // prescriptions

          "item.prescriptions.items.name",
          "item.prescriptions.items.directions",
          // files
          "item.files.originalName",
          "item.files.comment",

          // discharge instructions
          "item.dischargeInstructions.title",
          "item.dischargeInstructions.content",

          /**
           * prscription
           */
          "item.items.name",
          "item.items.directions",

          /**
           * file
           */
          "item.originalName",
          "item.comment",
        ],
        includeMatches: true,
        threshold: 0.1,
        includeScore: true,
        findAllMatches: true,
        minMatchCharLength: searchString.length > 3 ? searchString.length : 3,
        ignoreLocation: true,
        shouldSort: false,
        ignoreFieldNorm: true,
      };

      const fuse = new Fuse(lifecycleSorted, searchOptions);

      const searchResult = fuse.search(searchString);

      //console.log(JSON.stringify(searchResult));

      const res = highlight(searchResult); // array of items with highlighted fields

      // console.log(JSON.stringify(res));
      setLifecycleSortedWithHighlights(res);
    };
    search();
  }, [searchString, lifecycleSorted]);

  return (
    <Box p="md">
      {lifecycleSorted === undefined && <BLoader size={16} />}
      {lifecycleSorted === null && <Text>{t("ERROR")}</Text>}
      {lifecycleSorted && (
        <PatientLifecycle lifecycle={lifecycleSortedWithHighlights} />
      )}
    </Box>
  );
};

const highlight = (fuseSearchResult: FuseResult<SortedItem>[]) => {
  const set = (obj: any, match: FuseResultMatch) => {
    // TODO this is buggy (might replace filename in url if string occurs somewhere else)
    // solution might be to use recursive search

    const { indices, key, value } = match;
    if (!key || !value) throw new Error("NO_KEY_OR_VALUE_FOR_MATCH");

    if (key === "item.files.originalName" || key === "item.files.comment") {
      // file of consultation
      const highlighted = generateHighlightedText(value, [...indices]);
      const objectString = JSON.stringify(obj);
      const newObj: SortedItem = JSON.parse(objectString) as SortedItem;

      const item = newObj.item as SConsultation;

      for (const file of item.files) {
        file.originalName = file.originalName.replaceAll(value, highlighted);
        file.comment &&
          (file.comment = file.comment.replaceAll(value, highlighted));
      }

      return newObj;
    } else if (key === "item.originalName" || key === "item.comment") {
      // standalone file
      const highlighted = generateHighlightedText(value, [...indices]);
      const objectString = JSON.stringify(obj);
      const newObj: SortedItem = JSON.parse(objectString) as SortedItem;

      const item = newObj.item as SFile;
      item.originalName = item.originalName.replaceAll(value, highlighted);
      item.comment &&
        (item.comment = item.comment.replaceAll(value, highlighted));
      return newObj;
    } else {
      const highlighted = generateHighlightedText(value, [...indices]);

      const objectString = JSON.stringify(obj);
      const objectStringHighlighted = objectString.replaceAll(
        value,
        highlighted
      );
      const newObj: SortedItem = JSON.parse(
        objectStringHighlighted
      ) as SortedItem;

      return newObj;
    }
  };

  const generateHighlightedText = (
    inputText: string,
    regions: RangeTuple[] = []
  ) => {
    let content = "";
    let nextUnhighlightedRegionStartingIndex = 0;

    regions.forEach((region) => {
      const lastRegionNextIndex = region[1] + 1;

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        `<mark>`,
        inputText.substring(region[0], lastRegionNextIndex),
        "</mark>",
      ].join("");

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
    });

    content += inputText.substring(nextUnhighlightedRegionStartingIndex);

    return content;
  };

  return fuseSearchResult
    .filter(({ matches }) => matches && matches.length)
    .map(({ item, matches }) => {
      let highlightedItem: SortedItem = { ...item };
      // console.log("item", item);

      matches?.forEach((match) => {
        // console.log("match", match);

        if (match.key && match.value)
          highlightedItem = set(highlightedItem, match);
      });

      return highlightedItem;
    });
};
