import { z } from "zod";

export const productConcentrationSoluteUnitTypeEnum = z.enum([
  "µg",
  "mg",
  "g",
  "kg",
  "IU",
]);

export type ProductConcentrationSoluteUnitType = z.infer<
  typeof productConcentrationSoluteUnitTypeEnum
>;

// t("µg")
// t("mg")
// t("g")
// t("kg")
// t("IU")
