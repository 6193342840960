import {
  errorResult,
  GPDFOnCallData,
  GPDFOnCallResponse,
  Result,
  Source,
  successResult,
} from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";

interface GetPrescriptionPDFFileURL {
  ({
    organizationId,
    docId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    docId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const getPrescriptionPDFFileURL: GetPrescriptionPDFFileURL = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generatePrescriptionOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generatePrescriptionOnCall");
  try {
    const { data } = await generatePrescriptionOnCall({
      docId,
      organizationId,
      action: "RETURN_DL_URL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};
interface SendPrescriptionViaEmail {
  ({
    organizationId,
    docId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    docId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const sendPrescriptionViaEmail: SendPrescriptionViaEmail = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generatePrescriptionOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generatePrescriptionOnCall");
  try {
    const { data } = await generatePrescriptionOnCall({
      docId: docId,
      organizationId,
      action: "SEND_EMAIL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const prescriptions = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getPrescriptionPDFFileURL: (docId: string) =>
      getPrescriptionPDFFileURL({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
    sendPrescriptionViaEmail: (docId: string) =>
      sendPrescriptionViaEmail({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
  };
};
