import { showNotification } from "@mantine/notifications";
import {
  BStorageFileCustomMetadataData,
  getNotificationByResultType,
  id_util,
  obju,
} from "beitary-shared";
import { useSServices } from "hooks/useSService/useSService";
import { useSubmitState } from "hooks/useSubmitState";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BucketType } from "services/storage/storage-service";

export const useUploadThumbnail = () => {
  const [submitThumbnailState, setSubmitThumbnailState] = useSubmitState();
  const [thumbnailProgress, setThumbnailProgress] = useState<{
    status: "error" | "success" | "ready" | "in-progress";
    progressRate: number;
  }>();

  const { t } = useTranslation();

  const storage = useSServices();

  const thumbnailReady = useCallback(() => {
    setThumbnailProgress({
      progressRate: 0,
      status: "ready",
    });
  }, []);

  const clearThumbnail = useCallback(() => {
    setThumbnailProgress(undefined);
  }, []);

  //console.log(thumbnailProgress);

  const submitThumbnail = useCallback(
    async (file: File, parentDocPath: string) => {
      //console.log("submitThumbnail");
      // this is to trigger the right cloud function/extension
      const bucket: BucketType = "thumbnail";

      //   const notificationId = `upload-thumbnail`;
      //   showNotification(
      //     loadingInfoNotification({
      //       id: notificationId,
      //       message: t("WAITING_FOR_SERVER_RESPONSE"),
      //       title: t("UPLOADING_FILE"),
      //     })
      //   );

      try {
        const customMetadata: Omit<
          Omit<BStorageFileCustomMetadataData, "readOrgIds">,
          "organizationId"
        > = {
          originalName: file.name,
          shareWithClient: "false",
          shareReadWithSelectedOrgs: "false",
          parentDocPath,
        };
        obju.removeUndefined(customMetadata);

        // init BThumbnail doc

        setThumbnailProgress({
          status: "ready",
          progressRate: 0,
        });

        // upload to storage
        if (file !== undefined) {
          const fileId = id_util.newId20();
          await storage.uploadFile({
            bucket,
            file,
            fileId,
            fileCategory: "THUMBNAIL",
            customMetadata,
            onError: async (_error) => {
              // console.log("storage error");
              // console.log(_error);

              setThumbnailProgress({ progressRate: 0, status: "error" });
              setSubmitThumbnailState("error");
              showNotification({
                ...getNotificationByResultType("error")({
                  message: t("ERROR"),
                }),
              });
              return false;
            },
            onSuccess: async (osProps) => {
              setThumbnailProgress({
                ...thumbnailProgress,
                status: "success",
                progressRate: 100,
              });
            },
            onProgress: (progressRate) => {
              //console.log(`storage in progress at ${progressRate}`);
              setThumbnailProgress({
                ...thumbnailProgress,
                progressRate,
                status: "in-progress",
              });
            },
          });
        }
      } catch (error) {
        console.log(error);
        setThumbnailProgress(undefined);
        return false;
      }

      //setThumbnail(undefined);
      return true;
    },
    [setSubmitThumbnailState, storage, t, thumbnailProgress]
  );

  return [
    {
      thumbnailProgress,
      submitThumbnailState,
      submitThumbnail,
      thumbnailReady,
      clearThumbnail,
    },
  ] as const; // idk why but adding as const allows for correct state suggestion with vs code
};
