import { errorResult, Result, successResult } from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";

interface GetAuthToken {
  (props: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    pinCode: string;
  }): Promise<Result<{ token: string } | null>>; // TODO SHARED
}
const getAuthToken: GetAuthToken = async ({
  organizationId,
  callable,
  fcts,
  pinCode,
}) => {
  const getAuthTokenOnCall = callable<
    { orgId: string; pinCode: string },
    { token: string }
  >(fcts, "callable-getAuthTokenOnCall");
  try {
    const r = await getAuthTokenOnCall({
      orgId: organizationId,
      pinCode,
    });

    return successResult({
      message: "success",
      payload: r.data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

export const auth = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string
) => {
  return {
    getAuthToken: (pinCode: string) =>
      getAuthToken({
        callable,
        fcts,
        organizationId,
        pinCode,
      }),
  };
};
