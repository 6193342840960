import { CAD, EUR, TND, USD } from "@dinero.js/currencies";
import { allocate, dinero, toDecimal, toSnapshot } from "dinero.js";
import { Currency } from "../../types-schemas";

const getDC = (c: Currency) => {
  switch (c) {
    case "TND":
      return TND;
    case "USD":
      return USD;
    case "CAD":
      return CAD;
    case "EUR":
      return EUR;

    default:
      throw new Error(`CURRENCY_NOT_SUPPORTED: ${c}`);
  }
};

const toD = ({ amount, c }: { amount: number; c: Currency }) => {
  const currency = getDC(c);
  return toDecimal(dinero({ amount, currency }));
};

/**
 * calculated % of a monetary value.
 *
 * @param {object} options - The options object.
 * @param {number} options.amount - The original amount.
 * @param {number} options.percentage2p - The %. should be precision 2 and positive.
 * @returns {number} The calculated % amount.
 */
const getPercentage = ({
  c,
  amount,
  percentage2p,
}: {
  c: Currency;
  amount: number;
  percentage2p: number;
}): number => {
  if (percentage2p < 0) throw new Error("percentage2p should be positive");
  const currency = getDC(c);

  const [perc] = allocate(dinero({ amount, currency }), [
    percentage2p,
    10000 - percentage2p, // 10000 cauz we have % * 100: 2p
  ]);
  return toSnapshot(perc).amount;
};

const multiply = ({
  amount,
  multiplier,
}: {
  amount: number;
  multiplier: number;
}) => {
  // https://stackoverflow.com/a/32178833

  // DO NOT TOUCH THIS, otherwise everything will go booooom
  const p = Math.round(
    Number(Math.round(parseFloat(amount * multiplier + "e4")) + "e-4")
  );

  return p;
};

const calculateItemValueAndTaxes = ({
  unitValue,
  totalDiscount,
  qty,
  taxRate, // ex: 0.19
}: {
  unitValue: number;
  totalDiscount: number;
  qty: number;
  taxRate: number;
}) => {
  // taxes calc: round as early as possible

  // ex: 10000, 1000, 0.33, 0.19
  // -> unitDiscount: 3030
  // -> unitValueDiscounted: 6970
  // -> unitValueWithTaxes: 8294
  // -> unitTaxes: 1324
  // -> totalValueNoTaxes: 2300
  // -> totalValueWithTaxes: 2737
  // -> totalTaxes: 437

  // ex: 10000, 1000, 0.33, 0.075
  // -> unitDiscount: 3030
  // -> unitValueDiscounted: 6970
  // -> unitValueWithTaxes: 7493
  // -> unitTaxes: 523
  // -> totalValueNoTaxes: 2300
  // -> totalValueWithTaxes: 2473
  // -> totalTaxes: 173

  // ex: 17500, 3500, 2, 0.19
  // -> unitDiscount: 1750
  // -> unitValueDiscounted: 15750
  // -> unitValueWithTaxes: 18743
  // -> unitTaxes: 2993
  // -> totalValueNoTaxes: 31500
  // -> totalValueWithTaxes: 37486
  // -> totalTaxes: 5986

  // DO NOT TOUCH THIS, otherwise everything will go booooom
  const unitDiscount =
    qty === 0
      ? 0
      : multiply({
          amount: totalDiscount,
          multiplier: 1 / qty,
        });

  const unitValueDiscounted = unitValue - unitDiscount;

  const unitValueWithTaxes = multiply({
    amount: unitValueDiscounted,
    multiplier: 1 + taxRate,
  });
  const unitTaxes = unitValueWithTaxes - unitValueDiscounted;

  const totalValueNoTaxes = multiply({
    amount: unitValueDiscounted,
    multiplier: qty,
  });

  const totalValueWithTaxes = multiply({
    amount: unitValueWithTaxes,
    multiplier: qty,
  });

  const totalTaxes = totalValueWithTaxes - totalValueNoTaxes;

  return {
    unitValue,
    totalDiscount,
    qty,
    taxRate,
    unitDiscount,
    unitValueDiscounted,
    unitValueWithTaxes,
    unitTaxes,
    totalValueNoTaxes,
    totalValueWithTaxes,
    totalTaxes,
  };
};

export const money_utils = {
  getDC,
  toD,
  getPercentage,
  multiply,
  calculateItemValueAndTaxes,
};
