import { Group, Input, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  BoardingResource,
  getNotificationByResultType,
  loadingInfoNotification,
  Result,
} from "beitary-shared";
import { BBox, BColorPicker, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import {
  BoardingResourceFormValues,
  rules,
} from "./BoardingResourceForm.rules";

export interface BoardingResourceFormProps {
  boardingResource?: BoardingResource;
}

export const BoardingResourceForm = ({
  boardingResource,
}: BoardingResourceFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editBoardingResource, addBoardingResource } =
    useDBServices().scheduleSettingsDBService.boardingResources;

  const form = useForm<BoardingResourceFormValues>({
    initialValues: boardingResource ? { ...boardingResource } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: BoardingResourceFormValues) => {
    const notificationId = "submit-inventory-item-boardingResource";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(
          boardingResource
            ? "UPDATE_BOARDING_RESOURCE"
            : "ADD_BOARDING_RESOURCE"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (boardingResource) {
      result = await editBoardingResource(boardingResource.id, values);
    } else {
      result = await addBoardingResource(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            boardingResource ? (
              <Text weight={500}>{t("EDIT_BOARDING_RESOURCE")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_BOARDING_RESOURCE")}</Text>
            )
          }
        >
          <Stack p="xl">
            <Group grow>
              <TextInput
                minLength={1}
                required
                label={t("NAME")}
                {...form.getInputProps("name")}
              />
              <Input.Wrapper required label={t("COLOR")}>
                <BColorPicker
                  setColor={(color) => form.setFieldValue("color", color)}
                  defaultColor={form.values.color}
                />
              </Input.Wrapper>
            </Group>
            <Textarea
              minRows={4}
              label={t("DESCRIPTION")}
              {...form.getInputProps("description")}
            />
          </Stack>
        </BBox>

        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
