import { z } from "zod";
import {
  AuthorizationEnum,
  authorizationEnum,
  authorizationValues,
} from "../AuthorizationEnum";
import { FeatureEnum, featureEnum, featureKeys } from "../FeatureEnum";

const featureSide = `(${featureKeys.join("|")})`;
const authSide = `(${authorizationValues.join("|")})`;

export const deserializedAuthorizationSchema = z.record(
  featureEnum,
  authorizationEnum
);
export type DeserializedAuthorization = z.infer<
  typeof deserializedAuthorizationSchema
>;

/**
 * "f1:w-f2:r-f4:w"
 * regex (([aa-ae]:[rw]-)*([aa-ae]:[rw]))|^$
 */
export const authorizationSchema = z
  .string()
  .regex(
    new RegExp(`((${featureSide}:${authSide})*(${featureSide}:${authSide}))|^$`)
  );

export type Authorization = z.infer<typeof authorizationSchema>;

export const serializeAuthorization = (
  auth: DeserializedAuthorization
): Authorization => {
  return Object.entries(auth)
    .filter(
      ([featureName, featureAuth]) =>
        featureName && featureAuth && featureAuth !== "n"
    ) // filtering the n feature auth
    .map(([featureName, featureAuth]) => `${featureName}:${featureAuth}`)
    .join("-");
};

export const deserializeAuthorization = (
  auth: Authorization
): DeserializedAuthorization => {
  const deserialized: DeserializedAuthorization = {};
  const splitAuths = auth.split("-").filter((str) => str.length);
  splitAuths.forEach((featureBlock: string) => {
    const [feature, authorization]: [FeatureEnum, AuthorizationEnum] =
      featureBlock.split(":") as [FeatureEnum, AuthorizationEnum];
    deserialized[feature] = authorization;
  });
  featureKeys.forEach((featureName: FeatureEnum) => {
    if (!splitAuths.find((splitAuth) => splitAuth.startsWith(featureName))) {
      deserialized[featureName] = "n";
    }
  });
  return deserialized;
};
