import { zodResolver } from "@mantine/form";
import { boardingAppointmentDataSchema } from "beitary-shared";
import { z } from "zod";

const appointmentFormSchema = boardingAppointmentDataSchema.omit({
  organizationId: true,
  organizationName: true,
  status: true,
  yearWeeksCovered: true,
});

export type AppointmentFormValues = z.infer<typeof appointmentFormSchema>;

export const rules = zodResolver(appointmentFormSchema);
