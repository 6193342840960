import { z } from "zod";

export const documentTypesEnum = z.enum([
  "INVOICE",
  "PAYMENT_RECEIPT",
  "VACCINE_CERTIFICATE",
  "DISCHARGE_LETTER",
  "MEDICAL_EXAM_REPORT",
  "REMINDERS_SUMMARY",
]);
export type DocumentType = z.infer<typeof documentTypesEnum>;
