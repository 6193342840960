import { Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconStack2 } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Product } from "beitary-shared";
import { BMultiSelect } from "components";
import {
  selectActiveBundles,
  selectActiveProducts,
  selectProductCategories,
} from "features/admin/catalog/Catalog.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ProductMultiSelectProps {
  required?: boolean;
  onChange?: (items: SelectedItem[]) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

type SelectedItem = {
  bundleId?: string;
  bundleName?: string;
  code?: string;
} & Product;

export const ProductMultiSelect = ({
  onChange,
  ...props
}: ProductMultiSelectProps) => {
  const { t } = useTranslation();
  const categories = useAppSelector(selectProductCategories);
  const products = useAppSelector(selectActiveProducts);
  const bundles = useAppSelector(selectActiveBundles);

  const data = products
    .map((o) => ({
      value: o.id,
      label: o.name,
      code: o.code,
      isBundle: false,
      group: t(categories.find((c) => c.id === o.categoryId)?.name ?? "OTHER"),
    }))
    .concat(
      bundles.map((o) => ({
        value: o.id,
        label: o.name,
        code: o.code,
        isBundle: true,
        group: t("BUNDLE"),
      }))
    );

  const onSelectedItemsChange = (value: string[] | undefined) => {
    const selectedBundles = bundles.filter((b) => value?.includes(b.id));
    const selectedProducts: SelectedItem[] = products.filter((p) =>
      value?.includes(p.id)
    );
    const bundleProducts: SelectedItem[] = selectedBundles
      .map((b) => {
        const arr: SelectedItem[] = [];

        products
          .filter((p) => b.products.map((p) => p.id).includes(p.id))
          .forEach((p) => {
            const dose = b.hasFixedPrice
              ? b.products.find((pr) => pr.id === p.id)?.min ??
                1 * (p.dose ?? 1) ??
                p.dose ??
                1
              : 1;

            // console.log(p.id, dose);

            let item: Product & { bundleId?: string; bundleName?: string } = {
              ...p,
              dose,
            };

            if (b.hasFixedPrice && b.id && b.name && b.taxRate) {
              item["bundleId"] = b.id;
              item["bundleName"] = b.name;
              item["taxRate"] = b.taxRate;

              if (
                b.categoryId !== undefined &&
                b.unitSellingPrice !== undefined &&
                !arr.find((i) => i.id === b.id)
              ) {
                const bundleItem: SelectedItem = {
                  ...b,
                  categoryId: b.categoryId,
                  type: "OTHER",
                  unitSellingPrice: b.unitSellingPrice,
                  bundleId: b.id,
                  bundleName: b.name,
                  taxRate: b.taxRate,
                };
                arr.push(bundleItem);
              }
            }
            arr.push(item);
          });
        return arr;
      })
      .flat();

    onChange && onChange(bundleProducts.concat(selectedProducts));
  };

  return (
    <BMultiSelect
      {...props}
      itemComponent={SelectItem}
      filter={(value, selected, item) =>
        !selected &&
        (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.code?.toLowerCase().includes(value.toLowerCase().trim()))
      }
      nothingFound={t("NOTHING_FOUND")}
      data={data}
      onChange={onSelectedItemsChange}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  code?: string;
  isBundle?: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ code, label, isBundle, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Stack>
        <div>
          <Group>
            <Text>{label}</Text>
            {isBundle && (
              <ThemeIcon color="gray" size="xs" variant="outline">
                <IconStack2 />
              </ThemeIcon>
            )}
          </Group>
          {code && (
            <Text size="xs" color="dimmed">
              {code}
            </Text>
          )}
        </div>
      </Stack>
    </div>
  )
);
