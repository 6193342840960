import { Box, Group, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { FieldInputType, OrgPermissions } from "beitary-shared";
import {
  BAdvancedRadioGroup,
  BBox,
  BCheckboxGroup,
  BMultiSelect,
  BSelect,
  BTextEditor,
  EditButton,
  MoveBackButton,
} from "components";
import { selectPhysicalExamTemplates } from "features/admin/templates/Templates.slice";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export const ViewPhysicalExamTemplate = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const physicalExamTemplate = useAppSelector(selectPhysicalExamTemplates)
    .filter((physicalExamTemplate) => physicalExamTemplate.id === id)
    .at(0);

  if (!physicalExamTemplate) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  const name = physicalExamTemplate.name;
  const fields = physicalExamTemplate.fields;

  return (
    <Stack spacing="xl">
      <Group>
        <MoveBackButton />
        <BCan I="do" a={OrgPermissions.PHYSICAL_EXAM_TEMPLATES_CUD}>
          <EditButton
            f={() => {
              navigate(
                `/admin/templates/physical-exams/edit/${physicalExamTemplate.id}`
              );
            }}
          />
        </BCan>
      </Group>
      <BBox>
        <Stack p="xl">
          <Text size="lg" weight={500} mb="xl">
            {name}
          </Text>
          {fields.map((field) => (
            <Stack>
              <Text weight={500}>{field.name}</Text>
              {field.inputs.map((i) => (
                <Box key={i.id}>
                  {getInput({
                    type: i.inputType,
                    options: i.options.map((o) => ({
                      value: o.id,
                      label: o.value,
                    })),
                    defaultOptionValue: i.defaultOptionId,
                    defaultTextValue: i.defaultTextValue,
                  })}
                </Box>
              ))}
            </Stack>
          ))}
        </Stack>
      </BBox>
    </Stack>
  );
};

const getInput = ({
  type,
  options,
  defaultOptionValue,
  defaultTextValue,
}: {
  type: FieldInputType;
  options: {
    value: string;
    label: string;
  }[];
  defaultOptionValue?: string | string[];
  defaultTextValue?: string;
}) => {
  switch (type) {
    case "TEXT_AREA":
      return <Textarea defaultValue={defaultTextValue} />;
    case "TEXT_EDITOR":
      return <BTextEditor value={defaultTextValue} />;
    case "TEXT_INPUT":
      return <TextInput defaultValue={defaultTextValue} />;
    case "VALUE_SELECT":
      return (
        <BSelect
          required
          data={options}
          value={
            defaultOptionValue ? (defaultOptionValue as string) : undefined
          }
        />
      );
    case "VALUE_MULTISELECT":
      return (
        <BMultiSelect
          required
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string[])
              : undefined
          }
        />
      );
    case "VALUE_CHECKBOX":
      return (
        <BCheckboxGroup
          required
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string[])
              : undefined
          }
        />
      );
    case "VALUE_RADIO":
      return (
        <BAdvancedRadioGroup
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string)
              : undefined
          }
        />
      );

    default:
      return <></>;
  }
};
