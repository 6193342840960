import { Checkbox, Group, Stack, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  InventoryItem,
  loadingInfoNotification,
  normalizePurchaseOrder,
  obju,
  PurchaseOrder,
  PurchaseOrderData,
  Result,
  tu,
} from "beitary-shared";
import { BDateInput, BMoneyInput, SaveButton } from "components";
import { selectActiveInventoryItemsUnderReorderPoint } from "features/admin/inventory/Inventory.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { InventoryItemSupplierSelect } from "../../../Items/components/Items/components";
import { PurchaseOrderStatusSelect } from "../PurchaseOrderStatusSelect";
import { PurchaseOrderFormValues, rules } from "./PurchaseOrderForm.rules";

export interface PurchaseOrderFormProps {
  purchaseOrder?: PurchaseOrder;
  callback?: () => void;
}

export const PurchaseOrderForm = ({
  purchaseOrder,
  callback,
}: PurchaseOrderFormProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const { addPurchaseOrder, updatePurchaseOrder } =
    useDBServices().inventoryDBService.purchaseOrders;

  const inventoryItems = useAppSelector(
    selectActiveInventoryItemsUnderReorderPoint
  );

  const form = useForm<PurchaseOrderFormValues>({
    initialValues: purchaseOrder
      ? { ...normalizePurchaseOrder(purchaseOrder) }
      : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "PENDING");
  if (!form.values.items) form.setFieldValue("items", {});
  if (!form.values.date) form.setFieldValue("date", tu.getCurrentDateTime());

  const submit = async (values: PurchaseOrderFormValues) => {
    const data: PurchaseOrderData = {
      date: values.date,
      items: values.items,
      status: values.status,
      vendorId: values.vendorId,
      vendorName: values.vendorName,
      note: values.note,
      additionalCost: values.additionalCost,
    };

    obju.removeUndefined(data);

    const notificationId = "submit-purchaseOrder";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("UPDATE_PURCHASE_ORDER")
        // t("ADD_PURCHASE_ORDER")
        title: t(
          purchaseOrder ? "UPDATE_PURCHASE_ORDER" : "ADD_PURCHASE_ORDER"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (purchaseOrder) {
      result = await updatePurchaseOrder(purchaseOrder.id, data);
    } else {
      // new order
      let vendorItems: InventoryItem[] | undefined = undefined;
      if (values.includeBelowReorderPoint) {
        // !!!!!! only if new order
        vendorItems = inventoryItems.filter(
          (i) => i.supplierId === values.vendorId
        );
      }
      result = await addPurchaseOrder(data, vendorItems);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
    callback && callback();
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack p="xl">
        {purchaseOrder && (
          <PurchaseOrderStatusSelect {...form.getInputProps("status")} />
        )}
        <Group grow>
          <InventoryItemSupplierSelect
            required
            disabled={purchaseOrder !== undefined}
            {...form.getInputProps("vendorId")}
            onChange={(v) => {
              if (v) {
                form.setValues({
                  vendorId: v.id,
                  vendorName: v.name,
                });
              }
            }}
          />
          <BDateInput
            required
            label={t("DATE")}
            {...form.getInputProps("date")}
          />
        </Group>
        <Group grow>
          <BMoneyInput
            label={t("ADDITIONAL_COST")}
            {...form.getInputProps("additionalCost")}
          />
        </Group>
        <Textarea label={t("NOTE")} {...form.getInputProps("note")} />
        {purchaseOrder === undefined && (
          <Checkbox
            label={t("INCLUDE_ITEMS_BELOW_REORDER_POINT")}
            {...form.getInputProps("includeBelowReorderPoint", {
              type: "checkbox",
            })}
          />
        )}
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
