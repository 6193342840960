import { z } from "zod";

export enum SubscriptionBillingCycles {
  MONTHLY = "0",
  YEARLY = "1",
}

export const subscriptionBillingCycleEnum = z.nativeEnum(
  SubscriptionBillingCycles
);
export type SubscriptionBillingCycle = z.infer<
  typeof subscriptionBillingCycleEnum
>;
