import { Container, Tabs, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const View = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("client-communication/settings")
    ? "settings"
    : path.includes("client-communication/reminders")
    ? "reminders"
    : path.includes("client-communication/appointment-notifications")
    ? "appointment-notifications"
    : path.includes("client-communication/communication-history")
    ? "communication-history"
    : "settings";

  return (
    <Container fluid>
      <Title mt="xl" weight={500} order={3}>
        {t("CLIENT_COMMUNICATION")}
      </Title>
      <Tabs
        mt="xl"
        value={activeTab}
        onTabChange={(value) => {
          navigate(`${value}`);
        }}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value="settings">{t("SETTINGS")}</Tabs.Tab>
          <Tabs.Tab value="reminders">{t("REMINDERS")}</Tabs.Tab>
          <Tabs.Tab value="appointment-notifications">
            {t("APPOINTMENT_NOTIFICATIONS")}
          </Tabs.Tab>
          <Tabs.Tab value="communication-history">{t("HISTORY")}</Tabs.Tab>
        </Tabs.List>
        <Outlet />
      </Tabs>
    </Container>
  );
};
