import { z } from "zod";
import { sourceEnum } from "./common/source/Source";

// inspiration: https://itnext.io/parse-dont-validate-incoming-data-in-typescript-d6d5bfb092c8
// zod: https://github.com/colinhacks/zod

export const baseRecordSchema = z.object({
  id: z.string(),
  version: z.string(), // when we add new field for example we change version. this is needed for correct data mapping
  source: sourceEnum, // webapp, mobileapp, ...
  authorId: z.string(),
  authorName: z.string(),
  createdAt: z.number().positive().int(),
  lastUpdatedAt: z.number().positive().int(),
});

export type BaseRecord = z.infer<typeof baseRecordSchema>;
