import { Card, Stack, Tabs, Text } from "@mantine/core";
import { Client } from "beitary-shared";
import { useTranslation } from "react-i18next";
import { Appointments, Financial, Reminders, Tasks } from "./components";

export interface ClientTabsProps {
  client: Client;
}

export const ClientTabs = ({ client }: ClientTabsProps) => {
  const { t } = useTranslation();

  return (
    // keep mounted false to prevent prematurate data fetching
    // and refresh tab on select
    <Tabs defaultValue="financial" keepMounted={false}>
      <Stack>
        <Card withBorder radius="xs" p={0}>
          <Tabs.List>
            <Tabs.Tab value="financial">
              <Text p="xs">{t("FINANCIAL")}</Text>
            </Tabs.Tab>
            {/* <Tabs.Tab value="tasks">
              <Text p="xs">{t("TASKS")}</Text>
            </Tabs.Tab> */}
            <Tabs.Tab value="appointments">
              <Text p="xs">{t("APPOINTMENTS")}</Text>
            </Tabs.Tab>
            <Tabs.Tab value="reminders">
              <Text p="xs">{t("REMINDERS")}</Text>
            </Tabs.Tab>
            <Tabs.Tab value="tasks">
              <Text p="xs">{t("TASKS")}</Text>
            </Tabs.Tab>
          </Tabs.List>
        </Card>
        <Tabs.Panel value="financial">
          <Financial client={client} />
        </Tabs.Panel>
        <Tabs.Panel value="appointments">
          <Appointments clientId={client.id} clientName={client.name} />
        </Tabs.Panel>
        <Tabs.Panel value="reminders">
          <Reminders clientId={client.id} clientName={client.name} />
        </Tabs.Panel>
        <Tabs.Panel value="tasks">
          <Tasks clientId={client.id} clientName={client.name} />
        </Tabs.Panel>
      </Stack>
    </Tabs>
  );
};
