import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { selectDiagnoses } from "../../Diagnoses.slice";
import { DiagnosisForm } from "../DiagnosisForm";

export const EditDiagnosis = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const diagnosis = useAppSelector(selectDiagnoses).find((d) => d.id === id);

  if (!diagnosis) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <DiagnosisForm diagnosis={diagnosis} />;
};
