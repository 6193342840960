import { Button, Center } from "@mantine/core";
import { IconPrinter } from "@tabler/icons-react";
import { useSubmitState } from "hooks/useSubmitState";

export interface BButtonPrintProps {
  f: Function;
  disabled?: boolean;
}

export const BButtonPrint = ({ f, disabled }: BButtonPrintProps) => {
  const [submitState, setSubmitState] = useSubmitState();

  const submit = async () => {
    setSubmitState("pending-response");
    await f();
    setSubmitState("success");
  };

  return (
    <Button
      variant="outline"
      loading={submitState === "pending-response"}
      onClick={submit}
      disabled={disabled}
    >
      <Center>
        <IconPrinter size={18} />
      </Center>
    </Button>
  );
};
