import { z } from "zod";

// t("ACTIVE")
// t("IN_REVIEW")
// t("LAB_PENDING")
// t("LOCKED")
export const consultationStatusEnum = z.enum([
  "ACTIVE",
  "IN_REVIEW",
  "LAB_PENDING",
  "LOCKED",
]);
export type ConsultationStatus = z.infer<typeof consultationStatusEnum>;
