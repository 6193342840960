import {
  Badge,
  Divider,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { Appointment, tu } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentForm } from "../../../AppointmentForm";

export interface AppointmentCardProps {
  appointment: Appointment;
  color: string;
  topPosition: number;
  lIndex: number;
  lLength: number;
}

export const AppointmentCard = ({
  appointment,
  color,
  topPosition,
  lIndex,
  lLength,
}: AppointmentCardProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="xl"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("EDIT_APPOINTMENT")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack>
          <AppointmentForm appointment={appointment} />
        </Stack>
      </Modal>

      <UnstyledButton
        sx={{
          position: "absolute",
          top: topPosition,
          border: `1px solid ${color}`,
          //borderLeft: `6px solid ${color}`,
          height: appointment.duration * 4,
          backgroundColor: "white",
          borderRadius: 4,
          overflow: "hidden",
          left: `calc(${lIndex}*(100%/${lLength}))`,
          width: `calc(100% - (${lIndex}*(100%/${lLength})))`,
          "&:hover": {
            // transform: "scale(1.02)",
            // transitionDuration: "0.3s",
            filter: `brightness(97%)`,
            transitionDuration: "0.3s",
          },
        }}
        onClick={() => setOpened(true)}
      >
        <Stack m="xs" justify="flex-start" h="100%" spacing={0}>
          <Divider
            size={6}
            color={color}
            mb={6}
            mt={6}
            sx={{
              borderRadius: 4,
            }}
          />
          <Flex
            direction={{ base: "row", xs: "column" }}
            gap={{ base: "sm", xs: 0 }}
            align="flex-start"
          >
            <Text size="sm" weight="bold">
              {appointment.patientName}
            </Text>
            <Text size="sm" color="dimmed">
              {tu.getHoursAndMinutesString(appointment.time)}
              {" - "}
              {tu.getHoursAndMinutesString(
                appointment.time + appointment.duration * 1000 * 60
              )}
            </Text>
          </Flex>
          <Group>
            <Text size="sm">{appointment.reasonForConsultation}</Text>
            <Badge size="lg">{t(appointment.status + "_APT")}</Badge>
          </Group>
        </Stack>
      </UnstyledButton>
    </>
  );
};
