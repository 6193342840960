import { ActionIcon, Badge, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  OrgPermissions,
  SMSReminderNotificationTemplate,
} from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import {
  selectReminders,
  selectSMSReminderNotificationTemplates,
} from "features/admin/client-communication/ClientCommunication.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type SMSTemplatesTableItem = SMSReminderNotificationTemplate & {
  reminders: string;
  actions?: undefined;
};

export const SMSTemplatesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const smsTemplates = useAppSelector(selectSMSReminderNotificationTemplates);
  const reminders = useAppSelector(selectReminders);

  const tableItems: SMSTemplatesTableItem[] = smsTemplates.map(
    (smsTemplate) => ({
      ...smsTemplate,
      reminders: reminders
        .filter((at) => smsTemplate.remindersIds.includes(at.id))
        .map((at) => at.name)
        .join("[-]"),
    })
  );

  const {
    deleteSMSReminderNotificationTemplate,
    archiveSMSReminderNotificationTemplate,
    dearchiveSMSReminderNotificationTemplate,
  } = useDBServices().clientCommunication.smsReminderNotificationTemplates;

  const columns: BDataTableColumn<SMSTemplatesTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "reminders",
      title: <Text>{t("REMINDERS")}</Text>,
      sortable: true,
      render: ({ reminders }) => (
        <Group>
          {reminders.split("[-]").map((a) => (
            <Badge>{a}</Badge>
          ))}
        </Group>
      ),
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (smsTemplate) => (
        <BCan I="do" a={OrgPermissions.CLIENT_COMMUNICATION}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${smsTemplate.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {smsTemplate.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () =>
                  dearchiveSMSReminderNotificationTemplate(smsTemplate.id)
                }
                item={smsTemplate}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () =>
                  archiveSMSReminderNotificationTemplate(smsTemplate.id)
                }
                item={smsTemplate}
              />
            )}
            <DeleteConfirmationModal
              f={async () =>
                deleteSMSReminderNotificationTemplate(smsTemplate.id)
              }
              item={smsTemplate}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
