import { Box, Button, Card, Group, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import { errorNotification, successNotification, tu } from "beitary-shared";
import {
  AsyncActionButton,
  BBox,
  BButtonDownload,
  BButtonEmail,
  BButtonPrint,
} from "components";
import { selectActiveConsultationDischargeInstructions } from "features/consultations/Consultations.slice";
import { useCServices } from "hooks/useCService/useCService";
import { useDBServices } from "hooks/useDBService/useDBService";
import HTMLReactParser from "html-react-parser";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { file_util } from "utils/file_util";

export interface DischargeInstructionsProps {
  consultationId: string;
  clientId: string;
  patientId: string;
  patientName: string;
  disabled: boolean;
}

export const DischargeInstructions = ({
  consultationId,
  clientId,
  patientId,
  patientName,
  disabled,
}: DischargeInstructionsProps) => {
  const { t } = useTranslation();

  const cservice = useCServices();

  const { deleteDischargeInstruction } =
    useDBServices().consultationsDBService.dischargeInstructions(
      consultationId
    );

  const navigate = useNavigate();

  const instructions = useAppSelector(
    selectActiveConsultationDischargeInstructions
  );

  const printDischargeInstructions = async () => {
    const result =
      await cservice.dischargeInstructionsCService.getDischargeInstructionPDFFileURL(
        { consultationId, clientId, patientId }
      );
    if (result.type === "success") {
      if (result.payload) {
        file_util.printFileFromURL({ url: result.payload.content });
      }
    } else {
      showNotification(
        errorNotification({
          id: "emailDischargeInstructions-notif",
          message: t("ERROR_GENERATING_DOCUMENT_FOR_PRINTING"),
        })
      );
    }
  };

  const downloadDischargeInstructions = async () => {
    const result =
      await cservice.dischargeInstructionsCService.getDischargeInstructionPDFFileURL(
        { consultationId, clientId, patientId }
      );
    if (result.type === "success") {
      if (result.payload) {
        // Set the file name of the PDF
        const fileName = `${patientName}_${t(
          "DISCHARGE_INSTRUCTIONS"
        )}_${tu.getYYYYMMDDString(tu.getCurrentDateTime())}.pdf`;

        file_util.saveFileFromURL({ url: result.payload.content, fileName });
      }
    } else {
      showNotification(
        errorNotification({
          id: "emailDischargeInstructions-notif",
          message: t("ERROR_GENERATING_DOCUMENT_FOR_DOWNLOAD"),
        })
      );
    }
  };

  const emailDischargeInstructions = async () => {
    const result =
      await cservice.dischargeInstructionsCService.sendDischargeInstructionViaEmail(
        { consultationId, clientId, patientId }
      );
    if (result.type === "success") {
      showNotification(
        successNotification({
          id: "emailDischargeInstructions-notif",
          message: t("EMAIL_QUEUED_FOR_DELIVERY"),
        })
      );
    } else {
      showNotification(
        errorNotification({
          id: "emailDischargeInstructions-notif",
          message: t("ERROR_QUEUING_EMAIL_FOR_DELIVERY"),
        })
      );
    }
  };

  return (
    <Stack>
      <Card withBorder radius="xs" p="xs" mb="md">
        <Group position="apart">
          <Text weight={500}>{t("DISCHARGE_INSTRUCTIONS")}</Text>
          <Group>
            <BCan I="am" a="DOCTOR">
              <Button disabled={disabled} onClick={() => navigate("new")}>
                {t("ADD_NEW")}
              </Button>
            </BCan>
            <Group>
              <BButtonEmail f={async () => emailDischargeInstructions()} />
              <BButtonDownload
                f={async () => downloadDischargeInstructions()}
              />
              <BButtonPrint f={async () => printDischargeInstructions()} />
            </Group>
          </Group>
        </Group>
      </Card>
      {instructions.map((i) => (
        <BBox
          key={i.id}
          header={
            <Group position="apart">
              <Text color="cyan">{i.title}</Text>
              <Group>
                <BCan I="am" a="DOCTOR">
                  <Button
                    disabled={disabled}
                    onClick={() => navigate(`${i.id}/edit`)}
                  >
                    {t("EDIT")}
                  </Button>
                  <AsyncActionButton
                    disabled={disabled}
                    variant="outline"
                    color="red"
                    child={t("DELETE")}
                    f={async () => deleteDischargeInstruction(i.id)}
                  />
                </BCan>
              </Group>
            </Group>
          }
        >
          <Box p="xl" mih={120}>
            {HTMLReactParser(i.content)}
          </Box>
        </BBox>
      ))}
    </Stack>
  );
};
