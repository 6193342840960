import { z } from "zod";

// t("MALE")
// t("FEMALE")
// t("MALE_NEUTERED")
// t("MALE_MONORCHID")
// t("MALE_CRYPTORCHID")
// t("FEMALE_SPAYED_OV")
// t("FEMALE_SPAYED_HY")
// t("FEMALE_SPAYED_OV_HY")
// t("UNDETERMINED")

export const patientSexEnum = z.enum([
  "MALE",
  "FEMALE",
  "MALE_NEUTERED",
  "MALE_MONORCHID",
  "MALE_CRYPTORCHID",
  "FEMALE_SPAYED_OV",
  "FEMALE_SPAYED_HY",
  "FEMALE_SPAYED_OV_HY",
  "UNDETERMINED",
]);
export type PatientSex = z.infer<typeof patientSexEnum>;
