import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export interface RemindersProps {}

export const Reminders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("reminders/reminders-list")
    ? "reminders-list"
    : path.includes("reminders/email-templates")
    ? "email-templates"
    : path.includes("reminders/sms-templates")
    ? "sms-templates"
    : "reminders-list";

  return (
    <Tabs
      mt="xl"
      value={activeTab}
      onTabChange={(value) => {
        navigate(`${value}`);
      }}
      variant="pills"
      radius="xs"
    >
      <Tabs.List mb="xl">
        <Tabs.Tab value="reminders-list">{t("REMINDERS_LIST")}</Tabs.Tab>
        <Tabs.Tab value="email-templates">{t("EMAIL_TEMPLATES")}</Tabs.Tab>
        <Tabs.Tab value="sms-templates">{t("SMS_TEMPLATES")}</Tabs.Tab>
      </Tabs.List>
      <Outlet />
    </Tabs>
  );
};
