export type RemNotifTempalteData = {
  client: string;
  patient: string;
  reminder: string;
  dueDate: string;
  clinicName: string;
  clinicEmail: string;
  clinicPhone: string;
  clinicAddress: string;
  clinicLogoURL?: string;
};

const generateRemNotifHTML = (str: string, data: RemNotifTempalteData) => {
  const newStr = str
    .replace(new RegExp("{CLIENT}", "g"), data.client)
    .replace(new RegExp("{PATIENT}", "g"), data.patient)
    .replace(new RegExp("{REMINDER}", "g"), data.reminder)
    .replace(new RegExp("{DUE_DATE}", "g"), data.dueDate)
    .replace(new RegExp("{CLINIC_NAME}", "g"), data.clinicName)
    .replace(new RegExp("{CLINIC_EMAIL}", "g"), data.clinicEmail)
    .replace(new RegExp("{CLINIC_PHONE}", "g"), data.clinicPhone)
    .replace(new RegExp("{CLINIC_ADDRESS}", "g"), data.clinicAddress)
    .replace(
      new RegExp("{CLINIC_LOGO}", "g"),
      `<img
      src="${data.clinicLogoURL ?? ""}"
      height="60px"
      width="auto"
      max-width: 100%;
      alt="logo"
      border="0"
    />`
    );

  return newStr;
};

const generateRemNotifText = (str: string, data: RemNotifTempalteData) => {
  const newStr = str
    .replace(new RegExp("{CLIENT}", "g"), data.client)
    .replace(new RegExp("{PATIENT}", "g"), data.patient)
    .replace(new RegExp("{REMINDER}", "g"), data.reminder)
    .replace(new RegExp("{DUE_DATE}", "g"), data.dueDate)
    .replace(new RegExp("{CLINIC_NAME}", "g"), data.clinicName)
    .replace(new RegExp("{CLINIC_EMAIL}", "g"), data.clinicEmail)
    .replace(new RegExp("{CLINIC_PHONE}", "g"), data.clinicPhone)
    .replace(new RegExp("{CLINIC_ADDRESS}", "g"), data.clinicAddress);

  return newStr;
};

export const rnt_utils = {
  generateRemNotifText,
  generateRemNotifHTML,
};
