import {
  Group,
  Input,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  AppointmentType,
  getNotificationByResultType,
  loadingInfoNotification,
  Result,
} from "beitary-shared";
import { BBox, BColorPicker, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { AppointmentTypeFormValues, rules } from "./AppointmentTypeForm.rules";

export interface AppointmentTypeFormProps {
  appointmentType?: AppointmentType;
}

export const AppointmentTypeForm = ({
  appointmentType,
}: AppointmentTypeFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editAppointmentType, addAppointmentType } =
    useDBServices().scheduleSettingsDBService.appointmentTypes;

  const form = useForm<AppointmentTypeFormValues>({
    initialValues: appointmentType ? { ...appointmentType } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: AppointmentTypeFormValues) => {
    const notificationId = "submit-inventory-item-appointmentType";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(
          appointmentType ? "UPDATE_APPOINTMENT_TYPE" : "ADD_APPOINTMENT_TYPE"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<AppointmentType | null>;
    if (appointmentType) {
      result = await editAppointmentType(appointmentType.id, values);
    } else {
      result = await addAppointmentType(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            appointmentType ? (
              <Text weight={500}>{t("EDIT_APPOINTMENT_TYPE")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_APPOINTMENT_TYPE")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              minLength={1}
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />
            <Group align="flex-end">
              <NumberInput
                required
                w={300}
                min={1}
                label={t("DURATION")}
                {...form.getInputProps("duration")}
              />
              <Text>{t("MINUTES")}</Text>
            </Group>
            <Input.Wrapper required label={t("COLOR")}>
              <BColorPicker
                setColor={(color) => form.setFieldValue("color", color)}
                defaultColor={form.values.color}
              />
            </Input.Wrapper>
          </Stack>
        </BBox>

        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
