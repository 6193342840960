import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

// t("TEXT_INPUT")
// t("TEXT_AREA")
// t("TEXT_EDITOR")
// t("VALUE_SELECT")
// t("VALUE_MULTISELECT")
// t("VALUE_RADIO")
// t("VALUE_CHECKBOX")
export const fieldInputTypeEnum = z.enum([
  "TEXT_INPUT",
  "TEXT_AREA",
  "TEXT_EDITOR",
  "VALUE_SELECT",
  "VALUE_MULTISELECT",
  "VALUE_RADIO",
  "VALUE_CHECKBOX",
]);

export type FieldInputType = z.infer<typeof fieldInputTypeEnum>;

export const physicalExamTemplateField = z.object({
  id: z.string(),
  name: z.string(),
  inputs: z.array(
    z.object({
      id: z.string(),
      inputType: fieldInputTypeEnum,
      options: z
        .array(
          z.object({
            id: z.string(),
            value: z.string().default("-"),
          })
        )
        .default([]),
      defaultOptionId: z
        .string()
        .default("-")
        .or(z.array(z.string()).default([]))
        .optional(),
      defaultTextValue: z.string().optional(),
    })
  ),
});

export type PhysicalExamTemplateField = z.infer<
  typeof physicalExamTemplateField
>;

const physicalExamTemplateDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  fields: z.array(physicalExamTemplateField).default([]),
});

type PhysicalExamTemplateDataV0 = z.infer<
  typeof physicalExamTemplateDataV0Schema
>;

const physicalExamTemplateV0Schema = baseRecordSchema.merge(
  physicalExamTemplateDataV0Schema
);

type PhysicalExamTemplateV0 = z.infer<typeof physicalExamTemplateV0Schema>;

const normalizePhysicalExamTemplateV0 = (data: any): PhysicalExamTemplate => {
  try {
    const physicalExamTemplateV0: PhysicalExamTemplateV0 =
      physicalExamTemplateV0Schema.parse(data);

    const { ...rest } = physicalExamTemplateV0;

    // here it's same object cause current v is 0
    const physicalExamTemplate: PhysicalExamTemplate = {
      ...rest,
    };
    return physicalExamTemplate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PHYSICAL_EXAM_TEMPLATE_VERSION = "0";

export type PhysicalExamTemplateData = PhysicalExamTemplateDataV0;

export type PhysicalExamTemplate = BaseRecord & PhysicalExamTemplateData;

export const physicalExamTemplateDataSchema = physicalExamTemplateDataV0Schema;

export const physicalExamTemplateSchema = physicalExamTemplateV0Schema;

export const normalizePhysicalExamTemplate = (
  data: any
): PhysicalExamTemplate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizePhysicalExamTemplateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
