import { Box, Group, Table, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { InvoiceLineItem, tu } from "beitary-shared";
import { AsyncActionIcon, BBox } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";
import { ItemActions } from "../ItemActions";

export interface InvoiceLineItemsTableProps {
  charges: InvoiceLineItem[];
  title: string;
  disabled: boolean;
}

export const InvoiceLineItemsTable = ({
  charges,
  title,
  disabled,
}: InvoiceLineItemsTableProps) => {
  const { t } = useTranslation();
  const { deleteInvoiceLineItems } = useDBServices().invoicesDBService.invoices;

  const deleteItems = async (id: string) => {
    const invoiceId = charges.at(0)?.invoiceId;
    if (!invoiceId) return;
    const allIds: string[] = [id];

    charges
      .filter((c) => c.bundleId === id)
      .forEach((c) => {
        if (!allIds.includes(c.id)) allIds.push(c.id);
      });

    return deleteInvoiceLineItems({
      ids: allIds,
      invoiceId,
    });
  };

  const rows = charges.map((element) => {
    const isFromFixedPriceBundle =
      element.bundleId !== undefined && element.bundleId !== element.id;
    const { totalValueWithTaxes } = mu.calculateItemValueAndTaxes({
      unitValue: isFromFixedPriceBundle ? 0 : element.pUnitSellingPrice,
      qty: element.qty,
      taxRate: element.taxRate,
      totalDiscount: element.discount ?? 0,
    });

    return (
      <tr key={element.id}>
        <td>{tu.getLocaleDateStringFromEpoch(element.createdAt)}</td>
        <td>{element.pName}</td>
        <td>{mu.toD(element.pUnitSellingPrice)}</td>
        <td>
          {element.pUnitSellingPrice !== 0 &&
          element.discount !== undefined &&
          element.qty > 0
            ? `${Number(
                (element.discount * 100) /
                  (element.qty * element.pUnitSellingPrice)
              ).toFixed(2)} %`
            : "-"}
        </td>
        <td>{element.qty}</td>
        <td>
          {Number(Math.round(parseFloat(element.taxRate * 100 + "e4")) + "e-4")}
        </td>
        <td>{mu.toD(totalValueWithTaxes)}</td>
        <td>
          {(element.bundleId === undefined ||
            element.bundleId === element.id) && (
            <Group>
              {element.discountable && (
                <ItemActions
                  disabled={disabled}
                  key={`action-item-${element.id}`}
                  invoiceId={element.invoiceId}
                  item={element}
                />
              )}
              {element.parentType === "INVOICE" && (
                <AsyncActionIcon
                  disabled={disabled}
                  icon={<IconTrash size={18} />}
                  f={async () => deleteItems(element.id)}
                />
              )}
            </Group>
          )}
        </td>
      </tr>
    );
  });

  return (
    <BBox header={<Text color="cyan">{title}</Text>}>
      <Box p="xl">
        <Table>
          <thead>
            <tr>
              <th>{t("DATE")}</th>
              <th>{t("DESCRIPTION")}</th>
              <th>{t("UNIT_PRICE_NO_TAXES")}</th>
              <th>{t("DISCOUNT")}</th>
              <th>{t("QTY")}</th>
              <th>{t("TAXES_%")}</th>
              <th>{t("TOTAL_PRICE")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Box>
    </BBox>
  );
};
