import {
  AMOnCallData,
  AMOnCallResponse,
  errorResult,
  Result,
  Source,
  successResult,
} from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";

interface AddMember {
  (props: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    authorId: string;
    authorName: string;
    data: {
      email?: string;
      password?: string;
      name: string;
      initials: string;
      rolesIds: string[];
      licenceType: "DOCTOR" | "EMPLOYEE";
      isClinicLogin?: boolean;
      remoteAccess?: boolean;
    };
    source: Source;
  }): Promise<Result<AMOnCallResponse | null>>; // TODO SHARED
}

const addMember: AddMember = async ({
  organizationId,
  authorId,
  authorName,
  source,
  callable,
  fcts,
  data,
}) => {
  const addMemberOnCall = callable<AMOnCallData, AMOnCallResponse>(
    fcts,
    "callable-addMemberOnCall"
  );
  try {
    const r = await addMemberOnCall({
      organizationId,
      authorId,
      authorName,
      ...data,
      source,
    });

    return successResult({
      message: "success",
      payload: r.data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

export const members = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    addMember: (data: {
      email?: string;
      password?: string;
      name: string;
      initials: string;
      rolesIds: string[];
      licenceType: "DOCTOR" | "EMPLOYEE";
      isClinicLogin?: boolean;
      remoteAccess?: boolean;
    }) =>
      addMember({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
  };
};
