import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { ImagingIntegrationProduct, OrgPermissions } from "beitary-shared";
import { BDataTable, DeleteConfirmationModal } from "components";
import { selectImagingIntegrationProducts } from "features/admin/catalog/Catalog.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type ProductsTableItem = ImagingIntegrationProduct & {
  actions?: undefined;
};

export const BTRDLProductsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const products = useAppSelector(selectImagingIntegrationProducts).filter(
    (p) => p.integration === "INTEGRATION_BTR_DL"
  );

  const tableItems: ProductsTableItem[] = products.map((product) => ({
    ...product,
  }));

  const { deleteImagingIntegrationProduct } =
    useDBServices().catalogDBService.imagingIntegrationProducts;

  const columns: BDataTableColumn<ProductsTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "description",
      title: <Text>{t("DESCRIPTION")}</Text>,
      sortable: true,
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (product) => (
        <BCan I="do" a={OrgPermissions.PRODUCTS_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${product.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>

            <DeleteConfirmationModal
              f={async () => deleteImagingIntegrationProduct(product.id)}
              item={product}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
