import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

// TODO implement outside Patient
const patientVaccinationDataV0Schema = z.object({
  name: z.string(),
  note: z.string().optional(),
  adminDate: z.number().nonnegative().int(),
  dueDate: z.number().nonnegative().int().optional(),
  manufacturer: z.string(),
  lotNumber: z.string().optional(),
  lotExpirationDate: z.number().positive().optional(),
  serialNumber: z.string().optional(),
});

type PatientVaccinationDataV0 = z.infer<typeof patientVaccinationDataV0Schema>;

const patientVaccinationV0Schema = baseRecordSchema.merge(
  patientVaccinationDataV0Schema
);

type PatientVaccinationV0 = z.infer<typeof patientVaccinationV0Schema>;

const normalizepatientVaccinationV0 = (data: any): PatientVaccination => {
  try {
    const patientVaccinationV0: PatientVaccinationV0 =
      patientVaccinationV0Schema.parse(data);

    const { ...rest } = patientVaccinationV0;

    // here it's same object cause current v is 0
    const patientVaccination: PatientVaccination = {
      ...rest,
    };
    return patientVaccination;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PATIENT_VACCINATION_VERSION = "0";

export type PatientVaccinationData = PatientVaccinationDataV0;

export type PatientVaccination = BaseRecord & PatientVaccinationData;

export const patientVaccinationDataSchema = patientVaccinationDataV0Schema;

export const patientVaccinationSchema = patientVaccinationV0Schema;

export const normalizepatientVaccination = (data: any): PatientVaccination => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizepatientVaccinationV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
