import { zodResolver } from "@mantine/form";
import { paymentDataSchema } from "beitary-shared";
import { z } from "zod";

const refundPreviousPaymentFormSchema = paymentDataSchema;

export type RefundPreviousPaymentFormValues = z.infer<
  typeof refundPreviousPaymentFormSchema
>;

export const rules = zodResolver(refundPreviousPaymentFormSchema);
