import { zodResolver } from "@mantine/form";
import { physicalExamEntryDataSchema } from "beitary-shared";
import { z } from "zod";

const physicalExamEntryFormSchema = physicalExamEntryDataSchema;

export type PhysicalExamEntryFormValues = z.infer<
  typeof physicalExamEntryFormSchema
>;

export const rules = zodResolver(physicalExamEntryFormSchema);
