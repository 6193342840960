import { createStyles, Group, Text, TextInput } from "@mantine/core";
import { IconChevronUp, IconSearch, IconSelector } from "@tabler/icons-react";
import {
  DataTable,
  DataTableRowExpansionProps,
  DataTableSortStatus,
} from "mantine-datatable";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BDataTableColumn } from "schemas-types";

// TODO fix this, shows white, not transparent. idk what to do
const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
  },
  header: {
    backgroundColor: "transparent",
  },
  footer: {
    backgroundColor: "transparent",
  },
  pagination: {
    backgroundColor: "transparent",
  },
}));
export interface BDataTableProps<T> {
  data: T[];
  //fields: { key: keyof T; label: string }[];
  columns: BDataTableColumn<T>[];
  defaultSortKey?: keyof T;
  //getRow: (item: T) => React.ReactNode;
  //hasControlsColumn?: boolean;
  //extraControl?: React.ReactNode;
  //minWidth?: number;
  rowExpansion?: DataTableRowExpansionProps<T>;
}

const DEFAULT_PAGE_SIZE = 10;
export const BDataTable = <T,>({
  data,
  columns,
  defaultSortKey,
  rowExpansion,
}: BDataTableProps<T>) => {
  const { classes } = useStyles();
  const keys = useMemo(() => columns.map((c) => c.accessor), [columns]);

  const [sortBy, setSortBy] = useState<keyof T | null>(defaultSortKey ?? null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [sortedData, setSortedData] = useState(
    sortData(data, { sortBy, reversed: false, search: "" }, keys)
  );
  const [records, setRecords] = useState(
    sortedData.slice(0, DEFAULT_PAGE_SIZE)
  );
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: (defaultSortKey ?? "id") as string,
    direction: "asc",
  });

  const [search, setSearch] = useState("");
  useEffect(() => {
    setSortedData(
      sortData(
        data,
        { sortBy: defaultSortKey ?? null, reversed: false, search: "" },
        keys
      )
    ); // this is cauz otherwise table won't refresh if data changes
  }, [data, defaultSortKey, keys]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(
        data,
        { sortBy, reversed: reverseSortDirection, search: value },
        keys
      )
    );
  };

  useEffect(() => {
    const from = (page - 1) * DEFAULT_PAGE_SIZE;
    const to = from + DEFAULT_PAGE_SIZE;
    setRecords(sortedData.slice(from, to));
  }, [page, sortedData]);

  useEffect(() => {
    setSortStatus({
      columnAccessor: sortBy?.toString() ?? "",
      direction: reverseSortDirection ? "desc" : "asc",
    });
  }, [sortBy, reverseSortDirection]);

  const setSorting = (field: keyof T) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);

    setSortedData(sortData(data, { sortBy: field, reversed, search }, keys));
  };

  return (
    <>
      <Group mb="md">
        <TextInput
          //placeholder={t("SEARCH_BY_ANY_FIELD")} TODO
          icon={<IconSearch size={14} stroke={1.5} />}
          value={search}
          onChange={handleSearchChange}
          sx={{ flexGrow: 1 }} // make the input fill the remaining space
        />
      </Group>
      <DataTable
        // TODO remove white background! https://icflorescu.github.io/mantine-datatable/examples/additional-styling
        classNames={classes}
        horizontalSpacing="sm"
        verticalSpacing="xs"
        records={records}
        columns={columns}
        totalRecords={sortedData.length}
        recordsPerPage={DEFAULT_PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
        sortStatus={sortStatus}
        onSortStatusChange={(s) => {
          setSorting(s.columnAccessor as keyof T);
        }}
        // uncomment the next line to use a custom loading text
        // loadingText="Loading..."
        // uncomment the next line to display a custom text when no records were found
        // noRecordsText="No records found"
        // uncomment the next line to use a custom pagination text
        // paginationText={({ from, to, totalRecords }) => `Records ${from} - ${to} of ${totalRecords}`}
        // uncomment the next line to use a custom pagination color (see https://mantine.dev/theming/colors/)
        // paginationColor="grape"
        // uncomment the next line to use a custom pagination size
        // paginationSize="md"
        emptyState={<Text m="xl">{t("NO_RECORDS_FOUND")}</Text>}
        minHeight={150}
        noRecordsText={t("NO_RECORDS_FOUND")}
        sortIcons={{
          sorted: <IconChevronUp size={14} />,
          unsorted: <IconSelector size={14} />,
        }}
        rowExpansion={rowExpansion}
      />
    </>
  );
};

const filterData = <T,>(data: T[], search: string, keys: (keyof T)[]) => {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => `${item[key]}`.toLowerCase().includes(query))
  );
};

const sortData = <T,>(
  data: T[],
  payload: { sortBy: keyof T | null; reversed: boolean; search: string },
  keys: (keyof T)[]
) => {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search, keys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return `${b[sortBy]}`.toString().localeCompare(`${a[sortBy]}`);
      }

      return `${a[sortBy]}`.localeCompare(`${b[sortBy]}`); // `${}` is used to convert anything to strings
    }),
    payload.search,
    keys
  );
};
