import { ActionIcon, createStyles, Group, Text } from "@mantine/core";
import { IconEdit, IconTrash, IconVaccine } from "@tabler/icons-react";
import { Treatment } from "beitary-shared";
import { AsyncActionIcon, BBox, TableWithSearchAndSort } from "components";
import { useIsMobile } from "hooks/useIsMobile";
import { BCan } from "permissions";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type TxTableItem = Treatment & { pName: string };

const useStyles = createStyles((theme) => ({
  tr: {
    // backgroundColor: theme.white,
    // borderRadius: theme.radius.xs,
    // margin: theme.spacing.xl,
  },
}));

interface TxTableProps {
  treatments: Treatment[];
  deleteTreatment: (id: string) => void;
  administerTreatment: (id: string) => void;
  administerFPBTreatments: (id: string) => void;
  deleteFPBTreatments: (id: string) => void;
  disabled?: boolean;
}

export const TxTable = ({
  treatments,
  administerTreatment,
  deleteTreatment,
  administerFPBTreatments,
  deleteFPBTreatments,
  disabled,
}: TxTableProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    // console.log("rerendering");
  }, [treatments]);

  const data: TxTableItem[] = useMemo(
    () => treatments.map((t) => ({ ...t, pName: t.productSnapshot.name })),
    [treatments]
  );
  console.log(data);

  const fields: {
    key: keyof TxTableItem;
    label: string;
  }[] = [
    { key: "pName", label: t("TREATMENT") },
    { key: "type", label: t("TYPE") },
    { key: "qty", label: t("QUANTITY/DOSE") },
  ];

  const getRow = (treatment: TxTableItem) => (
    <tr key={treatment.id} className={classes.tr}>
      <td>
        <Group>
          <Text>{treatment.productSnapshot.name}</Text>
          {/* {treatment.bundleId && treatment.bundleName && (
            <Tooltip label={treatment.bundleName}>
              <ThemeIcon color="gray" size="xs" variant="outline">
                <IconStack2 />
              </ThemeIcon>
            </Tooltip>
          )} */}
        </Group>
      </td>
      <td>
        <Text>{t(treatment.type)}</Text>
      </td>
      <td>
        <Text>
          {treatment.qty} {treatment.unit && `(${t(treatment.unit)})`}
        </Text>
      </td>

      <td>
        {(treatment.isFixedPriceBundle || !treatment.fixedPriceBundleId) && (
          <BCan I="am" a="DOCTOR">
            <Group position="right" w={isMobile ? 120 : undefined}>
              <AsyncActionIcon
                disabled={disabled}
                icon={<IconVaccine size={18} />}
                f={async () =>
                  treatment.fixedPriceBundleId === treatment.id
                    ? administerFPBTreatments(treatment.id)
                    : administerTreatment(treatment.id)
                }
              />

              <ActionIcon
                disabled={disabled}
                onClick={() =>
                  navigate(
                    treatment.fixedPriceBundleId === treatment.id
                      ? `bundle-treatments/${treatment.id}/edit`
                      : `treatments/${treatment.id}/edit`
                  )
                }
              >
                <IconEdit size={18} />
              </ActionIcon>
              <AsyncActionIcon
                disabled={disabled}
                icon={<IconTrash size={18} />}
                f={async () =>
                  treatment.fixedPriceBundleId === treatment.id
                    ? deleteFPBTreatments(treatment.id)
                    : deleteTreatment(treatment.id)
                }
              />
            </Group>
          </BCan>
        )}
      </td>
    </tr>
  );
  return (
    <BBox p="xl" bgcs={0}>
      <TableWithSearchAndSort
        data={data}
        fields={fields}
        getRow={getRow}
        hasControlsColumn
      />
    </BBox>
  );
};
