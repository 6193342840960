import { ActionIcon, Badge, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  OrgPermissions,
  SMSAppointmentNotificationTemplate,
} from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectSMSAppointmentNotificationTemplates } from "features/admin/client-communication/ClientCommunication.slice";
import { selectAppointmentTypes } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type SMSAppointmentNotificationTemplatesTableItem =
  SMSAppointmentNotificationTemplate & {
    aptTpes: string;
    actions?: undefined;
  };

export const SMSAppointmentNotificationTemplatesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const smsAppointmentNotificationTemplates = useAppSelector(
    selectSMSAppointmentNotificationTemplates
  );
  const appointmentTypes = useAppSelector(selectAppointmentTypes);

  const tableItems: SMSAppointmentNotificationTemplatesTableItem[] =
    smsAppointmentNotificationTemplates.map(
      (smsAppointmentNotificationTemplate) => ({
        ...smsAppointmentNotificationTemplate,
        aptTpes: appointmentTypes
          .filter((at) =>
            smsAppointmentNotificationTemplate.aptTypeIds.includes(at.id)
          )
          .map((at) => at.name)
          .join("[-]"),
      })
    );

  const {
    deleteSMSAppointmentNotificationTemplate,
    archiveSMSAppointmentNotificationTemplate,
    dearchiveSMSAppointmentNotificationTemplate,
  } = useDBServices().clientCommunication.smsAppointmentNotificationsTemplates;

  const columns: BDataTableColumn<SMSAppointmentNotificationTemplatesTableItem>[] =
    [
      {
        accessor: "name",
        title: <Text>{t("NAME")}</Text>,
        sortable: true,
      },
      {
        accessor: "type",
        title: <Text>{t("TYPE")}</Text>,
        sortable: true,
        render: ({ type }) => <Text>{t(type)}</Text>,
      },
      {
        accessor: "aptTpes",
        title: <Text>{t("APPOINTMENT_TYPES")}</Text>,
        sortable: true,
        render: ({ aptTpes }) => (
          <Group>
            {aptTpes.split("[-]").map((a) => (
              <Badge>{a}</Badge>
            ))}
          </Group>
        ),
      },
      {
        accessor: "status",
        title: <Text>{t("STATUS")}</Text>,
        sortable: true,
        render: ({ status }) => (
          <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>
            {t(status)}
          </Text>
        ),
      },
      {
        accessor: "actions",
        title: "",
        width: 140,
        render: (smsAppointmentNotificationTemplate) => (
          <BCan I="do" a={OrgPermissions.CLIENT_COMMUNICATION}>
            <Group position="right">
              <ActionIcon
                onClick={() =>
                  navigate(`edit/${smsAppointmentNotificationTemplate.id}`)
                }
              >
                <IconEdit size={18} />
              </ActionIcon>
              {smsAppointmentNotificationTemplate.status === "ARCHIVED" ? (
                <DearchiveConfirmationModal
                  f={async () =>
                    dearchiveSMSAppointmentNotificationTemplate(
                      smsAppointmentNotificationTemplate.id
                    )
                  }
                  item={smsAppointmentNotificationTemplate}
                />
              ) : (
                <ArchiveConfirmationModal
                  f={async () =>
                    archiveSMSAppointmentNotificationTemplate(
                      smsAppointmentNotificationTemplate.id
                    )
                  }
                  item={smsAppointmentNotificationTemplate}
                />
              )}
              <DeleteConfirmationModal
                f={async () =>
                  deleteSMSAppointmentNotificationTemplate(
                    smsAppointmentNotificationTemplate.id
                  )
                }
                item={smsAppointmentNotificationTemplate}
              />
            </Group>
          </BCan>
        ),
      },
    ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
