import { inventoryItemUnitTypeEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InventoryItemUnitTypeSelectProps {
  required?: boolean;
  onChange: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const InventoryItemUnitTypeSelect = (
  props: InventoryItemUnitTypeSelectProps
) => {
  const { t } = useTranslation();

  const data = inventoryItemUnitTypeEnum.options.map((o) => ({
    value: o,
    label: t(o),
  }));

  return (
    <BSelect
      {...props}
      clearable={true}
      label={t("UNIT_TYPE")}
      placeholder={t("UNIT_TYPE_PLACEHOLDER")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};
