import { Group, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  Consultation,
  getNotificationByResultType,
  loadingInfoNotification,
  normalizeConsultation,
  obju,
  OrgPermissions,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { selectActiveServiceProviders } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { selectPatients } from "features/Clients/Clients.slice";
import {
  AddPatientWeightModal,
  EditPatientWeightModal,
} from "features/Clients/components/Client/components/Patient/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { BCan, useAbilityContext } from "permissions";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationActionsMenu } from "../../../ConsultationActionsMenu";
import {
  AssignedMembersMultiSelect,
  DoctorSelect,
  LocationSelect,
  StatusSelect,
} from "./components";
import { rules, SummaryFormValues } from "./SummaryForm.rules";

interface SummaryFormProps {
  consultation: Consultation;
}

export const SummaryForm = ({ consultation }: SummaryFormProps) => {
  const initialValues = useMemo(() => {
    const normCons = normalizeConsultation(consultation);

    const x: Pick<
      Consultation,
      | "status"
      | "membersIds"
      | "notes"
      | "supervisingDoctorId"
      | "supervisingDoctorName"
      | "title"
    > = {
      status: normCons.status,
      membersIds: normCons.membersIds,
      notes: normCons.notes,
      supervisingDoctorId: normCons.supervisingDoctorId,
      supervisingDoctorName: normCons.supervisingDoctorName,
      title: normCons.title,
    };
    return x;
  }, [consultation]);

  const form = useForm<SummaryFormValues>({
    initialValues,
    validate: rules,
  });

  useEffect(() => {
    form.setValues(initialValues);

    // After form.resetDirty is called, form.isDirty will compare
    // form.values to snapshot instead of initialValues
    form.resetDirty(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultation, initialValues]);

  console.log(form.isDirty()); // this is to check if need to autoupdate or not TODO autoupdate
  console.log(form.isDirty("membersIds"));
  console.log(form.isDirty("notes"));
  console.log(form.isDirty("status"));
  console.log(form.isDirty("supervisingDoctorId"));
  console.log(form.isDirty("supervisingDoctorName"));
  console.log(form.isDirty("title"));

  const ability = useAbilityContext();
  const locked = consultation.status === "LOCKED";

  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const consultationDBService =
    useDBServices().consultationsDBService.consultations;

  const defaultMembersIds = useAppSelector(selectActiveServiceProviders).find(
    (s) => s.id === consultation.serviceProviderId
  )?.membersIds;

  const patient = useAppSelector(selectPatients).find(
    (p) => p.id === consultation.patientId
  );

  if (form.values.membersIds === undefined && defaultMembersIds !== undefined) {
    form.setFieldValue("membersIds", defaultMembersIds);
  }

  const canUnlock =
    ability.can("do", OrgPermissions.CONSULTATIONS_UNLOCK) &&
    form.values.status !== "LOCKED";

  const submit = async (values: SummaryFormValues) => {
    if (locked && !canUnlock) return;
    obju.removeUndefined(values);
    const notificationId = "submit-summary";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_CONSULTATION"),
      })
    );
    setSubmitState("pending-response");
    const result = await consultationDBService.updateConsultation(
      consultation.id,
      values
    );

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const getWeightComponent = () => {
    if (patient) {
      const w = patient.weights?.find((w) => w.id === consultation.id);

      if (w) {
        return (
          <Stack align="flex-start" spacing={0}>
            <Text weight={500} size="sm">
              {t("WEIGHT(KG)")}
            </Text>
            <Group>
              <Text>{w.v}</Text>
              <EditPatientWeightModal
                patient={patient}
                consultationId={consultation.id}
                patientWeight={w}
              />
            </Group>
          </Stack>
        );
      } else {
        return (
          <Stack align="flex-start" spacing={0}>
            <Text weight={500} size="sm">
              {t("WEIGHT(KG)")}
            </Text>
            <Group>
              <IconAlertTriangle size={22} color="red" />
              <AddPatientWeightModal
                patient={patient}
                consultationId={consultation.id}
              />
            </Group>
          </Stack>
        );
      }
    }
    return <></>;
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <BBox
          header={
            <Group position="apart">
              <Text weight={500}>{t("CONSULTATION_SUMMARY")}</Text>

              <Group>
                <BCan I="am" a="DOCTOR">
                  <Text size="sm">{t("STATUS")}:</Text>

                  <StatusSelect
                    disabled={
                      !ability.can("do", OrgPermissions.CONSULTATIONS_UNLOCK)
                    }
                    {...form.getInputProps("status")}
                  />
                </BCan>
                <BCan I="do" a={OrgPermissions.CONSULTATION_DELETE_UPDATE_DATE}>
                  <ConsultationActionsMenu consultation={consultation} />
                </BCan>
              </Group>
            </Group>
          }
        >
          <Stack p="xl">
            <Group grow>
              <TextInput
                disabled={locked}
                label={t("TITLE")}
                {...form.getInputProps("title")}
              />
              <LocationSelect
                disabled={locked}
                {...form.getInputProps("patientLocation")}
              />
              <Group position="center">{getWeightComponent()}</Group>
            </Group>
            <Group grow>
              <DoctorSelect
                disabled={locked}
                {...form.getInputProps("supervisingDoctorId")}
                onChange={({ id, name }) => {
                  form.setFieldValue("supervisingDoctorId", id);
                  form.setFieldValue("supervisingDoctorName", name);
                }}
              />
              <AssignedMembersMultiSelect
                disabled={locked}
                {...form.getInputProps("membersIds")}
              />
            </Group>

            <Textarea
              disabled={locked}
              placeholder={t("ENTER_NOTES")}
              label={t("NOTES")}
              {...form.getInputProps("notes")}
            />
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton
            disabled={locked && !canUnlock}
            state={submitState}
            canSave={form.isTouched() && form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
