import { useAppSelector } from "app/hooks";
import { BMultiSelect } from "components";
import { selectActiveAppointmentTypes } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface AppointmentTypeMultiselectProps {
  required?: boolean;
  onChange?: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const AppointmentTypeMultiselect = (
  props: AppointmentTypeMultiselectProps
) => {
  const { t } = useTranslation();
  const appointmentTypes = useAppSelector(selectActiveAppointmentTypes);
  const data = appointmentTypes.map((o) => ({ value: o.id, label: o.name }));

  return (
    <BMultiSelect
      {...props}
      label={t("SERVICES_OFFERED")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};
