import { productTypeEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface TypeSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const TypeSelect = (props: TypeSelectProps) => {
  const { t } = useTranslation();
  const data = productTypeEnum.options.map((o) => ({ value: o, label: t(o) }));

  return (
    <BSelect
      {...props}
      data={data}
      label={t("PRODUCT_TYPE")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
