import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import {
  paymentMethodsEnum,
  paymentStatusEnum,
  paymentTypeEnum,
} from "../../payments";

/**
 * schema and types versions
 * from first to last
 */

// v0

const invoicePaymentDataV0Schema = z.object({
  invoiceId: z.string(),
  clientId: z.string(),
  clientName: z.string(),
  method: paymentMethodsEnum,
  type: paymentTypeEnum,
  status: paymentStatusEnum,
  paymentAmount: z.number().int(), // amount paid to this invoice
  invoiceTotal: z.number().int(),
  creditAmount: z.number().int(),
  change: z.number().int(),
  payToCreditBalance: z.boolean(),
  note: z.string().optional(),
  dateIssued: z.number().int().positive().optional(),
  serialNumber: z.string().optional(),
});

type InvoicePaymentDataV0 = z.infer<typeof invoicePaymentDataV0Schema>;

const invoicePaymentV0Schema = baseRecordSchema.merge(
  invoicePaymentDataV0Schema
);

type InvoicePaymentV0 = z.infer<typeof invoicePaymentV0Schema>;

const normalizeInvoicePaymentV0 = (data: any): InvoicePayment => {
  try {
    const invoicePaymentV0: InvoicePaymentV0 =
      invoicePaymentV0Schema.parse(data);

    const { ...rest } = invoicePaymentV0;

    // here it's same object cause current v is 0
    const invoicePayment: InvoicePayment = {
      ...rest,
    };
    return invoicePayment;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVOICE_PAYMENT_VERSION = "0";

export type InvoicePaymentData = InvoicePaymentDataV0;

export type InvoicePayment = BaseRecord & InvoicePaymentData;

export const invoicePaymentDataSchema = invoicePaymentDataV0Schema;

export const invoicePaymentSchema = invoicePaymentV0Schema;

export const normalizeInvoicePayment = (data: any): InvoicePayment => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInvoicePaymentV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
