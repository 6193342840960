import {
  errorResult,
  GPDFOnCallData,
  GPDFOnCallResponse,
  Result,
  Source,
  successResult,
} from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";

interface GetDischargeInstructionPDFFileURL {
  ({
    organizationId,
    consultationId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    consultationId: string;
    clientId: string;
    patientId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const getDischargeInstructionPDFFileURL: GetDischargeInstructionPDFFileURL =
  async ({
    organizationId,
    consultationId,
    authorId,
    authorName,
    callable,
    fcts,
    clientId,
    patientId,
  }) => {
    const generateDischargeInstructionsOnCall = callable<
      GPDFOnCallData,
      GPDFOnCallResponse
    >(fcts, "callable-generateDischargeInstructionsOnCall");
    try {
      const { data } = await generateDischargeInstructionsOnCall({
        consultationId,
        organizationId,
        action: "RETURN_DL_URL",
        authorId,
        authorName,
        clientId,
        patientId,
      });

      return successResult({
        message: "success",
        payload: data,
      });
    } catch (err: any) {
      console.error("Error:", err);
      return errorResult({ message: err.message });
    }
  };
interface SendDischargeInstructionViaEmail {
  ({
    organizationId,
    consultationId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    consultationId: string;
    clientId: string;
    patientId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const sendDischargeInstructionViaEmail: SendDischargeInstructionViaEmail =
  async ({
    organizationId,
    consultationId,
    authorId,
    authorName,
    callable,
    fcts,
    clientId,
    patientId,
  }) => {
    const generateDischargeInstructionsOnCall = callable<
      GPDFOnCallData,
      GPDFOnCallResponse
    >(fcts, "callable-generateDischargeInstructionsOnCall");
    try {
      const { data } = await generateDischargeInstructionsOnCall({
        consultationId,
        organizationId,
        clientId,
        patientId,
        action: "SEND_EMAIL",
        authorId,
        authorName,
      });

      return successResult({
        message: "success",
        payload: data,
      });
    } catch (err: any) {
      console.error("Error:", err);
      return errorResult({ message: err.message });
    }
  };

// we inject dependencies to improve testability
export const dischargeInstructions = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getDischargeInstructionPDFFileURL: (props: {
      consultationId: string;
      patientId: string;
      clientId: string;
    }) =>
      getDischargeInstructionPDFFileURL({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        ...props,
      }),
    sendDischargeInstructionViaEmail: (props: {
      consultationId: string;
      patientId: string;
      clientId: string;
    }) =>
      sendDischargeInstructionViaEmail({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        ...props,
      }),
  };
};
