import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { InventoryItemLocation } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectInventoryItemLocations } from "features/admin/inventory/Inventory.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type LocationsTableItem = InventoryItemLocation & {
  actions?: undefined;
};

export const LocationsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableItems = useAppSelector(selectInventoryItemLocations);

  const {
    deleteInventoryItemLocation,
    archiveInventoryItemLocation,
    dearchiveInventoryItemLocation,
  } = useDBServices().inventoryDBService.inventoryItemLocations;

  const columns: BDataTableColumn<LocationsTableItem>[] = [
    { accessor: "name", title: <Text>{t("NAME")}</Text>, sortable: true },
    { accessor: "notes", title: <Text>{t("NOTES")}</Text>, sortable: true },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (location) => (
        <Group position="right">
          <ActionIcon onClick={() => navigate(`edit/${location.id}`)}>
            <IconEdit size={18} />
          </ActionIcon>
          {location.status === "ARCHIVED" ? (
            <DearchiveConfirmationModal
              f={async () =>
                dearchiveInventoryItemLocation(location.id, location)
              }
              item={location}
            />
          ) : (
            <ArchiveConfirmationModal
              f={async () =>
                archiveInventoryItemLocation(location.id, location)
              }
              item={location}
            />
          )}
          <DeleteConfirmationModal
            f={async () => deleteInventoryItemLocation(location.id)}
            item={location}
          />
        </Group>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
