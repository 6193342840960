import { zodResolver } from "@mantine/form";
import { paymentDataSchema } from "beitary-shared";
import { z } from "zod";

const withdrawCashDromCreditBalanceFormSchema = paymentDataSchema;

export type WithdrawCashDromCreditBalanceFormValues = z.infer<
  typeof withdrawCashDromCreditBalanceFormSchema
>;

export const rules = zodResolver(withdrawCashDromCreditBalanceFormSchema);
