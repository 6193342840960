import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  Switch,
  Table,
  Text,
} from "@mantine/core";
import {
  IconAlertTriangle,
  IconArrowBack,
  IconPencilCheck,
  IconRefresh,
} from "@tabler/icons-react";
import { id_util, ReminderSnapshot, tu } from "beitary-shared";
import {
  AsyncActionIcon,
  BBox,
  BDateInput,
  DeleteConfirmationModal,
} from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditReminderSnapshotModal } from "../EditReminderSnapshotModal";
import { ReminderSnapshotModal } from "../ReminderSnapshotModal";

type RemindersSnapshotsTableItem = ReminderSnapshot & { actions?: undefined };

export interface RemindersSnapshotsTableProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
  weekOnly: boolean;
}

// started chatgpt opt
export const RemindersSnapshotsTable = ({
  clientId,
  patientId,
  clientName,
  patientName,
  weekOnly,
}: RemindersSnapshotsTableProps) => {
  const { t } = useTranslation();

  const [startAfterId, setStartAfterTime] = useState<string | undefined>(
    undefined
  );
  const [startBeforeId, setStartBeforeTime] = useState<string | undefined>(
    undefined
  );

  // this for week select
  const [selectedDate, setSelectedDate] = useState(
    tu.getStartOfDate(new Date())
  );

  const {
    deleteReminderSnapshot,
    getRemindersSnapshots,
    completeReminderSnapshot,
    uncompleteReminderSnapshot,
  } = useDBServices().clientCommunication.reminderSnapshots;

  const [reminderSnapshots, setReminderSnapshots] = useState<
    undefined | null | RemindersSnapshotsTableItem[]
  >(undefined);

  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [refresh, setRefresh] = useState(id_util.newId36());

  const handleNextPage = () => {
    fetchRemindersSnapshots({ startAfterId, showComplete, action: "next" });
  };

  const handlePreviousPage = () => {
    fetchRemindersSnapshots({
      startBeforeId,
      showComplete,
      action: "previous",
    });
  };

  interface fetchRemindersSnapshotsProps {
    startAfterId?: string;
    startBeforeId?: string;
    startAtId?: string;
    showComplete?: boolean;
    action: "refresh" | "next" | "previous"; // | "week-change" | "toggle-complete";
  }
  const fetchRemindersSnapshots = useCallback(
    async ({
      startAfterId,
      startBeforeId,
      showComplete,
      startAtId,
      action,
    }: fetchRemindersSnapshotsProps) => {
      setRefreshing(true);
      const props = {
        showComplete,
        startAfterId,
        startBeforeId,
        startAtId,
        clientId,
        patientId,
        day: weekOnly ? selectedDate : undefined,
      };
      const result = await getRemindersSnapshots(props);

      setRefreshing(false);
      // ... handle pagination ...
      if (result.payload) {
        if (result.payload.length > 0) {
          setReminderSnapshots(result.payload);
          setStartAfterTime(result.payload[result.payload.length - 1]?.id);
          setStartBeforeTime(result.payload[0]?.id);
        } else {
          if (action === "next") {
            setStartAfterTime(undefined);
          } else if (action === "previous") {
            setStartBeforeTime(undefined);
          } else {
            setReminderSnapshots(result.payload);
            setStartAfterTime(undefined);
            setStartBeforeTime(undefined);
          }
        }
      } else {
        // // No more data to fetch
        if (startAfterId) setStartAfterTime(undefined);
        if (startBeforeId) setStartBeforeTime(undefined);
      }
    },
    [clientId, getRemindersSnapshots, patientId, selectedDate, weekOnly]
  );

  useEffect(() => {
    fetchRemindersSnapshots({ showComplete, action: "refresh" });
  }, [fetchRemindersSnapshots, refresh, showComplete]);

  const getRow = useCallback(
    (snapshot: ReminderSnapshot) => {
      const { id, name, patientName, dueDate, status } = snapshot;
      return (
        <tr key={id}>
          <td>
            <Text>{name}</Text>
          </td>
          <td>
            <Text>{patientName}</Text>
          </td>
          <td>
            <Group>
              <Text>{tu.getDateString(dueDate)}</Text>
              {status === "CREATED" && dueDate < tu.getCurrentDateTime() && (
                <IconAlertTriangle size={16} color="red" stroke={1.5} />
              )}
            </Group>
          </td>

          <td>
            <Text color={status === "CREATED" ? "dark" : "green"}>
              {t(status)}
            </Text>
          </td>
          <td>
            <Group position="right" w={120}>
              {status === "CREATED" ? (
                <AsyncActionIcon
                  f={async () => {
                    setRefreshing(true);
                    const result = await completeReminderSnapshot(id);
                    fetchRemindersSnapshots({
                      showComplete,
                      startAtId: reminderSnapshots?.at(0)?.id,
                      action: "refresh",
                    });
                    setRefreshing(false);
                    return result;
                  }}
                  icon={<IconPencilCheck size={18} />}
                />
              ) : (
                <AsyncActionIcon
                  f={async () => {
                    setRefreshing(true);
                    const result = await uncompleteReminderSnapshot(id);
                    fetchRemindersSnapshots({
                      showComplete,
                      startAtId: reminderSnapshots?.at(0)?.id,
                      action: "refresh",
                    });
                    setRefreshing(false);
                    return result;
                  }}
                  icon={<IconArrowBack size={18} />}
                />
              )}
              <EditReminderSnapshotModal reminderSnapshot={snapshot} />
              <DeleteConfirmationModal
                f={async () => {
                  const props: fetchRemindersSnapshotsProps = {
                    showComplete,
                    action: "refresh",
                  };
                  setRefreshing(true);
                  const result = await deleteReminderSnapshot(id);
                  fetchRemindersSnapshots(props);
                  setRefreshing(false);
                  return result;
                }}
                item={{ id, name }}
              />
            </Group>
          </td>
        </tr>
      );
    },
    [
      completeReminderSnapshot,
      deleteReminderSnapshot,
      fetchRemindersSnapshots,
      reminderSnapshots,
      showComplete,
      t,
      uncompleteReminderSnapshot,
    ]
  );

  const rows = useMemo(
    () => (reminderSnapshots ?? []).map((a) => getRow(a)),
    [reminderSnapshots, getRow]
  );

  return reminderSnapshots === null ? (
    <Text>{t("ERROR")}</Text>
  ) : (
    <BBox
      header={
        <Group position="apart">
          {weekOnly ? (
            <Group spacing={0}>
              <Text ml="xl" mr="xs">
                {t("WEEK_OF")}
              </Text>
              <BDateInput
                onChange={(v) => {
                  if (v) {
                    setSelectedDate(
                      tu.getDateValueAtHMSM(tu.getDate(v), 0, 0, 0, 0)
                    );
                  }
                }}
                value={selectedDate}
              />
            </Group>
          ) : (
            <div></div>
          )}

          <Group>
            <ActionIcon
              onClick={() => {
                setRefresh(id_util.newId36());
              }}
            >
              {refreshing ? <Loader size="xs" /> : <IconRefresh size={18} />}
            </ActionIcon>

            <Switch
              label={t("SHOW_COMPLETE")}
              labelPosition="left"
              onChange={(v) => {
                setShowComplete(v.currentTarget.checked);
              }}
              checked={showComplete}
            />
            <ReminderSnapshotModal
              clientId={clientId}
              clientName={clientName}
              patientId={patientId}
              patientName={patientName}
            />
          </Group>
        </Group>
      }
    >
      <Box p="xl">
        <Table>
          <thead>
            <tr>
              <th>{t("NAME")}</th>
              <th>{t("PATIENT_NAME")}</th>
              <th>{t("DUE_DATE")}</th>
              <th>{t("STATUS")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <Group position="right" mt="xl">
          <Button onClick={handlePreviousPage} disabled={!startBeforeId}>
            {t("PREVIOUS")}
          </Button>
          <Button onClick={handleNextPage} disabled={!startAfterId}>
            {t("NEXT")}
          </Button>
        </Group>
      </Box>
    </BBox>
  );
};
