import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { appointmentTypes } from "./appointment-types";
import { boardingResources } from "./boarding-resources";
import { serviceProviders } from "./service-providers";

export const scheduleSettings = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    appointmentTypes: appointmentTypes(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    serviceProviders: serviceProviders(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    boardingResources: boardingResources(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
  };
};
