import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectClients } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PatientForm } from "../PatientForm";

export interface AddPatientProps {}

export const AddPatient = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const client = useAppSelector(selectClients)
    .filter((c) => c.id === clientId)
    .at(0);

  if (!client) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <PatientForm clientId={client.id} clientName={client.name} />;
};
