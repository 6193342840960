import { ActionIcon, Menu, Stack } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import { AppointmentStatus } from "beitary-shared";
import { AsyncActionButton } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface AppointmentActionsProps {
  appointmentId: string;
  appointmentStatus: AppointmentStatus;
  disabled?: boolean;
}

export const AppointmentActions = ({
  appointmentId,
  appointmentStatus,
  disabled,
}: AppointmentActionsProps) => {
  const { t } = useTranslation();
  const db = useDBServices().appointmentsDBService;

  const [opened, setOpened] = useState(false);

  const getButtons = () => {
    switch (appointmentStatus) {
      case "BOOKED":
        return [
          <AsyncActionButton
            f={async () => db.checkInAppointment(appointmentId)}
            child={t("CHECK_IN")}
            color="green"
          />,
          <AsyncActionButton
            f={async () => db.cancelAppointment(appointmentId)}
            child={t("CANCEL")}
            color="red"
            variant={"outline"}
          />,
          <AsyncActionButton
            f={async () => db.noShowAppointment(appointmentId)}
            child={t("NO_SHOW")}
            color="blue"
            variant={"subtle"}
          />,
        ];

      case "CANCELED":
        return <></>;

      case "CHECKED_IN":
        return [
          <AsyncActionButton
            f={async () => db.checkOutAppointment(appointmentId)}
            child={t("CHECK_OUT")}
            color="green"
          />,
        ];

      default:
        return <></>;
    }
  };

  return (
    <Menu
      disabled={disabled}
      withinPortal
      position="bottom-end"
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <ActionIcon
          disabled={disabled}
          // color={theme.primaryColor}
        >
          <IconDotsVertical size={16} stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown p="xl">
        <Stack>{getButtons()}</Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
