import { Badge, Group, Image, Stack, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { OrgPermissions } from "beitary-shared";
import { BBox, EditButton } from "components";
import { selectOrganization } from "features/Organization/Organization.slice";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const GeneralSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const org = useAppSelector(selectOrganization);

  if (!org) return <Text>{t("ORGANIZATION_NOT_FOUND")}</Text>;

  const {
    name,
    thumbURL,
    email,
    description,
    phoneNumber,
    address_Line1,
    address_city,
    address_countryCode,
    address_postalCode,
    address_Line2,
    address_stateOrProvince,
    tags,
    language,
    currency,
    timeZone,
    temperatureUnit,
  } = org;

  return (
    <Stack>
      <BBox>
        <Group grow position="apart" align="flex-start">
          <Stack p="xl" spacing="xl">
            <div>
              <Text color="cyan">{t("NAME")}</Text>
              <Text>{name}</Text>
            </div>
            <div>
              <Text color="cyan">{t("CONTACT_EMAIL")}</Text>
              <Text>{email}</Text>
            </div>
            <div>
              <Text color="cyan">{t("PHONE_NUMBER")}</Text>
              <Text>{phoneNumber}</Text>
            </div>
            <div>
              <Text color="cyan">{t("DESCRIPTION")}</Text>
              <Text>{description}</Text>
            </div>
            <div>
              <Text color="cyan">{t("ADDRESS_LINE_1")}</Text>
              <Text>{address_Line1}</Text>
            </div>
            <div>
              <Text color="cyan">{t("ADDRESS_LINE_2")}</Text>
              <Text>{address_Line2}</Text>
            </div>
            <div>
              <Text color="cyan">{t("ADDRESS_CITY")}</Text>
              <Text>{address_city}</Text>
            </div>
            <div>
              <Text color="cyan">{t("ADDRESS_STATE_OR_PROVINCE")}</Text>
              <Text>{address_stateOrProvince}</Text>
            </div>
            <div>
              <Text color="cyan">{t("ADDRESS_POSTAL_CODE")}</Text>
              <Text>{address_postalCode}</Text>
            </div>
            <div>
              <Text color="cyan">{t("COUNTRY")}</Text>
              <Text>{address_countryCode}</Text>
            </div>
          </Stack>
          <Stack p="xl" spacing="xl">
            <div>
              <Text color="cyan">{t("LOGO")}</Text>
              <Image src={thumbURL} width={128} height={128} withPlaceholder />
            </div>
            <div>
              <Text color="cyan">{t("TAGS")}</Text>
              <Text>
                {tags?.map((t) => (
                  <Badge>{t}</Badge>
                ))}
              </Text>
            </div>
            <div>
              <Text color="cyan">{t("TEMPERATURE_UNIT")}</Text>
              <Text>{temperatureUnit}</Text>
            </div>
            <div>
              <Text color="cyan">{t("LANGUAGE")}</Text>
              <Text>{language}</Text>
            </div>
            <div>
              <Text color="cyan">{t("CURRENCY")}</Text>
              <Text>{currency}</Text>
            </div>
            <div>
              <Text color="cyan">{t("TIMEZONE")}</Text>
              <Text>{timeZone}</Text>
            </div>
          </Stack>
        </Group>
      </BBox>
      <Group position="right">
        <BCan I="do" a={OrgPermissions.CLINIC_SETTINGS_CUD}>
          <EditButton f={() => navigate("edit")} />
        </BCan>
      </Group>
    </Stack>
  );
};
