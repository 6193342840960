const getItemFromLocalStorage = <T>(itemLocation: string) => {
  try {
    const itemString = localStorage.getItem(itemLocation);
    let item: T | null = null;
    if (itemString) {
      item = JSON.parse(itemString);
    }
    return item;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const saveItemToLocalStorage = <T>(item: T | null, itemLocation: string) => {
  try {
    localStorage.setItem(itemLocation, JSON.stringify(item));
  } catch (error) {
    console.log(error);
  }
};

export const lsu = {
  getItemFromLocalStorage,
  saveItemToLocalStorage,
};
