import { ActionIcon, Modal } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { ReminderSnapshot } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReminderSnapshotForm } from "../ReminderSnapshotForm";

export interface EditReminderSnapshotModalProps {
  reminderSnapshot: ReminderSnapshot;
}

export const EditReminderSnapshotModal = ({
  reminderSnapshot,
}: EditReminderSnapshotModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="xl"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("EDIT_REMINDER")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <div>
          <ReminderSnapshotForm reminderSnapshot={reminderSnapshot} />
        </div>
      </Modal>
      <ActionIcon onClick={() => setOpened(true)}>
        <IconEdit size={18} />
      </ActionIcon>
    </>
  );
};
