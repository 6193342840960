import { z } from "zod";

// sauce https://en.wikipedia.org/wiki/Veterinary_specialties bi tassarrof

// t("ANESTHESIOLOGY_PAIN_MANAGEMENT")
// t("ANATOMIC_PATHOLOGY")
// t("ANIMAL_BEHAVIOR")
// t("ANIMAL_WELFARE")
// t("AQUATIC_ANIMAL_MEDICINE")
// t("AVIAN_MEDICINE")
// t("BOVINE_MEDICINE")
// t("CANINE_MEDICINE")
// t("CARDIOLOGY")
// t("CLINICAL_PATHOLOGY")
// t("CLINICAL_PHARMACOLOGY")
// t("DENTISTRY")
// t("DERMATOLOGY")
// t("DIAGNOSTIC_IMAGING")
// t("EQINE_MEDICINE")
// t("EMERGENCY_AND_CRITICAL_CARE")
// t("HONEY_BEE_MEDICINE")
// t("FELINE_MEDICINE")
// t("IMMUNOLOGY")
// t("INTERNAL_MEDICINE")
// t("LABORATORY_ANIMAL_MEDICINE")
// t("MICROBIOLOGY")
// t("NEUROLOGY")
// t("NUTRITION")
// t("ENCOLOGY")
// t("OPHTHALMOLOGY")
// t("PARASITOLOGY")
// t("PORCIINE_MEDICINE")
// t("POULTRY_MEDICINE")
// t("PREVENTIVE_MEDICINE")
// t("RADIATION_ONCOLOGY")
// t("REPTILE_AND_AMPHIBIAN_MEDICINE")
// t("SHELTER_MEDICINE")
// t("SMALL_RUMINANT_MEDICINE")
// t("SPORTS_MEDICINE_AND_REHABILITATION")
// t("SURGERY")
// t("THERIOGENOLOGY")
// t("TOXICOLOGY")
// t("ZOOLOGICAL_MEDICINE")

export const vetSpecialtiesTagEnum = z.enum([
  "ANESTHESIOLOGY_PAIN_MANAGEMENT",
  "ANATOMIC_PATHOLOGY",
  "ANIMAL_BEHAVIOR",
  "ANIMAL_WELFARE",
  "AQUATIC_ANIMAL_MEDICINE",
  "AVIAN_MEDICINE",
  "BOVINE_MEDICINE",
  "CANINE_MEDICINE",
  "CARDIOLOGY",
  "CLINICAL_PATHOLOGY",
  "CLINICAL_PHARMACOLOGY",
  "DENTISTRY",
  "DERMATOLOGY",
  "DIAGNOSTIC_IMAGING",
  "EQINE_MEDICINE",
  "EMERGENCY_AND_CRITICAL_CARE",
  "HONEY_BEE_MEDICINE",
  "FELINE_MEDICINE",
  "IMMUNOLOGY",
  "INTERNAL_MEDICINE",
  "LABORATORY_ANIMAL_MEDICINE",
  "MICROBIOLOGY",
  "NEUROLOGY",
  "NUTRITION",
  "ENCOLOGY",
  "OPHTHALMOLOGY",
  "PARASITOLOGY",
  "PORCIINE_MEDICINE",
  "POULTRY_MEDICINE",
  "PREVENTIVE_MEDICINE",
  "RADIATION_ONCOLOGY",
  "REPTILE_AND_AMPHIBIAN_MEDICINE",
  "SHELTER_MEDICINE",
  "SMALL_RUMINANT_MEDICINE",
  "SPORTS_MEDICINE_AND_REHABILITATION",
  "SURGERY",
  "THERIOGENOLOGY", // = reproduction of animals
  "TOXICOLOGY",
  "ZOOLOGICAL_MEDICINE",
]);

export type VetSpecialtiesTag = z.infer<typeof vetSpecialtiesTagEnum>;
