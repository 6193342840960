import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { currencyEnum } from "../../common";
import { UnableToNormalizeData } from "../../errors";
/**
 * schema and types versions
 * from first to last
 */

// v0

const subscriptionTypeRecordDataV0Schema = z.object({
  email: z.number().positive().int(),
  sms: z.number().positive().int(),
  storage: z.number().positive().int(),
  monthlyPrice: z.number().int().positive(),
  yearlyPrice: z.number().int().positive(),
  taxRate: z.number().positive(),
  currency: currencyEnum,
  name: z.string(),
});

type SubscriptionTypeRecordDataV0 = z.infer<
  typeof subscriptionTypeRecordDataV0Schema
>;

const subscriptionTypeRecordV0Schema = baseRecordSchema.merge(
  subscriptionTypeRecordDataV0Schema
);

type SubscriptionTypeRecordV0 = z.infer<typeof subscriptionTypeRecordV0Schema>;

const normalizeSubscriptionTypeRecordV0 = (
  data: any
): SubscriptionTypeRecord => {
  try {
    const subscriptionTypeRecordV0: SubscriptionTypeRecordV0 =
      subscriptionTypeRecordV0Schema.parse(data);

    const { ...rest } = subscriptionTypeRecordV0;

    // here it's same object cause current v is 0
    const subscriptionTypeRecord: SubscriptionTypeRecord = {
      ...rest,
    };
    return subscriptionTypeRecord;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const SUBSCRIOTION_TYPE_RECORD_VERSION = "0";

export type SubscriptionTypeRecordData = SubscriptionTypeRecordDataV0;

export type SubscriptionTypeRecord = BaseRecord & SubscriptionTypeRecordData;

export const subscriptionTypeRecordDataSchema =
  subscriptionTypeRecordDataV0Schema;

export const subscriptionTypeRecordSchema = subscriptionTypeRecordV0Schema;

export const normalizeSubscriptionTypeRecord = (
  data: any
): SubscriptionTypeRecord => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeSubscriptionTypeRecordV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
