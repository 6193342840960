import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { clientStatusEnum } from "../ClientStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const clientDataV0Schema = z.object({
  status: clientStatusEnum, // TODO: update stuff based on this
  name: z.string(),
  taxID: z.string().optional(),
  email: z.string().email().optional(),
  phoneNumber: z.string().optional(),
  phoneNumber_countryCode: z.string().optional(),
  additionalContactInfo: z
    .array(
      z.object({
        id: z.string(),
        type: z.enum(["EMAIL", "PHONE_NUMBER"]),
        description: z.string(),
      })
    )
    .optional(),
  thumbURL: z.string().url().optional(),
  emailNotif: z.boolean().optional(),
  smsNotif: z.boolean().optional(),
  creditBalance: z.number().int().default(0), // removed non negative to ease fixing bugs
  outstandingBalance: z.number().int().default(0), // removed non negative to ease fixing bugs
  discountId: z.string().optional(),
  alert: z.string().optional(),
  additionalInfo: z.string().optional(),
  // address
  address_Line1: z.string().optional(),
  address_Line2: z.string().optional(),
  address_city: z.string().optional(),
  address_stateOrProvince: z.string().optional(),
  address_postalCode: z.string().optional(),
  address_countryCode: z.string().optional(),
});

type ClientDataV0 = z.infer<typeof clientDataV0Schema>;

const clientV0Schema = baseRecordSchema.merge(clientDataV0Schema);

type ClientV0 = z.infer<typeof clientV0Schema>;

const normalizeClientV0 = (data: any): Client => {
  try {
    const clientV0: ClientV0 = clientV0Schema.parse(data);

    const { ...rest } = clientV0;

    // here it's same object cause current v is 0
    const client: Client = {
      ...rest,
    };
    return client;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const CLIENT_VERSION = "0";

export type ClientData = ClientDataV0;

export type Client = BaseRecord & ClientData;

export const clientDataSchema = clientDataV0Schema;

export const clientSchema = clientV0Schema;

export const normalizeClient = (data: any): Client => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeClientV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
