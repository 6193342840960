import { Button, Modal } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { PatientSex, PatientSpecies } from "beitary-shared";
import { selectClients } from "features/Clients/Clients.slice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentForm } from "../AppointmentForm";

export interface AppointmentModalProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
  patientSex?: PatientSex;
  patientSpecies?: PatientSpecies;
  patientPictureURL?: string;
  defaultTime?: number;
}

export const AppointmentModal = (props: AppointmentModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  const client = useAppSelector(selectClients).find(
    (c) => c.id === props.clientId
  );

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("NEW_APPOINTMENT")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <AppointmentForm
          {...props}
          emailNotif={client?.emailNotif}
          smsNotif={client?.smsNotif}
        />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("ADD_APPOINTMNET")}</Button>
    </>
  );
};
