import { z } from "zod";

// t("ACTIVE_INV")
// t("CHARGES_COMPLETE_INV")
// t("CHECKED_OUT_INV")
export const invoiceStatusEnum = z.enum([
  "ACTIVE",
  "CHECKED_OUT",
  "CHARGES_COMPLETE",
]);

export type InvoiceStatus = z.infer<typeof invoiceStatusEnum>;
