import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const ImagingIntegrationProducts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("imaging-integration-products/btr-dl")
    ? "btr-dl"
    : "btr-dl";

  return (
    <Tabs
      mt="xl"
      value={activeTab}
      onTabChange={(value) => {
        navigate(`${value}`);
      }}
      variant="pills"
      radius="xs"
    >
      <Tabs.List mb="xl">
        <Tabs.Tab value="btr-dl">{t("INTEGRATION_BTR_DL")}</Tabs.Tab>
      </Tabs.List>
      <Outlet />
    </Tabs>
  );
};
