import { useAppSelector } from "app/hooks";
import { InventoryItem } from "beitary-shared";
import { BSelect } from "components";
import { selectActiveInventoryItems } from "features/admin/inventory/Inventory.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InventoryItemSelectProps {
  required?: boolean;
  onChange: (value: InventoryItem | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  clearable?: boolean;
  supplierId: string;
}

export const InventoryItemSelect = (props: InventoryItemSelectProps) => {
  const { t } = useTranslation();
  const items = useAppSelector(selectActiveInventoryItems).filter(
    (i) => i.supplierId === props.supplierId
  );
  const data = items.map((o) => ({ value: o.id, label: o.name }));

  const handleChange = (selectedValue?: string) => {
    if (props.onChange) {
      const item = items.find((i) => i.id === selectedValue);
      props.onChange(item);
    }
  };

  return (
    <BSelect
      {...props}
      label={t("INVENTORY_ITEM")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
      limit={20}
      onChange={handleChange}
    />
  );
};
