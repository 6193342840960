import {
  ActionIcon,
  Button,
  Group,
  Menu,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconClock, IconDotsVertical } from "@tabler/icons-react";
import {
  Consultation,
  getNotificationByResultType,
  loadingInfoNotification,
} from "beitary-shared";
import { BDateAndTimeInput, DeleteConfirmationModal } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface ConsultationActionsMenuProps {
  consultation: Consultation;
}

export const ConsultationActionsMenu = ({
  consultation,
}: ConsultationActionsMenuProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const { deleteConsultation, updateConsultation } =
    useDBServices().consultationsDBService.consultations;

  // checkedin time modal
  const [openedCheckedInTimeModal, setOpenedCheckedInTimeModal] =
    useState(false);
  const [checkedInTime, setCheckedInTime] = useState(
    consultation.checkedInTime
  );
  const [submitState, setSubmitState] = useSubmitState();

  const submitCheckedInTime = async () => {
    const notificationId = `update-${consultation.id}`;
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("WAITING_FOR_SERVER_RESPONSE"),
        title: t("UPDATE_CONSULTATION"),
      })
    );
    setSubmitState("pending-response");
    const result = await updateConsultation(consultation.id, { checkedInTime });
    setSubmitState(result.type);

    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const confirm = () => {
    submitCheckedInTime();
    setOpened(false);
  };

  const cannotUpdateCheckintime = consultation.status === "LOCKED";

  // made this instead of component cauz kept closing whenever clicking outside menu
  const CheckedInTimeModal = (
    <Modal
      opened={openedCheckedInTimeModal}
      centered
      onClose={() => setOpenedCheckedInTimeModal(false)}
      closeOnClickOutside={false}
      overlayProps={{
        color: "white",
        opacity: 0.55,
        blur: 3,
      }}
    >
      <Stack pb="xl" align="center" spacing={0}>
        <Text>{t("NEW_CHECKIN_TIME")}</Text>
        <BDateAndTimeInput
          value={checkedInTime}
          onChange={(v) => {
            if (v) setCheckedInTime(v);
          }}
        />
      </Stack>
      <Group mt="xl" position="right">
        <Button onClick={confirm} loading={submitState === "pending-response"}>
          {t("CONFIRM")}
        </Button>
        <Button
          variant="outline"
          onClick={() => setOpenedCheckedInTimeModal(false)}
        >
          {t("CANCEL")}
        </Button>
      </Group>
    </Modal>
  );

  return (
    <div>
      <Menu
        withinPortal
        position="bottom-end"
        opened={opened}
        onChange={setOpened}
      >
        <Menu.Target>
          <ActionIcon
          // color={theme.primaryColor}
          >
            <IconDotsVertical size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown p="xl">
          <Stack>
            <Button
              disabled={cannotUpdateCheckintime}
              onClick={() => setOpenedCheckedInTimeModal(true)}
              loading={submitState === "pending-response"}
              leftIcon={<IconClock size={18} />}
              variant="subtle"
            >
              {t("UPDATE_CHECKEDIN_TIME")}
            </Button>
            <DeleteConfirmationModal
              button
              f={async () => deleteConsultation(consultation.id)}
              item={{
                id: consultation.id,
                name: `${t("CONSULTATION")} ${consultation.title ?? ""} ${t(
                  "PATIENT"
                )}: ${consultation.patientName}`,
              }}
            />
          </Stack>
        </Menu.Dropdown>
      </Menu>
      {CheckedInTimeModal}
    </div>
  );
};
