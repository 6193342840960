import { Button, Modal } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TaskForm } from "../TaskForm";

export interface AddTaskModalProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
  dueDate?: number;
}

export const AddTaskModal = (props: AddTaskModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("NEW_TASK")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <TaskForm {...props} />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("ADD_TASK")}</Button>
    </>
  );
};
