import {
  errorResult,
  GPDFOnCallData,
  GPDFOnCallResponse,
  Result,
  Source,
  successResult,
} from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";
import { utils } from "../utils/utils";

interface GetClientInvoicePDFFileURL {
  ({
    organizationId,
    docId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    docId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const getInvoicePDFFileURL: GetClientInvoicePDFFileURL = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generateClientInvoiceOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generateClientInvoiceOnCall");
  try {
    const { data } = await generateClientInvoiceOnCall({
      docId: docId,
      organizationId,
      action: "RETURN_DL_URL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};
const getRefundInvoicePDFFileURL: GetClientInvoicePDFFileURL = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generateClientInvoiceOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generateClientRefundInvoiceOnCall");
  try {
    const { data } = await generateClientInvoiceOnCall({
      docId: docId,
      organizationId,
      action: "RETURN_DL_URL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};
interface SendClientInvoiceViaEmail {
  ({
    organizationId,
    docId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    docId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const sendClientInvoiceViaEmail: SendClientInvoiceViaEmail = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generateClientInvoiceOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generateClientInvoiceOnCall");
  try {
    const { data } = await generateClientInvoiceOnCall({
      docId: docId,
      organizationId,
      action: "SEND_EMAIL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

const sendClientRefundInvoiceViaEmail: SendClientInvoiceViaEmail = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generateClientInvoiceOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generateClientRefundInvoiceOnCall");
  try {
    const { data } = await generateClientInvoiceOnCall({
      docId: docId,
      organizationId,
      action: "SEND_EMAIL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const invoices = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getInvoicePDFFileURL: (docId: string) =>
      getInvoicePDFFileURL({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
    getRefundInvoicePDFFileURL: (docId: string) =>
      getRefundInvoicePDFFileURL({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
    sendClientInvoiceViaEmail: (docId: string) =>
      sendClientInvoiceViaEmail({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
    sendClientRefundInvoiceViaEmail: (docId: string) =>
      sendClientRefundInvoiceViaEmail({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
    addInvoiceSerialNumberAndDateIssued: (docId: string) =>
      utils({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        source,
      }).addSerialNumberAndDateIssued(docId, "invoice"),
    addRefundInvoiceSerialNumberAndDateIssued: (docId: string) =>
      utils({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        source,
      }).addSerialNumberAndDateIssued(docId, "refund-invoice"),
  };
};
