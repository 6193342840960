import { Stack } from "@mantine/core";
import { Consultation } from "beitary-shared";
import { BCan } from "permissions";
import { useEffect } from "react";
import { PhysicalExams, Vitals } from "./components";

interface ObjectiveProps {
  consultation: Consultation;
}

export const Objective = ({ consultation }: ObjectiveProps) => {
  const disabled = consultation.status === "LOCKED";
  useEffect(() => {}, [consultation]);

  return (
    <Stack>
      <Vitals consultationId={consultation.id} disabled={disabled} />
      <BCan I="am" a="DOCTOR">
        <PhysicalExams
          consultationId={consultation.id}
          defaultTemplateId={consultation.examTemplateId}
          disabled={disabled}
        />
      </BCan>
    </Stack>
  );
};
