import { zodResolver } from "@mantine/form";
import { consultationDataSchema } from "beitary-shared";
import { z } from "zod";

const assessmentFormSchema = consultationDataSchema.pick({
  diagnoses: true,
  problems: true,
  prognosisFrom: true,
  prognosisTo: true,
  chronicConditions: true,
  suspicions: true,
});

export type AssessmentFormValues = z.infer<typeof assessmentFormSchema>;

export const rules = zodResolver(assessmentFormSchema);
