import {
  Alert,
  Avatar,
  Badge,
  Button,
  Container,
  Flex,
  Group,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import { IconAlertCircle, IconUser } from "@tabler/icons-react";
import { Client, OrgPermissions } from "beitary-shared";
import {
  AlertTooltip,
  ArchiveConfirmationModal,
  BBox,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
  MoveBackButton,
} from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  ClientPatients,
  ClientTabs,
  ClientUpcomingAppointments,
} from "./components";

interface WrapperProps {
  client: Client;
}

export const Wrapper = ({ client }: WrapperProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deleteClient, archiveClient, dearchiveClient } =
    useDBServices().clientsDBService;

  const clientAddressString = [
    client.address_Line1,
    client.address_Line2,
    client.address_city,
    client.address_stateOrProvince,
    client.address_postalCode,
    client.address_countryCode,
  ]
    .filter((v) => v)
    .join(", ");

  return (
    <Container fluid>
      <Stack mt="xl">
        <Group mb="md">
          <MoveBackButton />
        </Group>

        <Flex
          mih={50}
          gap="md"
          justify="flex-start"
          align="flex-start"
          direction={{ base: "column", md: "row" }}
        >
          <Stack
            sx={{
              flex: 4,
            }}
          >
            <BBox
              header={
                <Group position="apart">
                  <Group spacing={4}>
                    <Avatar src={client.thumbURL} size={40} radius="xl" mr="xs">
                      <IconUser size={25} />
                    </Avatar>
                    <Text weight={500}>{client.name}</Text>
                    {client.alert && (
                      <AlertTooltip alert={client.alert} size={20} />
                    )}
                    <Badge size="lg" radius="xs" ml="xl">
                      {t(client.status)}
                    </Badge>
                  </Group>
                  <Group>
                    <BCan I="do" a={OrgPermissions.PATIENTS_CLIENTS_CUD}>
                      <Button
                        onClick={() => navigate(`/clients/edit/${client.id}`)}
                      >
                        {t("EDIT")}
                      </Button>
                      <DeleteConfirmationModal
                        button
                        f={async () => deleteClient(client.id)}
                        item={client}
                      />
                      {client.status === "ACTIVE" && (
                        <ArchiveConfirmationModal
                          button
                          f={async () => archiveClient(client.id)}
                          item={client}
                        />
                      )}
                      {client.status === "ARCHIVED" && (
                        <DearchiveConfirmationModal
                          button
                          f={async () => dearchiveClient(client.id)}
                          item={client}
                        />
                      )}
                    </BCan>
                  </Group>
                </Group>
              }
            >
              <SimpleGrid p="xl" cols={3}>
                <div>
                  <Text color="cyan">{t("EMAIL")}</Text>
                  <Text>{client.email}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("PHONE_NUMBER")}</Text>
                  <Text>{client.phoneNumber ?? "-"}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("ADDITIONAL_CONTACT_INFO")}</Text>
                  <Text>
                    {client.additionalContactInfo
                      ? client.additionalContactInfo
                          .map((o) => o.description)
                          .join(" - ")
                      : "-"}
                  </Text>
                </div>
                <div>
                  <Text color="cyan">{t("ADDRESS")}</Text>
                  <Text>{clientAddressString ?? "-"}</Text>
                </div>
                {/* <div>
                  <Text color="cyan">{t("CLIENT_DISCOUNT")}</Text>
                  <Text>{client.discountId ?? "-"}</Text>
                </div> */}
                <div>
                  <Text color="cyan">{t("ADDITIONAL_INFORMATION")}</Text>
                  <Text>{client.additionalInfo ?? "-"}</Text>
                </div>
                {client.alert && (
                  <div>
                    <Alert
                      icon={<IconAlertCircle size="1rem" />}
                      color="red.5"
                      variant="filled"
                    >
                      {client.alert}
                    </Alert>
                  </div>
                )}
              </SimpleGrid>
            </BBox>
            <ClientTabs client={client} />
          </Stack>
          <Stack
            sx={{
              flex: 3,
              width: "100%",
            }}
          >
            <ClientPatients clientId={client.id} />
            <ClientUpcomingAppointments
              clientId={client.id}
              clientName={client.name}
              emailNotif={client.emailNotif}
              smsNotif={client.smsNotif}
            />
          </Stack>
        </Flex>
      </Stack>
    </Container>
  );
};
