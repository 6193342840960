import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectSMSAppointmentNotificationTemplates } from "features/admin/client-communication/ClientCommunication.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SMSAppointmentNotificationTemplateForm } from "../SMSAptNotifTemplateForm";

export const EditSMSAppointmentNotificationTemplate = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const smsAppointmentNotificationTemplate = useAppSelector(
    selectSMSAppointmentNotificationTemplates
  )
    .filter(
      (smsAppointmentNotificationTemplate) =>
        smsAppointmentNotificationTemplate.id === id
    )
    .at(0);

  if (!smsAppointmentNotificationTemplate) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return (
    <SMSAppointmentNotificationTemplateForm
      smsAppointmentNotificationTemplate={smsAppointmentNotificationTemplate}
    />
  );
};
