import {
  Bundle,
  BundleData,
  BUNDLE_VERSION,
  errorResult,
  normalizeBundle,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization bundle

interface AddBundle {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: BundleData;
  }): Promise<Result<Bundle | null>>;
}

const addBundle: AddBundle = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new bundle id
    const newBundleRef = doc(
      collection(db, "organizations", organizationId, "bundles")
    );

    const newBundle: Bundle = normalizeBundle({
      ...data,
      id: newBundleRef.id,
      authorId,
      authorName,
      version: BUNDLE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newBundleRef, newBundle);

    // t("BUNDLE_CREATED")
    const successMessage = "BUNDLE_CREATED";
    return successResult({
      message: successMessage,
      payload: newBundle,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetBundle {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Bundle | null>>;
}

const getBundle: GetBundle = async ({ db, organizationId, id }) => {
  try {
    const bundleDocRef = doc(
      db,
      "organizations",
      organizationId,
      "bundles",
      id
    );
    const bundleDocSnapshot = await getDoc(bundleDocRef);
    if (bundleDocSnapshot.exists()) {
      try {
        const data: unknown = bundleDocSnapshot.data();
        const bundle: Bundle = normalizeBundle(data);
        // t("BUNDLE_FOUND")
        const successMessage = "BUNDLE_FOUND";
        return successResult({
          message: successMessage,
          payload: bundle,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("BUNDLE_NOT_FOUND")
      const errorMessage = "BUNDLE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditBundle {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: BundleData;
  }): Promise<Result<Bundle | null>>;
}

const editBundle: EditBundle = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const bundle = (await getBundle({ db, organizationId, id })).payload;

    if (!bundle) {
      // t("BUNDLE_NOT_FOUND")
      const errorMessage = "BUNDLE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = bundle;
      const updatedBundle: Bundle = normalizeBundle({
        ...data,
        id,
        authorId,
        authorName,
        version: BUNDLE_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      const bundleRef = doc(db, "organizations", organizationId, "bundles", id);

      await setDoc(bundleRef, updatedBundle);

      // t("BUNDLE_UPDATED")
      const successMessage = "BUNDLE_UPDATED";
      // console.log(successMessage, updatedBundle);
      return successResult({
        message: successMessage,
        payload: updatedBundle,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteBundle {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteBundle: DeleteBundle = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "bundles", id);
    try {
      await deleteDoc(docRef);
      // t("TREATMNET_DELETED")
      const successMessage = "TREATMNET_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveBundle: EditBundle = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: BundleData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editBundle({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("BUNDLE_ARCHIVED")
    const successMessage = "BUNDLE_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveBundle: EditBundle = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: BundleData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editBundle({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("BUNDLE_DEARCHIVED")
    const successMessage = "BUNDLE_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization bundles listener
interface GetBundlesListenerCallback {
  (bundles: Bundle[]): void;
}
interface GetBundlesListener {
  db: Firestore;
  organizationId: string;
  callback: GetBundlesListenerCallback;
}

const getBundlesListener = ({
  db,
  organizationId,
  callback,
}: GetBundlesListener): Unsubscribe => {
  try {
    // console.log("getBundlesListener: new listener");
    const bundlesQuery = query(
      collection(db, "organizations", organizationId, "bundles"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(bundlesQuery, (querySnapshot) => {
      const bundles: Bundle[] = [];
      querySnapshot.forEach((doc) => {
        try {
          bundles.push(normalizeBundle(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(bundles);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const bundles = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getBundlesListener: (callback: GetBundlesListenerCallback) =>
      getBundlesListener({
        db,
        organizationId,
        callback,
      }),
    getBundle: (id: string) =>
      getBundle({
        db,
        organizationId,
        id,
      }),
    addBundle: (data: BundleData) =>
      addBundle({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editBundle: (id: string, data: BundleData) =>
      editBundle({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteBundle: (id: string, data: BundleData) =>
      deleteBundle({
        db,
        organizationId,
        id,
      }),
    archiveBundle: (id: string, data: BundleData) =>
      archiveBundle({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveBundle: (id: string, data: BundleData) =>
      dearchiveBundle({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
