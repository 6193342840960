import { Source } from "beitary-shared";
import { callable, fcts } from "services/firebase/FirebaseService";
import { auth } from "./auth";
import { clients } from "./clients";
import { dischargeInstructions } from "./discharge-instructions";
import { invoices } from "./invoices";
import { members } from "./members";
import { patient } from "./patient";
import { payments } from "./payments";
import { prescriptions } from "./prescriptions";
import { reports } from "./reports";

export const CService = (
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => ({
  paymentsCService: payments(
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  invoicesCService: invoices(
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  prescriptionsCService: prescriptions(
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  dischargeInstructionsCService: dischargeInstructions(
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  clientsCService: clients(
    // TODO: prolly no longer needed
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  patientsCService: patient(
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  membersCService: members(
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  reports: reports(
    callable,
    fcts,
    organizationId,
    authorId,
    authorName,
    source
  ),
  ath: auth(callable, fcts, organizationId),
});
