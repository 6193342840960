import { Text } from "@mantine/core";
import { Invoice as InvoiceBS } from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Wrapper } from "./Invoice.wrapper";
import { RefundWrapper } from "./RefundInvoice.wrapper";

export const Invoice = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [invoice, setInvoice] = useState<InvoiceBS | undefined | null>(
    undefined
  );

  const db = useDBServices().invoicesDBService.invoices;

  useEffect(() => {
    const listener = id ? db.getInvoiceListener(id, setInvoice) : () => null;

    return () => {
      listener();
    };
  }, [id, db]);

  // console.log(invoice);

  if (invoice === undefined) {
    return <Text>{t("LOADING")}</Text>;
  } else if (invoice === null) {
    return <Text>{t("NOT_FOUND")}</Text>;
  } else {
    return !!invoice.parentId ? (
      <RefundWrapper invoice={invoice} />
    ) : (
      <Wrapper invoice={invoice} />
    );
  }
};
