import { Container, Tabs, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const View = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("inventory/purchase-orders")
    ? "purchase-orders"
    : path.includes("inventory/inventory-items")
    ? "inventory-items"
    : path.includes("inventory/transactions")
    ? "transactions"
    : "inventory-items";

  return (
    <Container fluid>
      <Title mt="xl" weight={500} order={3}>
        {t("INVENTORY")}
      </Title>
      <Tabs
        mt="xl"
        value={activeTab}
        onTabChange={(value) => {
          navigate(`${value}`);
        }}
      >
        <Tabs.List mb="xl">
          {/* <Tabs.Tab value="dashboard">{t("DASHBOARD")}</Tabs.Tab> */}
          <Tabs.Tab value="inventory-items">{t("INVENTORY_ITEMS")}</Tabs.Tab>
          <Tabs.Tab value="purchase-orders">{t("PURCHASE_ORDERS")}</Tabs.Tab>
          <Tabs.Tab value="transactions">{t("TRANSACTIONS")}</Tabs.Tab>
        </Tabs.List>
        <Outlet />
      </Tabs>
    </Container>
  );
};
