import { InvoiceLineItem, normalizeInvoice, Source } from "beitary-shared";
import { doc, Firestore, onSnapshot, Unsubscribe } from "firebase/firestore";

// get organization invoiceLineItems listener
interface GetChargesListenerCallback {
  (charges: InvoiceLineItem[]): void;
}
interface GetChargesListener {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  invoiceId: string;
  callback: GetChargesListenerCallback;
}

const getChargesListener = ({
  db,
  organizationId,
  consultationId,
  invoiceId,
  callback,
}: GetChargesListener): Unsubscribe => {
  try {
    // console.log("getChargesListener: new listener");

    const invoiceQuery = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      invoiceId
    );

    return onSnapshot(invoiceQuery, (querySnapshot) => {
      let invoiceLineItems: InvoiceLineItem[] = [];
      try {
        invoiceLineItems = Object.values(
          normalizeInvoice(querySnapshot.data()).lineItems
        );
      } catch (err) {
        console.log(err);
      }
      callback(
        invoiceLineItems.filter((i) => i.consultationId === consultationId)
      );
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const charges = ({
  authorId,
  authorName,
  consultationId,
  db,
  organizationId,
  source,
}: {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  authorId: string;
  authorName: string;
  source: Source;
}) => {
  return {
    getChargesListener: (
      invoiceId: string,
      callback: GetChargesListenerCallback
    ) =>
      getChargesListener({
        db,
        organizationId,
        consultationId,
        invoiceId,
        callback,
      }),
  };
};
