import { Stack } from "@mantine/core";
import { Consultation } from "beitary-shared";
import { useEffect } from "react";
import { SubjectiveForm } from "./components";

interface SubjectiveProps {
  consultation: Consultation;
}

export const Subjective = ({ consultation }: SubjectiveProps) => {
  useEffect(() => {}, [consultation]);

  return (
    <Stack>
      <SubjectiveForm consultation={consultation} />
    </Stack>
  );
};
