import { Box, Container, Flex, Tabs } from "@mantine/core";
import { useAppDispatch } from "app/hooks";
import {
  BFile,
  Consultation,
  DischargeInstruction,
  InvoiceLineItem,
  PhysicalExamEntry,
  Prescription,
  Treatment,
  VitalsEntry,
} from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import {
  setActiveConsltationCharges,
  setActiveConsltationDischargeInstructions,
  setActiveConsltationFiles,
  setActiveConsltationPhysicalExams,
  setActiveConsltationPrescriptions,
  setActiveConsltationTreatments,
  setActiveConsltationVitals,
} from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Assessment,
  Charges,
  ConsultationNotesCard,
  DischargeInstructions,
  ImportantInfoCard,
  Objective,
  PatientLifecycleSearchContainer,
  Plan,
  Prescriptions,
  Subjective,
  Summary,
} from "./components";
import {
  AddDischargeInstruction,
  EditDischargeInstruction,
} from "./components/DischargeInstructions/components";
import {
  PhysicalExamEntryForm,
  VitalsEntryForm,
} from "./components/Objective/components";
import {
  AdminBundleTxForm,
  AdminTxForm,
  BundleTxForm,
  TxForm,
} from "./components/Plan/components";
import {
  AddPrescription,
  EditPrescription,
} from "./components/Prescriptions/components";
import { ViewPrescription } from "./components/Prescriptions/components/ViewPrescription";

interface WrapperProps {
  consultation: Consultation;
}

export const Wrapper = ({ consultation }: WrapperProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [collapseLifecycle, setCollapseLifecycle] = useState<
    boolean | undefined
  >(undefined);

  const { id, invoiceId } = consultation;
  const db = useDBServices();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getConsultationTreatmentsListenerUnsubscribe =
      db.consultationsDBService
        .treatments(id)
        .getTreatmentsListener((items: Treatment[]) => {
          dispatch(setActiveConsltationTreatments(items));
        });

    const getConsultationVitalsListenerUnsubscribe = db.consultationsDBService
      .vitals(id)
      .getVitalsEntrysListener((items: VitalsEntry[]) => {
        dispatch(setActiveConsltationVitals(items));
      });

    const getConsultationPhysicalExamsListenerUnsubscribe =
      db.consultationsDBService
        .physicalExams(id)
        .getPhysicalExamEntrysListener((items: PhysicalExamEntry[]) => {
          dispatch(setActiveConsltationPhysicalExams(items));
        });

    const getConsultationDischargeInstructionsListenerUnsubscribe =
      db.consultationsDBService
        .dischargeInstructions(id)
        .getDischargeInstructionsListener((items: DischargeInstruction[]) => {
          dispatch(setActiveConsltationDischargeInstructions(items));
        });

    const getConsultationPrescriptionsListenerUnsubscribe =
      db.prescriptionsDBService.getConsultationPrescriptionsListener({
        consultationId: id,
        callback: (items: Prescription[]) => {
          dispatch(setActiveConsltationPrescriptions(items));
        },
      });

    const getConsultationChargesListenerUnsubscribe = invoiceId
      ? db.consultationsDBService
          .charges(id)
          .getChargesListener(invoiceId, (items: InvoiceLineItem[]) => {
            dispatch(setActiveConsltationCharges(items));
          })
      : () => dispatch(setActiveConsltationCharges([]));

    const getConsultationFilesListener =
      db.filesDBService.getConsultationBFilesListener({
        consultationId: id,
        callback: (items: BFile[]) => {
          dispatch(setActiveConsltationFiles(items));
        },
      });

    return () => {
      getConsultationTreatmentsListenerUnsubscribe();
      getConsultationVitalsListenerUnsubscribe();
      getConsultationPhysicalExamsListenerUnsubscribe();
      getConsultationDischargeInstructionsListenerUnsubscribe();
      getConsultationPrescriptionsListenerUnsubscribe();
      getConsultationChargesListenerUnsubscribe();
      getConsultationFilesListener();
    };
  }, [db, dispatch, id, invoiceId]);

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("summary")
    ? "summary"
    : path.includes("subjective")
    ? "subjective"
    : path.includes("objective")
    ? "objective"
    : path.includes("assessment")
    ? "assessment"
    : path.includes("plan")
    ? "plan"
    : path.includes("discharge-instructions")
    ? "discharge-instructions"
    : path.includes("prescriptions")
    ? "prescriptions"
    : path.includes("charges")
    ? "charges"
    : "summary";

  const consultationLocked = consultation.status === "LOCKED";

  return (
    <Container fluid>
      <ImportantInfoCard
        consultation={consultation}
        setCollapseLifecycle={() =>
          setCollapseLifecycle(
            collapseLifecycle === undefined ? false : !collapseLifecycle
          )
        }
        collapseLifecycle={collapseLifecycle}
      />
      <Flex
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction={{ base: "column-reverse", lg: "row" }}
        columnGap={
          collapseLifecycle === undefined || collapseLifecycle === true
            ? 0
            : "lg"
        }
      >
        <Box
          sx={{
            flex: 2,
          }}
        >
          {consultation.notes && (
            <ConsultationNotesCard notes={consultation.notes} />
          )}

          <Tabs
            mt="xl"
            value={activeTab}
            onTabChange={(value) => {
              navigate(`${value}`);
            }}
            unstyled
            styles={(theme) => ({
              tab: {
                ...theme.fn.focusStyles(),
                backgroundColor: theme.white,
                color: theme.colors.gray[6],
                border: `1px solid ${theme.colors.gray[3]}`,
                padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
                cursor: "pointer",
                fontSize: theme.fontSizes.sm,
                display: "flex",
                alignItems: "center",

                "&:disabled": {
                  opacity: 0.5,
                  cursor: "not-allowed",
                },

                "&:not(:first-of-type)": {
                  borderLeft: 0,
                },

                "&[data-active]": {
                  backgroundColor: theme.colors.cyan[6],
                  borderColor: theme.colors.cyan[6],
                  color: theme.white,
                },
                flexShrink: 1,
              },

              tabIcon: {
                marginRight: theme.spacing.xs,
                display: "flex",
                alignItems: "center",
              },

              tabsList: {
                display: "flex",
                flexWrap: "wrap",
              },
            })}
          >
            <Tabs.List mb="xl" grow w="100%">
              <Tabs.Tab value="summary">{t("SUMMARY")}</Tabs.Tab>
              <Tabs.Tab value="subjective">{t("SUBJECTIVE")}</Tabs.Tab>
              <Tabs.Tab value="objective">{t("OBJECTIVE")}</Tabs.Tab>
              <Tabs.Tab value="assessment">{t("ASSESSMENT")}</Tabs.Tab>
              <Tabs.Tab value="plan">{t("PLAN")}</Tabs.Tab>
              <Tabs.Tab value="discharge-instructions">
                {t("DISCHARGE_INSTRUCTIONS")}
              </Tabs.Tab>
              <Tabs.Tab value="prescriptions">{t("PRESCRIPTIONS")}</Tabs.Tab>
              <Tabs.Tab value="charges">{t("CHARGES")}</Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <Routes>
            <Route index element={<Navigate to="summary" replace />} />
            <Route
              path={"summary"}
              element={<Summary consultation={consultation} />}
            />
            <Route
              path="subjective"
              element={<Subjective consultation={consultation} />}
            />
            <Route path="objective" element={<Outlet />}>
              <Route
                index
                element={<Objective consultation={consultation} />}
              />
              <Route
                path="vitals/:vitalId/new"
                element={
                  <VitalsEntryForm newEntry consultationId={consultation.id} />
                }
              />
              <Route
                path="vitals/:vitalId/edit"
                element={
                  <VitalsEntryForm
                    consultationId={consultation.id}
                    disabled={consultationLocked}
                  />
                }
              />
              <Route
                path="physical-exams/:physicalExamId/new"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <PhysicalExamEntryForm
                        newEntry
                        consultation={consultation}
                      />
                    }
                  />
                }
              />
              <Route
                path="physical-exams/:physicalExamId/edit"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <PhysicalExamEntryForm consultation={consultation} />
                    }
                  />
                }
              />
            </Route>
            <Route
              path="assessment"
              element={<Assessment consultation={consultation} />}
            />
            <Route path="plan" element={<Outlet />}>
              <Route index element={<Plan consultation={consultation} />} />
              <Route
                path="treatments/:treatmentId/edit"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <TxForm
                        consultationId={consultation.id}
                        disabled={consultationLocked}
                      />
                    }
                  />
                }
              />
              <Route
                path="treatments/administred/:treatmentId/edit"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <AdminTxForm
                        consultationId={consultation.id}
                        disabled={consultationLocked}
                      />
                    }
                  />
                }
              />

              <Route
                path="bundle-treatments/:bundleId/edit"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <BundleTxForm
                        consultationId={consultation.id}
                        disabled={consultationLocked}
                      />
                    }
                  />
                }
              />
              <Route
                path="bundle-treatments/administred/:bundleId/edit"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <AdminBundleTxForm
                        consultationId={consultation.id}
                        disabled={consultationLocked}
                      />
                    }
                  />
                }
              />
            </Route>

            <Route path="discharge-instructions" element={<Outlet />}>
              <Route
                index
                element={
                  <DischargeInstructions
                    consultationId={consultation.id}
                    clientId={consultation.clientId}
                    patientId={consultation.patientId}
                    disabled={consultationLocked}
                    patientName={consultation.patientName}
                  />
                }
              />
              <Route
                path=":instructionId/edit"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <EditDischargeInstruction
                        disabled={consultationLocked}
                        consultationId={consultation.id}
                      />
                    }
                  />
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <AddDischargeInstruction
                        disabled={consultationLocked}
                        consultationId={consultation.id}
                      />
                    }
                  />
                }
              />
            </Route>
            <Route path="prescriptions" element={<Outlet />}>
              <Route
                index
                element={<Prescriptions disabled={consultationLocked} />}
              />
              <Route
                path=":prescriptionId/edit"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <EditPrescription
                        disabled={consultationLocked}
                        clientId={consultation.clientId}
                        clientName={consultation.clientName}
                        consultationId={consultation.id}
                        patientId={consultation.patientId}
                        patientName={consultation.patientName}
                      />
                    }
                  />
                }
              />
              <Route
                path=":prescriptionId/view"
                element={<ViewPrescription />}
              />
              <Route
                path="new"
                element={
                  <ProtectedRouteWrapper
                    conditions={["am", "DOCTOR"]}
                    element={
                      <AddPrescription
                        disabled={consultationLocked}
                        clientId={consultation.clientId}
                        clientName={consultation.clientName}
                        consultationId={consultation.id}
                        patientId={consultation.patientId}
                        patientName={consultation.patientName}
                      />
                    }
                  />
                }
              />
            </Route>
            <Route
              path="charges"
              element={<Charges invoiceId={consultation.invoiceId} />}
            />
          </Routes>
        </Box>
        <Box
          sx={{
            flex:
              collapseLifecycle === undefined || collapseLifecycle === true
                ? 0
                : 1,
            width: "100%",
          }}
        >
          {collapseLifecycle !== undefined && (
            <PatientLifecycleSearchContainer
              visible={!collapseLifecycle}
              clientId={consultation.clientId}
              patientId={consultation.patientId}
            />
          )}
        </Box>
      </Flex>
    </Container>
  );
};
