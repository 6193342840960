import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectEmailReminderNotificationTemplates } from "features/admin/client-communication/ClientCommunication.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EmailTemplateForm } from "../EmailTemplateForm";

export const EditEmailTemplate = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const emailTemplate = useAppSelector(selectEmailReminderNotificationTemplates)
    .filter((emailTemplate) => emailTemplate.id === id)
    .at(0);

  if (!emailTemplate) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <EmailTemplateForm emailTemplate={emailTemplate} />;
};
