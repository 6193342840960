import { z } from "zod";

// t("APT_BOOKED")
// t("APT_RESCHEDULED")
// t("APT_CANCELED")
// t("APT_REMINDER")
export const appointmentNotificationTemplateTypeEnum = z.enum([
  "APT_BOOKED",
  "APT_RESCHEDULED",
  "APT_CANCELED",
  "APT_REMINDER",
]);

export type AppointmentNotificationTemplateType = z.infer<
  typeof appointmentNotificationTemplateTypeEnum
>;
