import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Diagnoses } from "features/admin/diagnoses";
import {
  AddDiagnosis,
  DiagnosesTable,
  EditDiagnosis,
} from "features/admin/diagnoses/components";
import { Route } from "react-router-dom";

export const DiagnosesRoutes = (
  <Route path="diagnoses" element={<Diagnoses />}>
    <Route index element={<DiagnosesTable />} />
    <Route
      path="new"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.DIAGNOSES_CUD]}
          element={<AddDiagnosis />}
        />
      }
    />
    <Route
      path="edit/:id"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.DIAGNOSES_CUD]}
          element={<EditDiagnosis />}
        />
      }
    />
  </Route>
);
