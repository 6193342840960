import { Box } from "@mantine/core";
import { BFile } from "beitary-shared";
import { App, AppOptions, decoderScripts, ToolConfig, ViewConfig } from "dwv";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";

// Image decoders (for web workers)
decoderScripts.jpeg2000 = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpeg2000.js`;
decoderScripts[
  "jpeg-lossless"
] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/rii-mango/decode-jpegloss.js`;
decoderScripts[
  "jpeg-baseline"
] = `${process.env.PUBLIC_URL}/assets/dwv/decoders/pdfjs/decode-jpegbaseline.js`;
decoderScripts.rle = `${process.env.PUBLIC_URL}/assets/dwv/decoders/dwv/decode-rle.js`;

export interface BSimpleDICOMViewerProps {
  // dlURL: string | undefined;
  file?: BFile;
  fileId: string;
  setFilename?: (name: string) => void;
}

export const BSimpleDICOMViewer = ({
  fileId,
  file,
  setFilename,
}: // dlURL,
BSimpleDICOMViewerProps) => {
  const db = useDBServices().filesDBService;

  const [fileDlURL, setFileDlURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getDLURL = async () => {
      try {
        let dlURL: string | undefined = undefined;

        if (file) {
          dlURL = file.dlURL;
        } else {
          const bfile = (await db.getBFile(fileId)).payload;
          if (bfile) {
            dlURL = bfile.dlURL;
            if (setFilename) setFilename(bfile.originalName);
          }
        }

        if (dlURL) {
          setFileDlURL(dlURL);
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    getDLURL();
  }, [db, file, fileId, setFilename]);

  return <MyDICOMViewer dlURL={fileDlURL} />;
};

export const MyDICOMViewer = ({ dlURL }: { dlURL?: string }) => {
  const [app] = useState(new App());

  useEffect(() => {
    // initialise
    const viewConfig0 = new ViewConfig("layerGroup0");
    const viewConfigs = { "*": [viewConfig0] };
    const options = new AppOptions(viewConfigs);
    options.tools = { Scroll: new ToolConfig() };
    app.init(options);
    // load dicom data
    app.loadURLs([dlURL ?? ""]);
  }, [app, dlURL]);

  return <Box id="layerGroup0" w={"100%"} h={"90%"}></Box>;
};
