import { TasksTable } from "features/tasks/components";

export interface TasksProps {
  clientId: string;
  clientName: string;
  patientId: string;
  patientName: string;
}

export const Tasks = ({
  clientId,
  clientName,
  patientId,
  patientName,
}: TasksProps) => {
  return (
    <TasksTable
      clientId={clientId}
      clientName={clientName}
      patientId={patientId}
      patientName={patientName}
    />
  );
};
