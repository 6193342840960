import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const emailReminderNotificationTemplateDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  remindersIds: z.array(z.string()),
  notificationContent: z.string(),
});

type EmailReminderNotificationTemplateDataV0 = z.infer<
  typeof emailReminderNotificationTemplateDataV0Schema
>;

const emailReminderNotificationTemplateV0Schema = baseRecordSchema.merge(
  emailReminderNotificationTemplateDataV0Schema
);

type EmailReminderNotificationTemplateV0 = z.infer<
  typeof emailReminderNotificationTemplateV0Schema
>;

const normalizeEmailReminderNotificationTemplateV0 = (
  data: any
): EmailReminderNotificationTemplate => {
  try {
    const emailReminderNotificationTemplateV0: EmailReminderNotificationTemplateV0 =
      emailReminderNotificationTemplateV0Schema.parse(data);

    const { ...rest } = emailReminderNotificationTemplateV0;

    // here it's same object cause current v is 0
    const emailReminderNotificationTemplate: EmailReminderNotificationTemplate =
      {
        ...rest,
      };
    return emailReminderNotificationTemplate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const EMAIL_REMINDER_NOTIFICATION_TEMPLATE_VERSION = "0";

export type EmailReminderNotificationTemplateData =
  EmailReminderNotificationTemplateDataV0;

export type EmailReminderNotificationTemplate = BaseRecord &
  EmailReminderNotificationTemplateData;

export const emailReminderNotificationTemplateDataSchema =
  emailReminderNotificationTemplateDataV0Schema;

export const emailReminderNotificationTemplateSchema =
  emailReminderNotificationTemplateV0Schema;

export const normalizeEmailReminderNotificationTemplate = (
  data: any
): EmailReminderNotificationTemplate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeEmailReminderNotificationTemplateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
