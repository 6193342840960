import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { memberRoles } from "./member-roles";
import { memberss } from "./members/members";

export const members = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    members: memberss(db, organizationId, authorId, authorName, source),
    memberRoles: memberRoles(db, organizationId, authorId, authorName, source),
  };
};
