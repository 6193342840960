import { BSelect } from "components/BSelect";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ContactInfoTypeSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const ContactInfoTypeSelect = (props: ContactInfoTypeSelectProps) => {
  const { t } = useTranslation();

  const data = ["EMAIL", "PHONE_NUMBER"].map((o) => ({
    value: o,
    label: t(o),
  }));

  return <BSelect {...props} data={data} nothingFound={t("NOTHING_FOUND")} />;
};
