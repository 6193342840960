import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";

/**
 * schema and types versions
 * from first to last
 */

// v0

const responseDataV0Schema = z.object({
  content: z.string(),
  language: z.string().optional(),
});

type ResponseDataV0 = z.infer<typeof responseDataV0Schema>;

const responseV0Schema = baseRecordSchema.merge(responseDataV0Schema);

type ResponseV0 = z.infer<typeof responseV0Schema>;

const normalizeResponseV0 = (data: any): Response => {
  try {
    const responseV0: ResponseV0 = responseV0Schema.parse(data);

    const { ...rest } = responseV0;

    // here it's same object cause current v is 0
    const response: Response = {
      ...rest,
    };
    return response;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const RESPONSE_VERSION = "0";

export type ResponseData = ResponseDataV0;

export type Response = BaseRecord & ResponseData;

export const responseDataSchema = responseDataV0Schema;

export const responseSchema = responseV0Schema;

export const normalizeResponse = (data: any): Response => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeResponseV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
