import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors";
import { imagingIntegrationEnum } from "../Integration/Integration";

const imagingIntegrationProductDataV0Schema = z.object({
  status: genericStatusEnum,
  integration: imagingIntegrationEnum,
  name: z.string(), // name of integration product = code (like DR) = requested procedure id
  description: z.string().optional(), // description of integration product = display name (like DR) = requested procedure description
  modality: z.string().optional(), // AE title
});

type ImagingIntegrationProductDataV0 = z.infer<
  typeof imagingIntegrationProductDataV0Schema
>;

const imagingIntegrationProductV0Schema = baseRecordSchema.merge(
  imagingIntegrationProductDataV0Schema
);

type ImagingIntegrationProductV0 = z.infer<
  typeof imagingIntegrationProductV0Schema
>;

const normalizeImagingIntegrationProductV0 = (
  data: any
): ImagingIntegrationProduct => {
  try {
    const imagingIntegrationProductV0: ImagingIntegrationProductV0 =
      imagingIntegrationProductV0Schema.parse(data);

    const { ...rest } = imagingIntegrationProductV0;

    // here it's same object cause current v is 0
    const imagingIntegrationProduct: ImagingIntegrationProduct = {
      ...rest,
    };
    return imagingIntegrationProduct;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const IMAGING_INTEGRATION_PRODUCT_VERSION = "0";

export type ImagingIntegrationProductData = ImagingIntegrationProductDataV0;

export type ImagingIntegrationProduct = BaseRecord &
  ImagingIntegrationProductData;

export const imagingIntegrationProductDataSchema =
  imagingIntegrationProductDataV0Schema;

export const imagingIntegrationProductSchema =
  imagingIntegrationProductV0Schema;

export const normalizeImagingIntegrationProduct = (
  data: any
): ImagingIntegrationProduct => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeImagingIntegrationProductV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
