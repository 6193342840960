// Import the functions you need from the SDKs you need
import axios from "axios";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { MissingFirebaseAuthEmulatorConfig } from "./errors/MissingFirebaseAuthEmulatorConfig";
import { MissingFirebaseCloudFunctionAPIEmulatorConfig } from "./errors/MissingFirebaseCloudFunctionAPIEmulatorConfig";
import { MissingFirebaseConfig } from "./errors/MissingFirebaseConfig";
import { MissingFirebaseFirestoreEmulatorConfig } from "./errors/MissingFirebaseFirestoreEmulatorConfig";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// you need .env.development file in project root directory with all config keys
if (
  !process.env.REACT_APP_FIREBASE_API_KEY ||
  !process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  !process.env.REACT_APP_FIREBASE_PROJECT_ID ||
  !process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
  !process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
  !process.env.REACT_APP_FIREBASE_APP_ID ||
  !process.env.REACT_APP_FIREBASE_MEASUREMENT_ID ||
  !process.env.REACT_APP_CLOUD_FUNCTIIONS_API_URL
) {
  throw new MissingFirebaseConfig();
}

var config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(config);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);
const thumbnailsStorage = getStorage(app, `${config.projectId}-thumbnails`);
const imagesStorage = getStorage(app, `${config.projectId}-images`);
const videosStorage = getStorage(app, `${config.projectId}-videos`);
const auth = getAuth(app);
// apply device language for email verif language
auth.useDeviceLanguage();

const cloudFunctionsAPI = axios.create({
  headers: {
    "content-type": "application/json",
  },
});
cloudFunctionsAPI.defaults.baseURL =
  process.env.REACT_APP_CLOUD_FUNCTIIONS_API_URL;

// connect to emulator if local dev
if (
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1" ||
  window.location.hostname === ""
) {
  if (
    !process.env.REACT_APP_FIRESTORE_EMULATOR_HOST ||
    !process.env.REACT_APP_FIRESTORE_EMULATOR_PORT
  ) {
    throw new MissingFirebaseFirestoreEmulatorConfig();
  }
  if (
    !process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST ||
    !process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT
  ) {
    throw new MissingFirebaseAuthEmulatorConfig();
  }
  if (!process.env.REACT_APP_CLOUD_FUNCTIIONS_EMULATOR_API_URL) {
    throw new MissingFirebaseCloudFunctionAPIEmulatorConfig();
  }

  console.log(
    `Connecting to Firestore emulator running on ${process.env.REACT_APP_FIRESTORE_EMULATOR_HOST}:${process.env.REACT_APP_FIRESTORE_EMULATOR_PORT} ...`
  );
  connectFirestoreEmulator(
    db,
    process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
    parseInt(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT)
  );

  console.log(
    `Connecting to Firebase Authentication emulator running on  ${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST}:${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT} ...`
  );
  connectAuthEmulator(
    auth,
    `${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST}:${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT}`
  );

  // use cloud functions emulator api
  console.log(
    `Connecting to Firebase Cloud Functions emulator API on ${process.env.REACT_APP_CLOUD_FUNCTIIONS_EMULATOR_API_URL}...`
  );
  cloudFunctionsAPI.defaults.baseURL =
    process.env.REACT_APP_CLOUD_FUNCTIIONS_EMULATOR_API_URL;

  connectFunctionsEmulator(functions, "127.0.0.1", 5001);

  // use cloud functions emulator api
  console.log(
    `Connecting to Firebase Cloud Storage emulator API on 127.0.0.1:9199 ...`
  );

  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectStorageEmulator(thumbnailsStorage, "127.0.0.1", 9199);
  connectStorageEmulator(imagesStorage, "127.0.0.1", 9199);
  connectStorageEmulator(videosStorage, "127.0.0.1", 9199);
}

// connect to emulator if local dev mobile
if (
  window.location.hostname.startsWith("192.168.1") // this is in case opening app from mobile device
) {
  if (
    !process.env.REACT_APP_FIRESTORE_EMULATOR_HOST_LAN ||
    !process.env.REACT_APP_FIRESTORE_EMULATOR_PORT
  ) {
    throw new MissingFirebaseFirestoreEmulatorConfig();
  }
  if (
    !process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST_LAN ||
    !process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT
  ) {
    throw new MissingFirebaseAuthEmulatorConfig();
  }
  if (!process.env.REACT_APP_CLOUD_FUNCTIIONS_EMULATOR_API_URL_LAN) {
    throw new MissingFirebaseCloudFunctionAPIEmulatorConfig();
  }

  console.log(
    `Connecting to Firestore emulator running on ${process.env.REACT_APP_FIRESTORE_EMULATOR_HOST_LAN}:${process.env.REACT_APP_FIRESTORE_EMULATOR_PORT} ...`
  );
  connectFirestoreEmulator(
    db,
    process.env.REACT_APP_FIRESTORE_EMULATOR_HOST_LAN,
    parseInt(process.env.REACT_APP_FIRESTORE_EMULATOR_PORT)
  );

  console.log(
    `Connecting to Firebase Authentication emulator running on  ${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST_LAN}:${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT} ...`
  );
  connectAuthEmulator(
    auth,
    `${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST_LAN}:${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_PORT}`
  );

  // use cloud functions emulator api
  console.log(
    `Connecting to Firebase Cloud Functions emulator API on ${process.env.REACT_APP_CLOUD_FUNCTIIONS_EMULATOR_API_URL_LAN}...`
  );
  cloudFunctionsAPI.defaults.baseURL =
    process.env.REACT_APP_CLOUD_FUNCTIIONS_EMULATOR_API_URL_LAN;

  connectFunctionsEmulator(functions, "192.168.1.169", 5001);

  // use cloud functions emulator api
  console.log(
    `Connecting to Firebase Cloud Storage emulator API on 192.168.1.169:9199 ...`
  );

  connectStorageEmulator(storage, "192.168.1.169", 9199);
  connectStorageEmulator(thumbnailsStorage, "192.168.1.169", 9199);
  connectStorageEmulator(imagesStorage, "192.168.1.169", 9199);
  connectStorageEmulator(videosStorage, "192.168.1.169", 9199);
}

export const dbInstance = db;
export const fcts = functions;
export const storageInstance = storage;
export const thumbnailStorageInstance = thumbnailsStorage;
export const imagesStorageInstance = imagesStorage;
export const videosStorageInstance = videosStorage;
export const callable = httpsCallable;
export const authInstance = auth;
export const cfAPI = cloudFunctionsAPI;
