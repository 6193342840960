import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveInventoryItems } from "features/admin/inventory/Inventory.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InventoryItemSelectProps {
  required?: boolean;
  onChange: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  clearable?: boolean;
}

export const InventoryItemSelect = (props: InventoryItemSelectProps) => {
  const { t } = useTranslation();
  const items = useAppSelector(selectActiveInventoryItems);
  const data = items.map((o) => ({ value: o.id, label: o.name }));

  return (
    <BSelect
      {...props}
      label={t("INVENTORY_ITEM")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
      limit={20}
    />
  );
};
