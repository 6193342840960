import { NumberInput } from "@mantine/core";
import { ReactNode } from "react";
import { org_params_util } from "utils/org_params_utils";

export interface BMoneyInputProps {
  required?: boolean;
  onChange: (value: number | "") => void;
  error?: ReactNode;
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  label?: string;
  description?: string;
}

export const BMoneyInput = ({
  min,
  max,
  value,
  onChange,
  ...props
}: BMoneyInputProps) => {
  const { dCurrency } = org_params_util.getOrgParams();
  const { exponent } = dCurrency;

  return (
    <NumberInput
      {...props}
      step={0.05}
      precision={exponent}
      min={min ?? 0}
      max={max}
      value={value !== undefined ? value / 10 ** exponent : undefined}
      onChange={(v) => {
        if (v !== "") {
          onChange(v * 10 ** exponent);
        } else onChange(v);
      }}
    />
  );
};
