import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  BFile,
  Consultation,
  DischargeInstruction,
  InvoiceLineItem,
  PhysicalExamEntry,
  Prescription,
  Treatment,
  VitalsEntry,
} from "beitary-shared";

interface ConsultationsState {
  currentConsultations: Consultation[];
  activeConsultation: Consultation | null;
  activeConsltationTreatments: Treatment[];
  activeConsltationVitals: VitalsEntry[];
  activeConsltationPhysicalExams: PhysicalExamEntry[];
  activeConsltationDischargeInstructions: DischargeInstruction[];
  activeConsltationPrescription: Prescription[];
  activeConsltationCharges: InvoiceLineItem[];
  activeConsltationFiles: BFile[];
}

const initialState: ConsultationsState = {
  currentConsultations: [],
  activeConsultation: null,
  activeConsltationTreatments: [],
  activeConsltationVitals: [],
  activeConsltationPhysicalExams: [],
  activeConsltationDischargeInstructions: [],
  activeConsltationPrescription: [],
  activeConsltationCharges: [],
  activeConsltationFiles: [],
};

export const consultationsSlice = createSlice({
  name: "consultations",
  initialState,
  reducers: {
    setCurrentConsultations: (state, action: PayloadAction<Consultation[]>) => {
      state.currentConsultations = action.payload;
    },
    setActiveConsltation: (
      state,
      action: PayloadAction<Consultation | null>
    ) => {
      state.activeConsultation = action.payload;
    },
    setActiveConsltationTreatments: (
      state,
      action: PayloadAction<Treatment[]>
    ) => {
      state.activeConsltationTreatments = action.payload;
    },
    setActiveConsltationVitals: (
      state,
      action: PayloadAction<VitalsEntry[]>
    ) => {
      state.activeConsltationVitals = action.payload;
    },
    setActiveConsltationPhysicalExams: (
      state,
      action: PayloadAction<PhysicalExamEntry[]>
    ) => {
      state.activeConsltationPhysicalExams = action.payload;
    },
    setActiveConsltationDischargeInstructions: (
      state,
      action: PayloadAction<DischargeInstruction[]>
    ) => {
      state.activeConsltationDischargeInstructions = action.payload;
    },
    setActiveConsltationPrescriptions: (
      state,
      action: PayloadAction<Prescription[]>
    ) => {
      state.activeConsltationPrescription = action.payload;
    },
    setActiveConsltationCharges: (
      state,
      action: PayloadAction<InvoiceLineItem[]>
    ) => {
      state.activeConsltationCharges = action.payload;
    },
    setActiveConsltationFiles: (state, action: PayloadAction<BFile[]>) => {
      state.activeConsltationFiles = action.payload;
    },
  },
});

export const {
  setCurrentConsultations,
  setActiveConsltationTreatments,
  setActiveConsltationPhysicalExams,
  setActiveConsltationVitals,
  setActiveConsltationDischargeInstructions,
  setActiveConsltationPrescriptions,
  setActiveConsltationCharges,
  setActiveConsltation,
  setActiveConsltationFiles,
} = consultationsSlice.actions;

export const selectCurrentConsultations = (state: RootState) =>
  state.consultations.currentConsultations;

export const selectActiveConsultation = (state: RootState) =>
  state.consultations.activeConsultation;

export const selectActiveConsultations = (state: RootState) =>
  state.consultations.currentConsultations.filter((c) => c.status === "ACTIVE");

export const selectInReviewConsultations = (state: RootState) =>
  state.consultations.currentConsultations.filter(
    (c) => c.status === "IN_REVIEW"
  );

export const selectLabPendingConsultations = (state: RootState) =>
  state.consultations.currentConsultations.filter(
    (c) => c.status === "LAB_PENDING"
  );

export const selectActiveConsultationTreatments = (state: RootState) =>
  state.consultations.activeConsltationTreatments;

export const selectActiveConsultationVitals = (state: RootState) =>
  state.consultations.activeConsltationVitals;

export const selectActiveConsultationPhysicalExams = (state: RootState) =>
  state.consultations.activeConsltationPhysicalExams;

export const selectActiveConsultationDischargeInstructions = (
  state: RootState
) => state.consultations.activeConsltationDischargeInstructions;

export const selectActiveConsultationPrescriptions = (state: RootState) =>
  state.consultations.activeConsltationPrescription;

export const selectActiveConsultationCharges = (state: RootState) =>
  state.consultations.activeConsltationCharges;

// without .slice() or ... got an error of "cannot assign to read only propert"
// reason the array is frozen in your case is because your using the array as props from the redux store and props in React are immutable hence your not being able to mutate the array.
// The array is frozen to prevent mutation of the redux state
export const selectActiveConsultationFiles = (state: RootState) =>
  [...state.consultations.activeConsltationFiles].sort((a, b) =>
    b.createdAt < a.createdAt ? 1 : -1
  );

export const consultationsReducer = consultationsSlice.reducer;
