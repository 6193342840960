import { appointmentLocationEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface AppointmentLocationSelectProps {
  required?: boolean;
  onChange: (value?: string) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const AppointmentLocationSelect = (
  props: AppointmentLocationSelectProps
) => {
  const { t } = useTranslation();

  const data = appointmentLocationEnum.options.map((o) => ({
    value: o,
    label: t(o),
  }));

  return (
    <BSelect
      {...props}
      data={data}
      label={t("APPOINTMENT_LOCATION")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
