import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Member, MemberRole } from "beitary-shared";

interface MembersState {
  members: Member[];
  memberRoles: MemberRole[];
}

const key = "members";

const initialState: MembersState = {
  members: [],
  memberRoles: [],
};

export const membersSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setMembers: (state, action: PayloadAction<Member[]>) => {
      state.members = action.payload;
    },
    setMemberRoles: (state, action: PayloadAction<MemberRole[]>) => {
      state.memberRoles = action.payload;
    },
  },
});

export const { setMembers, setMemberRoles } = membersSlice.actions;

export const selectMembers = (state: RootState) => state.members.members;
export const selectMemberRoles = (state: RootState) =>
  state.members.memberRoles;

export const selectActiveMembers = (state: RootState) =>
  state.members.members.filter((o) => o.status === "ACTIVE");

// TODO fix members
export const selectDoctors = (state: RootState) =>
  state.members.members?.filter((member) => member.licenceType === "DOCTOR");

export const selectActiveDoctors = (state: RootState) =>
  state.members.members
    ?.filter((member) => member.licenceType === "DOCTOR")
    .filter((member) => member.status !== "ARCHIVED");

export const membersReducer = membersSlice.reducer;
