import {
  Box,
  Button,
  FileButton,
  Group,
  Image,
  List,
  Stack,
  Text,
} from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { img_utils } from "beitary-shared";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface BImageToBase64InputProps {
  src?: string;
  formFieldValue?: string;
  formFieldSet?: (str: string) => void;
}

export const BImageToBase64Input = ({
  src,
  formFieldSet,
  formFieldValue,
}: BImageToBase64InputProps) => {
  const { t } = useTranslation();
  // logo img

  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logoError, setLogoError] = useState<string | undefined>(undefined);

  // TODO: fix gfetting compressing called many times idk why
  useEffect(() => {
    const updateCompressedLogo = async () => {
      // console.log("compressing");
      try {
        if (logoFile) {
          if (logoFile.size < 0.5 * 1024 ** 2) {
            img_utils.logoCompress(
              logoFile,
              async (file) => {
                if (file) {
                  const str = await img_utils.fileToBase64(file);
                  setLogoError(undefined);
                  if (formFieldValue !== str && formFieldSet) formFieldSet(str); // TODO maybe find better way to avaid rerenders
                }
              },
              // t("AN_ERROR_HAPPENED_WHILE_UPLOADING_YOUR_IMAGE")
              (_) => {
                throw Error("AN_ERROR_HAPPENED_WHILE_UPLOADING_YOUR_IMAGE");
              }
            );
          } else {
            // t("FILE_SIZE_BIGGER_THAN_LIMIT")
            throw Error("FILE_SIZE_BIGGER_THAN_LIMIT");
          }
        }
      } catch (error: any) {
        // t("AN_ERROR_HAPPENED_WHILE_UPLOADING_YOUR_IMAGE")
        setLogoError(error.message);
      }
    };
    updateCompressedLogo();
  }, [logoFile, formFieldSet, formFieldValue]);
  return (
    <Box>
      <Stack align="center" p="xl">
        <Image src={src} width={128} height={128} radius={64} withPlaceholder />
        <Stack sx={{ width: 300 }} align="center" justify="flex-end">
          <List size="xs" withPadding>
            <List.Item>{t("FILE_SIZE_SHOULD_BE_LESS_THAN_512KB")}</List.Item>
            <List.Item>{t("ACCEPTED_FILE_FORMATS")}</List.Item>
          </List>
          <Text size="sm" align="center" mt="sm">
            {logoError ? (
              <Group align="center" position="center" spacing="xs">
                <IconAlertTriangle size={25} stroke={1.5} />{" "}
                <Text>{t(logoError)}</Text>
              </Group>
            ) : logoFile ? (
              logoFile.name
            ) : (
              t(" ")
            )}
          </Text>

          <FileButton onChange={setLogoFile} accept="image/png,image/jpeg">
            {(props) => <Button {...props}>{t("UPLOAD_IMAGE")}</Button>}
          </FileButton>
        </Stack>
      </Stack>
    </Box>
  );
};
