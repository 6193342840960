import { zodResolver } from "@mantine/form";
import { invoiceLineItemDataSchema } from "beitary-shared";
import { z } from "zod";

const addInvoiceItemsFormItemSchema = invoiceLineItemDataSchema.merge(
  z.object({
    id: z.string(),
    dose: z.number().optional(),
    isFromFixedPriceBundle: z.boolean(),
  })
);
const addInvoiceItemsFormSchema = z.object({
  items: z.array(addInvoiceItemsFormItemSchema),
});

export type AddInvoiceItemsFormItem = z.infer<
  typeof addInvoiceItemsFormItemSchema
>;

export type AddInvoiceItemsFormValues = z.infer<
  typeof addInvoiceItemsFormSchema
>;

export const rules = zodResolver(addInvoiceItemsFormSchema);
