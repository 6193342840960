import { zodResolver } from "@mantine/form";
import { paymentDataSchema, paymentInvoiceDataSchema } from "beitary-shared";
import { z } from "zod";

const valueSchema = paymentInvoiceDataSchema.merge(
  z.object({
    include: z.boolean(),
    showInput: z.boolean(),
  })
);

const paymentFormSchema = paymentDataSchema.omit({ invoices: true }).merge(
  z.object({
    invoices: z.record(valueSchema),
  })
);

export type PaymentFormValues = z.infer<typeof paymentFormSchema>;
export type PaymentFormValue = z.infer<typeof valueSchema>;

export const rules = zodResolver(paymentFormSchema);
