import {
  accountCustomClaims,
  AccountCustomClaims,
  errorResult,
  Result,
  successResult,
} from "beitary-shared";
import {
  Auth,
  sendEmailVerification,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

export interface AuthUser {
  uid: string;
  displayName: string | null;
  email: string | null;
  claims: AccountCustomClaims;
}
export interface SignIn {
  auth: Auth;
  email: string;
  password: string;
}

const signIn = async ({
  auth,
  email,
  password,
}: SignIn): Promise<Result<string | null>> => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const displayName = userCredential.user.displayName;
    // console.log("Firebase: signed in");
    return successResult({
      message: "Signed in!",
      payload: displayName,
    });
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Firebase error: " + errorCode + " : " + errorMessage);
    return errorResult({ message: errorMessage });
  }
};

export interface SignInWithToken {
  auth: Auth;
  token: string;
}

const signInWithToken = async ({
  auth,
  token,
}: SignInWithToken): Promise<
  Result<(AccountCustomClaims & { name: string }) | null>
> => {
  try {
    const userCredential = await signInWithCustomToken(auth, token);
    const user = userCredential.user;
    const idTokenResult = await user.getIdTokenResult();
    let claims: AccountCustomClaims | undefined = undefined;
    const claimsParseResult = accountCustomClaims.safeParse(
      idTokenResult.claims
    );
    if (claimsParseResult.success) {
      claims = claimsParseResult.data;
    } else {
      throw new Error(claimsParseResult.error.toString());
    }
    // console.log("Firebase: signed in");
    return successResult({
      message: "Signed in!",
      payload: { ...claims, name: user.displayName ?? "" },
    });
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Firebase error: " + errorCode + " : " + errorMessage);
    return errorResult({ message: errorMessage });
  }
};

interface LogOut {
  auth: Auth;
}

const logOut = async ({ auth }: LogOut): Promise<Result<boolean | null>> => {
  try {
    await signOut(auth);
    // console.log("Firebase: signed out");
    return successResult({ message: "Signed out!", payload: true });
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Firebase error: " + errorCode + " : " + errorMessage);
    return errorResult({ message: errorMessage });
  }
};
interface SendEmailVerifLink {
  auth: Auth;
}

const sendEmailVerifLink = async ({
  auth,
}: SendEmailVerifLink): Promise<Result<boolean | null>> => {
  try {
    const user = auth.currentUser;
    // t("USER_NOT_SIGNED_IN")
    if (!user) return errorResult({ message: "USER_NOT_SIGNED_IN" });
    await sendEmailVerification(user);
    // t("EMAIL_VERIF_LINK_SENT")
    return successResult({ message: "EMAIL_VERIF_LINK_SENT", payload: true });
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log("Firebase error: " + errorCode + " : " + errorMessage);
    return errorResult({ message: errorMessage });
  }
};

interface AuthListener {
  auth: Auth;
  setAuthState: (user: AuthUser | null) => void;
}

const authListener = ({ auth, setAuthState }: AuthListener) =>
  auth.onAuthStateChanged(async (user) => {
    try {
      if (user) {
        const { uid, displayName, email } = user;

        const idTokenResult = await user.getIdTokenResult();
        let claims: AccountCustomClaims | undefined = undefined;
        const claimsParseResult = accountCustomClaims.safeParse(
          idTokenResult.claims
        );
        if (claimsParseResult.success) {
          claims = claimsParseResult.data;
        } else {
          throw new Error(claimsParseResult.error.toString());
        }

        // // console.log("AuthState: logged in email:");
        // console.log(email);
        // console.log("AuthState: logged in organizationsRules:");
        // console.log(organizationsRules);

        setAuthState({
          uid,
          displayName,
          email,
          claims,
        });
      } else {
        console.log("AuthState: logged out:");
        setAuthState(null);
      }
    } catch (error) {
      console.error(error);
      setAuthState(null);
    }
  });

// we inject dependencies to improve testability
export const authentication = (auth: Auth) => {
  return {
    signIn: (email: string, password: string) =>
      signIn({ auth, email, password }),
    signOut: () => logOut({ auth }),
    sendEmailVerifLink: () => sendEmailVerifLink({ auth }),
    authListener: (setAuthState: (user: AuthUser | null) => void) =>
      authListener({ auth, setAuthState }),
    signInWithToken: (token: string) => signInWithToken({ auth, token }),
  };
};
