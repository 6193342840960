import { Button, Group, Text } from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconExternalLink } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  ConsultationData,
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
  Patient,
  tu,
} from "beitary-shared";
import { BBox } from "components";
import { selectCurrentConsultations } from "features/consultations/Consultations.slice";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface ActionsProps {
  patient: Patient;
}

export const Actions = ({ patient }: ActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useSubmitState();

  const consultation = useAppSelector(selectCurrentConsultations).find(
    (c) => c.patientId === patient.id
  );

  const org = useAppSelector(selectOrganization);

  const { addConsultation } =
    useDBServices().consultationsDBService.consultations;

  const submit = async () => {
    if (!org) throw new Error(t("MUST_HAVE_ORG"));

    const notificationId = "submit-consultation";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(consultation ? "UPDATE_APPOINTMENT" : "ADD_APPOINTMENT"),
      })
    );
    setSubmitState("pending-response");

    const newData: ConsultationData = {
      checkedInTime: tu.getCurrentDateTime(),
      clientId: patient.ownerId,
      clientName: patient.ownerName,
      organizationId: org.id,
      organizationName: org.name,
      patientId: patient.id,
      patientName: patient.name,
      patientSex: patient.sex,
      patientSpecies: patient.species,
      status: "ACTIVE",
      patientPictureURL: patient.thumbURL,
    };

    obju.removeUndefined(newData);

    const result = await addConsultation(newData);
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const viewConsultation = (id: string) =>
    navigate(`/consultations/${id}/summary`);

  return (
    <BBox
      header={
        <Group position="apart">
          <Group>
            <Text weight={500}>{t("ACTIONS")}</Text>
          </Group>
        </Group>
      }
    >
      <Group p="xl">
        {consultation ? (
          <Button
            leftIcon={<IconExternalLink size={16} />}
            onClick={() => viewConsultation(consultation.id)}
          >
            {t("VIEW_CONSULTATION")}
          </Button>
        ) : (
          <Button loading={submitState === "pending-response"} onClick={submit}>
            {" "}
            {t("CHECK_IN")}
          </Button>
        )}
        {/* <Button leftIcon={<IconFileDiff size={16} />} onClick={() => {}}>
          {t("ADD_ESTIMATE")}
        </Button> */}
      </Group>
    </BBox>
  );
};
