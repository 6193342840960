import {
  ActionIcon,
  Button,
  Group,
  Menu,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  FieldInputType,
  PhysicalExamEntry,
  PhysicalExamEntryData,
  tu,
} from "beitary-shared";
import { BBox } from "components";
import { selectActivePhysicalExamTemplates } from "features/admin/templates/Templates.slice";
import { selectActiveConsultationPhysicalExams } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useIsMobile } from "hooks/useIsMobile";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface PhysicalExamsProps {
  disabled: boolean;
  consultationId: string;
  defaultTemplateId?: string;
}
export const PhysicalExams = ({
  disabled,
  consultationId,
  defaultTemplateId,
}: PhysicalExamsProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useSubmitState();
  const [deleteState, setDeleteState] = useSubmitState();
  const isMobie = useIsMobile();

  const physicalExamsDBService =
    useDBServices().consultationsDBService.physicalExams(consultationId);

  const templates = useAppSelector(selectActivePhysicalExamTemplates);

  const data: PhysicalExamEntry[] = useAppSelector(
    selectActiveConsultationPhysicalExams
  ).filter((v) => v.consultationId === consultationId);

  const [selectedPhysicalExamId, setSelectedPhysicalExamId] = useState<
    string | undefined
  >(data[0]?.id);

  const [selectedTemplateId, setSelectedTemplateId] = useState<
    string | undefined
  >(defaultTemplateId ?? templates[0]?.id);

  const physicalExamEntry = data.find((d) => d.id === selectedPhysicalExamId);

  const physicalExamsSelectData = data.map((o) => ({
    value: o.id,
    label: tu.getDateAndTimeString(o.createdAt),
  }));

  const addNew = async (templateId?: string) => {
    if (disabled) return;

    if (!templateId) return;
    const template = templates.find((t) => t.id === templateId);

    if (!template) return;

    const newPhysicalExam: PhysicalExamEntryData = {
      status: "ACTIVE",
      consultationId,
      fields: template.fields,
    };

    setSubmitState("pending-response");

    const result = await physicalExamsDBService.addPhysicalExamEntry(
      newPhysicalExam
    );

    if (result.type === "success") {
      setSubmitState("success");
      navigate(`physical-exams/${result.payload?.id}/new`);
    } else {
      setSubmitState("error");
    }
  };

  const deletePhysicalExam = async (id: string) => {
    if (disabled) return;

    setDeleteState("pending-response");

    const result = await physicalExamsDBService.deletePhysicalExamEntry(id);

    if (result.type === "success") {
      setDeleteState("success");
      // setSelectedVitalId(newVitals[0]?.id);
    } else {
      setDeleteState("error");
    }
  };

  if (!selectedTemplateId) {
    return (
      <BBox
        header={
          <Group position="apart">
            <Text weight={500}>{t("PHYSICAL_EXAMS")}</Text>
          </Group>
        }
      >
        <Text m="xl">{t("CREATE_TEMPLATE_FIRST")}</Text>
      </BBox>
    );
  }

  return (
    <BBox
      header={
        <Group position="apart">
          <Text weight={500}>{t("PHYSICAL_EXAMS")}</Text>

          <Group noWrap spacing={0}>
            <Menu disabled={disabled} withinPortal position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  disabled={disabled}
                  variant="filled"
                  color={theme.primaryColor}
                  size={isMobie ? 30 : 36}
                  sx={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <IconChevronDown size={16} stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown p="xl">
                <Select
                  disabled={disabled}
                  withinPortal
                  label={t("SELECT_TEMPLATE")}
                  data={templates.map((t) => ({ value: t.id, label: t.name }))}
                  value={selectedTemplateId}
                  onChange={(v) => {
                    v && setSelectedTemplateId(v);
                  }}
                  clearable={false}
                  allowDeselect={false}
                  searchable
                />
              </Menu.Dropdown>
            </Menu>
            <Button
              disabled={disabled || selectedTemplateId === undefined}
              loading={submitState === "pending-response"}
              onClick={() => addNew(selectedTemplateId)}
              sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              {t("ADD_NEW")}
            </Button>
          </Group>
        </Group>
      }
    >
      {physicalExamEntry && (
        <>
          <Group
            p="xs"
            sx={{
              backgroundColor: "white",
              borderBottom: `1px solid ${theme.colors.gray[3]}`,
            }}
            position="apart"
          >
            <Select
              withinPortal
              data={physicalExamsSelectData}
              value={selectedPhysicalExamId}
              onChange={(v) => {
                v && setSelectedPhysicalExamId(v);
              }}
              clearable={false}
              allowDeselect={false}
            />
            <Group>
              <Button
                disabled={disabled}
                onClick={() =>
                  navigate(`physical-exams/${physicalExamEntry.id}/edit`)
                }
              >
                {t("EDIT")}
              </Button>
              <Button
                disabled={disabled}
                variant="outline"
                color="red"
                loading={deleteState === "pending-response"}
                onClick={() => deletePhysicalExam(physicalExamEntry.id)}
              >
                {t("DELETE")}
              </Button>
            </Group>
          </Group>

          <Stack p="xl">
            <div key={physicalExamEntry.supervisingDoctorId}>
              <Text color="cyan">{t("SUPERVISING_DOCTOR")}</Text>
              <Text>{physicalExamEntry.supervisingDoctorName}</Text>
            </div>
            <SimpleGrid cols={3}>
              {physicalExamEntry.fields.map((e) => (
                <div key={e.id}>
                  <Text color="cyan">{e.name}</Text>
                  {e.inputs.map((i, index) => (
                    <Text
                      key={i.id}
                      weight={
                        500 - index * 100 > 100 && i.inputType !== "TEXT_EDITOR"
                          ? 500 - index * 100
                          : 400
                      }
                    >
                      {getInputValue({
                        options: i.options,
                        type: i.inputType,
                        defaultTextValue: i.defaultTextValue,
                        defaultOptionValue: i.defaultOptionId,
                      })}
                    </Text>
                  ))}
                </div>
              ))}
            </SimpleGrid>
          </Stack>
        </>
      )}
    </BBox>
  );
};

const getInputValue = ({
  type,
  options,
  defaultOptionValue,
  defaultTextValue,
}: {
  type: FieldInputType;
  options: {
    id: string;
    value: string;
  }[];
  defaultOptionValue?: string | string[];
  defaultTextValue?: string;
}) => {
  switch (type) {
    case "TEXT_AREA":
    case "TEXT_INPUT":
      return defaultTextValue;
    case "TEXT_EDITOR":
      return HTMLReactParser(defaultTextValue ?? "");
    case "VALUE_MULTISELECT":
    case "VALUE_CHECKBOX":
      if (!defaultOptionValue) return;
      const selectedOptionsIds = defaultOptionValue as unknown as string[];
      const values: string[] = [];
      selectedOptionsIds.forEach((id) => {
        const input = options.find((o) => o.id === id);
        if (input) values.push(input.value);
      });
      return values;
    case "VALUE_SELECT":
    case "VALUE_RADIO":
      if (!defaultOptionValue) return;
      const selectedOptionsId = defaultOptionValue as unknown as string;
      let value: string | undefined = undefined;
      const input = options.find((o) => o.id === selectedOptionsId);
      if (input) value = input.value;
      return value;

    default:
      return;
  }
};
