import { zodResolver } from "@mantine/form";
import { boardingResourceDataSchema } from "beitary-shared";
import { z } from "zod";

const boardingResourceFormSchema = boardingResourceDataSchema;

export type BoardingResourceFormValues = z.infer<
  typeof boardingResourceFormSchema
>;

export const rules = zodResolver(boardingResourceFormSchema);
