import {
  ActionIcon,
  Box,
  Button,
  Dialog,
  Divider,
  Group,
  ScrollArea,
  Stack,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import {
  FieldInputType,
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  normalizePhysicalExamTemplate,
  PhysicalExamTemplate,
  Result,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldInputTypeSelect } from "../FieldInputTypeSelect";
import { Preview } from "../Preview";
import {
  physicalExamTemplateFormSchema,
  PhysicalExamTemplateFormValues,
  rules,
} from "./PhysicalExamTemplateForm.rules";

export interface PhysicalExamTemplateFormProps {
  physicalExamTemplate?: PhysicalExamTemplate;
}

export const PhysicalExamTemplateForm = ({
  physicalExamTemplate,
}: PhysicalExamTemplateFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editPhysicalExamTemplate, addPhysicalExamTemplate } =
    useDBServices().templatesDBService.physicalExamTemplates;

  const form = useForm<PhysicalExamTemplateFormValues>({
    initialValues: physicalExamTemplate
      ? { ...normalizePhysicalExamTemplate(physicalExamTemplate) }
      : {
          fields: [],
          name: "",
          status: "ACTIVE",
        },
    validate: rules,
  });

  const [selectedFiledId, setSelectedFiledId] = useState<string | undefined>();

  console.log("values");
  console.log(form.values);

  console.log("---------------");

  const addNewField = () => {
    try {
      const newTemplate = JSON.parse(JSON.stringify(form.values));
      // was getting Cannot add property 0, object is not extensible at Array.push
      // seems it's cauz form values are frozen, so can't push

      const id = id_util.newId20();
      newTemplate.fields.push({
        name: " ",
        id: id,
        inputs: [
          {
            id: id,
            inputType: "TEXT_INPUT",
            defaultTextValue: " ",
            options: [
              {
                id: id,
                value: " ", // using spaces cauz empty string for some reason
                // resulted in displaying the id on select
              },
            ],
            defaultOptionId: id,
          },
        ],
      });

      // setTemplate\(newTemplate\);
      form.setFieldValue("fields", newTemplate.fields);
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const updateFieldName = ({ name, id }: { name: string; id: string }) => {
    try {
      const newTemplate = { ...form.values };

      const field = newTemplate.fields.find((f) => f.id === id);
      if (field) {
        field.name = name;

        // setTemplate\(newTemplate\);
        form.setFieldValue("fields", newTemplate.fields);
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const removeField = ({ id }: { id: string }) => {
    try {
      const newTemplate = { ...form.values };

      newTemplate.fields = newTemplate.fields.filter((f) => f.id !== id);

      // setTemplate\(newTemplate\);
      form.setFieldValue("fields", newTemplate.fields);
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const addFieldInput = ({ id }: { id: string }) => {
    try {
      const newTemplate = { ...form.values };

      const field = newTemplate.fields.find((f) => f.id === id);
      if (field) {
        const id = id_util.newId20();
        field.inputs.push({
          id: id,
          inputType: "TEXT_INPUT",
          options: [
            {
              id: id,
              value: " ",
            },
          ],
          defaultOptionId: id,
          defaultTextValue: " ",
        });
        // setTemplate\(newTemplate\);
        form.setFieldValue("fields", newTemplate.fields);
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const updateFieldInputType = ({
    fieldId,
    inputId,
    type,
  }: {
    fieldId: string;
    inputId: string;
    type: string;
  }) => {
    try {
      const newTemplate = { ...form.values };

      const field = newTemplate.fields.find((f) => f.id === fieldId);

      if (field) {
        const input = field.inputs.find((i) => i.id === inputId);

        if (input) {
          input.inputType = type as FieldInputType;

          if (
            (input.inputType === "VALUE_SELECT" ||
              input.inputType === "VALUE_RADIO") &&
            input.options &&
            input.options.length > 0
          ) {
            input.defaultOptionId = input.options[0]?.id;
          }

          if (
            (input.inputType === "VALUE_MULTISELECT" ||
              input.inputType === "VALUE_CHECKBOX") &&
            input.options &&
            input.options.length > 0
          ) {
            input.defaultOptionId = [input.options[0]?.id];
          }

          // setTemplate\(newTemplate\);
          form.setFieldValue("fields", newTemplate.fields);
        }
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const removeFieldInput = ({
    fieldId,
    inputId,
  }: {
    fieldId: string;
    inputId: string;
  }) => {
    try {
      const newTemplate = { ...form.values };

      const field = newTemplate.fields.find((f) => f.id === fieldId);

      if (field) {
        field.inputs = field.inputs.filter((i) => i.id !== inputId);
        // setTemplate\(newTemplate\);
        form.setFieldValue("fields", newTemplate.fields);
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const addFieldInputOption = ({
    fieldId,
    inputId,
  }: {
    fieldId: string;
    inputId: string;
  }) => {
    try {
      const newTemplate = { ...form.values };

      const field = newTemplate.fields.find((f) => f.id === fieldId);

      if (field) {
        const input = field.inputs.find((i) => i.id === inputId);

        if (input) {
          input.options.push({
            id: id_util.newId20(),
            value: "",
          });
          // setTemplate\(newTemplate\);
          form.setFieldValue("fields", newTemplate.fields);
        }
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const removeFieldInputOption = ({
    fieldId,
    inputId,
    optionId,
  }: {
    fieldId: string;
    inputId: string;
    optionId: string;
  }) => {
    try {
      const newTemplate = { ...form.values };

      const field = newTemplate.fields.find((f) => f.id === fieldId);

      if (field) {
        const input = field.inputs.find((i) => i.id === inputId);

        if (input) {
          input.options = input.options.filter((o) => o.id !== optionId);
          if (input.defaultOptionId === optionId)
            input.defaultOptionId = input.options[0]?.id;
          // setTemplate\(newTemplate\);
          form.setFieldValue("fields", newTemplate.fields);
        }
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const updateFieldInputOptionValue = ({
    fieldId,
    inputId,
    optionId,
    value,
  }: {
    fieldId: string;
    inputId: string;
    optionId: string;
    value: string;
  }) => {
    try {
      const newTemplate = { ...form.values };

      const field = newTemplate.fields.find((f) => f.id === fieldId);

      if (field) {
        const input = field.inputs.find((i) => i.id === inputId);

        if (input) {
          const option = input.options.find((o) => o.id === optionId);
          if (option) {
            option.value = value;
            // setTemplate\(newTemplate\);
            form.setFieldValue("fields", newTemplate.fields);
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const reorderField = ({
    source,
    destination,
  }: {
    source: number;
    destination: number;
  }) => {
    try {
      const newTemplate = { ...form.values };

      const fields = newTemplate.fields;

      if (
        destination < 0 ||
        destination > newTemplate.fields.length - 1 ||
        source < 0 ||
        source > newTemplate.fields.length - 1
      )
        return;

      const [removed] = fields.splice(source, 1);
      fields.splice(destination, 0, removed);
      // setTemplate\(newTemplate\);
      form.setFieldValue("fields", newTemplate.fields);
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  const updateFieldInputDefaultValue = ({
    fieldId,
    inputId,
    value,
  }: {
    fieldId: string;
    inputId: string;
    value?: string | string[];
  }) => {
    console.log(`${fieldId} - ${inputId} - ${value}`);

    try {
      const newTemplate = { ...form.values };

      // console.log("newTemplate");
      // console.log(newTemplate);

      const field = newTemplate.fields.find((f) => f.id === fieldId);

      if (field) {
        const input = field.inputs.find((i) => i.id === inputId);

        if (input) {
          if (input.inputType.startsWith("TEXT")) {
            input.defaultTextValue = value as string;
          } else {
            input.defaultOptionId = value;
          }

          // console.log("newTemplate");
          // console.log(ttemplate);
          // setTemplate\(newTemplate\);
          form.setFieldValue("fields", newTemplate.fields);
        }
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: PhysicalExamTemplateFormValues) => {
    try {
      const newTemplate = physicalExamTemplateFormSchema.parse(values);
      const notificationId = "submit-inventory-item-physicalExamTemplate";
      showNotification(
        loadingInfoNotification({
          id: notificationId,
          message: t("Waiting for server response"),
          title: t(
            physicalExamTemplate
              ? "UPDATE_PHYSICAL_EXAM_TEMPLATE"
              : "ADD_PHYSICAL_EXAM_TEMPLATE"
          ),
        })
      );
      setSubmitState("pending-response");
      let result: Result<PhysicalExamTemplate | null>;
      if (physicalExamTemplate) {
        result = await editPhysicalExamTemplate(
          physicalExamTemplate.id,
          newTemplate
        );
      } else {
        result = await addPhysicalExamTemplate(newTemplate);
      }
      if (result.type === "success") {
        setSubmitState("success");
      } else {
        setSubmitState("error");
      }
      updateNotification({
        ...getNotificationByResultType(result.type)({
          message: t(result.message),
        }),
        id: notificationId,
      });
    } catch (error) {
      console.log(error);
      // TODO add way to show this
    }
  };

  const getSelectedFieldControlsUI = () => {
    const f = form.values.fields?.find((i) => i.id === selectedFiledId);

    if (!f) return <></>;

    return (
      <Stack p="xl" key={f.id}>
        <TextInput
          required
          label={t("FIELD_NAME")}
          value={f.name}
          onChange={(e) =>
            updateFieldName({
              id: f.id,
              name: e.currentTarget.value,
            })
          }
        />
        <Divider m="xl" />
        <Stack>
          {f.inputs.map((i, _index) => (
            <Stack mb="lg" key={i.id}>
              {_index !== 0 && <Divider m="xl" />}
              <FieldInputTypeSelect
                required
                value={i.inputType}
                onChange={(v) => {
                  console.log(v);
                  if (v)
                    updateFieldInputType({
                      fieldId: f.id,
                      inputId: i.id,
                      type: v,
                    });
                }}
              />
              {f.inputs.length > 1 && (
                <Group position="right">
                  <Button
                    variant="outline"
                    color="red"
                    onClick={() =>
                      removeFieldInput({
                        fieldId: f.id,
                        inputId: i.id,
                      })
                    }
                  >
                    {t("REMOVE_INPUT")}
                  </Button>
                </Group>
              )}
              {i.inputType.startsWith("VALUE") && (
                <Stack>
                  {i.options.map((o, index) => (
                    <Group align="flex-end" key={o.id}>
                      <TextInput
                        w={"78%"}
                        label={t("OPTION") + " " + (index + 1)}
                        required
                        value={o.value}
                        onChange={(e) =>
                          updateFieldInputOptionValue({
                            fieldId: f.id,
                            inputId: i.id,
                            optionId: o.id,
                            value: e.currentTarget.value,
                          })
                        }
                      />
                      {index !== 0 && (
                        <ActionIcon
                          color="red"
                          radius="xl"
                          variant="filled"
                          size="xs"
                          mb="xs"
                          ml="sm"
                          disabled={
                            i.defaultOptionId === o.id || // instanceof gave parser error
                            (typeof i.defaultOptionId === "object" &&
                              i.defaultOptionId?.includes(o.id))
                          }
                          onClick={() =>
                            removeFieldInputOption({
                              fieldId: f.id,
                              inputId: i.id,
                              optionId: o.id,
                            })
                          }
                        >
                          <IconMinus />
                        </ActionIcon>
                      )}
                    </Group>
                  ))}
                  <ActionIcon
                    color="cyan"
                    radius="xl"
                    variant="filled"
                    size="xs"
                    onClick={() =>
                      addFieldInputOption({
                        fieldId: f.id,
                        inputId: i.id,
                      })
                    }
                  >
                    <IconPlus />
                  </ActionIcon>
                </Stack>
              )}
            </Stack>
          ))}
        </Stack>
        <Box>
          <Button onClick={() => addFieldInput({ id: f.id })}>
            {t("ADD_INPUT")}
          </Button>
        </Box>
      </Stack>
    );
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox>
          <Stack p="xl">
            <TextInput
              required
              placeholder={t("ENTER_NAME")}
              label={t("NAME")}
              {...form.getInputProps("name")}
            />
            <Preview
              template={{ ...form.values }}
              removeField={removeField}
              reorderField={reorderField}
              setDefaultValue={updateFieldInputDefaultValue}
              selectField={({ id }) => setSelectedFiledId(id)}
            />
            <Group position="right">
              <Button onClick={addNewField}>{t("ADD_FIELD")}</Button>
            </Group>
            {selectedFiledId && (
              <Dialog
                opened={!!selectedFiledId}
                h={"95vh"}
                withCloseButton
                onClose={() => setSelectedFiledId(undefined)}
              >
                <Box mt="xl">
                  <ScrollArea.Autosize
                    mah={"85vh"}
                    sx={{ maxWidth: 400 }}
                    mx="auto"
                  >
                    {getSelectedFieldControlsUI()}
                  </ScrollArea.Autosize>
                </Box>
              </Dialog>
            )}
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
