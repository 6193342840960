import {
  Checkbox,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
} from "beitary-shared";
import { BBox, MoveBackButton, SaveButton } from "components";
import { useCServices } from "hooks/useCService/useCService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MemberRolesMultiSelect } from "../MemberRolesMultiSelect";
import { AddMemberFormValues, rules } from "./AddMemberForm.rules";

export const AddMemberForm = () => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const cs = useCServices().membersCService;

  const [pin, setPin] = useState<string | undefined>();

  const form = useForm<AddMemberFormValues>({
    validate: rules,
  });

  const submit = async (values: AddMemberFormValues) => {
    const notificationId = "submit-new-member";

    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("ADDING_NEW_MEMBER"),
      })
    );

    setSubmitState("pending-response");
    obju.removeUndefined(values); // cauz callable with undefined become null and cauz noramlization error
    const result = await cs.addMember(values);

    if (result.type === "success" && result.payload?.pin) {
      setSubmitState("success");
      setPin(result.payload.pin);
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(
        result.type === "error" ? "error" : "success"
      )({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <Stack spacing="xl">
      <Group mt="xl">
        <MoveBackButton />
      </Group>

      {pin === undefined && (
        <BBox header={<Text weight={500}>{t("NEW_MEMBER")}</Text>}>
          <form
            id="add-member-form"
            onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}
          >
            <Stack p="xl">
              <MemberRolesMultiSelect
                required
                {...form.getInputProps("rolesIds")}
              />
              <Checkbox
                label={t("CLINIC_LOGIN")}
                checked={form.values.isClinicLogin === true}
                onChange={(e) => {
                  form.setFieldValue("isClinicLogin", e.currentTarget.checked);
                  if (e.currentTarget.checked)
                    form.setFieldValue("remoteAccess", true);
                }}
              />
              <TextInput
                required
                label={t("NAME")}
                {...form.getInputProps("name")}
              />
              <TextInput
                required
                maxLength={3}
                label={t("INITIALS")}
                {...form.getInputProps("initials")}
              />{" "}
              <Checkbox
                required={form.values.isClinicLogin === true}
                disabled={form.values.isClinicLogin === true}
                label={t("REMOTE_ACCESS")}
                checked={form.values.remoteAccess === true}
                onChange={(e) =>
                  form.setFieldValue("remoteAccess", e.currentTarget.checked)
                }
              />
              {form.values.remoteAccess && (
                <>
                  <TextInput
                    type="email"
                    required={form.values.remoteAccess === true}
                    label={t("EMAIL")}
                    {...form.getInputProps("email")}
                  />
                  <TextInput
                    type="email"
                    required={form.values.remoteAccess === true}
                    label={t("CONFIRM_EMAIL")}
                    {...form.getInputProps("confirmEmail")}
                  />
                  <PasswordInput
                    required={form.values.remoteAccess === true}
                    label={t("PASSWORD")}
                    {...form.getInputProps("password")}
                  />
                  <PasswordInput
                    required={form.values.remoteAccess === true}
                    label={t("CONFIRM_PASSWORD")}
                    {...form.getInputProps("confirmPassword")}
                  />
                </>
              )}
              <Checkbox
                label={t("IS_A_DOCTOR")}
                checked={form.values.licenceType === "DOCTOR"}
                onChange={(e) =>
                  form.setFieldValue(
                    "licenceType",
                    e.currentTarget.checked ? "DOCTOR" : "EMPLOYEE"
                  )
                }
              />
              <Checkbox
                required
                label={t("USER_AGREED_TO_BEITARY_TOS_AND_PP")}
                checked={form.values.hasAgreedToTOSAndPP === true}
                onChange={(e) =>
                  form.setFieldValue(
                    "hasAgreedToTOSAndPP",
                    e.currentTarget.checked
                  )
                }
              />
            </Stack>
          </form>
        </BBox>
      )}
      {pin !== undefined && (
        <Paper p="xl">
          <Group>
            <Text weight="bold">{t("PIN_CODE")}:</Text>
            <Text>{pin}</Text>
          </Group>
        </Paper>
      )}
      {pin === undefined && (
        <Group position="right">
          <SaveButton
            state={submitState}
            canSave={form.isValid()}
            formId="add-member-form"
          />
        </Group>
      )}
    </Stack>
  );
};
