import { z } from "zod";

export enum SubsType {
  custom = "0",
  tier1 = "a",
  tier2 = "b",
  tier3 = "c",
  tier4 = "d",
  tier5 = "e",
  smsExtra1 = "f",
  smsExtra2 = "g",
  smsExtra3 = "h",
  smsExtra4 = "i",
  smsExtra5 = "j",
  emailExtra1 = "k",
  emailExtra2 = "l",
  emailExtra3 = "m",
  emailExtra4 = "n",
  emailExtra5 = "o",
  storageExtra1 = "p",
  storageExtra2 = "q",
  storageExtra3 = "r",
  storageExtra4 = "s",
  storageExtra5 = "t",
}

export const subscriptionTypeEnum = z.nativeEnum(SubsType);
export type SubscriptionType = z.infer<typeof subscriptionTypeEnum>;
