import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectActiveConsultationDischargeInstructions } from "features/consultations/Consultations.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DischargeInstructionForm } from "../DischargeInstructionForm";

export interface EditDischargeInstructionProps {
  consultationId: string;
  disabled: boolean;
}

export const EditDischargeInstruction = ({
  consultationId,
  disabled,
}: EditDischargeInstructionProps) => {
  const { t } = useTranslation();
  const { instructionId } = useParams();

  const dischargeInstruction = useAppSelector(
    selectActiveConsultationDischargeInstructions
  )
    .filter((dischargeInstruction) => dischargeInstruction.id === instructionId)
    .at(0);

  if (!dischargeInstruction) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return (
    <DischargeInstructionForm
      disabled={disabled}
      dischargeInstruction={dischargeInstruction}
      consultationId={consultationId}
    />
  );
};
