import { Box, Table, Text } from "@mantine/core";
import { InvoicePayment, tu } from "beitary-shared";
import { BBox } from "components";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";

export interface PaymentsTableProps {
  payments: InvoicePayment[];
}

export const PaymentsTable = ({ payments }: PaymentsTableProps) => {
  const { t } = useTranslation();

  const getPaymentRow = (p: InvoicePayment) => (
    <tr key={p.id}>
      <td>
        <Text>{tu.getDateString(p.createdAt)}</Text>
      </td>
      <td>
        <Text>{p.id.slice(0, 8).toUpperCase()}</Text>
      </td>
      <td>
        <Text>{t(p.method)}</Text>
      </td>
      <td>
        <Text>{mu.toD(p.invoiceTotal)}</Text>
      </td>
      <td>
        <Text>{mu.toD(p.paymentAmount)}</Text>
      </td>
      <td>
        <Text>{mu.toD(p.creditAmount)}</Text>
      </td>
      <td>
        <Text>{p.note}</Text>
      </td>
    </tr>
  );

  const rows = payments.map((p) => getPaymentRow(p));

  return (
    <BBox header={<Text>{t("PAYMENTS")}</Text>}>
      <Box p="xl">
        {rows.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>{t("DATE")}</th>
                <th>{t("PAYMENT_ID")}</th>
                <th>{t("METHOD")}</th>
                <th>{t("INVOICE_TOTAL")}</th>
                <th>{t("PAID")}</th>
                <th>{t("CREDIT")}</th>
                <th>{t("NOTE")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        ) : (
          <Text>{t("NO_PAYMENTS_MADE")}</Text>
        )}
      </Box>
    </BBox>
  );
};
