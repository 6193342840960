import { patientSpeciesEnum } from "beitary-shared";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { BSelect } from "components/BSelect";

export interface PatientSpeciesSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const PatientSpeciesSelect = (props: PatientSpeciesSelectProps) => {
  const { t } = useTranslation();

  const data = patientSpeciesEnum.options.map((o) => ({
    value: o,
    label: t(o),
  }));

  return (
    <BSelect
      {...props}
      data={data}
      nothingFound={t("NOTHING_FOUND")}
      label={t("SPECIES")}
    />
  );
};
