import { UnableToNormalizeData } from "beitary-shared";
import { z } from "zod";

export const communicationHistorySchema = z.object({
  id: z.string(),
  to: z.array(z.string()),
  delivery: z
    .object({
      state: z.string().optional(),
      error: z.string().optional().nullable(),
      startTime: z
        .object({
          seconds: z.number(),
          nanoseconds: z.number(),
        })
        .optional(),
    })
    .optional(),
});

export type CommunicationHistory = z.infer<typeof communicationHistorySchema>;

export const normalizeCommunicationHistory = (
  data: any
): CommunicationHistory => {
  try {
    return communicationHistorySchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};
