import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  AppointmentType,
  BoardingResource,
  ServiceProvider,
} from "beitary-shared";
interface ScheduleSettingsState {
  serviceProviders: ServiceProvider[];
  appointmentTypes: AppointmentType[];
  boardingResources: BoardingResource[];
}

const initialState: ScheduleSettingsState = {
  serviceProviders: [],
  appointmentTypes: [],
  boardingResources: [],
};

export const scheduleSettingsSlice = createSlice({
  name: "scheduleSettings",
  initialState,
  reducers: {
    setServiceProviders: (state, action: PayloadAction<ServiceProvider[]>) => {
      state.serviceProviders = action.payload;
    },
    setAppointmentTypes: (state, action: PayloadAction<AppointmentType[]>) => {
      state.appointmentTypes = action.payload;
    },
    setBoardingResources: (
      state,
      action: PayloadAction<BoardingResource[]>
    ) => {
      state.boardingResources = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
  },
});

export const {
  setServiceProviders,
  setAppointmentTypes,
  setBoardingResources,
} = scheduleSettingsSlice.actions;

export const selectServiceProviders = (state: RootState) =>
  state.scheduleSettings.serviceProviders;

export const selectActiveServiceProviders = (state: RootState) =>
  state.scheduleSettings.serviceProviders.filter((o) => o.status === "ACTIVE");

export const selectAppointmentTypes = (state: RootState) =>
  state.scheduleSettings.appointmentTypes;

export const selectActiveAppointmentTypes = (state: RootState) =>
  state.scheduleSettings.appointmentTypes.filter((o) => o.status === "ACTIVE");

export const selectBoardingResources = (state: RootState) =>
  state.scheduleSettings.boardingResources;

export const selectActiveBoardingResources = (state: RootState) =>
  state.scheduleSettings.boardingResources.filter((o) => o.status === "ACTIVE");

export const scheduleSettingsReducer = scheduleSettingsSlice.reducer;
