import { zodResolver } from "@mantine/form";
import { smsAppointmentNotificationTemplateDataSchema } from "beitary-shared";
import { z } from "zod";

const smsAppointmentNotificationTemplateFormSchema =
  smsAppointmentNotificationTemplateDataSchema;

export type SMSAppointmentNotificationTemplateFormValues = z.infer<
  typeof smsAppointmentNotificationTemplateFormSchema
>;

export const rules = zodResolver(smsAppointmentNotificationTemplateFormSchema);
