import { showNotification, updateNotification } from "@mantine/notifications";
import {
  BFileCategory,
  BFileData,
  BStorageFileCustomMetadataData,
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
} from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSServices } from "hooks/useSService/useSService";
import { useSubmitState } from "hooks/useSubmitState";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { BucketType } from "services/storage/storage-service";

interface UseUploadFile {
  comment?: string;
  clientId?: string;
  shareWithClient?: boolean;
  includeInMedicalRecord?: boolean;
  patientId?: string;
  consultationId?: string;
  filesCategory: BFileCategory;
}

export const useUploadFile = ({
  clientId,
  comment,
  shareWithClient,
  includeInMedicalRecord,
  filesCategory,
  consultationId,
  patientId,
}: UseUploadFile) => {
  const [submitFileState, setSubmitFileState] = useSubmitState();
  const [fileProgress, setFileProgress] = useState<{
    status: "error" | "success" | "ready" | "in-progress";
    progressRate: number;
  }>();

  const { t } = useTranslation();

  const storage = useSServices();
  const db = useDBServices().filesDBService;

  const fileReady = useCallback(() => {
    setFileProgress({
      progressRate: 0,
      status: "ready",
    });
  }, []);

  const clearFile = useCallback(() => {
    setFileProgress(undefined);
  }, []);

  //console.log(fileProgress);

  const submitFile = useCallback(
    async (file: File) => {
      if (!file) return false;
      //console.log("submitFile");
      // this is to trigger the right cloud function/extension
      const bucket: BucketType = file.type.startsWith("image")
        ? "images"
        : file.type.startsWith("video")
        ? "videos"
        : "default";

      const notificationId = `upload-file`;
      showNotification(
        loadingInfoNotification({
          id: notificationId,
          message: t("WAITING_FOR_SERVER_RESPONSE"),
          title: t("UPLOADING_FILE"),
        })
      );

      try {
        const customMetadata: Omit<
          Omit<BStorageFileCustomMetadataData, "readOrgIds">,
          "organizationId"
        > = {
          originalName: file.name,
          clientId,
          shareWithClient: shareWithClient ? "true" : "false",
          shareReadWithSelectedOrgs: includeInMedicalRecord ? "true" : "false",
        };

        // init BFile doc
        let fileId: string | undefined = undefined;

        setSubmitFileState("pending-response");
        const bfile: Omit<Omit<BFileData, "readOrgIds">, "organizationId"> = {
          ...customMetadata,
          fileSize: file.size,
          patientId,
          consultationId,
          fileCategory: filesCategory,
          contentType: file.type,
          status: "UPLOADING",
          comment,
        };

        obju.removeUndefined(bfile);

        setFileProgress({
          status: "ready",
          progressRate: 0,
        });
        //console.log("awaiting bfile create");
        const result = await db.addBFile(bfile);
        //console.log(`bfile created with ${result.type}`);
        if (result.type === "success" && result.payload) {
          fileId = result.payload;
        } else {
          setSubmitFileState("error");
          updateNotification({
            ...getNotificationByResultType("error")({
              message: t("ERROR"),
            }),
            id: notificationId,
          });
          return false;
        }

        // upload to storage
        if (fileId !== undefined) {
          const newid = fileId; // idk why but ts could believe that fileId is a strong at
          // onSuccess -> updateBFile
          //console.log("awaiting storage");
          await storage.uploadFile({
            bucket,
            file,
            fileId,
            fileCategory: filesCategory,
            customMetadata,
            onError: async (_error) => {
              // console.log("storage error");
              // console.log(_error);
              await db.deleteBFile(newid);

              setFileProgress({ progressRate: 0, status: "error" });
              setSubmitFileState("error");
              updateNotification({
                ...getNotificationByResultType("error")({
                  message: t("ERROR"),
                }),
                id: notificationId,
              });
              return false;
            },
            onSuccess: async (osProps) => {
              //console.log("storage success");
              const updates: Partial<BFileData> = {
                ...osProps,
                status: "UPLOADED",
              };
              const result = await db.updateBFile(updates, newid);

              //console.log(result);
              setFileProgress({
                ...fileProgress,
                status: "success",
                progressRate: 100,
              });
              if (result.type === "success") {
                setSubmitFileState("success");
                setFileProgress(undefined);
                //setComment(undefined);
                //setIncludeInMedicalRecord(true);
              } else {
                setSubmitFileState("error");
              }

              updateNotification({
                ...getNotificationByResultType(result.type)({
                  message: t("FILE_UPLOADED"),
                }),
                id: notificationId,
              });
            },
            onProgress: (progressRate) => {
              //console.log(`storage in progress at ${progressRate}`);
              setFileProgress({
                ...fileProgress,
                progressRate,
                status: "in-progress",
              });
            },
          });
        }
      } catch (error) {
        console.log(error);
        setFileProgress(undefined);
        return false;
      }

      //setFile(undefined);
      return true;
    },
    [
      clientId,
      comment,
      consultationId,
      db,
      fileProgress,
      filesCategory,
      includeInMedicalRecord,
      patientId,
      setSubmitFileState,
      shareWithClient,
      storage,
      t,
    ]
  );

  return [
    {
      fileProgress,
      submitFileState,
      submitFile,
      fileReady,
      clearFile,
    },
  ] as const; // idk why but adding as const allows for correct state suggestion with vs code
};
