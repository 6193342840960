import { z } from "zod";

// t("PENDING")
// t("PROCESSING")
// t("RETRY")
// t("SUCCESS")
// t("ERROR")
export const inventoryTransactionStateEnum = z.enum([
  "PENDING",
  "PROCESSING",
  "RETRY",
  "SUCCESS",
  "ERROR",
]);
export type InventoryTransactionState = z.infer<
  typeof inventoryTransactionStateEnum
>;
