import {
  ActionIcon,
  Group,
  Menu,
  NumberInput,
  Radio,
  Stack,
} from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import { InvoiceLineItem, OrgPermissions } from "beitary-shared";
import { AsyncActionButton } from "components";
import { BMoneyInput } from "components/BMoneyInput";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";

export interface ItemActionsProps {
  invoiceId: string;
  item: InvoiceLineItem;
  disabled?: boolean;
}

export const ItemActions = ({
  invoiceId,
  item,
  disabled,
}: ItemActionsProps) => {
  const { t } = useTranslation();
  const { updatItemDiscount } = useDBServices().invoicesDBService.invoices;

  const [value, setValue] = useState(0);
  const [percentageOrAmount, setPercentageOrAmount] = useState<"%" | "AMOUNT">(
    "AMOUNT"
  );
  const [opened, setOpened] = useState(false);

  const calcs = mu.calculateItemValueAndTaxes({
    unitValue: item.pUnitSellingPrice,
    qty: item.qty,
    taxRate: item.taxRate,
    totalDiscount: 0,
  });

  console.log(calcs);
  console.log(value);
  const discountAmount =
    percentageOrAmount === "AMOUNT"
      ? mu.multiply({ amount: value, multiplier: 1 / (1 + item.taxRate) })
      : mu.getPercentage({
          amount: calcs.totalValueNoTaxes,
          percentage2p: value * 100,
        });

  // console.log(discountAmount);

  const isSaveDisabled =
    disabled ||
    // !(discountAmount >= 0 && discountAmount <= calcs.totalValueWithTaxes);
    !(discountAmount >= 0);

  return (
    <BCan
      I="do"
      a={OrgPermissions.ITEM_PRICE_UPDATE_DISCOUNTS_APPLY_TO_INVOICE}
    >
      <Menu
        withinPortal
        position="bottom-end"
        opened={opened}
        onChange={setOpened}
      >
        <Menu.Target>
          <ActionIcon>
            <IconDotsVertical size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown p="xl">
          <Stack w={200}>
            <Radio.Group
              value={percentageOrAmount}
              onChange={(v) => {
                setValue(0);
                setPercentageOrAmount(v === "%" ? "%" : "AMOUNT");
              }}
              label={t("DISCOUNT_TYPE")}
              required
            >
              <Stack m="sm">
                <Radio value="AMOUNT" label={t("AMOUNT_WITH_TAXES")} />
                <Radio value="%" label="%" />
              </Stack>
            </Radio.Group>

            {percentageOrAmount === "AMOUNT" && (
              <BMoneyInput
                min={0}
                // max={calcs.totalValueWithTaxes}
                label={t("DISCOUNT")}
                value={value}
                onChange={(v) => {
                  setValue(v === "" ? 0 : v <= 0 ? 0 : v);
                }}
              />
            )}

            {percentageOrAmount === "%" && (
              <NumberInput
                label={t("DISCOUNT")}
                value={value}
                precision={2}
                max={100}
                min={0}
                icon={"%"}
                onChange={(v) => {
                  setValue(v === "" ? 0 : v);
                }}
              />
            )}
            <Group position="right">
              <AsyncActionButton
                disabled={isSaveDisabled}
                f={async () =>
                  isSaveDisabled
                    ? () => {}
                    : updatItemDiscount({
                        id: item.id,
                        discount: discountAmount,
                        invoiceId,
                      })
                }
                child={t("SAVE")}
                color="green"
              />
            </Group>
          </Stack>
        </Menu.Dropdown>
      </Menu>
    </BCan>
  );
};
