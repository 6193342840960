import { ActionIcon, Badge, Group, Text, Tooltip } from "@mantine/core";
import { IconAlertTriangle, IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { InventoryItem } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import {
  selectInventoryItemLocations,
  selectInventoryItems,
  selectInventoryItemSuppliers,
} from "features/admin/inventory/Inventory.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type ItemsTableItem = InventoryItem & {
  supplier?: string;
  locations: (string | undefined)[];
  actions?: undefined;
};

export const ItemsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const suppliers = useAppSelector(selectInventoryItemSuppliers);
  const locations = useAppSelector(selectInventoryItemLocations);

  const items = useAppSelector(selectInventoryItems);

  const tableItems: ItemsTableItem[] = items.map((item) => ({
    ...item,
    supplier: suppliers.find((o) => o.id === item.supplierId)?.name,
    locations: (
      item.locationsIds?.map((s) => locations.find((l) => l.id === s)?.name) ??
      []
    ).filter((l) => l !== undefined),
  }));

  const { deleteInventoryItem, archiveInventoryItem, dearchiveInventoryItem } =
    useDBServices().inventoryDBService.inventoryItems;

  const columns: BDataTableColumn<ItemsTableItem>[] = [
    { accessor: "name", title: <Text>{t("NAME")}</Text>, sortable: true },
    { accessor: "code", title: <Text>{t("CODE")}</Text>, sortable: true },
    {
      accessor: "supplier",
      title: <Text>{t("SUPPLIER")}</Text>,
      sortable: true,
    },
    {
      accessor: "locations",
      title: <Text>{t("LOCATIONS")}</Text>,
      render: ({ locations }) =>
        locations.map((l) => <Badge key={l}>{l}</Badge>),
    },
    {
      accessor: "currentQuantity",
      title: <Text>{t("QUANTITY")}</Text>,
      sortable: true,
      width: 160,
      render: ({
        currentQuantity,
        unitType,
        maxQuantity,
        minQuantity,
        reorderPoint,
        unitOfMeasurement,
      }) => (
        <Group spacing={4}>
          {`${currentQuantity.toFixed(2)} (${t(
            unitType ?? unitOfMeasurement ?? "UNIT"
          )})`}
          {maxQuantity && currentQuantity > maxQuantity && (
            <Tooltip label={t("ABOVE_MAX_QUANTITY")}>
              <IconAlertTriangle size={16} color="orange" stroke={2} />
            </Tooltip>
          )}
          {minQuantity && currentQuantity < minQuantity && (
            <Tooltip label={t("UNDER_MIN_QUANTITY")}>
              <IconAlertTriangle size={16} color="red" stroke={1.5} />
            </Tooltip>
          )}
          {reorderPoint && currentQuantity < reorderPoint && (
            <Tooltip label={t("UNDER_REORDER_POINT")}>
              <IconAlertTriangle size={16} color="blue" stroke={1.5} />
            </Tooltip>
          )}
        </Group>
      ),
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (item) => (
        <Group position="right">
          <ActionIcon onClick={() => navigate(`edit/${item.id}`)}>
            <IconEdit size={18} />
          </ActionIcon>
          {item.status === "ARCHIVED" ? (
            <DearchiveConfirmationModal
              f={async () => dearchiveInventoryItem(item.id, item)}
              item={item}
            />
          ) : (
            <ArchiveConfirmationModal
              f={async () => archiveInventoryItem(item.id, item)}
              item={item}
            />
          )}
          <DeleteConfirmationModal
            f={async () => deleteInventoryItem(item.id)}
            item={item}
          />
        </Group>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
