import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectInventoryItemSuppliers } from "features/admin/inventory/Inventory.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SupplierForm } from "../SupplierForm";

export const EditSupplier = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const supplier = useAppSelector(selectInventoryItemSuppliers)
    .filter((supplier) => supplier.id === id)
    .at(0);

  if (!supplier) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <SupplierForm supplier={supplier} />;
};
