import { Anchor, Avatar, Group, Text, useMantineTheme } from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Client, Patient } from "beitary-shared";
import { BDataTable } from "components";
import {
  ClientsMap,
  PatientsMap,
  selectClientsMap,
  selectPatientsMap,
} from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

export type ClientsAndPatientsTableData = Client & {
  pets: Patient[];
  petNames: string;
};

type ClientsTableItem = ClientsAndPatientsTableData & {};

export const ClientsTable = () => {
  const { t } = useTranslation();

  const clientsMap = useAppSelector(selectClientsMap);
  const patientsMap = useAppSelector(selectPatientsMap);

  const tableItems = getTableDataFromClientsAndPatients(
    clientsMap,
    patientsMap
  );

  const theme = useMantineTheme();

  const columns: BDataTableColumn<ClientsTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("CLIENT/PATIENT")}</Text>,
      sortable: true,
      render: (item) => (
        <Group>
          <Avatar src={item.thumbURL} size={50} radius="xl" />
          <Anchor
            color="dark"
            lineClamp={1}
            component={Link}
            to={`${item.id}`}
            // target="_blank"
          >
            {item.name} {<IconExternalLink size={12} />}
          </Anchor>
        </Group>
      ),
    },
    {
      accessor: "email",
      title: <Text>{t("EMAIL")}</Text>,
      sortable: true,
    },
    {
      accessor: "phoneNumber",
      title: <Text>{t("PHONE_NUMBER")}</Text>,
      sortable: true,
    },
    {
      accessor: "petNames",
      hidden: true,
    },
  ];

  return (
    <BDataTable
      data={tableItems}
      columns={columns}
      rowExpansion={{
        trigger: "always",
        content: ({ record }) => (
          <Group p="xs" spacing="xl">
            {record.pets.map((p) => (
              <Group
                key={p.id}
                p="sm"
                sx={{
                  backgroundColor: theme.colors.cyan[0],
                  borderRadius: 50,
                }}
              >
                <Avatar src={p.thumbURL} size={50} radius="xl">
                  <IconPaw size={30} />
                </Avatar>
                <Anchor
                  color="dark"
                  lineClamp={1}
                  component={Link}
                  to={`${p.ownerId}/patients/${p.id}`}
                  // target="_blank"
                >
                  {p.name} {<IconExternalLink size={12} />}
                </Anchor>
              </Group>
            ))}
          </Group>
        ),
      }}
    />
  );
};

const getTableDataFromClientsAndPatients = (
  clientsMap: ClientsMap,
  patientsMap: PatientsMap
) => {
  const resultsMap: { [id: string]: ClientsAndPatientsTableData } = {};

  Object.values(clientsMap).forEach((c) => {
    resultsMap[c.id] = { ...c, petNames: "", pets: [] };
  });

  Object.values(patientsMap).forEach((p) => {
    const client: Client | undefined = clientsMap[p.ownerId];
    if (client) {
      const resultsClient: ClientsAndPatientsTableData | undefined =
        resultsMap[client.id];
      let newRC: ClientsAndPatientsTableData;
      let pets: Patient[] = [];
      let petNames: string;
      if (resultsClient) {
        pets.push(...resultsClient.pets, p);
        petNames = [resultsClient.petNames, p.name].join("_");
      } else {
        pets.push(p);
        petNames = p.name;
      }
      newRC = {
        ...client,
        pets,
        petNames,
      };
      resultsMap[newRC.id] = newRC;
    }
  });

  return Object.values(resultsMap);
};
