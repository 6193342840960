import {
  Box,
  Button,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { IconGripVertical } from "@tabler/icons-react";
import { FieldInputType, PhysicalExamTemplateData } from "beitary-shared";
import {
  BAdvancedRadioGroup,
  BBox,
  BCheckboxGroup,
  BMultiSelect,
  BSelect,
  BTextEditor,
} from "components";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

export interface PreviewProps {
  template: PhysicalExamTemplateData;
  removeField: ({ id }: { id: string }) => void;
  selectField: ({ id }: { id: string }) => void;
  reorderField: ({
    source,
    destination,
  }: {
    source: number;
    destination: number;
  }) => void;
  setDefaultValue: ({
    fieldId,
    inputId,
    value,
  }: {
    fieldId: string;
    inputId: string;
    value?: string | string[];
  }) => void;
}

export const Preview = ({
  template,
  removeField,
  selectField,
  reorderField,
  setDefaultValue,
}: PreviewProps) => {
  const { t } = useTranslation();
  const data = template.fields;

  const items = data.map((item, index) => (
    <Draggable key={item.id} index={index} draggableId={item.id}>
      {(provided, _snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <BBox
            header={
              <Group position="apart">
                <Group {...provided.dragHandleProps}>
                  <IconGripVertical size={18} stroke={1.5} />
                  <Text weight={500}>{item.name}</Text>
                </Group>
                <Group>
                  <Button
                    variant="light" // light=show more actions?
                    onClick={() =>
                      selectField({
                        id: item.id,
                      })
                    }
                  >
                    {t("SHOW_CONTROLS")}
                  </Button>

                  <Button
                    variant="outline"
                    color="red"
                    onClick={() =>
                      removeField({
                        id: item.id,
                      })
                    }
                  >
                    {t("REMOVE")}
                  </Button>
                </Group>
              </Group>
            }
          >
            <Stack p="xl">
              {item.inputs.map((i) => (
                <Box key={i.id}>
                  {getInput({
                    type: i.inputType,
                    options: i.options.map((o) => ({
                      value: o.id,
                      label: o.value,
                    })),
                    defaultOptionValue: i.defaultOptionId,
                    defaultTextValue: i.defaultTextValue,
                    setDefaultValue: (v) =>
                      setDefaultValue({
                        fieldId: item.id,
                        inputId: i.id,
                        value: v,
                      }),
                  })}
                </Box>
              ))}
            </Stack>
          </BBox>
        </div>
      )}
    </Draggable>
  ));

  return (
    <DragDropContext
      onDragEnd={({ destination, source }, _provider) =>
        reorderField({
          source: source.index,
          destination: destination?.index || 0,
        })
      }
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Stack>{items}</Stack>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const getInput = ({
  type,
  options,
  defaultOptionValue,
  defaultTextValue,
  setDefaultValue,
}: {
  type: FieldInputType;
  options: {
    value: string;
    label: string;
  }[];
  defaultOptionValue?: string | string[];
  defaultTextValue?: string;
  setDefaultValue: (value?: string | string[]) => void;
}) => {
  switch (type) {
    case "TEXT_AREA":
      return (
        <Textarea
          defaultValue={defaultTextValue}
          onChange={(e) => setDefaultValue(e.target.value)}
        />
      );
    case "TEXT_EDITOR":
      return (
        <BTextEditor
          value={defaultTextValue}
          onChange={(v) => setDefaultValue(v)}
        />
      );
    case "TEXT_INPUT":
      return (
        <TextInput
          defaultValue={defaultTextValue}
          onChange={(e) => setDefaultValue(e.target.value)}
        />
      );
    case "VALUE_SELECT":
      return (
        <BSelect
          required
          data={options}
          value={
            defaultOptionValue ? (defaultOptionValue as string) : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );
    case "VALUE_MULTISELECT":
      return (
        <BMultiSelect
          required
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string[])
              : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );
    case "VALUE_CHECKBOX":
      return (
        <BCheckboxGroup
          required
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string[])
              : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );
    case "VALUE_RADIO":
      return (
        <BAdvancedRadioGroup
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string)
              : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );

    default:
      return <></>;
  }
};
