import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const smsReminderNotificationTemplateDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  remindersIds: z.array(z.string()),
  notificationContent: z.string(),
});

type SMSReminderNotificationTemplateDataV0 = z.infer<
  typeof smsReminderNotificationTemplateDataV0Schema
>;

const smsReminderNotificationTemplateV0Schema = baseRecordSchema.merge(
  smsReminderNotificationTemplateDataV0Schema
);

type SMSReminderNotificationTemplateV0 = z.infer<
  typeof smsReminderNotificationTemplateV0Schema
>;

const normalizeSMSReminderNotificationTemplateV0 = (
  data: any
): SMSReminderNotificationTemplate => {
  try {
    const smsReminderNotificationTemplateV0: SMSReminderNotificationTemplateV0 =
      smsReminderNotificationTemplateV0Schema.parse(data);

    const { ...rest } = smsReminderNotificationTemplateV0;

    // here it's same object cause current v is 0
    const smsReminderNotificationTemplate: SMSReminderNotificationTemplate = {
      ...rest,
    };
    return smsReminderNotificationTemplate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const SMS_REMINDER_NOTIFICATION_TEMPLATE_VERSION = "0";

export type SMSReminderNotificationTemplateData =
  SMSReminderNotificationTemplateDataV0;

export type SMSReminderNotificationTemplate = BaseRecord &
  SMSReminderNotificationTemplateData;

export const smsReminderNotificationTemplateDataSchema =
  smsReminderNotificationTemplateDataV0Schema;

export const smsReminderNotificationTemplateSchema =
  smsReminderNotificationTemplateV0Schema;

export const normalizeSMSReminderNotificationTemplate = (
  data: any
): SMSReminderNotificationTemplate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeSMSReminderNotificationTemplateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
