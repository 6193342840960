import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectReminders } from "features/admin/client-communication/ClientCommunication.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ReminderForm } from "../ReminderForm";

export const EditReminder = () => {
  const { t } = useTranslation();
  const { reminderId } = useParams();

  const reminder = useAppSelector(selectReminders)
    .filter((reminder) => reminder.id === reminderId)
    .at(0);

  if (!reminder) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <ReminderForm reminder={reminder} />;
};
