import { z } from "zod";

export const featureKeys = [
  "aa",
  "ab",
  "ac",
  "ad",
  "ae",
  "af",
  "ag",
  "ah",
  "ai",
  "aj",
  "ak",
  "al",
] as const;

export const featureEnum = z.enum(featureKeys);

export type FeatureEnum = z.infer<typeof featureEnum>;
