import {
  ActionIcon,
  Divider,
  Group,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconRefresh } from "@tabler/icons-react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  normalizeOrganization,
  Result,
} from "beitary-shared";
import { BBox, CancelButton, SaveButton } from "components";
import { BThumbnailInput } from "components/BThumbnailInput";
import {
  selectOrganization,
  setOrganization,
} from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadThumbnail } from "hooks/useThumbnailUpload";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TagsMultiselect } from "../TagsMultiselect";
import { GeneralSettingsFormValues, rules } from "./GeneralSettingsForm.rules";

export const GeneralSettingsForm = () => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { editOrganization, getCurrentOrganization } =
    useDBServices().organizationDBService;

  const [file, setFile] = useState<File | null>(null);
  const [{ submitThumbnail, thumbnailReady, clearThumbnail }] =
    useUploadThumbnail();

  const org = useAppSelector(selectOrganization);

  const form = useForm<GeneralSettingsFormValues>({
    initialValues: org ? normalizeOrganization(org) : undefined,
    validate: rules,
  });

  if (!org) return <Text>{t("ORGANIZATION_NOT_FOUND")}</Text>;

  const updateOrgSlice = async () => {
    const organization = await getCurrentOrganization();
    dispatch(setOrganization(organization.payload));
  };

  const submit = async (values: GeneralSettingsFormValues) => {
    const notificationId = "submit-clinicgeneralSettings";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_CLINIC_SETTINGS"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<string | null>;
    result = await editOrganization(org.id, values);

    if (result.type === "success") {
      if (result.payload && file) {
        await submitThumbnail(file, result.payload);
      }
      await updateOrgSlice();
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <Stack>
      <BBox
        header={
          <Group position="right">
            <ActionIcon onClick={updateOrgSlice}>
              <IconRefresh />
            </ActionIcon>
          </Group>
        }
      >
        <form
          id="generalSettings-form"
          onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}
        >
          <Stack p="xl" spacing="xl">
            <Group grow align="flex-end" spacing="xl">
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("NAME")}
                  {...form.getInputProps("name")}
                />

                <TextInput
                  required
                  label={t("TAX_ID")}
                  {...form.getInputProps("taxID")}
                />

                <TextInput
                  label={t("CONTACT_EMAIL")}
                  {...form.getInputProps("email")}
                />
                <TextInput
                  required
                  label={t("PHONE_NUMBER")}
                  {...form.getInputProps("phoneNumber")}
                />
              </Stack>
              <BThumbnailInput
                currentImgSrc={org?.thumbURL}
                file={file}
                setFile={(v) => {
                  console.log(v);

                  if (!v) {
                    clearThumbnail();
                  } else {
                    thumbnailReady();
                  }
                  setFile(v);
                }}
              />
            </Group>
            <TagsMultiselect {...form.getInputProps("tags")} />
            <Textarea
              placeholder={t("DESCRIPTION_PLACEHOLDER")}
              label={t("DESCRIPTION")}
              {...form.getInputProps("description")}
            />
            <Divider />
            <Group grow align="flex-start" spacing="xl">
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("ADDRESS_LINE_1")}
                  {...form.getInputProps("address_Line1")}
                />
                <TextInput
                  label={t("ADDRESS_STATE_OR_PROVINCE")}
                  {...form.getInputProps("address_stateOrProvince")}
                />
              </Stack>
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("ADDRESS_CITY")}
                  {...form.getInputProps("address_city")}
                />
                <TextInput
                  label={t("ADDRESS_LINE_2")}
                  {...form.getInputProps("address_Line2")}
                />
              </Stack>
              <Stack sx={{ minWidth: 300 }}>
                <TextInput
                  required
                  label={t("ADDRESS_POSTAL_CODE")}
                  {...form.getInputProps("address_postalCode")}
                />
                {/* <CountryCodeSelect
                  required
                  {...form.getInputProps("address_countryCode")}
                /> */}
              </Stack>
            </Group>
            {/* <Group grow align="flex-start" spacing="xl">
              <LanguageSelect required {...form.getInputProps("language")} />
              <CurrencySelect required {...form.getInputProps("currency")} />
              <TemperatureUnitSelect
                required
                {...form.getInputProps("temperatureUnit")}
              />
              <TimezoneSelect required {...form.getInputProps("timeZone")} />
            </Group> */}
          </Stack>
        </form>
      </BBox>
      <Group position="right">
        <CancelButton />
        <SaveButton
          state={submitState}
          canSave={form.isValid()}
          formId="generalSettings-form"
        />
      </Group>
    </Stack>
  );
};
