import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { catalogReducer } from "features/admin/catalog/Catalog.slice";
import { clientCommunicationReducer } from "features/admin/client-communication/ClientCommunication.slice";
import { clinicSettingsReducer } from "features/admin/clinic-settings/ClinicSettings.slice";
import { diagnosesReducer } from "features/admin/diagnoses/Diagnoses.slice";
import { inventoryReducer } from "features/admin/inventory/Inventory.slice";
import { membersReducer } from "features/admin/members/Members.slice";
import { scheduleSettingsReducer } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { templatesReducer } from "features/admin/templates/Templates.slice";
import { authenticationReducer } from "features/Authentication/AuthenticationSlice";
import { bSettingsReducer } from "features/BSettingsListener/BSettings.slice";
import { clientsReducer } from "features/Clients/Clients.slice";
import { consultationsReducer } from "features/consultations/Consultations.slice";
import { diagnosticsReducer } from "features/diagnostics/Diagnostics.slice";
import { invoicesReducer } from "features/invoices/Invoices.slice";
import { organizationReducer } from "features/Organization/Organization.slice";
import { appointmentsReducer } from "features/schedule/appointments/Appointments.slice";
import { tasksReducer } from "features/tasks/tasks.slice";
import { whiteboardReducer } from "features/whiteboard/Whiteboard.slice";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    members: membersReducer,
    clients: clientsReducer,
    organization: organizationReducer,
    appointments: appointmentsReducer,
    inventory: inventoryReducer,
    catalog: catalogReducer,
    clinicSettings: clinicSettingsReducer,
    scheduleSettings: scheduleSettingsReducer,
    templates: templatesReducer,
    diagnoses: diagnosesReducer,
    consultations: consultationsReducer,
    invoices: invoicesReducer,
    tasks: tasksReducer,
    whiteboard: whiteboardReducer,
    clientCommunication: clientCommunicationReducer,
    bSettings: bSettingsReducer,
    diagnostics: diagnosticsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
