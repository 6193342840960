import { z } from "zod";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

export const smsSchema = z.object({
  organizationId: z.string(),
  to: z.array(z.string()),
  // toUids: z.array(z.string()).optional(), removed this cauz want to create sms only if phonenumber known
  template: z
    .object({
      name: z.string(),
      data: z.any(),
    })
    .optional(),
  message: z.string().optional(),

  // for quotas
  quotaProcessingAt: z.number().int().positive().optional(),
  quotaProcessedAt: z.number().int().positive().optional(),
  quotaCount: z.number().int().positive().optional(), // basically Math.floor(text.length/160)+1
});

export type SMS = z.infer<typeof smsSchema>;

export const normalizeSMS = (data: any): SMS => {
  try {
    const sms: SMS = smsSchema.parse(data);
    return sms;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
