import { z } from "zod";

export const integrationTypeEnum = z.enum([
  "IMAGING_INTEGRATION",
  "LAB_INTEGRATION",
]);

export type IntegrationType = z.infer<typeof integrationTypeEnum>;

// t("INTEGRATION_BTR_DL")
export const imagingIntegrationEnum = z.enum(["INTEGRATION_BTR_DL"]);

export type ImagingIntegration = z.infer<typeof imagingIntegrationEnum>;

// t("INTEGRATION_BTR_DL")
export const labIntegrationEnum = z.enum(["INTEGRATION_BTR_DL"]);

export type LabIntegration = z.infer<typeof labIntegrationEnum>;
