import { zodResolver } from "@mantine/form";
import { organizationDataSchema } from "beitary-shared";
import { z } from "zod";

const settingsFormSchema = organizationDataSchema.pick({
  advancedSettings: true,
});

export type SettingsFormValues = z.infer<typeof settingsFormSchema>;

export const rules = zodResolver(settingsFormSchema);
