import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  Table,
  Text,
} from "@mantine/core";
import { IconEye, IconRefresh } from "@tabler/icons-react";
import {
  id_util,
  InventoryTransactionData,
  PurchaseOrder,
  tu,
} from "beitary-shared";
import { BBox, DeleteConfirmationModal } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";
import { AddPurchaseOrderModal } from "../AddPurchaseOrderModal";

type PurchaseOrdersTableItem = PurchaseOrder & { actions?: undefined };

export interface PurchaseOrdersTableProps {
  vendorId?: string;
  vendorName?: string;
}

// started chatgpt opt
export const PurchaseOrdersTable = ({
  vendorId,
  vendorName,
}: PurchaseOrdersTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [startAfterId, setStartAfterTime] = useState<string | undefined>(
    undefined
  );
  const [startBeforeId, setStartBeforeTime] = useState<string | undefined>(
    undefined
  );

  const db = useDBServices().inventoryDBService;

  const [purchaseOrders, setPurchaseOrders] = useState<
    undefined | null | PurchaseOrdersTableItem[]
  >(undefined);

  console.log(purchaseOrders);

  // const [showComplete, setShowComplete] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [refresh, setRefresh] = useState(id_util.newId36());

  const currency = org_params_util.getOrgParams().currency;

  const handleNextPage = () => {
    fetchPurchaseOrders({ startAfterId, action: "next" });
  };

  const handlePreviousPage = () => {
    fetchPurchaseOrders({
      startBeforeId,
      action: "previous",
    });
  };

  interface fetchPurchaseOrdersProps {
    startAfterId?: string;
    startBeforeId?: string;
    startAtId?: string;
    action: "refresh" | "next" | "previous"; // | "week-change" | "toggle-complete";
  }
  const fetchPurchaseOrders = useCallback(
    async ({
      startAfterId,
      startBeforeId,
      startAtId,
      action,
    }: fetchPurchaseOrdersProps) => {
      setRefreshing(true);
      const props = {
        startAfterId,
        startBeforeId,
        startAtId,
        vendorId,
      };
      const result = await db.purchaseOrders.getPurchaseOrders(props);

      setRefreshing(false);
      // ... handle pagination ...
      if (result.payload) {
        if (result.payload.length > 0) {
          setPurchaseOrders(result.payload);
          setStartAfterTime(result.payload[result.payload.length - 1]?.id);
          setStartBeforeTime(result.payload[0]?.id);
        } else {
          if (action === "next") {
            setStartAfterTime(undefined);
          } else if (action === "previous") {
            setStartBeforeTime(undefined);
          } else {
            setPurchaseOrders(result.payload);
            setStartAfterTime(undefined);
            setStartBeforeTime(undefined);
          }
        }
      } else {
        // // No more data to fetch
        if (startAfterId) setStartAfterTime(undefined);
        if (startBeforeId) setStartBeforeTime(undefined);
      }
    },
    [db.purchaseOrders, vendorId]
  );

  useEffect(() => {
    fetchPurchaseOrders({ action: "refresh" });
  }, [fetchPurchaseOrders, refresh]);

  const getTotal = (po: PurchaseOrder): number => {
    const itemsTotal = Object.values(po?.items ?? {}).reduce((acc, item) => {
      return (
        acc +
        mu.multiply({
          amount: item.costPerUnitOfMeasurement,
          multiplier: item.qtyOrdered,
        })
      );
    }, 0);
    return itemsTotal + (po?.additionalCost ?? 0);
  };

  const getRow = useCallback(
    (snapshot: PurchaseOrder) => {
      const { id, status, vendorName, date } = snapshot;
      return (
        <tr key={id}>
          <td>
            <Text>{tu.getDateString(date)}</Text>
          </td>
          <td>
            <Text>{id.slice(0, 4).toUpperCase()}</Text>
          </td>

          <td>
            <Text>{vendorName}</Text>
          </td>
          <td>
            <Text>{t(status)}</Text>
          </td>
          <td>
            <Text>
              {mu.toD(getTotal(snapshot))} {t(currency)}
            </Text>
          </td>
          <td>
            <Group position="right">
              <ActionIcon onClick={() => navigate(`${id}`)}>
                <IconEye size={18} />
              </ActionIcon>
              <DeleteConfirmationModal
                f={async () => {
                  const props: fetchPurchaseOrdersProps = {
                    action: "refresh",
                  };
                  setRefreshing(true);
                  const result = await db.purchaseOrders.deletePurchaseOrder(
                    id
                  );

                  for (const item of Object.values(snapshot.items)) {
                    if (item.status === "RECEIVED") {
                      // create transaction
                      const trans: InventoryTransactionData = {
                        costPerUnit: 0,
                        qty: -item.qtyReceived,
                        date: tu.getCurrentDateTime(),
                        invItemId: item.inventoryItemId,
                        invItemName: item.inventoryItemName,
                        sourceType: "PURCHASE_ORDER",
                        state: "PENDING",
                        taxRate: item.taxRate,
                        type: "PURCHASE_CANCELED",
                        sourceId: snapshot.id,
                      };

                      await db.inventoryTransactions.addInventoryTransaction(
                        trans
                      );
                    }
                  }

                  fetchPurchaseOrders(props);
                  setRefreshing(false);
                  return result;
                }}
                item={{ id, name: `${t("PURCHASE_ORDER")} ${id.slice(0, 4)}` }}
              />
            </Group>
          </td>
        </tr>
      );
    },
    [
      currency,
      db.inventoryTransactions,
      db.purchaseOrders,
      fetchPurchaseOrders,
      navigate,
      t,
    ]
  );

  const rows = useMemo(
    () => (purchaseOrders ?? []).map((a) => getRow(a)),
    [purchaseOrders, getRow]
  );

  return purchaseOrders === null ? (
    <Text>{t("ERROR")}</Text>
  ) : (
    <BBox
      header={
        <Group position="apart">
          <div></div>
          {/* {weekOnly ? (
            <Group spacing={0}>
              <Text ml="xl" mr="xs">
                {t("WEEK_OF")}
              </Text>
              <BDateInput
                onChange={(v) => {
                  if (v) {
                    setSelectedDate(
                      tu.getDateValueAtHMSM(tu.getDate(v), 0, 0, 0, 0)
                    );
                  }
                }}
                value={selectedDate}
              />
            </Group>
          ) : (
            <div></div>
          )} */}

          <Group>
            <ActionIcon
              onClick={() => {
                setRefresh(id_util.newId36());
              }}
            >
              {refreshing ? <Loader size="xs" /> : <IconRefresh size={18} />}
            </ActionIcon>
            {/* 
            <Switch
              label={t("SHOW_COMPLETE")}
              labelPosition="left"
              onChange={(v) => {
                setShowComplete(v.currentTarget.checked);
              }}
              checked={showComplete}
            /> */}
            <AddPurchaseOrderModal
              // vendorId={vendorId}
              // vendorName={vendorName}
              callback={() => setRefresh(id_util.newId36())}
            />
          </Group>
        </Group>
      }
    >
      <Box p="xl">
        <Table>
          <thead>
            <tr>
              <th>{t("DATE")}</th>
              <th>{t("ID")}</th>
              <th>{t("VENDOR")}</th>
              <th>{t("STATUS")}</th>
              <th>{t("TOTAL")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <Group position="right" mt="xl">
          <Button onClick={handlePreviousPage} disabled={!startBeforeId}>
            {t("PREVIOUS")}
          </Button>
          <Button onClick={handleNextPage} disabled={!startAfterId}>
            {t("NEXT")}
          </Button>
        </Group>
      </Box>
    </BBox>
  );
};
