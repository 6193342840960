import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectClinicLocations } from "features/admin/clinic-settings/ClinicSettings.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LocationForm } from "../LocationForm";

export const EditLocation = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const location = useAppSelector(selectClinicLocations)
    .filter((location) => location.id === id)
    .at(0);

  if (!location) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <LocationForm location={location} />;
};
