export type AptNotifTempalteData = {
  client: string;
  patient: string;
  aptType: string;
  aptTime: string;
  aptDate: string;
  clinicName: string;
  clinicEmail: string;
  clinicPhone: string;
  clinicAddress: string;
  clinicLogoURL?: string;
};

const generateAptNotifHTML = (str: string, data: AptNotifTempalteData) => {
  const newStr = str
    .replace(new RegExp("{CLIENT}", "g"), data.client)
    .replace(new RegExp("{PATIENT}", "g"), data.patient)
    .replace(new RegExp("{APT_TIME}", "g"), data.aptTime)
    .replace(new RegExp("{APT_DATE}", "g"), data.aptDate)
    .replace(new RegExp("{APT_TYPE}", "g"), data.aptType)
    .replace(new RegExp("{CLINIC_NAME}", "g"), data.clinicName)
    .replace(new RegExp("{CLINIC_EMAIL}", "g"), data.clinicEmail)
    .replace(new RegExp("{CLINIC_PHONE}", "g"), data.clinicPhone)
    .replace(new RegExp("{CLINIC_ADDRESS}", "g"), data.clinicAddress)
    .replace(
      new RegExp("{CLINIC_LOGO}", "g"),
      `<img
    src="${data.clinicLogoURL ?? ""}"
    height="60px"
    width="auto"
    max-width: 100%;
    alt="logo"
    border="0"
  />`
    );

  return newStr;
};

const generateAptNotifText = (str: string, data: AptNotifTempalteData) => {
  const newStr = str
    .replace(new RegExp("{CLIENT}", "g"), data.client)
    .replace(new RegExp("{PATIENT}", "g"), data.patient)
    .replace(new RegExp("{APT_TIME}", "g"), data.aptTime)
    .replace(new RegExp("{APT_DATE}", "g"), data.aptDate)
    .replace(new RegExp("{APT_TYPE}", "g"), data.aptType)
    .replace(new RegExp("{CLINIC_NAME}", "g"), data.clinicName)
    .replace(new RegExp("{CLINIC_EMAIL}", "g"), data.clinicEmail)
    .replace(new RegExp("{CLINIC_PHONE}", "g"), data.clinicPhone)
    .replace(new RegExp("{CLINIC_ADDRESS}", "g"), data.clinicAddress);

  return newStr;
};

export const ant_utils = {
  generateAptNotifText,
  generateAptNotifHTML,
};
