import {
  Checkbox,
  Divider,
  Group,
  Input,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
  TreatmentData,
  TreatmentType,
  treatmentTypeSchema,
} from "beitary-shared";
import {
  BBox,
  BDateAndTimeInput,
  BDateInput,
  BTextEditor,
  CancelButton,
  MoveBackButton,
  SaveButton,
} from "components";
import { selectActiveConsultationTreatments } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BundleTxFormValues, rules } from "./BundleTxForm.rules";

interface BundleTxFormProps {
  consultationId: string;
  disabled: boolean;
  nested?: boolean; // TODO this might be bad, just wanted to hide move back button
  // prolly need to create wrapper component
  // whatever
}

export const BundleTxForm = ({
  consultationId,
  disabled,
  nested,
}: BundleTxFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const txDBService =
    useDBServices().consultationsDBService.treatments(consultationId);

  const { bundleId } = useParams();
  const bundle = useAppSelector(selectActiveConsultationTreatments).find(
    (t) => t.id === bundleId
  );

  const products = useAppSelector(selectActiveConsultationTreatments).filter(
    (p) =>
      p.fixedPriceBundleId &&
      p.fixedPriceBundleId === bundle?.id &&
      p.fixedPriceBundleId !== p.id
  );

  const form = useForm<BundleTxFormValues>({
    initialValues: bundle
      ? {
          ...bundle,
          products: products.map((p) => ({
            ...p,
          })),
        }
      : undefined,
    validate: rules,
  });

  // console.log(form.values);

  if (!bundle) return <Text>{t("NOT_FOUND")}</Text>;

  const submit = async (values: BundleTxFormValues) => {
    if (disabled || !bundle) return;

    const items: { id: string; data: Partial<TreatmentData> }[] = [
      {
        id: bundle.id,
        data: { ...values },
      },
    ];
    values.products.forEach((p) => {
      obju.removeUndefined(p);
      let newItem: { id: string; data: Partial<TreatmentData> } = {
        id: p.id,
        data: p,
      };
      items.push(newItem);
    });

    const notificationId = "submit-treatment";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_TREATMENT"),
      })
    );
    setSubmitState("pending-response");

    const result = await txDBService.updateTreatments(items);
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        {!nested && (
          <Group>
            <MoveBackButton />
          </Group>
        )}
        <BBox header={<Text weight={500}>{bundle.productSnapshot.name}</Text>}>
          <Stack p="xl" spacing="xl">
            <Group grow>
              <NumberInput
                disabled={disabled}
                label={t("QUANTITY")}
                precision={2}
                rightSection={
                  bundle.unit ? (
                    <Text size="xs" mr="xs">
                      {t(bundle.unit)}
                    </Text>
                  ) : undefined
                }
                {...form.getInputProps("qty")}
                onChange={(v) => {
                  if (v !== "") {
                    products.forEach((p, index) => {
                      form.setFieldValue(`products.${index}.qty`, p.qty * v);
                    });
                    form.setFieldValue("qty", v);
                  }
                }}
              />
              <Select
                disabled={disabled}
                withinPortal
                label={t("TREATMENT_TYPE")}
                data={treatmentTypeSchema.options.map((o) => ({
                  value: o,
                  label: t(o),
                }))}
                {...form.getInputProps("type")}
                clearable={false}
                allowDeselect={false}
                searchable
                onChange={(v) => {
                  if (v) {
                    products.forEach((_p, index) => {
                      form.setFieldValue(`products.${index}.type`, v);
                    });
                    form.setFieldValue("type", v as TreatmentType);
                  }
                }}
              />
              {form.values.type === "SCHEDULED" && (
                <BDateAndTimeInput
                  disabled={disabled}
                  label={t("TIME_DUE")}
                  {...form.getInputProps("timeDue")}
                  onChange={(v) => {
                    if (v) {
                      products.forEach((_p, index) => {
                        form.setFieldValue(`products.${index}.timeDue`, v);
                      });
                      form.setFieldValue("timeDue", v);
                    }
                  }}
                />
              )}

              {form.values.type === "PRN" && (
                <NumberInput
                  disabled={disabled}
                  label={t("PRIORITY")}
                  min={0}
                  {...form.getInputProps("priority")}
                  onChange={(v) => {
                    if (v) {
                      products.forEach((_p, index) => {
                        form.setFieldValue(`products.${index}.priority`, v);
                      });
                      form.setFieldValue("priority", v);
                    }
                  }}
                />
              )}
            </Group>

            <Group grow>
              <Input.Wrapper label={t("ADDITIONAL_INSTRUCTIONS")}>
                <BTextEditor
                  disabled={disabled}
                  {...form.getInputProps(`adminInstr`)}
                />
              </Input.Wrapper>
            </Group>
            <div>
              <Text weight={500} mt="xl" mb="xs">
                {t("PRODUCTS")}
              </Text>
              <Divider />
            </div>
            {products.map((p, index) => (
              <BBox header={<Text color="cyan">{p.productSnapshot.name}</Text>}>
                <Stack p="xl">
                  {p.productSnapshot.integration && (
                    <Checkbox
                      label={t("ADMINISTER_WITHOUT_INTEGRATION")}
                      {...form.getInputProps(
                        `products.${index}.administerWithoutIntegration`,
                        {
                          type: "checkbox",
                        }
                      )}
                    />
                  )}
                  <Group grow>
                    <NumberInput
                      disabled={disabled}
                      label={t("QUANTITY")}
                      precision={2}
                      rightSection={
                        p.unit ? (
                          <Text size="xs" mr="xs">
                            {t(p.unit)}
                          </Text>
                        ) : undefined
                      }
                      {...form.getInputProps(`products.${index}.qty`)}
                    />
                    {(p.productSnapshot.type === "MEDICATION" ||
                      p.productSnapshot.type === "VACCINE") && (
                      <>
                        <TextInput
                          label={t("SERIAL_NUMBER")}
                          {...form.getInputProps(
                            `products.${index}.serialNumber`
                          )}
                          min={1}
                        />
                        <TextInput
                          disabled={disabled}
                          label={t("LOT_NUMBER")}
                          {...form.getInputProps(`products.${index}.lotNumber`)}
                        />
                        <BDateInput
                          disabled={disabled}
                          label={t("LOT_EXPIRATION_DATE")}
                          {...form.getInputProps(
                            `products.${index}.lotExpirationDate`
                          )}
                        />
                        <TextInput
                          disabled={disabled}
                          label={t("MANUFACTURER")}
                          {...form.getInputProps(
                            `products.${index}.manufacturer`
                          )}
                        />
                      </>
                    )}
                  </Group>
                  <Group grow>
                    <Input.Wrapper label={t("ADDITIONAL_INSTRUCTIONS")}>
                      <BTextEditor
                        disabled={disabled}
                        {...form.getInputProps(`products.${index}.adminInstr`)}
                      />
                    </Input.Wrapper>
                  </Group>
                </Stack>
              </BBox>
            ))}
          </Stack>
        </BBox>

        <Group position="right">
          <CancelButton />
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
