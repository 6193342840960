import { z } from "zod";
import { languagesEnum, UnableToNormalizeData } from "../../../types-schemas";

// NOT USED ANYMORE
export const gHOnHTTPRequestDataSchema = z.object({
  question: z.string(),
  organization_id: z.string(),
  organization_name: z.string(),
  user_id: z.string(),
  user_name: z.string(),
  language: languagesEnum,
});

export type GHOnHTTPRequestData = z.infer<typeof gHOnHTTPRequestDataSchema>;

export const normalizeGHOnHTTPRequestData = (
  data: unknown
): GHOnHTTPRequestData => {
  try {
    return gHOnHTTPRequestDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

export const gHOnHTTPRequestResponseSchema = z.object({
  message: z.string().nullable(),
  sources: z.array(z.string()),
});

export type GHOnHTTPRequestResponse = z.infer<
  typeof gHOnHTTPRequestResponseSchema
>;

export const normalizeGHOnHTTPRequestResponse = (
  data: unknown
): GHOnHTTPRequestResponse => {
  try {
    return gHOnHTTPRequestResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
