import { Group, Stack } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { BNotFound, MoveBackButton } from "components";
import {
  BundleTxForm,
  TxForm,
} from "features/consultations/components/Consultation/components/Plan/components";
import { setActiveConsltationTreatments } from "features/consultations/Consultations.slice";
import { selectConsTxs } from "features/whiteboard/Whiteboard.slice";
import { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { ConsultationTreatments } from "../ConsultationTreatments";
import { ImportantInfoCard } from "../ImportantInfoCard";

export interface ConsultationProps {}

export const Consultation = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const consTx = useAppSelector(selectConsTxs).find((c) => c.id === id);

  useEffect(() => {
    // THIS is cauz forms look up treatment in activeConsultationTreatments
    dispatch(
      setActiveConsltationTreatments([
        ...(consTx?.PRN ?? []),
        ...(consTx?.SCHEDULED ?? []),
        ...(consTx?.STAT ?? []),
      ])
    );
  }, [dispatch, consTx]);

  if (!consTx) return <BNotFound />;
  const disabled = consTx.status === "LOCKED";

  return (
    <Stack>
      <Group>
        <MoveBackButton />
      </Group>
      <ImportantInfoCard consultation={consTx} />
      <Routes>
        <Route index element={<ConsultationTreatments />} />
        <Route
          path="treatments/:treatmentId/edit"
          element={
            <TxForm nested consultationId={consTx.id} disabled={disabled} />
          }
        />
        <Route
          path="bundle-treatments/:bundleId/edit"
          element={
            <BundleTxForm
              nested
              consultationId={consTx.id}
              disabled={disabled}
            />
          }
        />
      </Routes>
    </Stack>
  );
};
