import { countriesEN, countriesAR, countriesFR } from "./assets";

interface CountriesObject {
  [key: string]: string;
}

interface CountrySelectData {
  label: string;
  value: string;
}

const countriesENObj: CountriesObject = countriesEN;

const countriesListEN: CountrySelectData[] = Object.keys(countriesENObj).map(
  (key) => ({
    label: countriesENObj[key],
    value: key,
  })
);

const countriesARObj: CountriesObject = countriesAR;

const countriesListAR: CountrySelectData[] = Object.keys(countriesARObj).map(
  (key) => ({
    label: countriesENObj[key],
    value: key,
  })
);

const countriesFRObj: CountriesObject = countriesFR;

const countriesListFR: CountrySelectData[] = Object.keys(countriesFRObj).map(
  (key) => ({
    label: countriesENObj[key],
    value: key,
  })
);

const getCountriesListByLanguage = (language: string) => {
  switch (language) {
    case "en":
      return countriesListEN;
    case "fr":
      return countriesListFR;
    case "ar":
      return countriesListAR;
    default:
      return countriesListEN;
  }
};

export const countries_util = {
  getCountriesListByLanguage,
};
