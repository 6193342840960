import { Avatar, Box, Container, Group, Tabs } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { MoveBackButton } from "components";
import {
  selectActiveConsultations,
  selectInReviewConsultations,
  selectLabPendingConsultations,
} from "features/consultations/Consultations.slice";
import { selectImagingDiagnosticItems } from "features/diagnostics/Diagnostics.slice";
import { selectTodaysAppointments } from "features/schedule/appointments/Appointments.slice";
import { selectCurrentDayTasks } from "features/tasks/tasks.slice";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActiveConsultationsList,
  ImagingDiagnosticsItemsList,
  InReviewConsultationsList,
  LabPendingConsultationsList,
  TodaysAppointmentsList,
  TodaysTasksList,
} from "./components";

export const View = () => {
  const { t } = useTranslation();

  const activeConsultationsLength = useAppSelector(
    selectActiveConsultations
  ).length;

  const inReviewConsultationsLength = useAppSelector(
    selectInReviewConsultations
  ).length;

  const labPendingConsultationsLength = useAppSelector(
    selectLabPendingConsultations
  ).length;

  const todaysAptLength = useAppSelector(selectTodaysAppointments).length;
  const todaysTasksLength = useAppSelector(selectCurrentDayTasks).length;
  const imagingDiagnosticsLength = useAppSelector(
    selectImagingDiagnosticItems
  ).length;

  //const aptRequestsLength = 0; // TODO fix me!!!

  const [activeTab, setActiveTab] = useState<string | null>(
    "active-consultations"
  );

  return (
    <Container fluid>
      <Box mt="xl">
        <MoveBackButton />
      </Box>

      <Tabs mt="xl" value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List mb="xl">
          <Tabs.Tab value="active-consultations">
            <Group>
              {t("ACTIVE_CONSULTATIONS")}
              {activeConsultationsLength > 0 && (
                <Avatar
                  src={null}
                  variant="filled"
                  color="green"
                  radius="xl"
                  size="sm"
                >
                  {activeConsultationsLength}
                </Avatar>
              )}
            </Group>
          </Tabs.Tab>
          <Tabs.Tab value="in-review-consultations">
            <Group>
              {t("IN_REVIEW")}
              {inReviewConsultationsLength > 0 && (
                <Avatar
                  src={null}
                  variant="filled"
                  color="orange"
                  radius="xl"
                  size="sm"
                >
                  {inReviewConsultationsLength}
                </Avatar>
              )}
            </Group>
          </Tabs.Tab>
          <Tabs.Tab value="lab-pending-consultations">
            <Group>
              {t("LAB_PENDING")}
              {labPendingConsultationsLength > 0 && (
                <Avatar
                  src={null}
                  variant="filled"
                  color="cyan"
                  radius="xl"
                  size="sm"
                >
                  {labPendingConsultationsLength}
                </Avatar>
              )}
            </Group>
          </Tabs.Tab>
          <Tabs.Tab value="todays-appointments">
            <Group>
              {t("TODAYS_APPOINTMENT")}
              {todaysAptLength > 0 && (
                <Avatar
                  src={null}
                  variant="filled"
                  color="red"
                  radius="xl"
                  size="sm"
                >
                  {todaysAptLength}
                </Avatar>
              )}
            </Group>
          </Tabs.Tab>
          {/* <Tabs.Tab value="appointment-requests">
            <Group>
              {t("APPOINTMENT_REQUESTS")}
              {aptRequestsLength > 0 && (
                <Avatar
                  src={null}
                  variant="filled"
                  color="red"
                  radius="xl"
                  size="sm"
                >
                  {aptRequestsLength}
                </Avatar>
              )}
            </Group>
          </Tabs.Tab> */}
          <Tabs.Tab value="todays-tasks">
            <Group>
              {t("TODAYS_TASKS")}
              {todaysTasksLength > 0 && (
                <Avatar
                  src={null}
                  variant="filled"
                  color="red"
                  radius="xl"
                  size="sm"
                >
                  {todaysTasksLength}
                </Avatar>
              )}
            </Group>
          </Tabs.Tab>
          <Tabs.Tab value="diagnostics">
            <Group>
              {t("DIAGNOSTICS")}
              {imagingDiagnosticsLength > 0 && (
                <Avatar
                  src={null}
                  variant="filled"
                  color="pink"
                  radius="xl"
                  size="sm"
                >
                  {imagingDiagnosticsLength}
                </Avatar>
              )}
            </Group>
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="active-consultations">
          <ActiveConsultationsList />
        </Tabs.Panel>
        <Tabs.Panel value="in-review-consultations">
          <InReviewConsultationsList />
        </Tabs.Panel>
        <Tabs.Panel value="lab-pending-consultations">
          <LabPendingConsultationsList />
        </Tabs.Panel>
        <Tabs.Panel value="todays-appointments">
          <TodaysAppointmentsList />
        </Tabs.Panel>
        {/* <Tabs.Panel value="appointment-requests">
          <AppointmentRequestsList />
        </Tabs.Panel> */}

        <Tabs.Panel value="todays-tasks">
          <TodaysTasksList />
        </Tabs.Panel>
        <Tabs.Panel value="diagnostics">
          <ImagingDiagnosticsItemsList />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};
