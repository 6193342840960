import { Group, Stack, Text } from "@mantine/core";
import { InvoiceLineItem } from "beitary-shared";
import { BBox } from "components";
import { useTranslation } from "react-i18next";
import { InvoiceLineItemsTable } from "../InvoiceLineItemsTable";

export interface NonConsultationChargesPanelProps {
  charges: InvoiceLineItem[];
  disabled: boolean;
}

export const NonConsultationChargesPanel = ({
  charges,
  disabled,
}: NonConsultationChargesPanelProps) => {
  const { t } = useTranslation();

  // console.log(charges);

  if (charges.length === 0) return <Text>{t("NOTHING_FOUND")}</Text>;

  const bundleCharges = charges
    .filter((c) => c.bundleId)
    .sort((a, b) => (b.createdAt < a.createdAt ? 1 : -1))
    .sort((a, b) => b.bundleId?.localeCompare(a.bundleId ?? "") ?? 0);

  // console.log(bundleCharges);

  const nonBundleCharges = charges.filter((c) => !c.bundleId);

  return (
    <BBox
      header={
        <Group position="apart">
          <Text color="cyan">{t("ITEMS")}</Text>
        </Group>
      }
    >
      <Stack p="xl">
        {bundleCharges.length > 0 && (
          <InvoiceLineItemsTable
            key={`bundles`}
            charges={bundleCharges}
            title={t("BUNDLES")}
            disabled={disabled}
          />
        )}
        {nonBundleCharges.length > 0 && (
          <InvoiceLineItemsTable
            key={`treatments`}
            charges={nonBundleCharges}
            title={t("OTHER")}
            disabled={disabled}
          />
        )}
      </Stack>
    </BBox>
  );
};
