import { Currency, Language, lsu, money_utils, TimeZone } from "beitary-shared";

const getOrgParams = () => {
  const params = lsu.getItemFromLocalStorage<{
    timezone: TimeZone;
    temperatureUnit: "C" | "F";
    language: Language;
    currency: Currency;
    countryCode: string;
  }>("btr_appSettings") ?? {
    currency: "TND",
    language: "EN",
    timezone: "Africa/Tunis",
    temperatureUnit: "C",
    countryCode: "TN",
  };

  return { ...params, dCurrency: money_utils.getDC(params.currency) };
};

export const org_params_util = { getOrgParams };
