import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectClients, selectPatients } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PatientForm } from "../PatientForm";

export const EditPatient = () => {
  const { t } = useTranslation();
  const { patientId, clientId } = useParams();

  const client = useAppSelector(selectClients)
    .filter((c) => c.id === clientId)
    .at(0);

  const patient = useAppSelector(selectPatients)
    .filter((patient) => patient.id === patientId)
    .at(0);

  if (!patient || !client) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return (
    <PatientForm
      patient={patient}
      clientId={client.id}
      clientName={client.name}
    />
  );
};
