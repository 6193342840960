import { zodResolver } from "@mantine/form";
import { productDataSchema } from "beitary-shared";
import { z } from "zod";

// t("DISCHARGE_INSTRUCTIONS_REQUIRED")
// t("MEDICAL_RECORD_NOTES_REQUIRED")
// t("PRESCRIPTION_DIRECTIONS_REQUIRED")
// t("TASK_NAME_REQUIRED")
// t("TASK_PERIOD_REQUIRED")
// t("TASK_DUE_DATE_REQUIRED")
// t("CC_DOSE_REQUIRED")
// t("LINKED_ITEM_UNIT_RATIO_REQUIRED")
// t("PATIENT_STATUS_REQUIRED")

const productFormSchema = productDataSchema
  .refine((data) => (data.hasDischInstr ? !!data.dischInstr : true), {
    message: "DISCHARGE_INSTRUCTIONS_REQUIRED",
  })
  .refine((data) => (data.hasMRN ? !!data.mrNotes : true), {
    message: "MEDICAL_RECORD_NOTES_REQUIRED",
  })
  .refine((data) => (data.hasPrespDir ? !!data.prespDir : true), {
    message: "PRESCRIPTION_DIRECTIONS_REQUIRED",
  })
  .refine((data) => (data.hasTask ? !!data.taskName : true), {
    message: "TASK_NAME_REQUIRED",
  })
  .refine((data) => (data.hasTask ? !!data.taskPeriod : true), {
    message: "TASK_PERIOD_REQUIRED",
  })
  .refine((data) => (data.hasTask ? !!data.taskDueDate : true), {
    message: "TASK_DUE_DATE_REQUIRED",
  })
  .refine(
    (data) =>
      data.type === "MEDICATION" || data.type === "VACCINE"
        ? !!data.ccSolventUnit &&
          !!data.doseBase &&
          // !!data.ccAmount &&
          // !!data.ccSoluteUnit &&
          !!data.dose
        : true,
    {
      message: "CC_DOSE_REQUIRED",
    }
  )
  .refine(
    (data) =>
      data.hasLII ? !!data.linkedInventoryItemId && !!data.unitRatio : true,
    {
      message: "LINKED_ITEM_UNIT_RATIO_REQUIRED",
    }
  )
  .refine((data) => (data.hasPatStat ? !!data.patStat : true), {
    message: "PATIENT_STATUS_REQUIRED",
  });

export type ProductFormValues = z.infer<typeof productFormSchema>;

export const rules = zodResolver(productFormSchema);

// t("REQUIRED_WHEN_SELECTED")
