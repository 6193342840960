import { Box, Card, Divider, useMantineTheme } from "@mantine/core";
import { ReactNode } from "react";

export interface BBoxProps {
  header?: ReactNode;
  children?: ReactNode;
  p?: "xs" | "sm" | "md" | "lg" | "xl" | number;
  m?: "xs" | "sm" | "md" | "lg" | "xl" | number;
  bgc?: string;
  bgcs?: number;
}

export const BBox = ({ header, children, p, m, bgc, bgcs }: BBoxProps) => {
  const theme = useMantineTheme();

  return (
    <Card withBorder radius="xs" p={p ?? 0} m={m ?? 0}>
      {header && (
        <>
          <Box bg={theme.white} p="md">
            {header}
          </Box>
          <Divider color={theme.colors.gray[3]} />
        </>
      )}
      {children && (
        <Box bg={theme.colors[bgc ?? "light_blue"][bgcs ?? 1]}>{children}</Box>
      )}
    </Card>
  );
};
