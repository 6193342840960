import {
  Center,
  Container,
  Group,
  Image,
  PinInput,
  Stack,
  Text,
} from "@mantine/core";
import { useFocusTrap } from "@mantine/hooks";
import { useAppSelector } from "app/hooks";
import { useAuthentication } from "features/Authentication/useAuthentication";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useIsMobile } from "hooks/useIsMobile";
import { useEffect, useState } from "react";

export const SigninPinForm = () => {
  const auth = useAuthentication();
  const org = useAppSelector(selectOrganization);
  const focusTrapRef = useFocusTrap();
  const [pin, setPin] = useState<string | undefined>();
  const isMobie = useIsMobile();

  useEffect(() => {
    const handleSubmit = async (pin: string) => {
      await auth.signInWithPinCode(pin);
      //      await signInWithPinCode({ pin: "TODO" });
    };

    if (pin && pin.length === 6) handleSubmit(pin);
  }, [auth, pin]);

  return (
    <Container fluid h="100vh" bg="white">
      <Center h="85%">
        <Stack align="center">
          <Group spacing="xs" position="center" mb="xl" align="flex-end">
            <Image
              src={org?.thumbURL}
              height={isMobie ? 100 : 180}
              fit="scale-down"
            />
            <Text size="xl" weight={500} color="cyan">
              {org?.name}
            </Text>
          </Group>

          <Stack w={320}>
            <Group position="center">
              <PinInput
                ref={focusTrapRef}
                type="number"
                mask
                length={6}
                onChange={(v) => {
                  if (v !== pin) setPin(v);
                }}
              />
            </Group>
          </Stack>
        </Stack>
      </Center>
    </Container>
  );
};
