import { Group, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  Category,
  getNotificationByResultType,
  loadingInfoNotification,
  Result,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { CategoryFormValues, rules } from "./CategoryForm.rules";

export interface CategoryFormProps {
  category?: Category;
}

export const CategoryForm = ({ category }: CategoryFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editProductCategory, addProductCategory } =
    useDBServices().catalogDBService.productCategories;

  const form = useForm<CategoryFormValues>({
    initialValues: category ? { ...category } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: CategoryFormValues) => {
    const notificationId = "submit-catalog-product-category";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(category ? "UPDATE_CATEGORY" : "ADD_CATEGORY"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<Category | null>;
    if (category) {
      result = await editProductCategory(category.id, values);
    } else {
      result = await addProductCategory(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            category ? (
              <Text weight={500}>{t("EDIT_CATEGORY")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_CATEGORY")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              required
              placeholder={t("ENTER_NAME")}
              label={t("NAME")}
              {...form.getInputProps("name")}
            />
            <Textarea
              placeholder={t("ENTER_NOTES")}
              label={t("NOTES")}
              {...form.getInputProps("notes")}
            />
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
