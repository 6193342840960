import { AppShell as Shell, AppShellProps, createStyles } from "@mantine/core";
// import { useState } from "react";
import { useMatch } from "react-router-dom";
import { AppNavbar } from "./components";

const useStyles = createStyles((theme) => ({
  display: {
    overflow: "hidden",
    backgroundColor: "#F1F7FE",
    height: "100%",
    position: "relative",
  },
}));

export const AppShell = (props: AppShellProps) => {
  const { classes } = useStyles();

  // const [opened, setOpened] = useState(false);
  const loginMatch = useMatch({ path: "/login" });
  const signupMatch = useMatch({ path: "/signup" });
  const hide = loginMatch || signupMatch;
  return (
    <Shell
      className={classes.display}
      fixed
      styles={{
        main: { padding: hide ? 0 : "auto" },
      }}
      navbar={<AppNavbar />}
      pb={60}
    >
      {props.children}
    </Shell>
  );
};
