import { Button, Container, Group, Stack, Text, Title } from "@mantine/core";
import { OrgPermissions } from "beitary-shared";
import { BBox } from "components";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ClientsTable } from "./components";

export const View = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container fluid>
      <Title mt="xl" mb="xl" weight={500} order={3}>
        {t("CLIENTS/PATIENTS")}
      </Title>

      <BBox
        header={
          <Group position="apart">
            <Text>{t("CLIENTS/PATIENTS")}</Text>
            <Group>
              <BCan I="do" a={OrgPermissions.PATIENTS_CLIENTS_CUD}>
                <Button onClick={() => navigate(`new`)}>{t("ADD_NEW")}</Button>
              </BCan>
            </Group>
          </Group>
        }
      >
        <Stack p="xl">
          <ClientsTable />
        </Stack>
      </BBox>
    </Container>
  );
};
