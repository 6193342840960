import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

// inspiration: https://stripe.com/docs/api/smsTemplates/object

const smsTemplateDataV0Schema = z.object({
  text: z.string().max(160),
  id: z.string(),
});

type SMSTemplateDataV0 = z.infer<typeof smsTemplateDataV0Schema>;

const smsTemplateV0Schema = baseRecordSchema.merge(smsTemplateDataV0Schema);

type SMSTemplateV0 = z.infer<typeof smsTemplateV0Schema>;

const normalizeSMSTemplateV0 = (data: any): SMSTemplate => {
  try {
    const smsTemplateV0: SMSTemplateV0 = smsTemplateV0Schema.parse(data);

    const { ...rest } = smsTemplateV0;

    // here it's same object cause current v is 0
    const smsTemplate: SMSTemplate = {
      ...rest,
    };
    return smsTemplate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const SMS_TEMPLATE_VERSION = "0";

export type SMSTemplateData = SMSTemplateDataV0;

export type SMSTemplate = BaseRecord & SMSTemplateData;

export const smsTemplateDataSchema = smsTemplateDataV0Schema;

export const smsTemplateSchema = smsTemplateV0Schema;

export const normalizeSMSTemplate = (data: any): SMSTemplate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeSMSTemplateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
