import {
  errorResult,
  i18n_utils,
  normalizePhysicalExamTemplate,
  PhysicalExamTemplate,
  PhysicalExamTemplateData,
  PHYSICAL_EXAM_TEMPLATE_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";
import { org_params_util } from "utils/org_params_utils";

// add organization PhysicalExamTemplate

interface AddPhysicalExamTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: PhysicalExamTemplateData;
  }): Promise<Result<PhysicalExamTemplate | null>>;
}

const addPhysicalExamTemplate: AddPhysicalExamTemplate = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new PhysicalExamTemplate id
    const newPhysicalExamTemplateRef = doc(
      collection(db, "organizations", organizationId, "physical_exam_templates")
    );

    const newPhysicalExamTemplate: PhysicalExamTemplate =
      normalizePhysicalExamTemplate({
        ...data,
        id: newPhysicalExamTemplateRef.id,
        authorId,
        authorName,
        version: PHYSICAL_EXAM_TEMPLATE_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

    await setDoc(newPhysicalExamTemplateRef, newPhysicalExamTemplate);

    // t("PHYSICAL_EXAM_TEMPLATE_CREATED")
    const successMessage = "PHYSICAL_EXAM_TEMPLATE_CREATED";
    return successResult({
      message: successMessage,
      payload: newPhysicalExamTemplate,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetPhysicalExamTemplate {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<PhysicalExamTemplate | null>>;
}

const getPhysicalExamTemplate: GetPhysicalExamTemplate = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const physicalExamTemplateDocRef = doc(
      db,
      "organizations",
      organizationId,
      "physical_exam_templates",
      id
    );
    const physicalExamTemplateDocSnapshot = await getDoc(
      physicalExamTemplateDocRef
    );
    if (physicalExamTemplateDocSnapshot.exists()) {
      try {
        const data: unknown = physicalExamTemplateDocSnapshot.data();
        const physicalExamTemplate: PhysicalExamTemplate =
          normalizePhysicalExamTemplate(data);
        // t("PHYSICAL_EXAM_TEMPLATE_FOUND")
        const successMessage = "PHYSICAL_EXAM_TEMPLATE_FOUND";
        return successResult({
          message: successMessage,
          payload: physicalExamTemplate,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("PHYSICAL_EXAM_TEMPLATE_NOT_FOUND")
      const errorMessage = "PHYSICAL_EXAM_TEMPLATE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface CopyPhysicalExamTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    id: string;
  }): Promise<Result<PhysicalExamTemplate | null>>;
}

const copyPhysicalExamTemplate: CopyPhysicalExamTemplate = async ({
  db,
  organizationId,
  id,
  authorId,
  authorName,
  source,
}) => {
  try {
    const physicalExamTemplateDocRef = doc(
      db,
      "organizations",
      organizationId,
      "physical_exam_templates",
      id
    );
    const physicalExamTemplateDocSnapshot = await getDoc(
      physicalExamTemplateDocRef
    );
    if (physicalExamTemplateDocSnapshot.exists()) {
      try {
        const data: unknown = physicalExamTemplateDocSnapshot.data();
        const physicalExamTemplate: PhysicalExamTemplate =
          normalizePhysicalExamTemplate(data);

        const copyText = i18n_utils.getCopyTranslation(
          org_params_util.getOrgParams().language
        );

        const cloneObj = {
          ...physicalExamTemplate,
          name: physicalExamTemplate.name + " " + copyText,
        };

        await addPhysicalExamTemplate({
          authorId,
          authorName,
          db,
          organizationId,
          source,
          data: cloneObj,
        });
        // t("PHYSICAL_EXAM_TEMPLATE_COPIED")
        const successMessage = "PHYSICAL_EXAM_TEMPLATE_COPIED";
        return successResult({
          message: successMessage,
          payload: physicalExamTemplate,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("PHYSICAL_EXAM_TEMPLATE_NOT_FOUND")
      const errorMessage = "PHYSICAL_EXAM_TEMPLATE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditPhysicalExamTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: PhysicalExamTemplateData;
  }): Promise<Result<PhysicalExamTemplate | null>>;
}

const editPhysicalExamTemplate: EditPhysicalExamTemplate = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const physicalExamTemplate = (
      await getPhysicalExamTemplate({ db, organizationId, id })
    ).payload;

    if (!physicalExamTemplate) {
      // t("PHYSICAL_EXAM_TEMPLATE_NOT_FOUND")
      const errorMessage = "PHYSICAL_EXAM_TEMPLATE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = physicalExamTemplate;
      const updatedPhysicalExamTemplate: PhysicalExamTemplate =
        normalizePhysicalExamTemplate({
          ...data,
          id,
          authorId,
          authorName,
          version: PHYSICAL_EXAM_TEMPLATE_VERSION,
          source,
          createdAt,
          lastUpdatedAt: tu.getCurrentDateTime(),
        });

      const physicalExamTemplateRef = doc(
        db,
        "organizations",
        organizationId,
        "physical_exam_templates",
        id
      );

      await setDoc(physicalExamTemplateRef, updatedPhysicalExamTemplate);

      // t("PHYSICAL_EXAM_TEMPLATE_UPDATED")
      const successMessage = "PHYSICAL_EXAM_TEMPLATE_UPDATED";
      // console.log(successMessage, updatedPhysicalExamTemplate);
      return successResult({
        message: successMessage,
        payload: updatedPhysicalExamTemplate,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeletePhysicalExamTemplate {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deletePhysicalExamTemplate: DeletePhysicalExamTemplate = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "physical_exam_templates",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("PHYSICAL_EXAM_TEMPLATE_DELETED")
      const successMessage = "PHYSICAL_EXAM_TEMPLATE_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archivePhysicalExamTemplate: EditPhysicalExamTemplate = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: PhysicalExamTemplateData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editPhysicalExamTemplate({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("PHYSICAL_EXAM_TEMPLATE_ARCHIVED")
    const successMessage = "PHYSICAL_EXAM_TEMPLATE_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchivePhysicalExamTemplate: EditPhysicalExamTemplate = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: PhysicalExamTemplateData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editPhysicalExamTemplate({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("PHYSICAL_EXAM_TEMPLATE_DEARCHIVED")
    const successMessage = "PHYSICAL_EXAM_TEMPLATE_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization PhysicalExamTemplates listener
interface GetPhysicalExamTemplatesListenerCallback {
  (physicalExamTemplates: PhysicalExamTemplate[]): void;
}
interface GetPhysicalExamTemplatesListener {
  db: Firestore;
  organizationId: string;
  callback: GetPhysicalExamTemplatesListenerCallback;
}

const getPhysicalExamTemplatesListener = ({
  db,
  organizationId,
  callback,
}: GetPhysicalExamTemplatesListener): Unsubscribe => {
  try {
    // console.log("getPhysicalExamTemplatesListener: new listener");
    const physicalExamTemplatesQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "physical_exam_templates"
      ),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(physicalExamTemplatesQuery, (querySnapshot) => {
      const physicalExamTemplates: PhysicalExamTemplate[] = [];
      querySnapshot.forEach((doc) => {
        try {
          physicalExamTemplates.push(normalizePhysicalExamTemplate(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(physicalExamTemplates);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const physicalExamTemplates = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getPhysicalExamTemplatesListener: (
      callback: GetPhysicalExamTemplatesListenerCallback
    ) =>
      getPhysicalExamTemplatesListener({
        db,
        organizationId,
        callback,
      }),
    getPhysicalExamTemplate: (id: string) =>
      getPhysicalExamTemplate({
        db,
        organizationId,
        id,
      }),
    addPhysicalExamTemplate: (data: PhysicalExamTemplateData) =>
      addPhysicalExamTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editPhysicalExamTemplate: (id: string, data: PhysicalExamTemplateData) =>
      editPhysicalExamTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    copyPhysicalExamTemplate: (id: string) =>
      copyPhysicalExamTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
      }),
    deletePhysicalExamTemplate: (id: string) =>
      deletePhysicalExamTemplate({
        db,
        organizationId,
        id,
      }),
    archivePhysicalExamTemplate: (id: string, data: PhysicalExamTemplateData) =>
      archivePhysicalExamTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchivePhysicalExamTemplate: (
      id: string,
      data: PhysicalExamTemplateData
    ) =>
      dearchivePhysicalExamTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
