import { zodResolver } from "@mantine/form";
import { inventoryTransactionDataSchema } from "beitary-shared";
import { z } from "zod";

const transactionFormSchema = inventoryTransactionDataSchema.omit({
  costPerUnit: true,
});

export type TransactionFormValues = z.infer<typeof transactionFormSchema>;

export const rules = zodResolver(transactionFormSchema);
