import {
  errorResult,
  MemberRole,
  MemberRoleData,
  MEMBER_ROLE_VERSION,
  normalizeMemberRole,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization memberRole

interface AddMemberRole {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: MemberRoleData;
  }): Promise<Result<boolean | null>>;
}

const addMemberRole: AddMemberRole = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new memberRole id
    const newMemberRoleRef = doc(
      collection(db, "organizations", organizationId, "member_roles")
    );

    const newMemberRole: MemberRole = normalizeMemberRole({
      ...data,
      id: newMemberRoleRef.id,
      authorId,
      authorName,
      version: MEMBER_ROLE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newMemberRoleRef, newMemberRole);

    const successMessage = "MemberRole created!";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetMemberRole {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<MemberRole | null>>;
}

const getMemberRole: GetMemberRole = async ({ db, organizationId, id }) => {
  try {
    const memberRoleDocRef = doc(
      db,
      "organizations",
      organizationId,
      "member_roles",
      id
    );
    const memberRoleDocSnapshot = await getDoc(memberRoleDocRef);
    if (memberRoleDocSnapshot.exists()) {
      try {
        const data: unknown = memberRoleDocSnapshot.data();
        const memberRole: MemberRole = normalizeMemberRole(data);
        const successMessage = "MemberRole found!";
        return successResult({
          message: successMessage,
          payload: memberRole,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "MemberRole not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditMemberRole {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<MemberRoleData>;
  }): Promise<Result<boolean | null>>;
}

const editMemberRole: EditMemberRole = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const updatedMemberRole: Partial<MemberRole> = {
      ...data,
      authorId,
      authorName,
      version: MEMBER_ROLE_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    const memberRoleRef = doc(
      db,
      "organizations",
      organizationId,
      "member_roles",
      id
    );

    await setDoc(memberRoleRef, updatedMemberRole);

    const successMessage = "MemberRole updated!";
    // console.log(successMessage, updatedMemberRole);
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteMemberRole {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteMemberRole: DeleteMemberRole = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "member_roles", id);

    try {
      await deleteDoc(docRef);
      // t("MEMBER_ROLE_DELETED")
      const successMessage = "MEMBER_ROLE_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// get organization memberRoles listener
interface GetMemberRolesListenerCallback {
  (memberRoles: MemberRole[]): void;
}
interface GetMemberRolesListener {
  db: Firestore;
  organizationId: string;
  callback: GetMemberRolesListenerCallback;
}

const getMemberRolesListener = ({
  db,
  organizationId,
  callback,
}: GetMemberRolesListener): Unsubscribe => {
  try {
    // console.log("getMemberRolesListener: new listener");
    const memberRolesQuery = query(
      collection(db, "organizations", organizationId, "member_roles"),
      orderBy("name", "asc")
    );
    return onSnapshot(memberRolesQuery, (querySnapshot) => {
      const memberRoles: MemberRole[] = [];
      querySnapshot.forEach((doc) => {
        try {
          memberRoles.push(normalizeMemberRole(doc.data()));
        } catch (error) {
          console.log(error);
        }
      });
      callback(memberRoles);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const memberRoles = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getMemberRolesListener: (callback: GetMemberRolesListenerCallback) =>
      getMemberRolesListener({
        db,
        organizationId,
        callback,
      }),
    getMemberRole: (id: string) =>
      getMemberRole({
        db,
        organizationId,
        id,
      }),
    addMemberRole: (data: MemberRoleData) =>
      addMemberRole({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editMemberRole: (id: string, data: MemberRoleData) =>
      editMemberRole({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteMemberRole: (id: string) =>
      deleteMemberRole({
        db,
        organizationId,
        id,
      }),
  };
};
