import { zodResolver } from "@mantine/form";
import { paymentDataSchema, paymentInvoiceDataSchema } from "beitary-shared";
import { z } from "zod";

const valueSchema = paymentInvoiceDataSchema.merge(
  z.object({
    include: z.boolean(),
    showInput: z.boolean(),
  })
);

const refundPaymentFormSchema = paymentDataSchema
  .omit({ invoices: true })
  .merge(
    z.object({
      invoices: z.record(valueSchema),
    })
  );

export type RefundPaymentFormValues = z.infer<typeof refundPaymentFormSchema>;
export type RefundPaymentFormValue = z.infer<typeof valueSchema>;

export const rules = zodResolver(refundPaymentFormSchema);
