import { Container, Tabs, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const View = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("reporting/reports")
    ? "reports"
    : "dl-history";

  return (
    <Container fluid>
      <Title mt="xl" weight={500} order={3}>
        {t("REPORTING")}
      </Title>
      <Tabs
        mt="xl"
        value={activeTab}
        onTabChange={(value) => {
          navigate(`${value}`);
        }}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value="reports">{t("REPORTS")}</Tabs.Tab>
          <Tabs.Tab value="dl-history">{t("DOWNLOAD_HISTORY")}</Tabs.Tab>
        </Tabs.List>
        <Outlet />
      </Tabs>
    </Container>
  );
};
