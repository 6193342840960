import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { appointmentNotificationTemplateTypeEnum } from "./AppointmentNotificationTemplate.type";
import { appointmentNotificationTimeUnitSchema } from "./AppointmentNotificationTimeUnit";

/**
 * schema and types versions
 * from first to last
 */

// v0

const emailAppointmentNotificationTemplateDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  type: appointmentNotificationTemplateTypeEnum.default("APT_BOOKED"),
  aptTypeIds: z.array(z.string()),
  notifications: z
    .array(appointmentNotificationTimeUnitSchema)
    .max(5)
    .optional(),
  notificationContent: z.string(),
});

type EmailAppointmentNotificationTemplateDataV0 = z.infer<
  typeof emailAppointmentNotificationTemplateDataV0Schema
>;

const emailAppointmentNotificationTemplateV0Schema = baseRecordSchema.merge(
  emailAppointmentNotificationTemplateDataV0Schema
);

type EmailAppointmentNotificationTemplateV0 = z.infer<
  typeof emailAppointmentNotificationTemplateV0Schema
>;

const normalizeEmailAppointmentNotificationTemplateV0 = (
  data: any
): EmailAppointmentNotificationTemplate => {
  try {
    const emailAppointmentNotificationTemplateV0: EmailAppointmentNotificationTemplateV0 =
      emailAppointmentNotificationTemplateV0Schema.parse(data);

    const { ...rest } = emailAppointmentNotificationTemplateV0;

    // here it's same object cause current v is 0
    const emailAppointmentNotificationTemplate: EmailAppointmentNotificationTemplate =
      {
        ...rest,
      };
    return emailAppointmentNotificationTemplate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_VERSION = "0";

export type EmailAppointmentNotificationTemplateData =
  EmailAppointmentNotificationTemplateDataV0;

export type EmailAppointmentNotificationTemplate = BaseRecord &
  EmailAppointmentNotificationTemplateData;

export const emailAppointmentNotificationTemplateDataSchema =
  emailAppointmentNotificationTemplateDataV0Schema;

export const emailAppointmentNotificationTemplateSchema =
  emailAppointmentNotificationTemplateV0Schema;

export const normalizeEmailAppointmentNotificationTemplate = (
  data: any
): EmailAppointmentNotificationTemplate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeEmailAppointmentNotificationTemplateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
