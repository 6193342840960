import {
  BStorageFileCustomMetadata,
  BStorageFileCustomMetadataData,
  BSTORAGE_FILE_CUSTOM_METADATA_VERSION,
  Source,
} from "beitary-shared";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  imagesStorageInstance,
  storageInstance,
  thumbnailStorageInstance,
  videosStorageInstance,
} from "services/firebase/FirebaseService";

export type BucketType = "default" | "thumbnail" | "images" | "videos";

const uploadFile = async (
  file: File | Blob,
  id: string,
  customMetadata: Omit<
    Omit<BStorageFileCustomMetadata, "createdAt">,
    "lastUpdatedAt"
  >,
  fileCategory: string,
  onError: (error: Error) => void,
  onSuccess: (props: { filePath: string; dlURL: string }) => void,
  onProgress: (progress: number) => void,
  bucket?: BucketType
) => {
  const strgInstance =
    bucket === "thumbnail"
      ? thumbnailStorageInstance
      : bucket === "images"
      ? imagesStorageInstance
      : bucket === "videos"
      ? videosStorageInstance
      : storageInstance;

  // id is to differenciate different uploads of same file
  // will get error on file download ERR_RESPONSE_HEADERS_MULTIPLE_CONTENT_DISPOSITION
  // if file name contains commas
  function sanitizeFileName(fileName: string) {
    return fileName.replace(new RegExp(",", "g"), "_").trim();
  }
  // DO NOT TOUCH THIS
  const fullPath = `organizations/${
    customMetadata.organizationId
  }/${fileCategory}/${file.type}/${id}/${sanitizeFileName(
    customMetadata.originalName
  )}`;

  const newRef = ref(strgInstance, fullPath);

  const uploadTask = uploadBytesResumable(newRef, file, {
    customMetadata,
    contentType: file.type,
  });

  // Listen for state changes, errors, and completion of the upload.
  return uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          onProgress(progress);
          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors TODO!!!
      onError(error);
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;
        case "storage/canceled":
          // User canceled the upload
          break;

        // ...

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        const osProps = {
          dlURL: downloadURL,
          filePath: uploadTask.snapshot.ref.fullPath,
        };

        onSuccess(osProps);
      });
    }
  );
};

export const sService = (
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => ({
  uploadFile: ({
    customMetadata,
    file,
    fileId,
    fileCategory,
    onError,
    onProgress,
    onSuccess,
    bucket,
  }: {
    file: File | Blob;
    fileId: string;
    fileCategory: string;
    customMetadata: Omit<
      Omit<BStorageFileCustomMetadataData, "readOrgIds">,
      "organizationId"
    >;
    onError: (error: Error) => void;
    onSuccess: (props: { filePath: string; dlURL: string }) => void;
    onProgress: (progress: number) => void;
    bucket?: BucketType;
  }) =>
    uploadFile(
      file,
      fileId,
      {
        ...customMetadata,
        organizationId,
        readOrgIds: organizationId,
        authorId,
        authorName,
        source,

        version: BSTORAGE_FILE_CUSTOM_METADATA_VERSION,
        id: fileId,
      },
      fileCategory,
      onError,
      onSuccess,
      onProgress,
      bucket
    ),
});
