import { Outlet, Route } from "react-router-dom";
import { AppointmentsRoutes } from "./Appointments/AppointmentsRoutes";
import { BoardingRoutes } from "./Boarding/BoardingRoutes";

export const ScheduleRoutes = (
  <Route path="schedule" element={<Outlet />}>
    {AppointmentsRoutes}
    {BoardingRoutes}
  </Route>
);
