import { useAppSelector } from "app/hooks";
import { BMultiSelect } from "components/BMultiSelect";
import { selectActiveReminders } from "features/admin/client-communication/ClientCommunication.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface RemindersMultiSelectProps {
  required?: boolean;
  onChange?: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const RemindersMultiSelect = (props: RemindersMultiSelectProps) => {
  const { t } = useTranslation();
  const reminders = useAppSelector(selectActiveReminders);

  const data = reminders.map((o) => ({ value: o.id, label: o.name }));

  return (
    <BMultiSelect
      {...props}
      label={t("REMINDERS")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};
