//import { useTranslation } from "react-i18next";

import { Client } from "beitary-shared";

export interface ClientCardProps {
  client?: Client;
}

export const ClientCard = ({ client }: ClientCardProps) => {
  //const { t } = useTranslation();

  return <div>Hello 👋, I am a ClientCard component. {client?.id}</div>;
};
