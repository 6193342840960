import { z } from "zod";
import {
  BaseRecord,
  baseRecordSchema,
} from "../../../types-schemas/baseRecord";
import { UnableToNormalizeData } from "../../../types-schemas/errors";
import { patientSexEnum } from "../../../types-schemas/patients";
import { imagingIntegrationEnum } from "../Integration/Integration";
import { diagnosticItemStatusEnum } from "./DiagnosticItem.status";

const imagingDiagnosticItemDataV0Schema = z.object({
  status: diagnosticItemStatusEnum,
  consultationId: z.string().optional(),
  clientId: z.string().optional(),
  clientName: z.string().optional(),
  patientId: z.string().optional(),
  patientName: z.string().optional(),
  patientSex: patientSexEnum.optional(),
  patientDOB: z.number().int().optional(),
  patientComments: z.string().optional(),
  additionalComments: z.string().optional(),
  referringDoctor: z.string().optional(),
  integration: imagingIntegrationEnum,
  accessionNumber: z.string().optional(),
  examTypeId: z.string().optional(), // id of integration product
  examTypeName: z.string().optional(), // name of integration product = code (like DR) = requested procedure id
  examTypeDescription: z.string().optional(), // description of integration product = display name (like DR) = requested procedure description
  modality: z.string().optional(), // AE title
  scheduledAt: z.number().int().optional(),
  bfileId: z.string().optional(),
  triggerTxId: z.string().optional(),
});

type ImagingDiagnosticItemDataV0 = z.infer<
  typeof imagingDiagnosticItemDataV0Schema
>;

const imagingDiagnosticItemV0Schema = baseRecordSchema.merge(
  imagingDiagnosticItemDataV0Schema
);

type ImagingDiagnosticItemV0 = z.infer<typeof imagingDiagnosticItemV0Schema>;

const normalizeImagingDiagnosticItemV0 = (data: any): ImagingDiagnosticItem => {
  try {
    const imagingDiagnosticItemV0: ImagingDiagnosticItemV0 =
      imagingDiagnosticItemV0Schema.parse(data);

    const { ...rest } = imagingDiagnosticItemV0;

    // here it's same object cause current v is 0
    const imagingDiagnosticItem: ImagingDiagnosticItem = {
      ...rest,
    };
    return imagingDiagnosticItem;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const IMAGING_DIAGNOSTIC_ITEM_VERSION = "0";

export type ImagingDiagnosticItemData = ImagingDiagnosticItemDataV0;

export type ImagingDiagnosticItem = BaseRecord & ImagingDiagnosticItemData;

export const imagingDiagnosticItemDataSchema =
  imagingDiagnosticItemDataV0Schema;

export const imagingDiagnosticItemSchema = imagingDiagnosticItemV0Schema;

export const normalizeImagingDiagnosticItem = (
  data: any
): ImagingDiagnosticItem => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeImagingDiagnosticItemV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
