import { paymentMethodsEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

export interface PaymentMethodSelectProps {
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  isRefund?: boolean;
  disableCredit: boolean;
}

export const PaymentMethodSelect = ({
  disableCredit,
  isRefund,
  ...props
}: PaymentMethodSelectProps) => {
  useEffect(() => {}, [disableCredit]);

  const { t } = useTranslation();
  const data = paymentMethodsEnum.options.map((o) => ({
    value: o,
    label: t(o),
    disabled: o === "CREDIT_BALANCE" && disableCredit,
  }));

  return (
    <BSelect
      {...props}
      required
      data={data}
      label={t(isRefund ? "REFUND_METHOD" : "PAYMENT_METHOD")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
