import { z } from "zod";

// t("DOG")
// t("CAT")
// t("CATTLE")
// t("PIGEON")
// t("CAMEL")
// t("CANARY")
// t("CHICKEN")
// t("DUCK")
// t("DONKEY")
// t("GOAT")
// t("SHEEP")
// t("GOOSE")
// t("HORSE")
// t("PIG")
// t("RABBIT")
// t("TURKEY")
// t("MOUSE")
// t("RAT")
// t("GUINEA_PIG")
// t("FISH")
// t("QUAIL")
// t("OTHER")
export const patientSpeciesEnum = z.enum([
  "DOG",
  "CAT",
  "CATTLE",
  "PIGEON",
  "CAMEL",
  "CANARY",
  "CHICKEN",
  "DUCK",
  "DONKEY",
  "GOAT",
  "SHEEP",
  "GOOSE",
  "HORSE",
  "PIG",
  "RABBIT",
  "TURKEY",
  "MOUSE",
  "RAT",
  "GUINEA_PIG",
  "FISH",
  "QUAIL",
  "OTHER",
]);
export type PatientSpecies = z.infer<typeof patientSpeciesEnum>;
