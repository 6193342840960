import {
  Consultation,
  ConsultationData,
  CONSULTATION_VERSION,
  errorResult,
  id_util,
  normalizeConsultation,
  Note,
  NoteData,
  NOTE_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  endBefore,
  FieldValue,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";

// add organization consultation

interface AddConsultation {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: ConsultationData;
  }): Promise<Result<Consultation | null>>;
}

const addConsultation: AddConsultation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new consultation id
    const newConsultationRef = doc(
      collection(db, "organizations", organizationId, "consultations")
    );

    const newConsultation: Consultation = normalizeConsultation({
      ...data,
      id: newConsultationRef.id,
      authorId,
      authorName,
      version: CONSULTATION_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newConsultationRef, newConsultation);

    // t("CONSULTATION_CREATED")
    const successMessage = "CONSULTATION_CREATED";
    return successResult({
      message: successMessage,
      payload: newConsultation,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetConsultation {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Consultation | null>>;
}

const getConsultation: GetConsultation = async ({ db, organizationId, id }) => {
  try {
    const consultationDocRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      id
    );
    const consultationDocSnapshot = await getDoc(consultationDocRef);
    if (consultationDocSnapshot.exists()) {
      try {
        const data: unknown = consultationDocSnapshot.data();
        const consultation: Consultation = normalizeConsultation(data);
        // t("CONSULTATION_FOUND")
        const successMessage = "CONSULTATION_FOUND";
        return successResult({
          message: successMessage,
          payload: consultation,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("CONSULTATION_NOT_FOUND")
      const errorMessage = "CONSULTATION_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetPatientConsultations {
  ({
    db,
    organizationId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    patientId: string;
  }): Promise<Result<Consultation[] | null>>;
}

const getPatientConsultations: GetPatientConsultations = async ({
  db,
  organizationId,
  patientId,
}) => {
  try {
    const consultationsQuery = query(
      collection(db, "organizations", organizationId, "consultations"),
      where("patientId", "==", patientId),
      orderBy("createdAt")
    );

    const querySnapshot = await getDocs(consultationsQuery);

    const consultations: Consultation[] = [];
    querySnapshot.forEach((doc) => {
      try {
        consultations.push(normalizeConsultation(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });
    // t("CONSULTATION_NOT_FOUND")
    const successMessage = "CONSULTATION_NOT_FOUND";
    return successResult({
      message: successMessage,
      payload: consultations,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetConsultations {
  ({
    db,
    organizationId,
    startAfterTime,
    startBeforeTime,
    startAtTime,
    clientId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    startAfterTime?: number;
    startBeforeTime?: number;
    startAtTime?: number;
    clientId?: string;
    patientId?: string;
  }): Promise<Result<Consultation[] | null>>;
}

const getConsultations: GetConsultations = async ({
  db,
  organizationId,
  startAfterTime,
  startBeforeTime,
  startAtTime,
  clientId,
  patientId,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "consultations")
    );

    if (clientId) {
      newQuery = query(newQuery, where("clientId", "==", clientId));
    }

    if (patientId) {
      newQuery = query(newQuery, where("patientId", "==", patientId));
    }

    newQuery = query(newQuery, orderBy("createdAt", "desc"), limit(PAGE_SIZE));

    // FirebaseError: Invalid query. You must not call startAt() or startAfter() before calling orderBy().
    if (startBeforeTime) {
      newQuery = query(
        newQuery,
        endBefore(startBeforeTime),
        limitToLast(PAGE_SIZE)
      );
    }

    if (startAfterTime) {
      newQuery = query(newQuery, startAfter(startAfterTime));
    }

    if (startAtTime) {
      newQuery = query(newQuery, startAt(startAtTime));
    }

    const querySnapshot = await getDocs(newQuery);

    const consultations: Consultation[] = [];

    querySnapshot.forEach((doc) => {
      try {
        consultations.push(normalizeConsultation(doc.data()));
      } catch (error) {
        console.log(error);
      }
    });

    return successResult({
      message: "SUCCESS",
      payload: consultations,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateConsultation {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<ConsultationData>;
  }): Promise<Result<Partial<ConsultationData> | null>>;
}

const updateConsultation: UpdateConsultation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      id
    );

    const updates: Partial<Consultation> = {
      ...data,
      authorId,
      authorName,
      version: CONSULTATION_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("CONSULTATION_UPDATED")
    const successMessage = "CONSULTATION_UPDATED";

    return successResult({
      message: successMessage,
      payload: updates,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteConsultation {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteConsultation: DeleteConsultation = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("CONSULTATION_DELETED")
      const successMessage = "CONSULTATION_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// get organization consultations listener
interface GetConsultationListenerCallback {
  (consultation: Consultation | null): void;
}
interface GetConsultationListener {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  callback: GetConsultationListenerCallback;
}

const getConsultationListener = ({
  db,
  organizationId,
  consultationId,
  callback,
}: GetConsultationListener): Unsubscribe => {
  try {
    // console.log("getConsultationsListener: new listener");
    const consultationQuery = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId
    );

    return onSnapshot(consultationQuery, (querySnapshot) => {
      try {
        callback(normalizeConsultation(querySnapshot.data()));
      } catch (err) {
        console.log(err);
        callback(null);
      }
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// get organization consultations listener
interface GetConsultationsListenerCallback {
  (consultations: Consultation[]): void;
}
interface GetConsultationsListener {
  db: Firestore;
  organizationId: string;
  callback: GetConsultationsListenerCallback;
}

const getConsultationsListener = ({
  db,
  organizationId,
  callback,
}: GetConsultationsListener): Unsubscribe => {
  try {
    // console.log("getConsultationsListener: new listener");
    const consultationsQuery = query(
      collection(db, "organizations", organizationId, "consultations"),
      where("status", "!=", "LOCKED")
    );
    return onSnapshot(consultationsQuery, (querySnapshot) => {
      const consultations: Consultation[] = [];
      querySnapshot.forEach((doc) => {
        try {
          consultations.push(normalizeConsultation(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(consultations);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// NOTES
interface AddNote {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    consultationId: string;
    authorName: string;
    source: Source;
    noteData: NoteData;
    noteCategory:
      | "PLAN"
      | "LAB_DOC"
      | "IMAGING_DOC"
      | "OTHER_DOC"
      | "ADDENDUM"
      | "FORM";
  }): Promise<Result<string | null>>;
}

const addNote: AddNote = async ({
  db,
  organizationId,
  authorId,
  authorName,
  consultationId,
  source,
  noteData,
  noteCategory,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId
    );

    const id = id_util.newId20();

    const newNote: Note = {
      ...noteData,
      id,
      authorId,
      authorName,
      version: NOTE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    let updates: Partial<Consultation> = {
      lastUpdatedAt: tu.getCurrentDateTime(),
      authorId,
      authorName,
      source,
    };

    switch (noteCategory) {
      case "ADDENDUM":
        updates["addendumNotes"] = { [newNote.id]: newNote };
        break;

      case "FORM":
        updates["formsNotes"] = { [newNote.id]: newNote };
        break;

      case "IMAGING_DOC":
        updates["imagingDocsNotes"] = { [newNote.id]: newNote };
        break;

      case "LAB_DOC":
        updates["labDocsNotes"] = { [newNote.id]: newNote };
        break;

      case "OTHER_DOC":
        updates["otherDocsNotes"] = { [newNote.id]: newNote };
        break;

      case "PLAN":
        updates["planNotes"] = { [newNote.id]: newNote };
        break;

      default:
        break;
    }

    await setDoc(docRef, updates, { merge: true });

    // t("NOTE_ADDED")
    const successMessage = "NOTE_ADDED";
    return successResult({
      message: successMessage,
      payload: id,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface DeleteNote {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    consultationId: string;
    authorName: string;
    source: Source;
    noteId: string;
    noteCategory:
      | "PLAN"
      | "LAB_DOC"
      | "IMAGING_DOC"
      | "OTHER_DOC"
      | "ADDENDUM"
      | "FORM";
  }): Promise<Result<string | null>>;
}

const deleteNote: DeleteNote = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  consultationId,
  noteId,
  noteCategory,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId
    );

    let updates: Partial<Consultation> = {
      lastUpdatedAt: tu.getCurrentDateTime(),
      authorId,
      authorName,
      source,
    };

    let toDelete: { [key: string]: { [key: string]: FieldValue } } = {};

    switch (noteCategory) {
      case "ADDENDUM":
        toDelete = { addendumNotes: { [noteId]: deleteField() } };
        break;

      case "FORM":
        toDelete = { formsNotes: { [noteId]: deleteField() } };
        break;

      case "IMAGING_DOC":
        toDelete = { imagingDocsNotes: { [noteId]: deleteField() } };
        break;

      case "LAB_DOC":
        toDelete = { labDocsNotes: { [noteId]: deleteField() } };
        break;

      case "OTHER_DOC":
        toDelete = { otherDocsNotes: { [noteId]: deleteField() } };
        break;

      case "PLAN":
        toDelete = { planNotes: { [noteId]: deleteField() } };
        break;

      default:
        break;
    }

    await setDoc(
      docRef,
      {
        ...toDelete,
        ...updates,
      },
      { merge: true }
    );

    // t("INVOICE_UPDATED")
    const successMessage = "INVOICE_UPDATED";

    return successResult({
      message: successMessage,
      payload: noteId,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const consultations = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getConsultationsListener: (callback: GetConsultationsListenerCallback) =>
      getConsultationsListener({
        db,
        organizationId,
        callback,
      }),

    getConsultations: (props: {
      startAfterTime?: number;
      startBeforeTime?: number;
      startAtTime?: number;
      clientId?: string;
      patientId?: string;
      showLocked?: boolean;
    }) =>
      getConsultations({
        db,
        organizationId,
        ...props,
      }),
    getConsultationListener: (
      consultationId: string,
      callback: GetConsultationListenerCallback
    ) =>
      getConsultationListener({
        db,
        organizationId,
        consultationId,
        callback,
      }),
    getConsultation: (id: string) =>
      getConsultation({
        db,
        organizationId,
        id,
      }),
    getPatientConsultations: (patientId: string) =>
      getPatientConsultations({
        db,
        organizationId,
        patientId,
      }),
    addConsultation: (data: ConsultationData) =>
      addConsultation({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    deleteConsultation: (id: string) =>
      deleteConsultation({
        db,
        organizationId,
        id,
      }),
    lockConsultation: (id: string) =>
      updateConsultation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "LOCKED" },
      }),
    markConsultationAsInReview: (id: string) =>
      updateConsultation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "IN_REVIEW" },
      }),
    markConsultationAsLabPending: (id: string) =>
      updateConsultation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "LAB_PENDING" },
      }),

    updateConsultationNotes: (id: string, data: string) =>
      updateConsultation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { notes: data },
      }),
    updateConsultationPlanRecommendations: (id: string, data: string) =>
      updateConsultation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { recommendations: data },
      }),

    updateConsultation: (id: string, data: Partial<ConsultationData>) =>
      updateConsultation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    addNote: (props: {
      noteData: NoteData;
      consultationId: string;
      noteCategory:
        | "PLAN"
        | "LAB_DOC"
        | "IMAGING_DOC"
        | "OTHER_DOC"
        | "ADDENDUM"
        | "FORM";
    }) =>
      addNote({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        ...props,
      }),

    deleteNote: (props: {
      noteId: string;
      consultationId: string;
      noteCategory:
        | "PLAN"
        | "LAB_DOC"
        | "IMAGING_DOC"
        | "OTHER_DOC"
        | "ADDENDUM"
        | "FORM";
    }) =>
      deleteNote({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        ...props,
      }),
  };
};
