import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";
import { physicalExamTemplateField } from "../../../types-schemas/templates";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const physicalExamEntryDataV0Schema = z.object({
  status: genericStatusEnum,
  consultationId: z.string(),
  supervisingDoctorId: z.string().optional(),
  supervisingDoctorName: z.string().optional(),
  fields: z.array(physicalExamTemplateField).default([]),
});

type PhysicalExamEntryDataV0 = z.infer<typeof physicalExamEntryDataV0Schema>;

const physicalExamEntryV0Schema = baseRecordSchema.merge(
  physicalExamEntryDataV0Schema
);

type PhysicalExamEntryV0 = z.infer<typeof physicalExamEntryV0Schema>;

const normalizePhysicalExamEntryV0 = (data: any): PhysicalExamEntry => {
  try {
    const physicalExamEntryV0: PhysicalExamEntryV0 =
      physicalExamEntryV0Schema.parse(data);

    const { ...rest } = physicalExamEntryV0;

    // here it's same object cause current v is 0
    const physicalExamEntry: PhysicalExamEntry = {
      ...rest,
    };
    return physicalExamEntry;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PHYSICAL_EXAM_ENTRY_VERSION = "0";

export type PhysicalExamEntryData = PhysicalExamEntryDataV0;

export type PhysicalExamEntry = BaseRecord & PhysicalExamEntryData;

export const physicalExamEntryDataSchema = physicalExamEntryDataV0Schema;

export const physicalExamEntrySchema = physicalExamEntryV0Schema;

export const normalizePhysicalExamEntry = (data: any): PhysicalExamEntry => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizePhysicalExamEntryV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
