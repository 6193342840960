import { z } from "zod";

export const prescriptionItemSchema = z.object({
  id: z.string(),
  pId: z.string(),
  pName: z.string(),
  isChronic: z.boolean().default(false),
  qty: z.number().positive(),
  nOfRefills: z.number().nonnegative().optional(),
  validUntil: z.number().positive().optional(),
  directions: z.string().optional(),
});

export type PrescriptionItem = z.infer<typeof prescriptionItemSchema>;
