import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
/**
 * schema and types versions
 * from first to last
 */

// v0

// inspiration: https://stripe.com/docs/api/appointmentNotifications/object

const appointmentNotificationDataV0Schema = z.object({
  type: z.enum(["EMAIL", "SMS"]),
  templateId: z.string().optional(),
  status: z.enum(["PENDING", "QUEUED"]),
  time: z.number(),
  appointmentId: z.string(),
  organizationId: z.string(),
});

type AppointmentNotificationDataV0 = z.infer<
  typeof appointmentNotificationDataV0Schema
>;

const appointmentNotificationV0Schema = baseRecordSchema.merge(
  appointmentNotificationDataV0Schema
);

type AppointmentNotificationV0 = z.infer<
  typeof appointmentNotificationV0Schema
>;

const normalizeAppointmentNotificationV0 = (
  data: any
): AppointmentNotification => {
  try {
    const appointmentNotificationV0: AppointmentNotificationV0 =
      appointmentNotificationV0Schema.parse(data);

    const { ...rest } = appointmentNotificationV0;

    // here it's same object cause current v is 0
    const appointmentNotification: AppointmentNotification = {
      ...rest,
    };
    return appointmentNotification;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const APPOINTMENT_NOTIFICATION_VERSION = "0";

export type AppointmentNotificationData = AppointmentNotificationDataV0;

export type AppointmentNotification = BaseRecord & AppointmentNotificationData;

export const appointmentNotificationDataSchema =
  appointmentNotificationDataV0Schema;

export const appointmentNotificationSchema = appointmentNotificationV0Schema;

export const normalizeAppointmentNotification = (
  data: any
): AppointmentNotification => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeAppointmentNotificationV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
