import {
  errorResult,
  normalizePayment,
  Payment,
  PaymentData,
  PAYMENT_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import { Unsubscribe } from "firebase/auth";
import {
  collection,
  doc,
  Firestore,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";

interface AddPayment {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: PaymentData;
  }): Promise<Result<Payment | null>>;
}

const addPayment: AddPayment = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    const newRef = doc(
      collection(db, "organizations", organizationId, "payments")
    );

    const newObj: Payment = normalizePayment({
      ...data,
      id: newRef.id,
      authorId,
      authorName,
      version: PAYMENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newRef, newObj);

    // t("PAYMENT_CREATED")
    const successMessage = "PAYMENTS_CREATED";
    return successResult({
      message: successMessage,
      payload: newObj,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetClientPayments {
  ({
    db,
    organizationId,
    clientId,
  }: {
    db: Firestore;
    organizationId: string;
    clientId: string;
  }): Promise<Result<Payment[] | null>>;
}

const getClientPayments: GetClientPayments = async ({
  db,
  organizationId,
  clientId,
}) => {
  try {
    let paymentsQuery = query(
      collection(db, "organizations", organizationId, "payments"),
      where("clientId", "==", clientId)
    );

    const querySnapshot = await getDocs(paymentsQuery);

    const payments: Payment[] = [];
    querySnapshot.forEach((doc) => {
      try {
        payments.push(normalizePayment(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });
    // t("PAYMENTS_FOUND")
    const successMessage = "PAYMENTS_FOUND";
    return successResult({
      message: successMessage,
      payload: payments,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetClientPaymentsListenerCallback {
  (invoices: Payment[]): void;
}
interface GetClientPaymentsListener {
  db: Firestore;
  organizationId: string;
  clientId: string;
  callback: GetClientPaymentsListenerCallback;
}

const getClientPaymentsListener = ({
  db,
  organizationId,
  clientId,
  callback,
}: GetClientPaymentsListener): Unsubscribe => {
  try {
    // console.log("getClientPaymentsListener: new listener");
    const paymentsQuery = query(
      collection(db, "organizations", organizationId, "payments"),
      where("clientId", "==", clientId),
      orderBy("createdAt", "desc")
    );
    return onSnapshot(paymentsQuery, (querySnapshot) => {
      const payments: Payment[] = [];
      querySnapshot.forEach((doc) => {
        try {
          payments.push(normalizePayment(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(payments);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface GetPaymentListenerCallback {
  (payment: Payment | null): void;
}
interface GetPaymentListener {
  db: Firestore;
  organizationId: string;
  paymentId: string;
  callback: GetPaymentListenerCallback;
}

const getPaymentListener = ({
  db,
  organizationId,
  paymentId,
  callback,
}: GetPaymentListener): Unsubscribe => {
  try {
    // console.log("getPaymentListener: new listener");

    const paymentQuery = doc(
      db,
      "organizations",
      organizationId,
      "payments",
      paymentId
    );

    return onSnapshot(paymentQuery, (querySnapshot) => {
      try {
        callback(normalizePayment(querySnapshot.data()));
      } catch (err) {
        console.log(err);
        callback(null);
      }
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

export const payments = ({
  authorId,
  authorName,
  db,
  organizationId,
  source,
}: {
  db: Firestore;
  organizationId: string;
  authorId: string;
  authorName: string;
  source: Source;
}) => {
  return {
    getPaymentListener: (
      paymentId: string,
      callback: GetPaymentListenerCallback
    ) =>
      getPaymentListener({
        db,
        organizationId,
        paymentId,
        callback,
      }),
    addPayment: (data: PaymentData) =>
      addPayment({
        authorId,
        authorName,
        data,
        db,
        organizationId,
        source,
      }),
    getClientPayments: (clientId: string) =>
      getClientPayments({
        db,
        organizationId,
        clientId,
      }),
    getClientPaymentsListener: (
      clientId: string,
      callback: GetClientPaymentsListenerCallback
    ) =>
      getClientPaymentsListener({
        db,
        organizationId,
        clientId,
        callback,
      }),
  };
};
