import { z } from "zod";
import { accountCustomClaims } from "../../accounts";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

const authUserSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  name: z.string(),
  claims: accountCustomClaims.optional(),
});

export type AuthUser = z.infer<typeof authUserSchema>;

export const normalizeAuthUser = (data: any): AuthUser => {
  try {
    const authUser: AuthUser = authUserSchema.parse(data);
    return authUser;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
