import {
  Category,
  CategoryData,
  CATEGORY_VERSION,
  errorResult,
  normalizeCategory,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization productCategory

interface AddProductCategory {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: CategoryData;
  }): Promise<Result<Category | null>>;
}

const addProductCategory: AddProductCategory = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new productCategory id
    const newRef = doc(
      collection(db, "organizations", organizationId, "product_categories")
    );

    const newObj: Category = normalizeCategory({
      ...data,
      id: newRef.id,
      authorId,
      authorName,
      version: CATEGORY_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newRef, newObj);

    // t("CATEGORY_CREATED")
    const successMessage = "CATEGORY_CREATED";
    return successResult({
      message: successMessage,
      payload: newObj,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetProductCategory {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Category | null>>;
}

const getProductCategory: GetProductCategory = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "product_categories",
      id
    );
    const docSanp = await getDoc(docRef);
    if (docSanp.exists()) {
      try {
        const data: unknown = docSanp.data();
        const newObj: Category = normalizeCategory(data);
        // t("CATEGORY_FOUND")
        const successMessage = "CATEGORY_FOUND";
        return successResult({
          message: successMessage,
          payload: newObj,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("CATEGORY_NOT_FOUND")
      const errorMessage = "CATEGORY_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditProductCategory {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: CategoryData;
  }): Promise<Result<Category | null>>;
}

const editProductCategory: EditProductCategory = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const obj = (await getProductCategory({ db, organizationId, id })).payload;

    if (!obj) {
      // t("CATEGORY_NOT_FOUND")
      const errorMessage = "CATEGORY_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = obj;
      const updatedProductCategory: Category = normalizeCategory({
        ...data,
        id,
        authorId,
        authorName,
        version: CATEGORY_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "product_categories",
        id
      );

      await setDoc(docRef, updatedProductCategory);

      // t("CATEGORY_UPDATED")
      const successMessage = "CATEGORY_UPDATED";
      // console.log(successMessage, updatedProductCategory);
      return successResult({
        message: successMessage,
        payload: updatedProductCategory,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteProductCategory {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteProductCategory: DeleteProductCategory = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "product_categories",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("PRODUCT_CATEGORY_DELETED")
      const successMessage = "PRODUCT_CATEGORY_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveProductCategory: EditProductCategory = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: CategoryData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editProductCategory({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("CATEGORY_ARCHIVED")
    const successMessage = "CATEGORY_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveProductCategory: EditProductCategory = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: CategoryData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editProductCategory({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("CATEGORY_DEARCHIVED")
    const successMessage = "CATEGORY_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization product-categories listener
interface GetProductCategoriesListenerCallback {
  (productCategories: Category[]): void;
}
interface GetProductCategoriesListener {
  db: Firestore;
  organizationId: string;
  callback: GetProductCategoriesListenerCallback;
}

const getProductCategoriesListener = ({
  db,
  organizationId,
  callback,
}: GetProductCategoriesListener): Unsubscribe => {
  try {
    // console.log("getProductCategoriesListener: new listener");
    const productCategoriesQuery = query(
      collection(db, "organizations", organizationId, "product_categories"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(productCategoriesQuery, (querySnapshot) => {
      const productCategories: Category[] = [];
      querySnapshot.forEach((doc) => {
        try {
          productCategories.push(normalizeCategory(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(productCategories);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const productCategories = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getProductCategoriesListener: (
      callback: GetProductCategoriesListenerCallback
    ) =>
      getProductCategoriesListener({
        db,
        organizationId,
        callback,
      }),
    getProductCategory: (id: string) =>
      getProductCategory({
        db,
        organizationId,
        id,
      }),
    addProductCategory: (data: CategoryData) =>
      addProductCategory({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editProductCategory: (id: string, data: CategoryData) =>
      editProductCategory({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteProductCategory: (id: string) =>
      deleteProductCategory({
        db,
        organizationId,
        id,
      }),
    archiveProductCategory: (id: string, data: CategoryData) =>
      archiveProductCategory({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveProductCategory: (id: string, data: CategoryData) =>
      dearchiveProductCategory({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
