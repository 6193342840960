import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const serviceProviderDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  color: z.string(),
  code: z.string().max(3),
  servicesIds: z.array(z.string()).min(1),
  membersIds: z.array(z.string()).optional(),
  supervisingDoctorId: z.string().optional(),
  supervisingDoctorName: z.string().optional(),

  // TODO: routine availability
  // TODO: additional availability
  // TODO: breaks
});

type ServiceProviderDataV0 = z.infer<typeof serviceProviderDataV0Schema>;

const serviceProviderV0Schema = baseRecordSchema.merge(
  serviceProviderDataV0Schema
);

type ServiceProviderV0 = z.infer<typeof serviceProviderV0Schema>;

const normalizeServiceProviderV0 = (data: any): ServiceProvider => {
  try {
    const serviceProviderV0: ServiceProviderV0 =
      serviceProviderV0Schema.parse(data);

    const { ...rest } = serviceProviderV0;

    // here it's same object cause current v is 0
    const serviceProvider: ServiceProvider = {
      ...rest,
    };
    return serviceProvider;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const SERVICE_PROVIDER_VERSION = "0";

export type ServiceProviderData = ServiceProviderDataV0;

export type ServiceProvider = BaseRecord & ServiceProviderData;

export const serviceProviderDataSchema = serviceProviderDataV0Schema;

export const serviceProviderSchema = serviceProviderV0Schema;

export const normalizeServiceProvider = (data: any): ServiceProvider => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeServiceProviderV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
