import {
  Button,
  Card,
  Checkbox,
  Container,
  Group,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  Client,
  getNotificationByResultType,
  Invoice,
  loadingInfoNotification,
  obju,
  Payment,
  PaymentData,
  PaymentInvoiceData,
  tu,
} from "beitary-shared";
import { BBox, MoveBackButton, TableWithSearchAndSort } from "components";
import { BMoneyInput } from "components/BMoneyInput";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";
import { ImportantInfoCard } from "../ImportantInfoCard";
import { PaymentMethodSelect } from "../PaymentMethodSelect";
import { RefundSuccessModal } from "../RefundSuccessModal";
import {
  RefundPaymentFormValue,
  RefundPaymentFormValues,
  rules,
} from "./RefundPaymentForm.rules";

export interface RefundPaymentFormProps {
  invoices: Invoice[];
  client: Client;
}

export const RefundPaymentForm = ({
  invoices,
  client,
}: RefundPaymentFormProps) => {
  useEffect(() => {
    // to update UI when new data comes
  }, [invoices, client]);

  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const { addPayment, getPaymentListener } =
    useDBServices().invoicesDBService.payments;

  const [paymentResult, setPaymentResult] = useState<
    Payment | null | undefined
  >(undefined);

  const [paymentResultId, setPaymentResultId] = useState<
    string | null | undefined
  >(undefined);

  const notificationId = "submit-payment";

  useEffect(() => {
    const updatePaymentResult = (payment: Payment | null) => {
      if (payment && payment.status === "ERROR") {
        updateNotification({
          ...getNotificationByResultType("error")({
            message: t(payment.note ?? ""),
            title: t("CHECK_OUT_REFUND"),
          }),
          id: notificationId,
        });
        setSubmitState("error");
      } else if (payment && payment.status === "SUCCESS") {
        updateNotification({
          ...getNotificationByResultType("success")({
            message: t("REFUND_PROCESSED"),
            title: t("CHECK_OUT_REFUND"),
          }),
          id: notificationId,
        });
        setSubmitState("success");
      } else if (payment && payment.status === "PROCESSING") {
        updateNotification({
          ...loadingInfoNotification({
            id: notificationId,
            message: t("REFUND_PROCESSING"),
            title: t("CHECK_OUT_REFUND"),
          }),
          id: notificationId,
        });
        setSubmitState("success");
      }

      setPaymentResult(payment);
    };

    const listener = paymentResultId
      ? getPaymentListener(paymentResultId, updatePaymentResult)
      : () => null;

    return () => {
      listener();
    };
  }, [paymentResultId, getPaymentListener, setSubmitState, t]);

  // console.log("paymentResultId");
  // console.log(paymentResultId);
  // console.log("paymentResult");
  // console.log(paymentResult);

  const items = invoices
    .map((i) => {
      let obj: RefundPaymentFormValue = {
        paymentAmount: 0,
        include: i.balanceDue < 0,
        showInput: i.balanceDue < 0,
        invoiceId: i.id,
        balanceDue: i.balanceDue,
        dateIssued: i.dateIssued,
        total: i.total,
        paid: i.paid,
        refunded: i.refunded,
        createdAt: i.createdAt,
        serialNumber: i.serialNumber,
      };
      obju.removeUndefined(obj);
      return obj;
    })
    .reduce(
      (
        obj: { [x: string]: RefundPaymentFormValue },
        item: RefundPaymentFormValue
      ) => {
        obj[item.invoiceId] = item;
        return obj;
      },
      {}
    );

  const form = useForm<RefundPaymentFormValues>({
    initialValues: {
      type: "INVOICE_REFUND",
      totalInvoicesPaymentAmounts: 0,
      creditAmount: 0,
      totalPaymentAmount: 0,
      paymentMethod: "CASH",
      payToCreditBalance: false,
      invoices: items,
      clientId: client.id,
      clientName: client.name,
      status: "PENDING",
      change: 0,
    },
    validate: rules,
  });

  // calc

  // const originalTotal = invoices.reduce(
  //   (acc, invoice) => acc + invoice.balanceDue,
  //   0
  // );

  const valuesArr = Object.values(form.values.invoices);

  const totalInvoicesPaymentAmounts = valuesArr.reduce(
    (acc, item) => acc + (item.include ? item.paymentAmount : 0),
    0
  );

  // console.log("totalInvoiceInputAmout");
  // console.log(totalInvoiceInputAmout);

  // console.log("invoicesTotal");
  // console.log(invoicesTotal);

  const disbleCheckout =
    totalInvoicesPaymentAmounts >= 0 || submitState === "success";

  // table
  const currency = org_params_util.getOrgParams().currency;

  const fields: {
    key: keyof RefundPaymentFormValue;
    label: string;
  }[] = [
    { key: "createdAt", label: t("CREATED_AT") },
    { key: "invoiceId", label: t("INVOICE_ID") },
    { key: "total", label: t("TOTAL") },
    { key: "balanceDue", label: t("REFUNDABLE_BALANCE") },
  ];

  // console.log(form.values);

  const getRow = (value: RefundPaymentFormValue) => (
    <tr key={value.invoiceId}>
      <td>
        <Text>{tu.getLocaleDateStringFromEpoch(value.createdAt)}</Text>
      </td>
      <td>{value.invoiceId.slice(0, 8).toUpperCase()}</td>
      <td>
        <Text color="red">
          {mu.toD(value.total)} {t(currency)}
        </Text>
      </td>
      <td>
        <Text color="red">
          {value.balanceDue < 0
            ? `${mu.toD(-value.balanceDue)} ${t(currency)}`
            : "-"}
        </Text>
      </td>

      <td>
        {value.showInput && (
          <Group>
            <BMoneyInput
              min={0}
              disabled={!value.include}
              // max={-value.balanceDue} useless cauz in millimes
              {...form.getInputProps(
                `invoices.${value.invoiceId}.paymentAmount`
              )}
              onChange={(v) => {
                // TODO fix me
                if (v === "") {
                  form.setFieldValue(
                    `invoices.${value.invoiceId}.paymentAmount`,
                    0
                  );
                } else {
                  form.setFieldValue(
                    `invoices.${value.invoiceId}.paymentAmount`,
                    Math.max(-v, value.balanceDue)
                  );
                }
              }}
              value={-value.paymentAmount}
            />
            <Checkbox
              {...form.getInputProps(`invoices.${value.invoiceId}.include`, {
                type: "checkbox",
              })}
            />
          </Group>
        )}
      </td>
    </tr>
  );

  const submit = async (values: RefundPaymentFormValues) => {
    const items = Object.values(values.invoices)
      .filter((i) => i.include)
      .filter((i) => i.paymentAmount !== 0);

    const totalPaymentAmount = items.reduce(
      (acc, item) => acc + item.paymentAmount,
      0
    );

    let amounts: {
      [x: string]: PaymentInvoiceData;
    } = {};

    items.forEach((i) => {
      obju.removeUndefined(i);
      amounts[i.invoiceId] = i;
    });

    const newPayment: PaymentData = {
      ...values,
      invoices: amounts,
      change:
        values.paymentMethod !== "CREDIT_BALANCE" ? -totalPaymentAmount : 0,
      creditAmount:
        values.paymentMethod === "CREDIT_BALANCE" ? -totalPaymentAmount : 0,
      totalPaymentAmount,
      payToCreditBalance: values.paymentMethod === "CREDIT_BALANCE",
      totalInvoicesPaymentAmounts,
    };

    console.log("newPayment");
    console.log(newPayment);

    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("CHECK_OUT_REFUND"),
      })
    );
    setSubmitState("pending-response");
    const result = await addPayment(newPayment);
    // console.log("result");
    // console.log(result);

    if (result.type === "success") {
      setPaymentResultId(result.payload?.id);
    } else {
      setSubmitState("error");
      setPaymentResultId(null);
      updateNotification({
        ...getNotificationByResultType(result.type)({
          message: t(result.message),
        }),
        id: notificationId,
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Container fluid>
        <ImportantInfoCard client={client} />
        <Stack mt="xl">
          <Group>
            <MoveBackButton />
          </Group>
          <Group align="flex-start">
            <BBox
              header={
                <Group>
                  <Text weight={500}>{t("NEW_REFUND")}</Text>
                </Group>
              }
            >
              <Stack
                p="xl"
                sx={{
                  flexGrow: 5,
                }}
              >
                <Group>
                  <PaymentMethodSelect
                    isRefund
                    disableCredit={false}
                    {...form.getInputProps("paymentMethod")}
                  />
                </Group>

                <Text mt="xl" weight={500}>
                  {t("INVOICES")}
                </Text>
                <TableWithSearchAndSort
                  data={valuesArr}
                  fields={fields}
                  getRow={getRow}
                  hasControlsColumn
                />
                <Textarea
                  mt="xl"
                  label={t("REFUND_NOTE")}
                  placeholder={t("ADD_A_NOTE")}
                  {...form.getInputProps("note")}
                />
              </Stack>
            </BBox>
            <Stack
              sx={{
                flexGrow: 1,
              }}
            >
              <Card withBorder radius="xs">
                <Group grow>
                  <Stack spacing={0}>
                    <Text>{t("OUTSTANDING_BALANCE")}</Text>
                    <Text color="red">
                      {mu.toD(client.outstandingBalance)} {t(currency)}
                    </Text>
                  </Stack>
                  <Stack spacing={0}>
                    <Text>{t("CREDIT_BALANCE")}</Text>
                    <Text color="green">
                      {mu.toD(client.creditBalance)} {t(currency)}
                    </Text>
                  </Stack>
                </Group>
              </Card>
              <Card withBorder radius="xs">
                <Stack w="100%">
                  <Group position="apart">
                    {" "}
                    <Text weight={500}>{t("SUMMARY")}</Text>
                    {paymentResult && paymentResult.status === "SUCCESS" && (
                      <RefundSuccessModal payment={paymentResult} />
                    )}
                  </Group>

                  <Group position="apart">
                    <Text>{t("REFUND_AMOUNT")}</Text>
                    <Text>
                      {mu.toD(-totalInvoicesPaymentAmounts)} {t(currency)}
                    </Text>
                  </Group>
                  <Button
                    disabled={disbleCheckout}
                    loading={submitState === "pending-response"}
                    type="submit"
                  >
                    {t("CHECK_OUT")}
                  </Button>
                </Stack>
              </Card>
            </Stack>
          </Group>
        </Stack>
      </Container>
    </form>
  );
};
