import {
  errorResult,
  InventoryTransaction,
  InventoryTransactionData,
  INVENTORY_TRANSACTION_VERSION,
  normalizeInventoryTransaction,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  doc,
  Firestore,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";

// add organization inventoryTransaction

interface AddInventoryTransaction {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: InventoryTransactionData;
  }): Promise<Result<InventoryTransaction | null>>;
}

const addInventoryTransaction: AddInventoryTransaction = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new inventoryTransaction id
    const newInventoryTransactionRef = doc(
      collection(db, "organizations", organizationId, "inventory_transactions")
    );

    const newInventoryTransaction: InventoryTransaction =
      normalizeInventoryTransaction({
        ...data,
        id: newInventoryTransactionRef.id,
        authorId,
        authorName,
        version: INVENTORY_TRANSACTION_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

    await setDoc(newInventoryTransactionRef, newInventoryTransaction);

    // t("INVENTORY_TRANSACTION_CREATED")
    const successMessage = "INVENTORY_TRANSACTION_CREATED";
    return successResult({
      message: successMessage,
      payload: newInventoryTransaction,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetInventoryTransactions {
  ({
    db,
    organizationId,
    invItemId,
    memberId,
    type,
  }: {
    db: Firestore;
    organizationId: string;
    invItemId?: string;
    memberId?: string;
    type?: string;
  }): Promise<Result<InventoryTransaction[] | null>>;
}

const getInventoryTransactions: GetInventoryTransactions = async ({
  db,
  organizationId,
  invItemId,
  memberId,
  type,
}) => {
  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "inventory_transactions")
    );

    // console.log(invItemId);
    // console.log(memberId);
    // console.log(type);

    if (invItemId) {
      newQuery = query(newQuery, where("invItemId", "==", invItemId));
    }

    if (memberId) {
      newQuery = query(newQuery, where("authorId", "==", memberId));
    }

    if (type) {
      newQuery = query(newQuery, where("type", "==", type));
    }

    newQuery = query(newQuery, orderBy("lastUpdatedAt", "desc"), limit(10));

    const querySnapshot = await getDocs(newQuery);

    const objs: InventoryTransaction[] = [];
    querySnapshot.forEach((doc) => {
      try {
        objs.push(normalizeInventoryTransaction(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });

    // t("INVENTORY_TRANSACTION_NOT_FOUND")
    const successMessage = "INVENTORY_TRANSACTION_NOT_FOUND";
    return successResult({
      message: successMessage,
      payload: objs,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const inventoryTransactions = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getInventoryTransactions: (queryData: {
      memberId?: string;
      invItemId?: string;
      type?: string;
    }) =>
      getInventoryTransactions({
        db,
        organizationId,
        ...queryData,
      }),
    addInventoryTransaction: (data: InventoryTransactionData) =>
      addInventoryTransaction({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
  };
};
