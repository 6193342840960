import { Anchor, Avatar, Card, Group, Text } from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { Client } from "beitary-shared";
import { Link } from "react-router-dom";

export interface ImportantInfoCardProps {
  client: Client;
}

export const ImportantInfoCard = ({ client }: ImportantInfoCardProps) => {
  const { email, phoneNumber, thumbURL } = client;

  const infoElms = [email, phoneNumber];

  let info = infoElms
    .filter((e) => !!e)
    .map((e, i) => (
      <Text size="sm" key={i}>
        {e}
      </Text>
    ));
  info = info
    .map((e, i) =>
      i < info.length - 1 ? [e, <Text key={`.${i}`}>.</Text>] : [e]
    )
    .flat();

  return (
    <Card withBorder radius="xs">
      <Group>
        <Avatar src={thumbURL} size={50} radius="xl">
          <IconPaw size={30} />
        </Avatar>
        <Anchor
          color="dark"
          size="sm"
          weight={400}
          lineClamp={1}
          component={Link}
          to={`/clients/${client.id}`}
        >
          {client.name} {<IconExternalLink size={12} />}
        </Anchor>
        {info}
      </Group>
    </Card>
  );
};
