import { Avatar, Group, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { PatientSex, PatientSpecies } from "beitary-shared";
import { BSelect } from "components";
import { selectActivePatients } from "features/Clients/Clients.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface PatientSelectProps {
  ownerId?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: ({
    id,
    name,
    thumbURL,
    sex,
    species,
    ownerId,
  }: {
    id: string;
    name: string;
    thumbURL?: string;
    sex: PatientSex;
    species: PatientSpecies;
    ownerId: string;
  }) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const PatientSelect = ({ ownerId, ...other }: PatientSelectProps) => {
  const { t } = useTranslation();
  const patients = useAppSelector(selectActivePatients).filter(
    (patient) => patient.ownerId === ownerId
  );
  const data = patients.map((patient) => ({
    value: patient.id,
    label: patient.name,
    thumbURL: patient.thumbURL,
    description: patient.species,
  }));
  const onSelectChange = (value?: string) => {
    if (value) {
      const patient = patients.find((patient) => patient.id === value);
      if (patient) {
        other.onChange({
          id: value,
          name: patient.name,
          thumbURL: patient.thumbURL,
          sex: patient.sex,
          species: patient.species,
          ownerId: patient.ownerId,
        });
      }
    }
  };

  return (
    <BSelect
      {...other}
      itemComponent={SelectItem}
      label={t("PATIENT")}
      onChange={onSelectChange}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
      limit={20}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  thumbURL: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ thumbURL, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={thumbURL} radius="xl" />

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);
