import { Box, Group, Stack, Text, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  Consultation,
  FieldInputType,
  getNotificationByResultType,
  loadingInfoNotification,
  normalizePhysicalExamEntry,
} from "beitary-shared";
import {
  BAdvancedRadioGroup,
  BBox,
  BCheckboxGroup,
  BMultiSelect,
  BSelect,
  BTextEditor,
  CancelButton,
  MoveBackButton,
  SaveButton,
} from "components";
import { selectActiveConsultationPhysicalExams } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DoctorSelect } from "../../../Summary/components/SummaryForm/components";
import {
  PhysicalExamEntryFormValues,
  rules,
} from "./PhysicalExamEntryForm.rules";

export interface PhysicalExamEntryFormProps {
  newEntry?: boolean;
  consultation: Consultation;
}

export const PhysicalExamEntryForm = ({
  newEntry,
  consultation,
}: PhysicalExamEntryFormProps) => {
  const disabled = consultation.status === "LOCKED";
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();

  const physicalExamsDBService =
    useDBServices().consultationsDBService.physicalExams(consultation.id);

  const { physicalExamId } = useParams();

  const physicalExamEntry = useAppSelector(
    selectActiveConsultationPhysicalExams
  ).find((p) => p.id === physicalExamId);

  const form = useForm<PhysicalExamEntryFormValues>({
    initialValues: physicalExamEntry
      ? { ...normalizePhysicalExamEntry(physicalExamEntry) }
      : undefined,
    validate: rules,
  });

  if (!physicalExamId || !physicalExamEntry) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  const fields = physicalExamEntry.fields;

  if (
    !form.values.supervisingDoctorId &&
    !!consultation.supervisingDoctorId &&
    !!consultation.supervisingDoctorName
  ) {
    form.setFieldValue("supervisingDoctorId", consultation.supervisingDoctorId);
    form.setFieldValue(
      "supervisingDoctorName",
      consultation.supervisingDoctorName
    );
  } // TODO this is a hack idk why it's selected by default

  const submit = async (values: PhysicalExamEntryFormValues) => {
    if (disabled) return;
    const notificationId = "submit-physical-exam";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_PHYSICAL_EXAM"),
      })
    );
    setSubmitState("pending-response");

    const result = await physicalExamsDBService.updatePhysicalExamEntry(
      physicalExamId,
      values
    );

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const updateFieldInputDefaultValue = ({
    fieldId,
    inputId,
    value,
  }: {
    fieldId: string;
    inputId: string;
    value?: string | string[];
  }) => {
    // console.log(`${fieldId} - ${inputId} - ${value}`);

    try {
      const newEntry = JSON.parse(
        JSON.stringify(form.values)
      ) as PhysicalExamEntryFormValues;

      const field = newEntry.fields.find((f) => f.id === fieldId);

      if (field) {
        const input = field.inputs.find((i) => i.id === inputId);

        if (input) {
          if (input.inputType.startsWith("TEXT")) {
            input.defaultTextValue = value as string;
          } else {
            input.defaultOptionId = value;
          }

          form.setFieldValue("fields", newEntry.fields);
        }
      }
    } catch (error: any) {
      console.log(error.message);
      // TODO find a way to show these errors
    }
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            newEntry ? (
              <Text weight={500}>{t("ADD_PHYSICAL_EXAM")}</Text>
            ) : (
              <Text weight={500}>{t("EDIT_PHYSICAL_EXAM")}</Text>
            )
          }
        >
          <Stack p="xl">
            <DoctorSelect
              disabled={disabled}
              required
              {...form.getInputProps("supervisingDoctorId")}
              onChange={({ id, name }) => {
                form.setFieldValue("supervisingDoctorId", id);
                form.setFieldValue("supervisingDoctorName", name);
              }}
            />
            {fields.map((field) => (
              <Stack key={field.id}>
                <Text weight={500}>{field.name}</Text>
                {field.inputs.map((i) => (
                  <Box key={i.id}>
                    {getInput({
                      type: i.inputType,
                      disabled: disabled,
                      options: i.options.map((o) => ({
                        value: o.id,
                        label: o.value,
                      })),
                      defaultOptionValue: i.defaultOptionId,
                      defaultTextValue: i.defaultTextValue,
                      setDefaultValue: (v) =>
                        updateFieldInputDefaultValue({
                          fieldId: field.id,
                          inputId: i.id,
                          value: v,
                        }),
                    })}
                  </Box>
                ))}
              </Stack>
            ))}
          </Stack>
        </BBox>

        <Group position="right">
          <CancelButton />
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isDirty() && form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};

const getInput = ({
  type,
  options,
  defaultOptionValue,
  defaultTextValue,
  setDefaultValue,
  disabled,
}: {
  type: FieldInputType;
  options: {
    value: string;
    label: string;
  }[];
  defaultOptionValue?: string | string[];
  defaultTextValue?: string;
  setDefaultValue: (value?: string | string[]) => void;
  disabled?: boolean;
}) => {
  switch (type) {
    case "TEXT_AREA":
      return (
        <Textarea
          disabled={disabled}
          defaultValue={defaultTextValue}
          onChange={(e) => setDefaultValue(e.target.value)}
        />
      );
    case "TEXT_EDITOR":
      return (
        <BTextEditor
          disabled={disabled}
          value={defaultTextValue}
          onChange={(v) => setDefaultValue(v)}
        />
      );
    case "TEXT_INPUT":
      return (
        <TextInput
          disabled={disabled}
          defaultValue={defaultTextValue}
          onChange={(e) => setDefaultValue(e.target.value)}
        />
      );
    case "VALUE_SELECT":
      return (
        <BSelect
          disabled={disabled}
          required
          data={options}
          value={
            defaultOptionValue ? (defaultOptionValue as string) : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );
    case "VALUE_MULTISELECT":
      return (
        <BMultiSelect
          disabled={disabled}
          required
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string[])
              : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );
    case "VALUE_CHECKBOX":
      return (
        <BCheckboxGroup
          disabled={disabled}
          required
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string[])
              : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );
    case "VALUE_RADIO":
      return (
        <BAdvancedRadioGroup
          disabled={disabled}
          data={options}
          value={
            defaultOptionValue
              ? (defaultOptionValue as unknown as string)
              : undefined
          }
          onChange={(e) => setDefaultValue(e)}
        />
      );

    default:
      return <></>;
  }
};
