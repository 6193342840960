import {
  Avatar,
  Box,
  createStyles,
  Group,
  Popover,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectMembers } from "features/admin/members/Members.slice";
import {
  selectActiveMember,
  setIsLocked,
} from "features/Authentication/AuthenticationSlice";
import { useAuthentication } from "features/Authentication/useAuthentication";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    //padding: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    color: theme.black,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

interface UserLinkProps {
  collapsed?: boolean;
}

export function UserLink({ collapsed }: UserLinkProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { name, email } = useAppSelector(selectActiveMember) ?? {};
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const account = useAppSelector(selectMembers).find(
    (member) => member.email === email
  );
  const { signOut } = useAuthentication();
  const [opened, setOpened] = useState<boolean>(false);

  const handleSignout = async () => {
    await signOut();
  };

  const handleLock = async () => {
    dispatch(setIsLocked(true));
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      //width={250}
      position="bottom"
      withArrow
    >
      <Popover.Target>
        <UnstyledButton
          data-testid="nav-userLink"
          className={classes.user}
          onClick={() => setOpened((o: boolean) => !o)}
        >
          <Group
            p={2}
            spacing={isMobile ? 8 : 12}
            my={isMobile ? 2 : "xs"}
            ml={collapsed ? 0 : "xs"}
          >
            <Avatar
              src={account?.thumbURL}
              radius="xl"
              variant="light"
              size={isMobile ? "sm" : "md"}
            >
              {account?.initials}
            </Avatar>

            {!collapsed && (
              <Box w={isMobile ? 100 : 130}>
                <div>
                  <Text size="xs" weight={500} truncate>
                    {name ?? "unknown"}
                  </Text>
                  <Text color="dimmed" size="xs" truncate>
                    {email}
                  </Text>
                </div>
              </Box>
            )}
          </Group>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <UnstyledButton data-testid="nav-signout" onClick={handleSignout}>
            {t("SIGN_OUT")}
          </UnstyledButton>
          <UnstyledButton onClick={handleLock}>
            {t("LOCK_SCREEN")}
          </UnstyledButton>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
}
