import { zodResolver } from "@mantine/form";
import { memberDataSchema } from "beitary-shared";
import { z } from "zod";

const addMemberFormSchema = memberDataSchema
  .omit({
    status: true,
  })
  .merge(
    z.object({
      hasAgreedToTOSAndPP: z.boolean(),
      isClinicLogin: z.boolean().optional(),
      remoteAccess: z.boolean().optional(),
      email: z.string().email().optional(),
      confirmEmail: z.string().email().optional(),
      password: z.string().min(6).optional(),
      confirmPassword: z.string().min(6).optional(),
    })
  )
  .refine((data) => data.hasAgreedToTOSAndPP, {
    message: "USER_MUST_AGREE_TO_TOS_AND_PP",
  })
  .refine(
    (data) =>
      data.isClinicLogin
        ? !!data.remoteAccess && !!data.email && !!data.password
        : true,
    {
      //t("EMAIL_REQUIRED")
      message: "EMAIL_REQUIRED",
      path: ["email"], // path of error
    }
  )
  .refine((data) => (data.remoteAccess ? !!data.email : true), {
    //t("EMAIL_REQUIRED")
    message: "EMAIL_REQUIRED",
    path: ["email"], // path of error
  })
  .refine((data) => data.email === data.confirmEmail, {
    //t("EMAILS_DONT_MATCH")
    message: "EMAILS_DONT_MATCH",
    path: ["confirmEmail"], // path of error
  })
  .refine((data) => (data.remoteAccess ? !!data.password : true), {
    //t("PASSWORD_REQUIRED")
    message: "PASSWORD_REQUIRED",
    path: ["password"], // path of error
  })
  .refine((data) => data.password === data.confirmPassword, {
    //t("PASSWORDS_DONT_MATCH")
    message: "PASSWORDS_DONT_MATCH",
    path: ["confirmPassword"], // path of error
  });

export type AddMemberFormValues = z.infer<typeof addMemberFormSchema>;

export const rules = zodResolver(addMemberFormSchema);
