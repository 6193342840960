import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { ImagingIntegrationProducts } from "features/admin/catalog/components";
import { BTRDLProducts } from "features/admin/catalog/components/ImagingIntegrationProducts/components";
import {
  AddBTRDLProduct,
  EditBTRDLProduct,
} from "features/admin/catalog/components/ImagingIntegrationProducts/components/BTRDLProducts/components";
import { Navigate, Outlet, Route } from "react-router-dom";

export const ImagingIntegrationProductsRoutes = (
  <Route
    path="imaging-integration-products"
    element={<ImagingIntegrationProducts />}
  >
    <Route index element={<Navigate to="btr-dl" replace />} />
    <Route path="btr-dl" element={<Outlet />}>
      <Route index element={<BTRDLProducts />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PRODUCTS_CUD]}
            element={<AddBTRDLProduct />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PRODUCTS_CUD]}
            element={<EditBTRDLProduct />}
          />
        }
      />
    </Route>
  </Route>
);
