import { Group, NumberInput, Stack, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  InventoryTransactionData,
  InventoryTransactionType,
  loadingInfoNotification,
  obju,
  tu,
} from "beitary-shared";
import { BBox, BDateAndTimeInput, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { selectActiveInventoryItems } from "features/admin/inventory/Inventory.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";
import { InventoryItemSelect } from "../InventoryItemSelect";
import { TransactionTypeSelect } from "../TransactionTypeSelect";
import { rules, TransactionFormValues } from "./TransactionForm.rules";

export const TransactionForm = () => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { addInventoryTransaction } =
    useDBServices().inventoryDBService.inventoryTransactions;

  const form = useForm<TransactionFormValues>({
    validate: rules,
  });

  if (!form.values.state) form.setFieldValue("state", "PENDING");
  if (!form.values.sourceType) form.setFieldValue("sourceType", "MANUAL");

  const items = useAppSelector(selectActiveInventoryItems);

  const submit = async (values: TransactionFormValues) => {
    const notificationId = "submit-inventory-item-transaction";

    const item = items.find((i) => i.id === values.invItemId);
    if (!item) throw new Error(t("ITEM_NOT_FOUND"));

    const plus: InventoryTransactionType[] = [
      "ADJUSTMENT_IN",
      "INVENTORY_COUNT",
      "INVENTORY_SURPLUS",
      "RETURN_CUSTOMER",
      "PRODUCT_DEADMINISTRED",
      "PURCHASE",
    ];
    const qty = plus.includes(values.type) ? values.qty : -values.qty;

    const data: InventoryTransactionData = {
      ...values,
      costPerUnit:
        values.qty <= 0
          ? 0
          : mu.multiply({
              amount: item.costPerUnitOfMeasurement,
              multiplier: values.qty / (item.units ?? 1),
            }),
      taxRate: item.taxRate,
      qty,
    };

    obju.removeUndefined(data);
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("ADD_TRANSACTION"),
      })
    );
    setSubmitState("pending-response");
    const result = await addInventoryTransaction(data);
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <Stack>
      <Group>
        <MoveBackButton />
      </Group>
      <BBox header={<Text weight={500}>{t("NEW_TRANSACTION")}</Text>}>
        <Stack p="xl">
          <form
            id="transaction-form"
            onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}
          >
            <Stack>
              <Group grow align="flex-start" spacing="xl">
                <Stack sx={{ minWidth: 300 }}>
                  <TransactionTypeSelect
                    required
                    fullList={false}
                    {...form.getInputProps("type")}
                  />
                  <InventoryItemSelect
                    required
                    {...form.getInputProps("invItemId")}
                    onChange={(v) => {
                      if (v) {
                        const item = items.find((i) => i.id === v);
                        if (item) {
                          form.setFieldValue("invItemId", item.id);
                          form.setFieldValue("invItemName", item.name);
                        }
                      }
                    }}
                  />
                  <NumberInput
                    label={t("QUANTITY")}
                    required
                    precision={2}
                    {...form.getInputProps("qty")}
                  />

                  <BDateAndTimeInput
                    maxDateTime={tu.getCurrentDateTime()}
                    required
                    label={t("DATE")}
                    {...form.getInputProps("date")}
                  />
                </Stack>
              </Group>

              <Textarea
                placeholder={t("NOTES_PLACEHOLDER")}
                label={t("NOTES")}
                {...form.getInputProps("notes")}
              />
            </Stack>
          </form>
        </Stack>
      </BBox>
      <Group position="right">
        <SaveButton
          state={submitState}
          canSave={form.isValid()}
          formId="transaction-form"
        />
      </Group>
    </Stack>
  );
};
