import { useAppSelector } from "app/hooks";
import { Reminder } from "beitary-shared";
import { BSelect } from "components";
import { selectActiveReminders } from "features/admin/client-communication/ClientCommunication.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ReminderSelectProps {
  required?: boolean;
  onChange: (rem?: Reminder) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  label?: string;
}

export const ReminderSelect = (props: ReminderSelectProps) => {
  const { t } = useTranslation();

  const items = useAppSelector(selectActiveReminders);

  const data = items.map((o) => ({
    value: o.id,
    label: t(o.name),
  }));

  const onSelectChange = (value?: string) => {
    if (value) {
      const item = items.find((i) => i.id === value);
      if (item) {
        props.onChange(item);
      } else {
        props.onChange();
      }
    } else {
      props.onChange();
    }
  };

  return (
    <BSelect
      {...props}
      data={data}
      label={props.label ?? t("REMINDER")}
      nothingFound={t("NOTHING_FOUND")}
      onChange={onSelectChange}
      clearable
    />
  );
};
