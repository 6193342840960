import { z } from "zod";

export const paymentTypeEnum = z.enum([
  "INVOICE_PAYMENT",
  "INVOICE_REFUND",
  "CREDIT_BALANCE_PAYMENT", // all paid to credit balance
  "CREDIT_BALANCE_REFUND", // withdraw from credit balance
  "PAYMENT_REFUND", // refund a payment = goodwill refund, doesnt affect balances
]);

export type PaymentType = z.infer<typeof paymentTypeEnum>;
