import { productDoseBaseEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface DoseBaseSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const DoseBaseSelect = (props: DoseBaseSelectProps) => {
  const { t } = useTranslation();
  const data = productDoseBaseEnum.options.map((o) => ({
    value: o,
    label: t(o),
  }));

  return <BSelect {...props} data={data} nothingFound={t("NOTHING_FOUND")} />;
};
