import { z } from "zod";
import {
  productConcentrationSolventUnitTypeEnum,
  productDoseBaseEnum,
} from "../../../../types-schemas/common";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { estimateStatusEnum } from "./Estimate.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const estimateProductSchema = z.object({
  id: z.string(),
  min: z.number().nonnegative(),
  max: z.number().positive(),
  name: z.string(),
  // minPrice: z.number().int().nonnegative(),
  unitSellingPrice: z.number().int().nonnegative(),
  // dispFees: z.number().int().nonnegative(),
  taxRate: z.number().nonnegative().default(0.19),
  ccSolventUnit: productConcentrationSolventUnitTypeEnum.optional(),
  dose: z.number().positive().optional(),
  doseBase: productDoseBaseEnum.optional(),
});

export type EstimateProduct = z.infer<typeof estimateProductSchema>;

const estimateDataV0Schema = z.object({
  status: estimateStatusEnum,
  products: z.array(estimateProductSchema).min(1),
  name: z.string(),
  clientId: z.string(),
  clientName: z.string(),
  patientId: z.string().optional(),
  patientName: z.string().optional(),
  patientWeight: z.number().positive().optional(),
  consultationId: z.string().nullable(),
  expirationDate: z.number().int().positive(),
  notes: z.string().optional(),
});

type EstimateDataV0 = z.infer<typeof estimateDataV0Schema>;

const estimateV0Schema = baseRecordSchema.merge(estimateDataV0Schema);

type EstimateV0 = z.infer<typeof estimateV0Schema>;

const normalizeEstimateV0 = (data: any): Estimate => {
  try {
    const estimateV0: EstimateV0 = estimateV0Schema.parse(data);

    const { ...rest } = estimateV0;

    // here it's same object cause current v is 0
    const estimate: Estimate = {
      ...rest,
    };
    return estimate;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const ESTIMATE_VERSION = "0";

export type EstimateData = EstimateDataV0;

export type Estimate = BaseRecord & EstimateData;

export const estimateDataSchema = estimateDataV0Schema;

export const estimateSchema = estimateV0Schema;

export const normalizeEstimate = (data: any): Estimate => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeEstimateV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
