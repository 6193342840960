import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";
/**
 * schema and types versions
 * from first to last
 */

// v0

const orgHoursSlotV0Schema = z.object({
  status: genericStatusEnum,
  day: z.string(),
  start: z.number().positive(),
  end: z.number().positive(),
});

type OrgHoursSlotV0 = z.infer<typeof orgHoursSlotV0Schema>;

const normalizeOrgHoursSlotV0 = (data: any): OrgHoursSlot => {
  try {
    const orgHoursSlotV0: OrgHoursSlotV0 = orgHoursSlotV0Schema.parse(data);

    const { ...rest } = orgHoursSlotV0;

    // here it's same object cause current v is 0
    const orgHoursSlot: OrgHoursSlot = {
      ...rest,
    };
    return orgHoursSlot;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const ORG_HOURS_SLOT_VERSION = "0";

export type OrgHoursSlot = OrgHoursSlotV0;

export const orgHoursSlotSchema = orgHoursSlotV0Schema;

export const normalizeOrgHoursSlot = (data: any): OrgHoursSlot => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeOrgHoursSlotV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
