import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectEmailAppointmentNotificationTemplates } from "features/admin/client-communication/ClientCommunication.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EmailAppointmentNotificationTemplateForm } from "../EmailAptNotifTemplateForm";

export const EditEmailAppointmentNotificationTemplate = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const emailAppointmentNotificationTemplate = useAppSelector(
    selectEmailAppointmentNotificationTemplates
  )
    .filter(
      (emailAppointmentNotificationTemplate) =>
        emailAppointmentNotificationTemplate.id === id
    )
    .at(0);

  if (!emailAppointmentNotificationTemplate) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return (
    <EmailAppointmentNotificationTemplateForm
      emailAppointmentNotificationTemplate={
        emailAppointmentNotificationTemplate
      }
    />
  );
};
