import {
  Anchor,
  Avatar,
  Badge,
  Box,
  Divider,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Appointment, tu } from "beitary-shared";
import {
  selectActiveAppointmentTypes,
  selectActiveServiceProviders,
} from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EditAppointmentModal } from "../EditAppointmentModal";

export interface ListViewProps {
  dayAppointments: Appointment[];
  weekAppointments: Appointment[][];
  viewType: string;
  selectedDate: number;
}

type AppointmentsListItem = Appointment & {
  patientClientName: string;
  date: string;
  aptTypeColor: string;
  servProvColor: string;
};

export const ListView = ({
  dayAppointments,
  weekAppointments,
  viewType,
  selectedDate,
}: ListViewProps) => {
  const { t } = useTranslation();
  const appointmentTypes = useAppSelector(selectActiveAppointmentTypes);
  const serviceProviders = useAppSelector(selectActiveServiceProviders);

  const getListAppointments = (
    appointments: Appointment[]
  ): AppointmentsListItem[] => {
    return appointments.map((appointment) => {
      const item: AppointmentsListItem = {
        ...appointment,
        patientClientName: appointment.patientName + appointment.clientName,
        date: `${tu.getDateAndTimeString(
          appointment.time
        )} - ${tu.getDateAndTimeString(
          appointment.time + appointment.duration * 60 * 1000
        )}`,
        aptTypeColor:
          appointmentTypes.find((t) => t.id === appointment.appointmentTypeId)
            ?.color ?? "white",
        servProvColor:
          serviceProviders.find((p) => p.id === appointment.serviceProviderId)
            ?.color ?? "white",
      };
      return item;
    });
  };

  const getRow = (appointment: AppointmentsListItem) => {
    return (
      <Group
        p={4}
        position="apart"
        key={appointment.id}
        sx={{
          backgroundColor: "white",
          // border: `1px solid ${appointment.aptTypeColor}`,
          borderRadius: 4,
        }}
      >
        <Divider
          orientation="vertical"
          size={6}
          maw="6px"
          color={appointment.aptTypeColor}
          m={6}
          sx={{
            borderRadius: 3,
          }}
        />
        <Box>
          <Stack align="center" spacing={0}>
            <Text size="sm">{`${tu.getHoursAndMinutesString(
              appointment.time
            )}`}</Text>
            <Text size="sm">{"|"}</Text>
            <Text size="sm">{`${tu.getHoursAndMinutesString(
              appointment.time + appointment.duration * 60 * 1000
            )}`}</Text>
          </Stack>
        </Box>
        <Box miw="20%">
          <Group>
            <Avatar src={appointment.patientPictureURL} size={50} radius="xl">
              <IconPaw size={30} />
            </Avatar>
            <div>
              <Anchor
                color="dark"
                weight={500}
                lineClamp={1}
                component={Link}
                to={`/clients/${appointment.clientId}/patients/${appointment.patientId}`}
              >
                {appointment.patientName} {<IconExternalLink size={12} />}
              </Anchor>
              <Anchor
                color="dark"
                weight={400}
                lineClamp={1}
                component={Link}
                to={`/clients/${appointment.clientId}`}
              >
                {appointment.clientName} {<IconExternalLink size={12} />}
              </Anchor>
            </div>
          </Group>
        </Box>
        <Box>
          <Group>
            <Text>{t(appointment.status + "_APT")}</Text>
          </Group>
        </Box>
        <Box>
          <Badge c={appointment.servProvColor}>
            <Text>{appointment.serviceProviderName}</Text>
          </Badge>
        </Box>
        <Box>
          <Text>{appointment.reasonForConsultation}</Text>
        </Box>
        <Box maw={25}>
          <EditAppointmentModal appointment={appointment} />
        </Box>
      </Group>
    );
  };

  const getList = (apts: Appointment[], i?: number) => {
    const time =
      i !== undefined
        ? tu.getDateTime(
            tu.getDate(
              tu.getStartOfWeek(tu.getDate(selectedDate)) +
                i * 24 * 60 * 60 * 1000
            )
          )
        : selectedDate;

    return (
      <Stack spacing={0}>
        <Group h={80} w="100%">
          <div>
            <Text>{tu.getDateString(time)}</Text>
            <Badge sx={{ textTransform: "lowercase" }}>
              <Text>
                {apts.length} {t("APPOINTMENTS")}
              </Text>
            </Badge>
          </div>
        </Group>
        <Stack justify="right">
          {getListAppointments(apts).map((i) => getRow(i))}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack spacing="xl">
      {viewType === "week"
        ? weekAppointments.map((apts, i) => getList(apts, i))
        : getList(dayAppointments)}
    </Stack>
  );
};
