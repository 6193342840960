import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Input,
  Stack,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  normalizeReminderSnapshot,
  obju,
  Reminder,
  ReminderSnapshot,
  ReminderSnapshotData,
  Result,
  tu,
} from "beitary-shared";
import { BDateInput, ReminderSelect, SaveButton } from "components";
import { ProductMultiSelect } from "features/admin/catalog/components/Products/components";
import { ClientSelect, PatientSelect } from "features/Clients/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import {
  ReminderSnapshotFormValues,
  rules,
} from "./ReminderSnapshotForm.rules";

export interface ReminderSnapshotFormProps {
  reminderSnapshot?: ReminderSnapshot;
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
}

export const ReminderSnapshotForm = ({
  reminderSnapshot,
  clientId,

  clientName,
  patientId,
  patientName,
}: ReminderSnapshotFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { updateReminderSnapshot, addReminderSnapshot } =
    useDBServices().clientCommunication.reminderSnapshots;

  const getInitialValues = () => {
    if (!reminderSnapshot) return undefined;
    const {
      autocompleteProductIds,
      clientId,
      clientName,
      dueDate,
      name,
      patientId,
      patientName,
      status,
      txIdComplete,
      txIdCreate,
      notifications,
      reminderId,
    } = normalizeReminderSnapshot(reminderSnapshot);
    const data: ReminderSnapshotFormValues = {
      autocompleteProductIds,
      clientId,
      clientName,
      dueDate,
      name,
      patientId,
      patientName,
      status,
      txIdComplete,
      txIdCreate,
      reminderId,
      notifications: notifications?.map((v) => ({
        id: id_util.newId20(),
        value: tu.getProductReminderNotifDateEpoch(v),
      })),
    };
    obju.removeUndefined(data);
    return data;
  };

  const form = useForm<ReminderSnapshotFormValues>({
    initialValues: getInitialValues(),
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "CREATED");
  if (!form.values.patientId && patientId && patientName)
    form.setValues({
      patientId,
      patientName,
    });
  if (!form.values.clientId && clientId && clientName)
    form.setValues({ clientId, clientName });

  const submit = async (values: ReminderSnapshotFormValues) => {
    const data: Omit<ReminderSnapshotData, "organizationId"> = {
      ...values,
      notifications: values.notifications?.map((v) =>
        tu.getProductReminderNotifDateString(v.value)
      ),
    };

    console.log(data);

    obju.removeUndefined(data);
    const notificationId = "submit-reminderSnapshot";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("ADD_REMINDER")
        // t("UPDATE_REMINDER")
        title: t(reminderSnapshot ? "UPDATE_REMINDER" : "ADD_REMINDER"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (reminderSnapshot) {
      result = await updateReminderSnapshot(reminderSnapshot.id, data);
    } else {
      result = await addReminderSnapshot(data);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };
  console.log(form.values);

  const onReminderChange = (v: Reminder | undefined): void => {
    if (!v) {
      form.setValues({
        reminderId: undefined,
      });
    } else {
      const dueDate = tu.calculateNextDateTime({
        timeSinceEpoch: tu.getCurrentDateTime(),
        dueIn: v.dueIn,
        timeUnit: v.timeUnit,
      });
      form.setValues({
        reminderId: v.id,
        name: v.name,
        dueDate,
        autocompleteProductIds: v.autocompleteProductIds,
        notifications: v.notifications
          ? v.notifications.map((n) => ({
              value: tu.calculateNextDateTime({
                timeSinceEpoch: dueDate,
                dueIn: n.beforeAfter === "AFTER" ? n.sendIn : -n.sendIn,
                timeUnit: n.timeUnit,
              }),
              id: id_util.newId20(),
            }))
          : undefined,
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack p="xl">
        <ReminderSelect
          value={form.values.reminderId}
          onChange={onReminderChange}
          label={t("TEMPLATE")}
        />
        <Divider />
        <Group grow>
          <TextInput
            required
            label={t("NAME")}
            {...form.getInputProps("name")}
          />
          <BDateInput
            required
            label={t("DUE_DATE")}
            {...form.getInputProps("dueDate")}
          />
        </Group>
        <Group grow>
          <ClientSelect
            required
            {...form.getInputProps("clientId")}
            onChange={(v) => {
              form.setValues({
                clientId: v.id,
                clientName: v.name,
                patientId: undefined,
                patientName: undefined,
              });
            }}
          />
          <PatientSelect
            required
            ownerId={form.getInputProps("clientId").value}
            {...form.getInputProps("patientId")}
            onChange={({ id, name }) => {
              form.setValues({
                patientId: id,
                patientName: name,
              });
            }}
          />
        </Group>
        <ProductMultiSelect
          key={(form.values.autocompleteProductIds ?? []).join()} // this is to force refresh, otherwise new values not displayed
          required
          limit={5}
          maxSelectedValues={5}
          label={t("AUTO_COMPLETE_PRODUCTS")}
          {...form.getInputProps("autocompleteProductIds")}
        />
        <Input.Wrapper label={t("NOTIFICATIONS")}>
          <Stack pt="md">
            {form.values.notifications &&
              form.values.notifications.map((v, index) => (
                <Group key={v.id}>
                  <BDateInput
                    required
                    label={t("SEND_AT")}
                    {...form.getInputProps(`notifications.${index}.value`)}
                  />
                  <ActionIcon
                    onClick={() =>
                      form.setFieldValue(
                        "notifications",
                        (form.values.notifications ?? []).filter(
                          (elem) => elem.id !== v.id
                        )
                      )
                    }
                  >
                    <IconTrash size={18} />
                  </ActionIcon>
                </Group>
              ))}
            <Group>
              <Button
                onClick={() => {
                  form.values.notifications
                    ? form.values.notifications.length < 5 &&
                      form.setFieldValue("notifications", [
                        ...form.values.notifications,
                        {
                          id: id_util.newId20(),
                          value: tu.getCurrentDateTime(),
                        },
                      ])
                    : form.setFieldValue("notifications", [
                        {
                          id: id_util.newId20(),
                          value: tu.getCurrentDateTime(),
                        },
                      ]);
                }}
              >
                {t("ADD_NOTIFICATION")}
              </Button>
            </Group>
          </Stack>
        </Input.Wrapper>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
