import { zodResolver } from "@mantine/form";
import { phone_utils } from "beitary-shared";
import { z } from "zod";

const editClientFormSchema = z
  .object({
    name: z.string(),
    taxID: z.string().optional(),
    email: z.string().email().optional(),
    phoneNumber: z.string().optional(),
    phoneNumber_countryCode: z.string().optional(),
    thumbURL: z.string().url().optional(),
    discountId: z.string().optional(),
    alert: z.string().optional(),
    additionalInfo: z.string().optional(),
    emailNotif: z.boolean().optional(),
    smsNotif: z.boolean().optional(),
    // address
    address_Line1: z.string().optional(),
    address_Line2: z.string().optional(),
    address_city: z.string().optional(),
    address_stateOrProvince: z.string().optional(),
    address_postalCode: z.string().optional(),
    address_countryCode: z.string().optional(),
    additionalContactInfo: z
      .array(
        z.object({
          id: z.string(),
          type: z.enum(["EMAIL", "PHONE_NUMBER"]),
          description: z.string(),
        })
      )
      .optional(),
  })
  .refine(
    (data) =>
      data.phoneNumber && data.phoneNumber_countryCode
        ? phone_utils.parse(data.phoneNumber, data.phoneNumber_countryCode)
        : true,
    {
      // t("INVALID_PHONE_NUMBER")
      message: "INVALID_PHONE_NUMBER",
      path: ["phoneNumber"],
    }
  );

export type EditClientFormValues = z.infer<typeof editClientFormSchema>;

export const rules = zodResolver(editClientFormSchema);
