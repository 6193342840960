import { Container, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { InvoicesTable } from "./components";

export const View = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  return (
    <Container fluid>
      <Title mt="xl" mb="xl" weight={500} order={3}>
        {t("INVOICES")}
      </Title>
      <InvoicesTable />
    </Container>
  );
};
