import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectActiveConsultationPrescriptions } from "features/consultations/Consultations.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PrecriptionForm } from "../PrecriptionForm";

export interface EditPrescriptionProps {
  consultationId: string;
  clientId: string;
  clientName: string;
  patientId: string;
  patientName: string;
  disabled: boolean;
}

export const EditPrescription = (props: EditPrescriptionProps) => {
  const { t } = useTranslation();
  const { prescriptionId } = useParams();

  const prescription = useAppSelector(selectActiveConsultationPrescriptions)
    .filter((prescription) => prescription.id === prescriptionId)
    .at(0);

  if (!prescription) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <PrecriptionForm {...props} prescription={prescription} />;
};
