import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectServiceProviders } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ServiceProviderForm } from "../ServiceProviderForm";

export const EditServiceProvider = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const serviceProvider = useAppSelector(selectServiceProviders)
    .filter((serviceProvider) => serviceProvider.id === id)
    .at(0);

  if (!serviceProvider) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <ServiceProviderForm serviceProvider={serviceProvider} />;
};
