import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { clinicLocations } from "./locations";

export const clinicSettings = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    clinicLocations: clinicLocations(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
  };
};
