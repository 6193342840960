import {
  errorResult,
  normalizeReminder,
  Reminder,
  ReminderData,
  REMINDER_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

interface AddReminder {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: ReminderData;
  }): Promise<Result<boolean | null>>;
}

const addReminder: AddReminder = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new reminder id
    const newReminderRef = doc(
      collection(db, "organizations", organizationId, "reminders")
    );

    // console.log(newReminderRef);

    const newReminder: Reminder = normalizeReminder({
      ...data,
      id: newReminderRef.id,
      authorId,
      authorName,
      version: REMINDER_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newReminderRef, newReminder);

    // t("REMINDER_CREATED")
    const successMessage = "REMINDER_CREATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetReminder {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Reminder | null>>;
}

const getReminder: GetReminder = async ({ db, organizationId, id }) => {
  try {
    const reminderDocRef = doc(
      db,
      "organizations",
      organizationId,
      "reminders",
      id
    );
    const reminderDocSnapshot = await getDoc(reminderDocRef);
    if (reminderDocSnapshot.exists()) {
      try {
        const data: unknown = reminderDocSnapshot.data();
        const reminder: Reminder = normalizeReminder(data);
        const successMessage = "Reminder found!";
        return successResult({
          message: successMessage,
          payload: reminder,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "Reminder not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface DeleteReminder {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteReminder: DeleteReminder = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "reminders", id);

    try {
      await deleteDoc(docRef);
      // t("REMINDER_DELETED")
      const successMessage = "REMINDER_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateReminder {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<ReminderData>;
  }): Promise<Result<boolean | null>>;
}

const updateReminder: UpdateReminder = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "reminders", id);

    const updates: Partial<Reminder> = {
      ...data,
      authorId,
      authorName,
      version: REMINDER_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("REMINDER_UPDATED")
    const successMessage = "REMINDER_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization reminders listener
interface GetRemindersListenerCallback {
  (reminders: Reminder[]): void;
}
interface GetRemindersListener {
  db: Firestore;
  organizationId: string;
  callback: GetRemindersListenerCallback;
}

const getRemindersListener = ({
  db,
  organizationId,
  callback,
}: GetRemindersListener): Unsubscribe => {
  try {
    // console.log("getRemindersListener: new listener");
    const remindersQuery = query(
      collection(db, "organizations", organizationId, "reminders")
    );
    return onSnapshot(remindersQuery, (querySnapshot) => {
      const reminders: Reminder[] = [];
      querySnapshot.forEach((doc) => {
        reminders.push(normalizeReminder(doc.data()));
      });
      callback(reminders);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const reminders = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    addReminder: (data: ReminderData) =>
      addReminder({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    getRemindersListener: (callback: GetRemindersListenerCallback) =>
      getRemindersListener({
        db,
        organizationId,
        callback,
      }),
    getReminder: (id: string) =>
      getReminder({
        db,
        organizationId,
        id,
      }),
    updateReminder: (id: string, data: Partial<ReminderData>) =>
      updateReminder({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteReminder: (id: string) =>
      deleteReminder({
        db,
        organizationId,
        id,
      }),
    archiveReminder: (id: string) =>
      updateReminder({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchiveReminder: (id: string) =>
      updateReminder({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
