import {
  Button,
  Card,
  Group,
  Input,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { getNotificationByResultType, Treatment, tu } from "beitary-shared";
import { AsyncActionIcon, BTextEditor } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { BCan } from "permissions";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface TxCardProps {
  treatment: Treatment;
  disabled: boolean;
}

export const TxCard = ({ treatment, disabled }: TxCardProps) => {
  const [addNoteState, setAddNoteState] = useSubmitState();
  const { t } = useTranslation();

  const txDBService = useDBServices().consultationsDBService.treatments(
    treatment.consultationId
  );

  const [note, setNote] = useState(treatment.productSnapshot.mrNotes ?? "");

  const treatmentNotes = Object.values(treatment.medicalRecordNotes ?? {}).sort(
    (a, b) => a.createdAt - b.createdAt
  );

  const deleteNote = async (id: string) => {
    if (disabled) return;
    await txDBService.deleteNote({ noteId: id, treatmentId: treatment.id });
  };

  const addNote = async () => {
    if (disabled) return;
    setAddNoteState("pending-response");
    const result = await txDBService.addNote({
      noteData: { content: note },
      treatmentId: treatment.id,
    });

    if (result && result.type === "success") {
      setAddNoteState("success");
    } else {
      setAddNoteState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
  };

  return (
    <Stack p="md">
      <SimpleGrid cols={3}>
        <div>
          <Text color="cyan">{t("ADMINISTRATION_INSTRUCTIONS")}</Text>
          <Text>
            {HTMLReactParser(treatment.productSnapshot.adminInstr ?? "-")}
          </Text>
        </div>
        <div>
          <Text color="cyan">{t("ADMINISTRATION_ROUTES")}</Text>
          <Text>
            {treatment.adminRoutes
              ? treatment.adminRoutes.map((r) => r).join(" - ")
              : "-"}
          </Text>
        </div>
        <div>
          <Text color="cyan">{t("ADMINISTRED_BY")}</Text>
          <Text>{treatment.adminBy ?? "-"}</Text>
        </div>
        {treatment.type === "PRN" && (
          <div>
            <Text color="cyan">{t("PRIORITY")}</Text>
            <Text>{treatment.priority ?? "-"}</Text>
          </div>
        )}
        {treatment.type === "STAT" && (
          <div>
            <Text color="cyan">{t("TIME_DUE")}</Text>
            <Text>
              {treatment.timeDue
                ? tu.getDateAndTimeString(treatment.timeDue)
                : "-"}
            </Text>
          </div>
        )}
        <div>
          <Text color="cyan">{t("SERIAL_NUMBER")}</Text>
          <Text>{treatment.serialNumber ?? "-"}</Text>
        </div>
        <div>
          <Text color="cyan">{t("LOT_NUMBER")}</Text>
          <Text>{treatment.lotNumber ?? "-"}</Text>
        </div>
        <div>
          <Text color="cyan">{t("LOT_EXPIRATION_DATE")}</Text>
          <Text>
            {treatment.lotExpirationDate
              ? tu.getDateString(treatment.lotExpirationDate)
              : "-"}
          </Text>
        </div>
      </SimpleGrid>
      <BCan I="am" a="DOCTOR">
        {treatmentNotes.map((n) => (
          <Card withBorder radius="xs" p="xs">
            <Group position="apart" align="flex-start">
              <Stack spacing={0}>
                <Text>{HTMLReactParser(n.content)}</Text>
                <Text color="dimmed" size="sm">
                  {`${t("CREATED_BY")} ${n.authorName} ${t(
                    "ON"
                  )} ${tu.getDateAndTimeString(n.createdAt)}`}
                </Text>
              </Stack>
              <AsyncActionIcon
                disabled={disabled}
                icon={<IconTrash size={18} />}
                f={async () => deleteNote(n.id)}
              />
            </Group>
          </Card>
        ))}
        <Input.Wrapper label={t("NOTES")}>
          <BTextEditor disabled={disabled} value={note} onChange={setNote} />
        </Input.Wrapper>
        <Group position="right">
          <Button
            onClick={addNote}
            disabled={disabled || note.length === 0 || note === "<p></p>"}
            loading={addNoteState === "pending-response"}
            leftIcon={<IconPlus size={18} />}
          >
            {t("ADD_NOTE")}
          </Button>
        </Group>
      </BCan>
    </Stack>
  );
};
