import { Button, Modal } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientForm } from "../PatientForm";

export interface QuickPatientProps {
  clientId: string;
  clientName: string;
  disabled?: boolean;
}

export const QuickPatient = (props: QuickPatientProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <PatientForm quick {...props} />
      </Modal>
      <Button compact disabled={props.disabled} onClick={() => setOpened(true)}>
        {t("NEW_PATIENT")}
      </Button>
    </>
  );
};
