import {
  Box,
  Center,
  Group,
  Input,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  loadingInfoNotification,
} from "beitary-shared";
import {
  BAdvancedRadioGroup,
  BBox,
  BTextEditor,
  CancelButton,
  MoveBackButton,
  SaveButton,
} from "components";
import { selectActiveConsultationVitals } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { org_params_util } from "utils/org_params_utils";
import { rules, VitalsEntryFormValues } from "./VitalsEntryForm.rules";

export interface VitalsEntryFormProps {
  newEntry?: boolean;
  disabled?: boolean;
  consultationId: string;
}

export const VitalsEntryForm = ({
  newEntry,
  consultationId,
  disabled,
}: VitalsEntryFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const vitalsDBService =
    useDBServices().consultationsDBService.vitals(consultationId);

  const { vitalId } = useParams();

  const vitalEntry = useAppSelector(selectActiveConsultationVitals).find(
    (v) => v.id === vitalId
  );

  const tempUnit = org_params_util.getOrgParams().temperatureUnit;

  const form = useForm<VitalsEntryFormValues>({
    initialValues: vitalEntry ? { ...vitalEntry } : undefined,
    validate: rules,
  });

  if (!vitalId || !vitalEntry) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  if (!form.values.capRefTime) form.setFieldValue("capRefTime", "<2s"); // TODO this is a hack idk why it's selected by default

  const submit = async (values: VitalsEntryFormValues) => {
    if (disabled) return;
    const notificationId = "submit-vital";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_CONSULTATION"),
      })
    );
    setSubmitState("pending-response");

    const result = await vitalsDBService.updateVitalsEntry(vitalId, values);

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            newEntry ? (
              <Text weight={500}>{t("ADD_VITAL")}</Text>
            ) : (
              <Text weight={500}>{t("EDIT_VITAL")}</Text>
            )
          }
        >
          <Stack p="xl">
            <Group>
              <Box w={200}>
                <Input.Wrapper label={t("CAPILLARY_REFILL_TIME")}>
                  <BAdvancedRadioGroup
                    disabled={disabled}
                    data={[
                      {
                        label: (
                          <Center w={40} h={40}>
                            {t("<2s")}
                          </Center>
                        ),
                        value: "<2s",
                      },
                      {
                        label: (
                          <Center w={40} h={40}>
                            {t("=2s")}
                          </Center>
                        ),
                        value: "=2s",
                      },
                      {
                        label: (
                          <Center w={40} h={40}>
                            {t(">2s")}
                          </Center>
                        ),
                        value: ">2s",
                      },
                    ]}
                    {...form.getInputProps("capRefTime")}
                  />
                </Input.Wrapper>
              </Box>
            </Group>
            <Group grow>
              <NumberInput
                disabled={disabled}
                label={t("TEMPERATURE")}
                precision={2}
                rightSection={
                  <Text size="xs" mr="xs">
                    {t(tempUnit)}
                  </Text>
                }
                {...form.getInputProps("temp")}
              />
              <NumberInput
                disabled={disabled}
                label={t("PULSE")}
                rightSection={
                  <Text size="xs" mr="xs">
                    {t("BPS")}
                  </Text>
                }
                {...form.getInputProps("pulse")}
              />
              <NumberInput
                disabled={disabled}
                label={t("RESPIRATORY_RATE")}
                rightSection={
                  <Text size="xs" mr="xs">
                    {t("BPM")}
                  </Text>
                }
                {...form.getInputProps("resp")}
              />
            </Group>
            <Group grow>
              <TextInput
                disabled={disabled}
                label={t("MUCUS_MEMBRANE_COLOR")}
                {...form.getInputProps("mucusColor")}
              />

              <TextInput
                disabled={disabled}
                label={t("MENTATION")}
                {...form.getInputProps("mentation")}
              />
            </Group>

            <Group grow>
              <Input.Wrapper label={t("NOTES")}>
                <BTextEditor
                  disabled={disabled}
                  {...form.getInputProps("notes")}
                />
              </Input.Wrapper>
            </Group>
          </Stack>
        </BBox>

        <Group position="right">
          <CancelButton />
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isDirty() && form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
