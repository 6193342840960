export class UnableToNormalizeData extends Error {
  constructor({ error, data }: { error: any; data: any }) {
    super(
      `Unable to normalize data:\n${JSON.stringify({ error, data }, null, 4)}`
    );
    // https://stackoverflow.com/questions/68899615/how-to-expect-a-custom-error-to-be-thrown-with-jest
    Object.setPrototypeOf(this, UnableToNormalizeData.prototype);
    this.name = "Unable To Normalize Data";
  }
}
