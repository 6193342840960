import {
  ActionIcon,
  Box,
  Button,
  Code,
  Group,
  Input,
  List,
  NumberInput,
  Paper,
  Radio,
  Select,
  Stack,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  ant_utils,
  appointmentNotificationTimeUnitTimeUnitEnum,
  EmailAppointmentNotificationTemplate,
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  normalizeEmailAppointmentNotificationTemplate,
  Result,
  tu,
} from "beitary-shared";
import {
  AppointmentTypeMultiSelect,
  BBox,
  BTextEditor,
  SaveButton,
} from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { selectActiveUser } from "features/Authentication/AuthenticationSlice";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import {
  EmailAppointmentNotificationTemplateFormValues,
  rules,
} from "./EmailAptNotifTemplateForm.rules";

export interface EmailAppointmentNotificationTemplateFormProps {
  emailAppointmentNotificationTemplate?: EmailAppointmentNotificationTemplate;
}

export const EmailAppointmentNotificationTemplateForm = ({
  emailAppointmentNotificationTemplate,
}: EmailAppointmentNotificationTemplateFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const {
    updateEmailAppointmentNotificationTemplate,
    addEmailAppointmentNotificationTemplate,
  } =
    useDBServices().clientCommunication.emailAppointmentNotificationsTemplates;

  const org = useAppSelector(selectOrganization);
  const user = useAppSelector(selectActiveUser);

  const form = useForm<EmailAppointmentNotificationTemplateFormValues>({
    initialValues: emailAppointmentNotificationTemplate
      ? normalizeEmailAppointmentNotificationTemplate(
          emailAppointmentNotificationTemplate
        )
      : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (
    values: EmailAppointmentNotificationTemplateFormValues
  ) => {
    const notificationId = "submit-emailAppointmentNotificationTemplate";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("ADD_TEMPLATE")
        // t("UPDATE_TEMPLATE")
        title: t(
          emailAppointmentNotificationTemplate
            ? "UPDATE_TEMPLATE"
            : "ADD_TEMPLATE"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (emailAppointmentNotificationTemplate) {
      result = await updateEmailAppointmentNotificationTemplate(
        emailAppointmentNotificationTemplate.id,
        values
      );
    } else {
      result = await addEmailAppointmentNotificationTemplate(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  console.log(form.values);

  const getContentPreviewText = () => {
    const aptDate = tu.getDateString(tu.getCurrentDateTime());
    const aptTime = tu.getHoursAndMinutesString(tu.getCurrentDateTime());
    const aptType = t("VACCINE");
    const client = user?.displayName ?? "";
    const patient = "Rex";
    const clinicAddress = [
      org?.address_Line1,
      org?.address_Line2,
      org?.address_postalCode,
      org?.address_city,
      org?.address_stateOrProvince,
    ]
      .filter((i) => i !== undefined && i !== "")
      .join(", ");
    return ant_utils.generateAptNotifHTML(
      form.values.notificationContent ?? "",
      {
        aptDate,
        aptTime,
        aptType,
        client,
        patient,
        clinicAddress,
        clinicEmail: org?.email ?? "",
        clinicLogoURL: org?.thumbURL ?? "",
        clinicName: org?.name ?? "",
        clinicPhone: org?.phoneNumber ?? "",
      }
    );
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            emailAppointmentNotificationTemplate ? (
              <Text weight={500}>{t("EDIT_TEMPLATE")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_TEMPLATE")}</Text>
            )
          }
        >
          {/* "APT_BOOKED",
  "APT_RESCHEDULED",
  "APT_CANCELED",
  "APT_REMINDER", */}
          <Stack p="xl">
            <Radio.Group
              required
              // label={t("TYPE")}
              {...form.getInputProps("type")}
            >
              <Group m="xl">
                <Radio
                  value="APT_REMINDER"
                  label={t("APT_REMINDER_NOTIFICATION")}
                />
                <Radio
                  value="APT_BOOKED"
                  label={t("APT_BOOKED_NOTIFICATION")}
                />
                <Radio
                  value="APT_RESCHEDULED"
                  label={t("APT_RESCHEDULED_NOTIFICATION")}
                />
                <Radio
                  value="APT_CANCELED"
                  label={t("APT_CANCELED_NOTIFICATION")}
                />
              </Group>
            </Radio.Group>
            <TextInput
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />{" "}
            <AppointmentTypeMultiSelect
              required
              {...form.getInputProps("aptTypeIds")}
              onChange={(v) => {
                if (v) form.setFieldValue("aptTypeIds", v);
              }}
            />
            {form.values.type === "APT_REMINDER" && (
              <Input.Wrapper label={t("NOTIFICATIONS")}>
                <Stack pt="md">
                  {form.values.notifications &&
                    form.values.notifications.map((v, index) => (
                      <Group key={v.id}>
                        <NumberInput
                          required
                          min={1}
                          label={t("SEND_IN")}
                          {...form.getInputProps(
                            `notifications.${index}.sendIn`
                          )}
                        />
                        <Select
                          required
                          label={t("TIME_UNIT")}
                          data={appointmentNotificationTimeUnitTimeUnitEnum.options.map(
                            (o) => ({
                              value: o,
                              label: t(o),
                            })
                          )}
                          {...form.getInputProps(
                            `notifications.${index}.timeUnit`
                          )}
                        />
                        <Select
                          required
                          label={t("BEFORE_AFTER")}
                          data={["BEFORE", "AFTER"].map((o) => ({
                            value: o,
                            label: t(o),
                          }))}
                          {...form.getInputProps(
                            `notifications.${index}.beforeAfter`
                          )}
                        />
                        <ActionIcon
                          onClick={() =>
                            form.setFieldValue(
                              "notifications",
                              (form.values.notifications ?? []).filter(
                                (elem) => elem.id !== v.id
                              )
                            )
                          }
                        >
                          <IconTrash size={18} />
                        </ActionIcon>
                      </Group>
                    ))}
                  <Group>
                    <Button
                      onClick={() => {
                        form.values.notifications
                          ? form.values.notifications.length < 5 &&
                            form.setFieldValue("notifications", [
                              ...form.values.notifications,
                              {
                                id: id_util.newId20(),
                                beforeAfter: "BEFORE",
                                sendIn: 1,
                                timeUnit: "MONTH",
                              },
                            ])
                          : form.setFieldValue("notifications", [
                              {
                                id: id_util.newId20(),
                                beforeAfter: "BEFORE",
                                sendIn: 1,
                                timeUnit: "MONTH",
                              },
                            ]);
                      }}
                    >
                      {t("ADD_NOTIFICATION")}
                    </Button>
                  </Group>
                </Stack>
              </Input.Wrapper>
            )}
            <Group align="flex-start" position="apart">
              <Box w="65%">
                <Input.Wrapper label={t("NOTIFICATION_CONTENT")} required>
                  <Tabs variant="default" defaultValue="form" mt="sm">
                    <Tabs.List>
                      <Tabs.Tab value="form">{t("EDIT")}</Tabs.Tab>
                      <Tabs.Tab value="preview">{t("PREVIEW")}</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="form" pt="xs">
                      <BTextEditor
                        {...form.getInputProps("notificationContent")}
                      />
                    </Tabs.Panel>

                    <Tabs.Panel value="preview" pt="xs">
                      <Paper p="xl">
                        <Text> {HTMLReactParser(getContentPreviewText())}</Text>
                      </Paper>
                    </Tabs.Panel>
                  </Tabs>{" "}
                </Input.Wrapper>
              </Box>
              <Box w="30%" mt="lg">
                <Text mb="xl" size="sm">
                  {t("EMAIL_NOTIF_TEMPLATE_CONTENT_EXPLAINER")}
                </Text>
                <List size="sm" withPadding>
                  <List.Item>
                    <Code color="cyan">{"{PATIENT}"}</Code> : {t("PATIENT")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLIENT}"}</Code> : {t("CLIENT")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{APT_TIME}"}</Code> : {t("APT_TIME")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{APT_DATE}"}</Code> : {t("APT_DATE")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{APT_TYPE}"}</Code> : {t("APT_TYPE")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_NAME}"}</Code> :{" "}
                    {t("CLINIC_NAME")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_EMAIL}"}</Code> :{" "}
                    {t("CLINIC_EMAIL")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_PHONE}"}</Code> :{" "}
                    {t("CLINIC_PHONE")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_ADDRESS}"}</Code> :{" "}
                    {t("CLINIC_ADDRESS")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_LOGO}"}</Code> :{" "}
                    {t("CLINIC_LOGO")}
                  </List.Item>
                </List>
              </Box>
            </Group>
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
