import { Card, Stack, Text, Timeline, useMantineTheme } from "@mantine/core";
import { IconFile, IconStethoscope } from "@tabler/icons-react";
import { SConsultation, SFile, SPrescription, tu } from "beitary-shared";
import { useTranslation } from "react-i18next";
import { LifecycleItem } from "./components";

type SortedItem = {
  item: SConsultation | SFile | SPrescription;
  type: "consultation" | "file" | "prescription";
};

export interface PatientLifecycleProps {
  lifecycle: SortedItem[];
}

export const PatientLifecycle = ({ lifecycle }: PatientLifecycleProps) => {
  const theme = useMantineTheme();
  const { t } = useTranslation();

  if (lifecycle.length === 0) return <Text>{t("NOTHING_FOUND")}</Text>;

  return (
    <Stack>
      <Timeline active={lifecycle.length} bulletSize={26} lineWidth={3}>
        {lifecycle.map((i) => {
          const dateString = tu.getDateAndTimeString(i.item.lifecycleDate);

          return (
            <Timeline.Item
              title={
                <Text size="xs" weight={600} color="dark">
                  {dateString}
                </Text>
              }
              key={i.item.id}
              mb="xl"
              // mt="xl"
              //   lineVariant="dotted"
              bullet={
                i.type === "consultation" ? (
                  <IconStethoscope size="1rem" />
                ) : i.type === "file" ? (
                  <IconFile size="1rem" />
                ) : (
                  <IconStethoscope size="1rem" />
                )
              }
            >
              <Card
                mt="md"
                sx={{ border: `1px solid ${theme.colors.gray[3]}` }}
              >
                <LifecycleItem sortedItem={i} />
              </Card>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Stack>
  );
};
