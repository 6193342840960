import {
  errorResult,
  ImagingDiagnosticItem,
  ImagingDiagnosticItemData,
  IMAGING_DIAGNOSTIC_ITEM_VERSION,
  normalizeImagingDiagnosticItem,
  obju,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import { Unsubscribe } from "firebase/auth";
import {
  collection,
  doc,
  Firestore,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// get organization bSettings listener
interface GetImagingDiagnosticItemsListenerCallback {
  (items: ImagingDiagnosticItem[]): void;
}
interface GetImagingDiagnosticItemsListener {
  db: Firestore;
  organizationId: string;
  callback: GetImagingDiagnosticItemsListenerCallback;
}

const getImagingDiagnosticItemsListener = ({
  db,
  organizationId,
  callback,
}: GetImagingDiagnosticItemsListener): Unsubscribe => {
  console.log(organizationId);

  try {
    // console.log("getImagingDiagnosticItemsListener: new listener");
    const itemsQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "imaging_diagnostic_items"
      ),
      orderBy("createdAt", "desc")
    );
    return onSnapshot(itemsQuery, (querySnapshot) => {
      const items: ImagingDiagnosticItem[] = [];
      querySnapshot.forEach((doc) => {
        items.push(normalizeImagingDiagnosticItem(doc.data()));
      });
      callback(items);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

/**
 * log root boundary error
 */

interface AddImagingDiagnosticItem {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    id: string;
    data: ImagingDiagnosticItemData;
  }): Promise<Result<boolean | null>>;
}

const addImagingDiagnosticItem: AddImagingDiagnosticItem = async ({
  db,
  organizationId,
  source,
  data,
  authorId,
  authorName,
  id,
}) => {
  try {
    const newId = id;
    const newItemRef = doc(
      db,
      "organizations",
      organizationId,
      "imaging_diagnostic_items",
      newId
    );

    // console.log(newErrorLogRef);

    const newItem: ImagingDiagnosticItem = normalizeImagingDiagnosticItem({
      ...data,
      id: newId,
      authorId,
      authorName,
      version: IMAGING_DIAGNOSTIC_ITEM_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    obju.removeUndefined(newItem);

    await setDoc(newItemRef, newItem);

    const successMessage = "SUCCESS";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface UpdateImagingDiagnosticItem {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<ImagingDiagnosticItemData>;
  }): Promise<Result<boolean | null>>;
}

const updateImagingDiagnosticItem: UpdateImagingDiagnosticItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "imaging_diagnostic_items",
      id
    );

    const updates: Partial<ImagingDiagnosticItem> = {
      ...data,
      authorId,
      authorName,
      version: IMAGING_DIAGNOSTIC_ITEM_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("IMAGING_DIAGNOSTIC_ITEM_UPDATED")
    const successMessage = "IMAGING_DIAGNOSTIC_ITEM_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

export const imagingDiagnostics = ({
  authorId,
  authorName,
  db,
  organizationId,
  source,
}: {
  db: Firestore;
  organizationId: string;
  authorId: string;
  authorName: string;
  source: Source;
}) => ({
  getImagingDiagnosticItemsListener: (
    callback: GetImagingDiagnosticItemsListenerCallback
  ) =>
    getImagingDiagnosticItemsListener({
      db,
      callback,
      organizationId,
    }),

  addImagingDiagnosticItem: (id: string, data: ImagingDiagnosticItemData) =>
    addImagingDiagnosticItem({
      db,
      organizationId,
      authorId,
      authorName,
      source,
      data,
      id,
    }),

  updateMappedConsultation: ({
    consultationId,
    id,
    clientId,
    clientName,
    patientId,
    patientName,
  }: {
    consultationId: string;
    clientId: string;
    clientName: string;
    patientId: string;
    patientName: string;
    id: string;
  }) =>
    updateImagingDiagnosticItem({
      db,
      organizationId,
      authorId,
      authorName,
      source,
      data: {
        consultationId,
        clientId,
        clientName,
        patientId,
        patientName,
        status: "ORPHANED_MAPPED",
      },
      id,
    }),

  cancelImagingDiagnosticItem: ({ id }: { id: string }) =>
    updateImagingDiagnosticItem({
      db,
      organizationId,
      authorId,
      authorName,
      source,
      data: {
        status: "CANCELED",
      },
      id,
    }),
});
