import {
  Badge,
  Group,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  InventoryTransactionData,
  PurchaseOrder,
  PurchaseOrderItem,
  Result,
  tu,
} from "beitary-shared";
import {
  BBox,
  BDataTable,
  BLoader,
  DeleteConfirmationModal,
  MoveBackButton,
} from "components";
import { selectInventoryItems } from "features/admin/inventory/Inventory.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";
import { AddPurchaseOrderItemModal } from "../AddPurchaseOrderItemModal";
import { EditPurchaseOrder } from "../EditPurchaseOrder";
import { PurchaseOrderItemQtyOrderedUpdateModal } from "../PurchaseOrderItemQtyOrderedUpdateModal";
import { PurchaseOrderItemReceiveModal } from "../PurchaseOrderItemReceiveModal";

type POItem = PurchaseOrderItem & {
  actions?: undefined;
  qtyOnHand?: number;
  uOM?: string;
  unitType?: string;
  unitCostString: string;
  cost: number;
  unitsOrdered: number;
  unitsReceived: number;
  inventoryItemNotFound: boolean;
};

export interface PurchaseOrderComponentProps {}

export const PurchaseOrderComponent = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { purchaseOrders, inventoryTransactions } =
    useDBServices().inventoryDBService;

  const { deletePurchaseOrderItems } = purchaseOrders;

  const { addInventoryTransaction } = inventoryTransactions;

  const [po, setPO] = useState<PurchaseOrder | null | undefined>(undefined);

  const currency = org_params_util.getOrgParams().currency;

  const invItems = useAppSelector(selectInventoryItems); //.filter((i) => Object.values(po?.items??{}).map((i) => i.inventoryItemId).includes(i.id) )

  const tableItems: POItem[] = Object.values(po?.items ?? {})
    // .sort((a, b) => (a.createdAt - b.createdAt ? -1 : 1)) no need cauz by default sorted by name
    .map((o) => {
      const item = invItems.find((i) => i.id === o.inventoryItemId);
      return {
        ...o,
        qtyOnHand: item?.currentQuantity,
        unitCostString: `${mu.toD(o.costPerUnitOfMeasurement)} ${t(
          currency
        )} / ${t(o?.inventoryItemUnitOfMesurement ?? "UNIT")}`,
        cost: mu.multiply({
          amount: o.costPerUnitOfMeasurement,
          multiplier: o.qtyOrdered,
        }),
        uOM: o?.inventoryItemUnitOfMesurement,
        unitType: o?.inventoryItemUnitType,
        unitsOrdered: o.qtyOrdered * o.inventoryItemUnits,
        unitsReceived: o.qtyReceived * o.inventoryItemUnits,
        inventoryItemNotFound: item === undefined,
      };
    });

  const listener =
    useDBServices().inventoryDBService.purchaseOrders.getPurchaseOrderListener;

  useEffect(() => {
    const unsubscribe = id ? listener(id, (p) => setPO(p)) : () => null;

    return () => {
      unsubscribe();
    };
  }, [id, listener]);

  // render: ({ cost, unitCostString }) => (
  //   <Stack align="flex-end" spacing={0} w={150}>
  //     <Text>
  //       {mu.toD(cost)} {t(currency)}
  //     </Text>
  //     <Text size="sm">{unitCostString}</Text>
  //   </Stack>
  // ),

  // items table
  const columns: BDataTableColumn<POItem>[] = [
    {
      accessor: "inventoryItemName",
      title: <Text size="xs">{t("INVENTORY_ITEM")}</Text>,
      sortable: true,
      render: ({ inventoryItemName, inventoryItemNotFound }) => (
        <Group>
          <Text size="xs">{inventoryItemName}</Text>
          {inventoryItemNotFound && (
            <Tooltip label={t("NOT_FOUND")}>
              <IconAlertTriangle size={16} color="orange" stroke={2} />
            </Tooltip>
          )}
        </Group>
      ),
    },
    {
      accessor: "qtyOnHand",
      title: <Text size="xs">{t("QUATNITY_ON_HAND")}</Text>,
      sortable: true,
      render: ({ qtyOnHand, unitType }) =>
        qtyOnHand !== undefined ? (
          <Text size="xs">
            {qtyOnHand} {unitType && `(${t(unitType)})`}
          </Text>
        ) : (
          "-"
        ),
    },
    {
      accessor: "qtyOrdered",
      title: <Text size="xs">{t("QUATNITY_ORDERED")}</Text>,
      sortable: true,
      render: ({ qtyOrdered, uOM }) => (
        <Text size="xs">
          {qtyOrdered} ({t(uOM ?? "UNIT")})
        </Text>
      ),
    },
    {
      accessor: "unitsOrdered",
      title: <Text size="xs">{t("UNITS_ORDERED")}</Text>,
      sortable: true,
      render: ({ unitsOrdered, unitType }) => (
        <Text size="xs">
          {unitsOrdered} {unitType && `(${t(unitType)})`}
        </Text>
      ),
    },

    {
      accessor: "qtyReceived",
      title: <Text size="xs">{t("QUATNITY_RECEIVED")}</Text>,
      sortable: true,
      render: ({ qtyReceived, uOM }) => (
        <Text size="xs">
          {qtyReceived} ({t(uOM ?? "UNIT")})
        </Text>
      ),
    },
    {
      accessor: "unitsReceived",
      title: <Text size="xs">{t("UNITS_RECEIVED")}</Text>,
      sortable: true,
      render: ({ unitsReceived, unitType }) => (
        <Text size="xs">
          {unitsReceived} {unitType && `(${t(unitType)})`}
        </Text>
      ),
    },
    {
      accessor: "cost",
      title: <Text size="xs">{t("COST")}</Text>,
      sortable: true,
      render: ({ cost, unitCostString }) => (
        <Stack align="flex-end" spacing={0} w={150}>
          <Text size="xs">
            {mu.toD(cost)} {t(currency)}
          </Text>
          <Text size="xs">{unitCostString}</Text>
        </Stack>
      ),
    },
    {
      accessor: "status",
      title: <Text size="xs">{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text size="xs" w={100}>
          {t(status)}
        </Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (item) => (
        <Group position="right">
          {/* <ActionIcon onClick={() => navigate(`edit/${supplier.id}`)}>
            <IconEdit size={18} />
          </ActionIcon>
          {supplier.status === "ARCHIVED" ? (
            <DearchiveConfirmationModal
              f={async () =>
                dearchiveInventoryItemSupplier(supplier.id, supplier)
              }
              item={supplier}
            />
          ) : (
            <ArchiveConfirmationModal
              f={async () =>
                archiveInventoryItemSupplier(supplier.id, supplier)
              }
              item={supplier}
            />
          )}
          <DeleteConfirmationModal
            f={async () => deleteInventoryItemSupplier(supplier.id)}
            item={supplier}
          /> */}
          {item.qtyOrdered > item.qtyReceived && po && (
            <PurchaseOrderItemQtyOrderedUpdateModal
              itemId={item.id}
              orderedQty={item.qtyOrdered}
              purchaseOrderId={po.id}
            />
          )}
          {item.qtyOrdered > item.qtyReceived && po && (
            <PurchaseOrderItemReceiveModal
              purchaseOrderId={po.id}
              purchaseOrderItem={item}
            />
          )}
          <DeleteConfirmationModal
            f={async () => {
              let result: Result<boolean | null> = {
                message: t("SUCCESS"),
                payload: false,
                type: "success",
              };
              if (id) {
                result = await deletePurchaseOrderItems({
                  ids: [item.id],
                  purchaseOrderId: id,
                });
              }
              if (item.status === "RECEIVED") {
                // create transaction
                const trans: InventoryTransactionData = {
                  costPerUnit: 0,
                  qty: -item.qtyReceived * item.inventoryItemUnits,
                  date: tu.getCurrentDateTime(),
                  invItemId: item.inventoryItemId,
                  invItemName: item.inventoryItemName,
                  sourceType: "PURCHASE_ORDER",
                  state: "PENDING",
                  taxRate: item.taxRate,
                  type: "PURCHASE_CANCELED",
                  sourceId: id,
                };

                addInventoryTransaction(trans);
              }
              return result; // this needed for notif not to keep loading
            }}
            item={{ id: item.id, name: item.inventoryItemName }}
          />
        </Group>
      ),
    },
  ];

  const getTotal = (): number => {
    const itemsTotal = Object.values(po?.items ?? {}).reduce((acc, item) => {
      return (
        acc +
        mu.multiply({
          amount: item.costPerUnitOfMeasurement,
          multiplier: item.qtyOrdered,
        })
      );
    }, 0);
    return itemsTotal + (po?.additionalCost ?? 0);
  };

  return (
    <Stack>
      <Group>
        <MoveBackButton />
      </Group>
      {po === undefined && <BLoader />}
      {po === null && <Text>{t("ERROR")}</Text>}
      {po !== undefined && po !== null && (
        <BBox
          header={
            <Group position="apart">
              <Group>
                <Text weight={500}>{t("PURCHASE_ORDER")}</Text>
                <Badge
                  size="lg"
                  sx={{ textTransform: "capitalize" }}
                  color={
                    po.status === "PENDING"
                      ? "lime"
                      : po.status === "ORDERED"
                      ? "green"
                      : po.status === "READY_TO_ORDER"
                      ? "orange"
                      : po.status === "PARTIALLY_RECEIVED"
                      ? "blue"
                      : po.status === "CLOSED"
                      ? "gray"
                      : "red"
                  }
                >
                  <Text>{t(po.status)}</Text>
                </Badge>
              </Group>

              <Group>
                <AddPurchaseOrderItemModal
                  purchaseOrderId={po.id}
                  vendorId={po.vendorId}
                />
                <EditPurchaseOrder purchaseOrder={po} />
              </Group>
            </Group>
          }
        >
          <SimpleGrid p="xl" cols={5}>
            <div>
              <Text color="cyan">{t("VENDOR")}</Text>
              <Text>{po.vendorName}</Text>
            </div>
            <div>
              <Text color="cyan">{t("ID")}</Text>
              <Text>{po.id.slice(0, 4).toUpperCase()}</Text>
            </div>
            <div>
              <Text color="cyan">{t("DATE")}</Text>
              <Text>{tu.getDateString(po.date)}</Text>
            </div>
            <div>
              <Text color="cyan">{t("ADDITIONAL_COST")}</Text>
              <Text>
                {mu.toD(po.additionalCost ?? 0)} {t(currency)}
              </Text>
            </div>
            <div>
              <Text color="cyan">{t("NOTE")}</Text>
              <Text>{po.note ?? ""}</Text>
            </div>
          </SimpleGrid>
          <BBox bgcs={0}>
            <ScrollArea p="xl">
              <BDataTable
                data={tableItems}
                columns={columns}
                defaultSortKey={"inventoryItemName"}
              />
            </ScrollArea>
          </BBox>

          <Stack align="flex-end" p="xl">
            <div>
              <Text color="cyan" weight="bold">
                {t("TOTAL")}
              </Text>
              <Text weight="bold">
                {mu.toD(getTotal())} {t(currency)}
              </Text>
            </div>
          </Stack>
        </BBox>
      )}
    </Stack>
  );
};
