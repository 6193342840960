import {
  errorResult,
  GPDFOnCallData,
  GPDFOnCallResponse,
  Result,
  Source,
  successResult,
} from "beitary-shared";
import { Functions, httpsCallable } from "firebase/functions";
import { utils } from "../utils/utils";

interface GetPaymentReceiptPDFFileURL {
  ({
    organizationId,
    docId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    docId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const getPaymentReceiptPDFFileURL: GetPaymentReceiptPDFFileURL = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generatePaymentReceiptOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generatePaymentReceiptOnCall");
  try {
    const { data } = await generatePaymentReceiptOnCall({
      docId: docId,
      organizationId,
      action: "RETURN_DL_URL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};
interface SendPaymentReceiptViaEmail {
  ({
    organizationId,
    docId,
  }: {
    callable: typeof httpsCallable;
    fcts: Functions;
    organizationId: string;
    docId: string;
    authorId: string;
    authorName: string;
  }): Promise<Result<GPDFOnCallResponse | null>>;
}

const sendPaymentReceiptViaEmail: SendPaymentReceiptViaEmail = async ({
  organizationId,
  docId,
  authorId,
  authorName,
  callable,
  fcts,
}) => {
  const generatePaymentReceiptOnCall = callable<
    GPDFOnCallData,
    GPDFOnCallResponse
  >(fcts, "callable-generatePaymentReceiptOnCall");
  try {
    const { data } = await generatePaymentReceiptOnCall({
      docId: docId,
      organizationId,
      action: "SEND_EMAIL",
      authorId,
      authorName,
    });

    return successResult({
      message: "success",
      payload: data,
    });
  } catch (err: any) {
    console.error("Error:", err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const payments = (
  callable: typeof httpsCallable,
  fcts: Functions,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getPaymentReceiptPDFFileURL: (docId: string) =>
      getPaymentReceiptPDFFileURL({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
    sendPaymentReceiptViaEmail: (docId: string) =>
      sendPaymentReceiptViaEmail({
        callable,
        fcts,
        organizationId,
        docId,
        authorId,
        authorName,
      }),
    addPaymentSerialNumberAndDateIssued: (docId: string) =>
      utils({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        source,
      }).addSerialNumberAndDateIssued(docId, "payment"),
    addRefundSerialNumberAndDateIssued: (docId: string) =>
      utils({
        callable,
        fcts,
        organizationId,
        authorId,
        authorName,
        source,
      }).addSerialNumberAndDateIssued(docId, "refund"),
  };
};
