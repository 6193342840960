import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectClients } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EditClientForm } from "../EditClientForm";

export const EditClient = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();

  const client = useAppSelector(selectClients)
    .filter((client) => client.id === clientId)
    .at(0);

  if (!client) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <EditClientForm client={client} />;
};
