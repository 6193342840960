import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveClinicLocations } from "features/admin/clinic-settings/ClinicSettings.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface LocationSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const LocationSelect = (props: LocationSelectProps) => {
  const { t } = useTranslation();
  const locations = useAppSelector(selectActiveClinicLocations);
  const data = locations.map((o) => ({ value: o.id, label: o.name }));

  return (
    <BSelect
      {...props}
      data={data}
      label={t("PATIENT_LOCATION")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
