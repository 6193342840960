import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { bundles } from "./bundles";
import { imagingIntegrationProducts } from "./imaging-integration-products";
import { productCategories } from "./product-categories";
import { products } from "./products";

export const catalog = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    products: products(db, organizationId, authorId, authorName, source),
    productCategories: productCategories(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    bundles: bundles(db, organizationId, authorId, authorName, source),
    imagingIntegrationProducts: imagingIntegrationProducts(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
  };
};
