import { Avatar, Group, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BMultiSelect } from "components";
import { selectActiveMembers } from "features/admin/members/Members.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface AssignedMembersMultiSelectProps {
  required?: boolean;
  onChange?: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const AssignedMembersMultiSelect = (
  props: AssignedMembersMultiSelectProps
) => {
  const { t } = useTranslation();
  const members = useAppSelector(selectActiveMembers);
  const data = members.map((member) => ({
    value: member.id,
    label: member.name,
    thumbURL: member.thumbURL,
  }));

  return (
    <BMultiSelect
      {...props}
      itemComponent={SelectItem}
      label={t("ASSIGNED_MEMBERS")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  thumbURL: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ thumbURL, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={thumbURL} radius="xl" />

        <Text size="sm">{label}</Text>
      </Group>
    </div>
  )
);
