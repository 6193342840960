import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
import { genericStatusEnum } from "../../common/status/GenericStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const categoryDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string().min(1),
  notes: z.string().optional(),
});

type CategoryDataV0 = z.infer<typeof categoryDataV0Schema>;

const categoryV0Schema = baseRecordSchema.merge(categoryDataV0Schema);

type CategoryV0 = z.infer<typeof categoryV0Schema>;

const normalizeCategoryV0 = (data: any): Category => {
  try {
    const categoryV0: CategoryV0 = categoryV0Schema.parse(data);

    const { ...rest } = categoryV0;

    // here it's same object cause current v is 0
    const category: Category = {
      ...rest,
    };
    return category;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const CATEGORY_VERSION = "0";

export type CategoryData = CategoryDataV0;

export type Category = BaseRecord & CategoryData;

export const categoryDataSchema = categoryDataV0Schema;

export const categorySchema = categoryV0Schema;

export const normalizeCategory = (data: any): Category => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeCategoryV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
