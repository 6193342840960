import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Appointment, AppointmentRequest, tu } from "beitary-shared";

interface AppointmentsState {
  appointments: Appointment[];
  appointmentRequests: AppointmentRequest[];
}

const initialState: AppointmentsState = {
  appointments: [],
  appointmentRequests: [],
};

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    setAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.appointments = action.payload;
    },
    setAppointmenRequests: (
      state,
      action: PayloadAction<AppointmentRequest[]>
    ) => {
      state.appointmentRequests = action.payload;
    },
  },
});

export const { setAppointments, setAppointmenRequests } =
  appointmentsSlice.actions;

export const selectAppointments = (state: RootState) =>
  state.appointments.appointments;

export const selectTodaysAppointments = (state: RootState) =>
  state.appointments.appointments.filter(
    (a) =>
      a.time <=
        tu.getDateValueAtHMSM(tu.getCurrentDate(), 0, 0, 0, 0) +
          24 * 60 * 60 * 1000 &&
      a.time >= tu.getDateValueAtHMSM(tu.getCurrentDate(), 0, 0, 0, 0)
  );

export const selectAppointmentRequests = (state: RootState) =>
  state.appointments.appointmentRequests;

export const appointmentsReducer = appointmentsSlice.reducer;
