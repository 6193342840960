import {
  Center,
  Divider,
  Image,
  Navbar,
  NavLink,
  ScrollArea,
  Text,
} from "@mantine/core";
import {
  IconBellRinging,
  IconCalendarTime,
  IconClipboardList,
  IconFileInvoice,
  IconHome,
  IconList,
  IconReportAnalytics,
  IconSettings,
  IconStethoscope,
  IconUsers,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { logoBImg, logoImg2 } from "assets";
import { OrgPermissions } from "beitary-shared";
import { UserLink } from "components/layout/UserLink";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useIsMobile } from "hooks/useIsMobile";
import { useAbilityContext } from "permissions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { NavPopover } from "../NavPopover";
import { ResizeButton } from "../ResizeButton";

export const AppNavbar = () => {
  const cyanColorCode = "#15AABF";
  const darkColorCode = "#25262B";
  const { t } = useTranslation();
  const location = useLocation();
  const ability = useAbilityContext();
  const [collapsed, setCollapsed] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile]);

  const chooseColor = (link: string) => {
    return location.pathname.toLowerCase().startsWith(link)
      ? cyanColorCode
      : darkColorCode;
  };

  const path = location.pathname.toLowerCase();

  const links = [
    {
      label: t("DASHBOARD"),
      link: "/dashboard",
      icon: (
        <IconHome size={20} color={chooseColor("/dashboard")} stroke={1.5} />
      ),
      // shouldDisplay: true, // to change later
    },
    {
      label: t("CLIENTS/PATIENTS"),
      link: "/clients",
      icon: (
        <IconUsers size={20} color={chooseColor("/clients")} stroke={1.5} />
      ),
      // shouldDisplay: true, // to change later
    },
    // {
    //   label: t("PATIENTS"),
    //   link: "/patients",
    //   icon: <IconPaw color={chooseColor("/patients")} stroke={1.5} size={20} />,
    //   // shouldDisplay: true, // to change later
    // },
    {
      label: t("CONSULTATIONS"),
      link: "/consultations",
      icon: (
        <IconStethoscope
          size={20}
          color={chooseColor("/consultations")}
          stroke={1.5}
        />
      ),
    },
    {
      label: t("WHITEBOARD"),
      link: "/whiteboard",
      icon: (
        <IconClipboardList
          size={20}
          color={chooseColor("/whiteboard")}
          stroke={1.5}
        />
      ),
    },
    {
      label: t("SCHEDULE"),
      link: "/schedule",
      icon: (
        <IconCalendarTime
          size={20}
          color={chooseColor("/schedule")}
          stroke={1.5}
        />
      ),
      // shouldDisplay: true, // to change later
      links: [
        { label: t("APPOINTMENTS"), link: "/schedule/appointments" },
        { label: t("BOARDING"), link: "/schedule/boarding" },
        // { label: t("TREATMENT_PLANS"), link: "/schedule/treatment-plans" },
        // { label: t("REMINDERS"), link: "/schedule/reminders" },
        // { label: t("TASKS"), link: "/schedule/tasks" },
      ],
    },

    {
      label: t("INVOICES"),
      link: "/invoices",
      icon: (
        <IconFileInvoice
          size={20}
          color={chooseColor("/invoices")}
          stroke={1.5}
        />
      ),
      // shouldDisplay: true, // to change later
      // links: [
      //   { label: t("INVOICES"), link: "/finances/invoices" },
      //   { label: t("ESTIMATES"), link: "/finances/estimates" },
      //   { label: t("REFUNDS"), link: "/finances/refunds" },
      //   { label: t("PAYMENTS"), link: "/finances/payments" },
      //   { label: t("CREDITS"), link: "/finances/credits" },
      // ],
    },
    {
      label: t("TASKS"),
      link: "/tasks",
      icon: <IconList size={20} color={chooseColor("/tasks")} stroke={1.5} />,
    },
    {
      label: t("REMINDERS"),
      link: "/reminders",
      icon: (
        <IconBellRinging
          size={20}
          color={chooseColor("/reminders")}
          stroke={1.5}
        />
      ),
    },
    ...(ability.can("do", OrgPermissions.FINANCIAL_ACCESS) ||
    ability.can("do", OrgPermissions.MEDICATION_ACCESS) ||
    ability.can("do", OrgPermissions.CLIENTS_PATIENTS_ACCESS) ||
    ability.can("do", OrgPermissions.PRACTICE_MANAGEMENT_ACCESS)
      ? [
          {
            label: t("REPORTING"),
            link: "/reporting",
            icon: (
              <IconReportAnalytics
                size={20}
                color={chooseColor("/reporting")}
                stroke={1.5}
              />
            ),
          },
        ]
      : []),
    // {
    //   label: t("HISTORY"),
    //   link: "/history",
    //   icon: (
    //     <IconHistory size={20} color={chooseColor("/history")} stroke={1.5} />
    //   ),
    //   // shouldDisplay: true, // to change later
    // },
    // {
    //   label: t("INVENTORY"),
    //   link: "/inventory",
    //   icon: (
    //     <IconBuildingWarehouse
    //       size={20}
    //       color={chooseColor("/inventory")}
    //       stroke={1.5}
    //     />
    //   ),
    //   // shouldDisplay: true, // to change later
    //   links: [
    //     { label: t("INVENTORY_ITEMS"), link: "/inventory/items" },
    //     { label: t("ORDERS"), link: "/inventory/refunds" },
    //     // { label: t("RECEIPTS"), link: "/inventory/payments" },//??????????
    //     { label: t("RETURNS"), link: "/inventory/credits" },
    //   ],
    // },
    {
      label: t("ADMIN"),
      link: "/admin",
      icon: (
        <IconSettings size={20} color={chooseColor("/admin")} stroke={1.5} />
      ),
      // shouldDisplay: true, // to change later
      links: [
        { label: t("CLINIC_SETTINGS"), link: "/admin/clinic-settings" },
        { label: t("MEMBERS"), link: "/admin/members" },
        ...(ability.can("do", OrgPermissions.INVENTORY_ACCESS)
          ? [{ label: t("INVENTORY"), link: "/admin/inventory" }]
          : []),
        { label: t("CATALOG"), link: "/admin/catalog" },
        { label: t("TEMPLATES"), link: "/admin/templates" },
        { label: t("DIAGNOSES"), link: "/admin/diagnoses" },
        { label: t("SCHEDULE_SETTINGS"), link: "/admin/schedule-settings" },
        {
          label: t("CLIENT_COMMUNICATION"),
          link: "/admin/client-communication",
        },
      ],
    },
  ];

  const org = useAppSelector(selectOrganization);

  return (
    <Navbar width={{ base: collapsed ? 42 : isMobile ? 160 : 200 }}>
      <ResizeButton setCollapsed={setCollapsed} collapsed={collapsed} />
      {org && !collapsed && (
        <>
          <Navbar.Section>
            <Center>
              <Image
                m={isMobile ? 6 : 10}
                src={org.thumbURL}
                height={isMobile ? 40 : 80}
                maw={isMobile ? 100 : 180}
                fit="scale-down"
              />
            </Center>
          </Navbar.Section>
        </>
      )}
      {org && !collapsed && (
        <>
          <Divider />
          <Navbar.Section>
            <Center p="sm">
              <Text size="xs" weight={500} color="cyan">
                {org.name}
              </Text>
            </Center>
          </Navbar.Section>
        </>
      )}

      <Divider />
      <Navbar.Section
        grow
        component={ScrollArea}
        mt={isMobile ? "xs" : "lg"}
        mx="-xs"
        px="xs"
      >
        {links.map(
          (
            item //TODO: add permission check once we create roles
          ) =>
            // <LinksGroup {...item} />
            collapsed ? (
              <NavPopover item={item} path={path} />
            ) : (
              <NavLink
                pl="xs"
                label={<Text size="xs">{item.label}</Text>}
                key={item.label}
                component={Link}
                to={item.link}
                icon={item.icon}
                active={path.startsWith(item.link)}
                //onClick={() => !item.links && setActive(item.link)}
                style={{
                  borderLeft:
                    !item.links &&
                    location.pathname.toLowerCase().startsWith(item.link)
                      ? `6px solid ${cyanColorCode}`
                      : "0px",
                }}
              >
                {item.links &&
                  item.links.map((childItem) => (
                    <NavLink
                      p="xs"
                      label={<Text size="xs">{childItem.label}</Text>}
                      key={childItem.label}
                      component={Link}
                      to={childItem.link}
                      active={path.startsWith(childItem.link)}
                      // onClick={() => setActive(childItem.link)}
                      style={{
                        borderLeft: location.pathname
                          .toLowerCase()
                          .startsWith(childItem.link)
                          ? `6px solid ${cyanColorCode}`
                          : "0px",
                      }}
                    />
                  ))}
              </NavLink>
            )
        )}
      </Navbar.Section>
      <Divider />
      <Navbar.Section>
        <Center>
          <UserLink collapsed={collapsed} />
        </Center>
      </Navbar.Section>
      <Divider />
      <Navbar.Section h={isMobile ? 48 : 72}>
        <Center py={isMobile ? "xs" : "md"} px={4}>
          <Image
            src={collapsed ? logoBImg : logoImg2}
            height={isMobile ? 26 : 40}
            fit="scale-down"
          />
        </Center>
      </Navbar.Section>
    </Navbar>
  );
};
