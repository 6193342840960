import { Group, Input, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  Consultation,
  DischargeInstructionData,
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
} from "beitary-shared";
import { BBox, BTextEditor, SaveButton } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { AssessmentFormValues, rules } from "./AssessmentForm.rules";
import { ProblemsMultiSelect, PrognosisSelect } from "./components";

export interface AssessmentFormProps {
  consultation: Consultation;
}

export const AssessmentForm = ({ consultation }: AssessmentFormProps) => {
  const disabled = consultation.status === "LOCKED";

  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { updateConsultation } =
    useDBServices().consultationsDBService.consultations;
  const { addDischargeInstructions } =
    useDBServices().consultationsDBService.dischargeInstructions(
      consultation.id
    );

  const form = useForm<AssessmentFormValues>({
    initialValues: consultation ? { ...consultation } : undefined,
    validate: rules,
  });

  const submit = async (values: AssessmentFormValues) => {
    if (disabled) return;
    const dischIntrsData: (DischargeInstructionData & { id: string })[] = [];

    const allItems = (form.values.problems ?? [])
      .concat(form.values.diagnoses ?? [])
      .concat(form.values.chronicConditions ?? []);

    const dedupedItems = allItems.filter(
      (i, index, arr) => arr.findIndex((t) => t.id === i.id) === index
    );

    dedupedItems.forEach((i) => {
      const dischInstr = i.dischInstr;
      if (dischInstr)
        dischIntrsData.push({
          id: i.id,
          consultationId: consultation.id,
          content: dischInstr,
          title: i.name,
        });
    });

    const notificationId = "submit-assessment";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_CONSULTATION"),
      })
    );
    setSubmitState("pending-response");
    obju.removeUndefined(values);
    const result = await updateConsultation(consultation.id, values);
    const result2 = await addDischargeInstructions(dischIntrsData); // TODO, this should be added by trigger fct

    if (result.type === "success" && result2.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <BBox header={<Text weight={500}>{t("ASSESSMENT")}</Text>}>
          <Stack p="xl">
            <Group grow>
              <ProblemsMultiSelect
                disabled={disabled}
                label={t("PROBLEMS")}
                {...form.getInputProps("problems")}
                value={form.values.problems?.map((i) => i.id)}
              />
              <ProblemsMultiSelect
                disabled={disabled}
                label={t("SUSPECTED_DIAGNOSES")}
                {...form.getInputProps("suspicions")}
                value={form.values.suspicions?.map((i) => i.id)}
              />
              <ProblemsMultiSelect
                disabled={disabled}
                label={t("DIAGNOSES")}
                {...form.getInputProps("diagnoses")}
                value={form.values.diagnoses?.map((i) => i.id)}
              />
              {/* TODO fix chronic conditions <ProblemsMultiSelect
                disabled={disabled}
                label={t("CHRONIC_CONDITIONS")}
                {...form.getInputProps("chronicConditions")}
                value={form.values.chronicConditions?.map((i) => i.id)}
              /> */}
            </Group>
            <Input.Wrapper label={t("ASSESSMENT")}>
              <BTextEditor
                disabled={disabled}
                {...form.getInputProps("assessment")}
              />
            </Input.Wrapper>
            <Group align="flex-end">
              <PrognosisSelect
                disabled={disabled}
                label={t("PROGNOSIS")}
                {...form.getInputProps("prognosisFrom")}
              />
              <Text>{t("TO")}</Text>
              <PrognosisSelect
                disabled={disabled}
                {...form.getInputProps("prognosisTo")}
              />
            </Group>
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isDirty() && form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
