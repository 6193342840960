import { Box, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { ImagingDiagnosticItem, tu } from "beitary-shared";
import { BBox, BDataTable } from "components";
import { selectImagingDiagnosticItems } from "features/diagnostics/Diagnostics.slice";
import { useTranslation } from "react-i18next";
import { BDataTableColumn } from "schemas-types";
import { ImagingDiagnosticItemMenu } from "./components";

type ImagingDiagnosticItemsTableItem = ImagingDiagnosticItem & {
  actions?: undefined;
};

export interface ImagingDiagnosticItemsListProps {}

export const ImagingDiagnosticsItemsList = () => {
  const { t } = useTranslation();

  const tableItems: ImagingDiagnosticItemsTableItem[] = useAppSelector(
    selectImagingDiagnosticItems
  );

  const columns: BDataTableColumn<ImagingDiagnosticItemsTableItem>[] = [
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: (item) => <Text>{t(item.status)} </Text>,
    },
    {
      accessor: "patientName",
      title: <Text>{t("PATIENT")}</Text>,
      sortable: true,
    },
    {
      accessor: "clientName",
      title: <Text>{t("CLIENT")}</Text>,
      sortable: true,
    },
    {
      accessor: "integration",
      title: <Text>{t("INTEGRATION")}</Text>,
      sortable: true,
      render: ({ integration }) => <Text>{t(integration)}</Text>,
    },
    {
      accessor: "accessionNumber",
      title: <Text>{t("ACCESSION_NUMBER")}</Text>,
      sortable: true,
    },
    {
      accessor: "examTypeDescription",
      title: <Text>{t("EXAM_TYPE")}</Text>,
      sortable: true,
    },
    {
      accessor: "modality",
      title: <Text>{t("MODALITY")}</Text>,
      sortable: true,
    },
    {
      accessor: "createdAt",
      title: <Text>{t("CREATED_AT")}</Text>,
      sortable: true,
      render: ({ createdAt }) => (
        <Text>{tu.getDateAndTimeString(createdAt)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 30,
      render: (item) => (
        <ImagingDiagnosticItemMenu item={item} />
        // <Group position="right">
        //   {/* {item.status === "CREATED" ? (
        //     <AsyncActionIcon
        //       f={async () => completeImagingDiagnosticItem(item.id)}
        //       icon={<IconPencilCheck size={18} />}
        //     />
        //   ) : (
        //     <AsyncActionIcon
        //       f={async () => uncompleteImagingDiagnosticItem(item.id)}
        //       icon={<IconArrowBack size={18} />}
        //     />
        //   )}
        //   <EditImagingDiagnosticItemModal task={item} />
        //   <DeleteConfirmationModal
        //     f={async () => deleteImagingDiagnosticItem(item.id)}
        //     item={item}
        //   /> */}
        // </Group>
      ),
    },
  ];

  return (
    <BBox>
      <Box p="xl">
        <BDataTable data={tableItems} columns={columns} />
      </Box>
    </BBox>
  );
};
