// import { useTranslation } from "react-i18next";

import { useAppSelector } from "app/hooks";
import { selectTodaysAppointments } from "features/schedule/appointments/Appointments.slice";
import { AppointmentsTable } from "features/schedule/appointments/components";

export interface TodaysAppointmentsListProps {}

export const TodaysAppointmentsList = () => {
  // const { t } = useTranslation();

  const apts = useAppSelector(selectTodaysAppointments);
  return <AppointmentsTable appointments={apts} />;
};
