import {
  Box,
  Code,
  Group,
  Input,
  List,
  Paper,
  Stack,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  normalizeSMSReminderNotificationTemplate,
  Result,
  rnt_utils,
  SMSReminderNotificationTemplate,
  tu,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { RemindersMultiSelect } from "components/RemindersMultiSelect";
import { selectActiveUser } from "features/Authentication/AuthenticationSlice";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { rules, SMSTemplateFormValues } from "./SMSTemplateForm.rules";

export interface SMSTemplateFormProps {
  smsTemplate?: SMSReminderNotificationTemplate;
}

export const SMSTemplateForm = ({ smsTemplate }: SMSTemplateFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const {
    updateSMSReminderNotificationTemplate,
    addSMSReminderNotificationTemplate,
  } = useDBServices().clientCommunication.smsReminderNotificationTemplates;

  const org = useAppSelector(selectOrganization);
  const user = useAppSelector(selectActiveUser);

  const form = useForm<SMSTemplateFormValues>({
    initialValues: smsTemplate
      ? normalizeSMSReminderNotificationTemplate(smsTemplate)
      : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: SMSTemplateFormValues) => {
    const notificationId = "submit-smsTemplate";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("ADD_TEMPLATE")
        // t("UPDATE_TEMPLATE")
        title: t(smsTemplate ? "UPDATE_TEMPLATE" : "ADD_TEMPLATE"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (smsTemplate) {
      result = await updateSMSReminderNotificationTemplate(
        smsTemplate.id,
        values
      );
    } else {
      result = await addSMSReminderNotificationTemplate(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  console.log(form.values);

  const getContentPreviewText = () => {
    const dueDate = tu.getDateString(tu.getCurrentDateTime());
    const reminder = t("VACCINE");
    const client = user?.displayName ?? "";
    const patient = "Rex";
    const clinicAddress = [
      org?.address_Line1,
      org?.address_Line2,
      org?.address_postalCode,
      org?.address_city,
      org?.address_stateOrProvince,
    ]
      .filter((i) => i !== undefined && i !== "")
      .join(", ");
    return rnt_utils.generateRemNotifText(
      form.values.notificationContent ?? "",
      {
        reminder,
        dueDate,
        client,
        patient,
        clinicAddress,
        clinicEmail: org?.email ?? "",
        clinicName: org?.name ?? "",
        clinicPhone: org?.phoneNumber ?? "",
      }
    );
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            smsTemplate ? (
              <Text weight={500}>{t("EDIT_TEMPLATE")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_TEMPLATE")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />{" "}
            <RemindersMultiSelect
              required
              {...form.getInputProps("remindersIds")}
              onChange={(v) => {
                if (v) form.setFieldValue("remindersIds", v);
              }}
            />
            <Group align="flex-start" position="apart">
              <Box w="65%">
                <Input.Wrapper label={t("NOTIFICATION_CONTENT")} required>
                  <Tabs variant="default" defaultValue="form" mt="sm">
                    <Tabs.List>
                      <Tabs.Tab value="form">{t("EDIT")}</Tabs.Tab>
                      <Tabs.Tab value="preview">{t("PREVIEW")}</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="form" pt="xs">
                      <Textarea
                        minRows={6}
                        {...form.getInputProps("notificationContent")}
                        onChange={(v) => {
                          form.setFieldValue(
                            "notificationContent",
                            v.target.value
                          );
                          console.log(v.target.value);
                        }}
                      />
                    </Tabs.Panel>

                    <Tabs.Panel value="preview" pt="xs">
                      <Paper p="xl">
                        <Text> {HTMLReactParser(getContentPreviewText())}</Text>
                      </Paper>
                    </Tabs.Panel>
                  </Tabs>{" "}
                </Input.Wrapper>
              </Box>
              <Box w="30%" mt="lg">
                <Text mb="xl" size="sm">
                  {t("SMS_NOTIF_TEMPLATE_CONTENT_EXPLAINER")}
                </Text>
                <List size="sm" withPadding>
                  <List.Item>
                    <Code color="cyan">{"{PATIENT}"}</Code> : {t("PATIENT")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLIENT}"}</Code> : {t("CLIENT")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{REMINDER}"}</Code> : {t("REMINDER")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{DUE_DATE}"}</Code> : {t("DUE_DATE")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_NAME}"}</Code> :{" "}
                    {t("CLINIC_NAME")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_EMAIL}"}</Code> :{" "}
                    {t("CLINIC_EMAIL")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_PHONE}"}</Code> :{" "}
                    {t("CLINIC_PHONE")}
                  </List.Item>
                  <List.Item>
                    <Code color="cyan">{"{CLINIC_ADDRESS}"}</Code> :{" "}
                    {t("CLINIC_ADDRESS")}
                  </List.Item>
                </List>
              </Box>
            </Group>
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
