import { zodResolver } from "@mantine/form";
import { organizationDataSchema } from "beitary-shared";
import { z } from "zod";

const generalSettingsFormSchema = organizationDataSchema;

export type GeneralSettingsFormValues = z.infer<
  typeof generalSettingsFormSchema
>;

export const rules = zodResolver(generalSettingsFormSchema);
