import { z } from "zod";

// sauce https://en.wikipedia.org/wiki/Animal_identification

// t("ACOUSTIC_TAG")
// t("ADHESIVE_TAG")
// t("ANKLET")
// t("BRANDING_FREEZE")
// t("BRANDING_HOT_IRON")
// t("CODED_WIRE_TAG")
// t("COLLAR_ELECTRONIC")
// t("COLLAR_NON_ELECTRONIC")
// t("COWBELL")
// t("EAR_TAG_ELECTRONIC")
// t("EAR_TAG_NON_ELECTRONIC")
// t("EARMARKING")
// t("FIN_CLIPPING")
// t("HAIR_DYE")
// t("LEG_RING_ELECTRONIC")
// t("LEG_RING_NON_ELECTRONIC")
// t("MICROSHIP_IMPLANT")
// t("PASSPORT")
// t("PASSIVE_INTEGRATED_TRANSPONDER")
// t("RUMEN_BOLUS_ELECTRONIC")
// t("SEMI_PERMANENT_PAINT")
// t("TATTOO")
// t("TELEMETRY")
// t("TOE_CLIPPING")
// t("VISIBLE_IMPLENT_ELASTOMETER")
// t("WING_TAG_ELECTRONIC")
// t("WING_TAG_NON_ELECTRONIC")
// t("OTHER")

export const patientExternalIdTypeEnum = z.enum([
  "ACOUSTIC_TAG",
  "ADHESIVE_TAG",
  "ANKLET",
  "BRANDING_FREEZE",
  "BRANDING_HOT_IRON",
  "CODED_WIRE_TAG",
  "COLLAR_ELECTRONIC",
  "COLLAR_NON_ELECTRONIC",
  "COWBELL",
  "EAR_TAG_ELECTRONIC",
  "EAR_TAG_NON_ELECTRONIC",
  "EARMARKING",
  "FIN_CLIPPING",
  "HAIR_DYE",
  "LEG_RING_ELECTRONIC",
  "LEG_RING_NON_ELECTRONIC",
  "MICROSHIP_IMPLANT",
  "PASSPORT",
  "PASSIVE_INTEGRATED_TRANSPONDER",
  "RUMEN_BOLUS_ELECTRONIC",
  "SEMI_PERMANENT_PAINT",
  "TATTOO",
  "TELEMETRY",
  "TOE_CLIPPING",
  "VISIBLE_IMPLENT_ELASTOMETER",
  "WING_TAG_ELECTRONIC",
  "WING_TAG_NON_ELECTRONIC",
  "OTHER",
]);
export type PatientExternalIdType = z.infer<typeof patientExternalIdTypeEnum>;
