import {
  Anchor,
  Box,
  Button,
  Card,
  Group,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import {
  Client,
  getAdditionalTaxes,
  Invoice,
  Payment,
  tu,
} from "beitary-shared";
import { AsyncActionButton, BBox, TableWithSearchAndSort } from "components";
import {
  PaymentMenu,
  WithdrawCashDromCreditBalanceForm,
} from "features/invoices/components/Payment/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";

export interface FinancialProps {
  client: Client;
}

export const Financial = ({ client }: FinancialProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { currency, countryCode } = org_params_util.getOrgParams();

  const db = useDBServices().invoicesDBService;

  const [invoices, setInvoices] = useState<undefined | null | Invoice[]>(
    undefined
  );
  const [payments, setPayments] = useState<undefined | null | Payment[]>(
    undefined
  );

  useEffect(() => {
    const listener = db.invoices.getClientInvoicesListener(
      client.id,
      setInvoices
    );
    return () => {
      listener();
    };
  }, [db, client.id]);

  useEffect(() => {
    const listener = db.payments.getClientPaymentsListener(
      client.id,
      setPayments
    );
    return () => {
      listener();
    };
  }, [db, client.id]);

  const addNewIvoice = async () => {
    const result = await db.invoices.addInvoice({
      clientId: client.id,
      clientName: client.name,
      type: "INVOICE",
      status: "ACTIVE",
      currency,
      additionalTaxes: getAdditionalTaxes(countryCode),
      lineItems: {},
      payments: {},
      refunds: {},
      refunded: 0,
      balanceDue: 0,
      discount: 0,
      paid: 0,
      subtotal: 0,
      taxes: 0,
      total: 0,
    });

    if (result.type === "success" && result.payload) {
      navigate(`/invoices/edit/${result.payload.id}`);
    }
  };

  /**
   * invoices table
   */

  const invoiceFields: {
    key: keyof Invoice;
    label: string;
  }[] = [
    { key: "createdAt", label: t("CREATED_AT") },
    { key: "id", label: t("INVOICE_ID") },
    { key: "status", label: t("STATUS") },
    { key: "checkedOutAt", label: t("CHECKED_OUT") },
    { key: "serialNumber", label: t("SERIAL_NUMBER") },
    // { key: "total", label: t("TOTAL") },
    // { key: "paid", label: t("PAID") },
    { key: "balanceDue", label: t("BALANCE") },
  ];

  const getInvoiceRow = (i: Invoice) => (
    <tr key={i.id}>
      <td>
        <Text>{tu.getYYYYMMDDString(i.createdAt)}</Text>
      </td>
      <td>
        <Anchor
          color="dark"
          //lineClamp={1}
          component={Link}
          // size="xs"
          to={`/invoices/edit/${i.id}`}
        >
          <Group spacing="xs">
            <Text>{i.id.slice(0, 4).toUpperCase()}</Text>
            {<IconExternalLink size={12} />}
          </Group>
        </Anchor>
      </td>
      <td>
        <Text>{t(i.status + "_INV")}</Text>
      </td>
      <td>
        <Text>{i.checkedOutAt && tu.getYYYYMMDDString(i.checkedOutAt)}</Text>
      </td>
      {/* <td>
        <Text>{i.dateIssued && tu.getYYYYMMDDString(i.dateIssued)}</Text>
      </td> */}
      <td>
        <Text>{i.serialNumber ?? ""}</Text>
      </td>
      {/* <td>
        <Text>{mu.toD(i.total)}</Text>
      </td>
      <td>
        <Text>{mu.toD(i.paid)}</Text>
      </td> */}
      <td>
        <Text>{mu.toD(i.balanceDue)}</Text>
      </td>
    </tr>
  );

  /**
   * payments table
   */

  const paymentFields: {
    key: keyof Payment;
    label: string;
  }[] = [
    { key: "createdAt", label: t("DATE") },
    { key: "id", label: t("PAYMENT_ID") },
    { key: "status", label: t("STATUS") },
    // { key: "paymentMethod", label: t("METHOD") },
    { key: "invoices", label: t("TOTAL") },
    { key: "totalPaymentAmount", label: t("PAID") },
    { key: "note", label: t("NOTE") },
    // { key: "creditAmount", label: t("CREDIT") },
  ];

  const getPaymentRow = (p: Payment) => (
    <tr key={p.id}>
      <td>
        <Text>{tu.getYYYYMMDDString(p.createdAt)}</Text>
      </td>
      <td>
        <Text>{p.id.slice(0, 4).toUpperCase()}</Text>
      </td>
      <td>
        <Text>{t(p.status)}</Text>
      </td>
      {/* <td>
        <Text>{t(p.paymentMethod)}</Text>
      </td> */}
      <td>
        <Text>
          {mu.toD(
            Object.values(p.invoices).reduce(
              (acc, inv) => acc + inv.paymentAmount,
              0
            )
          )}
        </Text>
      </td>
      <td>
        <Text>{mu.toD(p.totalPaymentAmount)}</Text>
      </td>
      <td>
        <Text>{p.note}</Text>
      </td>
      <td>
        <PaymentMenu payment={p} />
      </td>
      {/* <td>
        <Text>{mu.toD(p.creditAmount)}</Text>
      </td> */}
    </tr>
  );

  return (
    <Stack>
      <Card withBorder radius="xs">
        <Group position="apart">
          <Group>
            <Stack spacing={0}>
              <Text>{t("OUTSTANDING_BALANCE")}</Text>
              <Text color="red">
                {mu.toD(client.outstandingBalance)} {t(currency)}
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Text>{t("CREDIT_BALANCE")}</Text>
              <Group>
                <Text color="green">
                  {mu.toD(client.creditBalance)} {t(currency)}
                </Text>
                <WithdrawCashDromCreditBalanceForm client={client} />
              </Group>
            </Stack>
          </Group>
          <Group>
            <Button
              onClick={() => navigate(`/invoices/payments/new/${client.id}`)}
            >
              {t("TAKE_PAYMENT")}
            </Button>
            <AsyncActionButton f={addNewIvoice} child={t("QUICK_INVOICE")} />
            {/* <Button disabled>{t("PRINT_STATEMENT")}</Button> TODO idk */}
          </Group>
        </Group>
      </Card>

      <BBox
        header={
          <Group position="apart">
            <Text weight={500}>{t("INVOICES")}</Text>
          </Group>
        }
      >
        <Box p="xl">
          {invoices === undefined ? (
            <Text>{t("LOADING")}</Text>
          ) : invoices === null ? (
            <Text>{t("ERROR")}</Text>
          ) : (
            <ScrollArea>
              <TableWithSearchAndSort
                data={invoices}
                fields={invoiceFields}
                getRow={getInvoiceRow}
                minWidth={500}
              />
            </ScrollArea>
          )}
        </Box>
      </BBox>
      <BBox
        header={
          <Group position="apart">
            <Text weight={500}>{t("PAYMENTS")}</Text>
          </Group>
        }
      >
        <Box p="xl">
          {payments === undefined ? (
            <Text>{t("LOADING")}</Text>
          ) : payments === null ? (
            <Text>{t("ERROR")}</Text>
          ) : (
            <ScrollArea>
              <TableWithSearchAndSort
                data={payments}
                fields={paymentFields}
                getRow={getPaymentRow}
                minWidth={500}
                hasControlsColumn
              />
            </ScrollArea>
          )}
        </Box>
      </BBox>
    </Stack>
  );
};
