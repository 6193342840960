import { ActionIcon, Box, Button, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Diagnosis, OrgPermissions } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BBox,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";
import { selectDiagnoses } from "../../Diagnoses.slice";

type DiagnosesTableItem = Diagnosis & { actions?: undefined };

export const DiagnosesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const diagnoses = useAppSelector(selectDiagnoses);

  const tableItems: DiagnosesTableItem[] = diagnoses;

  const { deleteDiagnosis, archiveDiagnosis, dearchiveDiagnosis } =
    useDBServices().diagnosesDBService;

  const columns: BDataTableColumn<DiagnosesTableItem>[] = [
    { accessor: "name", title: <Text>{t("NAME")}</Text>, sortable: true },
    { accessor: "code", title: <Text>{t("CODE")}</Text>, sortable: true },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (item) => (
        <BCan I="do" a={OrgPermissions.DIAGNOSES_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${item.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {item.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () => dearchiveDiagnosis(item.id, item)}
                item={item}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () => archiveDiagnosis(item.id, item)}
                item={item}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteDiagnosis(item.id)}
              item={item}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BBox
      header={
        <Group position="apart">
          <Text size="sm" weight={500}>
            {t("DIAGNOSES_LIST")}
          </Text>
          <BCan I="do" a={OrgPermissions.DIAGNOSES_CUD}>
            <Button onClick={() => navigate("new")}>{t("ADD_NEW")}</Button>
          </BCan>
        </Group>
      }
    >
      <Box p="xl">
        <BDataTable
          data={tableItems}
          columns={columns}
          defaultSortKey={"name"}
        />
      </Box>
    </BBox>
  );
};
