import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const dischargeInstructionDataV0Schema = z.object({
  consultationId: z.string(),
  title: z.string(),
  content: z.string(),
});

type DischargeInstructionDataV0 = z.infer<
  typeof dischargeInstructionDataV0Schema
>;

const dischargeInstructionV0Schema = baseRecordSchema.merge(
  dischargeInstructionDataV0Schema
);

type DischargeInstructionV0 = z.infer<typeof dischargeInstructionV0Schema>;

const normalizeDischargeInstructionV0 = (data: any): DischargeInstruction => {
  try {
    const dischargeInstructionV0: DischargeInstructionV0 =
      dischargeInstructionV0Schema.parse(data);

    const { ...rest } = dischargeInstructionV0;

    // here it's same object cause current v is 0
    const dischargeInstruction: DischargeInstruction = {
      ...rest,
    };
    return dischargeInstruction;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const DISCHARGE_INSTRUCTION_VERSION = "0";

export type DischargeInstructionData = DischargeInstructionDataV0;

export type DischargeInstruction = BaseRecord & DischargeInstructionData;

export const dischargeInstructionDataSchema = dischargeInstructionDataV0Schema;

export const dischargeInstructionSchema = dischargeInstructionV0Schema;

export const normalizeDischargeInstruction = (
  data: any
): DischargeInstruction => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeDischargeInstructionV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
