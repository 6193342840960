import {
  Box,
  Button,
  Group,
  NumberInput,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
} from "beitary-shared";
import { BBox } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";
import { ProductMultiSelect } from "../ProductMultiSelect";
import {
  AddInvoiceItemsFormItem,
  AddInvoiceItemsFormValues,
  rules,
} from "./AddInvoiceItemsForm.rules";

export interface AddInvoiceItemsFormProps {
  disabled?: boolean;
  invoiceId: string;
}

export const AddInvoiceItemsForm = ({
  disabled,
  invoiceId,
}: AddInvoiceItemsFormProps) => {
  const { t } = useTranslation();

  const db = useDBServices();

  const form = useForm<AddInvoiceItemsFormValues>({
    initialValues: {
      items: [],
    },
    validate: rules,
  });

  const [addProductsState, setAddProductsState] = useSubmitState();

  const addSelectedProductsToInvoice = async ({
    items,
  }: AddInvoiceItemsFormValues) => {
    if (disabled || !(items.length > 0)) return;

    const notificationId = "submit-invoice-items";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("ADD_INVOICE_ITEMS"),
      })
    );

    setAddProductsState("pending-response");
    const result = await db.invoicesDBService.invoices.addInvoiceItems({
      invoiceId,
      dataArr: items,
    });
    if (result.type === "success") {
      setAddProductsState("success");
      form.setFieldValue("items", []);
    } else {
      setAddProductsState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  console.log(form.values.items);

  const rows = form.values.items.map((element, index) => {
    const isFromFixedPriceBundle =
      element.bundleId !== undefined && element.bundleId !== element.id;
    const { totalValueWithTaxes } = mu.calculateItemValueAndTaxes({
      qty: element.qty,
      taxRate: element.taxRate,
      totalDiscount: 0,
      unitValue: isFromFixedPriceBundle ? 0 : element.pUnitSellingPrice,
    });
    return (
      <tr key={index}>
        <td>
          <Text>{element.pName}</Text>
        </td>
        <td>
          <NumberInput
            min={0}
            precision={2}
            removeTrailingZeros
            value={element.qty}
            onChange={(v) => {
              if (v !== "") {
                form.setFieldValue(`items.${index}.qty`, v);

                if (element.bundleId === element.id) {
                  // fixed price bundle
                  const indexes: { index: number; qty: number }[] = [];

                  form.values.items.forEach((i, index) => {
                    if (
                      i.bundleId &&
                      i.bundleId === element.id &&
                      i.bundleId !== i.id
                    ) {
                      const newQty = i.dose ?? i.qty;
                      indexes.push({ index, qty: newQty * v });
                    }
                  });

                  indexes.forEach((i) =>
                    form.setFieldValue(`items.${i.index}.qty`, i.qty)
                  );
                }
              }
            }}
          />
        </td>
        <td>{mu.toD(element.pUnitSellingPrice)}</td>
        <td>{mu.toD(totalValueWithTaxes)}</td>
      </tr>
    );
  });

  /////////////////

  return (
    <form
      onSubmit={form.onSubmit(addSelectedProductsToInvoice, (errors) =>
        console.log(errors)
      )}
    >
      <BBox
        header={
          <Group position="apart">
            <Text>{t("ADD_INVOICE_ITEMS")}</Text>
          </Group>
        }
      >
        <Stack p="xl">
          <Group align="flex-start">
            <Box
              sx={{
                width: 400,
                flexGrow: 3,
              }}
            >
              <ProductMultiSelect
                disabled={disabled}
                onChange={(v) =>
                  form.setFieldValue(
                    "items",
                    v.map((p) => {
                      const {
                        bundleId,
                        bundleName,
                        id,
                        name,
                        categoryId,
                        unitSellingPrice,
                        dose,
                        linkedInventoryItemId,
                        taxRate,
                        discountable,
                        unitRatio,
                      } = p;

                      const isFromFixedPriceBundle =
                        bundleId !== undefined && bundleId !== id;

                      const qty = dose ?? 1;

                      let newItem: AddInvoiceItemsFormItem = {
                        id,
                        qty,
                        pId: id,
                        pName: name,
                        category: categoryId,
                        invoiceId,
                        parentId: invoiceId,
                        parentType: "INVOICE",
                        isFromFixedPriceBundle,
                        pUnitSellingPrice: unitSellingPrice,
                        pLinkedInventoryItemId: linkedInventoryItemId,
                        pUnitRatio: unitRatio,
                        dose,
                        bundleId,
                        bundleName,
                        taxRate,
                        discountable,
                      };
                      obju.removeUndefined(newItem);
                      return newItem;
                    })
                  )
                }
              />
            </Box>
            <Button
              sx={{
                flexGrow: 1,
              }}
              disabled={disabled || form.values.items.length === 0}
              loading={addProductsState === "pending-response"}
              type="submit"
            >
              {t("ADD_ITEMS")}
            </Button>
          </Group>

          {rows.length > 0 && (
            <Box p="xl">
              <Table>
                <thead>
                  <tr>
                    <th>{t("DESCRIPTION")}</th>
                    <th>{t("QTY")}</th>
                    <th>{t("UNIT_PRICE_NO_TAXES")}</th>
                    <th>{t("PRICE")}</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </Table>
            </Box>
          )}
        </Stack>
      </BBox>
    </form>
  );
};
