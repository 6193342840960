import {
  Button,
  Group,
  Select,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { tu, VitalsEntry } from "beitary-shared";
import { BBox } from "components";
import { selectActiveConsultationVitals } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface VitalsProps {
  disabled: boolean;
  consultationId: string;
}

export const Vitals = ({ consultationId, disabled }: VitalsProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [submitState, setSubmitState] = useSubmitState();
  const [deleteState, setDeleteState] = useSubmitState();

  const data: VitalsEntry[] = useAppSelector(
    selectActiveConsultationVitals
  ).filter((v) => v.consultationId === consultationId);

  const vitalsDBService =
    useDBServices().consultationsDBService.vitals(consultationId);

  const [selectedVitalId, setSelectedVitalId] = useState<string | undefined>(
    data[0]?.id
  );

  const vitalEntry = data.find((d) => d.id === selectedVitalId);

  const vitalsSelectData = data.map((o) => ({
    value: o.id,
    label: tu.getDateAndTimeString(o.createdAt),
  }));

  const addNew = async () => {
    if (disabled) return;
    setSubmitState("pending-response");

    const result = await vitalsDBService.addVitalsEntry({
      consultationId,
      status: "ACTIVE",
    });

    if (result.type === "success") {
      setSubmitState("success");
      navigate(`vitals/${result.payload?.id}/new`);
    } else {
      setSubmitState("error");
    }
  };

  const deleteVital = async (id: string) => {
    if (disabled) return;
    setDeleteState("pending-response");

    const result = await vitalsDBService.deleteVitalsEntry(id);

    if (result.type === "success") {
      setDeleteState("success");
      // setSelectedVitalId(newVitals[0]?.id);
    } else {
      setDeleteState("error");
    }
  };

  return (
    <BBox
      header={
        <Group position="apart">
          <Text weight={500}>{t("VITALS")}</Text>
          <Button
            disabled={disabled}
            loading={submitState === "pending-response"}
            onClick={addNew}
          >
            {t("ADD_NEW")}
          </Button>
        </Group>
      }
    >
      {vitalEntry && (
        <>
          <Group
            p="xs"
            sx={{
              backgroundColor: "white",
              borderBottom: `1px solid ${theme.colors.gray[3]}`,
            }}
            position="apart"
          >
            <Select
              withinPortal
              data={vitalsSelectData}
              value={selectedVitalId}
              onChange={(v) => {
                v && setSelectedVitalId(v);
              }}
              clearable={false}
              allowDeselect={false}
            />
            <Group>
              <Button
                disabled={disabled}
                onClick={() => navigate(`vitals/${vitalEntry.id}/edit`)}
              >
                {t("EDIT")}
              </Button>
              <Button
                disabled={disabled}
                variant="outline"
                color="red"
                loading={deleteState === "pending-response"}
                onClick={() => deleteVital(vitalEntry.id)}
              >
                {t("DELETE")}
              </Button>
            </Group>
          </Group>

          <Stack p="xl">
            <Group grow align="flex-start">
              <Stack>
                <div>
                  <Text color="cyan">{t("TEMPERATURE")}</Text>
                  <Text>{vitalEntry.temp}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("MUCUS_MEMBRANE_COLOR")}</Text>
                  <Text>{vitalEntry.mucusColor}</Text>
                </div>
              </Stack>
              <Stack>
                <div>
                  <Text color="cyan">{t("PULSE")}</Text>
                  <Text>{vitalEntry.pulse}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("CAPILLARY_REFILL_TIME")}</Text>
                  <Text>{vitalEntry.capRefTime}</Text>
                </div>
              </Stack>
              <Stack>
                <div>
                  <Text color="cyan">{t("RESPIRATORY_RATE")}</Text>
                  <Text>{vitalEntry.resp}</Text>
                </div>
                <div>
                  <Text color="cyan">{t("MENTATION")}</Text>
                  <Text>{vitalEntry.mentation}</Text>
                </div>
              </Stack>
            </Group>
            <div>
              <Text color="cyan">{t("NOTES")}</Text>
              {HTMLReactParser(vitalEntry.notes ?? "")}
            </div>
          </Stack>
        </>
      )}
    </BBox>
  );
};
