import { z } from "zod";

// t("HOUR")
// t("DAY")
// t("WEEK")
// t("MONTH")
// t("BEFORE")
// t("AFTER")
export const appointmentNotificationTimeUnitTimeUnitEnum = z.enum([
  "HOUR",
  "DAY",
  "WEEK",
  "MONTH",
]);

export const appointmentNotificationTimeUnitSchema = z.object({
  id: z.string(),
  sendIn: z.number(),
  timeUnit: appointmentNotificationTimeUnitTimeUnitEnum,
  beforeAfter: z.enum(["BEFORE", "AFTER"]),
});

export type AppointmentNotificationTimeUnit = z.infer<
  typeof appointmentNotificationTimeUnitSchema
>;
