import { z } from "zod";
import { UnableToNormalizeData } from "../../errors";
import { providerDataSchema } from "../ProviderData";
import { userCustomClaimSchema } from "../UserCustomClaim";
import { userMetaDataDataSchema } from "../UserMetaData";

/**
 * schema and types versions
 * from first to last
 */

export const userSchema = z.object({
  uid: z.string(),
  displayName: z.string(),
  email: z.string().email(),
  emailVerified: z.boolean(),
  disabled: z.boolean(),
  tokensValidAfterTime: z.string(),
  providerData: providerDataSchema.array(),
  metadata: userMetaDataDataSchema,
  customClaims: userCustomClaimSchema,
});

export type User = z.infer<typeof userSchema>;

export const normalizeUser = (data: any): User => {
  try {
    const userV0: User = userSchema.parse(data);

    const { ...rest } = userV0;

    // here it's same object cause current v is 0
    const user: User = {
      ...rest,
    };
    return user;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
