import { Avatar, Group, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveDoctors } from "features/admin/members/Members.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface DoctorSelectProps {
  required?: boolean;
  onChange: ({ id, name }: { id: string; name: string }) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const DoctorSelect = (props: DoctorSelectProps) => {
  const { t } = useTranslation();
  const doctors = useAppSelector(selectActiveDoctors);

  const onSelectChange = (value?: string) => {
    if (value) {
      const doctor = doctors.find((o) => o.id === value);
      if (doctor) {
        props.onChange({
          id: value,
          name: doctor.name,
        });
      }
    }
  };

  const data = doctors.map((doctor) => ({
    value: doctor.id,
    label: doctor.name,
    thumbURL: doctor.thumbURL,
    description: doctor.email,
  }));

  return (
    <BSelect
      {...props}
      itemComponent={SelectItem}
      label={t("VETERINARIAN")}
      nothingFound={t("NOTHING_FOUND")}
      onChange={onSelectChange}
      data={data}
    />
  );
};
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  thumbURL: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ thumbURL, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={thumbURL} radius="xl" />

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);
