import { Box, Button, Group, Text } from "@mantine/core";
import { OrgPermissions } from "beitary-shared";
import { BBox } from "components";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MembersTable } from "./components";

export const Members = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BBox
      header={
        <Group position="apart">
          <Text size="sm" weight={500}>
            {t("MEMBERS_LIST")}
          </Text>
          <Group>
            <BCan I="do" a={OrgPermissions.MEMBERS_CUD}>
              <Button onClick={() => navigate(`new`)}>{t("ADD_NEW")}</Button>
            </BCan>
          </Group>
        </Group>
      }
    >
      <Box p="xl">
        <MembersTable />
      </Box>
    </BBox>
  );
};
