import { z } from "zod";

// export const timeZoneSchema = z.object({
//   zoneName: z.string(),
//   gmtOffset: z.number().nonnegative(),
//   gmtOffsetName: z.string(),
//   abbreviation: z.string(),
//   tzName: z.string(),
// });

// t("Europe/Andorra")
// t("Asia/Dubai")
// t("Asia/Kabul")
// t("Europe/Tirane")
// t("Asia/Yerevan")
// t("Antarctica/Casey")
// t("Antarctica/Davis")
// t("Antarctica/DumontDUrville")
// t("Antarctica/Mawson")
// t("Antarctica/Palmer")
// t("Antarctica/Rothera")
// t("Antarctica/Syowa")
// t("Antarctica/Troll")
// t("Antarctica/Vostok")
// t("America/Argentina/Buenos_Aires")
// t("America/Argentina/Cordoba")
// t("America/Argentina/Salta")
// t("America/Argentina/Jujuy")
// t("America/Argentina/Tucuman")
// t("America/Argentina/Catamarca")
// t("America/Argentina/La_Rioja")
// t("America/Argentina/San_Juan")
// t("America/Argentina/Mendoza")
// t("America/Argentina/San_Luis")
// t("America/Argentina/Rio_Gallegos")
// t("America/Argentina/Ushuaia")
// t("Pacific/Pago_Pago")
// t("Europe/Vienna")
// t("Australia/Lord_Howe")
// t("Antarctica/Macquarie")
// t("Australia/Hobart")
// t("Australia/Currie")
// t("Australia/Melbourne")
// t("Australia/Sydney")
// t("Australia/Broken_Hill")
// t("Australia/Brisbane")
// t("Australia/Lindeman")
// t("Australia/Adelaide")
// t("Australia/Darwin")
// t("Australia/Perth")
// t("Australia/Eucla")
// t("Asia/Baku")
// t("America/Barbados")
// t("Asia/Dhaka")
// t("Europe/Brussels")
// t("Europe/Sofia")
// t("Atlantic/Bermuda")
// t("Asia/Brunei")
// t("America/La_Paz")
// t("America/Noronha")
// t("America/Belem")
// t("America/Fortaleza")
// t("America/Recife")
// t("America/Araguaina")
// t("America/Maceio")
// t("America/Bahia")
// t("America/Sao_Paulo")
// t("America/Campo_Grande")
// t("America/Cuiaba")
// t("America/Santarem")
// t("America/Porto_Velho")
// t("America/Boa_Vista")
// t("America/Manaus")
// t("America/Eirunepe")
// t("America/Rio_Branco")
// t("America/Nassau")
// t("Asia/Thimphu")
// t("Europe/Minsk")
// t("America/Belize")
// t("America/St_Johns")
// t("America/Halifax")
// t("America/Glace_Bay")
// t("America/Moncton")
// t("America/Goose_Bay")
// t("America/Blanc-Sablon")
// t("America/Toronto")
// t("America/Nipigon")
// t("America/Thunder_Bay")
// t("America/Iqaluit")
// t("America/Pangnirtung")
// t("America/Atikokan")
// t("America/Winnipeg")
// t("America/Rainy_River")
// t("America/Resolute")
// t("America/Rankin_Inlet")
// t("America/Regina")
// t("America/Swift_Current")
// t("America/Edmonton")
// t("America/Cambridge_Bay")
// t("America/Yellowknife")
// t("America/Inuvik")
// t("America/Creston")
// t("America/Dawson_Creek")
// t("America/Fort_Nelson")
// t("America/Vancouver")
// t("America/Whitehorse")
// t("America/Dawson")
// t("Indian/Cocos")
// t("Europe/Zurich")
// t("Africa/Abidjan")
// t("Pacific/Rarotonga")
// t("America/Santiago")
// t("America/Punta_Arenas")
// t("Pacific/Easter")
// t("Asia/Shanghai")
// t("Asia/Urumqi")
// t("America/Bogota")
// t("America/Costa_Rica")
// t("America/Havana")
// t("Atlantic/Cape_Verde")
// t("America/Curacao")
// t("Indian/Christmas")
// t("Asia/Nicosia")
// t("Asia/Famagusta")
// t("Europe/Prague")
// t("Europe/Berlin")
// t("Europe/Copenhagen")
// t("America/Santo_Domingo")
// t("Africa/Algiers")
// t("America/Guayaquil")
// t("Pacific/Galapagos")
// t("Europe/Tallinn")
// t("Africa/Cairo")
// t("Africa/El_Aaiun")
// t("Europe/Madrid")
// t("Africa/Ceuta")
// t("Atlantic/Canary")
// t("Europe/Helsinki")
// t("Pacific/Fiji")
// t("Atlantic/Stanley")
// t("Pacific/Chuuk")
// t("Pacific/Pohnpei")
// t("Pacific/Kosrae")
// t("Atlantic/Faroe")
// t("Europe/Paris")
// t("Europe/London")
// t("Asia/Tbilisi")
// t("America/Cayenne")
// t("Africa/Accra")
// t("Europe/Gibraltar")
// t("America/Godthab")
// t("America/Danmarkshavn")
// t("America/Scoresbysund")
// t("America/Thule")
// t("Europe/Athens")
// t("Atlantic/South_Georgia")
// t("America/Guatemala")
// t("Pacific/Guam")
// t("Africa/Bissau")
// t("America/Guyana")
// t("Asia/Hong_Kong")
// t("America/Tegucigalpa")
// t("America/Port-au-Prince")
// t("Europe/Budapest")
// t("Asia/Jakarta")
// t("Asia/Pontianak")
// t("Asia/Makassar")
// t("Asia/Jayapura")
// t("Europe/Dublin")
// t("Asia/Jerusalem")
// t("Asia/Kolkata")
// t("Indian/Chagos")
// t("Asia/Baghdad")
// t("Asia/Tehran")
// t("Atlantic/Reykjavik")
// t("Europe/Rome")
// t("America/Jamaica")
// t("Asia/Amman")
// t("Asia/Tokyo")
// t("Africa/Nairobi")
// t("Asia/Bishkek")
// t("Pacific/Tarawa")
// t("Pacific/Enderbury")
// t("Pacific/Kiritimati")
// t("Asia/Pyongyang")
// t("Asia/Seoul")
// t("Asia/Almaty")
// t("Asia/Qyzylorda")
// t("Asia/Qostanay")
// t("Asia/Aqtobe")
// t("Asia/Aqtau")
// t("Asia/Atyrau")
// t("Asia/Oral")
// t("Asia/Beirut")
// t("Asia/Colombo")
// t("Africa/Monrovia")
// t("Europe/Vilnius")
// t("Europe/Luxembourg")
// t("Europe/Riga")
// t("Africa/Tripoli")
// t("Africa/Casablanca")
// t("Europe/Monaco")
// t("Europe/Chisinau")
// t("Pacific/Majuro")
// t("Pacific/Kwajalein")
// t("Asia/Yangon")
// t("Asia/Ulaanbaatar")
// t("Asia/Hovd")
// t("Asia/Choibalsan")
// t("Asia/Macau")
// t("America/Martinique")
// t("Europe/Malta")
// t("Indian/Mauritius")
// t("Indian/Maldives")
// t("America/Mexico_City")
// t("America/Cancun")
// t("America/Merida")
// t("America/Monterrey")
// t("America/Matamoros")
// t("America/Mazatlan")
// t("America/Chihuahua")
// t("America/Ojinaga")
// t("America/Hermosillo")
// t("America/Tijuana")
// t("America/Bahia_Banderas")
// t("Asia/Kuala_Lumpur")
// t("Asia/Kuching")
// t("Africa/Maputo")
// t("Africa/Windhoek")
// t("Pacific/Noumea")
// t("Pacific/Norfolk")
// t("Africa/Lagos")
// t("America/Managua")
// t("Europe/Amsterdam")
// t("Europe/Oslo")
// t("Asia/Kathmandu")
// t("Pacific/Nauru")
// t("Pacific/Niue")
// t("Pacific/Auckland")
// t("Pacific/Chatham")
// t("America/Panama")
// t("America/Lima")
// t("Pacific/Tahiti")
// t("Pacific/Marquesas")
// t("Pacific/Gambier")
// t("Pacific/Port_Moresby")
// t("Pacific/Bougainville")
// t("Asia/Manila")
// t("Asia/Karachi")
// t("Europe/Warsaw")
// t("America/Miquelon")
// t("Pacific/Pitcairn")
// t("America/Puerto_Rico")
// t("Asia/Gaza")
// t("Asia/Hebron")
// t("Europe/Lisbon")
// t("Atlantic/Madeira")
// t("Atlantic/Azores")
// t("Pacific/Palau")
// t("America/Asuncion")
// t("Asia/Qatar")
// t("Indian/Reunion")
// t("Europe/Bucharest")
// t("Europe/Belgrade")
// t("Europe/Kaliningrad")
// t("Europe/Moscow")
// t("Europe/Simferopol")
// t("Europe/Kirov")
// t("Europe/Astrakhan")
// t("Europe/Volgograd")
// t("Europe/Saratov")
// t("Europe/Ulyanovsk")
// t("Europe/Samara")
// t("Asia/Yekaterinburg")
// t("Asia/Omsk")
// t("Asia/Novosibirsk")
// t("Asia/Barnaul")
// t("Asia/Tomsk")
// t("Asia/Novokuznetsk")
// t("Asia/Krasnoyarsk")
// t("Asia/Irkutsk")
// t("Asia/Chita")
// t("Asia/Yakutsk")
// t("Asia/Khandyga")
// t("Asia/Vladivostok")
// t("Asia/Ust-Nera")
// t("Asia/Magadan")
// t("Asia/Sakhalin")
// t("Asia/Srednekolymsk")
// t("Asia/Kamchatka")
// t("Asia/Anadyr")
// t("Asia/Riyadh")
// t("Pacific/Guadalcanal")
// t("Indian/Mahe")
// t("Africa/Khartoum")
// t("Europe/Stockholm")
// t("Asia/Singapore")
// t("America/Paramaribo")
// t("Africa/Juba")
// t("Africa/Sao_Tome")
// t("America/El_Salvador")
// t("Asia/Damascus")
// t("America/Grand_Turk")
// t("Africa/Ndjamena")
// t("Indian/Kerguelen")
// t("Asia/Bangkok")
// t("Asia/Dushanbe")
// t("Pacific/Fakaofo")
// t("Asia/Dili")
// t("Asia/Ashgabat")
// t("Africa/Tunis")
// t("Pacific/Tongatapu")
// t("Europe/Istanbul")
// t("America/Port_of_Spain")
// t("Pacific/Funafuti")
// t("Asia/Taipei")
// t("Europe/Kiev")
// t("Europe/Uzhgorod")
// t("Europe/Zaporozhye")
// t("Pacific/Wake")
// t("America/New_York")
// t("America/Detroit")
// t("America/Kentucky/Louisville")
// t("America/Kentucky/Monticello")
// t("America/Indiana/Indianapolis")
// t("America/Indiana/Vincennes")
// t("America/Indiana/Winamac")
// t("America/Indiana/Marengo")
// t("America/Indiana/Petersburg")
// t("America/Indiana/Vevay")
// t("America/Chicago")
// t("America/Indiana/Tell_City")
// t("America/Indiana/Knox")
// t("America/Menominee")
// t("America/North_Dakota/Center")
// t("America/North_Dakota/New_Salem")
// t("America/North_Dakota/Beulah")
// t("America/Denver")
// t("America/Boise")
// t("America/Phoenix")
// t("America/Los_Angeles")
// t("America/Anchorage")
// t("America/Juneau")
// t("America/Sitka")
// t("America/Metlakatla")
// t("America/Yakutat")
// t("America/Nome")
// t("America/Adak")
// t("Pacific/Honolulu")
// t("America/Montevideo")
// t("Asia/Samarkand")
// t("Asia/Tashkent")
// t("America/Caracas")
// t("Asia/Ho_Chi_Minh")
// t("Pacific/Efate")
// t("Pacific/Wallis")
// t("Pacific/Apia")
// t("Africa/Johannesburg")

export const timezoneEnum = z.enum([
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay",
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
]);

export type TimeZone = z.infer<typeof timezoneEnum>;
