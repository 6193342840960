import {
  BoardingResource,
  BoardingResourceData,
  BOARDING_RESOURCE_VERSION,
  errorResult,
  normalizeBoardingResource,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

// add organization boardingResource

interface AddBoardingResource {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: BoardingResourceData;
  }): Promise<Result<boolean | null>>;
}

const addBoardingResource: AddBoardingResource = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new boardingResource id
    const newBoardingResourceRef = doc(
      collection(db, "organizations", organizationId, "boarding_resources")
    );

    const newBoardingResource: BoardingResource = normalizeBoardingResource({
      ...data,
      id: newBoardingResourceRef.id,
      authorId,
      authorName,
      version: BOARDING_RESOURCE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newBoardingResourceRef, newBoardingResource);

    // t("BOARDING_RESOURCE_CREATED")
    const successMessage = "BOARDING_RESOURCE_CREATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetBoardingResource {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<BoardingResource | null>>;
}

const getBoardingResource: GetBoardingResource = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const boardingResourceDocRef = doc(
      db,
      "organizations",
      organizationId,
      "boarding_resources",
      id
    );
    const boardingResourceDocSnapshot = await getDoc(boardingResourceDocRef);
    if (boardingResourceDocSnapshot.exists()) {
      try {
        const data: unknown = boardingResourceDocSnapshot.data();
        const boardingResource: BoardingResource =
          normalizeBoardingResource(data);
        // t("BOARDING_RESOURCE_FOUND")
        const successMessage = "BOARDING_RESOURCE_FOUND";
        return successResult({
          message: successMessage,
          payload: boardingResource,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("BOARDING_RESOURCE_NOT_FOUND")
      const errorMessage = "BOARDING_RESOURCE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateBoardingResource {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<BoardingResourceData>;
  }): Promise<Result<boolean | null>>;
}

const updateBoardingResource: UpdateBoardingResource = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const ref = doc(
      db,
      "organizations",
      organizationId,
      "boarding_resources",
      id
    );

    const updates: Partial<BoardingResource> = {
      ...data,
      authorId,
      authorName,
      version: BOARDING_RESOURCE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(ref, updates);

    // t("BOARDING_RESOURCE_NOT_FOUND")
    const successMessage = "BOARDING_RESOURCE_NOT_FOUND";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteBoardingResource {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteBoardingResource: DeleteBoardingResource = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "boarding_resources",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("BOARDING_RESOURCE_DELETED")
      const successMessage = "BOARDING_RESOURCE_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// get organization boardingResources listener
interface GetBoardingResourcesListenerCallback {
  (boardingResources: BoardingResource[]): void;
}
interface GetBoardingResourcesListener {
  db: Firestore;
  organizationId: string;
  callback: GetBoardingResourcesListenerCallback;
}

const getBoardingResourcesListener = ({
  db,
  organizationId,
  callback,
}: GetBoardingResourcesListener): Unsubscribe => {
  try {
    // console.log("getBoardingResourcesListener: new listener");
    const boardingResourcesQuery = query(
      collection(db, "organizations", organizationId, "boarding_resources"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(boardingResourcesQuery, (querySnapshot) => {
      const boardingResources: BoardingResource[] = [];
      querySnapshot.forEach((doc) => {
        try {
          boardingResources.push(normalizeBoardingResource(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(boardingResources);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const boardingResources = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getBoardingResourcesListener: (
      callback: GetBoardingResourcesListenerCallback
    ) =>
      getBoardingResourcesListener({
        db,
        organizationId,
        callback,
      }),
    getBoardingResource: (id: string) =>
      getBoardingResource({
        db,
        organizationId,
        id,
      }),
    addBoardingResource: (data: BoardingResourceData) =>
      addBoardingResource({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editBoardingResource: (id: string, data: BoardingResourceData) =>
      updateBoardingResource({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteBoardingResource: (id: string) =>
      deleteBoardingResource({
        db,
        organizationId,
        id,
      }),
    archiveBoardingResource: (id: string) =>
      updateBoardingResource({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchiveBoardingResource: (id: string) =>
      updateBoardingResource({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
