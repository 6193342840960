import { z } from "zod";

// t("OTHER")
// t("VACCINE")
// t("MEDICATION")
// t("SERVICE")
export const productTypeEnum = z.enum([
  "MEDICATION",
  "VACCINE",
  "SERVICE",
  "OTHER",
]);
export type ProductType = z.infer<typeof productTypeEnum>;
