import {
  AdditionalTax,
  errorResult,
  id_util,
  Invoice,
  InvoiceData,
  InvoiceLineItem,
  InvoiceLineItemData,
  InvoicePayment,
  INVOICE_LINE_ITEM_VERSION,
  INVOICE_VERSION,
  normalizeInvoice,
  normalizeInvoiceLineItem,
  obju,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";

// add organization invoice

interface AddInvoice {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: InvoiceData;
    items?: InvoiceLineItem[];
  }): Promise<Result<Invoice | null>>;
}

const addInvoice: AddInvoice = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
  items,
}) => {
  try {
    const newInvoiceRef = doc(
      collection(db, "organizations", organizationId, "invoices")
    );

    const newInvoice: Invoice = normalizeInvoice({
      ...data,
      id: newInvoiceRef.id,
      authorId,
      authorName,
      version: INVOICE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newInvoiceRef, newInvoice);

    if (items) {
      const lineItems: { [id: string]: InvoiceLineItem } = (items ?? []).reduce(
        (acc: { [id: string]: InvoiceLineItem }, item) => {
          const newItem: InvoiceLineItem = {
            ...item,
            authorId,
            authorName,
            version: INVOICE_LINE_ITEM_VERSION,
            source,
            createdAt: tu.getCurrentDateTime(),
            lastUpdatedAt: tu.getCurrentDateTime(),
          };
          obju.removeUndefined(newItem);
          acc[item.id] = newItem;
          return acc;
        },
        {}
      );

      const updates: Partial<InvoiceData> = {
        lineItems,
      };
      await updateDoc(newInvoiceRef, updates);
    }

    // t("INVOICE_CREATED")
    const successMessage = "INVOICE_CREATED";

    return successResult({
      message: successMessage,
      payload: newInvoice,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface AddInvoiceItems {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    invoiceId,
    dataArr,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    invoiceId: string;
    authorName: string;
    source: Source;
    dataArr: (InvoiceLineItemData & { id: string })[];
  }): Promise<Result<InvoiceLineItemData[] | null>>;
}

const addInvoiceItems: AddInvoiceItems = async ({
  db,
  organizationId,
  authorId,
  authorName,
  invoiceId,
  source,
  dataArr,
}) => {
  try {
    const invoiceRef = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      invoiceId
    );

    // console.log("dataArr");
    // console.log(dataArr);

    // this is to handle fixed price bundles
    // fixed price bundles have bundleId===pId
    // and are present only one here cauz multiselect
    // so we get their ids
    // and if a tx has a bundleid in the list
    // we pId = bundleId
    // this si to make sure we don't get same id for different
    // addition of same bundle

    const newItems: InvoiceLineItemData[] = [];

    let newLineItems: { [id: string]: InvoiceLineItem } = {};

    let bundlesRecord: { [id: string]: InvoiceLineItemData } = {};

    dataArr.forEach((p) => {
      const { bundleId, bundleName } = p;

      // add bundle item to invoice
      // otherwise (if regular product) push it to arr
      if (bundleId && bundleName) {
        bundlesRecord[p.id] = p;
      } else {
        newItems.push(p);
      }
    });

    const bundles = Object.values(bundlesRecord);

    newItems.push(...bundles);

    // make sure we have bundles first
    newItems.sort((a, b) => {
      if (a.pId === a.bundleId && b.pId !== b.bundleId) {
        return -1; // a comes before b
      } else if (a.pId !== a.bundleId && b.pId === b.bundleId) {
        return 1; // b comes before a
      } else {
        return 0; // order is irrelevant
      }
    });

    // console.log("newItems sorted");
    // console.log(newItems);

    const ids: { [originalBundleId: string]: string } = {};

    newItems.forEach((data) => {
      const newId = id_util.newId20();

      if (data.bundleId && data.bundleId === data.pId && !ids[data.bundleId]) {
        ids[data.bundleId] = newId;
      }

      // we know for sure that we have bundles first cauz sorted

      let newData = { ...data };

      // whether it is a fpbundle item or a product of a fpbundle
      // we give it the id of the bundle tx
      if (data.bundleId) {
        newData["bundleId"] = ids[data.bundleId];
      }

      const newObj: InvoiceLineItem = normalizeInvoiceLineItem({
        ...newData,
        id: newId,
        authorId,
        authorName,
        version: INVOICE_LINE_ITEM_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      newLineItems[newId] = newObj;
    });

    const updates: Partial<Invoice> = {
      lineItems: newLineItems,
      lastUpdatedAt: tu.getCurrentDateTime(),
      authorId,
      authorName,
    };

    await setDoc(invoiceRef, updates, { merge: true });

    // t("INVOICE_LINE_ITEM_ADDED")
    const successMessage = "INVOICE_LINE_ITEM_ADDED";
    return successResult({
      message: successMessage,
      payload: newItems,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetInvoice {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Invoice | null>>;
}

const getInvoice: GetInvoice = async ({ db, organizationId, id }) => {
  try {
    const invoiceDocRef = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      id
    );
    const invoiceDocSnapshot = await getDoc(invoiceDocRef);
    if (invoiceDocSnapshot.exists()) {
      try {
        const data: unknown = invoiceDocSnapshot.data();
        const invoice: Invoice = normalizeInvoice(data);
        // t("INVOICE_FOUND")
        const successMessage = "INVOICE_FOUND";
        return successResult({
          message: successMessage,
          payload: invoice,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("INVOICE_NOT_FOUND")
      const errorMessage = "INVOICE_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetClientInvoices {
  ({
    db,
    organizationId,
    clientId,
  }: {
    db: Firestore;
    organizationId: string;
    clientId: string;
  }): Promise<Result<Invoice[] | null>>;
}

const getClientInvoices: GetClientInvoices = async ({
  db,
  organizationId,
  clientId,
}) => {
  try {
    let invoicesQuery = query(
      collection(db, "organizations", organizationId, "invoices"),
      where("clientId", "==", clientId)
    );

    const querySnapshot = await getDocs(invoicesQuery);

    const invoices: Invoice[] = [];
    querySnapshot.forEach((doc) => {
      try {
        invoices.push(normalizeInvoice(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });
    // t("INVOICE_NOT_FOUND")
    const successMessage = "INVOICE_NOT_FOUND";
    return successResult({
      message: successMessage,
      payload: invoices,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateInvoice {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<InvoiceData>;
  }): Promise<Result<boolean | null>>;
}

const updateInvoice: UpdateInvoice = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "invoices", id);

    const updates: Partial<Invoice> = {
      ...data,
      authorId,
      authorName,
      version: INVOICE_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("INVOICE_UPDATED")
    const successMessage = "INVOICE_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface GetInvoiceListenerCallback {
  (invoice: Invoice | null): void;
}
interface GetInvoiceListener {
  db: Firestore;
  organizationId: string;
  invoiceId: string;
  callback: GetInvoiceListenerCallback;
}

const getInvoiceListener = ({
  db,
  organizationId,
  invoiceId,
  callback,
}: GetInvoiceListener): Unsubscribe => {
  try {
    // console.log("getInvoicesListener: new listener");
    const invoiceQuery = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      invoiceId
    );

    return onSnapshot(invoiceQuery, (querySnapshot) => {
      if (!querySnapshot.exists()) return callback(null);
      try {
        callback(normalizeInvoice(querySnapshot.data()));
      } catch (err) {
        console.log(err);
        callback(null);
      }
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface DeleteInvoice {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteInvoice: DeleteInvoice = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "invoices", id);

    try {
      await deleteDoc(docRef);
      // t("INVOICE_DELETED")
      const successMessage = "INVOICE_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetClientNonPaidInvoicesListenerCallback {
  (invoices: Invoice[]): void;
}
interface GetClientNonPaidInvoicesListener {
  db: Firestore;
  organizationId: string;
  clientId: string;
  callback: GetClientNonPaidInvoicesListenerCallback;
}

const getClientNonPaidInvoicesListener = ({
  db,
  organizationId,
  clientId,
  callback,
}: GetClientNonPaidInvoicesListener): Unsubscribe => {
  try {
    // console.log("getClientNonPaidInvoicesListener: new listener");
    const invoicesQuery = query(
      collection(db, "organizations", organizationId, "invoices"),
      where("balanceDue", "!=", 0),
      where("clientId", "==", clientId)
    );
    return onSnapshot(invoicesQuery, (querySnapshot) => {
      const invoices: Invoice[] = [];
      querySnapshot.forEach((doc) => {
        try {
          invoices.push(normalizeInvoice(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(invoices);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface GetClientInvoicesListenerCallback {
  (invoices: Invoice[]): void;
}
interface GetClientInvoicesListener {
  db: Firestore;
  organizationId: string;
  clientId: string;
  callback: GetClientInvoicesListenerCallback;
}

const getClientInvoicesListener = ({
  db,
  organizationId,
  clientId,
  callback,
}: GetClientInvoicesListener): Unsubscribe => {
  try {
    // console.log("getClientInvoicesListener: new listener");
    const invoicesQuery = query(
      collection(db, "organizations", organizationId, "invoices"),
      where("clientId", "==", clientId),
      orderBy("createdAt", "desc")
    );
    return onSnapshot(invoicesQuery, (querySnapshot) => {
      const invoices: Invoice[] = [];
      querySnapshot.forEach((doc) => {
        try {
          invoices.push(normalizeInvoice(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(invoices);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface UpdateInvoiceLineItem {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    invoiceId,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    invoiceId: string;
    id: string;
    source: Source;
    data: Partial<InvoiceLineItemData>;
  }): Promise<Result<Partial<InvoiceLineItemData> | null>>;
}

const updateInvoiceLineItem: UpdateInvoiceLineItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  invoiceId,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      invoiceId
    );

    const updates: Partial<InvoiceLineItem> = {
      ...data,
      authorId,
      authorName,
      version: INVOICE_LINE_ITEM_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await setDoc(
      docRef,
      {
        lineItems: {
          [id]: updates,
        },
        lastUpdatedAt: tu.getCurrentDateTime(),
      },
      { merge: true }
    );

    // t("INVOICE_UPDATED")
    const successMessage = "INVOICE_UPDATED";

    return successResult({
      message: successMessage,
      payload: updates,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteInvoiceLineItems {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    invoiceId,
    ids,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    invoiceId: string;
    ids: string[];
  }): Promise<Result<boolean | null>>;
}

const deleteInvoiceLineItems: DeleteInvoiceLineItems = async ({
  db,
  organizationId,
  authorId,
  authorName,
  invoiceId,
  ids,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      invoiceId
    );

    const fieldsToDelete = ids.map((id) => [id, deleteField()]);

    await setDoc(
      docRef,
      {
        lineItems: {
          ...Object.fromEntries(fieldsToDelete),
        },
        lastUpdatedAt: tu.getCurrentDateTime(),
        authorId,
        authorName,
      },
      { merge: true }
    );

    // t("INVOICE_UPDATED")
    const successMessage = "INVOICE_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

////

interface UpdateAdditionalTaxes {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    invoiceId: string;
    source: Source;
    data: AdditionalTax[];
  }): Promise<Result<Partial<InvoiceLineItemData> | null>>;
}

const updateAdditionalTaxes: UpdateAdditionalTaxes = async ({
  db,
  organizationId,
  authorId,
  authorName,
  invoiceId,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      invoiceId
    );

    const updates: Partial<Invoice> = {
      additionalTaxes: data,
      authorId,
      authorName,
      version: INVOICE_LINE_ITEM_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("INVOICE_UPDATED")
    const successMessage = "INVOICE_UPDATED";

    return successResult({
      message: successMessage,
      payload: updates,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization invoiceLineItems listener
interface GetInvoiceLineItemsListenerCallback {
  (invoiceLineItems: InvoiceLineItem[]): void;
}
interface GetInvoicePaymentsListenerCallback {
  (invoiceLineItems: InvoicePayment[]): void;
}
interface GetInvoiceLineItemsAndPaymentsListener {
  db: Firestore;
  organizationId: string;
  invoiceId: string;
  lineItemsCallback: GetInvoiceLineItemsListenerCallback;
  paymentsCallback: GetInvoicePaymentsListenerCallback;
}

const getInvoiceLineItemsAndPaymentsListener = ({
  db,
  organizationId,
  invoiceId,
  lineItemsCallback,
  paymentsCallback,
}: GetInvoiceLineItemsAndPaymentsListener): Unsubscribe => {
  try {
    // console.log("getInvoiceLineItemsAndPaymentsListener: new listener");

    const invoiceQuery = doc(
      db,
      "organizations",
      organizationId,
      "invoices",
      invoiceId
    );

    return onSnapshot(invoiceQuery, (querySnapshot) => {
      let invoiceLineItems: InvoiceLineItem[] = [];
      let invoicePayments: InvoicePayment[] = [];
      try {
        const invoice = normalizeInvoice(querySnapshot.data());
        invoiceLineItems = Object.values(invoice.lineItems);
        invoicePayments = Object.values(invoice.payments);
      } catch (err) {
        console.log(err);
      }
      lineItemsCallback(invoiceLineItems);
      paymentsCallback(invoicePayments);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface GetInvoices {
  ({
    db,
    organizationId,
    startAfterTime,
    startBeforeTime,
    startAtTime,
    clientId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    startAfterTime?: number;
    startBeforeTime?: number;
    startAtTime?: number;
    clientId?: string;
    patientId?: string;
  }): Promise<Result<Invoice[] | null>>;
}

const getInvoices: GetInvoices = async ({
  db,
  organizationId,
  startAfterTime,
  startBeforeTime,
  startAtTime,
  clientId,
  patientId,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "invoices")
    );

    if (clientId) {
      newQuery = query(newQuery, where("clientId", "==", clientId));
    }

    if (patientId) {
      newQuery = query(newQuery, where("patientId", "==", patientId));
    }

    newQuery = query(newQuery, orderBy("createdAt", "desc"), limit(PAGE_SIZE));

    // FirebaseError: Invalid query. You must not call startAt() or startAfter() before calling orderBy().
    if (startBeforeTime) {
      newQuery = query(
        newQuery,
        endBefore(startBeforeTime),
        limitToLast(PAGE_SIZE)
      );
    }

    if (startAfterTime) {
      newQuery = query(newQuery, startAfter(startAfterTime));
    }

    if (startAtTime) {
      newQuery = query(newQuery, startAt(startAtTime));
    }

    const querySnapshot = await getDocs(newQuery);

    const invoices: Invoice[] = [];

    querySnapshot.forEach((doc) => {
      try {
        invoices.push(normalizeInvoice(doc.data()));
      } catch (error) {
        console.log(error);
      }
    });

    return successResult({
      message: "SUCCESS",
      payload: invoices,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const invoices = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getInvoices: (props: {
      startAfterTime?: number;
      startBeforeTime?: number;
      startAtTime?: number;
      clientId?: string;
      patientId?: string;
      showLocked?: boolean;
    }) =>
      getInvoices({
        db,
        organizationId,
        ...props,
      }),
    getInvoiceListener: (
      invoiceId: string,
      callback: GetInvoiceListenerCallback
    ) =>
      getInvoiceListener({
        db,
        organizationId,
        invoiceId,
        callback,
      }),
    getInvoice: (id: string) =>
      getInvoice({
        db,
        organizationId,
        id,
      }),
    getClientInvoices: (clientId: string) =>
      getClientInvoices({
        db,
        organizationId,
        clientId,
      }),
    getClientNonPaidInvoicesListener: (
      clientId: string,
      callback: GetClientNonPaidInvoicesListenerCallback
    ) =>
      getClientNonPaidInvoicesListener({
        db,
        organizationId,
        clientId,
        callback,
      }),
    getClientInvoicesListener: (
      clientId: string,
      callback: GetClientInvoicesListenerCallback
    ) =>
      getClientInvoicesListener({
        db,
        organizationId,
        clientId,
        callback,
      }),
    addInvoice: (data: InvoiceData, items?: InvoiceLineItem[]) =>
      addInvoice({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
        items,
      }),
    addInvoiceItems: ({
      invoiceId,
      dataArr,
    }: {
      invoiceId: string;
      dataArr: (InvoiceLineItemData & { id: string })[];
    }) =>
      addInvoiceItems({
        db,
        organizationId,
        authorId,
        authorName,
        invoiceId,
        source,
        dataArr,
      }),
    markInvoiceAsChargesComplete: (id: string) =>
      updateInvoice({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "CHARGES_COMPLETE" },
      }),
    markInvoiceAsCheckedOut: (id: string) =>
      updateInvoice({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "CHECKED_OUT", checkedOutAt: Date.now() },
      }),
    markInvoiceAsActive: (id: string) =>
      updateInvoice({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),

    updateInvoice: (id: string, data: Partial<InvoiceData>) =>
      updateInvoice({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),

    deleteInvoiceLineItems: ({
      ids,
      invoiceId,
    }: {
      ids: string[];
      invoiceId: string;
    }) =>
      deleteInvoiceLineItems({
        authorId,
        authorName,
        db,
        ids,
        invoiceId,
        organizationId,
      }),
    updatItemDiscount: ({
      id,
      invoiceId,
      discount,
    }: {
      id: string;
      invoiceId: string;
      discount: number;
    }) =>
      updateInvoiceLineItem({
        authorId,
        authorName,
        data: {
          discount,
        },
        db,
        id,
        invoiceId,
        organizationId,
        source,
      }),
    updateInvoiceLineItem: ({
      id,
      invoiceId,
      data,
    }: {
      id: string;
      invoiceId: string;
      data: Partial<InvoiceLineItemData>;
    }) =>
      updateInvoiceLineItem({
        authorId,
        authorName,
        data,
        db,
        id,
        invoiceId,
        organizationId,
        source,
      }),
    getInvoiceLineItemsAndPaymentsListener: ({
      invoiceId,
      lineItemsCallback,
      paymentsCallback,
    }: {
      invoiceId: string;
      lineItemsCallback: GetInvoiceLineItemsListenerCallback;
      paymentsCallback: GetInvoicePaymentsListenerCallback;
    }) =>
      getInvoiceLineItemsAndPaymentsListener({
        db,
        organizationId,
        invoiceId,
        lineItemsCallback,
        paymentsCallback,
      }),
    updateAdditionalTaxes: ({
      invoiceId,
      additionalTaxes,
    }: {
      invoiceId: string;
      additionalTaxes: AdditionalTax[];
    }) =>
      updateAdditionalTaxes({
        authorId,
        authorName,
        data: additionalTaxes,
        db,
        invoiceId,
        organizationId,
        source,
      }),
    deleteInvoice: (id: string) =>
      deleteInvoice({
        db,
        organizationId,
        id,
      }),
  };
};
