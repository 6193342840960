import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Clients } from "features/Clients";
import { Client } from "features/Clients/components";
import {
  EditClient,
  NewClientForm,
  Patient,
} from "features/Clients/components/Client/components";
import {
  AddPatient,
  EditPatient,
  PatientLifecycleSearchContainer,
} from "features/Clients/components/Client/components/Patient/components";
import { Outlet, Route } from "react-router-dom";

export const ClientRoutes = (
  <Route path="/clients" element={<Outlet />}>
    <Route index element={<Clients />} />
    <Route path=":clientId" element={<Client />} />
    {/* <Route
      path=":clientId/patients/:patientId"
      element={<>patient of client</>}
    /> */}

    <Route
      path="new"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.PATIENTS_CLIENTS_CUD]}
          element={<NewClientForm />}
        />
      }
    />
    <Route
      path="edit/:clientId"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.PATIENTS_CLIENTS_CUD]}
          element={<EditClient />}
        />
      }
    />

    <Route path=":clientId/patients/:patientId" element={<Patient />} />
    <Route
      path=":clientId/patients/:patientId/lifecycle"
      element={<PatientLifecycleSearchContainer />}
    />
    <Route
      path=":clientId/patients/new"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.PATIENTS_CLIENTS_CUD]}
          element={<AddPatient />}
        />
      }
    />
    <Route
      path=":clientId/patients/edit/:patientId"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.PATIENTS_CLIENTS_CUD]}
          element={<EditPatient />}
        />
      }
    />
  </Route>
);
