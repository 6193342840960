import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { prescriptionItemSchema } from "./Prescription.item";
import { prescriptionTypeEnum } from "./Prescription.type";

/**
 * schema and types versions
 * from first to last
 */

// v0

const prescriptionDataV0Schema = z.object({
  consultationId: z.string().optional(),
  items: z.array(prescriptionItemSchema),
  patientId: z.string(),
  patientName: z.string(),
  clientId: z.string(),
  clientName: z.string(),
  prescribingDrId: z.string(),
  prescribingDrName: z.string(),
  type: prescriptionTypeEnum,
});

type PrescriptionDataV0 = z.infer<typeof prescriptionDataV0Schema>;

const prescriptionV0Schema = baseRecordSchema.merge(prescriptionDataV0Schema);

type PrescriptionV0 = z.infer<typeof prescriptionV0Schema>;

const normalizePrescriptionV0 = (data: any): Prescription => {
  try {
    const prescriptionV0: PrescriptionV0 = prescriptionV0Schema.parse(data);

    const { ...rest } = prescriptionV0;

    // here it's same object cause current v is 0
    const prescription: Prescription = {
      ...rest,
    };
    return prescription;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PRESCRIPTION_VERSION = "0";

export type PrescriptionData = PrescriptionDataV0;

export type Prescription = BaseRecord & PrescriptionData;

export const prescriptionDataSchema = prescriptionDataV0Schema;

export const prescriptionSchema = prescriptionV0Schema;

export const normalizePrescription = (data: any): Prescription => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizePrescriptionV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
