import { createStyles } from "@mantine/core";
import {
  IconGenderAgender,
  IconGenderAndrogyne,
  IconGenderDemiboy,
  IconGenderDemigirl,
  IconGenderFemale,
  IconGenderMale,
  IconGenderNeutrois,
} from "@tabler/icons-react";
import { PatientSex } from "beitary-shared";

const useStyles = createStyles((theme) => ({
  iconMale: {
    color: theme.colors.blue[5],
  },
  iconFemale: {
    color: theme.colors.pink[5],
  },
  iconMaleNeutered: {
    color: theme.colors.cyan[3],
    transform: "rotate(45deg)",
  },
  iconMaleMonorchid: {
    color: theme.colors.cyan[3],
  },
  iconMaleCryptorchid: {
    color: theme.colors.cyan[3],
  },
  iconFemaleSpayedOV: {
    color: theme.colors.grape[3],
  },
  iconFemaleSpayedHY: {
    color: theme.colors.grape[3],
    transform: "scale(-1, 1)",
  },
  iconFemaleSpayedOVHY: {
    color: theme.colors.grape[3],
    transform: "scale(-1)",
  },
  iconUndetermined: {
    color: theme.colors.gray[5],
  },
}));

export interface BSexIconProps {
  sex: PatientSex;
  size?: number;
}

export const BSexIcon = ({ sex, size }: BSexIconProps) => {
  const { classes } = useStyles();

  const sexIcon =
    sex === "MALE" ? (
      <IconGenderMale className={classes.iconMale} size={size} />
    ) : sex === "FEMALE" ? (
      <IconGenderFemale className={classes.iconFemale} size={size} />
    ) : sex === "MALE_NEUTERED" ? (
      <IconGenderNeutrois className={classes.iconMaleNeutered} size={size} />
    ) : sex === "MALE_MONORCHID" ? (
      <IconGenderDemiboy className={classes.iconMaleMonorchid} size={size} />
    ) : sex === "MALE_CRYPTORCHID" ? (
      <IconGenderAndrogyne
        className={classes.iconMaleCryptorchid}
        size={size}
      />
    ) : sex === "FEMALE_SPAYED_OV" ? (
      <IconGenderDemigirl className={classes.iconFemaleSpayedOV} size={size} />
    ) : sex === "FEMALE_SPAYED_HY" ? (
      <IconGenderDemigirl className={classes.iconFemaleSpayedHY} size={size} />
    ) : sex === "FEMALE_SPAYED_OV_HY" ? (
      <IconGenderNeutrois
        className={classes.iconFemaleSpayedOVHY}
        size={size}
      />
    ) : (
      <IconGenderAgender className={classes.iconUndetermined} size={size} />
    );

  return sexIcon;
};
