//import { useTranslation } from "react-i18next";

import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  Checkbox,
  Group,
  Image,
  Progress,
  ScrollArea,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
} from "@mantine/core";
import { FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import {
  IconCircleCheck,
  IconExternalLink,
  IconTrash,
} from "@tabler/icons-react";
import { BFile } from "beitary-shared";
import { BBox, BDropzone, BLoader } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useUploadFile } from "hooks/useUploadFile";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface FilesProps {
  patientId: string;
  clientId: string;
}

// TODO fix this to incvlude more thatn files
export const Files = ({ patientId, clientId }: FilesProps) => {
  const { t } = useTranslation();

  const db = useDBServices().filesDBService;

  const [files, setFiles] = useState<undefined | null | BFile[]>(undefined);

  useEffect(() => {
    const getConsultationFilesListener = db.getPatientBFilesListener({
      patientId,
      callback: (items: BFile[]) => {
        setFiles(items);
      },
    });

    return () => {
      getConsultationFilesListener();
    };
  }, [patientId, db]);

  const [includeInMedicalRecord, setIncludeInMedicalRecord] =
    useState<boolean>(true);
  const [comment, setComment] = useState<string | undefined>();

  const [file, setFile] = useState<FileWithPath | undefined>();

  console.log(file);

  const [{ fileProgress, submitFile, submitFileState, fileReady, clearFile }] =
    useUploadFile({
      filesCategory: "GENERIC_PATIENT_FILE",
      clientId,
      comment,
      includeInMedicalRecord,
      shareWithClient: includeInMedicalRecord,
      patientId,
    });

  useEffect(() => {
    if (file) {
      fileReady();
    } else {
      clearFile();
    }
  }, [clearFile, file, fileReady]);

  return (
    <BBox
      header={
        <Group position="apart">
          <Group>
            <Text weight={500}>{t("FILES")}</Text>
          </Group>
        </Group>
      }
    >
      <Box p="xl">
        {files === undefined && <BLoader />}
        {files === null && <Text>{t("ERROR")}</Text>}
        {files?.length === 0 && <Text>{t("NO_RECORDS_FOUND")}</Text>}
        <ScrollArea>
          <Group grow p="xl" align="flex-start">
            <Stack>
              <BDropzone
                line1={t("DZ_LINE_1")}
                line2={t("DZ_LINE_@")}
                dzProps={{
                  accept: [
                    // docs
                    MIME_TYPES.csv,
                    MIME_TYPES.docx,
                    MIME_TYPES.xlsx,
                    MIME_TYPES.pdf,
                    MIME_TYPES.doc,
                    MIME_TYPES.xls,
                    MIME_TYPES.docx,
                    MIME_TYPES.ppt,
                    MIME_TYPES.pptx,
                    // img
                    MIME_TYPES.png,
                    MIME_TYPES.jpeg,
                    MIME_TYPES.webp,
                    MIME_TYPES.avif,
                    MIME_TYPES.gif,
                    // vid
                    MIME_TYPES.mp4,
                    // other
                    MIME_TYPES.zip,
                    "application/x-zip-compressed",
                  ],
                  onDrop: (files) => {
                    const f = files.at(0);
                    if (f) {
                      setFile(f);
                      //fileReady();
                    } else {
                      setFile(undefined);
                      // clearFile();
                    }
                  },

                  loading: submitFileState === "pending-response",
                  maxFiles: 1,
                  // maxSize: 10 * 1024 * 1024,
                }}
              />
              <Group grow>
                {file && fileProgress && (
                  <Stack>
                    <Card withBorder>
                      <Group position="apart">
                        <Text w={240} truncate>
                          {file.name}
                        </Text>
                        <Text w={80}>{`${(file.size / (1024 * 1024)).toFixed(
                          2
                        )} mb`}</Text>
                        <Box w={160}>
                          {(fileProgress.status === "in-progress" ||
                            fileProgress.status === "success") && (
                            <Progress
                              value={fileProgress.progressRate}
                              striped
                              animate={fileProgress.status === "in-progress"}
                              w={150}
                            />
                          )}
                        </Box>
                        <Box w={30}>
                          {fileProgress.status === "success" && (
                            <ThemeIcon color="green" size={24} radius="xl">
                              <IconCircleCheck size={16} />
                            </ThemeIcon>
                          )}
                          {fileProgress.status === "ready" && (
                            <ActionIcon
                              onClick={() => {
                                setFile(undefined);
                                // clearFile();
                              }}
                            >
                              <IconTrash size={18} />
                            </ActionIcon>
                          )}
                        </Box>
                      </Group>
                      <Group grow mt="xl" mb="xl">
                        <Textarea
                          label={t("COMMENT")}
                          onChange={(e) => {
                            setComment(e.currentTarget.value);
                          }}
                          value={comment}
                        />
                      </Group>
                      <Checkbox
                        label={t("INCLUDE_IN_MEDICAL_RECORD")}
                        onChange={(e) => {
                          setIncludeInMedicalRecord(e.currentTarget.checked);
                        }}
                        checked={includeInMedicalRecord}
                      />
                    </Card>
                    <Group position="right">
                      {fileProgress.status === "ready" && (
                        <Button onClick={() => submitFile(file)}>
                          {t("UPLOAD_FILE")}
                        </Button>
                      )}
                    </Group>
                  </Stack>
                )}
              </Group>

              {(files ?? []).map((file) => (
                <Card withBorder>
                  <Group position="apart">
                    <Group position="left">
                      <Text w={240} truncate>
                        {file.originalName}
                      </Text>
                      <Text w={80}>{`${(
                        Number(file.fileSize) /
                        (1024 * 1024)
                      ).toFixed(2)} mb`}</Text>
                      <Text truncate>{file.comment}</Text>
                    </Group>
                    <Anchor
                      color="dark"
                      weight={500}
                      href={file.dlURL}
                      target="_blank"
                    >
                      {<IconExternalLink size={12} />}
                    </Anchor>
                  </Group>
                  {file.thumbURL && (
                    <Image
                      p="xl"
                      maw={300}
                      mx="auto"
                      radius="md"
                      src={file.thumbURL}
                    />
                  )}
                </Card>
              ))}
            </Stack>
            {/* <Stack>
          <Input.Wrapper label={t("NOTES")}>
            <BTextEditor />
          </Input.Wrapper>
          <Group position="right">
            <Button
              // onClick={addNote}
              // disabled={note.length === 0 || note === "<p></p>"}
              // loading={addNoteState === "pending-response"}
              leftIcon={<IconPlus size={18} />}
            >
              {t("ADD_NOTE")}
            </Button>
          </Group>
        </Stack> */}
          </Group>
          {/* <Table>
              <tbody>{rows}</tbody>
            </Table> */}
        </ScrollArea>
      </Box>
    </BBox>
  );
};
