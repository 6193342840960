import {
  errorResult,
  normalizeReminderSnapshot,
  ReminderSnapshot,
  ReminderSnapshotData,
  REMINDER_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";

interface AddReminderSnapshot {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: Omit<ReminderSnapshotData, "organizationId">;
  }): Promise<Result<boolean | null>>;
}

const addReminderSnapshot: AddReminderSnapshot = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new reminderSnapshot id
    const newReminderSnapshotRef = doc(collection(db, "reminders_snapshots"));

    // console.log(newReminderSnapshotRef);

    const newReminderSnapshot: ReminderSnapshot = normalizeReminderSnapshot({
      ...data,
      organizationId,
      id: newReminderSnapshotRef.id,
      authorId,
      authorName,
      version: REMINDER_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newReminderSnapshotRef, newReminderSnapshot);

    // t("REMINDER_CREATED")
    const successMessage = "REMINDER_CREATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetReminderSnapshot {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<ReminderSnapshot | null>>;
}

const getReminderSnapshot: GetReminderSnapshot = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const reminderSnapshotDocRef = doc(db, "reminders_snapshots", id);
    const reminderSnapshotDocSnapshot = await getDoc(reminderSnapshotDocRef);
    if (reminderSnapshotDocSnapshot.exists()) {
      try {
        const data: unknown = reminderSnapshotDocSnapshot.data();
        const reminderSnapshot: ReminderSnapshot =
          normalizeReminderSnapshot(data);
        const successMessage = "ReminderSnapshot found!";
        return successResult({
          message: successMessage,
          payload: reminderSnapshot,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "ReminderSnapshot not found!";
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetRemindersSnapshots {
  (props: {
    db: Firestore;
    organizationId: string;
    startAfterId?: string;
    startBeforeId?: string;
    startAtId?: string;
    clientId?: string;
    patientId?: string;
    showComplete?: boolean;
    day?: number;
  }): Promise<Result<ReminderSnapshot[] | null>>;
}

const getRemindersSnapshots: GetRemindersSnapshots = async ({
  db,
  organizationId,
  startAfterId,
  startBeforeId,
  startAtId,
  clientId,
  patientId,
  showComplete,
  day,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "reminders_snapshots"),
      where("organizationId", "==", organizationId)
    );

    if (!showComplete) {
      newQuery = query(newQuery, where("status", "==", "CREATED"));
    }
    if (clientId) {
      newQuery = query(newQuery, where("clientId", "==", clientId));
    }

    if (patientId) {
      newQuery = query(newQuery, where("patientId", "==", patientId));
    }

    if (day !== undefined) {
      const weekStart = tu.getStartOfWeek(tu.getDate(day));
      const weekEnd = weekStart + 7 * 24 * 60 * 60 * 1000;
      newQuery = query(
        newQuery,
        where("dueDate", ">=", weekStart),
        where("dueDate", "<=", weekEnd)
      );
    }
    newQuery = query(newQuery, orderBy("dueDate", "desc"), limit(PAGE_SIZE));

    if (startAtId) {
      const docRef = doc(db, "reminders_snapshots", startAtId);
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAt(cursorDoc));
    } else if (startBeforeId) {
      const docRef = doc(db, "reminders_snapshots", startBeforeId);
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, endBefore(cursorDoc), limitToLast(PAGE_SIZE));
    } else if (startAfterId) {
      const docRef = doc(db, "reminders_snapshots", startAfterId);
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAfter(cursorDoc));
    }

    const querySnapshot = await getDocs(newQuery);

    const remindersSnapshots: ReminderSnapshot[] = [];

    querySnapshot.forEach((doc) => {
      try {
        remindersSnapshots.push(normalizeReminderSnapshot(doc.data()));
      } catch (error) {
        console.log(error);
      }
    });

    return successResult({
      message: "SUCCESS",
      payload: remindersSnapshots,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface DeleteReminderSnapshot {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteReminderSnapshot: DeleteReminderSnapshot = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(db, "reminders_snapshots", id);

    try {
      await deleteDoc(docRef);
      // t("REMINDER_DELETED")
      const successMessage = "REMINDER_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateReminderSnapshot {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<ReminderSnapshotData>;
  }): Promise<Result<boolean | null>>;
}

const updateReminderSnapshot: UpdateReminderSnapshot = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "reminders_snapshots", id);

    const updates: Partial<ReminderSnapshot> = {
      ...data,
      authorId,
      authorName,
      version: REMINDER_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("REMINDER_UPDATED")
    const successMessage = "REMINDER_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const reminderSnapshots = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    addReminderSnapshot: (data: Omit<ReminderSnapshotData, "organizationId">) =>
      addReminderSnapshot({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    getRemindersSnapshots: (props: {
      startAfterId?: string;
      startBeforeId?: string;
      startAtId?: string;
      clientId?: string;
      patientId?: string;
      showComplete?: boolean;
      day?: number;
    }) =>
      getRemindersSnapshots({
        db,
        organizationId,
        ...props,
      }),
    getReminderSnapshot: (id: string) =>
      getReminderSnapshot({
        db,
        organizationId,
        id,
      }),
    updateReminderSnapshot: (id: string, data: Partial<ReminderSnapshotData>) =>
      updateReminderSnapshot({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteReminderSnapshot: (id: string) =>
      deleteReminderSnapshot({
        db,
        organizationId,
        id,
      }),
    completeReminderSnapshot: (id: string) =>
      updateReminderSnapshot({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "COMPLETED" },
      }),
    uncompleteReminderSnapshot: (id: string) =>
      updateReminderSnapshot({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "CREATED" },
      }),
  };
};
