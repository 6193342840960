import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Reporting } from "features/reporting";
import { Reports } from "features/reporting/components";
import { DownloadHistory } from "features/reporting/components/DownloadHistory";
import {
  AccountsReceivableReport,
  ClientsPatientsCounts,
  ConsultationsReport,
  ControlledDrugsReport,
  EndOfDayReport,
  FinancesSummaryPerClientReport,
  InventoryItemsBelowOrAtReorderPointReport,
  InventoryItemsReport,
  InventoryItemsUsageReport,
  InvoiceLineItemsReport,
  InvoicesSummaryReport,
  ProductsReport,
  ProductsUsageReport,
  RemindersReport,
  VaccineReport,
} from "features/reporting/components/Reports/components";
import { PaymentsReport } from "features/reporting/components/Reports/components/PaymentsReport";
import { Navigate, Outlet, Route } from "react-router-dom";

export const ReportingRoutes = (
  <Route
    path="reporting"
    element={
      <ProtectedRouteWrapper
        conditions={["do", "REPORTING"]}
        element={<Reporting />}
      />
    }
  >
    <Route index element={<Navigate to="reports" replace />} />

    <Route path="reports" element={<Outlet />}>
      <Route index element={<Reports />} />
      <Route
        path="clients-patients-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.CLIENTS_PATIENTS_ACCESS]}
            element={<ClientsPatientsCounts />}
          />
        }
      />
      <Route
        path="invoices-summary-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.FINANCIAL_ACCESS]}
            element={<InvoicesSummaryReport />}
          />
        }
      />
      <Route
        path="accounts-receivable-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.FINANCIAL_ACCESS]}
            element={<AccountsReceivableReport />}
          />
        }
      />
      <Route
        path="consultations-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<ConsultationsReport />}
          />
        }
      />
      <Route
        path="finances-summary-per-client-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.FINANCIAL_ACCESS]}
            element={<FinancesSummaryPerClientReport />}
          />
        }
      />
      <Route
        path="inventory-items-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<InventoryItemsReport />}
          />
        }
      />
      <Route
        path="controlled-drugs-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<ControlledDrugsReport />}
          />
        }
      />
      <Route
        path="inventory-items-reporder-point-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<InventoryItemsBelowOrAtReorderPointReport />}
          />
        }
      />
      <Route
        path="end-of-day-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.FINANCIAL_ACCESS]}
            element={<EndOfDayReport />}
          />
        }
      />
      <Route
        path="inventory-items-usage-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<InventoryItemsUsageReport />}
          />
        }
      />
      <Route
        path="products-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<ProductsReport />}
          />
        }
      />
      <Route
        path="products-usage-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<ProductsUsageReport />}
          />
        }
      />
      <Route
        path="payments-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.FINANCIAL_ACCESS]}
            element={<PaymentsReport />}
          />
        }
      />
      <Route
        path="reminders-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.CLIENTS_PATIENTS_ACCESS]}
            element={<RemindersReport />}
          />
        }
      />
      <Route
        path="invoice-line-items-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.FINANCIAL_ACCESS]}
            element={<InvoiceLineItemsReport />}
          />
        }
      />
      <Route
        path="vaccine-report"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.MEDICATION_ACCESS]}
            element={<VaccineReport />}
          />
        }
      />
    </Route>

    <Route path="dl-history" element={<Outlet />}>
      <Route index element={<DownloadHistory />} />
    </Route>
  </Route>
);
