import {
  BFile,
  bFileCategoriesEnum,
  BFileData,
  BFILE_VERSION,
  errorResult,
  normalizeBFile,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import { Unsubscribe } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";

interface AddBFile {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: Omit<Omit<BFileData, "readOrgIds">, "organizationId">;
  }): Promise<Result<string | null>>;
}

const addBFile: AddBFile = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    const newRef = doc(
      collection(db, "organizations", organizationId, "files")
    );

    const newObj: BFile = {
      ...data,
      organizationId,
      readOrgIds: { [organizationId]: true },
      id: newRef.id,
      authorId,
      authorName,
      version: BFILE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await setDoc(newRef, newObj);

    // t("BFILE_CREATED")
    const successMessage = "BFILES_CREATED";
    return successResult({
      message: successMessage,
      payload: newRef.id,
    });
  } catch (err: any) {
    console.log(err);

    return errorResult({ message: err.message });
  }
};

interface GetBFile {
  (props: { db: Firestore; organizationId: string; id: string }): Promise<
    Result<BFile | null>
  >;
}

const getBFile: GetBFile = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "files", id);

    const docSnapshot = await getDoc(docRef);

    const file = normalizeBFile(docSnapshot.data());

    // t("BFILE")
    const successMessage = "BFILE_DELETED";
    return successResult({
      message: successMessage,
      payload: file,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface UpdateBFile {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    id: string;
    data: Partial<BFileData>;
  }): Promise<Result<boolean | null>>;
}

const updateBFile: UpdateBFile = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  id,
  data,
}) => {
  try {
    const newRef = doc(db, "organizations", organizationId, "files", id);

    const updates: Partial<BFile> = {
      ...data,
      authorId,
      authorName,
      version: BFILE_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(newRef, updates);

    // t("BFILE_UPDATED")
    const successMessage = "BFILE_UPDATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};
interface DeleteBFile {
  (props: { db: Firestore; organizationId: string; id: string }): Promise<
    Result<boolean | null>
  >;
}

const deleteBFile: DeleteBFile = async ({ db, organizationId, id }) => {
  try {
    const newRef = doc(db, "organizations", organizationId, "files", id);

    await deleteDoc(newRef);

    // t("BFILE")
    const successMessage = "BFILE_DELETED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetPatientBFiles {
  ({
    db,
    organizationId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    patientId: string;
  }): Promise<Result<BFile[] | null>>;
}

const getPatientBFiles: GetPatientBFiles = async ({
  db,
  organizationId,
  patientId,
}) => {
  try {
    let filesQuery = query(
      collection(db, "organizations", organizationId, "files"),
      where("patientId", "==", patientId)
    );

    const querySnapshot = await getDocs(filesQuery);

    const files: BFile[] = [];
    querySnapshot.forEach((doc) => {
      try {
        files.push(normalizeBFile(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });
    // t("BFILES_FOUND")
    const successMessage = "BFILES_FOUND";
    return successResult({
      message: successMessage,
      payload: files,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetConsultationBFilesListenerCallback {
  (invoices: BFile[]): void;
}
interface GetConsultationBFilesListener {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  callback: GetConsultationBFilesListenerCallback;
}

const getConsultationBFilesListener = ({
  db,
  organizationId,
  consultationId,
  callback,
}: GetConsultationBFilesListener): Unsubscribe => {
  try {
    // console.log("getClientBFilesListener: new listener");
    const filesQuery = query(
      collection(db, "organizations", organizationId, "files"),
      where("consultationId", "==", consultationId)
      //orderBy("createdAt") no need for this
    );
    return onSnapshot(filesQuery, (querySnapshot) => {
      const files: BFile[] = [];
      querySnapshot.forEach((doc) => {
        try {
          files.push(normalizeBFile(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(files);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface GetPatientBFilesListener {
  db: Firestore;
  organizationId: string;
  patientId: string;
  callback: GetConsultationBFilesListenerCallback;
}

const getPatientBFilesListener = ({
  db,
  organizationId,
  patientId,
  callback,
}: GetPatientBFilesListener): Unsubscribe => {
  try {
    // console.log("getClientBFilesListener: new listener");
    const filesQuery = query(
      collection(db, "organizations", organizationId, "files"),
      where("patientId", "==", patientId)
      //orderBy("createdAt") no need for this
    );
    return onSnapshot(filesQuery, (querySnapshot) => {
      const files: BFile[] = [];
      querySnapshot.forEach((doc) => {
        try {
          files.push(normalizeBFile(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(files);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// interface GetBFileListenerCallback {
//   (files: BFile | null): void;
// }
// interface GetBFileListener {
//   db: Firestore;
//   organizationId: string;
//   filesId: string;
//   callback: GetBFileListenerCallback;
// }

// const getBFileListener = ({
//   db,
//   organizationId,
//   filesId,
//   callback,
// }: GetBFileListener): Unsubscribe => {
//   try {
//     // console.log("getBFileListener: new listener");

//     const filesQuery = doc(
//       db,
//       "organizations",
//       organizationId,
//       "files",
//       filesId
//     );

//     return onSnapshot(filesQuery, (querySnapshot) => {
//       try {
//         callback(normalizeBFile(querySnapshot.data()));
//       } catch (err) {
//         console.log(err);
//         callback(null);
//       }
//     });
//   } catch (err: any) {
//     console.log(err);
//     return () => {};
//   }
// };

interface GetReportingBFiles {
  ({
    db,
    organizationId,
    startAfterTime,
    startAtTime,
    startBeforeTime,
  }: {
    db: Firestore;
    organizationId: string;
    startAfterTime?: number;
    startBeforeTime?: number;
    startAtTime?: number;
  }): Promise<Result<BFile[] | null>>;
}

const getReportingBFiles: GetReportingBFiles = async ({
  db,
  organizationId,
  startAfterTime,
  startAtTime,
  startBeforeTime,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "files"),
      where("organizationId", "==", organizationId),
      where("fileCategory", "==", bFileCategoriesEnum.Values.REPORT),
      orderBy("createdAt", "desc"),
      limit(PAGE_SIZE)
    );

    if (startAtTime) {
      newQuery = query(newQuery, startAt(startAtTime));
    } else if (startBeforeTime) {
      newQuery = query(
        newQuery,
        endBefore(startBeforeTime),
        limitToLast(PAGE_SIZE)
      );
    } else if (startAfterTime) {
      newQuery = query(newQuery, startAfter(startAfterTime));
    }

    const querySnapshot = await getDocs(newQuery);

    const files: BFile[] = [];
    querySnapshot.forEach((doc) => {
      try {
        files.push(normalizeBFile(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });
    // t("BFILES_FOUND")
    const successMessage = "BFILES_FOUND";
    return successResult({
      message: successMessage,
      payload: files,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

export const files = ({
  authorId,
  authorName,
  db,
  organizationId,
  source,
}: {
  db: Firestore;
  organizationId: string;
  authorId: string;
  authorName: string;
  source: Source;
}) => {
  return {
    //   getBFileListener: (
    //     filesId: string,
    //     callback: GetBFileListenerCallback
    //   ) =>
    //     getBFileListener({
    //       db,
    //       organizationId,
    //       filesId,
    //       callback,
    //     }),
    addBFile: (data: Omit<Omit<BFileData, "readOrgIds">, "organizationId">) =>
      addBFile({
        authorId,
        authorName,
        data,
        db,
        organizationId,
        source,
      }),
    updateBFile: (data: Partial<BFileData>, id: string) =>
      updateBFile({
        authorId,
        authorName,
        data,
        db,
        organizationId,
        source,
        id,
      }),
    deleteBFile: (id: string) =>
      deleteBFile({
        db,
        organizationId,
        id,
      }),
    getBFile: (id: string) =>
      getBFile({
        db,
        organizationId,
        id,
      }),
    getPatientBFiles: (patientId: string) =>
      getPatientBFiles({
        db,
        organizationId,
        patientId,
      }),
    getPatientBFilesListener: (props: {
      patientId: string;
      callback: GetConsultationBFilesListenerCallback;
    }) =>
      getPatientBFilesListener({
        db,
        organizationId,
        ...props,
      }),
    getConsultationBFilesListener: ({
      consultationId,
      callback,
    }: {
      consultationId: string;
      callback: GetConsultationBFilesListenerCallback;
    }) =>
      getConsultationBFilesListener({
        db,
        organizationId,
        consultationId,
        callback,
      }),
    //   getPatientBFilesListener: (
    //     clientId: string,
    //     callback: GetClientBFilesListenerCallback
    //   ) =>
    //     getClientBFilesListener({
    //       db,
    //       organizationId,
    //       clientId,
    //       callback,
    //     }),

    getReportingBFiles: (props: {
      startBeforeTime?: number;
      startAtTime?: number;
    }) =>
      getReportingBFiles({
        db,
        organizationId,
        ...props,
      }),
  };
};
