import { Box, Button, Container, Group, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconDownload } from "@tabler/icons-react";
import { bFileCategoriesEnum, errorNotification, tu } from "beitary-shared";
import { BBox, BDateAndTimeInput, MoveBackButton } from "components";
import { useCServices } from "hooks/useCService/useCService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadFile } from "hooks/useUploadFile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { utils, write, writeFile } from "xlsx";

export interface ProductsUsageReportProps {}

export const ProductsUsageReport = () => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const [{ submitFile, submitFileState }] = useUploadFile({
    filesCategory: bFileCategoriesEnum.Values.REPORT,
  });

  const [txs, setTxs] = useState<any>([]); // TODO fix

  const [start, setStart] = useState<number>(
    tu.getStartOfDate(tu.getCurrentDate())
  );
  const [end, setEnd] = useState<number>(
    tu.getStartOfDate(tu.getCurrentDate()) + 1000 * 60 * 60 * 24 - 1
  );

  const txsQuery = useCServices().reports.treatmentsAdministredReport;

  const query = async () => {
    setSubmitState("pending-response");

    const txsResult = await txsQuery({
      start,
      end,
    });

    if (txsResult.type === "error") {
      showNotification(
        errorNotification({
          message: t("ERROR_GENERATING_REPORT"),
        })
      );
      setSubmitState("error");
      return;
    }
    if (txsResult.type === "success" && txsResult.payload)
      setTxs(txsResult.payload.data);
    setSubmitState("success");
  };

  const downloadXslx = async () => {
    const summarySheet = utils.json_to_sheet([
      {
        date_from: tu.getDateAndTimeString(start),
        date_to: tu.getDateAndTimeString(end),
      },
    ]);
    const txsSheet = utils.json_to_sheet(txs);

    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, summarySheet, t("SUMMARY"));
    utils.book_append_sheet(workBook, txsSheet, t("PRODUCT_USAGE_SHEET_NAME"));

    const buf = write(workBook, { bookType: "xlsx", type: "buffer" });

    const title = `${t("PRODUCTS_USAGE_REPORT")} ${tu.getYYYYMMDDString(
      tu.getCurrentDateTime()
    )} (${tu.getYYYYMMDDString(start)} - ${tu.getYYYYMMDDString(end)}).xlsx`;

    const file = new File([buf], title, { type: "application/octet-stream" });
    await submitFile(file);

    writeFile(workBook, title);
  };

  return (
    <Container fluid>
      <Stack spacing="xl">
        <Group mt="xl">
          <MoveBackButton />
        </Group>

        <BBox header={<Text weight={500}>{t("PRODUCTS_USAGE_REPORT")}</Text>}>
          <Stack p="xl">
            <Group position="apart" align="flex-end">
              <Group>
                <BDateAndTimeInput
                  label={t("DATE_FROM")}
                  maxDateTime={end}
                  onChange={(v) => {
                    if (v !== undefined) setStart(v);
                  }}
                  value={start}
                />
                <BDateAndTimeInput
                  label={t("DATE_TO")}
                  maxDateTime={tu.getCurrentDateTime()}
                  minDateTime={start}
                  onChange={(v) => {
                    if (v !== undefined) setEnd(v);
                  }}
                  value={end}
                />
              </Group>
              <Group>
                <Button
                  onClick={query}
                  loading={submitState === "pending-response"}
                >
                  {t("GENERATE_REPORT")}
                </Button>
                <Button
                  variant="outline"
                  loading={submitFileState === "pending-response"}
                  leftIcon={<IconDownload size={18} />}
                  disabled={submitState !== "success"}
                  onClick={downloadXslx}
                >
                  {t("DOWNLOAD")}
                </Button>
              </Group>
            </Group>

            <Box mt="md" mb="md">
              {submitState === "success" && (
                <Text>
                  {txs.length} {t("ITEMS_FOUND")}
                </Text>
              )}
            </Box>
          </Stack>
        </BBox>
      </Stack>
    </Container>
  );
};
