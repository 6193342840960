import { Button, Card, Group, Stack, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { selectActiveConsultationCharges } from "features/consultations/Consultations.slice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ChargesPanel } from "./components";

export interface ChargesProps {
  invoiceId?: string;
}

export const Charges = ({ invoiceId }: ChargesProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const charges = useAppSelector(selectActiveConsultationCharges);

  const bundleCharges = charges
    .filter((c) => c.bundleId)
    .sort((a, b) => (b.createdAt < a.createdAt ? 1 : -1))
    .sort((a, b) => b.bundleId?.localeCompare(a.bundleId ?? "") ?? 0);
  // console.log(bundleCharges);

  const nonBundleCharges = charges.filter((c) => !c.bundleId);

  const treatments = nonBundleCharges.filter(
    (c) => c.parentType === "TREATMENT"
  );
  const vaccines = nonBundleCharges.filter((c) => c.parentType === "VACCINE");
  const prescriptions = nonBundleCharges.filter(
    (c) => c.parentType === "PRESCRIPTION"
  );
  const services = nonBundleCharges.filter((c) => c.parentType === "SERVICE");

  // console.log(charges);

  return (
    <Stack>
      <Card withBorder radius="xs" p="xs" mb="md">
        <Group position="apart">
          <Text weight={500}>{t("CHARGES")}</Text>
          <Group>
            <Button
              leftIcon={<IconExternalLink size={16} />}
              disabled={!invoiceId}
              onClick={() => {
                invoiceId && navigate(`/invoices/edit/${invoiceId}`);
              }}
            >
              {t("VIEW_INVOICE")}
            </Button>
          </Group>
        </Group>
      </Card>
      {bundleCharges.length > 0 && (
        <ChargesPanel charges={bundleCharges} title={t("BUNDLES")} />
      )}
      {treatments.length > 0 && (
        <ChargesPanel charges={treatments} title={t("TREATMENTS")} />
      )}
      {vaccines.length > 0 && (
        <ChargesPanel charges={vaccines} title={t("VACCINES")} />
      )}
      {services.length > 0 && (
        <ChargesPanel charges={services} title={t("SERVICES")} />
      )}
      {prescriptions.length > 0 && (
        <ChargesPanel charges={prescriptions} title={t("PRESCRIPTIONS")} />
      )}
    </Stack>
  );
};
