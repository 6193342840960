import { z } from "zod";

// t("PLANNED")
// t("ORDERED")
// t("ADMINISTRED")
export const treatmentStatusSchema = z.enum([
  "PLANNED",
  "ORDERED",
  "ADMINISTRED",
]);

export type TreatmentStatus = z.infer<typeof treatmentStatusSchema>;
