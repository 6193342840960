import { Appointment, tu } from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { View } from "./Appointments.view";

export const Container = () => {
  const db = useDBServices().appointmentsDBService;

  const [selectedDate, setSelectedDate] = useState(
    tu.getStartOfDate(new Date())
  );

  // TODO use undef, null, Appts[] and show loading, error...
  const [appts, setAppts] = useState<Appointment[]>([]);

  useEffect(() => {
    const getAppointmentsListenerUnsubscribe =
      db.getAppointmentsForWeekListener({
        callback: (a) =>
          setAppts(
            a.map((i) => ({ ...i, time: tu.cleanTimeSecondsAndMillis(i.time) })) // this is to keep things tidy in ui
          ),
        day: selectedDate,
      });

    // console.log(selectedDate);

    return () => {
      getAppointmentsListenerUnsubscribe();
    };
  }, [selectedDate, db]);

  return (
    <View
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      appointments={appts}
    />
  );
};
