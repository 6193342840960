import {
  Checkbox,
  Group,
  Input,
  NumberInput,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  normalizeTreatment,
  treatmentTypeSchema,
} from "beitary-shared";
import {
  BBox,
  BDateAndTimeInput,
  BDateInput,
  BTextEditor,
  CancelButton,
  MoveBackButton,
  SaveButton,
} from "components";
import { selectActiveConsultationTreatments } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { rules, TxFormValues } from "./TxForm.rules";

interface TxFormProps {
  consultationId: string;
  disabled: boolean;
  nested?: boolean; // TODO this might be bad, just wanted to hide move back button
  // prolly need to create wrapper component
  // whatever
}

export const TxForm = ({ consultationId, disabled, nested }: TxFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const txDBService =
    useDBServices().consultationsDBService.treatments(consultationId);

  const { treatmentId } = useParams();
  const treatment = useAppSelector(selectActiveConsultationTreatments).find(
    (t) => t.id === treatmentId
  );

  const form = useForm<TxFormValues>({
    initialValues: treatment ? normalizeTreatment(treatment) : undefined,
    validate: rules,
  });

  if (!treatment) return <Text>{t("NOT_FOUND")}</Text>;

  const submit = async (values: TxFormValues) => {
    if (disabled || !treatment) return;
    const notificationId = "submit-treatment";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_TREATMENT"),
      })
    );
    setSubmitState("pending-response");

    const result = await txDBService.updateTreatment(treatment.id, values);
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        {!nested && (
          <Group>
            <MoveBackButton />
          </Group>
        )}
        <BBox
          header={<Text weight={500}>{treatment.productSnapshot.name}</Text>}
        >
          <Stack p="xl">
            {treatment.productSnapshot.integration && (
              <Checkbox
                label={t("ADMINISTER_WITHOUT_INTEGRATION")}
                {...form.getInputProps("administerWithoutIntegration", {
                  type: "checkbox",
                })}
              />
            )}
            <Group grow>
              <NumberInput
                disabled={disabled}
                label={t("QUANTITY")}
                precision={2}
                rightSection={
                  treatment.unit ? (
                    <Text size="xs" mr="xs">
                      {t(treatment.unit)}
                    </Text>
                  ) : undefined
                }
                {...form.getInputProps("qty")}
              />
              <Select
                disabled={disabled}
                withinPortal
                label={t("TREATMENT_TYPE")}
                data={treatmentTypeSchema.options.map((o) => ({
                  value: o,
                  label: t(o),
                }))}
                {...form.getInputProps("type")}
                clearable={false}
                allowDeselect={false}
                searchable
              />
              {form.values.type === "SCHEDULED" && (
                <BDateAndTimeInput
                  disabled={disabled}
                  label={t("TIME_DUE")}
                  {...form.getInputProps("timeDue")}
                />
              )}

              {form.values.type === "PRN" && (
                <NumberInput
                  disabled={disabled}
                  label={t("PRIORITY")}
                  min={0}
                  {...form.getInputProps("priority")}
                />
              )}
            </Group>

            {(treatment.productSnapshot.type === "MEDICATION" ||
              treatment.productSnapshot.type === "VACCINE") && (
              <Group grow>
                <TextInput
                  label={t("SERIAL_NUMBER")}
                  {...form.getInputProps("serialNumber")}
                  min={1}
                />
                <TextInput
                  disabled={disabled}
                  label={t("LOT_NUMBER")}
                  {...form.getInputProps("lotNumber")}
                />
                <BDateInput
                  disabled={disabled}
                  label={t("LOT_EXPIRATION_DATE")}
                  {...form.getInputProps("lotExpirationDate")}
                />
                <TextInput
                  disabled={disabled}
                  label={t("MANUFACTURER")}
                  {...form.getInputProps("manufacturer")}
                />
              </Group>
            )}
            <Group grow>
              <Input.Wrapper label={t("ADDITIONAL_INSTRUCTIONS")}>
                <BTextEditor
                  disabled={disabled}
                  {...form.getInputProps("adminInstr")}
                />
              </Input.Wrapper>
            </Group>
          </Stack>
        </BBox>

        <Group position="right">
          <CancelButton />
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
