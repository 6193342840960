import {
  ActionIcon,
  Box,
  Card,
  Collapse,
  Group,
  ScrollArea,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { ReminderSnapshot, tu } from "beitary-shared";
import { BLoader } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type RemindersTableItem = ReminderSnapshot;

export interface PatientRemindersProps {
  clientId: string;
  patientId: string;
}

export const PatientReminders = ({
  clientId,
  patientId,
}: PatientRemindersProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);
  const theme = useMantineTheme();
  const db = useDBServices().clientCommunication.reminderSnapshots;

  const [reminders, setReminders] = useState<
    undefined | null | ReminderSnapshot[]
  >(undefined);

  console.log(reminders);

  useEffect(() => {
    const getReminders = async () => {
      const result = await db.getRemindersSnapshots({
        clientId,
        patientId,
      });
      setReminders(result.payload);
    };
    getReminders();
  }, [patientId, clientId, db]);

  const getRow = (reminder: RemindersTableItem) => {
    return (
      <tr key={reminder.id}>
        <td>
          <Text weight={500} size="xs">
            {tu.getDateString(reminder.dueDate)}
          </Text>
        </td>{" "}
        <td>
          <Text color="dimmed" size="xs">
            {reminder.name}
          </Text>
        </td>
      </tr>
    );
  };

  const rows = (reminders ?? []).map((a) => getRow(a));

  return (
    <Card sx={{ border: `1px solid ${theme.colors.gray[3]}` }} mt="md">
      <Group>
        <ActionIcon onClick={() => setOpened(!opened)}>
          <IconChevronDown
            style={{
              transform: opened ? "rotate(180deg)" : "none",
              transitionDuration: "200ms",
            }}
          />
        </ActionIcon>
        <Text weight={600} size="sm">
          {t("REMINDERS")}
        </Text>
      </Group>

      <Collapse in={opened} transitionDuration={400}>
        <Box p="xl">
          {reminders === undefined && <BLoader size={16} />}
          {reminders === null && <Text>{t("ERROR")}</Text>}
          {reminders?.length === 0 && <Text>{t("NO_REMINDERS")}</Text>}

          {reminders?.length !== 0 && (
            <ScrollArea>
              <Table>
                <tbody>{rows}</tbody>
              </Table>
            </ScrollArea>
          )}
        </Box>
      </Collapse>
    </Card>
  );
};
