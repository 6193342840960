import { PurchaseOrders } from "features/admin/inventory/components";
import { PurchaseOrderComponent } from "features/admin/inventory/components/PurchaseOrders/components";
import { Outlet, Route } from "react-router-dom";

export const PurchaseOrdersRoutes = (
  <Route path="purchase-orders" element={<Outlet />}>
    <Route index element={<PurchaseOrders />} />
    <Route path=":id" element={<PurchaseOrderComponent />} />
  </Route>
);
