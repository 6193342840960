import { useAppSelector } from "app/hooks";
import { Source } from "beitary-shared";
import {
  selectActiveOrganization,
  selectActiveUser,
} from "features/Authentication/AuthenticationSlice";
import { useEffect, useState } from "react";
import { sService } from "services/storage/storage-service";

/**
 * db needs organization id and user id as arguments
 * this hook is created to watch these ids and update
 * the service accordingly
 */

const recordSource: Source = "WEB";

export const useSServices = () => {
  const organizationId = useAppSelector(selectActiveOrganization);
  const authUser = useAppSelector(selectActiveUser);

  if (!organizationId || !authUser || !authUser.uid || !authUser.displayName) {
    throw new Error("SService invalid args");
  }

  const [service, setService] = useState(
    sService(organizationId, authUser.uid, authUser.displayName, recordSource)
  );

  useEffect(() => {
    if (
      !organizationId ||
      !authUser ||
      !authUser.uid ||
      !authUser.displayName
    ) {
      throw new Error("SService invalid args");
    }
    const authorId = authUser.uid;
    const updatedService = sService(
      organizationId,
      authorId,
      authUser.displayName,
      recordSource
    );
    setService(updatedService);
  }, [organizationId, authUser]);

  return service;
};
