import { z } from "zod";
import { sourceEnum, UnableToNormalizeData } from "../../types-schemas";

export const vUEPOnCallDataSchema = z.object({
  data: z.object({
    email: z.string().email().optional(),
  }),
  organizationId: z.string(),
  authorName: z.string(),
  authorId: z.string(),
  source: sourceEnum,
});

export type VUEPOnCallData = z.infer<typeof vUEPOnCallDataSchema>;

export const normalizeVUEPOnCallData = (data: any): VUEPOnCallData => {
  try {
    return vUEPOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const vUEPOnCallResponseSchema = z.object({
  userWithEmailExists: z.boolean(),
});

export type VUEPOnCallResponse = z.infer<typeof vUEPOnCallResponseSchema>;

export const normalizeVUEPOnCallResponse = (data: any): VUEPOnCallResponse => {
  try {
    return vUEPOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
