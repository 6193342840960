import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { charges } from "./charges/charges";
import { consultations as childConsultations } from "./consultation/consultation";
import { dischargeInstructions } from "./discharge-instructions/discharge-instructions";
import { physicalExams } from "./physical-exams/physical-exams";
import { treatments } from "./treatments/treatments";
import { vitals } from "./vitals/vitals";

export const consultations = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    consultations: childConsultations(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
    treatments: (consultationId: string) =>
      treatments({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        consultationId,
      }),
    vitals: (consultationId: string) =>
      vitals({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        consultationId,
      }),
    physicalExams: (consultationId: string) =>
      physicalExams({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        consultationId,
      }),

    dischargeInstructions: (consultationId: string) =>
      dischargeInstructions({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        consultationId,
      }),
    charges: (consultationId: string) =>
      charges({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        consultationId,
      }),
  };
};
