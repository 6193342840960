import { zodResolver } from "@mantine/form";
import { invoiceLineItemSchema } from "beitary-shared";
import { z } from "zod";

const refundInvoiceFormSchema = z.object({
  items: z.array(
    invoiceLineItemSchema.merge(
      z.object({
        selected: z.boolean(),
      })
    )
  ),
});

export type RefundInvoiceFormValues = z.infer<typeof refundInvoiceFormSchema>;

export const rules = zodResolver(refundInvoiceFormSchema);
