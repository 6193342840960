import {
  ActionIcon,
  Button,
  Container,
  Group,
  Input,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  normalizePatient,
  obju,
  Patient,
  PatientData,
  Result,
} from "beitary-shared";
import {
  BBox,
  BDateInput,
  PatientExternalIdSelect,
  PatientSexSelect,
  PatientSpeciesSelect,
  SaveButton,
} from "components";
import { BThumbnailInput } from "components/BThumbnailInput";
import { MoveBackButton } from "components/MoveBackButton";
import { PatientStatusSelect } from "components/PatientStatusSelect";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadThumbnail } from "hooks/useThumbnailUpload";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientFormValues, rules } from "./PatientForm.rules";

export interface PatientFormProps {
  patient?: Patient;
  clientId: string;
  clientName: string;
  quick?: boolean;
}

export const PatientForm = ({
  patient,
  clientId,
  clientName,
  quick,
}: PatientFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const [file, setFile] = useState<File | null>(null);
  const [
    { submitThumbnailState, submitThumbnail, thumbnailReady, clearThumbnail },
  ] = useUploadThumbnail();
  const { t } = useTranslation();
  const { updatePatient, addPatient } = useDBServices().patientsDBService;

  const form = useForm<PatientFormValues>({
    initialValues: patient ? { ...normalizePatient(patient) } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");
  if (!form.values.ownerId)
    form.setValues({
      ownerName: clientName,
      ownerId: clientId,
    });

  const submit = async (values: PatientFormValues) => {
    const {
      dateOfBirth,
      name,
      sex,
      species,
      status,
      alert,
      breed,
      color,
      externalIDs,
      thumbURL,
    } = values;
    const notificationId = "submit-catalog--patient";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(patient ? "UPDATE_PATIENT" : "ADD_PATIENT"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<string | null>;

    const newData: PatientData = {
      dateOfBirth,
      name,
      ownerId: clientId,
      ownerName: clientName,
      sex,
      species,
      status,
      alert,
      breed,
      color,
      externalIDs,
      thumbURL,
    };

    obju.removeUndefined(newData);

    // console.log(newData);

    if (patient) {
      result = await updatePatient(patient.id, newData);
    } else {
      result = await addPatient({
        ...newData,
        ownerId: clientId,
        ownerName: clientName,
      });
    }
    if (result.type === "success") {
      if (result.payload && file) {
        await submitThumbnail(file, result.payload);
        if (submitThumbnailState === "success") {
          setSubmitState("success");
        } else {
          setSubmitState("error");
        }
      } else {
        setSubmitState("success");
      }
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form
      id="new-patient-form"
      onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}
    >
      <Container fluid>
        <Stack mt="xl">
          {!quick && (
            <Group mb="md">
              <MoveBackButton />
            </Group>
          )}
          <BBox
            header={
              patient ? (
                <Text weight={500}>{t("EDIT_PATIENT")}</Text>
              ) : (
                <Text weight={500}>{t("NEW_PATIENT")}</Text>
              )
            }
          >
            <Stack p="xl">
              {!quick && (
                <BThumbnailInput
                  currentImgSrc={patient?.thumbURL}
                  file={file}
                  setFile={(v) => {
                    console.log(v);

                    if (!v) {
                      clearThumbnail();
                    } else {
                      thumbnailReady();
                    }
                    setFile(v);
                  }}
                />
              )}
              {patient && (
                <PatientStatusSelect
                  required
                  {...form.getInputProps("status")}
                />
              )}
              <TextInput
                required
                label={t("NAME")}
                {...form.getInputProps("name")}
              />
              <PatientSexSelect required {...form.getInputProps("sex")} />
              <PatientSpeciesSelect
                required
                {...form.getInputProps("species")}
              />
              <TextInput label={t("BREED")} {...form.getInputProps("breed")} />
              <BDateInput
                required
                maxDateTime={Date.now()}
                label={t("DATE_OF_BIRTH")}
                {...form.getInputProps("dateOfBirth")}
              />
              {!quick && (
                <>
                  <TextInput
                    label={t("COLOR")}
                    {...form.getInputProps("color")}
                  />
                  <TextInput
                    label={t("ALERT")}
                    {...form.getInputProps("alert")}
                  />

                  <Input.Wrapper label={t("IDENTIFIERS")}>
                    <Stack pt="md">
                      {form.values.externalIDs &&
                        form.values.externalIDs.map((v, index) => (
                          <Group key={v.id}>
                            <PatientExternalIdSelect
                              {...form.getInputProps(
                                `externalIDs.${index}.type`
                              )}
                            />
                            <TextInput
                              {...form.getInputProps(
                                `externalIDs.${index}.description`
                              )}
                            />
                            <ActionIcon
                              onClick={() =>
                                form.setFieldValue(
                                  "externalIDs",
                                  (form.values.externalIDs ?? []).filter(
                                    (elem) => elem.id !== v.id
                                  )
                                )
                              }
                            >
                              <IconTrash size={18} />
                            </ActionIcon>
                          </Group>
                        ))}
                      <Group>
                        <Button
                          onClick={() => {
                            form.values.externalIDs
                              ? form.setFieldValue("externalIDs", [
                                  ...form.values.externalIDs,
                                  {
                                    type: "ACOUSTIC_TAG",
                                    description: "",
                                    id: id_util.newId20(),
                                  },
                                ])
                              : form.setFieldValue("externalIDs", [
                                  {
                                    type: "ACOUSTIC_TAG",
                                    description: "",
                                    id: id_util.newId20(),
                                  },
                                ]);
                          }}
                        >
                          {t("ADD_IDENTIFIER")}
                        </Button>
                      </Group>
                    </Stack>
                  </Input.Wrapper>
                </>
              )}
            </Stack>
          </BBox>
          <Group position="right">
            <SaveButton
              state={submitState}
              canSave={form.isValid()}
              formId="new-patient-form"
            />
          </Group>
        </Stack>
      </Container>
    </form>
  );
};
