import {
  BSettings,
  ErrorLog,
  errorResult,
  id_util,
  normalizeBSettings,
  normalizeErrorLog,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import { Unsubscribe } from "firebase/auth";
import { doc, Firestore, onSnapshot, setDoc } from "firebase/firestore";
import { dbInstance } from "services/firebase/FirebaseService";

// get organization bSettings listener
interface GetBSettingsListenerCallback {
  (bSettings: BSettings): void;
}
interface GetBSettingsListener {
  db: Firestore;
  callback: GetBSettingsListenerCallback;
}

const getBSettingsListener = ({
  db,
  callback,
}: GetBSettingsListener): Unsubscribe => {
  try {
    const name = process.env.REACT_APP_NAME;
    if (!name) throw new Error("NO_APP_NAME");

    const bSettingsRef = doc(db, "settings", name);

    return onSnapshot(bSettingsRef, (querySnapshot) => {
      try {
        callback(normalizeBSettings(querySnapshot.data()));
      } catch (error) {
        console.log(error);
      }
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

/**
 * log root boundary error
 */

interface AddErrorLog {
  ({
    db,
    source,
    data,
  }: {
    db: Firestore;
    source: Source;
    data: Pick<ErrorLog, "error" | "componentStack" | "data">;
  }): Promise<Result<boolean | null>>;
}

const addErrorLog: AddErrorLog = async ({ db, source, data }) => {
  try {
    const newId = id_util.newId20();
    const newErrorLogRef = doc(db, "error_logs", newId);

    // console.log(newErrorLogRef);

    const newErrorLog: ErrorLog = normalizeErrorLog({
      ...data,
      time: tu.getCurrentDateTime(),
      id: newId,
      source,
      appVersion: process.env.REACT_APP_VERSION,
    });

    await setDoc(newErrorLogRef, newErrorLog);

    // t("REMINDER_CREATED")
    const successMessage = "REMINDER_CREATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

export const DBNoIdsService = () => ({
  getBSettingsListener: (callback: GetBSettingsListenerCallback) =>
    getBSettingsListener({
      db: dbInstance,
      callback,
    }),

  addErrorLog: (data: Pick<ErrorLog, "error" | "componentStack" | "data">) =>
    addErrorLog({
      db: dbInstance,
      source: "WEB",
      data,
    }),
});
