//import { useTranslation } from "react-i18next";

import {
  Anchor,
  Avatar,
  Box,
  Button,
  Group,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { Consultation, tu } from "beitary-shared";
import { BBox, BLoader } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export interface HistoryProps {
  patientId: string;
  clientId: string;
}

// TODO fix this to incvlude more thatn consultations
export const History = ({ patientId, clientId }: HistoryProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const db = useDBServices().consultationsDBService.consultations;

  const [consultations, setConsultations] = useState<
    undefined | null | Consultation[]
  >(undefined);

  console.log(consultations);

  useEffect(() => {
    const fetchApts = async () => {
      const result = await db.getPatientConsultations(patientId);
      setConsultations(result.payload);
    };

    fetchApts();
  }, [clientId, patientId, db]);

  const getRow = (consultation: Consultation) => {
    return (
      <tr key={consultation.id}>
        <td>
          <Text size="xs" color="dimmed">
            {`${tu.getDateAndTimeString(consultation.createdAt)}`}
          </Text>
        </td>
        <td>
          <Group>
            <Text>{t(consultation.status)}</Text>
          </Group>
        </td>
        <td>
          <Group noWrap>
            <Avatar radius="xl" />

            <Text size="sm">{consultation.supervisingDoctorName}</Text>
          </Group>
        </td>
        <td>
          <Text>{consultation.title ?? ""}</Text>
        </td>{" "}
        <td>
          <Anchor
            color="dark"
            weight={500}
            lineClamp={1}
            component={Link}
            to={`/consultations/${consultation.id}/summary`}
          >
            {<IconExternalLink size={12} />}
          </Anchor>
        </td>
      </tr>
    );
  };

  const rows = (consultations ?? []).map((c) => getRow(c));

  return (
    <BBox
      header={
        <Group position="apart">
          <Text weight={500}>{t("HISTORY")}</Text>
          <Button onClick={() => navigate("lifecycle")} mr="xl">
            {t("LIFECYCLE")}
          </Button>
        </Group>
      }
    >
      <Box p="xl">
        {consultations === undefined && <BLoader />}
        {consultations === null && <Text>{t("ERROR")}</Text>}
        {consultations?.length === 0 && <Text>{t("NO_RECORDS_FOUND")}</Text>}
        {consultations?.length !== 0 && (
          <ScrollArea>
            <Table>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        )}
      </Box>
    </BBox>
  );
};
