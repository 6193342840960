import { zodResolver } from "@mantine/form";
import { purchaseOrderItemDataSchema } from "beitary-shared";
import { z } from "zod";

const purchaseOrderItemFormSchema = purchaseOrderItemDataSchema;

export type PurchaseOrderItemFormValues = z.infer<
  typeof purchaseOrderItemFormSchema
>;

export const rules = zodResolver(purchaseOrderItemFormSchema);
