import { Stack, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectInventoryItems } from "features/admin/inventory/Inventory.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ItemForm } from "../ItemForm";

export const EditItem = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const item = useAppSelector(selectInventoryItems)
    .filter((item) => item.id === id)
    .at(0);

  if (!item) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return (
    <Stack>
      <ItemForm item={item} />
    </Stack>
  );
};
