import { Box, Button, FileButton, Image, List, Stack } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import { useTranslation } from "react-i18next";

export interface BThumbnailInputProps {
  file: File | null;
  setFile: (payload: File | null) => void;
  currentImgSrc?: string;
}

export const BThumbnailInput = ({
  file,
  setFile,
  currentImgSrc,
}: BThumbnailInputProps) => {
  const { t } = useTranslation();
  // logo img

  // const [file, setFile] = useState<File | null>(null);

  return (
    <Box>
      <Stack align="center" p="xl">
        <Image
          src={file ? URL.createObjectURL(file) : currentImgSrc}
          width={128}
          height={128}
          radius={64}
          withPlaceholder
        />
        <Stack sx={{ width: 300 }} align="center" justify="flex-end">
          <List size="xs" withPadding>
            <List.Item>{t("ACCEPTED_IMAGE_FILE_FORMATS")}</List.Item>
          </List>
          <FileButton
            onChange={setFile}
            accept={[
              MIME_TYPES.avif,
              MIME_TYPES.gif,
              MIME_TYPES.jpeg,
              MIME_TYPES.png,
              MIME_TYPES.webp,
            ].join(",")}
          >
            {(props) => <Button {...props}>{t("UPLOAD_IMAGE")}</Button>}
          </FileButton>
        </Stack>
      </Stack>
    </Box>
  );
};
