import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectPatients } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Wrapper } from "./Patient.wrapper";

export const Patient = () => {
  const { t } = useTranslation();

  // currently not fetching from db, just from store
  // prolly only worth it to fetch from db if average patients count > 1000
  // to re evaluate after app used
  const { patientId } = useParams();

  const patient = useAppSelector(selectPatients).find(
    (c) => c.id === patientId
  );

  if (!patient || !patientId) {
    return <Text>{t("NOT_FOUND")}</Text>;
  } else {
    return <Wrapper patient={patient} />;
  }
};
