import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Group,
  Modal,
  Stack,
  Switch,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAlertCircle } from "@tabler/icons-react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  AdditionalTax,
  errorNotification,
  getNotificationByResultType,
  id_util,
  Invoice,
  InvoiceLineItem,
  InvoicePayment,
  InvoiceStatus,
  loadingInfoNotification,
  OrgPermissions,
  Result,
  successNotification,
  tu,
} from "beitary-shared";
import {
  BButtonDownload,
  BButtonEmail,
  BButtonPrint,
  BButtonStamp,
  DeleteConfirmationModal,
  MoveBackButton,
} from "components";
import { selectClients } from "features/Clients/Clients.slice";
import { useCServices } from "hooks/useCService/useCService";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { BCan } from "permissions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { file_util } from "utils/file_util";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";
import {
  ConsultationChargesPanel,
  ImportantInfoCard,
  InvoiceStatusSelect,
  PaymentsTable,
  RefundInvoiceModal,
} from "./components";
import { AddInvoiceItemsForm } from "./components/AddInvoiceItemsForm";
import { NonConsultationChargesPanel } from "./components/NonConsultationChargesPanel";

interface WrapperProps {
  invoice: Invoice;
}

export const Wrapper = ({ invoice }: WrapperProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const cservice = useCServices();

  const [items, setItems] = useState<InvoiceLineItem[]>([]);
  const [payments, setPayments] = useState<InvoicePayment[]>([]);
  const [newStatus, setNewStatus] = useState<InvoiceStatus>(invoice.status);
  const [opened, setOpened] = useState(false);
  const [submitState, setSubmitState] = useSubmitState();

  const consultationsIds = items.reduce(
    (consultationsIdsArray: string[], item) => {
      let arr = [...consultationsIdsArray];
      if (
        item.consultationId &&
        !consultationsIdsArray.includes(item.consultationId)
      )
        arr.push(item.consultationId);
      return arr;
    },
    []
  );

  const consultationsItems: InvoiceLineItem[][] = consultationsIds.map((id) =>
    items.filter((i) => i.consultationId === id)
  );
  const nonConsultationItems: InvoiceLineItem[] = items.filter(
    (i) => !i.consultationId
  );

  const currency = org_params_util.getOrgParams().currency;
  const cannotDeleteInvoice =
    invoice.dateIssued !== undefined ||
    invoice.serialNumber !== undefined ||
    Object.keys(invoice.lineItems).length > 0 ||
    invoice.total !== 0 ||
    invoice.paid !== 0;

  const { id } = invoice;
  const db = useDBServices();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getInvoiceLineItemsListenerUnsubscribe =
      db.invoicesDBService.invoices.getInvoiceLineItemsAndPaymentsListener({
        invoiceId: id,
        lineItemsCallback: (items: InvoiceLineItem[]) => {
          setItems(items);
        },
        paymentsCallback: (items: InvoicePayment[]) => {
          setPayments(items);
        },
      });

    return () => {
      getInvoiceLineItemsListenerUnsubscribe();
    };
  }, [db, dispatch, id]);

  // TODO: prolly should query patients and clients
  // when needed and not have all the clients all pulled
  const client = useAppSelector(selectClients).find(
    (c) => c.id === invoice.clientId
  );

  const updateInvoiceState = async () => {
    if (invoice.balanceDue !== 0 && newStatus === "CHECKED_OUT") return;
    setOpened(false);

    // console.log(v);
    const notificationId = "update-invoice";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_INVOICE"),
      })
    );
    setSubmitState("pending-response");

    const invoicesDBService = db.invoicesDBService.invoices;
    let r: Result<boolean | null> | undefined = undefined;
    switch (newStatus) {
      case "ACTIVE":
        r = await invoicesDBService.markInvoiceAsActive(invoice.id);
        break;
      case "CHECKED_OUT":
        r = await invoicesDBService.markInvoiceAsCheckedOut(invoice.id);
        break;
      case "CHARGES_COMPLETE":
        r = await invoicesDBService.markInvoiceAsChargesComplete(invoice.id);
        break;

      default:
        break;
    }

    if (r?.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    r &&
      updateNotification({
        ...getNotificationByResultType(r.type)({
          message: t(r.message),
        }),
        id: notificationId,
      });
  };

  const updateAdditionalTaxes = async ({
    index,
    value,
  }: {
    index: number;
    value: boolean;
  }) => {
    if (invoice.status === "CHECKED_OUT") return;

    // console.log(v);
    const notificationId = "update-invoice";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_INVOICE"),
      })
    );
    setSubmitState("pending-response");

    const newAdditionalTaxes = invoice.additionalTaxes.map((a, i) => {
      const nat: AdditionalTax = {
        ...a,
        status: i !== index ? a.status : value === true ? "ACTIVE" : "INACTIVE",
      };
      return nat;
    });

    const invoicesDBService = db.invoicesDBService.invoices;
    const r = await invoicesDBService.updateAdditionalTaxes({
      invoiceId: invoice.id,
      additionalTaxes: newAdditionalTaxes,
    });

    console.log(newAdditionalTaxes);

    if (r?.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    r &&
      updateNotification({
        ...getNotificationByResultType(r.type)({
          message: t(r.message),
        }),
        id: notificationId,
      });
  };

  const printInvoice = async () => {
    const result = await cservice.invoicesCService.getInvoicePDFFileURL(id);

    if (result.type === "success") {
      if (result.payload) {
        file_util.printFileFromURL({ url: result.payload.content });
      }
    } else {
      showNotification(
        errorNotification({
          id: "printInvoice-notif",
          message: t("ERROR_RETRIEVING_FILE"),
        })
      );
    }
  };

  const downloadInvoice = async () => {
    const result = await cservice.invoicesCService.getInvoicePDFFileURL(id);

    if (result.type === "success") {
      if (result.payload) {
        // Set the file name of the PDF
        const fileName = `${invoice.clientName}_${t(
          "INVOICE"
        )}_${tu.getYYYYMMDDString(tu.getCurrentDateTime())}.pdf`;

        file_util.saveFileFromURL({ url: result.payload.content, fileName });
      }
    } else {
      showNotification(
        errorNotification({
          id: "doanloadInvoice-notif",
          message: t("ERROR_RETRIEVING_FILE"),
        })
      );
    }
  };

  const emailInvoice = async () => {
    const result = await cservice.invoicesCService.sendClientInvoiceViaEmail(
      id
    );
    if (result.type === "success") {
      showNotification(
        successNotification({
          id: "emailInvoice-notif",
          message: t("EMAIL_QUEUED_FOR_DELIVERY"),
        })
      );
    } else {
      showNotification(
        errorNotification({
          id: "emailInvoice-notif",
          message: t("ERROR_QUEUING_EMAIL_FOR_DELIVERY"),
        })
      );
    }
  };

  const finalizeInvoice = async () => {
    const result =
      await cservice.invoicesCService.addInvoiceSerialNumberAndDateIssued(id);
    if (result.type === "success") {
      showNotification(
        successNotification({
          id: "finalizeInvoice-notif",
          message: t("FINALIZE_INVOICE"),
        })
      );
    } else {
      showNotification(
        errorNotification({
          id: "xxxInvoice-notif",
          message: t("ERROR_FINALIZE_INVOICE"),
        })
      );
    }
  };

  if (!client) return <Text>{t("NOT_FOUND")}</Text>;

  const disabled = invoice.status === "CHECKED_OUT";

  // t("INTERNAL_NOTES")
  return (
    <Container fluid>
      <ImportantInfoCard invoice={invoice} client={client} />
      <Stack mt="xl">
        <Group>
          <MoveBackButton />
        </Group>

        <Flex
          mih={50}
          gap="md"
          justify="flex-start"
          align="flex-start"
          direction={{ base: "column", md: "row" }}
        >
          <Stack
            sx={{
              flex: 5,
              width: "100%",
            }}
          >
            <Card withBorder radius="xs" p="xs">
              <Stack>
                <Group position="apart">
                  <Text weight={500}>{t("INVOICE")}</Text>
                  <Group spacing="xs">
                    <InvoiceStatusSelect
                      canCheckOut={invoice.balanceDue === 0}
                      // disabled={disabled}
                      value={newStatus}
                      onChange={(v) => setNewStatus(v as InvoiceStatus)}
                    />
                    <Button
                      disabled={
                        invoice.status === newStatus ||
                        (invoice.balanceDue !== 0 &&
                          newStatus === "CHECKED_OUT")
                      }
                      loading={submitState === "pending-response"}
                      // onClick={
                      //   newStatus === "CHECKED_OUT"
                      //     ? () => setOpened(true)
                      //     : updateInvoiceState
                      // }
                      onClick={updateInvoiceState}
                    >
                      {t("SAVE")}
                    </Button>
                  </Group>
                  <Group>
                    <BCan I="do" a={OrgPermissions.INVOICE_DELETE}>
                      <DeleteConfirmationModal
                        disabled={cannotDeleteInvoice}
                        button
                        f={async () =>
                          db.invoicesDBService.invoices.deleteInvoice(
                            invoice.id
                          )
                        }
                        item={{
                          id: invoice.id,
                          name: `${t("INVOICE")} ${
                            invoice.serialNumber ?? ""
                          } ${t("CLIENT")}: ${invoice.clientName}`,
                        }}
                      />
                    </BCan>

                    <BButtonEmail
                      disabled={!invoice.serialNumber || !invoice.dateIssued}
                      f={async () => emailInvoice()}
                    />
                    <BButtonDownload
                      disabled={!invoice.serialNumber || !invoice.dateIssued}
                      f={async () => downloadInvoice()}
                    />
                    <BButtonPrint
                      disabled={!invoice.serialNumber || !invoice.dateIssued}
                      f={async () => printInvoice()}
                    />
                    <BButtonStamp
                      disabled={
                        // invoice.status !== "CHECKED_OUT" ||
                        !!invoice.serialNumber || !!invoice.dateIssued
                      }
                      f={async () => finalizeInvoice()}
                    />
                  </Group>
                </Group>

                <Group position="apart">
                  {invoice.dateIssued && invoice.serialNumber && (
                    <Group>
                      <Text size="sm">
                        {t("DATE_ISSUED")}:{" "}
                        <Text color="dimmed">
                          {tu.getYYYYMMDDString(invoice.dateIssued)}
                        </Text>
                      </Text>
                      <Text size="sm">
                        {t("INVOICE_NUMBER")}:
                        <Text color="dimmed">{invoice.serialNumber}</Text>
                      </Text>
                    </Group>
                  )}
                  <Box></Box>
                  {invoice.status === "CHECKED_OUT" && (
                    <RefundInvoiceModal
                      items={items}
                      parentInvoiceId={invoice.id}
                      clientId={invoice.clientId}
                      clientName={invoice.clientName}
                      parentInvoiceSerialNumber={invoice.serialNumber}
                      maxRefundAmount={invoice.total - invoice.refunded}
                    />
                  )}
                </Group>
              </Stack>
            </Card>

            <AddInvoiceItemsForm invoiceId={invoice.id} disabled={disabled} />

            {consultationsItems.map((charges) => (
              <ConsultationChargesPanel
                charges={charges}
                key={id_util.newIdNoDigits20()}
                disabled={disabled}
              />
            ))}

            {nonConsultationItems.length > 0 && (
              <NonConsultationChargesPanel
                charges={nonConsultationItems}
                disabled={disabled}
              />
            )}

            <PaymentsTable payments={payments} />
          </Stack>

          <Stack
            sx={{
              flex: 2,
              width: "100%",
            }}
          >
            <Card withBorder radius="xs">
              <Group grow>
                <Stack spacing={0}>
                  <Text>{t("OUTSTANDING_BALANCE")}</Text>
                  <Text color="red">
                    {mu.toD(client.outstandingBalance)} {t(currency)}
                  </Text>
                </Stack>
                <Stack spacing={0}>
                  <Text>{t("CREDIT_BALANCE")}</Text>
                  <Text color="green">
                    {mu.toD(client.creditBalance)} {t(currency)}
                  </Text>
                </Stack>
              </Group>
            </Card>
            <Card withBorder radius="xs">
              <Stack w="100%">
                <Text weight={500}>{t("SUMMARY")}</Text>
                <Group position="apart">
                  <Text>{t("SUBTOTAL")}</Text>
                  <Text>
                    {mu.toD(invoice.subtotal)} {t(currency)}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>{t("DISCOUNT")}</Text>
                  <Text>
                    {mu.toD(invoice.discount)} {t(currency)}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>{t("TAX")}</Text>
                  <Text>
                    {mu.toD(invoice.taxes)} {t(currency)}
                  </Text>
                </Group>
                <Divider />
                <Text weight={500}>{t("ADDITIONAL_TAXES")}</Text>
                {invoice.additionalTaxes.map((a, index) => (
                  <Group position="apart">
                    <Group>
                      <Switch
                        disabled={disabled}
                        checked={
                          invoice.additionalTaxes[index].status === "ACTIVE"
                        }
                        onChange={(e) => {
                          updateAdditionalTaxes({
                            index,
                            value: e.target.checked,
                          });
                        }}
                      />
                      <Text color={a.status === "ACTIVE" ? "dark" : "dimmed"}>
                        {t(a.name)}
                      </Text>
                    </Group>
                    <Text color={a.status === "ACTIVE" ? "dark" : "dimmed"}>
                      {mu.toD(
                        mu.multiply({
                          amount: a.nature === "%" ? invoice.total : a.value,
                          multiplier: a.nature === "%" ? a.value : 1,
                        })
                      )}{" "}
                      {t(currency)}
                    </Text>
                  </Group>
                ))}
                <Divider />
                <Group position="apart">
                  <Text weight={500}>{t("TOTAL")}</Text>
                  <Text weight={500}>
                    {mu.toD(invoice.total)} {t(currency)}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>{t("PAID")}</Text>
                  <Text>
                    {mu.toD(invoice.paid)} {t(currency)}
                  </Text>
                </Group>
                <Group position="apart">
                  <Text>{t("REFUNDED")}</Text>
                  <Text>
                    {mu.toD(invoice.refunded)} {t(currency)}
                  </Text>
                </Group>
                <Divider />
                <Group position="apart">
                  <Text weight={500}>{t("BALANCE_DUE")}</Text>
                  <Text weight={500}>
                    {mu.toD(invoice.balanceDue)} {t(currency)}
                  </Text>
                </Group>
                <Button
                  disabled={disabled}
                  onClick={() =>
                    navigate(`/invoices/payments/new/${client.id}`)
                  }
                >
                  {t("TAKE_PAYMENT")}
                </Button>
              </Stack>
            </Card>
          </Stack>
        </Flex>
      </Stack>
      <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack pb="xl" align="center" spacing={0}>
          <ThemeIcon size={140} color="red.4" radius={70} variant="light">
            <IconAlertCircle size={100} />
          </ThemeIcon>
          <Text weight="bold" mt="xl">
            {t("YOU_ARE_ABOUT_TO_CHECKOUT_AN_INVOICE")}
          </Text>
          <Text mt="xl">{t("THIS_ACTION_CANNOT_BE_UNDONE")}</Text>
          <Text>{t("ARE_YOU_SURE")}</Text>
        </Stack>
        <Group mt="xl" position="right">
          <Button color="red" onClick={updateInvoiceState}>
            {t("CONFIRM")}
          </Button>
          <Button variant="outline" onClick={() => setOpened(false)}>
            {t("CANCEL")}
          </Button>
        </Group>
      </Modal>
    </Container>
  );
};
