import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { id_util, tu } from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import HTMLReactParser from "html-react-parser";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommunicationHistoryCard } from "../CommunicationHistoryCard";

type SMSCommunicationHistoryTableItem = {
  id: string;
  state: string;
  message: string;
  timeString: string;
  error?: string;
  to: string[];
};

export interface SMSCommunicationHistoryTableProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
}

// started chatgpt opt
export const SMSCommunicationHistoryTable = ({
  clientId,
  patientId,
  clientName,
  patientName,
}: SMSCommunicationHistoryTableProps) => {
  const { t } = useTranslation();

  const [startAfterId, setStartAfterId] = useState<string | undefined>(
    undefined
  );
  const [startBeforeId, setStartBeforeId] = useState<string | undefined>(
    undefined
  );

  const { getSMSCommunicationHistory, getCommunicationHistoryTime } =
    useDBServices().clientCommunication.communicationHistory;

  const [communicationHistorys, setCommunicationHistorys] = useState<
    undefined | null | SMSCommunicationHistoryTableItem[]
  >(undefined);

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [refresh, setRefresh] = useState(id_util.newId36());

  const handleNextPage = () => {
    fetchCommunicationHistory({ startAfterId });
  };

  const handlePreviousPage = () => {
    fetchCommunicationHistory({ startBeforeId });
  };

  interface fetchCommunicationHistoryProps {
    startAfterId?: string;
    startBeforeId?: string;
    startAtId?: string;
  }
  const fetchCommunicationHistory = useCallback(
    async ({
      startAfterId,
      startBeforeId,
      startAtId,
    }: fetchCommunicationHistoryProps) => {
      setRefreshing(true);
      const props = {
        startAfterId,
        startBeforeId,
        startAtId,
        clientId,
        patientId,
      };

      const result = await getSMSCommunicationHistory(props);

      setRefreshing(false);
      // ... handle pagination ...
      if (result.payload && result.payload.length > 0) {
        setCommunicationHistorys(
          result.payload.map((i) => ({
            error: i.delivery?.error ?? undefined,
            to: i.to,
            id: i.id,
            message: i.message ?? "",
            state: i.delivery?.state ?? "",
            timeString: tu.getDateAndTimeString(getCommunicationHistoryTime(i)),
          }))
        );
        const afterId = result.payload[result.payload.length - 1].id;
        const beforeId = result.payload[0].id;

        setStartAfterId(afterId);
        setStartBeforeId(beforeId);
      } else {
        // // No more data to fetch
        if (startAfterId) setStartAfterId(undefined);
        if (startBeforeId) setStartBeforeId(undefined);
      }
    },
    [
      clientId,
      patientId,
      getSMSCommunicationHistory,
      getCommunicationHistoryTime,
    ]
  );

  useEffect(() => {
    fetchCommunicationHistory({});
  }, [fetchCommunicationHistory, refresh]);

  const cards = useMemo(
    () =>
      (communicationHistorys ?? []).map((a) => {
        const { id, message, state, timeString: time, to, error } = a;
        return (
          <CommunicationHistoryCard
            key={id}
            content={HTMLReactParser(message)}
            state={
              state === "SUCCESS"
                ? "SUCCESS"
                : state === "ERROR"
                ? "ERROR"
                : "OTHER"
            }
            timestring={time}
            to={to}
            error={error}
          />
        );
      }),
    [communicationHistorys]
  );

  return communicationHistorys === null ? (
    <Text>{t("ERROR")}</Text>
  ) : (
    <Stack>
      <Group position="apart">
        <Box></Box>
        <Group>
          <ActionIcon
            onClick={() => {
              setRefresh(id_util.newId36());
            }}
          >
            {refreshing ? <Loader size="xs" /> : <IconRefresh size={18} />}
          </ActionIcon>
        </Group>
      </Group>
      <Box p="xl">
        <ScrollArea>
          <Stack>{cards}</Stack>
        </ScrollArea>
        <Group position="right" mt="xl">
          <Button onClick={handlePreviousPage} disabled={!startBeforeId}>
            {t("PREVIOUS")}
          </Button>
          <Button onClick={handleNextPage} disabled={!startAfterId}>
            {t("NEXT")}
          </Button>
        </Group>
      </Box>
    </Stack>
  );
};
