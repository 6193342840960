import { z } from "zod";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

export const mailSchema = z.object({
  organizationId: z.string(),
  from: z.string().email(),
  replyTo: z.string().email(),
  to: z.array(z.string().email()),
  // toUids: z.array(z.string()).optional(), removed this cauz want to create sms only if email known
  template: z
    .object({
      name: z.string(),
      data: z.any(),
    })
    .optional(),
  message: z
    .object({
      subject: z.string(),
      html: z.string(),
      attachments: z
        .array(
          z.object({
            filename: z.string().optional(),
            path: z.string().optional(),
            content: z.string().optional(),
          })
        )
        .optional(),
    })
    .optional(),

  // for quotas
  quotaProcessingAt: z.number().int().positive().optional(),
  quotaProcessedAt: z.number().int().positive().optional(),
});

export type Mail = z.infer<typeof mailSchema>;

export const normalizeMail = (data: any): Mail => {
  try {
    const mail: Mail = mailSchema.parse(data);
    return mail;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
