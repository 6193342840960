import { Group, Input, Stack, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  Consultation,
  getNotificationByResultType,
  loadingInfoNotification,
} from "beitary-shared";
import { BBox, BTextEditor, SaveButton } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { rules, SubjectiveFormValues } from "./SubjectiveForm.rules";

interface SubjectiveFormProps {
  consultation: Consultation;
}

export const SubjectiveForm = ({ consultation }: SubjectiveFormProps) => {
  const disabled = consultation.status === "LOCKED";

  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const consultationDBService =
    useDBServices().consultationsDBService.consultations;

  const form = useForm<SubjectiveFormValues>({
    initialValues: consultation ? { ...consultation } : undefined,
    validate: rules,
  });

  const submit = async (values: SubjectiveFormValues) => {
    if (disabled) return;
    const notificationId = "submit-subjective";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_CONSULTATION"),
      })
    );
    setSubmitState("pending-response");
    const result = await consultationDBService.updateConsultation(
      consultation.id,
      values
    );

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <BBox
          header={<Text weight={500}>{t("SUBJECTIVE_MEDICAL_NOTES")}</Text>}
        >
          <Stack p="xl">
            <Textarea
              disabled={disabled}
              label={t("REASON_FOR_CONSULTATION")}
              {...form.getInputProps("reasonForConsultation")}
            />
            <Input.Wrapper label={t("CURRENT_MEDICATION_AND_SUPPLEMENTS")}>
              <BTextEditor
                disabled={disabled}
                {...form.getInputProps("currentMedicationsAndSupplements")}
              />
            </Input.Wrapper>
            <Input.Wrapper label={t("HISTORY")}>
              <BTextEditor
                disabled={disabled}
                {...form.getInputProps("history")}
              />
            </Input.Wrapper>
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isDirty() && form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
