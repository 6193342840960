import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";

/**
 * schema and types versions
 * from first to last
 */

// v0

const inventoryItemLocationDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string().min(1),
  notes: z.string().optional(),
});

type InventoryItemLocationDataV0 = z.infer<
  typeof inventoryItemLocationDataV0Schema
>;

const inventoryItemLocationV0Schema = baseRecordSchema.merge(
  inventoryItemLocationDataV0Schema
);

type InventoryItemLocationV0 = z.infer<typeof inventoryItemLocationV0Schema>;

const normalizeInventoryItemLocationV0 = (data: any): InventoryItemLocation => {
  try {
    const inventoryItemLocationV0: InventoryItemLocationV0 =
      inventoryItemLocationV0Schema.parse(data);

    const { ...rest } = inventoryItemLocationV0;

    // here it's same object cause current v is 0
    const inventoryItemLocation: InventoryItemLocation = {
      ...rest,
    };
    return inventoryItemLocation;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVENTORY_ITEM_LOCATION_VERSION = "0";

export type InventoryItemLocationData = InventoryItemLocationDataV0;

export type InventoryItemLocation = BaseRecord & InventoryItemLocationData;

export const inventoryItemLocationDataSchema =
  inventoryItemLocationDataV0Schema;

export const inventoryItemLocationSchema = inventoryItemLocationV0Schema;

export const normalizeInventoryItemLocation = (
  data: any
): InventoryItemLocation => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInventoryItemLocationV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
