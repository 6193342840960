import { z } from "zod";
import { MemberLicenceType, memberLicenceType } from "../../members";
import { orgPermission } from "../../organization";
import {
  SubscriptionStatus,
  subscriptionStatusEnum,
  SubscriptionType,
  subscriptionTypeEnum,
} from "../../subscriptions";

// org subscription with status - account status - licence type - permissions
// subscription might no longer be relevant, cauz now everything is based on quota, no longer on feature

export const isOrgAccEnum = z.enum(["0", "1"]).default("1");
export type IsOrgAcc = z.infer<typeof isOrgAccEnum>;

export const isOwnerAccEnum = z.enum(["0", "1"]).default("0");
export type IsOwnerAcc = z.infer<typeof isOwnerAccEnum>;

export const permissionStatusEnum = z.enum(["0", "1"]);
export type PermissionStatus = z.infer<typeof permissionStatusEnum>;

const accountStatusEnum = z.enum(["0", "1"]);
export type AccountStatus = z.infer<typeof accountStatusEnum>;

export const accountCustomClaims = z.object({
  orgId: z.string(),
  isOrgAcc: isOrgAccEnum,
  isOwnerAcc: isOwnerAccEnum,
  permissions: z.record(orgPermission, permissionStatusEnum),
  subscription: z.object({
    status: subscriptionStatusEnum,
    type: subscriptionTypeEnum,
  }),
  accountStatus: accountStatusEnum,
  licenseType: memberLicenceType,
});
export type AccountCustomClaims = z.infer<typeof accountCustomClaims>;

export type AccountCustomClaimsAccountStatus = "0" | "1";
export type AccountCustomClaimsLicenceType =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";

export type ParsedAccountCustomClaims = {
  orgId: string;
  isOrgAcc: IsOrgAcc;
  isOwnerAcc: IsOwnerAcc;
  permissions: {
    // org id
    [k: string]: PermissionStatus;
  };
  accountStatus: AccountStatus;
  subscription: {
    status: SubscriptionStatus;
    type: SubscriptionType;
  };
  licenseType: MemberLicenceType;
};

export const parseClaims = (claims: AccountCustomClaims) => {
  let result: ParsedAccountCustomClaims;

  result = claims;

  return result;
};

// {"orgId":"rzwAVNeNFejUMOqKmqxN","isOrgAcc":"0","isOwnerAcc":"1","permissions":{
//   "a":"1",
//   "b":"1",
//   "c":"1",
//   "d":"1",
//   "e":"1",
//   "f":"1",
//   "j":"1",
//   "h":"1",
//   "i":"1",
//   "g":"1",
//   "k":"1",
//   "l":"1",
//   "m":"1",
//   "n":"1",
//   "o":"1",
//   "p":"1",
//   "q":"1",
//   "r":"1",
//   "s":"1",
//   "t":"1",
//   "u":"1",
//   "v":"1",
//   "x":"1",
//   "y":"1",
//   "z":"1"
//   },
//   "accountStatus":"1",
//   "subscription":{
//   "status":"1",
//   "type":"a"
//   },
//   "licenseType":"DOCTOR"
//   }
