import { z } from "zod";

export const inventoryItemUnitTypeEnum = z.enum([
  "µL",
  "mL",
  "L",
  "µg",
  "mg",
  "g",
  "kg",
  "IU",
  "mEq",
  "U",
  "gtt",
  "oz",
  "lb",
  "in",
  "cm",
  // from the unit of measurement enum
  "AMPOULE",
  "BAG",
  "BARREL",
  "BOTTLE",
  "BOX",
  "CAN",
  "CAPSULE",
  "CARTIDGE",
  "CARTON",
  "CASE",
  "CONTAINER",
  "CUP",
  "FILM",
  "FLACON",
  "FLEXIBAG",
  "JAR",
  "JUG",
  "LOZENGE",
  "PACKET",
  "PAIL",
  "PAMPHLET",
  "PATCH",
  "PILL",
  "PIPETTE",
  "POUCH",
  "RING",
  "SACHET",
  "SOFTGEL",
  "SUPPOSITORY",
  "SYRINGE",
  "TABLET",
  "TUBE",
  "UNIT",
  "VIAL",
]);

export type InventoryItemUnitType = z.infer<typeof inventoryItemUnitTypeEnum>;

// t("µL")
// t("mL")
// t("L")
// t("µg")
// t("mg")
// t("g")
// t("kg")
// t("IU")
// t("mEq")
// t("U")
// t("gtt")
// t("oz")
// t("lb")
// t("in")
// t("cm")
// t("FILM")
// t("LOZENGE")
// t("PATCH")
// t("PILL")
// t("PIPETTE")
// t("RING")
// t("SOFTGEL")
// t("SUPPOSITORY")
