import { Box, Container, Text } from "@mantine/core";
import { MoveBackButton } from "components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SearchInput } from "../PatientLifecycle/components";
import { PatientLifecycleSearch } from "../PatientLifecycleSearch/PatientLifecycleSearch";

export interface PatientLifecycleSearchContainerProps {}

export const PatientLifecycleSearchContainer = () => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const [searchString, setSearchString] = useState("");

  if (!patientId) {
    return <Text>{t("NOT_FOUND")}</Text>;
  } else {
    return (
      <Container fluid mt="xl">
        <MoveBackButton />
        <Text mt="xl" weight={500}>
          {t("LIFECYCLE")}
        </Text>
        <Box mt="xl">
          <SearchInput parentSetString={setSearchString} />
          <PatientLifecycleSearch
            patientId={patientId}
            searchString={searchString}
          />
        </Box>
      </Container>
    );
  }
};
