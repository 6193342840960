import { Outlet, Route } from "react-router-dom";
import { CatalogRoutes } from "./catalog/catalogRoutes";
import { ClientCommunicationRoutes } from "./client-communication/ClientCommunicationRoutes";
import { ClinicSettingsRoutes } from "./clinic-settings/ClinicSettingsRoutes";
import { DiagnosesRoutes } from "./diagnoses/DiagnosesRoutes";
import { InventoryRoutes } from "./inventory/InventoryRoutes";
import { MembersRoutes } from "./members/MembersRoutes";
import { ScheduleSettingsRoutes } from "./schedule-settings/ScheduleSettings";
import { TemplatesRoutes } from "./templates/TemplatesRoutes";

export const AdminRoutes = () => (
  <Route path="admin" element={<Outlet />}>
    {InventoryRoutes}
    {MembersRoutes}
    {CatalogRoutes}
    {ClientCommunicationRoutes}
    {ClinicSettingsRoutes}
    {TemplatesRoutes}
    {DiagnosesRoutes}
    {ScheduleSettingsRoutes}
  </Route>
);
