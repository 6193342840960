import { Consultations } from "features/consultations";
import { Consultation } from "features/consultations/components/Consultation";
import { Outlet, Route } from "react-router-dom";

export const ConsultationsRoutes = (
  <Route path="consultations" element={<Outlet />}>
    <Route index element={<Consultations />} />
    <Route path=":id/*" element={<Consultation />} />
  </Route>
);

// path=":id/*" means that the router can render nested routes that come from Consultation
