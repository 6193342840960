import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { Templates } from "features/admin/templates";
import { PhysicalExams } from "features/admin/templates/components";
import { Email } from "features/admin/templates/components/Email";
import {
  AddPhysicalExamTemplate,
  EditPhysicalExamTemplate,
  ViewPhysicalExamTemplate,
} from "features/admin/templates/components/PhysicalExams/components";
import { Navigate, Outlet, Route } from "react-router-dom";

export const TemplatesRoutes = (
  <Route path="templates" element={<Templates />}>
    <Route index element={<Navigate to="physical-exams" replace />} />
    <Route path="physical-exams" element={<Outlet />}>
      <Route index element={<PhysicalExams />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PHYSICAL_EXAM_TEMPLATES_CUD]}
            element={<AddPhysicalExamTemplate />}
          />
        }
      />
      <Route path="view/:id" element={<ViewPhysicalExamTemplate />} />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.PHYSICAL_EXAM_TEMPLATES_CUD]}
            element={<EditPhysicalExamTemplate />}
          />
        }
      />
    </Route>
    <Route path="email" element={<Outlet />}>
      <Route index element={<Email />} />

      {/* </Route>
    <Route path="sms" element={<Outlet />}>
      <Route index element={<SMS />} />
      
    </Route>
    <Route path="forms" element={<Outlet />}>
      <Route index element={<Forms />} />
     
    </Route>
    <Route path="snippets" element={<Outlet />}>
      <Route index element={<Snippets />} /> */}
    </Route>
  </Route>
);
