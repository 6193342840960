import { Group, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  Member,
} from "beitary-shared";
import { BBox, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { MemberRolesMultiSelect } from "../MemberRolesMultiSelect";
import { EditMemberFormValues, rules } from "./EditMemberForm.rules";

export interface EditMemberFormProps {
  member: Member;
}

export const EditMemberForm = ({ member }: EditMemberFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editMember } = useDBServices().organizationMembersDBService.members;

  const form = useForm<EditMemberFormValues>({
    initialValues: { ...member },
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: EditMemberFormValues) => {
    const notificationId = "submit-inventory-item-member";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_MEMBER"),
      })
    );
    setSubmitState("pending-response");
    const result = await editMember(member.id, values);
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox header={<Text weight={500}>{t("EDIT_MEMBER")}</Text>}>
          <Stack p="xl">
            <TextInput
              required
              label={t("INITIALS")}
              {...form.getInputProps("initials")}
            />
            <MemberRolesMultiSelect
              required
              {...form.getInputProps("rolesIds")}
            />
            {/* <Checkbox
              label={t("IS_A_DOCTOR")}
              checked={form.values.licenceType === "DOCTOR"}
              onChange={(e) =>
                form.setFieldValue(
                  "licenceType",
                  e.currentTarget.checked ? "DOCTOR" : "EMPLOYEE"
                )
              }
            /> */}
          </Stack>
        </BBox>

        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
