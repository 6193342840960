import { DateTimePicker } from "@mantine/dates";
import { tu } from "beitary-shared";
import { ReactNode } from "react";

export interface BDateAndTimeInputProps {
  required?: boolean;
  onChange?: (value: number | undefined) => void;
  error?: ReactNode;
  value?: number;
  minDateTime?: number;
  maxDateTime?: number;
  disabled?: boolean;
  label?: string;
}

export const BDateAndTimeInput = ({
  minDateTime,
  maxDateTime,
  ...props
}: BDateAndTimeInputProps) => {
  const getDate = (v?: number) => {
    if (v === undefined) return undefined;
    return tu.getDate(v);
  };

  const getEpoch = (d: Date | null) => {
    if (!d) return undefined;
    return tu.getDateTime(d);
  };

  // TODO couldnt find easy way to format time according to org formatting
  // TODO need to create date input??
  return (
    <DateTimePicker
      {...props}
      popoverProps={{ withinPortal: true }}
      minDate={getDate(minDateTime)}
      maxDate={getDate(maxDateTime)}
      // icon={<IconCalendar stroke={1.5} />}
      value={getDate(props.value)}
      onChange={(v) => {
        props.onChange && props.onChange(getEpoch(v));
      }}
    />
  );
};
