import { z } from "zod";
import {
  memberLicenceType,
  sourceEnum,
  UnableToNormalizeData,
} from "../../types-schemas";

export const gIMOnCallDataSchema = z.object({
  data: z.object({
    email: z.string().email(),
    initials: z.string().max(3).min(1),
    rolesIds: z.array(z.string()),
    licenceType: memberLicenceType.optional(),
  }),
  organizationId: z.string(),
  authorName: z.string(),
  authorId: z.string(),
  source: sourceEnum,
});

export type GIMOnCallData = z.infer<typeof gIMOnCallDataSchema>;

export const normalizeGIMOnCallData = (data: any): GIMOnCallData => {
  try {
    return gIMOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const gIMOnCallResponseSchema = z.object({
  contentType: z.enum(["application/json"]),
  content: z.string(),
});

export type GIMOnCallResponse = z.infer<typeof gIMOnCallResponseSchema>;

export const normalizeGIMOnCallResponse = (data: any): GIMOnCallResponse => {
  try {
    return gIMOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
