import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectProductCategories } from "features/admin/catalog/Catalog.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CategoryForm } from "../CategoryForm";

export const EditCategory = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const category = useAppSelector(selectProductCategories)
    .filter((category) => category.id === id)
    .at(0);

  if (!category) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <CategoryForm category={category} />;
};
