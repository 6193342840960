import {
  ActionIcon,
  Button,
  Checkbox,
  Container,
  Divider,
  Group,
  Input,
  Stack,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import {
  ClientData,
  errorNotification,
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  obju,
  PatientData,
  phone_utils,
} from "beitary-shared";
import {
  BBox,
  BDateInput,
  CountryCodeSelect,
  MoveBackButton,
  PatientSexSelect,
  PatientSpeciesSelect,
  SaveButton,
} from "components";
import { BThumbnailInput } from "components/BThumbnailInput";
import { ContactInfoTypeSelect } from "components/ContactInfoTypeSelect";
import { PatientExternalIdSelect } from "components/PatientExternalIdSelect";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadThumbnail } from "hooks/useThumbnailUpload";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { org_params_util } from "utils/org_params_utils";
import { NewClientFormValues, rules } from "./NewClientForm.rules";

export interface NewClientFormProps {
  quick?: boolean;
}

export const NewClientForm = ({ quick }: NewClientFormProps) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [fileUpload] = useUploadThumbnail();

  const [pFile, setPFile] = useState<File | null>(null);
  const [pFileUpload] = useUploadThumbnail();

  const db = useDBServices().clientsDBService;

  const [submitState, setSubmitState] = useSubmitState();

  const form = useForm<NewClientFormValues>({
    validate: rules,
  });

  const country = useMemo(() => org_params_util.getOrgParams().countryCode, []);

  if (!form.values.phoneNumber_countryCode) {
    form.setFieldValue("phoneNumber_countryCode", country);
  }

  if (form.values.smsNotif === undefined) {
    form.setFieldValue("smsNotif", true);
  }
  if (form.values.emailNotif === undefined) {
    form.setFieldValue("emailNotif", true);
  }

  const submit = async (values: NewClientFormValues) => {
    const {
      email,
      name,
      petDateOfBirth,
      petName,
      petSex,
      petSpecies,
      phoneNumber,
      additionalInfo,
      alert,
      discountId,
      emailNotif,
      petAlert,
      petBreed,
      petColor,
      petExternalIDs,
      petPictureURL,
      thumbURL,
      smsNotif,
      address_countryCode,
      phoneNumber_countryCode,
      address_Line1,
      address_Line2,
      address_city,
      address_postalCode,
      address_stateOrProvince,
      additionalContactInfo,
      taxID,
    } = values;
    const parsedPhoneNumber =
      phoneNumber && phoneNumber_countryCode
        ? phone_utils.parse(phoneNumber, phoneNumber_countryCode)
        : undefined;

    const notificationId = "submit-new-client-and-pet";

    if (parsedPhoneNumber === null) {
      showNotification(
        errorNotification({
          id: notificationId,
          message: t("ERROR"),
          title: t("INVALID_PHONE_NUMBER"),
        })
      );
      return;
    }

    const clientData: ClientData = {
      creditBalance: 0,
      email,
      name,
      outstandingBalance: 0,
      phoneNumber: parsedPhoneNumber,
      status: "ACTIVE",
      address_countryCode,
      phoneNumber_countryCode,
      additionalInfo,
      address_city,
      address_Line1,
      address_Line2,
      address_postalCode,
      address_stateOrProvince,
      alert,
      discountId,
      emailNotif,
      thumbURL,
      smsNotif,
      additionalContactInfo,
      taxID,
    };

    obju.removeUndefined(clientData);

    const patientData: PatientData = {
      dateOfBirth: petDateOfBirth,
      name: petName,
      ownerId: name, // TODO this might be hacky, just didnt want to pass in a new id
      ownerName: name,
      sex: petSex,
      species: petSpecies,
      status: "ACTIVE",
      alert: petAlert,
      breed: petBreed,
      color: petColor,
      externalIDs: petExternalIDs,
      thumbURL: petPictureURL,
    };

    obju.removeUndefined(patientData);

    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("ADDING_NEW_CLIENT"),
      })
    );

    // good
    // console.log(patientDataSchema.safeParse(patientData));

    setSubmitState("pending-response");
    const result = await db.addNewClientAndPatient({
      clientData,
      patientData,
    });

    if (result.type === "success") {
      if (result.payload) {
        if (file) {
          await fileUpload.submitThumbnail(file, result.payload.clientDocPath);
        }
        if (pFile) {
          await pFileUpload.submitThumbnail(
            pFile,
            result.payload.patientDocPath
          );
        }
      }
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(
        result.type === "error" ? "error" : "success"
      )({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form
      id="new-client-form"
      onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}
    >
      <Container fluid>
        <Stack spacing="xl">
          {!quick && (
            <Group mt="xl">
              <MoveBackButton />
            </Group>
          )}
          <BBox header={<Text weight={500}>{t("NEW_CLIENT")}</Text>}>
            <Stack p="xl">
              <div>
                <Text>{t("CLIENT")}</Text>
                <Divider />
              </div>
              {!quick && (
                <BThumbnailInput
                  file={file}
                  setFile={(v) => {
                    console.log(v);

                    if (!v) {
                      fileUpload.clearThumbnail();
                    } else {
                      fileUpload.thumbnailReady();
                    }
                    setFile(v);
                  }}
                />
              )}

              <TextInput
                required
                label={t("NAME")}
                {...form.getInputProps("name")}
              />
              <TextInput label={t("TAX_ID")} {...form.getInputProps("taxID")} />
              <Group grow>
                <TextInput
                  label={t("PRIMARY_MOBILE_PHONE_NUMBER")}
                  {...form.getInputProps("phoneNumber")}
                />
                <CountryCodeSelect
                  label={t("PHONE_COUNTRY")}
                  {...form.getInputProps("phoneNumber_countryCode")}
                />
              </Group>
              <TextInput
                type="email"
                label={t("PRIMARY_EMAIL")}
                {...form.getInputProps("email")}
                onChange={(e) => {
                  const v = e.target.value === "" ? undefined : e.target.value;
                  console.log(v);

                  form.setFieldValue("email", v);
                }}
              />
              {!quick && (
                <>
                  <Input.Wrapper label={t("ADDITIONAL_CONTACT_INFO")}>
                    <Stack pt="md">
                      {form.values.additionalContactInfo &&
                        form.values.additionalContactInfo.map((v, index) => (
                          <Group key={v.id}>
                            <ContactInfoTypeSelect
                              {...form.getInputProps(
                                `additionalContactInfo.${index}.type`
                              )}
                            />
                            <TextInput
                              {...form.getInputProps(
                                `additionalContactInfo.${index}.description`
                              )}
                            />
                            <ActionIcon
                              onClick={() =>
                                form.setFieldValue(
                                  "additionalContactInfo",
                                  (
                                    form.values.additionalContactInfo ?? []
                                  ).filter((elem) => elem.id !== v.id)
                                )
                              }
                            >
                              <IconTrash size={18} />
                            </ActionIcon>
                          </Group>
                        ))}
                      <Group>
                        <Button
                          onClick={() => {
                            form.values.additionalContactInfo
                              ? form.setFieldValue("additionalContactInfo", [
                                  ...form.values.additionalContactInfo,
                                  {
                                    type: "PHONE_NUMBER",
                                    description: "",
                                    id: id_util.newId20(),
                                  },
                                ])
                              : form.setFieldValue("additionalContactInfo", [
                                  {
                                    type: "PHONE_NUMBER",
                                    description: "",
                                    id: id_util.newId20(),
                                  },
                                ]);
                          }}
                        >
                          {t("ADD_CONTACT_INFO")}
                        </Button>
                      </Group>
                    </Stack>
                  </Input.Wrapper>
                  <Group grow align="flex-start" spacing="xl">
                    <TextInput
                      label={t("ADDRESS_LINE_1")}
                      {...form.getInputProps("address_Line1")}
                    />
                    <TextInput
                      label={t("ADDRESS_LINE_2")}
                      {...form.getInputProps("address_Line2")}
                    />
                  </Group>
                  <Group grow align="flex-start" spacing="xl">
                    <TextInput
                      label={t("ADDRESS_STATE_OR_PROVINCE")}
                      {...form.getInputProps("address_stateOrProvince")}
                    />
                    <TextInput
                      label={t("ADDRESS_CITY")}
                      {...form.getInputProps("address_city")}
                    />

                    <TextInput
                      label={t("ADDRESS_POSTAL_CODE")}
                      {...form.getInputProps("address_postalCode")}
                    />
                    <CountryCodeSelect
                      label={t("COUNTRY")}
                      {...form.getInputProps("address_countryCode")}
                    />
                  </Group>
                  <TextInput
                    label={t("ALERT")}
                    {...form.getInputProps("alert")}
                  />
                  <Input.Wrapper label={t("NOTIFICATIONS")}>
                    <Stack p="md" spacing="xs">
                      <Checkbox
                        label={t("EMAIL")}
                        {...form.getInputProps("emailNotif", {
                          type: "checkbox",
                        })}
                      />
                      <Checkbox
                        label={t("SMS")}
                        {...form.getInputProps("smsNotif", {
                          type: "checkbox",
                        })}
                      />
                    </Stack>
                  </Input.Wrapper>
                  <Textarea
                    label={t("ADDITIONAL_INFORMATION")}
                    {...form.getInputProps("additionalInfo")}
                  />
                </>
              )}
              <div>
                <Text>{t("PATIENT")}</Text>
                <Divider />
              </div>
              {!quick && (
                <BThumbnailInput
                  file={pFile}
                  setFile={(v) => {
                    console.log(v);

                    if (!v) {
                      pFileUpload.clearThumbnail();
                    } else {
                      pFileUpload.thumbnailReady();
                    }
                    setPFile(v);
                  }}
                />
              )}
              <TextInput
                required
                label={t("NAME")}
                {...form.getInputProps("petName")}
              />
              <PatientSexSelect required {...form.getInputProps("petSex")} />
              <PatientSpeciesSelect
                required
                {...form.getInputProps("petSpecies")}
              />
              <TextInput
                label={t("BREED")}
                {...form.getInputProps("petBreed")}
              />
              <BDateInput
                required
                maxDateTime={Date.now()}
                label={t("DATE_OF_BIRTH")}
                {...form.getInputProps("petDateOfBirth")}
              />
              {!quick && (
                <>
                  <TextInput
                    label={t("COLOR")}
                    {...form.getInputProps("petColor")}
                  />
                  <TextInput
                    label={t("ALERT")}
                    {...form.getInputProps("petAlert")}
                  />
                  <Input.Wrapper label={t("IDENTIFIERS")}>
                    <Stack pt="md">
                      {form.values.petExternalIDs &&
                        form.values.petExternalIDs.map((v, index) => (
                          <Group key={v.id}>
                            <PatientExternalIdSelect
                              {...form.getInputProps(
                                `petExternalIDs.${index}.type`
                              )}
                            />
                            <TextInput
                              {...form.getInputProps(
                                `petExternalIDs.${index}.description`
                              )}
                            />
                            <ActionIcon
                              onClick={() =>
                                form.setFieldValue(
                                  "petExternalIDs",
                                  (form.values.petExternalIDs ?? []).filter(
                                    (elem) => elem.id !== v.id
                                  )
                                )
                              }
                            >
                              <IconTrash size={18} />
                            </ActionIcon>
                          </Group>
                        ))}
                      <Group>
                        <Button
                          onClick={() => {
                            form.values.petExternalIDs
                              ? form.setFieldValue("petExternalIDs", [
                                  ...form.values.petExternalIDs,
                                  {
                                    type: "ACOUSTIC_TAG",
                                    description: "",
                                    id: id_util.newId20(),
                                  },
                                ])
                              : form.setFieldValue("petExternalIDs", [
                                  {
                                    type: "ACOUSTIC_TAG",
                                    description: "",
                                    id: id_util.newId20(),
                                  },
                                ]);
                          }}
                        >
                          {t("ADD_IDENTIFIER")}
                        </Button>
                      </Group>
                    </Stack>
                  </Input.Wrapper>
                </>
              )}
              <Checkbox
                required
                label={t("USER_AGREED_TO_BEITARY_TOS_AND_PP")}
                checked={form.values.hasAgreedToTOSAndPP === true}
                onChange={(e) =>
                  form.setFieldValue(
                    "hasAgreedToTOSAndPP",
                    e.currentTarget.checked
                  )
                }
              />
            </Stack>
          </BBox>
          <Group position="right">
            <SaveButton
              state={submitState}
              canSave={form.isValid()}
              formId="new-client-form"
            />
          </Group>
        </Stack>
      </Container>
    </form>
  );
};
