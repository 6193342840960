import {
  EmailReminderNotificationTemplate,
  EmailReminderNotificationTemplateData,
  EMAIL_REMINDER_NOTIFICATION_TEMPLATE_VERSION,
  errorResult,
  normalizeEmailReminderNotificationTemplate,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

interface AddEmailReminderNotificationTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: EmailReminderNotificationTemplateData;
  }): Promise<Result<boolean | null>>;
}

const addEmailReminderNotificationTemplate: AddEmailReminderNotificationTemplate =
  async ({ db, organizationId, authorId, authorName, source, data }) => {
    try {
      // create a new ref to get a new emailReminderNotificationTemplate id
      const newEmailReminderNotificationTemplateRef = doc(
        collection(
          db,
          "organizations",
          organizationId,
          "email_reminder_notification_templates"
        )
      );

      // console.log(newEmailReminderNotificationTemplateRef);

      const newEmailReminderNotificationTemplate: EmailReminderNotificationTemplate =
        normalizeEmailReminderNotificationTemplate({
          ...data,
          id: newEmailReminderNotificationTemplateRef.id,
          authorId,
          authorName,
          version: EMAIL_REMINDER_NOTIFICATION_TEMPLATE_VERSION,
          source,
          createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
          lastUpdatedAt: tu.getCurrentDateTime(),
        });

      await setDoc(
        newEmailReminderNotificationTemplateRef,
        newEmailReminderNotificationTemplate
      );

      // t("EMAIL_REMINDER_NOTIFICATION_TEMPLATE_CREATED")
      const successMessage = "EMAIL_REMINDER_NOTIFICATION_TEMPLATE_CREATED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (err: any) {
      return errorResult({ message: err.message });
    }
  };

interface DeleteEmailReminderNotificationTemplate {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteEmailReminderNotificationTemplate: DeleteEmailReminderNotificationTemplate =
  async ({ db, organizationId, id }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "email_reminder_notification_templates",
        id
      );

      try {
        await deleteDoc(docRef);
        // t("EMAIL_REMINDER_NOTIFICATION_TEMPLATE_DELETED")
        const successMessage = "EMAIL_REMINDER_NOTIFICATION_TEMPLATE_DELETED";
        return successResult({
          message: successMessage,
          payload: true,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } catch (err: any) {
      console.log(err);
      return errorResult({ message: err.message });
    }
  };

interface UpdateEmailReminderNotificationTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<EmailReminderNotificationTemplateData>;
  }): Promise<Result<boolean | null>>;
}

const updateEmailReminderNotificationTemplate: UpdateEmailReminderNotificationTemplate =
  async ({ db, organizationId, authorId, authorName, id, source, data }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "email_reminder_notification_templates",
        id
      );

      const updates: Partial<EmailReminderNotificationTemplate> = {
        ...data,
        authorId,
        authorName,
        version: EMAIL_REMINDER_NOTIFICATION_TEMPLATE_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      };

      await updateDoc(docRef, updates);

      // t("EMAIL_REMINDER_NOTIFICATION_TEMPLATE_UPDATED")
      const successMessage = "EMAIL_REMINDER_NOTIFICATION_TEMPLATE_UPDATED";

      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (err: any) {
      console.log(err.message);
      return errorResult({ message: err.message });
    }
  };

// get organization emailReminderNotificationTemplates listener
interface GetEmailReminderNotificationTemplatesListenerCallback {
  (
    emailReminderNotificationTemplates: EmailReminderNotificationTemplate[]
  ): void;
}
interface GetEmailReminderNotificationTemplatesListener {
  db: Firestore;
  organizationId: string;
  callback: GetEmailReminderNotificationTemplatesListenerCallback;
}

const getEmailReminderNotificationTemplatesListener = ({
  db,
  organizationId,
  callback,
}: GetEmailReminderNotificationTemplatesListener): Unsubscribe => {
  try {
    const emailReminderNotificationTemplatesQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "email_reminder_notification_templates"
      )
    );
    return onSnapshot(
      emailReminderNotificationTemplatesQuery,
      (querySnapshot) => {
        const emailReminderNotificationTemplates: EmailReminderNotificationTemplate[] =
          [];
        querySnapshot.forEach((doc) => {
          emailReminderNotificationTemplates.push(
            normalizeEmailReminderNotificationTemplate(doc.data())
          );
        });
        callback(emailReminderNotificationTemplates);
      }
    );
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const emailReminderNotificationTemplates = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    addEmailReminderNotificationTemplate: (
      data: EmailReminderNotificationTemplateData
    ) =>
      addEmailReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    getEmailReminderNotificationTemplatesListener: (
      callback: GetEmailReminderNotificationTemplatesListenerCallback
    ) =>
      getEmailReminderNotificationTemplatesListener({
        db,
        organizationId,
        callback,
      }),
    updateEmailReminderNotificationTemplate: (
      id: string,
      data: Partial<EmailReminderNotificationTemplateData>
    ) =>
      updateEmailReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteEmailReminderNotificationTemplate: (id: string) =>
      deleteEmailReminderNotificationTemplate({
        db,
        organizationId,
        id,
      }),
    archiveEmailReminderNotificationTemplate: (id: string) =>
      updateEmailReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchiveEmailReminderNotificationTemplate: (id: string) =>
      updateEmailReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
