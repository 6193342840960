import {
  ActionIcon,
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Group,
  Input,
  Stack,
  Text,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  IconChevronDown,
  IconCircleCheck,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  Consultation,
  getNotificationByResultType,
  loadingInfoNotification,
  obju,
  Product,
  TreatmentData,
  tu,
} from "beitary-shared";
import { AsyncActionIcon, BBox, BTextEditor } from "components";
import { selectPatients } from "features/Clients/Clients.slice";
import { selectActiveConsultationTreatments } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useIsMobile } from "hooks/useIsMobile";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { BCan } from "permissions";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TxTable } from "./components";
import { ProductMultiSelect } from "./components/ProductMultiSelect";

interface TxPlanProps {
  consultation: Consultation;
}

export const TxPlan = ({ consultation }: TxPlanProps) => {
  const isMobile = useIsMobile();

  const disabled = consultation.status === "LOCKED";
  const patient = useAppSelector(selectPatients).find(
    (p) => p.id === consultation.patientId
  );

  const patientWeight = patient?.weights?.find(
    (w) => w.id === consultation.id
  )?.v;

  const [opened, setOpened] = useState(true);

  const planNotes = Object.values(consultation.planNotes ?? {}).sort(
    (a, b) => a.createdAt - b.createdAt
  );
  const [addTreatmentsState, setAddTreatmentsState] = useSubmitState();
  const [orderAllTreatmentsState, setOrderAllTreatmentsState] =
    useSubmitState();
  const [administerAllTreatmentsState, setAdministerAllTreatmentsState] =
    useSubmitState();
  const [submitRecState, setSubmitRecState] = useSubmitState();
  const [addNoteState, setAddNoteState] = useSubmitState();
  const { t } = useTranslation();

  const consultationDBService =
    useDBServices().consultationsDBService.consultations;

  const treatmentsDBService = useDBServices().consultationsDBService.treatments(
    consultation.id
  );

  // use useMemo for these expensive calculations
  // https://beta.reactjs.org/learn/you-might-not-need-an-effect
  const treatments = useAppSelector(selectActiveConsultationTreatments);

  const plannedTreatments = useMemo(
    () =>
      treatments
        .filter((t) => t.status === "PLANNED")
        .sort((a, b) => a.createdAt - b.createdAt)
        .sort((a, b) => {
          if (a.id && b.id && a.fixedPriceBundleId === b.fixedPriceBundleId) {
            if (
              a.id === a.fixedPriceBundleId &&
              b.id !== b.fixedPriceBundleId
            ) {
              return -1; // a comes before b
            } else if (
              a.id !== a.fixedPriceBundleId &&
              b.id === b.fixedPriceBundleId
            ) {
              return 1; // b comes before a
            } else {
              return 0; // order is irrelevant
            }
          } else {
            return a.fixedPriceBundleId === undefined
              ? 1
              : b.fixedPriceBundleId === undefined
              ? -1
              : a.fixedPriceBundleId.localeCompare(b.fixedPriceBundleId);
          }
        }),
    [treatments]
  );

  const [selectedProducts, setSelectedProducts] = useState<
    (Product & {
      fixedPriceBundleId?: string;
      fixedPriceBundleName?: string;
      isFixedPriceBundle?: boolean;
    })[]
  >([]);

  const [note, setNote] = useState("");
  const [newRecommendations, setNewRecommendations] = useState(
    consultation.recommendations
  );

  const addSelectedProductsToConsultation = async () => {
    if (disabled || !(selectedProducts.length > 0)) return;
    const newTreatments: TreatmentData[] = [];

    // adding this so that I can sort by created at
    // cauz otherwise all txt will have same date
    //const currentTime = tu.getCurrentDateTime();
    selectedProducts.forEach((p, index) => {
      const {
        isFixedPriceBundle,
        fixedPriceBundleId,
        fixedPriceBundleName,
        lotExpirationDate,
        serialNumber,
        lotNumber,
        ccSolventUnit,
        dose,
        doseBase,
      } = p;
      const calculatedDose = Number(
        (dose && doseBase === "kg-of-patient-weight" && patientWeight
          ? patientWeight * dose
          : dose && doseBase === "patient"
          ? dose
          : dose ?? 1
        ).toFixed(2)
      );
      let newTreatment: TreatmentData = {
        consultationId: consultation.id,
        //createdAt: currentTime + index,
        status: "PLANNED",
        type: "STAT",
        qty: calculatedDose,
        productSnapshot: p,
        fixedPriceBundleId,
        fixedPriceBundleName,
        isFixedPriceBundle,
        lotExpirationDate,
        serialNumber,
        lotNumber,
        unit: ccSolventUnit,
      };

      obju.removeUndefined(newTreatment);

      newTreatments.push(newTreatment);
    });

    setAddTreatmentsState("pending-response");
    const result = await treatmentsDBService.addTreatments(newTreatments);

    if (result && result.type === "success") {
      setAddTreatmentsState("success");
    } else {
      setAddTreatmentsState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
  };

  const deleteTreatment = async (id: string) => {
    if (disabled) return;
    await treatmentsDBService.deleteTreatment(id);
  };

  const administerTreatment = async (id: string) => {
    if (disabled) return;
    await treatmentsDBService.administerTreatment(id);
  };

  const administerAllTreatments = async () => {
    if (disabled) return;
    const updates = plannedTreatments.map((t) => t.id);

    setAdministerAllTreatmentsState("pending-response");
    const result = await treatmentsDBService.administerTreatments(updates);
    if (result && result.type === "success") {
      setAdministerAllTreatmentsState("success");
    } else {
      setAdministerAllTreatmentsState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
  };

  const orderAllTreatments = async () => {
    if (disabled) return;
    const updates = plannedTreatments.map((t) => t.id);

    setOrderAllTreatmentsState("pending-response");
    const result = await treatmentsDBService.orderTreatments(updates);
    if (result && result.type === "success") {
      setOrderAllTreatmentsState("success");
    } else {
      setOrderAllTreatmentsState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
  };

  const administerFPBTreatments = async (id: string) => {
    if (disabled) return;
    const txIds = plannedTreatments
      .filter((t) => t.fixedPriceBundleId === id)
      .map((t) => t.id);
    await treatmentsDBService.administerTreatments(txIds);
  };

  const deleteFPBTreatments = async (id: string) => {
    if (disabled) return;
    const txIds = plannedTreatments
      .filter((t) => t.fixedPriceBundleId === id)
      .map((t) => t.id);
    await treatmentsDBService.deleteTreatments(txIds);
  };

  const submitRec = async () => {
    if (disabled || !newRecommendations) return;

    const notificationId = "submit-rec";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_RECOMMENDATIONS"),
      })
    );

    setSubmitRecState("pending-response");
    const result =
      await consultationDBService.updateConsultationPlanRecommendations(
        consultation.id,
        newRecommendations
      );
    if (result.type === "success") {
      setSubmitRecState("success");
    } else {
      setSubmitRecState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t("RECOMMENDATIONS_UPDATED"),
      }),
      id: notificationId,
    });
  };

  const deleteNote = async (id: string) => {
    if (disabled) return;
    console.log("deleting note");

    setAddNoteState("pending-response");
    const result = await consultationDBService.deleteNote({
      consultationId: consultation.id,
      noteCategory: "PLAN",
      noteId: id,
    });
    if (result && result.type === "success") {
      setAddNoteState("success");
      setNote("");
    } else {
      setAddNoteState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
  };

  const addNote = async () => {
    if (disabled) return;
    setAddNoteState("pending-response");
    const result = await consultationDBService.addNote({
      consultationId: consultation.id,
      noteCategory: "PLAN",
      noteData: { content: note },
    });
    if (result && result.type === "success") {
      setAddNoteState("success");
      setNote("");
    } else {
      setAddNoteState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
  };

  return (
    <Stack>
      <BBox
        header={
          <Group position="apart">
            <Text weight={500}>{t("TREATMENT_PLAN")}</Text>
            <ActionIcon onClick={() => setOpened(!opened)}>
              <IconChevronDown
                style={{
                  transform: opened ? "rotate(180deg)" : "none",
                  transitionDuration: "200ms",
                }}
              />
            </ActionIcon>
          </Group>
        }
      >
        <Collapse in={opened} transitionDuration={400}>
          <Stack p="xl">
            <BCan I="am" a="DOCTOR">
              <Input.Wrapper label={t("RECOMMENDATIONS")}>
                <BTextEditor
                  disabled={disabled}
                  value={newRecommendations}
                  onChange={setNewRecommendations}
                />
              </Input.Wrapper>
              <Group position="right">
                <Button
                  disabled={disabled}
                  onClick={submitRec}
                  loading={submitRecState === "pending-response"}
                  color="green"
                  leftIcon={<IconCircleCheck size={18} />}
                >
                  {t("SAVE")}
                </Button>
              </Group>
            </BCan>
            <div>
              <Text weight={500} mt="lg">
                {t("TREATMENTS")}
              </Text>
              <Divider />
            </div>
            <BCan I="am" a="DOCTOR">
              <Group position="apart" align="flex-start">
                <Group align="flex-start">
                  <Box w={isMobile ? 400 : 600}>
                    <ProductMultiSelect
                      disabled={disabled}
                      onChange={(v) => setSelectedProducts(v)}
                      // add
                      // medical
                      // record
                      // notes
                    />
                  </Box>
                  <Button
                    disabled={disabled || selectedProducts.length === 0}
                    loading={addTreatmentsState === "pending-response"}
                    onClick={addSelectedProductsToConsultation}
                  >
                    {t("ADD_TREATMENTS")}
                  </Button>
                </Group>
                <Group>
                  <Button
                    color="teal"
                    disabled={disabled || plannedTreatments.length === 0}
                    loading={orderAllTreatmentsState === "pending-response"}
                    onClick={orderAllTreatments}
                  >
                    {t("ORDER_TREATMENTS")}
                  </Button>
                  <Button
                    disabled={disabled || plannedTreatments.length === 0}
                    loading={
                      administerAllTreatmentsState === "pending-response"
                    }
                    onClick={administerAllTreatments}
                  >
                    {t("ADMINISTER_TREATMENTS")}
                  </Button>
                </Group>
              </Group>
            </BCan>
            <TxTable
              disabled={disabled}
              treatments={plannedTreatments}
              administerTreatment={administerTreatment}
              deleteTreatment={deleteTreatment}
              administerFPBTreatments={administerFPBTreatments}
              deleteFPBTreatments={deleteFPBTreatments}
            />
            <BCan I="am" a="DOCTOR">
              {planNotes.map((n) => (
                <Card withBorder radius="xs" p="xs" key={n.id}>
                  <Group position="apart" align="flex-start">
                    <Stack spacing={0}>
                      <Text>{HTMLReactParser(n.content)}</Text>
                      <Text color="dimmed" size="sm">
                        {`${t("CREATED_BY")} ${n.authorName} ${t(
                          "ON"
                        )} ${tu.getDateAndTimeString(n.createdAt)}`}
                      </Text>
                    </Stack>
                    <AsyncActionIcon
                      disabled={disabled}
                      icon={<IconTrash size={18} />}
                      f={async () => deleteNote(n.id)}
                    />
                  </Group>
                </Card>
              ))}
              <Input.Wrapper label={t("NOTES")}>
                <BTextEditor
                  disabled={disabled}
                  value={note}
                  onChange={setNote}
                />
              </Input.Wrapper>
              <Group position="right">
                <Button
                  onClick={addNote}
                  disabled={disabled || note.length === 0 || note === "<p></p>"}
                  loading={addNoteState === "pending-response"}
                  leftIcon={<IconPlus size={18} />}
                >
                  {t("ADD_NOTE")}
                </Button>
              </Group>
            </BCan>
          </Stack>
        </Collapse>
      </BBox>
    </Stack>
  );
};
