import {
  errorResult,
  normalizeSMSReminderNotificationTemplate,
  Result,
  SMSReminderNotificationTemplate,
  SMSReminderNotificationTemplateData,
  SMS_REMINDER_NOTIFICATION_TEMPLATE_VERSION,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

interface AddSMSReminderNotificationTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: SMSReminderNotificationTemplateData;
  }): Promise<Result<boolean | null>>;
}

const addSMSReminderNotificationTemplate: AddSMSReminderNotificationTemplate =
  async ({ db, organizationId, authorId, authorName, source, data }) => {
    try {
      // create a new ref to get a new smsReminderNotificationTemplate id
      const newSMSReminderNotificationTemplateRef = doc(
        collection(
          db,
          "organizations",
          organizationId,
          "sms_reminder_notification_templates"
        )
      );

      // console.log(newSMSReminderNotificationTemplateRef);

      const newSMSReminderNotificationTemplate: SMSReminderNotificationTemplate =
        normalizeSMSReminderNotificationTemplate({
          ...data,
          id: newSMSReminderNotificationTemplateRef.id,
          authorId,
          authorName,
          version: SMS_REMINDER_NOTIFICATION_TEMPLATE_VERSION,
          source,
          createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
          lastUpdatedAt: tu.getCurrentDateTime(),
        });

      await setDoc(
        newSMSReminderNotificationTemplateRef,
        newSMSReminderNotificationTemplate
      );

      // t("SMS_REMINDER_NOTIFICATION_TEMPLATE_CREATED")
      const successMessage = "SMS_REMINDER_NOTIFICATION_TEMPLATE_CREATED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (err: any) {
      return errorResult({ message: err.message });
    }
  };

interface DeleteSMSReminderNotificationTemplate {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteSMSReminderNotificationTemplate: DeleteSMSReminderNotificationTemplate =
  async ({ db, organizationId, id }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "sms_reminder_notification_templates",
        id
      );

      try {
        await deleteDoc(docRef);
        // t("SMS_REMINDER_NOTIFICATION_TEMPLATE_DELETED")
        const successMessage = "SMS_REMINDER_NOTIFICATION_TEMPLATE_DELETED";
        return successResult({
          message: successMessage,
          payload: true,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } catch (err: any) {
      console.log(err);
      return errorResult({ message: err.message });
    }
  };

interface UpdateSMSReminderNotificationTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<SMSReminderNotificationTemplateData>;
  }): Promise<Result<boolean | null>>;
}

const updateSMSReminderNotificationTemplate: UpdateSMSReminderNotificationTemplate =
  async ({ db, organizationId, authorId, authorName, id, source, data }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "sms_reminder_notification_templates",
        id
      );

      const updates: Partial<SMSReminderNotificationTemplate> = {
        ...data,
        authorId,
        authorName,
        version: SMS_REMINDER_NOTIFICATION_TEMPLATE_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      };

      await updateDoc(docRef, updates);

      // t("SMS_REMINDER_NOTIFICATION_TEMPLATE_UPDATED")
      const successMessage = "SMS_REMINDER_NOTIFICATION_TEMPLATE_UPDATED";

      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (err: any) {
      console.log(err.message);
      return errorResult({ message: err.message });
    }
  };

// get organization smsReminderNotificationTemplates listener
interface GetSMSReminderNotificationTemplatesListenerCallback {
  (smsReminderNotificationTemplates: SMSReminderNotificationTemplate[]): void;
}
interface GetSMSReminderNotificationTemplatesListener {
  db: Firestore;
  organizationId: string;
  callback: GetSMSReminderNotificationTemplatesListenerCallback;
}

const getSMSReminderNotificationTemplatesListener = ({
  db,
  organizationId,
  callback,
}: GetSMSReminderNotificationTemplatesListener): Unsubscribe => {
  try {
    const smsReminderNotificationTemplatesQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "sms_reminder_notification_templates"
      )
    );
    return onSnapshot(
      smsReminderNotificationTemplatesQuery,
      (querySnapshot) => {
        const smsReminderNotificationTemplates: SMSReminderNotificationTemplate[] =
          [];
        querySnapshot.forEach((doc) => {
          smsReminderNotificationTemplates.push(
            normalizeSMSReminderNotificationTemplate(doc.data())
          );
        });
        callback(smsReminderNotificationTemplates);
      }
    );
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const smsReminderNotificationTemplates = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    addSMSReminderNotificationTemplate: (
      data: SMSReminderNotificationTemplateData
    ) =>
      addSMSReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    getSMSReminderNotificationTemplatesListener: (
      callback: GetSMSReminderNotificationTemplatesListenerCallback
    ) =>
      getSMSReminderNotificationTemplatesListener({
        db,
        organizationId,
        callback,
      }),
    updateSMSReminderNotificationTemplate: (
      id: string,
      data: Partial<SMSReminderNotificationTemplateData>
    ) =>
      updateSMSReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteSMSReminderNotificationTemplate: (id: string) =>
      deleteSMSReminderNotificationTemplate({
        db,
        organizationId,
        id,
      }),
    archiveSMSReminderNotificationTemplate: (id: string) =>
      updateSMSReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchiveSMSReminderNotificationTemplate: (id: string) =>
      updateSMSReminderNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
