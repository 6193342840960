import { Checkbox, Group } from "@mantine/core";
import { ReactNode, useState } from "react";

export interface BCheckboxGroupProps {
  data: { value: string; label: string }[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string[]) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  label?: ReactNode;
  description?: ReactNode;
  placeholder?: string;
}

export const BCheckboxGroup = ({
  data,
  required,
  disabled,
  onChange,
  error,
  value,
  label,
  description,
  placeholder,
}: BCheckboxGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string[] | undefined>(
    value
  );

  const onSelectChange = (value: string[]) => {
    onChange && onChange(value);
    setSelectedValue(value);
  };

  return (
    <Checkbox.Group
      required={required}
      error={error}
      label={label}
      description={description}
      placeholder={placeholder}
      onChange={onSelectChange}
      value={selectedValue}
    >
      <Group>
        {data.map((d) => (
          <Checkbox
            key={d.value}
            disabled={disabled}
            value={d.value}
            label={d.label}
          />
        ))}
      </Group>
    </Checkbox.Group>
  );
};
