import { createStyles, SegmentedControl } from "@mantine/core";
import { ReactNode, useState } from "react";

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.colors.gray[2]}`,
  },
}));

export interface BAdvancedRadioGroupProps {
  data: { value: string; label: ReactNode }[];
  onChange?: (value: string) => void;
  value?: string; // could be undefined if form has no initial values
  placeholder?: string;
  disabled?: boolean;
}

export const BAdvancedRadioGroup = ({
  data,
  onChange,
  value,
  placeholder,
  disabled,
}: BAdvancedRadioGroupProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);
  const { classes } = useStyles();

  const onSelectChange = (value: string) => {
    onChange && onChange(value);
    setSelectedValue(value);
  };

  return (
    <SegmentedControl
      color="cyan.5"
      placeholder={placeholder}
      onChange={onSelectChange}
      value={selectedValue}
      data={data}
      classNames={classes}
      disabled={disabled}
    />
  );
};
