import { UnableToNormalizeData } from "beitary-shared";
import { z } from "zod";
import { communicationHistorySchema } from "./CommunicationHistory";

export const smsCommunicationHistorySchema = communicationHistorySchema.merge(
  z.object({
    message: z.string().optional(),
  })
);

export type SMSCommunicationHistory = z.infer<
  typeof smsCommunicationHistorySchema
>;

export const normalizeSMSCommunicationHistory = (
  data: any
): SMSCommunicationHistory => {
  try {
    return smsCommunicationHistorySchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};
