import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Organization } from "beitary-shared";

interface OrganizationsState {
  organization: Organization | null;
}

const key = "organization";

const initialState: OrganizationsState = {
  organization: null,
};

export const organizationSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization | null>) => {
      state.organization = action.payload;
    },
  },
});

export const { setOrganization } = organizationSlice.actions;

export const selectOrganization = (state: RootState) =>
  state.organization.organization;

export const organizationReducer = organizationSlice.reducer;
