import { z } from "zod";

// t("PENDING")
// t("PARTIALLY_RECEIVED")
// t("CLOSED")
// t("READY_TO_ORDER")
export const purchaseOrderStatusEnum = z.enum([
  "PENDING",
  "PARTIALLY_RECEIVED",
  "CLOSED",
  "ORDERED",
  "READY_TO_ORDER",
]);

export type PurchaseOrderStatus = z.infer<typeof purchaseOrderStatusEnum>;
