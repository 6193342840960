import { zodResolver } from "@mantine/form";
import { z } from "zod";

const signinFormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
});

export type SigninFormValues = z.infer<typeof signinFormSchema>;

export const rules = zodResolver(signinFormSchema);
