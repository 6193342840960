import { Container, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { ConsTx } from "./Whiteboard.container";

interface ViewProps {
  consTx: ConsTx[];
}

export const View = ({ consTx }: ViewProps) => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Title mt="xl" mb="xl" weight={500} order={3}>
        {t("WHITEBOARD")}
      </Title>
      <Outlet />
    </Container>
  );
};
