import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export interface CommunicationHistoryProps {}

export const CommunicationHistory = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("communication-history/email")
    ? "email"
    : path.includes("communication-history/sms")
    ? "sms"
    : "email";

  return (
    <Tabs
      mt="xl"
      value={activeTab}
      onTabChange={(value) => {
        navigate(`${value}`);
      }}
      variant="pills"
      radius="xs"
    >
      <Tabs.List mb="xl">
        <Tabs.Tab value="email">{t("EMAIL")}</Tabs.Tab>
        <Tabs.Tab value="sms">{t("SMS")}</Tabs.Tab>
      </Tabs.List>
      <Outlet />
    </Tabs>
  );
};
