import { zodResolver } from "@mantine/form";
import { bundleDataSchema } from "beitary-shared";
import { z } from "zod";

// t("PRICE_AND_CATEGORY_REQUIRED")

const bundleFormSchema = bundleDataSchema.refine(
  (data) =>
    data.hasFixedPrice ? !!data.unitSellingPrice && !!data.categoryId : true,
  {
    message: "PRICE_AND_CATEGORY_REQUIRED",
  }
);

export type BundleFormValues = z.infer<typeof bundleFormSchema>;

export const rules = zodResolver(bundleFormSchema);
