import { Whiteboard } from "features/whiteboard";
import { AllTreatments } from "features/whiteboard/components";
import { Consultation } from "features/whiteboard/components/Consultation";
import { Route } from "react-router-dom";

export const WhiteboardRoutes = (
  <Route path="whiteboard" element={<Whiteboard />}>
    <Route index element={<AllTreatments />} />
    <Route path="consultations/:id/*" element={<Consultation />}></Route>
  </Route>
);
