import { zodResolver } from "@mantine/form";
import { inventoryItemDataSchema } from "beitary-shared";
import { z } from "zod";

// t("UNITS_AND_UNIT_TYPE_REQUIRED")

const itemFormSchema = inventoryItemDataSchema.refine(
  (data) =>
    data.units || data.unitType ? !!data.units && !!data.unitType : true,
  {
    message: "UNITS_AND_UNIT_TYPE_REQUIRED",
  }
);

export type ItemFormValues = z.infer<typeof itemFormSchema>;

export const rules = zodResolver(itemFormSchema);
