import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { physicalExamTemplates } from "./physical-exam-templates";

export const templates = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    physicalExamTemplates: physicalExamTemplates(
      db,
      organizationId,
      authorId,
      authorName,
      source
    ),
  };
};
