import { z } from "zod";
import {
  BaseRecord,
  baseRecordSchema,
} from "../../../types-schemas/baseRecord";
import { UnableToNormalizeData } from "../../../types-schemas/errors";
/**
 * schema and types versions
 * from first to last
 */

// v0

const quotaDataV0Schema = z.object({
  email: z.object({
    base: z.number(),
    consumed: z.number(),
    consuming: z.number(),
  }),
  sms: z.object({
    base: z.number(),
    consumed: z.number(),
    consuming: z.number(),
  }),
  storage: z.object({
    base: z.number(),
    consumed: z.number(),
    consuming: z.number(),
  }),
  // resets: {
  //  "2023-09":
  //    {"at":17994545841,
  //    "subscriotionsIds":["HsbYhgf4AS44","bAYshgf4S44H"]}
  //  }
  resets: z.record(
    z.string(),
    z.object({
      at: z.number().int().positive(),
      subscriptionsIds: z.array(z.string()),
    })
  ),
});

type QuotaDataV0 = z.infer<typeof quotaDataV0Schema>;

const quotaV0Schema = baseRecordSchema.merge(quotaDataV0Schema);

type QuotaV0 = z.infer<typeof quotaV0Schema>;

const normalizeQuotaV0 = (data: any): Quota => {
  try {
    const quotaV0: QuotaV0 = quotaV0Schema.parse(data);

    const { ...rest } = quotaV0;

    // here it's same object cause current v is 0
    const quota: Quota = {
      ...rest,
    };
    return quota;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const QUOTA_VERSION = "0";

export type QuotaData = QuotaDataV0;

export type Quota = BaseRecord & QuotaData;

export const quotaDataSchema = quotaDataV0Schema;

export const quotaSchema = quotaV0Schema;

export const normalizeQuota = (data: any): Quota => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeQuotaV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
