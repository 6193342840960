import { countries_util } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface CountryCodeSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  label?: string;
}

export const CountryCodeSelect = ({
  label,
  ...props
}: CountryCodeSelectProps) => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const data = countries_util.getCountriesListByLanguage(currentLanguage);

  return (
    <BSelect
      {...props}
      data={data}
      label={label ?? t("COUNTRY")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
