import { Anchor, Avatar, createStyles, Group, Text } from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Consultation, tu } from "beitary-shared";
import { BBox, TableWithSearchAndSort } from "components";
import { selectClinicLocations } from "features/admin/clinic-settings/ClinicSettings.slice";
import { selectActiveConsultations } from "features/consultations/Consultations.slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type ConsultationsListItem = Consultation & {
  patientClientName: string;
};

const useStyles = createStyles((theme) => ({
  tr: {
    // backgroundColor: theme.white,
    // borderRadius: theme.radius.xs,
    // margin: theme.spacing.xl,
  },
}));

export const ActiveConsultationsList = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const consultations = useAppSelector(selectActiveConsultations);
  const locations = useAppSelector(selectClinicLocations);

  const tableConsultations: ConsultationsListItem[] = consultations.map(
    (consultation) => ({
      ...consultation,
      patientClientName: consultation.patientName + consultation.clientName,
    })
  );

  const fields: {
    key: keyof ConsultationsListItem;
    label: string;
  }[] = [
    { key: "patientClientName", label: t("PATIENT") },
    { key: "checkedInTime", label: t("CHECKED_IN") },
    { key: "patientLocation", label: t("PATIENT_LOCATION") },
    { key: "supervisingDoctorId", label: t("DOCTOR") },
    { key: "reasonForConsultation", label: t("REASON_FOR_CONSULTATION") },
  ];

  const getRow = (consultation: ConsultationsListItem) => (
    <tr key={consultation.id} className={classes.tr}>
      <td>
        <Group>
          <Avatar src={consultation.patientPictureURL} size={50} radius="xl">
            <IconPaw size={30} />
          </Avatar>
          <div>
            <Anchor
              color="dark"
              weight={500}
              lineClamp={1}
              component={Link}
              to={`/clients/${consultation.clientId}/patients/${consultation.patientId}`}
            >
              {consultation.patientName} {<IconExternalLink size={12} />}
            </Anchor>
            <Anchor
              color="dark"
              weight={400}
              lineClamp={1}
              component={Link}
              to={`/clients/${consultation.clientId}`}
            >
              {consultation.clientName} {<IconExternalLink size={12} />}
            </Anchor>
          </div>
        </Group>
      </td>
      <td>
        <Text>{tu.getDateAndTimeString(consultation.checkedInTime)}</Text>
      </td>
      <td>
        <Text>
          {locations.find((l) => consultation.patientLocation === l.id)?.name}
        </Text>
      </td>
      <td>
        <Text>{consultation.supervisingDoctorName}</Text>
      </td>
      <td>
        <Text>{consultation.reasonForConsultation}</Text>
      </td>
      <td>
        <Anchor
          color="dark"
          weight={400}
          lineClamp={1}
          component={Link}
          to={`/consultations/${consultation.id}/summary`}
        >
          {<IconExternalLink size={16} />}
        </Anchor>
      </td>
    </tr>
  );
  return (
    <BBox p="xl" bgcs={0}>
      <TableWithSearchAndSort
        data={tableConsultations}
        fields={fields}
        getRow={getRow}
        hasControlsColumn
      />
    </BBox>
  );
};
