import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectClients } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Wrapper } from "./Client.wrapper";

export const Client = () => {
  const { t } = useTranslation();

  // currently not fetching from db, just from store
  // prolly only worth it to fetch from db if average clients count > 1000
  // to re evaluate after app used
  const { clientId } = useParams();

  const client = useAppSelector(selectClients).find((c) => c.id === clientId);

  if (!client || !clientId) {
    return <Text>{t("NOT_FOUND")}</Text>;
  } else {
    return <Wrapper client={client} />;
  }
};
