import {
  Accordion,
  ActionIcon,
  Collapse,
  Group,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { IconArrowBack, IconChevronDown, IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Consultation, tu } from "beitary-shared";
import { AsyncActionIcon, BBox } from "components";
import { selectActiveConsultationTreatments } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useIsMobile } from "hooks/useIsMobile";
import { BCan } from "permissions";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TxCard } from "./components";

interface TxHistoryProps {
  consultation: Consultation;
}

export const TxHistory = ({ consultation }: TxHistoryProps) => {
  const disabled = consultation.status === "LOCKED";
  const isMobile = useIsMobile();

  const { t } = useTranslation();
  const theme = useMantineTheme();
  const txDBService = useDBServices().consultationsDBService.treatments(
    consultation.id
  );
  const navigate = useNavigate();

  const treatments = useAppSelector(selectActiveConsultationTreatments);

  const administredTreatments = useMemo(
    () =>
      treatments
        .filter((t) => t.status === "ADMINISTRED")
        .sort((a, b) => (a.dateAdministred ?? 0) - (b.dateAdministred ?? 0))
        .sort((a, b) => {
          if (a.id && b.id && a.fixedPriceBundleId === b.fixedPriceBundleId) {
            if (
              a.id === a.fixedPriceBundleId &&
              b.id !== b.fixedPriceBundleId
            ) {
              return -1; // a comes before b
            } else if (
              a.id !== a.fixedPriceBundleId &&
              b.id === b.fixedPriceBundleId
            ) {
              return 1; // b comes before a
            } else {
              return 0; // order is irrelevant
            }
          } else {
            return a.fixedPriceBundleId === undefined
              ? 1
              : b.fixedPriceBundleId === undefined
              ? -1
              : a.fixedPriceBundleId.localeCompare(b.fixedPriceBundleId);
          }
        }),
    [treatments]
  );

  const [opened, setOpened] = useState(administredTreatments.length !== 0);

  useEffect(() => {
    setOpened(administredTreatments.length !== 0);
  }, [administredTreatments]);

  const deadministerTreatment = async (id: string) => {
    if (disabled) return;
    return await txDBService.deadministerTreatment(id);
  };

  const deadministerFPBTreatments = async (id: string) => {
    if (disabled) return;
    const txIds = administredTreatments
      .filter((t) => t.fixedPriceBundleId === id)
      .map((t) => t.id);
    return await txDBService.deadministerTreatments(txIds);
  };

  return (
    <Stack>
      <BBox
        header={
          <Group position="apart">
            <Text weight={500}>{t("TREATMENT_HISTORY")}</Text>
            <ActionIcon onClick={() => setOpened(!opened)}>
              <IconChevronDown
                style={{
                  transform: opened ? "rotate(180deg)" : "none",
                  transitionDuration: "200ms",
                }}
              />
            </ActionIcon>
          </Group>
        }
      >
        <Collapse in={opened} transitionDuration={400}>
          <Stack p="xl">
            <Text weight={500} mt="md">
              {t("TREATMENTS")}
            </Text>

            <Accordion variant="contained" chevronPosition="left">
              {administredTreatments.length === 0 && (
                <Text color="dimmed" size="sm">
                  {t("NO_TREATMENT_ADMINISTRED_YET")}
                </Text>
              )}
              {administredTreatments.map((treatment) => (
                <Accordion.Item value={treatment.id} key={treatment.id}>
                  <Group>
                    <Accordion.Control
                      w={46}
                      h={46}
                      sx={{
                        borderRight: `1px solid ${theme.colors.gray[3]}`,
                      }}
                    />
                    <Group
                      pr="lg"
                      position="apart"
                      sx={{
                        flexGrow: 1, // idk why but it's working
                      }}
                    >
                      <Group w={isMobile ? 110 : 350} ml={0} p={0}>
                        <Text>{treatment.productSnapshot.name}</Text>
                        {/* {treatment.bundleId && treatment.bundleName && (
                          <Tooltip label={treatment.bundleName}>
                            <ThemeIcon color="gray" size="xs" variant="outline">
                              <IconStack2 />
                            </ThemeIcon>
                          </Tooltip>
                        )} */}
                      </Group>
                      <Text mr="xl">{t(treatment.type)}</Text>
                      <Tooltip label={t("QUANTITY/DOSE")}>
                        <Text w={isMobile ? 30 : 100}>
                          {treatment.qty}{" "}
                          {treatment.unit && `(${t(treatment.unit)})`}
                        </Text>
                      </Tooltip>
                      <Tooltip label={t("DATE_ADMINISTRED")}>
                        <Text w={isMobile ? 60 : 150} color="dimmed">
                          {treatment.dateAdministred &&
                            tu.getDateAndTimeString(treatment.dateAdministred)}
                        </Text>
                      </Tooltip>
                      <Group w={80}>
                        {(treatment.fixedPriceBundleId === treatment.id ||
                          !treatment.fixedPriceBundleId) && (
                          <BCan I="am" a="DOCTOR">
                            <ActionIcon
                              disabled={disabled}
                              onClick={() =>
                                navigate(
                                  treatment.fixedPriceBundleId === treatment.id
                                    ? `bundle-treatments/administred/${treatment.id}/edit`
                                    : `treatments/administred/${treatment.id}/edit`
                                )
                              }
                            >
                              <IconEdit size={18} />
                            </ActionIcon>
                            <AsyncActionIcon
                              disabled={disabled}
                              icon={<IconArrowBack size={18} />}
                              f={async () =>
                                treatment.fixedPriceBundleId === treatment.id
                                  ? deadministerFPBTreatments(treatment.id)
                                  : deadministerTreatment(treatment.id)
                              }
                            />
                          </BCan>
                        )}
                      </Group>
                    </Group>
                  </Group>
                  <Accordion.Panel>
                    <TxCard disabled={disabled} treatment={treatment} />
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Stack>
        </Collapse>
      </BBox>
    </Stack>
  );
};
