import {
  Badge,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { BoardingAppointment, tu } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentForm } from "../../../AppointmentForm";

export interface AppointmentCardProps {
  appointment: BoardingAppointment;
  color: string;
  leftPositionRatio: number;
  widthRatio: number;
  topPosition: number;
}

export const AppointmentCard = ({
  appointment,
  color,
  leftPositionRatio,
  widthRatio,
  topPosition,
}: AppointmentCardProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="xl"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("EDIT_RESERVATION")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack>
          <AppointmentForm appointment={appointment} />
        </Stack>
      </Modal>

      <UnstyledButton
        sx={{
          position: "absolute",
          left: `calc(${leftPositionRatio}*100%)`,
          top: topPosition,
          border: `1px solid ${color}`,
          backgroundColor: "white",
          borderRadius: 4,
          overflow: "hidden",
          height: 120,
          width: `calc(${widthRatio}*100%)`,
          "&:hover": {
            filter: `brightness(97%)`,
            transitionDuration: "0.3s",
          },
        }}
        onClick={() => setOpened(true)}
      >
        <Stack p="lg" justify="flex-start" h="100%" spacing={0}>
          <Flex
            direction={{ base: "row", xs: "column" }}
            gap={{ base: "sm", xs: 0 }}
            align="flex-start"
          >
            <Text size="sm" weight="bold">
              {appointment.patientName}
            </Text>
            <Text size="sm" color="dimmed">
              {tu.getDateAndTimeString(appointment.from)}
              {" - "}
              {tu.getDateAndTimeString(appointment.to)}
            </Text>
          </Flex>
          <Group>
            <Badge size="lg">{t(appointment.status + "_APT")}</Badge>
          </Group>
        </Stack>
      </UnstyledButton>
    </>
  );
};
