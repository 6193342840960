import {
  BoardingAppointment,
  BoardingAppointmentData,
  BOARDING_APPOINTMENT_VERSION,
  errorResult,
  normalizeBoardingAppointment,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";

// add organization boardingAppointment

interface AddBoardingAppointment {
  ({
    db,
    authorId,
    authorName,
    source,
    data,
    id,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    source: Source;
    data: BoardingAppointmentData;
    id?: string;
  }): Promise<Result<boolean | null>>;
}

const addBoardingAppointment: AddBoardingAppointment = async ({
  db,
  authorId,
  authorName,
  source,
  data,
  id,
}) => {
  try {
    // create a new ref to get a new boardingAppointment id
    const newBoardingAppointmentRef = id
      ? doc(db, "boarding_appointments", id)
      : doc(collection(db, "boarding_appointments"));

    // console.log(newBoardingAppointmentRef);

    const newBoardingAppointment: BoardingAppointment =
      normalizeBoardingAppointment({
        ...data,
        id: newBoardingAppointmentRef.id,
        authorId,
        authorName,
        version: BOARDING_APPOINTMENT_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

    await setDoc(newBoardingAppointmentRef, newBoardingAppointment);

    const successMessage = "BoardingAppointment created!";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetBoardingAppointment {
  ({ db, id }: { db: Firestore; id: string }): Promise<
    Result<BoardingAppointment | null>
  >;
}

const getBoardingAppointment: GetBoardingAppointment = async ({ db, id }) => {
  try {
    const boardingAppointmentDocRef = doc(db, "boarding_appointments", id);
    const boardingAppointmentDocSnapshot = await getDoc(
      boardingAppointmentDocRef
    );
    if (boardingAppointmentDocSnapshot.exists()) {
      try {
        const data: unknown = boardingAppointmentDocSnapshot.data();
        const boardingAppointment: BoardingAppointment =
          normalizeBoardingAppointment(data);
        const successMessage = "BoardingAppointment found!";
        return successResult({
          message: successMessage,
          payload: boardingAppointment,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("BOARDING_APPOINTMENT_NOT_FOUND")
      const errorMessage = "BOARDING_APPOINTMENT_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetClientBoardingAppointments {
  ({
    db,
    organizationId,
    clientId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    clientId: string;
    patientId?: string;
  }): Promise<Result<BoardingAppointment[] | null>>;
}

const getClientBoardingAppointments: GetClientBoardingAppointments = async ({
  db,
  clientId,
  patientId,
  organizationId,
}) => {
  try {
    let newQuery = query(
      collection(db, "boarding_appointments"),
      where("clientId", "==", clientId),
      where("organizationId", "==", organizationId)
    );

    if (patientId) {
      newQuery = query(newQuery, where("patientId", "==", patientId));
    }

    newQuery = query(newQuery, orderBy("time", "desc"));

    const boardingAppointmentsDocSnapshots = await getDocs(newQuery);

    const objs: BoardingAppointment[] = [];
    boardingAppointmentsDocSnapshots.forEach((doc) => {
      try {
        objs.push(normalizeBoardingAppointment(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });

    // t("BOARDING_APPOINTMENT_NOT_FOUND")
    const successMessage = "BOARDING_APPOINTMENT_NOT_FOUND";
    return successResult({
      message: successMessage,
      payload: objs,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateBoardingAppointment {
  ({
    db,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<BoardingAppointmentData>;
  }): Promise<Result<boolean | null>>;
}

const updateBoardingAppointment: UpdateBoardingAppointment = async ({
  db,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const boardingAppointmentRef = doc(db, "boarding_appointments", id);

    const updates: Partial<BoardingAppointment> = {
      ...data,
      authorId,
      authorName,
      version: BOARDING_APPOINTMENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(boardingAppointmentRef, updates);

    const successMessage = "BoardingAppointment updated!";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization boardingAppointments listener
interface GetBoardingAppointmentsForWeekListenerCallback {
  (boardingAppointments: BoardingAppointment[]): void;
}
interface GetBoardingAppointmentsListener {
  db: Firestore;
  organizationId: string;
  day: number;
  callback: GetBoardingAppointmentsForWeekListenerCallback;
}
//todo make it per day
const getBoardingAppointmentsForWeekListener = ({
  db,
  organizationId,
  day,
  callback,
}: GetBoardingAppointmentsListener): Unsubscribe => {
  try {
    const week = tu.getYearWeekString(day);
    // console.log("getBoardingAppointmentsListener: new listener");

    // console.log(day + 24 * 60 * 60 * 1000);

    const boardingAppointmentsQuery = query(
      collection(db, "boarding_appointments"),
      where("organizationId", "==", organizationId),
      where("yearWeeksCovered", "array-contains", week),
      orderBy("from", "asc")
    );
    return onSnapshot(boardingAppointmentsQuery, (querySnapshot) => {
      const boardingAppointments: BoardingAppointment[] = [];
      querySnapshot.forEach((doc) => {
        boardingAppointments.push(normalizeBoardingAppointment(doc.data()));
      });
      callback(boardingAppointments);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// get client upcoming boardingAppointments listener
interface GetClientUpcomingBoardingAppointmentsListenerCallback {
  (boardingAppointments: BoardingAppointment[]): void;
}
interface GetClientUpcomingBoardingAppointmentsListener {
  db: Firestore;
  organizationId: string;
  clientId: string;
  patientId?: string;
  callback: GetClientUpcomingBoardingAppointmentsListenerCallback;
}
//todo make it per day
const getClientUpcomingBoardingAppointmentsListener = ({
  db,
  organizationId,
  clientId,
  patientId,
  callback,
}: GetClientUpcomingBoardingAppointmentsListener): Unsubscribe => {
  try {
    let boardingAppointmentsQuery = query(
      collection(db, "boarding_appointments"),
      where("organizationId", "==", organizationId),
      where("clientId", "==", clientId)
    );

    if (patientId) {
      boardingAppointmentsQuery = query(
        boardingAppointmentsQuery,
        where("patientId", "==", patientId)
      );
    }
    boardingAppointmentsQuery = query(
      boardingAppointmentsQuery,
      where("time", ">=", tu.getCurrentDateTime()),
      orderBy("time", "asc")
    );
    return onSnapshot(boardingAppointmentsQuery, (querySnapshot) => {
      const boardingAppointments: BoardingAppointment[] = [];
      querySnapshot.forEach((doc) => {
        try {
          boardingAppointments.push(normalizeBoardingAppointment(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(boardingAppointments);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const boardingAppointments = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getBoardingAppointmentsForWeekListener: ({
      day,
      callback,
    }: {
      day: number;
      callback: GetBoardingAppointmentsForWeekListenerCallback;
    }) =>
      getBoardingAppointmentsForWeekListener({
        db,
        organizationId,
        day,
        callback,
      }),
    getBoardingAppointment: (id: string) =>
      getBoardingAppointment({
        db,
        id,
      }),
    getClientUpcomingBoardingAppointmentsListener: ({
      clientId,
      patientId,
      callback,
    }: {
      clientId: string;
      patientId?: string;
      callback: GetClientUpcomingBoardingAppointmentsListenerCallback;
    }) =>
      getClientUpcomingBoardingAppointmentsListener({
        db,
        clientId,
        patientId,
        organizationId,
        callback,
      }),
    getClientBoardingAppointments: ({
      clientId,
      patientId,
    }: {
      clientId: string;
      patientId?: string;
    }) =>
      getClientBoardingAppointments({
        db,
        clientId,
        organizationId,
        patientId,
      }),
    addBoardingAppointment: ({
      data,
      id,
    }: {
      data: BoardingAppointmentData;
      id?: string;
    }) =>
      addBoardingAppointment({
        db,
        authorId,
        authorName,
        source,
        data,
        id,
      }),
    editBoardingAppointment: (
      id: string,
      data: Partial<BoardingAppointmentData>
    ) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    checkInBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CHECKED_IN",
        },
      }),
    checkOutBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CHECKED_OUT",
        },
      }),
    cancelBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CANCELED",
        },
      }),
    noShowBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "NO_SHOW",
        },
      }),
    rescheduleBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "BOOKED",
        },
      }),
    undoCheckinBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "BOOKED",
        },
      }),
    undoNoShowBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "BOOKED",
        },
      }),
    undoCheckoutBoardingAppointment: (id: string) =>
      updateBoardingAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CHECKED_IN",
        },
      }),
  };
};
