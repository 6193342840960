import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Task } from "beitary-shared";

interface TasksState {
  currentDayTasks: Task[];
}

const initialState: TasksState = {
  currentDayTasks: [],
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setCurrentDayTasks: (state, action: PayloadAction<Task[]>) => {
      state.currentDayTasks = action.payload;
    },
  },
});

export const { setCurrentDayTasks } = tasksSlice.actions;

export const selectCurrentDayTasks = (state: RootState) =>
  state.tasks.currentDayTasks;

export const tasksReducer = tasksSlice.reducer;
