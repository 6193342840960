import {
  Box,
  Collapse,
  Group,
  Input,
  Stack,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  Diagnosis,
  getNotificationByResultType,
  loadingInfoNotification,
  Result,
} from "beitary-shared";
import { BBox, BTextEditor, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { DiagnosisFormValues, rules } from "./DiagnosisForm.rules";

export interface DiagnosisFormProps {
  diagnosis?: Diagnosis;
}

export const DiagnosisForm = ({ diagnosis }: DiagnosisFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editDiagnosis, addDiagnosis } = useDBServices().diagnosesDBService;

  const form = useForm<DiagnosisFormValues>({
    initialValues: diagnosis ? { ...diagnosis } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: DiagnosisFormValues) => {
    const notificationId = "submit-diagnosis";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: diagnosis ? t("UPDATE_DIAGNOSIS") : t("ADD_DIAGNOSIS"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<Diagnosis | null>;
    if (diagnosis) {
      result = await editDiagnosis(diagnosis.id, values);
    } else {
      result = await addDiagnosis(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            diagnosis ? (
              <Text weight={500}>{t("EDIT_DIAGNOSIS")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_DIAGNOSIS")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />
            <TextInput
              placeholder={t("DIAGNOSIS_CODE_PLACEHOLDER")}
              label={t("CODE")}
              {...form.getInputProps("code")}
            />
          </Stack>
        </BBox>
        {/* discharge instructions */}
        <BBox
          header={
            <Group position="apart">
              <Text weight={500}>{t("DISCHARGE_INSTRUCTIONS")}</Text>
              <Switch
                {...form.getInputProps("hasDischInstr", { type: "checkbox" })}
              />
            </Group>
          }
        >
          <Collapse in={form.values.hasDischInstr ?? false} p="xl">
            <Stack>
              <Input.Wrapper>
                <Group align="flex-start" position="apart">
                  <Box maw="100%">
                    <Input.Wrapper required={form.values.hasDischInstr}>
                      <BTextEditor
                        {...form.getInputProps("dischargeInstructions")}
                      />
                    </Input.Wrapper>
                  </Box>
                  {/* 
                  // TODO do u rewally want to do this?
                  <Divider orientation="vertical" />
                  <Box maw="30%">
                    <Text mb="xl" size="sm">
                      {t("DIAGNOSIS_DISCHARGE_INSTRUCTIONS_EXPLAINER")}
                    </Text>
                    <List size="sm" withPadding>
                      <List.Item>
                        <Code color="cyan">{"{PATIENT_NAME}"}</Code> :{" "}
                        {t("PATIENT_NAME")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{CLIENT_NAME}"}</Code> :{" "}
                        {t("CLIENT_NAME")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{DOCTOR}"}</Code> :{" "}
                        {t("ASSIGNED_DOCTOR")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{MEMBERS}"}</Code> :{" "}
                        {t("ASSIGNED_MEMBERS")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{REASON_FOR_ENCOUNTER}"}</Code> :{" "}
                        {t("REASON_FOR_ENCOUNTER")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{EMAIL}"}</Code> :{" "}
                        {t("CLINIC_EMAIL")}
                      </List.Item>
                      <List.Item>
                        <Code color="cyan">{"{PHONE_NUMBER}"}</Code> :{" "}
                        {t("CLINIC_PHONE_NUMBER")}
                      </List.Item>
                    </List>
                  </Box> */}
                </Group>
              </Input.Wrapper>
            </Stack>
          </Collapse>
        </BBox>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
