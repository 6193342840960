import { Box, Button, Container, Group, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconDownload } from "@tabler/icons-react";
import { bFileCategoriesEnum, errorNotification, tu } from "beitary-shared";
import { BBox, MoveBackButton } from "components";
import { useCServices } from "hooks/useCService/useCService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadFile } from "hooks/useUploadFile";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { utils, write, writeFile } from "xlsx";

export interface InventoryItemsReportProps {}

export const InventoryItemsReport = () => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();

  const [{ submitFile, submitFileState }] = useUploadFile({
    filesCategory: bFileCategoriesEnum.Values.REPORT,
  });

  const [data, setData] = useState<any>([]); // TODO fix

  const query = useCServices().reports.inventoryItemsReport;

  const runQuery = async () => {
    setSubmitState("pending-response");
    const result = await query();

    if (result.type === "success") {
      if (result.payload) setData(result.payload.data);
      setSubmitState("success");
    } else {
      showNotification(
        errorNotification({
          message: t("ERROR_GENERATING_REPORT"),
        })
      );
      setSubmitState("error");
      return;
    }
  };

  const downloadXslx = async () => {
    const summarySheet = utils.json_to_sheet([
      {
        date: tu.getDateAndTimeString(tu.getCurrentDateTime()),
        count: data.length,
      },
    ]);
    const dataSheet = utils.json_to_sheet(data);

    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, summarySheet, t("SUMMARY"));
    utils.book_append_sheet(workBook, dataSheet, t("INVENTORY_ITEMS"));

    const buf = write(workBook, { bookType: "xlsx", type: "buffer" });

    const title = `${t("INVENTORY_ITEMS_REPORT")} ${tu.getYYYYMMDDString(
      tu.getCurrentDateTime()
    )}.xlsx`;

    const file = new File([buf], title, { type: "application/octet-stream" });
    await submitFile(file);

    writeFile(workBook, title);
  };

  return (
    <Container fluid>
      <Stack spacing="xl">
        <Group mt="xl">
          <MoveBackButton />
        </Group>

        <BBox header={<Text weight={500}>{t("INVENTORY_ITEMS_REPORT")}</Text>}>
          <Stack p="xl">
            <Group position="apart" align="flex-end">
              <Group></Group>
              <Group>
                <Button
                  onClick={runQuery}
                  loading={submitState === "pending-response"}
                >
                  {t("GENERATE_REPORT")}
                </Button>
                <Button
                  variant="outline"
                  loading={submitFileState === "pending-response"}
                  leftIcon={<IconDownload size={18} />}
                  disabled={submitState !== "success"}
                  onClick={downloadXslx}
                >
                  {t("DOWNLOAD")}
                </Button>
              </Group>
            </Group>

            <Box mt="md" mb="md">
              {submitState === "success" && (
                <Stack>
                  <Text mt="xl">
                    {data.length} {t("ITEMS_FOUND")}
                  </Text>
                </Stack>
              )}
            </Box>
          </Stack>
        </BBox>
      </Stack>
    </Container>
  );
};
