import { Stack } from "@mantine/core";
import { Consultation } from "beitary-shared";
import { useEffect } from "react";
import { SummaryForm } from "./components";

interface SummaryProps {
  consultation: Consultation;
}

export const Summary = ({ consultation }: SummaryProps) => {
  useEffect(() => {}, [consultation]);

  return (
    <Stack spacing="xl">
      <SummaryForm consultation={consultation} />
      {/* <Box mt="xl"> TODO fix me!!!
        <EstimatesTable
          clientId={consultation.clientId}
          clientName={consultation.clientName}
          consultationId={consultation.id}
          patientId={consultation.patientId}
          patientName={consultation.patientName}
        />
      </Box> */}
    </Stack>
  );
};
