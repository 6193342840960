import { zodResolver } from "@mantine/form";
import { addressSchema, categoryDataSchema } from "beitary-shared";
import { z } from "zod";

const categoryFormSchema = categoryDataSchema;

export type CategoryFormValues = z.infer<typeof categoryFormSchema>;
export type CategoryAddressFormValues = z.infer<typeof addressSchema>;

export const rules = zodResolver(categoryFormSchema);
export const addressRules = zodResolver(addressSchema);
