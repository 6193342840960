import { Stack, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BMultiSelect } from "components";
import {
  selectActiveProducts,
  selectProductCategories,
} from "features/admin/catalog/Catalog.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ProductMultiSelectProps {
  required?: boolean;
  onChange?: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
  label?: string;
  limit?: number;
  maxSelectedValues?: number;
}

// TODO rename this multiseletedct????? whatever
export const ProductMultiSelect = (props: ProductMultiSelectProps) => {
  const { t } = useTranslation();
  const categories = useAppSelector(selectProductCategories);
  const items = useAppSelector(selectActiveProducts);
  const data = items.map((o) => ({
    value: o.id,
    label: o.name,
    code: o.code,
    group: t(categories.find((c) => c.id === o.categoryId)?.name ?? "OTHER"),
  }));

  return (
    <BMultiSelect
      {...props}
      label={props.label ?? t("PRODUCT")}
      itemComponent={SelectItem}
      filter={(value, selected, item) =>
        !selected &&
        (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.code?.toLowerCase().includes(value.toLowerCase().trim()))
      }
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  code?: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ code, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Stack>
        <div>
          <Text>{label}</Text>
          {code && (
            <Text size="xs" color="dimmed">
              {code}
            </Text>
          )}
        </div>
      </Stack>
    </div>
  )
);
