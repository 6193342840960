import { createContextualCan, useAbility } from "@casl/react";
import { useAppSelector } from "app/hooks";
import { BFile } from "beitary-shared";
import {
  selectActiveOrganization,
  selectActiveUser,
} from "features/Authentication/AuthenticationSlice";
import { defineAbilityFor } from "permissions/ability";
import { createContext, ReactNode } from "react";

const AbilityContext = createContext<BFile | any>(null);

export const AbilityProvider = ({ children }: { children: ReactNode }) => {
  const user = useAppSelector(selectActiveUser);
  const orgId = useAppSelector(selectActiveOrganization);
  const ability = defineAbilityFor({ user, orgId });

  console.log(ability);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export const useAbilityContext = () => {
  const ability = useAbility(AbilityContext);
  if (!ability) {
    throw new Error(
      "Ability is not available. Make sure you wrap your app with AbilityProvider."
    );
  }
  return ability;
};

export const BCan = createContextualCan(AbilityContext.Consumer);
