import { z } from "zod";

export const stateSchema = z.object({
  name: z.string(),
  countryCode: z.string(),
  isoCode: z.string(),
  latitude: z.string().nullable().optional(),
  longitude: z.string().nullable().optional(),
});

export type State = z.infer<typeof stateSchema>;
