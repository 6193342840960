import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const Products = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("products/categories")
    ? "categories"
    : "products";

  return (
    <Tabs
      mt="xl"
      value={activeTab}
      onTabChange={(value) => {
        navigate(`${value}`);
      }}
      variant="pills"
      radius="xs"
    >
      <Tabs.List mb="xl">
        <Tabs.Tab value="products">{t("PRODUCTS")}</Tabs.Tab>
        <Tabs.Tab value="categories">{t("CATEGORIES")}</Tabs.Tab>
      </Tabs.List>
      <Outlet />
    </Tabs>
  );
};
