//import { useTranslation } from "react-i18next";

import { TasksTable } from "features/tasks/components";

export interface TasksProps {
  clientId: string;
  clientName: string;
}

export const Tasks = ({ clientId, clientName }: TasksProps) => {
  //const { t } = useTranslation();

  return <TasksTable clientId={clientId} clientName={clientName} />;
};
