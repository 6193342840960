import { Transactions } from "features/admin/inventory/components";
import { TransactionForm } from "features/admin/inventory/components/Transactions/components";
import { Outlet, Route } from "react-router-dom";

export const TransactionsRoutes = (
  <Route path="transactions" element={<Outlet />}>
    <Route index element={<Transactions />} />
    <Route path="new" element={<TransactionForm />} />
  </Route>
);
