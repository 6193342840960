import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
/**
 * schema and types versions
 * from first to last
 */

// v0

// TODO
const orgAdvancedSettingsDataV0Schema = z.object({
  // app settings
  TODO: z.string(),

  //
});

type OrgAdvancedSettingsDataV0 = z.infer<
  typeof orgAdvancedSettingsDataV0Schema
>;

const orgAdvancedSettingsV0Schema = baseRecordSchema.merge(
  orgAdvancedSettingsDataV0Schema
);

type OrgAdvancedSettingsV0 = z.infer<typeof orgAdvancedSettingsV0Schema>;

const normalizeOrgAdvancedSettingsV0 = (data: any): OrgAdvancedSettings => {
  try {
    const orgAdvancedSettingsV0: OrgAdvancedSettingsV0 =
      orgAdvancedSettingsV0Schema.parse(data);

    const { ...rest } = orgAdvancedSettingsV0;

    // here it's same object cause current v is 0
    const orgAdvancedSettings: OrgAdvancedSettings = {
      ...rest,
    };
    return orgAdvancedSettings;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const ORG_ADVANCED_SETTINGS_VERSION = "0";

export type OrgAdvancedSettingsData = OrgAdvancedSettingsDataV0;

export type OrgAdvancedSettings = BaseRecord & OrgAdvancedSettingsData;

export const orgAdvancedSettingsDataSchema = orgAdvancedSettingsDataV0Schema;

export const orgAdvancedSettingsSchema = orgAdvancedSettingsV0Schema;

export const normalizeOrgAdvancedSettings = (
  data: any
): OrgAdvancedSettings => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeOrgAdvancedSettingsV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
