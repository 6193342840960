export type ResultType = "error" | "success";

export interface Result<T> {
  type: ResultType;
  message: string;
  payload: T;
}

export interface iErrorProps {
  message: string;
}

export const errorResult = ({ message }: iErrorProps): Result<null> => {
  return {
    type: "error",
    message: message,
    payload: null,
  };
};
export interface iSuccessProps<T> {
  message: string;
  payload: T;
}

export const successResult = <T>({
  message,
  payload,
}: iSuccessProps<T>): Result<T> => {
  return {
    type: "success",
    message: message,
    payload: payload,
  };
};
