import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  ClinicLocation,
  Currency,
  Language,
  lsu,
  Organization,
  TimeZone,
  tu,
} from "beitary-shared";
interface ClinicSettingsState {
  appSettings: {
    timezone: TimeZone;
    temperatureUnit: "C" | "F";
    language: Language;
    currency: Currency;
  };
  locations: ClinicLocation[];
}

const KEY_APP_SETTINGS = "btr_appSettings";

const initialState: ClinicSettingsState = {
  appSettings: lsu.getItemFromLocalStorage(KEY_APP_SETTINGS) ?? {
    currency: "TND",
    language: "FR",
    timezone: "Africa/Tunis",
    temperatureUnit: "C",
  },
  locations: [],
};

export const clinicSettingsSlice = createSlice({
  name: "clinicSettings",
  initialState,
  reducers: {
    setClinicAppSettings: (
      state,
      action: PayloadAction<Organization | null>
    ) => {
      const payload = action.payload;
      if (payload) {
        const settings: {
          timezone: TimeZone;
          temperatureUnit: "C" | "F";
          language: Language;
          currency: Currency;
          countryCode: string;
        } = {
          currency: payload.currency,
          language: payload.language,
          timezone: payload.timeZone,
          countryCode: payload.address_countryCode,
          temperatureUnit: payload.temperatureUnit,
        };

        state.appSettings = settings;
        lsu.saveItemToLocalStorage(settings, KEY_APP_SETTINGS);

        tu.applyTimeConfig(payload.timeZone, payload.language);
      }
    },
    setClinicLocations: (state, action: PayloadAction<ClinicLocation[]>) => {
      state.locations = action.payload;
    },
  },
});

export const { setClinicAppSettings, setClinicLocations } =
  clinicSettingsSlice.actions;

export const selectClinicAppSettings = (state: RootState) =>
  state.clinicSettings.appSettings;

export const selectClinicLocations = (state: RootState) =>
  state.clinicSettings.locations;

export const selectActiveClinicLocations = (state: RootState) =>
  state.clinicSettings.locations.filter((l) => l.status === "ACTIVE");

export const clinicSettingsReducer = clinicSettingsSlice.reducer;
