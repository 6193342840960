import { Container } from "@mantine/core";
import { PurchaseOrdersTable } from "./components";

export const PurchaseOrders = () => {
  return (
    <Container fluid>
      <PurchaseOrdersTable />
    </Container>
  );
};
