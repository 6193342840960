import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Invoice, InvoiceLineItem, InvoicePayment } from "beitary-shared";

interface InvoicesState {
  activeInvoice: Invoice | null;
  activeInvoiceLineItems: InvoiceLineItem[];
  activeInvoicePayments: InvoicePayment[];
}

const initialState: InvoicesState = {
  activeInvoice: null,
  activeInvoiceLineItems: [],
  activeInvoicePayments: [],
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setActiveInvoice: (state, action: PayloadAction<Invoice | null>) => {
      state.activeInvoice = action.payload;
    },
    setActiveInvoiceLineItems: (
      state,
      action: PayloadAction<InvoiceLineItem[]>
    ) => {
      state.activeInvoiceLineItems = action.payload;
    },
    setActiveInvoicePayments: (
      state,
      action: PayloadAction<InvoicePayment[]>
    ) => {
      state.activeInvoicePayments = action.payload;
    },
  },
});

export const {
  setActiveInvoiceLineItems,
  setActiveInvoice,
  setActiveInvoicePayments,
} = invoicesSlice.actions;

export const selectActiveInvoice = (state: RootState) =>
  state.invoices.activeInvoice;

export const selectActiveInvoiceCharges = (state: RootState) =>
  state.invoices.activeInvoiceLineItems;

export const selectActiveInvoicePayments = (state: RootState) =>
  state.invoices.activeInvoicePayments;

export const invoicesReducer = invoicesSlice.reducer;
