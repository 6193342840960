import { Button, Modal } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PurchaseOrderForm } from "../PurchaseOrderForm";

interface AddPurchaseOrderModalProps {
  callback?: () => void;
}

export const AddPurchaseOrderModal = ({
  callback,
}: AddPurchaseOrderModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        title={t("ADD_NEW")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <PurchaseOrderForm callback={callback} />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("ADD_NEW")}</Button>
    </>
  );
};
