import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { InventoryItemSupplier } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectInventoryItemSuppliers } from "features/admin/inventory/Inventory.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type SuppliersTableItem = InventoryItemSupplier & {
  address: string;
  representativeContact: string;
  actions?: undefined;
};

export const SuppliersTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const suppliers = useAppSelector(selectInventoryItemSuppliers);
  const tableItems: SuppliersTableItem[] = suppliers.map((o) => ({
    ...o,
    address: [
      o.address_Line1,
      o.address_Line2,
      o.address_city,
      o.address_stateOrProvince,
      o.address_postalCode,
      o.address_countryCode,
    ]
      .filter((v) => v)
      .join(", "),
    representativeContact: [o.representativeEmail, o.representativePhoneNumber]
      .filter((i) => !!i)
      .join(", "),
  }));

  const {
    deleteInventoryItemSupplier,
    archiveInventoryItemSupplier,
    dearchiveInventoryItemSupplier,
  } = useDBServices().inventoryDBService.inventoryItemSuppliers;

  const columns: BDataTableColumn<SuppliersTableItem>[] = [
    { accessor: "name", title: <Text>{t("NAME")}</Text>, sortable: true },
    {
      accessor: "representativeName",
      title: <Text>{t("REPRESENTATIVE_NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "representativeContact",
      title: <Text>{t("REPRESENTATIVE_CONTACT")}</Text>,
      sortable: true,
    },
    {
      accessor: "address",
      title: <Text>{t("ADDRESS")}</Text>,
      sortable: true,
    },

    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (supplier) => (
        <Group position="right">
          <ActionIcon onClick={() => navigate(`edit/${supplier.id}`)}>
            <IconEdit size={18} />
          </ActionIcon>
          {supplier.status === "ARCHIVED" ? (
            <DearchiveConfirmationModal
              f={async () =>
                dearchiveInventoryItemSupplier(supplier.id, supplier)
              }
              item={supplier}
            />
          ) : (
            <ArchiveConfirmationModal
              f={async () =>
                archiveInventoryItemSupplier(supplier.id, supplier)
              }
              item={supplier}
            />
          )}
          <DeleteConfirmationModal
            f={async () => deleteInventoryItemSupplier(supplier.id)}
            item={supplier}
          />
        </Group>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
