import { Box, Table, Text } from "@mantine/core";
import { InvoiceLineItem, tu } from "beitary-shared";
import { BBox } from "components";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";

export interface ChargesPanelProps {
  charges: InvoiceLineItem[];
  title: string;
}

export const ChargesPanel = ({ charges, title }: ChargesPanelProps) => {
  const { t } = useTranslation();

  const rows = charges.map((element) => {
    const isFromFixedPriceBundle =
      element.bundleId !== undefined && element.bundleId !== element.id;
    const { totalValueWithTaxes } = mu.calculateItemValueAndTaxes({
      qty: element.qty,
      taxRate: element.taxRate,
      totalDiscount: 0,
      unitValue: isFromFixedPriceBundle ? 0 : element.pUnitSellingPrice,
    });

    return (
      <tr key={element.id}>
        <td>{tu.getLocaleDateStringFromEpoch(element.createdAt)}</td>
        <td>{element.pName}</td>
        <td>{element.discount && mu.toD(element.discount)}</td>
        <td>{element.qty}</td>
        <td>{mu.toD(totalValueWithTaxes)}</td>
      </tr>
    );
  });

  return (
    <BBox header={<Text color="cyan">{title}</Text>}>
      <Box p="xl">
        <Table>
          <thead>
            <tr>
              <th>{t("DATE")}</th>
              <th>{t("DESCRIPTION")}</th>
              <th>{t("DISCOUNT")}</th>
              <th>{t("QTY")}</th>
              <th>{t("PRICE")}</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Box>
    </BBox>
  );
};
