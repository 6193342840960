import {
  errorResult,
  InventoryItemSupplier,
  InventoryItemSupplierData,
  INVENTORY_ITEM_SUPPLIER_VERSION,
  normalizeInventoryItemSupplier,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization inventoryItemSupplier

interface AddInventoryItemSupplier {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: InventoryItemSupplierData;
  }): Promise<Result<InventoryItemSupplier | null>>;
}

const addInventoryItemSupplier: AddInventoryItemSupplier = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new inventoryItemSupplier id
    const newInventoryItemSupplierRef = doc(
      collection(
        db,
        "organizations",
        organizationId,
        "inventory_item_suppliers"
      )
    );

    const newInventoryItemSupplier: InventoryItemSupplier =
      normalizeInventoryItemSupplier({
        ...data,
        id: newInventoryItemSupplierRef.id,
        authorId,
        authorName,
        version: INVENTORY_ITEM_SUPPLIER_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

    await setDoc(newInventoryItemSupplierRef, newInventoryItemSupplier);

    // t("INVENTORY_SUPPLIER_CREATED")
    const successMessage = "INVENTORY_SUPPLIER_CREATED";
    return successResult({
      message: successMessage,
      payload: newInventoryItemSupplier,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetInventoryItemSupplier {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<InventoryItemSupplier | null>>;
}

const getInventoryItemSupplier: GetInventoryItemSupplier = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const inventoryItemSupplierDocRef = doc(
      db,
      "organizations",
      organizationId,
      "inventory_item_suppliers",
      id
    );
    const inventoryItemSupplierDocSnapshot = await getDoc(
      inventoryItemSupplierDocRef
    );
    if (inventoryItemSupplierDocSnapshot.exists()) {
      try {
        const data: unknown = inventoryItemSupplierDocSnapshot.data();
        const inventoryItemSupplier: InventoryItemSupplier =
          normalizeInventoryItemSupplier(data);
        // t("INVENTORY_SUPPLIER_FOUND")
        const successMessage = "INVENTORY_SUPPLIER_FOUND";
        return successResult({
          message: successMessage,
          payload: inventoryItemSupplier,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("INVENTORY_SUPPLIER_NOT_FOUND")
      const errorMessage = "INVENTORY_SUPPLIER_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditInventoryItemSupplier {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: InventoryItemSupplierData;
  }): Promise<Result<InventoryItemSupplier | null>>;
}

const editInventoryItemSupplier: EditInventoryItemSupplier = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const inventoryItemSupplier = (
      await getInventoryItemSupplier({ db, organizationId, id })
    ).payload;

    if (!inventoryItemSupplier) {
      // t("INVENTORY_SUPPLIER_NOT_FOUND")
      const errorMessage = "INVENTORY_SUPPLIER_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = inventoryItemSupplier;
      const updatedInventoryItemSupplier: InventoryItemSupplier =
        normalizeInventoryItemSupplier({
          ...data,
          id,
          authorId,
          authorName,
          version: INVENTORY_ITEM_SUPPLIER_VERSION,
          source,
          createdAt,
          lastUpdatedAt: tu.getCurrentDateTime(),
        });

      const inventoryItemSupplierRef = doc(
        db,
        "organizations",
        organizationId,
        "inventory_item_suppliers",
        id
      );

      await setDoc(inventoryItemSupplierRef, updatedInventoryItemSupplier);

      // t("INVENTORY_SUPPLIER_UPDATED")
      const successMessage = "INVENTORY_SUPPLIER_UPDATED";
      // console.log(successMessage, updatedInventoryItemSupplier);
      return successResult({
        message: successMessage,
        payload: updatedInventoryItemSupplier,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteInventoryItemSupplier {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteInventoryItemSupplier: DeleteInventoryItemSupplier = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "inventory_item_suppliers",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("INVENTORY_SUPPLIER_DELETED")
      const successMessage = "INVENTORY_SUPPLIER_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveInventoryItemSupplier: EditInventoryItemSupplier = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: InventoryItemSupplierData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editInventoryItemSupplier({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("INVENTORY_SUPPLIER_ARCHIVED")
    const successMessage = "INVENTORY_SUPPLIER_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveInventoryItemSupplier: EditInventoryItemSupplier = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: InventoryItemSupplierData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editInventoryItemSupplier({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("INVENTORY_SUPPLIER_DEARCHIVED")
    const successMessage = "INVENTORY_SUPPLIER_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization inventoryItemSuppliers listener
interface GetInventoryItemSuppliersListenerCallback {
  (inventoryItemSuppliers: InventoryItemSupplier[]): void;
}
interface GetInventoryItemSuppliersListener {
  db: Firestore;
  organizationId: string;
  callback: GetInventoryItemSuppliersListenerCallback;
}

const getInventoryItemSuppliersListener = ({
  db,
  organizationId,
  callback,
}: GetInventoryItemSuppliersListener): Unsubscribe => {
  try {
    // console.log("getInventoryItemSuppliersListener: new listener");
    const inventoryItemSuppliersQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "inventory_item_suppliers"
      ),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(inventoryItemSuppliersQuery, (querySnapshot) => {
      const inventoryItemSuppliers: InventoryItemSupplier[] = [];
      querySnapshot.forEach((doc) => {
        try {
          inventoryItemSuppliers.push(
            normalizeInventoryItemSupplier(doc.data())
          );
        } catch (err) {
          console.log(err);
        }
      });
      callback(inventoryItemSuppliers);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const inventoryItemSuppliers = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getInventoryItemSuppliersListener: (
      callback: GetInventoryItemSuppliersListenerCallback
    ) =>
      getInventoryItemSuppliersListener({
        db,
        organizationId,
        callback,
      }),
    getInventoryItemSupplier: (id: string) =>
      getInventoryItemSupplier({
        db,
        organizationId,
        id,
      }),
    addInventoryItemSupplier: (data: InventoryItemSupplierData) =>
      addInventoryItemSupplier({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editInventoryItemSupplier: (id: string, data: InventoryItemSupplierData) =>
      editInventoryItemSupplier({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteInventoryItemSupplier: (id: string) =>
      deleteInventoryItemSupplier({
        db,
        organizationId,
        id,
      }),
    archiveInventoryItemSupplier: (
      id: string,
      data: InventoryItemSupplierData
    ) =>
      archiveInventoryItemSupplier({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveInventoryItemSupplier: (
      id: string,
      data: InventoryItemSupplierData
    ) =>
      dearchiveInventoryItemSupplier({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
