import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectBoardingResources } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BoardingResourceForm } from "../BoardingResourceForm";

export const EditBoardingResource = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const boardingResource = useAppSelector(selectBoardingResources)
    .filter((boardingResource) => boardingResource.id === id)
    .at(0);

  if (!boardingResource) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <BoardingResourceForm boardingResource={boardingResource} />;
};
