import { consultationPrognosisEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface PrognosisSelectProps {
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  label?: string;
}

export const PrognosisSelect = (props: PrognosisSelectProps) => {
  const { t } = useTranslation();
  const data = consultationPrognosisEnum.options.map((o) => ({
    value: o,
    label: t(o),
  }));

  return (
    <BSelect
      clearable
      {...props}
      data={data}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
