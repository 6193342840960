import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { genericStatusEnum } from "../../common/status/GenericStatus";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { memberLicenceType } from "./Member.licenceType";

/**
 * schema and types versions
 * from first to last
 */

// v0

const memberDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  email: z.string().optional(),
  isClinicLogin: z.boolean().optional(),
  isOwner: z.boolean().optional(),
  remoteAccess: z.boolean().optional(),
  initials: z.string().max(3).min(1),
  rolesIds: z.array(z.string()), // practice owner is a role
  thumbURL: z.string().url().optional(),
  licenceType: memberLicenceType.default("EMPLOYEE"),
  // phone
  phoneNumber: z.string().optional(),
  phoneNumber_countryCode: z.string().optional(),
});

type MemberDataV0 = z.infer<typeof memberDataV0Schema>;

const memberV0Schema = baseRecordSchema.merge(memberDataV0Schema);

type MemberV0 = z.infer<typeof memberV0Schema>;

const normalizeMemberV0 = (data: any): Member => {
  try {
    const memberV0: MemberV0 = memberV0Schema.parse(data);

    const { ...rest } = memberV0;

    // here it's same object cause current v is 0
    const member: Member = {
      ...rest,
    };
    return member;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const MEMBER_VERSION = "0";

export type MemberData = MemberDataV0;

export type Member = BaseRecord & MemberData;

export const memberDataSchema = memberDataV0Schema;

export const memberSchema = memberV0Schema;

export const normalizeMember = (data: any): Member => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeMemberV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
