import { Group, TextInput } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface SearchInputProps {
  parentSetString: (s: string) => void;
}

export const SearchInput = ({ parentSetString }: SearchInputProps) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 1000);

  useEffect(() => {
    if (debouncedSearchString.length === 0 || debouncedSearchString.length >= 3)
      parentSetString(debouncedSearchString);
  }, [debouncedSearchString, parentSetString]);

  return (
    <Group pl="xl" ml="xl" mr="sm" pt="xs" grow>
      <TextInput
        icon={<IconSearch size={14} stroke={1.5} />}
        value={searchString}
        onChange={(e) => setSearchString(e.currentTarget.value)}
        sx={{ flexGrow: 1 }} // make the input fill the remaining space
        placeholder={t("SEARCH_MEDICAL_HISTORY_PLACEHOLDER")}
      />
    </Group>
  );
};
