import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Group,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import {
  IconChevronDown,
  IconExternalLink,
  IconEye,
} from "@tabler/icons-react";
import { id_util, SConsultation, tu } from "beitary-shared";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface LifecycleConsultationItemProps {
  item: SConsultation;
}

export const LifecycleConsultationItem = ({
  item,
}: LifecycleConsultationItemProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(true);

  return (
    <>
      <Group position="apart">
        <Group>
          <ActionIcon onClick={() => setOpened(!opened)}>
            <IconChevronDown
              style={{
                transform: opened ? "rotate(180deg)" : "none",
                transitionDuration: "200ms",
              }}
            />
          </ActionIcon>
          <Text color="cyan" weight={600} size="md">
            {t("CONSULTATION")}
          </Text>
          <Badge size="lg" sx={{ textTransform: "none" }}>
            {HTMLReactParser(item.title ?? "-")}
          </Badge>
          <Badge
            radius="xs"
            size="lg"
            sx={{ textTransform: "none" }}
            color={
              item.status === "ACTIVE"
                ? "teal"
                : item.status === "IN_REVIEW"
                ? "orange"
                : item.status === "LAB_PENDING"
                ? "lime"
                : "gray"
            }
          >
            {t(item.status)}
          </Badge>
        </Group>
        <Button
          leftIcon={<IconEye size={16} />}
          onClick={() => navigate(`/consultations/${item.id}/summary`)}
          variant="subtle"
        >
          {t("VIEW_CONSULTATION")}
        </Button>
      </Group>
      <Collapse in={opened} transitionDuration={400}>
        <Divider />
        <Box mb="xl" p="xs">
          {/* <Text size="xs">{HTMLReactParser(item.notes ?? "-")}</Text> */}

          <Text size="md" weight="bold" color="dimmed" mt="xl">
            {t("SUBJECTIVE")}
          </Text>
          <Divider variant="dotted" />
          <Text size="xs" weight="bold" mt="xl">
            {t("REASON_FOR_CONSULTATION")}
          </Text>
          <Text size="xs">
            {HTMLReactParser(item.reasonForConsultation ?? "-")}
          </Text>
          <Text size="xs" weight="bold" mt="xl">
            {t("HISTORY")}
          </Text>
          <Text size="xs">{HTMLReactParser(item.history ?? "-")}</Text>
          <Text size="xs" weight="bold" mt="xl">
            {t("CURRENT_MEDICATION_AND_SUPPLEMENTS")}
          </Text>
          <Text size="xs">
            {HTMLReactParser(item.currentMedicationsAndSupplements ?? "-")}
          </Text>

          <Text size="md" weight="bold" color="dimmed" mt="xl">
            {t("OBJECTIVE")}
          </Text>
          <Divider variant="dotted" />
          <Text size="xs" weight="bold" mt="xl">
            {t("VITALS")}
          </Text>
          {item.vitals.map((entry) => (
            <Stack mt="md">
              <div>
                <Text size="xs" color="dimmed" weight={600}>
                  {t("DATE")}
                </Text>
                <Text size="xs" weight={600}>
                  {tu.getDateAndTimeString(entry.lifecycleDate)}
                </Text>
              </div>
              <SimpleGrid cols={4}>
                <div>
                  <Text size="xs" color="dimmed" weight={600}>
                    {t("TEMPERATURE")}
                  </Text>
                  <Text size="xs">
                    {HTMLReactParser(entry.temp?.toString() ?? "-")}
                  </Text>
                </div>
                <div>
                  <Text size="xs" color="dimmed" weight={600}>
                    {t("MUCUS_MEMBRANE_COLOR")}
                  </Text>
                  <Text size="xs">
                    {HTMLReactParser(entry.mucusColor ?? "-")}
                  </Text>
                </div>
                <div>
                  <Text size="xs" color="dimmed" weight={600}>
                    {t("PULSE")}
                  </Text>
                  <Text size="xs">
                    {HTMLReactParser(entry.pulse?.toString() ?? "-")}
                  </Text>
                </div>
                <div>
                  <Text size="xs" color="dimmed" weight={600}>
                    {t("CAPILLARY_REFILL_TIME")}
                  </Text>
                  <Text size="xs">
                    {HTMLReactParser(entry.capRefTime ?? "-")}
                  </Text>
                </div>{" "}
                <div>
                  <Text size="xs" color="dimmed" weight={600}>
                    {t("RESPIRATORY_RATE")}
                  </Text>
                  <Text size="xs">
                    {HTMLReactParser(entry.resp?.toString() ?? "-")}
                  </Text>
                </div>
                <div>
                  <Text size="xs" color="dimmed" weight={600}>
                    {t("MENTATION")}
                  </Text>
                  <Text size="xs">
                    {HTMLReactParser(entry.mentation ?? "-")}
                  </Text>
                </div>
                <div>
                  <Text size="xs" color="dimmed" weight={600}>
                    {t("NOTES")}
                  </Text>
                  {HTMLReactParser(entry.notes ?? "-")}
                </div>
              </SimpleGrid>
            </Stack>
          ))}
          <Text size="xs" weight="bold" mt="xl">
            {t("PHYSICAL_EXAMS")}
          </Text>
          {item.physicalExams.map((entry) => (
            <Stack mt="xl" mb="xl">
              <div>
                <Text size="xs" color="dimmed" weight={600}>
                  {t("DATE")}
                </Text>
                <Text size="xs" weight={600}>
                  {tu.getDateAndTimeString(entry.lifecycleDate)}
                </Text>
              </div>
              <div>
                <Text size="xs" color="dimmed" weight={600}>
                  {t("SUPERVISING_DOCTOR")}
                </Text>
                <Text size="xs" weight={600}>
                  {HTMLReactParser(entry.supervisingDoctorName ?? "-")}
                </Text>
              </div>
              <SimpleGrid cols={3}>
                {entry.fields.map((field) => (
                  <div>
                    <Text size="xs" color="dimmed" weight={600}>
                      {HTMLReactParser(field.name)}
                    </Text>
                    {field.values.map((v, index) => (
                      <Text
                        size="xs"
                        weight={
                          500 - index * 100 > 100 ? 500 - index * 100 : 400
                        }
                      >
                        {HTMLReactParser(v ?? "-")}
                      </Text>
                    ))}
                  </div>
                ))}
              </SimpleGrid>
            </Stack>
          ))}

          <Text size="md" weight="bold" color="dimmed" mt="xl">
            {t("ASSESSMENT")}
          </Text>
          <Divider variant="dotted" />
          <Text size="xs" weight="bold" mt="xl">
            {t("PROBLEMS")}
          </Text>
          <Group>
            {item.problems?.map((p) => (
              <Badge size="lg" sx={{ textTransform: "none" }}>
                {HTMLReactParser(p)}
              </Badge>
            ))}
          </Group>
          <Text size="xs" weight="bold" mt="xl">
            {t("SUSPECTED_DIAGNOSES")}
          </Text>
          <Group>
            {item.suspicions?.map((p) => (
              <Badge size="lg" sx={{ textTransform: "none" }}>
                {HTMLReactParser(p)}
              </Badge>
            ))}
          </Group>
          <Text size="xs" weight="bold" mt="xl">
            {t("DIAGNOSES")}
          </Text>
          <Group>
            {item.diagnoses?.map((p) => (
              <Badge size="lg" sx={{ textTransform: "none" }}>
                {HTMLReactParser(p)}
              </Badge>
            ))}
          </Group>
          {/* TODO <Text size="xs" weight="bold" mt="xl">
    {t("CHRONIC_CONDITIONS")}
  </Text>
  <Group>
    {item.chronicConditions?.map((p) => (
      <Badge size="lg" sx={{ textTransform: "none" }}>
        {HTMLReactParser(p)}
      </Badge>
    ))}
  </Group> */}
          <Text size="xs" weight="bold" mt="xl">
            {t("ASSESSMENT")}
          </Text>
          <Text size="xs">{HTMLReactParser(item.assessment ?? "")}</Text>
          <Text size="xs" weight="bold" mt="xl">
            {t("PROGNOSIS")}
          </Text>
          <Group>
            <Text size="xs">{t(item.prognosisFrom ?? "")}</Text>
            <Text size="xs">{t("TO")}</Text>
            <Text size="xs">{t(item.prognosisTo ?? "")}</Text>
          </Group>
          {/* TODO docs lab imging... notes */}

          <Text size="md" weight="bold" color="dimmed" mt="xl">
            {t("PLAN")}
          </Text>
          <Divider variant="dotted" />
          <Text size="xs" weight="bold" mt="xl">
            {t("RECOMMENDATIONS")}
          </Text>
          <Text size="xs">{HTMLReactParser(item.recommendations ?? "-")}</Text>
          <Text size="xs" weight="bold" mt="xl">
            {t("TREATMENTS_ADMINISTRED")}
          </Text>
          {item.treatments.map((tx) => (
            <div key={tx.id}>
              <Stack mt="md" spacing={2}>
                <Text size="xs" weight={600}>
                  {HTMLReactParser(tx.productName ?? "-")}
                  {` - ${t("QTY")}: ${tx.qty} ${
                    typeof tx.unit === "string" ? `(${t(tx.unit)})` : ""
                  }`}
                </Text>
                <Text size="xs" color="dimmed" weight={600}>
                  {`${t("ADMINISTRED_BY")} ${tx.adminBy ?? ""} ${t(
                    "ON"
                  )} ${tu.getDateAndTimeString(tx.lifecycleDate)}`}
                </Text>
                {tx.medicalRecordNotes && tx.medicalRecordNotes.length > 0 && (
                  <Text size="xs" weight={600} mt="xs">
                    {t("NOTES")}
                  </Text>
                )}
                {tx.medicalRecordNotes?.map((n) => (
                  <div key={n.id}>
                    <Text size="xs">{HTMLReactParser(n.content)}</Text>
                    <Text size="xs" color="dimmed">
                      {`${n.authorName ?? ""} ${t(
                        "ON"
                      )} ${tu.getDateAndTimeString(n.lifecycleDate)}`}
                    </Text>
                  </div>
                ))}
              </Stack>
            </div>
          ))}

          <Text size="md" weight="bold" color="dimmed" mt="xl">
            {t("DISCHARGE_INSTRUCTIONS")}
          </Text>
          <Divider variant="dotted" />
          {item.dischargeInstructions.map((d) => (
            <Stack mt="md" spacing={2}>
              <Text weight="bold">{HTMLReactParser(d.title)}</Text>
              <Text size="xs">{HTMLReactParser(d.content)}</Text>
              <Text size="xs" color="dimmed">
                {`${d.authorName} ${t("ON")} ${tu.getDateAndTimeString(
                  d.lifecycleDate
                )}`}
              </Text>
            </Stack>
          ))}

          <Text size="md" weight="bold" color="dimmed" mt="xl">
            {t("PRESCRIPTIONS")}
          </Text>
          <Divider variant="dotted" />
          {item.prescriptions.map((p) => (
            <Stack mt="md" spacing={2} key={p.id}>
              <Text size="xs" color="dimmed" weight={600}>
                {`${p.prescribingDrName} ${t("ON")} ${tu.getDateAndTimeString(
                  p.lifecycleDate
                )}`}
              </Text>
              {p.items.map((pitem) => (
                <div key={id_util.newId6()}>
                  <Group>
                    <Text size="xs" weight="bold" color="cyan">
                      {HTMLReactParser(pitem.name)}
                    </Text>
                    <Text size="xs">{`${t("QUANTITY_DISPENSED")} : ${
                      pitem.qty
                    }`}</Text>
                    <Text size="xs">{`${t("VALID_UNTIL")} : ${
                      pitem.validUntil
                        ? tu.getDateString(pitem.validUntil)
                        : "-"
                    }`}</Text>
                    <Text size="xs">{`${t("N_OF_REFILL")} : ${
                      pitem.nOfRefills ?? "-"
                    }`}</Text>
                  </Group>
                  <Group spacing={4}>
                    <Text size="xs">{`${t("Directions")} : ${
                      pitem.directions ? "" : "-"
                    }`}</Text>
                    {pitem.directions && (
                      <Box
                        style={{ fontSize: theme.fontSizes.xs, margin: 0 }}
                        sx={(_theme) => ({
                          fontSize: _theme.fontSizes.xs,
                          p: {
                            margin: 0 /* Remove margin from <p> elements */,
                          },
                        })}
                      >
                        {HTMLReactParser(pitem.directions)}
                      </Box>
                    )}
                  </Group>
                </div>
              ))}
            </Stack>
          ))}

          <Text size="md" weight="bold" color="dimmed" mt="xl">
            {t("FILES")}
          </Text>
          <Divider variant="dotted" />

          {item.files.map((file) => (
            <>
              <Group mt="xs">
                <Text size="xs" weight={600}>
                  {tu.getDateAndTimeString(file.lifecycleDate)}
                </Text>
                <Badge size="sm" sx={{ textTransform: "none" }}>
                  {t(file.fileCategory)}
                </Badge>
              </Group>
              <Group key={file.id}>
                <Box w={60}>
                  {file.thumbURL && <Image radius="md" src={file.thumbURL} />}
                </Box>
                <Text size="xs" weight="bold" color="cyan">
                  {HTMLReactParser(file.originalName)}
                </Text>
                <Text size="xs">{`${t("SIZE")} : ${(
                  Number(file.fileSize) /
                  (1024 * 1024)
                ).toFixed(2)} mb`}</Text>

                <Anchor
                  color="dark"
                  weight={500}
                  href={file.dlURL}
                  target="_blank"
                >
                  {<IconExternalLink size={10} />}
                </Anchor>
              </Group>{" "}
              {file.comment && (
                <Text size="xs" mt="xs">
                  {t("COMMENT")}:{" "}
                  <Text>{HTMLReactParser(file.comment ?? "")}</Text>
                </Text>
              )}
            </>
          ))}
        </Box>
      </Collapse>
    </>
  );
};
