import { Invoices } from "features/invoices";
import { Invoice } from "features/invoices/components/Invoice";
import { Payment } from "features/invoices/components/Payment";
import { Outlet, Route } from "react-router-dom";

export const InvoicesRoutes = (
  <Route path="invoices" element={<Outlet />}>
    <Route index element={<Invoices />} />
    <Route path="edit/:id" element={<Invoice />} />
    <Route path="payments/new/:clientId" element={<Payment />} />
    <Route path="refunds/new/:clientId" element={<Payment isRefund />} />
  </Route>
);
