import {
  ActionIcon,
  Button,
  Group,
  List,
  Modal,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconArchive } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  loadingInfoNotification,
} from "beitary-shared";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface ArchiveConfirmationModalProps<T> {
  f: Function;
  item: T;
  button?: boolean;
}

export const ArchiveConfirmationModal = <
  T extends { id: string; name: string }
>({
  f,
  item,
  button,
}: ArchiveConfirmationModalProps<T>) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const [opened, setOpened] = useState(false);

  const archiveItem = async () => {
    const notificationId = `archive-item-${item.id}`;
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("WAITING_FOR_SERVER_RESPONSE"),
        title: t("ARCHIVE_ITEM"),
      })
    );
    setSubmitState("pending-response");
    const result = await f(item.id, item);
    setSubmitState(result.type);

    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const confirm = () => {
    archiveItem();
    setOpened(false);
  };

  return (
    <>
      <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack pb="xl" align="center" spacing={0}>
          <ThemeIcon size={140} color="cyan.4" radius={70} variant="light">
            <IconArchive size={100} />
          </ThemeIcon>
          <Text weight="bold" mt="xl">
            {t("YOU_ARE_ABOUT_TO_ARCHIVE_AN_ITEM")}
          </Text>
          <List>
            <List.Item>{item.name}</List.Item>
          </List>
          <Text mt="xl">
            {t(
              "ARCHIVED_ITEMS_ARE_NOT_DELETED_BUT_THEY_WILL_NOT_SHOW_UP_TO_USERS"
            )}
          </Text>
        </Stack>
        <Group mt="xl" position="right">
          <Button onClick={confirm}>{t("ARCHIVE")}</Button>
          <Button variant="outline" onClick={() => setOpened(false)}>
            {t("CANCEL")}
          </Button>
        </Group>
      </Modal>
      {button ? (
        <Button
          onClick={() => setOpened(true)}
          loading={submitState === "pending-response"}
          leftIcon={<IconArchive size={18} />}
          variant="outline"
        >
          {t("ARCHIVE")}
        </Button>
      ) : (
        <ActionIcon
          onClick={() => setOpened(true)}
          loading={submitState === "pending-response"}
        >
          <IconArchive size={18} />
        </ActionIcon>
      )}
    </>
  );
};
