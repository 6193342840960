import { Group, Input, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  Result,
  ServiceProvider,
} from "beitary-shared";
import { BBox, BColorPicker, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { DoctorSelect } from "features/Members/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { AppointmentTypeMultiselect } from "../AppointmentTypeMultiselect";
import { MembersMultiselect } from "../MembersMultiselect";
import { rules, ServiceProviderFormValues } from "./ServiceProviderForm.rules";

export interface ServiceProviderFormProps {
  serviceProvider?: ServiceProvider;
}

export const ServiceProviderForm = ({
  serviceProvider,
}: ServiceProviderFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { editServiceProvider, addServiceProvider } =
    useDBServices().scheduleSettingsDBService.serviceProviders;

  const form = useForm<ServiceProviderFormValues>({
    initialValues: serviceProvider ? { ...serviceProvider } : undefined,
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "ACTIVE");

  const submit = async (values: ServiceProviderFormValues) => {
    const notificationId = "submit-inventory-item-serviceProvider";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(
          serviceProvider ? "UPDATE_SERVICE_PROVIDER" : "ADD_SERVICE_PROVIDER"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<ServiceProvider | null>;
    if (serviceProvider) {
      result = await editServiceProvider(serviceProvider.id, values);
    } else {
      result = await addServiceProvider(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            serviceProvider ? (
              <Text weight={500}>{t("EDIT_SERVICE_PROVIDER")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_SERVICE_PROVIDER")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              minLength={1}
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />
            <TextInput
              maxLength={3}
              required
              label={t("CODE")}
              {...form.getInputProps("code")}
            />
            <Input.Wrapper required label={t("COLOR")}>
              <BColorPicker
                setColor={(color) => form.setFieldValue("color", color)}
                defaultColor={form.values.color}
              />
            </Input.Wrapper>
            <DoctorSelect
              {...form.getInputProps("supervisingDoctorId")}
              onChange={({ id, name }) => {
                form.setFieldValue("supervisingDoctorId", id);
                form.setFieldValue("supervisingDoctorName", name);
              }}
            />
            <AppointmentTypeMultiselect
              required
              {...form.getInputProps("servicesIds")}
              onChange={(v) => {
                if (v) form.setFieldValue("servicesIds", v);
              }}
            />

            <MembersMultiselect
              {...form.getInputProps("membersIds")}
              onChange={(v) => {
                if (v) form.setFieldValue("membersIds", v);
              }}
            />
          </Stack>
        </BBox>

        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
