import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Collapse,
  Group,
  ScrollArea,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Appointment, tu } from "beitary-shared";
import { BLoader } from "components";
import {
  selectActiveAppointmentTypes,
  selectActiveServiceProviders,
} from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type AppointmentsTableItem = Appointment & {
  patientPatientName: string;
  date: string;
  aptTypeColor: string;
  servProvColor: string;
};

export interface PatientUpcomingAppointmentsProps {
  clientId: string;
  patientId: string;
}

export const PatientUpcomingAppointments = ({
  clientId,
  patientId,
}: PatientUpcomingAppointmentsProps) => {
  const { t } = useTranslation();
  const appointmentTypes = useAppSelector(selectActiveAppointmentTypes);
  const serviceProviders = useAppSelector(selectActiveServiceProviders);
  const [opened, setOpened] = useState(true);
  const theme = useMantineTheme();
  const db = useDBServices().appointmentsDBService;

  const [appointments, setAppointments] = useState<
    undefined | null | Appointment[]
  >(undefined);

  useEffect(() => {
    const getDiagnosesListenerUnsubscribe =
      db.getClientUpcomingAppointmentsListener({
        clientId,
        patientId,
        callback: setAppointments,
      });

    return () => {
      getDiagnosesListenerUnsubscribe();
    };
  }, [patientId, clientId, db]);

  const tableAppointments: AppointmentsTableItem[] = (appointments ?? []).map(
    (appointment) => ({
      ...appointment,
      patientPatientName: appointment.patientName + appointment.patientName,
      date: `${tu.getDateAndTimeString(
        appointment.time
      )} - ${tu.getDateAndTimeString(
        appointment.time + appointment.duration * 60 * 1000
      )}`,
      aptTypeColor:
        appointmentTypes.find((t) => t.id === appointment.appointmentTypeId)
          ?.color ?? "white",
      servProvColor:
        serviceProviders.find((p) => p.id === appointment.serviceProviderId)
          ?.color ?? "white",
    })
  );

  const getRow = (appointment: AppointmentsTableItem) => {
    return (
      <tr key={appointment.id}>
        <td>
          <Group
            sx={{
              borderLeft: `6px solid ${appointment.aptTypeColor}`,
              borderRadius: 4,
            }}
          >
            <Stack ml="xs" align="left" spacing={0}>
              <Text size="xs" weight={500}>{`${tu.getDateString(
                appointment.time
              )}`}</Text>
              <Group>
                <Text size="xs" color="dimmed">
                  {`${tu.getHoursAndMinutesString(appointment.time)}`} -{" "}
                  {`${tu.getHoursAndMinutesString(
                    appointment.time + appointment.duration * 60 * 1000
                  )}`}
                </Text>
              </Group>
            </Stack>
          </Group>
        </td>

        <td>
          <Badge c={appointment.servProvColor}>
            {appointment.serviceProviderName}
          </Badge>
        </td>
        <td>
          <Text size="xs">{appointment.reasonForConsultation}</Text>
        </td>
      </tr>
    );
  };

  const rows = tableAppointments.map((a) => getRow(a));

  return (
    <Card sx={{ border: `1px solid ${theme.colors.gray[3]}` }}>
      <Group>
        <ActionIcon onClick={() => setOpened(!opened)}>
          <IconChevronDown
            style={{
              transform: opened ? "rotate(180deg)" : "none",
              transitionDuration: "200ms",
            }}
          />
        </ActionIcon>
        <Text weight={600} size="sm">
          {t("UPCOMING_APPOINTMENTS")}
        </Text>
      </Group>

      <Collapse in={opened} transitionDuration={400}>
        <Box p="xl">
          {appointments === undefined && <BLoader size={16} />}
          {appointments === null && <Text>{t("ERROR")}</Text>}
          {appointments?.length === 0 && (
            <Text>{t("NO_UPCOMING_APPOINTMENTS")}</Text>
          )}

          {appointments?.length !== 0 && (
            <ScrollArea>
              <Table
                sx={{
                  border: "none",
                  "& thead tr th": {
                    border: "none",
                  },
                }}
              >
                <tbody>{rows}</tbody>
              </Table>
            </ScrollArea>
          )}
        </Box>
      </Collapse>
    </Card>
  );
};
