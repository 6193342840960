import { zodResolver } from "@mantine/form";
import { diagnosisDataSchema } from "beitary-shared";
import { z } from "zod";

const diagnosisFormSchema = diagnosisDataSchema.refine(
  (data) => (data.hasDischInstr ? !!data.dischargeInstructions : true),
  {
    message: "DISCHARGE_INSTRUCTIONS_REQUIRED",
  }
);

export type DiagnosisFormValues = z.infer<typeof diagnosisFormSchema>;

export const rules = zodResolver(diagnosisFormSchema);
