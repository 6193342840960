import { ActionIcon, Badge, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import {
  EmailReminderNotificationTemplate,
  OrgPermissions,
} from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import {
  selectEmailReminderNotificationTemplates,
  selectReminders,
} from "features/admin/client-communication/ClientCommunication.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type EmailTemplatesTableItem = EmailReminderNotificationTemplate & {
  reminders: string;
  actions?: undefined;
};

export const EmailTemplatesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const emailTemplates = useAppSelector(
    selectEmailReminderNotificationTemplates
  );
  const reminders = useAppSelector(selectReminders);

  const tableItems: EmailTemplatesTableItem[] = emailTemplates.map(
    (emailTemplate) => ({
      ...emailTemplate,
      reminders: reminders
        .filter((at) => emailTemplate.remindersIds.includes(at.id))
        .map((at) => at.name)
        .join("[-]"),
    })
  );

  const {
    deleteEmailReminderNotificationTemplate,
    archiveEmailReminderNotificationTemplate,
    dearchiveEmailReminderNotificationTemplate,
  } = useDBServices().clientCommunication.emailReminderNotificationTemplates;

  const columns: BDataTableColumn<EmailTemplatesTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "reminders",
      title: <Text>{t("REMINDERS")}</Text>,
      sortable: true,
      render: ({ reminders }) => (
        <Group>
          {reminders.split("[-]").map((a) => (
            <Badge>{a}</Badge>
          ))}
        </Group>
      ),
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (emailTemplate) => (
        <BCan I="do" a={OrgPermissions.CLIENT_COMMUNICATION}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${emailTemplate.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {emailTemplate.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () =>
                  dearchiveEmailReminderNotificationTemplate(emailTemplate.id)
                }
                item={emailTemplate}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () =>
                  archiveEmailReminderNotificationTemplate(emailTemplate.id)
                }
                item={emailTemplate}
              />
            )}
            <DeleteConfirmationModal
              f={async () =>
                deleteEmailReminderNotificationTemplate(emailTemplate.id)
              }
              item={emailTemplate}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
