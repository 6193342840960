import { Button, Modal } from "@mantine/core";
import { Patient } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientWeightForm } from "../PatientWeightForm";

export interface AddPatientWeightModalProps {
  consultationId?: string;
  patient: Patient;
}

export const AddPatientWeightModal = (props: AddPatientWeightModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("NEW_PATIENT_WEIGHT")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <PatientWeightForm {...props} />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("ADD_PATIENT_WEIGHT")}</Button>
    </>
  );
};
