import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../baseRecord";
import { UnableToNormalizeData } from "../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const noteDataV0Schema = z.object({
  content: z.string(),
});

type NoteDataV0 = z.infer<typeof noteDataV0Schema>;

const noteV0Schema = baseRecordSchema.merge(noteDataV0Schema);

type NoteV0 = z.infer<typeof noteV0Schema>;

const normalizenoteV0 = (data: any): Note => {
  try {
    const noteV0: NoteV0 = noteV0Schema.parse(data);

    const { ...rest } = noteV0;

    // here it's same object cause current v is 0
    const note: Note = {
      ...rest,
    };
    return note;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const NOTE_VERSION = "0";

export type NoteData = NoteDataV0;

export type Note = BaseRecord & NoteData;

export const noteDataSchema = noteDataV0Schema;

export const noteSchema = noteV0Schema;

export const normalizenote = (data: any): Note => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizenoteV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
