import { ActionIcon, Modal } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { Patient, PatientWeight } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PatientWeightForm } from "../PatientWeightForm";

export interface EditPatientWeightModalProps {
  consultationId?: string;
  patientWeight: PatientWeight;
  patient: Patient;
}

export const EditPatientWeightModal = (props: EditPatientWeightModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("EDIT_PATIENT_WEIGHT")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <PatientWeightForm {...props} />
      </Modal>
      <ActionIcon onClick={() => setOpened(true)}>
        <IconEdit size={18} />
      </ActionIcon>
    </>
  );
};
