import { z } from "zod";
import {
  inventoryItemUnitOfMeasurementEnum,
  inventoryItemUnitTypeEnum,
} from "../../../types-schemas/common";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";

/**
 * schema and types versions
 * from first to last
 */

// v0

const inventoryItemDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string(),
  notes: z.string().optional(),
  supplierId: z.string(),
  locationsIds: z.array(z.string()).optional(),
  code: z.string().optional(),
  supplierItemCode: z.string().optional(),

  minQuantity: z.number().positive().optional(),
  maxQuantity: z.number().positive().optional(),
  orderQuantity: z.number().positive().optional(),
  reorderPoint: z.number().positive().optional(),

  unitOfMeasurement: inventoryItemUnitOfMeasurementEnum.optional(),
  units: z.number().positive().optional(),
  unitType: inventoryItemUnitTypeEnum.optional(),

  costPerUnitOfMeasurement: z.number().int().nonnegative(), // no tax
  taxRate: z.number().nonnegative().default(0.19),
  initialCount: z.number().nonnegative(),
  currentQuantity: z.number(),
});

type InventoryItemDataV0 = z.infer<typeof inventoryItemDataV0Schema>;

const inventoryItemV0Schema = baseRecordSchema.merge(inventoryItemDataV0Schema);

type InventoryItemV0 = z.infer<typeof inventoryItemV0Schema>;

const normalizeInventoryItemV0 = (data: any): InventoryItem => {
  try {
    const inventoryItemV0: InventoryItemV0 = inventoryItemV0Schema.parse(data);

    const { ...rest } = inventoryItemV0;

    // here it's same object cause current v is 0
    const inventoryItem: InventoryItem = {
      ...rest,
    };
    return inventoryItem;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVENTORY_ITEM_VERSION = "0";

export type InventoryItemData = InventoryItemDataV0;

export type InventoryItem = BaseRecord & InventoryItemData;

export const inventoryItemDataSchema = inventoryItemDataV0Schema;

export const inventoryItemSchema = inventoryItemV0Schema;

export const normalizeInventoryItem = (data: any): InventoryItem => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInventoryItemV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
