import { Group } from "@mantine/core";
import { BoardingAppointment, id_util, tu } from "beitary-shared";
import { AppointmentCard } from "../AppointmentCard";

export interface RowProps {
  appointments: BoardingAppointment[];
  // dateTime: number;
  // resourceId: string;
  selectedDate: number;
  color: string;
  index: number;
}

export const Row = ({
  appointments,
  // dateTime,
  selectedDate,
  index,
  color,
}: RowProps) => {
  const calculateLeftPosAndWidth = (appointment: BoardingAppointment) => {
    const start = tu.getStartOfWeek(tu.getDate(selectedDate));
    const aptStart = Math.max(start, appointment.from);
    const aptEnd = Math.min(start + 7 * 24 * 60 * 60 * 1000, appointment.to);
    const aptWidthRatio = (aptEnd - aptStart) / (7 * 24 * 60 * 60 * 1000);
    const aptLeftPostRatio = (aptStart - start) / (7 * 24 * 60 * 60 * 1000);

    return { aptLeftPostRatio, aptWidthRatio };
  };

  const calculateTopPosition = () => {
    const p = 80 + index * 120;

    return p;
  };

  const getAppointmentCard = (appointment: BoardingAppointment) => {
    const r = calculateLeftPosAndWidth(appointment);
    return (
      <AppointmentCard
        key={id_util.newId20()}
        appointment={appointment}
        color={color}
        leftPositionRatio={r.aptLeftPostRatio}
        widthRatio={r.aptWidthRatio}
        topPosition={calculateTopPosition()}
      />
    );
  };

  return (
    <Group
      spacing={0}
      sx={{
        width: `calc(100% - 160px)`,
        height: `${80}px)`,
        position: "absolute",
        left: 160,
      }}
    >
      {appointments.map((appointment) => getAppointmentCard(appointment))}
    </Group>
  );
};
