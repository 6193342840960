import Compressor from "compressorjs";

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

const comp = (file: File, options: Compressor.Options) =>
  new Compressor(file, options);

const lowQ512Comp = (
  file: File,
  success: (file: File) => void,
  error: (error: Error) => void
) =>
  comp(file, {
    width: 256, // reduced size cauz it was cauzing img > 0.5 mb
    height: 256,
    quality: 0.4,
    resize: "contain",
    success,
    error,
  });

export const img_utils = {
  fileToBase64,
  logoCompress: lowQ512Comp,
};
