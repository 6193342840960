import { Group, NumberInput, Stack, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  normalizepatientWeight,
  obju,
  Patient,
  PatientWeight,
  tu,
} from "beitary-shared";
import { BDateAndTimeInput, SaveButton } from "components";
import { selectActiveUser } from "features/Authentication/AuthenticationSlice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { PatientWeightFormValues, rules } from "./PatientWeightForm.rules";

export interface PatientWeightFormProps {
  patient: Patient;
  patientWeight?: PatientWeight;
  consultationId?: string;
}

export const PatientWeightForm = ({
  patientWeight,
  consultationId,
  patient,
}: PatientWeightFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { updatePatient } = useDBServices().patientsDBService;
  const user = useAppSelector(selectActiveUser);

  const getInitialValues = () => {
    if (!patientWeight) return undefined;
    return normalizepatientWeight(patientWeight);
  };

  const form = useForm<PatientWeightFormValues>({
    initialValues: getInitialValues(),
    validate: rules,
  });

  if (!form.values.t)
    form.setValues({
      t: tu.getCurrentDateTime(),
    });

  const submit = async (values: PatientWeightFormValues) => {
    if (!user || !user.displayName) throw new Error(t("NO_ACTIVE_USER"));

    const weightId = patientWeight
      ? patientWeight.id
      : consultationId
      ? consultationId
      : id_util.newId6();
    const newWeight: PatientWeight = {
      ...values,
      id: weightId,
      a: user.displayName,
    };

    obju.removeUndefined(newWeight);

    const newWeights: PatientWeight[] = (patient.weights ?? []).filter(
      (w) => w.id !== weightId
    );
    newWeights.push(newWeight);

    console.log(patientWeight);
    console.log(patient.weights);
    console.log(weightId);
    console.log(newWeight);
    console.log(newWeights);

    const notificationId = "submit-patientWeight";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("ADD_PATIENT_WEIGHT")
        // t("UPDATE_PATIENT_WEIGHT")
        title: t(
          patientWeight ? "UPDATE_PATIENT_WEIGHT" : "ADD_PATIENT_WEIGHT"
        ),
      })
    );
    setSubmitState("pending-response");
    const result = await updatePatient(patient.id, { weights: newWeights });

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack p="xl">
        <NumberInput
          required
          min={0}
          precision={3}
          step={0.1}
          label={t("WEIGHT_KG")}
          {...form.getInputProps("v")}
        />
        <BDateAndTimeInput
          required
          label={t("DATE")}
          maxDateTime={tu.getCurrentDateTime()}
          {...form.getInputProps("t")}
        />
        <Textarea label={t("DESCRIPTION")} {...form.getInputProps("d")} />
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
