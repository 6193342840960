import { z } from "zod";

export const inventoryItemUnitOfMeasurementEnum = z.enum([
  "AMPOULE",
  "BAG",
  "BARREL",
  "BOTTLE",
  "BOX",
  "CAN",
  "CAPSULE",
  "CARTIDGE",
  "CARTON",
  "CASE",
  "CONTAINER",
  "CUP",
  "FILM",
  "FLACON",
  "FLEXIBAG",
  "JAR",
  "JUG",
  "LOZENGE",
  "PACKET",
  "PAIL",
  "PAMPHLET",
  "PATCH",
  "PILL",
  "PIPETTE",
  "POUCH",
  "RING",
  "SACHET",
  "SOFTGEL",
  "SUPPOSITORY",
  "SYRINGE",
  "TABLET",
  "TUBE",
  "UNIT",
  "VIAL",
]);

export type InventoryItemUnitOfMeasurement = z.infer<
  typeof inventoryItemUnitOfMeasurementEnum
>;

// t("AMPOULE")
// t("BAG")
// t("BARREL")
// t("BOTTLE")
// t("BOX")
// t("CAN")
// t("CAPSULE")
// t("CARTIDGE")
// t("CARTON")
// t("CASE")
// t("CONTAINER")
// t("CUP")
// t("FLACON")
// t("FLEXIBAG")
// t("JAR")
// t("JUG")
// t("PACKET")
// t("PAIL")
// t("PAMPHLET")
// t("POUCH")
// t("SACHET")
// t("SYRINGE")
// t("TABLET")
// t("TUBE")
// t("UNIT")
// t("VIAL")
