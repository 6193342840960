import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectMembers } from "features/admin/members/Members.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EditMemberForm } from "../EditMemberForm";

export const EditMember = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const member = useAppSelector(selectMembers)
    .filter((member) => member.id === id)
    .at(0);

  if (!member) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <EditMemberForm member={member} />;
};
