import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";
/**
 * schema and types versions
 * from first to last
 */

// v0

const orgClosureV0Schema = z.object({
  status: genericStatusEnum,
  startDate: z.number().positive(),
  endDate: z.number().positive().optional(),
  multiple: z.boolean().optional(),
  allDay: z.boolean().optional(),
  startHour: z.number().positive().optional(),
  endHour: z.number().positive().optional(),
});

type OrgClosureV0 = z.infer<typeof orgClosureV0Schema>;

const normalizeOrgClosureV0 = (data: any): OrgClosure => {
  try {
    const orgClosureV0: OrgClosureV0 = orgClosureV0Schema.parse(data);

    const { ...rest } = orgClosureV0;

    // here it's same object cause current v is 0
    const orgClosure: OrgClosure = {
      ...rest,
    };
    return orgClosure;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const ORG_CLOSURE_VERSION = "0";

export type OrgClosure = OrgClosureV0;

export const orgClosureSchema = orgClosureV0Schema;

export const normalizeOrgClosure = (data: any): OrgClosure => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeOrgClosureV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
