import { Avatar, Group, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveServiceProviders } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ServiceProviderSelectProps {
  required?: boolean;
  onChange: ({
    id,
    name,
    supervisingDoctorId,
    supervisingDoctorName,
  }: {
    id: string;
    name: string;
    supervisingDoctorId?: string;
    supervisingDoctorName?: string;
  }) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const ServiceProviderSelect = (props: ServiceProviderSelectProps) => {
  const { t } = useTranslation();
  const serviceProviders = useAppSelector(selectActiveServiceProviders);

  const onSelectChange = (value?: string) => {
    if (value) {
      const serviceProvider = serviceProviders.find((o) => o.id === value);
      if (serviceProvider) {
        props.onChange({
          id: value,
          name: serviceProvider.name,
          supervisingDoctorId: serviceProvider.supervisingDoctorId,
          supervisingDoctorName: serviceProvider.supervisingDoctorName,
        });
      }
    }
  };

  const data = serviceProviders.map((serviceProvider) => ({
    value: serviceProvider.id,
    label: serviceProvider.name,
    code: serviceProvider.code,
    color: serviceProvider.color,
  }));

  return (
    <BSelect
      {...props}
      itemComponent={SelectItem}
      label={t("SERVICE_PROVIDER")}
      nothingFound={t("NOTHING_FOUND")}
      onChange={onSelectChange}
      data={data}
    />
  );
};
interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  code: string;
  color: string;
  label: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ code, label, color, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar
          radius="xl"
          styles={{
            root: { color: "black" },
            placeholder: { backgroundColor: color, color: "white" },
          }}
        >
          {code}
        </Avatar>

        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);
