import { z } from "zod";
import { UnableToNormalizeData } from "../../types-schemas";

export const gPROnCallDataSchema = z.object({
  paymentId: z.string(),
  organizationId: z.string(),
});

export type GPROnCallData = z.infer<typeof gPROnCallDataSchema>;

export const normalizeGPROnCallData = (data: any): GPROnCallData => {
  try {
    return gPROnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const gPROnCallResponseSchema = z.object({
  contentType: z.enum(["application/pdf"]),
  content: z.string(),
});

export type GPROnCallResponse = z.infer<typeof gPROnCallResponseSchema>;

export const normalizeGPROnCallResponse = (data: any): GPROnCallResponse => {
  try {
    return gPROnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
