import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { purchaseOrderItemSchema } from "./PurchaseOrder.item";
import { purchaseOrderStatusEnum } from "./PurchaseOrder.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const purchaseOrderDataV0Schema = z.object({
  status: purchaseOrderStatusEnum,
  date: z.number().int(),
  vendorId: z.string(),
  vendorName: z.string(),
  items: z.record(purchaseOrderItemSchema),
  note: z.string().optional(),
  additionalCost: z.number().int().optional(),
});

type PurchaseOrderDataV0 = z.infer<typeof purchaseOrderDataV0Schema>;

const purchaseOrderV0Schema = baseRecordSchema.merge(purchaseOrderDataV0Schema);

type PurchaseOrderV0 = z.infer<typeof purchaseOrderV0Schema>;

const normalizePurchaseOrderV0 = (data: any): PurchaseOrder => {
  try {
    const purchaseOrderV0: PurchaseOrderV0 = purchaseOrderV0Schema.parse(data);

    const { ...rest } = purchaseOrderV0;

    // here it's same object cause current v is 0
    const purchaseOrder: PurchaseOrder = {
      ...rest,
    };
    return purchaseOrder;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const PURCHASE_ORDER_VERSION = "0";

export type PurchaseOrderData = PurchaseOrderDataV0;

export type PurchaseOrder = BaseRecord & PurchaseOrderData;

export const purchaseOrderDataSchema = purchaseOrderDataV0Schema;

export const purchaseOrderSchema = purchaseOrderV0Schema;

export const normalizePurchaseOrder = (data: any): PurchaseOrder => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizePurchaseOrderV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
