import { Source } from "beitary-shared";
import { Firestore } from "firebase/firestore";
import { invoices as childInvoices } from "./invoices/invoices";
import { payments } from "./payments";

export const invoices = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    invoices: childInvoices(db, organizationId, authorId, authorName, source),

    payments: payments({
      db,
      organizationId,
      authorId,
      authorName,
      source,
    }),
  };
};
