import { Container, Tabs, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const View = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("schedule-settings/service-providers")
    ? "service-providers"
    : path.includes("schedule-settings/appointment-types")
    ? "appointment-types"
    : path.includes("schedule-settings/boarding-resources")
    ? "boarding-resources"
    : "service-providers";

  return (
    <Container fluid>
      <Title mt="xl" weight={500} order={3}>
        {t("SCHEDULE_SETTINGS")}
      </Title>
      <Tabs
        mt="xl"
        value={activeTab}
        onTabChange={(value) => {
          navigate(`${value}`);
        }}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value="appointment-types">
            {t("APPOINTMENT_TYPES")}
          </Tabs.Tab>
          <Tabs.Tab value="service-providers">
            {t("SERVICE_PROVIDERS")}
          </Tabs.Tab>
          <Tabs.Tab value="boarding-resources">
            {t("BOARDING_RESOURCES")}
          </Tabs.Tab>
        </Tabs.List>
        <Outlet />
      </Tabs>
    </Container>
  );
};
