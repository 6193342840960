import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  InventoryItem,
  InventoryItemLocation,
  InventoryItemSupplier,
} from "beitary-shared";
interface InventorysState {
  items: InventoryItem[];
  suppliers: InventoryItemSupplier[];
  locations: InventoryItemLocation[];
  //   purchaseOrders: InventoryPurchaseOrder[];
  //   transactions: InventoryTransaction[];
}

const KEY = "inventory";

const initialState: InventorysState = {
  suppliers: [],
  locations: [],
  items: [],
};

export const inventorysSlice = createSlice({
  name: KEY,
  initialState,
  reducers: {
    setInventoryItemSuppliers: (
      state,
      action: PayloadAction<InventoryItemSupplier[]>
    ) => {
      state.suppliers = action.payload;
    },
    setInventoryItemLocations: (
      state,
      action: PayloadAction<InventoryItemLocation[]>
    ) => {
      state.locations = action.payload;
    },
    setInventoryItems: (state, action: PayloadAction<InventoryItem[]>) => {
      state.items = action.payload;
    },
  },
});

export const {
  setInventoryItemSuppliers,
  setInventoryItemLocations,
  setInventoryItems,
} = inventorysSlice.actions;

export const selectInventoryItemSuppliers = (state: RootState) =>
  state.inventory.suppliers;

export const selectActiveInventoryItemSuppliers = (state: RootState) =>
  state.inventory.suppliers.filter((o) => o.status === "ACTIVE");

export const selectInventoryItemLocations = (state: RootState) =>
  state.inventory.locations;

export const selectActiveInventoryItemLocations = (state: RootState) =>
  state.inventory.locations.filter((o) => o.status === "ACTIVE");

export const selectInventoryItems = (state: RootState) => state.inventory.items;

export const selectActiveInventoryItems = (state: RootState) =>
  state.inventory.items.filter((o) => o.status === "ACTIVE");

export const selectActiveInventoryItemsUnderReorderPoint = (state: RootState) =>
  state.inventory.items.filter(
    (o) => o.status === "ACTIVE" && o.currentQuantity <= (o.reorderPoint ?? 0)
  );

export const inventoryReducer = inventorysSlice.reducer;
