import { z } from "zod";
import {
  memberLicenceType,
  sourceEnum,
  UnableToNormalizeData,
} from "../../types-schemas";

export const aMOnCallDataSchema = z.object({
  organizationId: z.string(),
  authorId: z.string(),
  authorName: z.string(),
  source: sourceEnum,
  isClinicLogin: z.boolean().optional(),
  remoteAccess: z.boolean().optional(),
  email: z.string().email().optional(),
  password: z.string().min(6).optional(),
  name: z.string(),
  initials: z.string().max(3).min(1),
  rolesIds: z.array(z.string()), // practice owner is a role
  licenceType: memberLicenceType.default("EMPLOYEE"),
});

export type AMOnCallData = z.infer<typeof aMOnCallDataSchema>;

export const normalizeAMOnCallData = (data: any): AMOnCallData => {
  try {
    return aMOnCallDataSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

// not nullable, backend will throw error if something goes wrong
export const aMOnCallResponseSchema = z.object({
  errorMessage: z.string().optional(),
  pin: z.string().optional(),
});

export type AMOnCallResponse = z.infer<typeof aMOnCallResponseSchema>;

export const normalizeAMOnCallResponse = (data: any): AMOnCallResponse => {
  try {
    return aMOnCallResponseSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
