import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { ClientCommunication } from "features/admin/client-communication";
import {
  AppointmentNotifications,
  CommunicationHistory,
  Reminders,
  Settings,
} from "features/admin/client-communication/components";
import {
  EmailAppointmentNotificationTemplates,
  SMSAppointmentNotificationTemplates,
} from "features/admin/client-communication/components/AppointmentNotifications/components";
import {
  AddEmailAppointmentNotificationTemplate,
  EditEmailAppointmentNotificationTemplate,
} from "features/admin/client-communication/components/AppointmentNotifications/components/EmailAppointmentNotificationTemplates/components";
import {
  AddSMSAppointmentNotificationTemplate,
  EditSMSAppointmentNotificationTemplate,
} from "features/admin/client-communication/components/AppointmentNotifications/components/SMSAppointmentNotificationTemplates/components";
import {
  EmailCommunicationHistoryTable,
  SMSCommunicationHistoryTable,
} from "features/admin/client-communication/components/CommunicationHistory/components";
import {
  EmailTemplates,
  SMSTemplates,
} from "features/admin/client-communication/components/Reminders/components";
import {
  AddEmailTemplate,
  EditEmailTemplate,
} from "features/admin/client-communication/components/Reminders/components/EmailTemplates/components";
import {
  AddReminder,
  EditReminder,
  RemindersTable,
} from "features/admin/client-communication/components/Reminders/components/RemindersList/components";
import {
  AddSMSTemplate,
  EditSMSTemplate,
} from "features/admin/client-communication/components/Reminders/components/SMSTemplates/components";
import { SettingsForm } from "features/admin/client-communication/components/Settings/components";
import { Navigate, Outlet, Route } from "react-router-dom";

export const ClientCommunicationRoutes = (
  <Route path="client-communication" element={<ClientCommunication />}>
    <Route index element={<Navigate to="settings" replace />} />
    <Route path="settings" element={<Outlet />}>
      <Route index element={<Settings />} />
      <Route
        path="edit"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.CLINIC_SETTINGS_CUD]}
            element={<SettingsForm />}
          />
        }
      />
    </Route>
    {/* reminders */}
    <Route path="reminders" element={<Reminders />}>
      <Route index element={<Navigate to="reminders-list" replace />} />
      <Route path="reminders-list" element={<Outlet />}>
        <Route index element={<RemindersTable />} />
        <Route
          path="new"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<AddReminder />}
            />
          }
        />
        <Route
          path="edit/:reminderId"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<EditReminder />}
            />
          }
        />
      </Route>

      <Route path="email-templates" element={<Outlet />}>
        <Route index element={<EmailTemplates />} />
        <Route
          path="new"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<AddEmailTemplate />}
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<EditEmailTemplate />}
            />
          }
        />
      </Route>

      <Route path="sms-templates" element={<Outlet />}>
        <Route index element={<SMSTemplates />} />
        <Route
          path="new"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<AddSMSTemplate />}
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<EditSMSTemplate />}
            />
          }
        />
      </Route>
    </Route>
    {/* apt notifs */}
    <Route
      path="appointment-notifications"
      element={<AppointmentNotifications />}
    >
      <Route index element={<Navigate to="email-templates" replace />} />

      <Route path="email-templates" element={<Outlet />}>
        <Route index element={<EmailAppointmentNotificationTemplates />} />
        <Route
          path="new"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<AddEmailAppointmentNotificationTemplate />}
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<EditEmailAppointmentNotificationTemplate />}
            />
          }
        />
      </Route>

      <Route path="sms-templates" element={<Outlet />}>
        <Route index element={<SMSAppointmentNotificationTemplates />} />
        <Route
          path="new"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<AddSMSAppointmentNotificationTemplate />}
            />
          }
        />
        <Route
          path="edit/:id"
          element={
            <ProtectedRouteWrapper
              conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
              element={<EditSMSAppointmentNotificationTemplate />}
            />
          }
        />
      </Route>
    </Route>

    {/* history notifs */}
    <Route
      path="communication-history"
      element={
        <ProtectedRouteWrapper
          conditions={["do", OrgPermissions.CLIENT_COMMUNICATION]}
          element={<CommunicationHistory />}
        />
      }
    >
      <Route index element={<Navigate to="email" replace />} />

      <Route path="email" element={<EmailCommunicationHistoryTable />} />
      <Route path="sms" element={<SMSCommunicationHistoryTable />} />
    </Route>
  </Route>
);
