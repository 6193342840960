import { Group, Stack, Textarea, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  normalizeTask,
  obju,
  Result,
  Task,
  TaskData,
  tu,
} from "beitary-shared";
import { BDateAndTimeInput, SaveButton } from "components";
import { MembersMultiSelect } from "components/MembersMultiSelect";
import { ClientSelect, PatientSelect } from "features/Clients/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { rules, TaskFormValues } from "./TaskForm.rules";

export interface TaskFormProps {
  task?: Task;
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
  dueDate?: number;
}

export const TaskForm = ({
  task,
  clientId,

  clientName,
  patientId,
  patientName,
  dueDate,
}: TaskFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { updateTask, addTask } = useDBServices().tasksDBService;

  const getInitialValues = () => {
    if (!task) return undefined;
    return normalizeTask(task);
  };

  const form = useForm<TaskFormValues>({
    initialValues: getInitialValues(),
    validate: rules,
  });

  if (!form.values.status) form.setFieldValue("status", "CREATED");
  if (!form.values.patientId && patientId && patientName)
    form.setValues({
      patientId,
      patientName,
    });
  if (!form.values.clientId && clientId && clientName)
    form.setValues({ clientId, clientName });
  if (!form.values.dueDate && dueDate) form.setValues({ dueDate });

  const submit = async (values: TaskFormValues) => {
    const data: TaskData = {
      ...values,
    };

    obju.removeUndefined(data);
    const notificationId = "submit-task";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("ADD_TASK")
        // t("UPDATE_TASK")
        title: t(task ? "UPDATE_TASK" : "ADD_TASK"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<boolean | null>;
    if (task) {
      result = await updateTask(task.id, data);
    } else {
      result = await addTask(data);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack p="xl">
        <TextInput required label={t("NAME")} {...form.getInputProps("name")} />{" "}
        <Textarea
          label={t("DESCRIPTION")}
          {...form.getInputProps("description")}
        />
        <Group grow>
          <BDateAndTimeInput
            required
            label={t("DUE_DATE")}
            {...form.getInputProps("dueDate")}
            minDateTime={tu.getCurrentDateTime()}
          />
          <MembersMultiSelect
            label={t("ASSIGNED_TO")}
            {...form.getInputProps("assignedToIds")}
            onChange={(v) => form.setFieldValue("assignedToIds", v)}
          />
        </Group>
        <Group grow>
          <ClientSelect
            {...form.getInputProps("clientId")}
            onChange={(v) => {
              form.setValues({
                clientId: v.id,
                clientName: v.name,
                patientId: undefined,
                patientName: undefined,
              });
            }}
          />
          <PatientSelect
            ownerId={form.getInputProps("clientId").value}
            {...form.getInputProps("patientId")}
            onChange={({ id, name }) => {
              form.setValues({
                patientId: id,
                patientName: name,
              });
            }}
          />
        </Group>
        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
