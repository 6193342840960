import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveBoardingResources } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface BoardingResourceSelectProps {
  required?: boolean;
  onChange: (value?: string) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
}

export const BoardingResourceSelect = (props: BoardingResourceSelectProps) => {
  const { t } = useTranslation();

  const resources = useAppSelector(selectActiveBoardingResources);

  const data = resources.map((o) => ({
    value: o.id,
    label: t(o.name),
  }));

  return (
    <BSelect
      {...props}
      data={data}
      label={t("BOARDING_RESOURCE")}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
