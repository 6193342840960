import {
  errorResult,
  id_util,
  Member,
  MemberData,
  MEMBER_VERSION,
  normalizeMember,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization member

interface AddMember {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: MemberData;
  }): Promise<Result<Member | null>>;
}

const addMember: AddMember = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    const newMemberId = id_util.newIdUpperNo016();
    const newMemberRef = doc(
      db,
      "organizations",
      organizationId,
      "members",
      newMemberId
    );

    const newMember: Member = normalizeMember({
      ...data,
      id: newMemberRef.id,
      authorId,
      authorName,
      version: MEMBER_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newMemberRef, newMember);

    const successMessage = "Member created!";
    return successResult({
      message: successMessage,
      payload: newMember,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetMember {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Member | null>>;
}

const getMember: GetMember = async ({ db, organizationId, id }) => {
  try {
    const memberDocRef = doc(
      db,
      "organizations",
      organizationId,
      "members",
      id
    );
    const memberDocSnapshot = await getDoc(memberDocRef);
    if (memberDocSnapshot.exists()) {
      try {
        const data: unknown = memberDocSnapshot.data();
        const member: Member = normalizeMember(data);
        const successMessage = "Member found!";
        return successResult({
          message: successMessage,
          payload: member,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "Member not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditMember {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: MemberData;
  }): Promise<Result<Member | null>>;
}

const editMember: EditMember = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const member = (await getMember({ db, organizationId, id })).payload;

    if (!member) {
      const errorMessage = "Member not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = member;
      const updatedMember: Member = normalizeMember({
        ...data,
        id,
        authorId,
        authorName,
        version: MEMBER_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      const memberRef = doc(db, "organizations", organizationId, "members", id);

      await setDoc(memberRef, updatedMember);

      const successMessage = "Member updated!";
      // console.log(successMessage, updatedMember);
      return successResult({
        message: successMessage,
        payload: updatedMember,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteMember {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteMember: DeleteMember = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "members", id);

    try {
      await deleteDoc(docRef);
      // t("MEMBER_DELETED")
      const successMessage = "MEMBER_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// get organization members listener
interface GetMembersListenerCallback {
  (members: Member[]): void;
}
interface GetMembersListener {
  db: Firestore;
  organizationId: string;
  callback: GetMembersListenerCallback;
}

const getMembersListener = ({
  db,
  organizationId,
  callback,
}: GetMembersListener): Unsubscribe => {
  try {
    // console.log("getMembersListener: new listener");
    const membersQuery = query(
      collection(db, "organizations", organizationId, "members"),
      orderBy("name", "asc")
    );
    return onSnapshot(membersQuery, (querySnapshot) => {
      const members: Member[] = [];
      querySnapshot.forEach((doc) => {
        members.push(normalizeMember(doc.data()));
      });
      callback(members);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const memberss = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getMembersListener: (callback: GetMembersListenerCallback) =>
      getMembersListener({
        db,
        organizationId,
        callback,
      }),
    getMember: (id: string) =>
      getMember({
        db,
        organizationId,
        id,
      }),
    getCurrentMember: () =>
      getMember({
        db,
        organizationId,
        id: authorId,
      }),
    addMember: (data: MemberData) =>
      addMember({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editMember: (id: string, data: MemberData) =>
      editMember({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteMember: (id: string) =>
      deleteMember({
        db,
        organizationId,
        id,
      }),
    archiveMember: (id: string, data: MemberData) =>
      editMember({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { ...data, status: "ARCHIVED" },
      }),
    dearchiveMember: (id: string, data: MemberData) =>
      editMember({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { ...data, status: "ACTIVE" },
      }),
  };
};
