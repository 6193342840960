import { Divider, Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { OrgPermissions } from "beitary-shared";
import { BBox, EditButton } from "components";
import { selectOrganization } from "features/Organization/Organization.slice";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface SettingsProps {}

export const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const org = useAppSelector(selectOrganization);

  if (!org) return <Text>{t("ORGANIZATION_NOT_FOUND")}</Text>;

  const { advancedSettings } = org;

  const {
    sendEmailAptNotif,
    sendEmailNotifOnClientCreate,
    sendEmailNotifOnPatientCreate,
    sendEmailReminderNotif,
    sendSMSAptNotif,
    sendSMSNotifOnClientCreate,
    sendSMSNotifOnPatientCreate,
    sendSMSReminderNotif,
  } = advancedSettings;

  const getStatusIcon = (active?: boolean) => {
    return active ? (
      <ThemeIcon radius="xl" size="md" color="green.5">
        <IconCheck size={16} stroke={2} />
      </ThemeIcon>
    ) : (
      <ThemeIcon radius="xl" size="md" color="red.5">
        <IconX size={16} stroke={2} />
      </ThemeIcon>
    );
  };
  return (
    <Stack>
      <BBox
        header={
          <Group>
            <Text weight={500}>{t("SETTINGS")}</Text>
          </Group>
        }
      >
        <Stack p="xl" spacing="xl">
          <Group grow>
            <Stack p="xl" spacing="xl">
              {" "}
              <Text weight={500}>{t("APPOINTMENT_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text color="cyan">{t("SEND_APT_EMAIL_NOTIFICATION")}</Text>
                {getStatusIcon(sendEmailAptNotif)}
              </Group>
              <Group position="apart">
                <Text color="cyan">{t("SEND_APT_SMS_NOTIFICATION")}</Text>
                {getStatusIcon(sendSMSAptNotif)}
              </Group>
              <Divider />
              <Text weight={500}>{t("REMINDERS_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text color="cyan">
                  {t("SEND_REMINDER_EMAIL_NOTIFICATION")}
                </Text>
                {getStatusIcon(sendEmailReminderNotif)}
              </Group>
              <Group position="apart">
                <Text color="cyan">{t("SEND_REMINDER_SMS_NOTIFICATION")}</Text>
                {getStatusIcon(sendSMSReminderNotif)}
              </Group>
            </Stack>
            <Stack p="xl" spacing="xl">
              <Text weight={500}>{t("CLIENT_CREATED_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text color="cyan">
                  {t("SEND_EMAIL_NOTIFICATION_ON_CLIENT_CREATE")}
                </Text>
                {getStatusIcon(sendEmailNotifOnClientCreate)}
              </Group>
              <Group position="apart">
                <Text color="cyan">
                  {t("SEND_SMS_NOTIFICATION_ON_CLIENT_CREATE")}
                </Text>
                {getStatusIcon(sendSMSNotifOnClientCreate)}
              </Group>
              <Divider />
              <Text weight={500}>{t("PATIENT_CREATED_NOTIFICATIONS")}</Text>
              <Group position="apart">
                <Text color="cyan">
                  {t("SEND_EMAIL_NOTIFICATION_ON_PATIENT_CREATE")}
                </Text>
                {getStatusIcon(sendEmailNotifOnPatientCreate)}
              </Group>
              <Group position="apart">
                <Text color="cyan">
                  {t("SEND_SMS_NOTIFICATION_ON_PATIENT_CREATE")}
                </Text>
                {getStatusIcon(sendSMSNotifOnPatientCreate)}
              </Group>
            </Stack>
          </Group>
        </Stack>
      </BBox>
      <Group position="right">
        <BCan I="do" a={OrgPermissions.CLINIC_SETTINGS_CUD}>
          <EditButton f={() => navigate("edit")} />
        </BCan>
      </Group>
    </Stack>
  );
};
