import { useAppSelector } from "app/hooks";
import { BMultiSelect } from "components";
import { selectActiveMembers } from "features/admin/members/Members.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface MemberMultiselectProps {
  required?: boolean;
  onChange?: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const MembersMultiselect = (props: MemberMultiselectProps) => {
  const { t } = useTranslation();
  const members = useAppSelector(selectActiveMembers);
  const data = members.map((o) => ({ value: o.id, label: o.name }));

  return (
    <BMultiSelect
      {...props}
      label={t("MEMBERS")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};
