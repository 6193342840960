import { NavLink, Popover, Text } from "@mantine/core";
import { useState } from "react";
import { Link } from "react-router-dom";

interface NavPopoverProps {
  item:
    | {
        label: string;
        link: string;
        icon: JSX.Element;
        links?: undefined;
      }
    | {
        label: string;
        link: string;
        icon: JSX.Element;
        links: {
          label: string;
          link: string;
        }[];
      };
  path: string;
}

export function NavPopover({ item, path }: NavPopoverProps) {
  const cyanColorCode = "#15AABF";
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      //width={250}
      position="right"
      // withArrow
      withinPortal
    >
      <Popover.Target>
        <NavLink
          pl="xs"
          key={item.label}
          component={Link}
          to={item.link}
          icon={item.icon}
          active={path.startsWith(item.link)}
          //onClick={() => !item.links && setActive(item.link)}
          style={{
            borderLeft:
              !item.links && path.startsWith(item.link)
                ? `6px solid ${cyanColorCode}`
                : "0px",
          }}
          data-testid="nav-userLink"
          onClick={() => setOpened((o: boolean) => !o)}
          disableRightSectionRotation={true}
          styles={{
            rightSection: {
              display: "none",
            },
            label: {
              display: "none",
            },
            root: {
              maxWidth: 41,
            },
            icon: {
              marginRight: 0,
            },
          }}
        >
          {item.links && (
            <Popover.Dropdown p={0}>
              <NavLink opened childrenOffset={0}>
                {item.links.map((childItem) => (
                  <NavLink
                    p="xs"
                    label={<Text size="xs">{childItem.label}</Text>}
                    key={childItem.label}
                    component={Link}
                    to={childItem.link}
                    active={path.startsWith(childItem.link)}
                    onClick={() => setOpened(false)}
                    style={{
                      borderLeft: path.startsWith(childItem.link)
                        ? `6px solid ${cyanColorCode}`
                        : "0px",
                    }}
                  />
                ))}
              </NavLink>
            </Popover.Dropdown>
          )}
        </NavLink>
      </Popover.Target>
    </Popover>
  );
}
