import { Box, Button, Group, Text } from "@mantine/core";
import { BBox } from "components";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PrescriptionsTable } from "./components";

interface PrescriptionsProps {
  disabled: boolean;
}

export const Prescriptions = ({ disabled }: PrescriptionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BBox
      header={
        <Group position="apart">
          <Text weight={500}>{t("PRESCRIPTIONS")}</Text>
          <BCan I="am" a="DOCTOR">
            <Button disabled={disabled} onClick={() => navigate("new")}>
              {t("ADD_NEW")}
            </Button>
          </BCan>
        </Group>
      }
    >
      <Box p="xl">
        <PrescriptionsTable disabled={disabled} />
      </Box>
    </BBox>
  );
};
