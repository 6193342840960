import { z } from "zod";

export const citySchema = z.object({
  name: z.string(),
  countryCode: z.string(),
  stateCode: z.string(),
  latitude: z.string().nullable().optional(),
  longitude: z.string().nullable().optional(),
});

export type City = z.infer<typeof citySchema>;
