import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { OrgPermissions, Product } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import {
  selectProductCategories,
  selectProducts,
} from "features/admin/catalog/Catalog.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";

type ProductsTableItem = Product & {
  category?: string;
  nameAndCode: string;
  actions?: undefined;
  sellingPriceWithTaxes: number;
};

export const ProductsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currency = org_params_util.getOrgParams().currency;

  const products = useAppSelector(selectProducts);
  const categories = useAppSelector(selectProductCategories);

  const tableItems: ProductsTableItem[] = products.map((product) => ({
    ...product,
    category: categories.find((c) => c.id === product.categoryId)?.name,
    nameAndCode: product.name.concat(product.code ?? ""),
    type: t(product.type),
    sellingPriceWithTaxes: mu.multiply({
      amount: product.unitSellingPrice,
      multiplier: 1 + product.taxRate,
    }),
  }));

  const { deleteProduct, archiveProduct, dearchiveProduct } =
    useDBServices().catalogDBService.products;

  const columns: BDataTableColumn<ProductsTableItem>[] = [
    {
      accessor: "nameAndCode",
      title: <Text>{t("PRODUCT")}</Text>,
      sortable: true,
      render: ({ name, code }) => (
        <>
          <Text>{name}</Text>
          {code && (
            <Text size="sm" color="dimmed">
              {code}
            </Text>
          )}
        </>
      ),
    },
    {
      accessor: "category",
      title: <Text>{t("CATEGORY")}</Text>,
      sortable: true,
    },
    {
      accessor: "type",
      title: <Text>{t("TYPE")}</Text>,
      sortable: true,
    },
    {
      accessor: "unitSellingPrice",
      title: <Text>{t("SELLING_PRICE_NO_TAX")}</Text>,
      sortable: true,
      render: ({ unitSellingPrice }) => (
        <Text>
          {mu.toD(unitSellingPrice)} {t(currency)}
        </Text>
      ),
    },
    {
      accessor: "unitSellingPrice",
      title: <Text>{t("SELLING_PRICE")}</Text>,
      sortable: true,
      render: ({ sellingPriceWithTaxes }) => (
        <Text>
          {mu.toD(sellingPriceWithTaxes)} {t(currency)}
        </Text>
      ),
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (product) => (
        <BCan I="do" a={OrgPermissions.PRODUCTS_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${product.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {product.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () => dearchiveProduct(product.id)}
                item={product}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () => archiveProduct(product.id)}
                item={product}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteProduct(product.id)}
              item={product}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
