import {
  Anchor,
  Box,
  Collapse,
  Divider,
  Group,
  Paper,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface CommunicationHistoryCardProps {
  content: ReactNode;
  title?: ReactNode;
  error?: string;
  to: string[];
  state: "SUCCESS" | "ERROR" | "OTHER";
  timestring: string;
  attachments?: { filename?: string; path?: string }[];
}

export const CommunicationHistoryCard = ({
  title,
  content,
  state,
  timestring,
  to,
  error,
  attachments,
}: CommunicationHistoryCardProps) => {
  const [opened, { toggle }] = useDisclosure(false);
  const { t } = useTranslation();
  const theme = useMantineTheme();
  return (
    <Paper w="100%" p="md" radius="md">
      <Group grow position="center">
        <UnstyledButton onClick={toggle}>
          <Group>
            <Box
              sx={{
                borderRadius: 10,
                height: 20,
                width: 20,
              }}
              bg={
                state === "SUCCESS"
                  ? "green"
                  : state === "ERROR"
                  ? "red"
                  : "orange"
              }
            ></Box>
            <div>
              <Text>{to.join(", ")}</Text>
              <Text size="xs" color="dimmed">
                {timestring}
              </Text>
            </div>
          </Group>
        </UnstyledButton>
      </Group>

      <Collapse in={opened} p="xl" transitionDuration={400}>
        {error && (
          <Text size="lg" color="red" weight="bold">
            {error}
          </Text>
        )}
        {title && (
          <Text size="xs" weight="bold" mb="xl">
            {title}
          </Text>
        )}
        <Text size="xs" mb="xl">
          {content}
        </Text>

        {attachments && (
          <>
            <Divider mt="xl" />
            <Text mt="sm" weight={500}>
              {t("ATTACHMENTS")}
            </Text>
            <Group mt="sm">
              {attachments.map((a) => (
                <Paper
                  p="xs"
                  radius="md"
                  sx={{
                    border: `1px solid ${theme.colors.gray[4]}`,
                  }}
                >
                  <Anchor lineClamp={1} href={a.path ?? ""} target="_blank">
                    {a.filename ?? t("FILE")}
                  </Anchor>
                </Paper>
              ))}
            </Group>
          </>
        )}
      </Collapse>
    </Paper>
  );
};
