import { useAppSelector } from "app/hooks";
import { BSelect } from "components";
import { selectActiveInventoryItems } from "features/admin/inventory/Inventory.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InventoryItemSelectProps {
  required?: boolean;
  onChange: (
    value:
      | {
          id: string;
          name: string;
        }
      | undefined
  ) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const InventoryItemSelect = (props: InventoryItemSelectProps) => {
  const { t } = useTranslation();
  const items = useAppSelector(selectActiveInventoryItems);
  const data = items.map((o) => ({ value: o.id, label: o.name }));

  const onSelectChange = (value?: string) => {
    if (value) {
      const item = items.find((i) => i.id === value);
      if (item) {
        props.onChange({
          id: value,
          name: item.name,
        });
      }
    }
  };

  return (
    <BSelect
      {...props}
      data={data}
      onChange={onSelectChange}
      label={t("INVENTORY_ITEM")}
      nothingFound={t("NOTHING_FOUND")}
      limit={20}
    />
  );
};
