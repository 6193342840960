import {
  ActionIcon,
  Button,
  ColorPicker,
  DEFAULT_THEME,
  Group,
  Modal,
  Stack,
} from "@mantine/core";
import { IconColorPicker } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface BColorPickerProps {
  setColor: (color: string) => void;
  defaultColor?: string;
}

export const BColorPicker = ({ setColor, defaultColor }: BColorPickerProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState<string | undefined>();

  const confirm = () => {
    value && setColor(value);
    setOpened(false);
  };

  return (
    <>
      <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack pb="xl" align="center" spacing={0}>
          <ColorPicker
            size="md"
            value={value}
            onChange={setValue}
            swatches={[
              ...DEFAULT_THEME.colors.red,
              ...DEFAULT_THEME.colors.orange,
              ...DEFAULT_THEME.colors.yellow,
              ...DEFAULT_THEME.colors.green,
              ...DEFAULT_THEME.colors.blue,
              ...DEFAULT_THEME.colors.violet,
              ...DEFAULT_THEME.colors.gray,
            ]}
          />
        </Stack>
        <Group mt="xl" position="right">
          <Button onClick={confirm}>{t("CONFIRM")}</Button>
          <Button variant="outline" onClick={() => setOpened(false)}>
            {t("CANCEL")}
          </Button>
        </Group>
      </Modal>
      <ActionIcon
        onClick={() => setOpened(true)}
        size={"xl"}
        variant="filled"
        sx={{
          backgroundColor: defaultColor,
          "&:hover": {
            filter: "brightness(120%)",
            transform: "scale(1.03)",
            backgroundColor: defaultColor,
          },
        }}
      >
        <IconColorPicker />
      </ActionIcon>
    </>
  );
};
