import { ActionIcon, Group, Text } from "@mantine/core";
import { IconCopy, IconEdit, IconEye } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { OrgPermissions, PhysicalExamTemplate } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectPhysicalExamTemplates } from "features/admin/templates/Templates.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type PhysicalExamTemplatesTableItem = PhysicalExamTemplate & {
  actions?: undefined;
};

export const PhysicalExamTemplatesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableItems = useAppSelector(selectPhysicalExamTemplates);

  const {
    deletePhysicalExamTemplate,
    archivePhysicalExamTemplate,
    dearchivePhysicalExamTemplate,
    copyPhysicalExamTemplate,
  } = useDBServices().templatesDBService.physicalExamTemplates;

  const columns: BDataTableColumn<PhysicalExamTemplatesTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 240,
      render: (template) => (
        <Group position="right">
          <ActionIcon onClick={() => navigate(`view/${template.id}`)}>
            <IconEye size={18} />
          </ActionIcon>
          <BCan I="do" a={OrgPermissions.PHYSICAL_EXAM_TEMPLATES_CUD}>
            <ActionIcon onClick={() => copyPhysicalExamTemplate(template.id)}>
              <IconCopy size={18} />
            </ActionIcon>
            <ActionIcon onClick={() => navigate(`edit/${template.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {template.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () =>
                  dearchivePhysicalExamTemplate(template.id, template)
                }
                item={template}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () =>
                  archivePhysicalExamTemplate(template.id, template)
                }
                item={template}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deletePhysicalExamTemplate(template.id)}
              item={template}
            />
          </BCan>
        </Group>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
