import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Client, Patient } from "beitary-shared";

export type ClientsMap = { [id: string]: Client };
export type PatientsMap = { [id: string]: Patient };
interface ClientsState {
  clients: ClientsMap;
  patients: PatientsMap;
}

const initialState: ClientsState = {
  clients: {},
  patients: {},
};

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, action: PayloadAction<ClientsMap>) => {
      state.clients = action.payload;
    },
    setPatients: (state, action: PayloadAction<{ [id: string]: Patient }>) => {
      state.patients = action.payload;
    },
  },
});

export const { setClients, setPatients } = clientsSlice.actions;

export const selectClients = (state: RootState) =>
  Object.values(state.clients.clients);

export const selectPatients = (state: RootState) =>
  Object.values(state.clients.patients);

export const selectActiveClients = (state: RootState) =>
  Object.values(state.clients.clients).filter((c) => c.status === "ACTIVE");

export const selectActivePatients = (state: RootState) =>
  Object.values(state.clients.patients).filter((p) => p.status === "ACTIVE");

export const selectClientsMap = (state: RootState) => state.clients.clients;

export const selectPatientsMap = (state: RootState) => state.clients.patients;

export const clientsReducer = clientsSlice.reducer;
