import {
  errorResult,
  normalizeTask,
  Result,
  Source,
  successResult,
  Task,
  TaskData,
  TASK_VERSION,
  tu,
} from "beitary-shared";
import { Unsubscribe } from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";

interface AddTask {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: TaskData;
  }): Promise<Result<boolean | null>>;
}

const addTask: AddTask = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new task id
    const newTaskRef = doc(
      collection(db, "organizations", organizationId, "tasks")
    );

    // console.log(newTaskRef);

    const newTask: Task = normalizeTask({
      ...data,
      organizationId,
      id: newTaskRef.id,
      authorId,
      authorName,
      version: TASK_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newTaskRef, newTask);

    // t("TASK_CREATED")
    const successMessage = "TASK_CREATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetTask {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Task | null>>;
}

const getTask: GetTask = async ({ db, organizationId, id }) => {
  try {
    const taskDocRef = doc(db, "organizations", organizationId, "tasks", id);
    const taskDocSnapshot = await getDoc(taskDocRef);
    if (taskDocSnapshot.exists()) {
      try {
        const data: unknown = taskDocSnapshot.data();
        const task: Task = normalizeTask(data);
        const successMessage = "Task found!";
        return successResult({
          message: successMessage,
          payload: task,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "Task not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetTasks {
  ({
    db,
    organizationId,
    startAfterTime,
    startBeforeTime,
    startAtTime,
    clientId,
    patientId,
    showComplete,
  }: {
    db: Firestore;
    organizationId: string;
    startAfterTime?: number;
    startBeforeTime?: number;
    startAtTime?: number;
    clientId?: string;
    patientId?: string;
    showComplete?: boolean;
  }): Promise<Result<Task[] | null>>;
}

const getTasks: GetTasks = async ({
  db,
  organizationId,
  startAfterTime,
  startBeforeTime,
  startAtTime,
  clientId,
  patientId,
  showComplete,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "tasks")
    );

    if (!showComplete) {
      newQuery = query(newQuery, where("status", "==", "CREATED"));
    }
    if (clientId) {
      newQuery = query(newQuery, where("clientId", "==", clientId));
    }

    if (patientId) {
      newQuery = query(newQuery, where("patientId", "==", patientId));
    }

    newQuery = query(newQuery, orderBy("dueDate", "desc"), limit(PAGE_SIZE));

    if (startAtTime) {
      newQuery = query(newQuery, startAt(startAtTime));
    } else if (startBeforeTime) {
      newQuery = query(
        newQuery,
        endBefore(startBeforeTime),
        limitToLast(PAGE_SIZE)
      );
    } else if (startAfterTime) {
      newQuery = query(newQuery, startAfter(startAfterTime));
    }

    const querySnapshot = await getDocs(newQuery);

    const tasks: Task[] = [];

    querySnapshot.forEach((doc) => {
      try {
        tasks.push(normalizeTask(doc.data()));
      } catch (error) {
        console.log(error);
      }
    });

    return successResult({
      message: "SUCCESS",
      payload: tasks,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface DeleteTask {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteTask: DeleteTask = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "tasks", id);

    try {
      await deleteDoc(docRef);
      // t("TASK_DELETED")
      const successMessage = "TASK_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateTask {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<TaskData>;
  }): Promise<Result<boolean | null>>;
}

const updateTask: UpdateTask = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "tasks", id);

    const updates: Partial<Task> = {
      ...data,
      authorId,
      authorName,
      version: TASK_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("TASK_UPDATED")
    const successMessage = "TASK_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization tasks listener
interface GetTasksForDayListenerCallback {
  (tasks: Task[]): void;
}
interface GetTasksListener {
  db: Firestore;
  organizationId: string;
  day: number;
  callback: GetTasksForDayListenerCallback;
}

const getTasksForDayListener = ({
  db,
  organizationId,
  day,
  callback,
}: GetTasksListener): Unsubscribe => {
  try {
    // console.log("getTasksListener: new listener");

    // console.log(day + 24 * 60 * 60 * 1000);

    const tasksQuery = query(
      collection(db, "organizations", organizationId, "tasks"),
      where("dueDate", ">=", day),
      where("dueDate", "<=", day + 24 * 60 * 60 * 1000),
      orderBy("dueDate", "asc")
    );
    return onSnapshot(tasksQuery, (querySnapshot) => {
      const tasks: Task[] = [];
      querySnapshot.forEach((doc) => {
        tasks.push(normalizeTask(doc.data()));
      });
      callback(tasks);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const tasks = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getTasksForDayListener: (props: {
      day: number;
      callback: GetTasksForDayListenerCallback;
    }) => getTasksForDayListener({ db, organizationId, ...props }),
    addTask: (data: TaskData) =>
      addTask({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    getTasks: (props: {
      startAfterTime?: number;
      startBeforeTime?: number;
      startAtTime?: number;
      clientId?: string;
      patientId?: string;
      showComplete?: boolean;
    }) =>
      getTasks({
        db,
        organizationId,
        ...props,
      }),
    getTask: (id: string) =>
      getTask({
        db,
        organizationId,
        id,
      }),
    updateTask: (id: string, data: Partial<TaskData>) =>
      updateTask({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteTask: (id: string) =>
      deleteTask({
        db,
        organizationId,
        id,
      }),
    completeTask: (id: string) =>
      updateTask({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "COMPLETED" },
      }),
    uncompleteTask: (id: string) =>
      updateTask({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "CREATED" },
      }),
  };
};
