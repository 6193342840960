import { zodResolver } from "@mantine/form";
import { appointmentDataSchema } from "beitary-shared";
import { z } from "zod";

const appointmentFormSchema = appointmentDataSchema.omit({
  organizationId: true,
  organizationName: true,
  recurring: true, // TODO fix?
  status: true,
  remindersNotifications: true,
  bookedNotifications: true,
  canceledNotifications: true,
  rescheduledNotifications: true,
});

export type AppointmentFormValues = z.infer<typeof appointmentFormSchema>;

export const rules = zodResolver(appointmentFormSchema);
