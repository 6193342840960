import { Button, Modal } from "@mantine/core";
import { InvoiceLineItem } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RefundInvoiceForm } from "../RefundInvoiceForm";

export interface RefundInvoiceModalProps {
  items: InvoiceLineItem[];
  parentInvoiceId: string;
  parentInvoiceSerialNumber?: string;
  clientId: string;
  clientName: string;
  maxRefundAmount: number;
}

export const RefundInvoiceModal = (props: RefundInvoiceModalProps) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);
  return (
    <>
      <Modal
        size="xl"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("RETURN_ITEMS")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <RefundInvoiceForm {...props} />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("RETURN_ITEMS")}</Button>
    </>
  );
};
