import { z } from "zod";
import { UnableToNormalizeData } from "../types-schemas";

export const bSettingsSchema = z.object({
  id: z.string(),
  version: z.string(),
});

export type BSettings = z.infer<typeof bSettingsSchema>;

export const normalizeBSettings = (data: any): BSettings => {
  try {
    return bSettingsSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};
