import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { Member } from "beitary-shared";
import { AuthUser } from "services/auth/authentication/authentication";

export interface AuthState {
  activeUser: AuthUser | null;
  activeMember: Member | null; // undefined = not loaded yet; null = not found or error so member could not be fetched
  activeOrganizationId: string | null;
  isLocked: boolean;
}

const initialState: AuthState = {
  activeUser: null,
  activeMember: null,
  activeOrganizationId: null,
  isLocked: true,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setActiveUser: (state, action: PayloadAction<AuthUser | null>) => {
      state.activeUser = action.payload;
    },
    setIsLocked: (state, action: PayloadAction<boolean>) => {
      state.isLocked = action.payload;
    },
    setActiveMember: (state, action: PayloadAction<Member | null>) => {
      state.activeMember = action.payload;
    },
    setActiveOrganization: (state, action: PayloadAction<string | null>) => {
      if (action.payload !== state.activeOrganizationId) {
        state.activeOrganizationId = action.payload;
      }
    },
  },
});

export const {
  setActiveMember,
  setActiveUser,
  setActiveOrganization,
  setIsLocked,
} = authenticationSlice.actions;

export const selectActiveUser = (state: RootState) =>
  state.authentication.activeUser;

export const selectActiveMember = (state: RootState) =>
  state.authentication.activeMember; // TODO this is useless i guess

export const selectActiveOrganization = (state: RootState) =>
  state.authentication.activeOrganizationId;

export const selectIsLocked = (state: RootState) =>
  state.authentication.isLocked;

export const authenticationReducer = authenticationSlice.reducer;
