import { z } from "zod";
import { currencyEnum } from "../../../../types-schemas/common";
import { money_utils } from "../../../../utils";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { invoiceLineItemSchema } from "../InvoiceLineItem";
import { InvoicePayment, invoicePaymentSchema } from "../InvoicePayment";
import { invoiceStatusEnum } from "./Invoice.status";
import { invoiceTypeEnum } from "./Invoice.type";

/**
 * schema and types versions
 * from first to last
 */

// v0
// t("TIMBRE_FISCAL")
export const getAdditionalTaxes = (country: string): AdditionalTax[] => {
  switch (country) {
    case "TN":
      return [
        {
          name: "TIMBRE_FISCAL",
          nature: "AMOUNT",
          value: 1000,
          status: "INACTIVE",
        },
      ];

    default:
      throw new Error("COUNTRY_NOT_SUPPORTED");
  }
};

export const additionalTaxSchema = z.object({
  name: z.string(),
  nature: z.enum(["%", "AMOUNT"]),
  value: z.number(),
  status: z.enum(["ACTIVE", "INACTIVE"]),
});

export type AdditionalTax = z.infer<typeof additionalTaxSchema>;

const invoiceDataV0Schema = z.object({
  status: invoiceStatusEnum,
  type: invoiceTypeEnum,
  checkedOutAt: z.number().int().positive().optional(),
  dateIssued: z.number().int().positive().optional(),
  serialNumber: z.string().optional(),
  parentId: z.string().optional(), // for refund invoices
  parentSerialNumber: z.string().optional(), // for refund invoices
  clientId: z.string(),
  clientName: z.string(),
  clientDiscount: z.number().int().optional(),
  taxExempt: z.boolean().optional(), // remove tax amoount from taxable items
  subtotal: z.number().int(), // sum total of all invoice line items no taxes with discounts
  discount: z.number().int(), // sum total of all discounts (no taxes) on all invoice line items
  taxes: z.number().int(), // sum total of tax on all taxable items
  total: z.number().int(), // Subtotal + Tax (Total amount client is/was responsible for prior to any payments being made)
  paid: z.number().int(), // sum of payments made toward za invoice
  refunded: z.number().int(), // sum of refunds processed associated with the invoice
  balanceDue: z.number().int(), // amount still owed by client
  currency: currencyEnum,
  // notes:z.string().optional(), // client notes: should ne done like notes for tx
  // internalNotes:z.string().optional(),should ne done like notes for tx
  // activity log

  lineItems: z.record(invoiceLineItemSchema),
  payments: z.record(invoicePaymentSchema),
  refunds: z.record(invoicePaymentSchema).default({}), // come from payments done on refund-invoices

  additionalTaxes: z
    .array(additionalTaxSchema)
    .default(getAdditionalTaxes("TN")), // this is a copy of what's in the record, just to facilitate stuff
});

type InvoiceDataV0 = z.infer<typeof invoiceDataV0Schema>;

const invoiceV0Schema = baseRecordSchema.merge(invoiceDataV0Schema);

type InvoiceV0 = z.infer<typeof invoiceV0Schema>;

const normalizeInvoiceV0 = (data: any): Invoice => {
  try {
    const invoiceV0: InvoiceV0 = invoiceV0Schema.parse(data);

    const { ...rest } = invoiceV0;

    // here it's same object cause current v is 0
    const invoice: Invoice = {
      ...rest,
    };
    return invoice;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVOICE_VERSION = "0";

export type InvoiceData = InvoiceDataV0;

export type Invoice = BaseRecord & InvoiceData;

export const invoiceDataSchema = invoiceDataV0Schema;

export const invoiceSchema = invoiceV0Schema;

export const normalizeInvoice = (data: any): Invoice => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInvoiceV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};

export const invoiceUtils = (inv: Invoice) => {
  const type = inv.type;

  const lineItems = Object.values(inv.lineItems).map((i) => {
    const isFixedPriceBundle = i.bundleId === i.id;
    const isFromFixedPriceBundle =
      i.bundleId !== undefined && i.bundleId !== i.id;
    return {
      ...i,
      isFixedPriceBundle,
      isFromFixedPriceBundle,
      ...money_utils.calculateItemValueAndTaxes({
        qty: i.qty,
        taxRate: i.taxRate,
        totalDiscount: i.discount ?? 0,
        unitValue: isFromFixedPriceBundle ? 0 : i.pUnitSellingPrice,
      }),
    };
  });

  const payments: InvoicePayment[] = Object.values(inv.payments);
  const refunds: InvoicePayment[] = Object.values(inv.refunds);

  const totalPayments = payments.reduce(
    (acc, payment) => acc + payment.paymentAmount,
    0
  );

  const totalRefunds = refunds.reduce(
    (acc, refund) => acc + refund.paymentAmount,
    0
  );

  const additionalTaxesTotal = inv.additionalTaxes.reduce(
    (acc, item) =>
      acc +
      (item.status === "ACTIVE"
        ? item.nature === "%"
          ? money_utils.multiply({
              amount: subtotal,
              multiplier: item.value,
            })
          : item.value
        : 0),
    0
  );

  ////////////

  const totalItemsValuesNoTaxes = lineItems.reduce(
    (acc, item) => acc + item.totalValueNoTaxes,
    0
  );
  const totalItemsValuesWithTaxes = lineItems.reduce(
    (acc, item) => acc + item.totalValueWithTaxes,
    0
  );
  const totalItemsTaxes = lineItems.reduce(
    (acc, item) => acc + item.totalTaxes,
    0
  );
  const totalItemsDiscounts = lineItems.reduce(
    (acc, item) => acc + item.totalDiscount,
    0
  );

  const subtotal = totalItemsValuesNoTaxes;
  const discount = totalItemsDiscounts;
  const taxTotal = totalItemsTaxes;
  const subtotalWithTaxes = totalItemsValuesWithTaxes;
  const total = subtotal + taxTotal + additionalTaxesTotal;
  const balanceDue = total - totalPayments;

  return {
    type,
    lineItems,
    payments,
    refunds,
    total,
    subtotal,
    subtotalWithTaxes,
    discount,
    balanceDue,
    paid: totalPayments,
    taxes: taxTotal,
    refunded: totalRefunds,
  };
};
