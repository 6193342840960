import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { ClinicLocation, OrgPermissions } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectClinicLocations } from "features/admin/clinic-settings/ClinicSettings.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type LocationsTableItem = ClinicLocation & {
  actions?: undefined;
};

export const LocationsTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableItems = useAppSelector(selectClinicLocations);

  const {
    deleteClinicLocation,
    archiveClinicLocation,
    dearchiveClinicLocation,
  } = useDBServices().clinicSettingsDBService.clinicLocations;

  const columns: BDataTableColumn<LocationsTableItem>[] = [
    { accessor: "name", title: <Text>{t("NAME")}</Text>, sortable: true },
    { accessor: "notes", title: <Text>{t("NOTES")}</Text>, sortable: true },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (location) => (
        <BCan I="do" a={OrgPermissions.CLINIC_SETTINGS_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${location.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {location.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () => dearchiveClinicLocation(location.id, location)}
                item={location}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () => archiveClinicLocation(location.id, location)}
                item={location}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteClinicLocation(location.id)}
              item={location}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
