import { z } from "zod";
import { UnableToNormalizeData } from "../../../types-schemas/errors";

export const patientChronicConditionSchema = z.object({
  na: z.string(), // name
  no: z.string().optional(), // note
  sd: z.number().nonnegative().int(), // start date
  rez: z.number().nonnegative().int().optional(), // rezolved date
  id: z.string(),
  a: z.string(), // author
});

export type PatientChronicCondition = z.infer<
  typeof patientChronicConditionSchema
>;

export const normalizepatientChronicCondition = (
  data: any
): PatientChronicCondition => {
  try {
    return patientChronicConditionSchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};
