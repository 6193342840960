import {
  Anchor,
  Avatar,
  Badge,
  Card,
  Divider,
  Group,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { Client, Invoice } from "beitary-shared";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface ImportantInfoCardProps {
  invoice: Invoice;
  client: Client;
}

export const ImportantInfoCard = ({
  invoice,
  client,
}: ImportantInfoCardProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const { status } = invoice;

  const { email, phoneNumber, thumbURL } = client;

  const infoElms = [email, phoneNumber];

  let info = infoElms
    .filter((e) => !!e)
    .map((e, i) => (
      <Text size="sm" key={i}>
        {e}
      </Text>
    ));
  info = info
    .map((e, i) =>
      i < info.length - 1 ? [e, <Text key={`.${i}`}>.</Text>] : [e]
    )
    .flat();

  return (
    <Card sx={{ border: `1px solid ${theme.colors.gray[3]}` }}>
      <Group>
        <Badge size="lg">{t(status)}</Badge>

        <Divider orientation="vertical" />

        <Avatar src={thumbURL} size={50} radius="xl">
          <IconPaw size={30} />
        </Avatar>
        <Anchor
          color="dark"
          size="sm"
          weight={400}
          lineClamp={1}
          component={Link}
          to={`/clients/${client.id}`}
        >
          {client.name} {<IconExternalLink size={12} />}
        </Anchor>
        {info}
      </Group>
    </Card>
  );
};
