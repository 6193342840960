import { z } from "zod";

export const productAdministrationRouteEnum = z.enum([
  "IM", // intramuscular
  "IV", // intravenous
  "IP", // intraperitoneal
  "ID", // intradermal
  "SC", // subcutaneous
  "p.o.", // orally
  "p.r.", // by recum
  "o.d.", // right eye
  "o.s.", // left eye
  "o.u.", // poth eyes
  "a.d.", // right ear
  "a.s.", // left ear
  "a.u.", // both ears
  "top", // topical
  "vag", // vaginally
  "CRI", // constant rate infusion
  "OTM", // oral transmucosal administration
  "other", // other
]);

export type ProductAdministrationRoute = z.infer<
  typeof productAdministrationRouteEnum
>;

// t("IM")
// t("IV")
// t("IP")
// t("ID")
// t("SC")
// t("p.o")
// t("p.r")
// t("o.d.")
// t("o.s.")
// t("o.u.")
// t("a.d.")
// t("a.s.")
// t("a.u.")
// t("top")
// t("vag")
// t("CRI")
// t("OTM")
// t("other")
