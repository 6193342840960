import { z } from "zod";

export enum SubsStatus {
  TRIALING = "0",
  ACTIVE = "1",
  PAUSED = "2",
  PAST_DUE = "3",
  UNPAID = "4",
  ENDED = "5",
}

export const subscriptionStatusEnum = z.nativeEnum(SubsStatus);
export type SubscriptionStatus = z.infer<typeof subscriptionStatusEnum>;

// inspired by:

/**
 *
 * Possible values are  trialing, active, past_due, unpaid or ended. added paused (cauz we prefer them not leaving)
 * A subscription that is currently in a trial period is trialing and moves to active when the trial period is over.
 * A subscription past_due when its invoice is not paid by the due date, and unpaid if it is still not paid by an additional deadline after that (7 days default). Note that when a subscription has a status of unpaid, no subsequent invoices will be attempted (invoices will be created, but then immediately automatically closed). After receiving updated payment information from a customer, you may choose to reopen and pay their closed invoices.
 * A subscription is ended if customer no longer interested in using the software. Must have paid all previous invoices
 */
