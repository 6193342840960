import {
  Box,
  Button,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconAlertCircle, IconDownload } from "@tabler/icons-react";
import { bFileCategoriesEnum, errorNotification, tu } from "beitary-shared";
import { BBox, BMoneyInput, MoveBackButton } from "components";
import { useCServices } from "hooks/useCService/useCService";
import { useSubmitState } from "hooks/useSubmitState";
import { useUploadFile } from "hooks/useUploadFile";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";
import { utils, write, writeFile } from "xlsx";

export interface EndOfDayReportProps {}

export const EndOfDayReport = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [submitState, setSubmitState] = useSubmitState();

  const [{ submitFile, submitFileState }] = useUploadFile({
    filesCategory: bFileCategoriesEnum.Values.REPORT,
  });

  const [paymentsData, setPaymentsData] = useState<any>([]); // TODO fix
  const [accountsReceivableData, setAccountsReceivablePaymentsData] =
    useState<any>([]); // TODO fix
  const [invoicesData, setInvoicesData] = useState<any>([]); // TODO fix

  const [startAmount, setStartAmount] = useState(0);
  const [endAmount, setEndAmount] = useState(0);
  const [cashWithdrawal, setCashWithdrawal] = useState(0);
  const [start] = useState<number>(tu.getStartOfDate(tu.getCurrentDate()));
  const [end] = useState<number>(
    tu.getStartOfDate(tu.getCurrentDate()) + 1000 * 60 * 60 * 24 - 1
  );

  const paymentsQuery = useCServices().reports.paymentsReport;
  const accountsReceivableQuery =
    useCServices().reports.accountsReceivableReport;
  const invoicesQuery = useCServices().reports.invoicesSummaryReport;

  const runQuery = async () => {
    setSubmitState("pending-response");
    const paymentsResult = await paymentsQuery({ end, start });
    const accountsReceivableResult = await accountsReceivableQuery();
    const invoicesResult = await invoicesQuery({ end, start });

    if (
      paymentsResult.type === "success" &&
      accountsReceivableResult.type === "success" &&
      invoicesResult.type === "success" &&
      paymentsResult.payload &&
      accountsReceivableResult.payload &&
      invoicesResult.payload
    ) {
      setSubmitState("success");
      setPaymentsData(paymentsResult.payload.data);
      setAccountsReceivablePaymentsData(accountsReceivableResult.payload.data);
      setInvoicesData(invoicesResult.payload.data);
    } else {
      showNotification(
        errorNotification({
          message: t("ERROR_GENERATING_REPORT"),
        })
      );
      setSubmitState("error");
      return;
    }
  };

  const totalCashIncome = useMemo(
    () =>
      (paymentsData ?? [])
        .filter((p: any) => p.payment_method === "CASH")
        .reduce(
          (acc: number, item: any) =>
            acc + item.total_payment_amount - item.change,
          0
        ),
    [paymentsData]
  );
  const calculatedCashWithdrawal = useMemo(() => {
    return totalCashIncome + startAmount - endAmount;
  }, [totalCashIncome, endAmount, startAmount]);

  const calculatedEndAmount = useMemo(() => {
    return totalCashIncome + startAmount - cashWithdrawal;
  }, [totalCashIncome, cashWithdrawal, startAmount]);

  const downloadXslx = async () => {
    const summarySheet = utils.json_to_sheet([
      {
        date: tu.getDateAndTimeString(tu.getCurrentDateTime()),
        payments_count: paymentsData.length,
        invoices_count: paymentsData.length,
        start_amount: startAmount,
        end_amount: endAmount,
        cash_withdrawal: cashWithdrawal,
        cash_income_total: totalCashIncome,
        calculated_end_amount: calculatedEndAmount,
        calculated_cash_withdrawal: calculatedCashWithdrawal,
      },
    ]);
    const paymentsDataSheet = utils.json_to_sheet(paymentsData);
    const invoicesDataSheet = utils.json_to_sheet(invoicesData);
    const accountsReceivableDataSheet = utils.json_to_sheet(
      accountsReceivableData
    );

    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, summarySheet, t("SUMMARY"));
    utils.book_append_sheet(workBook, paymentsDataSheet, t("PAYMENTS"));
    utils.book_append_sheet(workBook, invoicesDataSheet, t("INVOICES"));
    utils.book_append_sheet(
      workBook,
      accountsReceivableDataSheet,
      t("ACCOUNTS_RECEIVABLE")
    );

    const buf = write(workBook, { bookType: "xlsx", type: "buffer" });

    const title = `${t("END_OF_DAY_REPORT")} ${tu.getYYYYMMDDString(
      tu.getCurrentDateTime()
    )}.xlsx`;

    const file = new File([buf], title, { type: "application/octet-stream" });
    await submitFile(file);

    writeFile(workBook, title);
  };
  return (
    <Container fluid>
      <Stack spacing="xl">
        <Group mt="xl">
          <MoveBackButton />
        </Group>

        <BBox header={<Text weight={500}>{t("END_OF_DAY_REPORT")}</Text>}>
          <Stack p="xl">
            <Group position="apart" align="flex-end">
              <Group>
                <BMoneyInput
                  label={t("START_AMOUNT")}
                  onChange={(v) => {
                    if (v !== "") setStartAmount(v);
                  }}
                  value={startAmount}
                />
                <BMoneyInput
                  label={t("END_AMOUNT")}
                  onChange={(v) => {
                    if (v !== "") setEndAmount(v);
                  }}
                  value={endAmount}
                />
                <BMoneyInput
                  label={t("CASH_WITHDRAWAL")}
                  onChange={(v) => {
                    if (v !== "") setCashWithdrawal(v);
                  }}
                  value={cashWithdrawal}
                />
              </Group>
              <Group>
                <Button
                  onClick={runQuery}
                  loading={submitState === "pending-response"}
                >
                  {t("GENERATE_REPORT")}
                </Button>
                <Button
                  variant="outline"
                  loading={submitFileState === "pending-response"}
                  leftIcon={<IconDownload size={18} />}
                  disabled={submitState !== "success"}
                  onClick={downloadXslx}
                >
                  {t("DOWNLOAD")}
                </Button>
              </Group>
            </Group>

            <Box mt="md" mb="md">
              {submitState === "success" && (
                <Stack>
                  <Group grow mt="xl" align="flex-start">
                    <Paper withBorder p="md" radius="md">
                      <Text color="dimmed" weight="bold" mb="xl">
                        {t("START_AMOUNT")}
                      </Text>
                      <Text size="xl" weight="bold">
                        {mu.toD(startAmount)}
                      </Text>
                    </Paper>
                    <Paper withBorder p="md" radius="md">
                      <Text color="dimmed" weight="bold" mb="xl">
                        {t("END_AMOUNT")}
                      </Text>
                      <Text size="xl" weight="bold">
                        {mu.toD(endAmount)}
                      </Text>
                    </Paper>
                    <Paper withBorder p="md" radius="md">
                      <Text color="dimmed" weight="bold" mb="xl">
                        {t("CASH_WITHDRAWAL")}
                      </Text>
                      <Text size="xl" weight="bold">
                        {mu.toD(cashWithdrawal)}
                      </Text>
                    </Paper>
                  </Group>
                  <Group grow mt="xl" align="flex-start">
                    <Paper withBorder p="md" radius="md">
                      <Text color="dimmed" weight="bold" mb="sm">
                        {t("CASH_PAYMENTS_TOTAL")}
                      </Text>
                      <Text size="xl" weight="bold">
                        {mu.toD(totalCashIncome)}
                      </Text>
                    </Paper>
                    <Paper withBorder p="md" radius="md">
                      <Group mb="sm" position="apart">
                        <Text color="dimmed" weight="bold">
                          {t("CALCULATED_END_AMOUNT")}
                        </Text>
                        {calculatedEndAmount !== endAmount && (
                          <IconAlertCircle
                            stroke={2}
                            color={theme.colors.red[5]}
                          />
                        )}
                      </Group>
                      <Group spacing="xs">
                        <Text size="xl" weight="bold">
                          {mu.toD(calculatedEndAmount)}
                        </Text>
                        <Text size="sm" color="dimmed">
                          {mu.toD(endAmount - calculatedEndAmount)}
                        </Text>
                      </Group>
                    </Paper>
                    <Paper withBorder p="md" radius="md">
                      <Group mb="sm" position="apart">
                        <Text color="dimmed" weight="bold">
                          {t("CALCULATED_CASH_WITHDRAWAL")}
                        </Text>
                        {calculatedCashWithdrawal !== cashWithdrawal && (
                          <IconAlertCircle
                            stroke={2}
                            color={theme.colors.red[5]}
                          />
                        )}
                      </Group>
                      <Group spacing="xs">
                        <Text size="xl" weight="bold">
                          {mu.toD(calculatedCashWithdrawal)}
                        </Text>
                        <Text size="sm" color="dimmed">
                          {mu.toD(calculatedCashWithdrawal - cashWithdrawal)}
                        </Text>
                      </Group>
                    </Paper>
                  </Group>
                </Stack>
              )}
            </Box>
          </Stack>
        </BBox>
      </Stack>
    </Container>
  );
};
