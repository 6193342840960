import {
  Button,
  Card,
  Checkbox,
  Container,
  Group,
  Stack,
  Text,
  Textarea
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  Client,
  getNotificationByResultType,
  Invoice,
  loadingInfoNotification,
  obju,
  Payment,
  PaymentData,
  PaymentInvoiceData,
  PaymentType,
  tu
} from "beitary-shared";
import { BBox, MoveBackButton, TableWithSearchAndSort } from "components";
import { BMoneyInput } from "components/BMoneyInput";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";
import { ImportantInfoCard } from "../ImportantInfoCard";
import { PaymentMethodSelect } from "../PaymentMethodSelect";
import { PaymentSuccessModal } from "../PaymentSuccessModal";
import {
  PaymentFormValue,
  PaymentFormValues,
  rules
} from "./PaymentForm.rules";

export interface PaymentFormProps {
  invoices: Invoice[];
  client: Client;
}

export const PaymentForm = ({ invoices, client }: PaymentFormProps) => {
  useEffect(() => {
    // to update UI when new data comes
  }, [invoices, client]);

  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const { addPayment } = useDBServices().invoicesDBService.payments;

  const [paymentResult, setPaymentResult] = useState<
    Payment | null | undefined
  >(undefined);

  const notificationId = "submit-payment";

  // console.log("paymentResultId");
  // console.log(paymentResultId);
  // console.log("paymentResult");
  // console.log(paymentResult);

  const items = invoices
    .map((i) => {
      let obj: PaymentFormValue = {
        paymentAmount: 0,
        include: true,
        showInput: i.balanceDue > 0,
        invoiceId: i.id,
        balanceDue: i.balanceDue,
        dateIssued: i.createdAt,
        total: i.total,
        paid: i.paid,
        refunded: i.refunded,
        createdAt: i.createdAt,
        serialNumber: i.serialNumber,
      };
      obju.removeUndefined(obj);
      return obj;
    })
    .reduce(
      (obj: { [x: string]: PaymentFormValue }, item: PaymentFormValue) => {
        obj[item.invoiceId] = item;
        return obj;
      },
      {}
    );

  const form = useForm<PaymentFormValues>({
    initialValues: {
      type: "INVOICE_PAYMENT",
      creditAmount: 0,
      totalPaymentAmount: 0,
      totalInvoicesPaymentAmounts: 0,
      paymentMethod: "CASH",
      payToCreditBalance: false,
      invoices: items,
      clientId: client.id,
      clientName: client.name,
      status: "PENDING",
      change: 0,
    },
    validate: rules,
  });

  // calc

  // const originalTotal = invoices.reduce(
  //   (acc, invoice) => acc + invoice.balanceDue,
  //   0
  // );

  const valuesArr = Object.values(form.values.invoices);

  const totalInvoicesPaymentAmounts = valuesArr.reduce(
    (acc, item) => acc + (item.include ? item.paymentAmount : 0),
    0
  );

  const payToCreditBalance =
    form.values.paymentMethod === "CREDIT_BALANCE"
      ? true
      : form.values.payToCreditBalance;

  const paymentType: PaymentType =
    payToCreditBalance && totalInvoicesPaymentAmounts === 0
      ? "CREDIT_BALANCE_PAYMENT"
      : "INVOICE_PAYMENT";

  const creditAmount = payToCreditBalance
    ? form.values.paymentMethod === "CREDIT_BALANCE"
      ? -totalInvoicesPaymentAmounts
      : form.values.totalPaymentAmount - totalInvoicesPaymentAmounts
    : 0;

  const change = form.values.payToCreditBalance
    ? 0
    : form.values.totalPaymentAmount - totalInvoicesPaymentAmounts;

  const disbleCheckout =
    (totalInvoicesPaymentAmounts === 0 && !payToCreditBalance) ||
    (totalInvoicesPaymentAmounts === 0 &&
      form.values.paymentMethod === "CREDIT_BALANCE") ||
    form.values.totalPaymentAmount <= 0 ||
    totalInvoicesPaymentAmounts > form.values.totalPaymentAmount ||
    submitState === "success";

  const isNotCreditBalancePayment =
    form.values.paymentMethod !== "CREDIT_BALANCE";

  // table
  const currency = org_params_util.getOrgParams().currency;

  const fields: {
    key: keyof PaymentFormValue;
    label: string;
  }[] = [
    { key: "createdAt", label: t("CREATED_AT") },
    { key: "invoiceId", label: t("INVOICE_ID") },
    { key: "total", label: t("TOTAL") },
    { key: "balanceDue", label: t("BALANCE_DUE") },
  ];

  const getRow = (value: PaymentFormValue) => (
    <tr key={value.invoiceId}>
      <td>
        <Text>{tu.getLocaleDateStringFromEpoch(value.createdAt)}</Text>
      </td>
      <td>{value.invoiceId.slice(0, 8).toUpperCase()}</td>
      <td>
        <Text color="red">
          {mu.toD(value.total)} {t(currency)}
        </Text>
      </td>
      <td>
        <Text color="red">
          {mu.toD(value.balanceDue)} {t(currency)}
        </Text>
      </td>

      <td>
        {value.showInput && (
          <Group>
            <BMoneyInput
              min={0}
              disabled={!value.include}
              max={Math.min(
                form.values.totalPaymentAmount,
                form.values.totalPaymentAmount -
                  (totalInvoicesPaymentAmounts -
                    (form.values.invoices[value.invoiceId].paymentAmount ?? 0)),
                value.balanceDue
              )}
              {...form.getInputProps(
                `invoices.${value.invoiceId}.paymentAmount`
              )}
              onChange={(v) => {
                // TODO fix me
                if (v === "") {
                  form.setFieldValue(
                    `invoices.${value.invoiceId}.paymentAmount`,
                    0
                  );
                } else if (
                  v <= value.balanceDue &&
                  v <= form.values.totalPaymentAmount
                ) {
                  form.setFieldValue(
                    `invoices.${value.invoiceId}.paymentAmount`,
                    v
                  );
                } else {
                  form.setFieldValue(
                    `invoices.${value.invoiceId}.paymentAmount`,
                    Math.min(
                      form.values.totalPaymentAmount,
                      form.values.totalPaymentAmount -
                        (totalInvoicesPaymentAmounts -
                          (form.values.invoices[value.invoiceId]
                            .paymentAmount ?? 0)),
                      value.balanceDue
                    )
                  );
                }
              }}
            />
            <Checkbox
              {...form.getInputProps(`invoices.${value.invoiceId}.include`, {
                type: "checkbox",
              })}
            />
          </Group>
        )}
      </td>
    </tr>
  );

  const submit = async (values: PaymentFormValues) => {
    const items = Object.values(values.invoices)
      .filter((i) => i.include)
      .filter((i) => i.paymentAmount !== 0);

    let amounts: {
      [x: string]: PaymentInvoiceData;
    } = {};

    items.forEach((i) => {
      obju.removeUndefined(i);
      amounts[i.invoiceId] = i;
    });

    const newPayment: PaymentData = {
      ...values,
      invoices: amounts,
      type: paymentType,
      creditAmount,
      payToCreditBalance,
      totalInvoicesPaymentAmounts,
      change,
    };

    // console.log(newPayment);

    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("CHECK_OUT_PAYMENT"),
      })
    );
    setSubmitState("pending-response");
    const result = await addPayment(newPayment);
    // console.log("result");
    // console.log(result);

    setPaymentResult(result.payload);
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
    setSubmitState(result.type === "success"?"success":"error")
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Container fluid>
        <ImportantInfoCard client={client} />
        <Stack mt="xl">
          <Group>
            <MoveBackButton />
          </Group>
          <Group align="flex-start">
            <BBox
              header={
                <Group>
                  <Text weight={500}>{t("NEW_PAYMENT")}</Text>
                </Group>
              }
            >
              <Stack
                p="xl"
                sx={{
                  flexGrow: 5,
                }}
              >
                <Group>
                  <PaymentMethodSelect
                    disableCredit={
                      client.creditBalance < totalInvoicesPaymentAmounts ||
                      client.creditBalance < form.values.totalPaymentAmount
                    }
                    {...form.getInputProps("paymentMethod")}
                  />
                  <BMoneyInput
                    required
                    min={0}
                    max={
                      form.values.paymentMethod === "CREDIT_BALANCE"
                        ? client.creditBalance
                        : undefined
                    }
                    label={t("PAYMENT_AMOUNT")}
                    {...form.getInputProps("totalPaymentAmount")}
                    onChange={(v) => {
                      if (v === "") {
                        form.setFieldValue(`totalPaymentAmount`, 0);
                      } else if (
                        form.values.paymentMethod !== "CREDIT_BALANCE"
                      ) {
                        if (v >= totalInvoicesPaymentAmounts) {
                          form.setFieldValue(`totalPaymentAmount`, v);
                        } else {
                          form.setFieldValue(
                            `totalPaymentAmount`,
                            totalInvoicesPaymentAmounts
                          );
                        }
                      } else {
                        if (
                          v >= totalInvoicesPaymentAmounts &&
                          v <= client.creditBalance
                        ) {
                          form.setFieldValue(`totalPaymentAmount`, v);
                        } else if (v >= totalInvoicesPaymentAmounts) {
                          form.setFieldValue(
                            `totalPaymentAmount`,
                            client.creditBalance
                          );
                        }
                      }
                    }}
                  />
                </Group>

                <Text mt="xl" weight={500}>
                  {t("INVOICES")}
                </Text>
                <TableWithSearchAndSort
                  data={valuesArr}
                  fields={fields}
                  getRow={getRow}
                  hasControlsColumn
                />
                <Textarea
                  mt="xl"
                  label={t("PAYMENT_NOTE")}
                  placeholder={t("ADD_A_NOTE")}
                  {...form.getInputProps("note")}
                />
              </Stack>
            </BBox>
            <Stack
              sx={{
                flexGrow: 1,
              }}
            >
              <Card withBorder radius="xs">
                <Group grow>
                  <Stack spacing={0}>
                    <Text>{t("OUTSTANDING_BALANCE")}</Text>
                    <Text color="red">
                      {mu.toD(client.outstandingBalance)} {t(currency)}
                    </Text>
                  </Stack>
                  <Stack spacing={0}>
                    <Text>{t("CREDIT_BALANCE")}</Text>
                    <Text color="green">
                      {mu.toD(client.creditBalance)} {t(currency)}
                    </Text>
                  </Stack>
                </Group>
              </Card>
              <Card withBorder radius="xs">
                <Stack w="100%">
                  <Group position="apart">
                    {" "}
                    <Text weight={500}>{t("SUMMARY")}</Text>
                    {paymentResult && paymentResult.status === "SUCCESS" && (
                      <PaymentSuccessModal payment={paymentResult} />
                    )}
                  </Group>

                  <Group position="apart">
                    <Text>{t("PAYMENT_AMOUT")}</Text>
                    <Text>
                      {mu.toD(form.values.totalPaymentAmount)} {t(currency)}
                    </Text>
                  </Group>
                  {isNotCreditBalancePayment ? (
                    <div>
                      <Group position="apart">
                        <Text>{t("CHANGE_DUE")}</Text>
                        <Text>
                          {mu.toD(
                            form.values.payToCreditBalance
                              ? creditAmount
                              : change
                          )}{" "}
                          {t(currency)}
                        </Text>
                      </Group>
                      <Checkbox
                        mt="sm"
                        label={t("APPLY_CHANGE_TO_CREDIT_BALANCE")}
                        disabled={
                          form.values.totalPaymentAmount -
                            totalInvoicesPaymentAmounts <=
                          0
                        }
                        {...form.getInputProps(`payToCreditBalance`, {
                          type: "checkbox",
                        })}
                      />
                    </div>
                  ) : (
                    form.values.paymentMethod !== "CREDIT_BALANCE" && (
                      <Group position="apart">
                        <Text>{t("CREDIT_AMOUNT")}</Text>
                        <Text>
                          {mu.toD(creditAmount)} {t(currency)}
                        </Text>
                      </Group>
                    )
                  )}

                  <Button
                    disabled={disbleCheckout}
                    loading={submitState === "pending-response"}
                    type="submit"
                  >
                    {t("CHECK_OUT")}
                  </Button>
                </Stack>
              </Card>
            </Stack>
          </Group>
        </Stack>
      </Container>
    </form>
  );
};
