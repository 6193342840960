import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { OrgPermissions, ServiceProvider } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectServiceProviders } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type ServiceProvidersTableItem = ServiceProvider & { actions?: undefined };

export const ServiceProvidersTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const serviceProviders = useAppSelector(selectServiceProviders);

  const tableItems: ServiceProvidersTableItem[] = serviceProviders.map(
    (serviceProvider) => ({
      ...serviceProvider,
    })
  );

  const {
    deleteServiceProvider,
    archiveServiceProvider,
    dearchiveServiceProvider,
  } = useDBServices().scheduleSettingsDBService.serviceProviders;

  const columns: BDataTableColumn<ServiceProvidersTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "color",
      title: <Text>{t("COLOR")}</Text>,
      sortable: true,
      render: ({ color }) => (
        <Box
          sx={{
            backgroundColor: color,
            borderRadius: 10,
            width: 20,
            height: 20,
          }}
        ></Box>
      ),
    },
    {
      accessor: "code",
      title: <Text>{t("CODE")}</Text>,
      sortable: true,
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (serviceProvider) => (
        <BCan I="do" a={OrgPermissions.SCHEDULE_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${serviceProvider.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {serviceProvider.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () =>
                  dearchiveServiceProvider(serviceProvider.id, serviceProvider)
                }
                item={serviceProvider}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () =>
                  archiveServiceProvider(serviceProvider.id, serviceProvider)
                }
                item={serviceProvider}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteServiceProvider(serviceProvider.id)}
              item={serviceProvider}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
