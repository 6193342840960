import {
  errorResult,
  InventoryItemLocation,
  InventoryItemLocationData,
  INVENTORY_ITEM_LOCATION_VERSION,
  normalizeInventoryItemLocation,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization inventoryItemLocation

interface AddInventoryItemLocation {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: InventoryItemLocationData;
  }): Promise<Result<InventoryItemLocation | null>>;
}

const addInventoryItemLocation: AddInventoryItemLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new inventoryItemLocation id
    const newInventoryItemLocationRef = doc(
      collection(
        db,
        "organizations",
        organizationId,
        "inventory_item_locations"
      )
    );

    const newInventoryItemLocation: InventoryItemLocation =
      normalizeInventoryItemLocation({
        ...data,
        id: newInventoryItemLocationRef.id,
        authorId,
        authorName,
        version: INVENTORY_ITEM_LOCATION_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

    await setDoc(newInventoryItemLocationRef, newInventoryItemLocation);

    // t("INVENTORY_LOCATION_CREATED")
    const successMessage = "INVENTORY_LOCATION_CREATED";
    return successResult({
      message: successMessage,
      payload: newInventoryItemLocation,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetInventoryItemLocation {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<InventoryItemLocation | null>>;
}

const getInventoryItemLocation: GetInventoryItemLocation = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const inventoryItemLocationDocRef = doc(
      db,
      "organizations",
      organizationId,
      "inventory_item_locations",
      id
    );
    const inventoryItemLocationDocSnapshot = await getDoc(
      inventoryItemLocationDocRef
    );
    if (inventoryItemLocationDocSnapshot.exists()) {
      try {
        const data: unknown = inventoryItemLocationDocSnapshot.data();
        const inventoryItemLocation: InventoryItemLocation =
          normalizeInventoryItemLocation(data);
        // t("INVENTORY_LOCATION_FOUND")
        const successMessage = "INVENTORY_LOCATION_FOUND";
        return successResult({
          message: successMessage,
          payload: inventoryItemLocation,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("INVENTORY_LOCATION_NOT_FOUND")
      const errorMessage = "INVENTORY_LOCATION_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditInventoryItemLocation {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: InventoryItemLocationData;
  }): Promise<Result<InventoryItemLocation | null>>;
}

const editInventoryItemLocation: EditInventoryItemLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const inventoryItemLocation = (
      await getInventoryItemLocation({ db, organizationId, id })
    ).payload;

    if (!inventoryItemLocation) {
      // t("INVENTORY_LOCATION_NOT_FOUND")
      const errorMessage = "INVENTORY_LOCATION_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = inventoryItemLocation;
      const updatedInventoryItemLocation: InventoryItemLocation =
        normalizeInventoryItemLocation({
          ...data,
          id,
          authorId,
          authorName,
          version: INVENTORY_ITEM_LOCATION_VERSION,
          source,
          createdAt,
          lastUpdatedAt: tu.getCurrentDateTime(),
        });

      const inventoryItemLocationRef = doc(
        db,
        "organizations",
        organizationId,
        "inventory_item_locations",
        id
      );

      await setDoc(inventoryItemLocationRef, updatedInventoryItemLocation);

      // t("INVENTORY_LOCATION_UPDATED")
      const successMessage = "INVENTORY_LOCATION_UPDATED";
      // console.log(successMessage, updatedInventoryItemLocation);
      return successResult({
        message: successMessage,
        payload: updatedInventoryItemLocation,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteInventoryItemLocation {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteInventoryItemLocation: DeleteInventoryItemLocation = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "inventory_item_locations",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("INVENTORY_LOCATION_DELETED")
      const successMessage = "INVENTORY_LOCATION_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveInventoryItemLocation: EditInventoryItemLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: InventoryItemLocationData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editInventoryItemLocation({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("INVENTORY_LOCATION_ARCHIVED")
    const successMessage = "INVENTORY_LOCATION_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveInventoryItemLocation: EditInventoryItemLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: InventoryItemLocationData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editInventoryItemLocation({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("INVENTORY_LOCATION_DEARCHIVED")
    const successMessage = "INVENTORY_LOCATION_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization inventoryItemLocations listener
interface GetInventoryItemLocationsListenerCallback {
  (inventoryItemLocations: InventoryItemLocation[]): void;
}
interface GetInventoryItemLocationsListener {
  db: Firestore;
  organizationId: string;
  callback: GetInventoryItemLocationsListenerCallback;
}

const getInventoryItemLocationsListener = ({
  db,
  organizationId,
  callback,
}: GetInventoryItemLocationsListener): Unsubscribe => {
  try {
    // console.log("getInventoryItemLocationsListener: new listener");
    const inventoryItemLocationsQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "inventory_item_locations"
      ),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(inventoryItemLocationsQuery, (querySnapshot) => {
      const inventoryItemLocations: InventoryItemLocation[] = [];
      querySnapshot.forEach((doc) => {
        try {
          inventoryItemLocations.push(
            normalizeInventoryItemLocation(doc.data())
          );
        } catch (err) {
          console.log(err);
        }
      });
      callback(inventoryItemLocations);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const inventoryItemLocations = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getInventoryItemLocationsListener: (
      callback: GetInventoryItemLocationsListenerCallback
    ) =>
      getInventoryItemLocationsListener({
        db,
        organizationId,
        callback,
      }),
    getInventoryItemLocation: (id: string) =>
      getInventoryItemLocation({
        db,
        organizationId,
        id,
      }),
    addInventoryItemLocation: (data: InventoryItemLocationData) =>
      addInventoryItemLocation({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editInventoryItemLocation: (id: string, data: InventoryItemLocationData) =>
      editInventoryItemLocation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteInventoryItemLocation: (id: string) =>
      deleteInventoryItemLocation({
        db,
        organizationId,
        id,
      }),
    archiveInventoryItemLocation: (
      id: string,
      data: InventoryItemLocationData
    ) =>
      archiveInventoryItemLocation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveInventoryItemLocation: (
      id: string,
      data: InventoryItemLocationData
    ) =>
      dearchiveInventoryItemLocation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
