import {
  ActionIcon,
  Affix,
  Anchor,
  Group,
  Loader,
  Popover,
  rem,
  ScrollArea,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import {
  IconExternalLink,
  IconMessageChatbot,
  IconSend,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { errorNotification } from "beitary-shared";
import { selectActiveUser } from "features/Authentication/AuthenticationSlice";
import { selectOrganization } from "features/Organization/Organization.slice";
import { useSubmitState } from "hooks/useSubmitState";
import { marked } from "marked";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "services/api/api";

export const HelpFloatingButton = () => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [submitState, setSubmitState] = useSubmitState();
  const [question, setQuestion] = useState("");

  const [message, setMessage] = useState<string | null>(null);
  const [sources, setSources] = useState<string[]>([]);

  const user = useAppSelector(selectActiveUser);
  const org = useAppSelector(selectOrganization);

  if (!user || !org) throw new Error("NEED_ORG_AND_USER");

  const submit = async () => {
    setSubmitState("pending-response");
    if (question.length === 0) return;
    const result = await api.getHelp({
      question,
      organization_id: org.id,
      organization_name: org.name,
      user_id: user.uid,
      user_name: user.displayName ?? "",
      language: org.language,
    });

    if (result.type === "success" && result.payload) {
      setSubmitState("success");
      setMessage(result.payload.message);
      setSources(result.payload.sources);
    } else {
      showNotification(
        errorNotification({
          message: t("ERROR_GENERATING_REPORT"),
        })
      );
      setSubmitState("error");
      return;
    }
  };

  // Function to convert Markdown text to HTML using marked
  const renderMarkdown = (text: string) => {
    return { __html: marked(text) };
  };

  return (
    <>
      <Group position="center">
        <Popover
          width={400}
          trapFocus
          position="bottom"
          withArrow
          shadow="md"
          opened={opened}
          // onChange={setOpened}
        >
          <Popover.Dropdown
            mah={400}
            sx={(theme) => ({
              borderTop: `20px solid ${theme.colors.cyan[5]}`,
              background:
                theme.colorScheme === "dark"
                  ? theme.colors.dark[7]
                  : theme.white,
            })}
          >
            <Stack>
              <ScrollArea h={276} p="md">
                {(submitState === "pending-response" ||
                  submitState === "not-submitted") && (
                  <Text color="dimmed" m="xl">
                    {t(
                      "THIS_IS_YOUR_HELP_ASSISTANT_ASK_IT_ANYTHING_YOU_WANT_ABOUT_THE_PLATFORM"
                    )}
                  </Text>
                )}
                {submitState === "pending-response" && (
                  <Loader variant="dots" />
                )}
                {submitState !== "pending-response" && message !== null && (
                  <>
                    <div dangerouslySetInnerHTML={renderMarkdown(message)} />
                    <Text mt="xl" weight="bold" size="xs">
                      {t("RESOURCES")}
                    </Text>
                    <div>
                      {sources.map((s) => (
                        <Anchor
                          href={`https://help.beitary.com${s}`}
                          target="_blank"
                        >
                          <Group spacing="xs">
                            <Text
                              w={300}
                              truncate
                            >{`https://help.beitary.com${s}`}</Text>
                            <IconExternalLink size={12} />
                          </Group>
                        </Anchor>
                      ))}
                    </div>
                  </>
                )}
              </ScrollArea>
              <Group>
                <Textarea
                  w={300}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <ActionIcon
                  // variant="filled"
                  radius={24}
                  size={48}
                  color="cyan"
                  onClick={submit}
                  // loading={submitState === "pending-response"}
                >
                  <IconSend />
                </ActionIcon>
              </Group>
            </Stack>
          </Popover.Dropdown>
          <Popover.Target>
            <Affix position={{ bottom: rem(10), right: rem(10) }}>
              <ActionIcon
                variant="filled"
                radius={24}
                size={48}
                color="cyan"
                onClick={() => setOpened(!opened)}
              >
                <IconMessageChatbot size={30} />
              </ActionIcon>
            </Affix>
          </Popover.Target>
        </Popover>
      </Group>
    </>
  );
};
