import { Divider, Group, Stack, Switch, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  loadingInfoNotification,
  normalizeOrganization,
} from "beitary-shared";
import { BBox, CancelButton, SaveButton } from "components";
import {
  selectOrganization,
  setOrganization,
} from "features/Organization/Organization.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { rules, SettingsFormValues } from "./SettingsForm.rules";

export const SettingsForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { updateOrganizationPartial, getCurrentOrganization } =
    useDBServices().organizationDBService;
  const [submitState, setSubmitState] = useSubmitState();

  const org = useAppSelector(selectOrganization);

  if (!org) throw new Error("NO_ORG");

  const form = useForm<SettingsFormValues>({
    initialValues: normalizeOrganization(org),
    validate: rules,
  });

  const updateOrgSlice = async () => {
    const organization = await getCurrentOrganization();
    dispatch(setOrganization(organization.payload));
  };

  const submit = async (values: SettingsFormValues) => {
    const notificationId = "submit-clinicgeneralSettings";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_CLINIC_SETTINGS"),
      })
    );
    setSubmitState("pending-response");
    const result = await updateOrganizationPartial(org.id, {
      advancedSettings: values.advancedSettings,
    });
    if (result.type === "success") {
      await updateOrgSlice();
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <Stack>
      <BBox
        header={
          <Group>
            <Text weight={500}>{t("UPDATE_STTINGS")}</Text>
          </Group>
        }
      >
        <form
          id="generalSettings-form"
          onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}
        >
          <Stack p="xl" spacing="xl">
            <Group grow>
              <Stack p="xl" spacing="xl">
                <Text weight={500}>{t("APPOINTMENT_NOTIFICATIONS")}</Text>
                <Group position="apart">
                  <Text>{t("SEND_APT_EMAIL_NOTIFICATION")}</Text>
                  <Switch
                    {...form.getInputProps(
                      "advancedSettings.sendEmailAptNotif",
                      {
                        type: "checkbox",
                      }
                    )}
                  />
                </Group>

                <Group position="apart">
                  <Text>{t("SEND_APT_SMS_NOTIFICATION")}</Text>
                  <Switch
                    {...form.getInputProps("advancedSettings.sendSMSAptNotif", {
                      type: "checkbox",
                    })}
                  />
                </Group>
                <Divider />
                <Text weight={500}>{t("REMINDERS_NOTIFICATIONS")}</Text>
                <Group position="apart">
                  <Text>{t("SEND_REMINDER_EMAIL_NOTIFICATION")}</Text>
                  <Switch
                    {...form.getInputProps(
                      "advancedSettings.sendEmailReminderNotif",
                      { type: "checkbox" }
                    )}
                  />
                </Group>
                <Group position="apart">
                  <Text>{t("SEND_REMINDER_SMS_NOTIFICATION")}</Text>
                  <Switch
                    {...form.getInputProps(
                      "advancedSettings.sendSMSReminderNotif",
                      { type: "checkbox" }
                    )}
                  />
                </Group>
              </Stack>
              <Stack p="xl" spacing="xl">
                <Text weight={500}>{t("CLIENT_CREATED_NOTIFICATIONS")}</Text>
                <Group position="apart">
                  <Text>{t("SEND_EMAIL_NOTIFICATION_ON_CLIENT_CREATE")}</Text>
                  <Switch
                    {...form.getInputProps(
                      "advancedSettings.sendEmailNotifOnClientCreate",
                      { type: "checkbox" }
                    )}
                  />
                </Group>
                <Group position="apart">
                  <Text>{t("SEND_SMS_NOTIFICATION_ON_CLIENT_CREATE")}</Text>
                  <Switch
                    {...form.getInputProps(
                      "advancedSettings.sendSMSNotifOnClientCreate",
                      { type: "checkbox" }
                    )}
                  />
                </Group>
                <Divider />
                <Text weight={500}>{t("PATIENT_CREATED_NOTIFICATIONS")}</Text>
                <Group position="apart">
                  <Text>{t("SEND_EMAIL_NOTIFICATION_ON_PATIENT_CREATE")}</Text>
                  <Switch
                    {...form.getInputProps(
                      "advancedSettings.sendEmailNotifOnPatientCreate",
                      { type: "checkbox" }
                    )}
                  />
                </Group>
                <Group position="apart">
                  <Text>{t("SEND_SMS_NOTIFICATION_ON_PATIENT_CREATE")}</Text>
                  <Switch
                    {...form.getInputProps(
                      "advancedSettings.sendSMSNotifOnPatientCreate",
                      { type: "checkbox" }
                    )}
                  />
                </Group>
              </Stack>
            </Group>
            {/* <Group grow align="flex-start" spacing="xl">
            <LanguageSelect required {...form.getInputProps("language")} />
            <CurrencySelect required {...form.getInputProps("currency")} />
            <TemperatureUnitSelect
              required
              {...form.getInputProps("temperatureUnit")}
            />
            <TimezoneSelect required {...form.getInputProps("timeZone")} />
          </Group> */}
          </Stack>
        </form>
      </BBox>
      <Group position="right">
        <CancelButton />
        <SaveButton
          state={submitState}
          canSave={form.isValid()}
          formId="generalSettings-form"
        />
      </Group>
    </Stack>
  );
};
