import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Group,
  Loader,
  Table,
  Text,
} from "@mantine/core";
import { IconExternalLink, IconRefresh } from "@tabler/icons-react";
import { id_util, Invoice, tu } from "beitary-shared";
import { BBox } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { mu } from "utils/money_utils";
import { org_params_util } from "utils/org_params_utils";

type InvoicesTableItem = Invoice & { actions?: undefined };

export interface InvoicesTableProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
}

// started chatgpt opt
export const InvoicesTable = ({ clientId, patientId }: InvoicesTableProps) => {
  const { t } = useTranslation();

  const currency = org_params_util.getOrgParams().currency;

  const [startAfterTime, setStartAfterTime] = useState<number | undefined>(
    undefined
  );
  const [startBeforeTime, setStartBeforeTime] = useState<number | undefined>(
    undefined
  );

  const { getInvoices } = useDBServices().invoicesDBService.invoices;

  const [invoices, setInvoices] = useState<
    undefined | null | InvoicesTableItem[]
  >(undefined);

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [refresh, setRefresh] = useState(id_util.newId36());

  // console.log(invoices);

  const handleNextPage = () => {
    fetchInvoices({ startAfterTime });
  };

  const handlePreviousPage = () => {
    fetchInvoices({ startBeforeTime });
  };

  interface fetchInvoicesProps {
    startAfterTime?: number;
    startBeforeTime?: number;
    startAtTime?: number;
    showComplete?: boolean;
  }
  const fetchInvoices = useCallback(
    async ({
      startAfterTime,
      startBeforeTime,
      showComplete,
      startAtTime,
    }: fetchInvoicesProps) => {
      setRefreshing(true);
      const props = {
        showComplete,
        startAfterTime,
        startBeforeTime,
        startAtTime,
        clientId,
        patientId,
      };
      const result = await getInvoices(props);

      setRefreshing(false);
      // ... handle pagination ...
      if (result.payload && result.payload.length > 0) {
        setInvoices(result.payload);
        setStartAfterTime(result.payload[result.payload.length - 1].createdAt);
        setStartBeforeTime(result.payload[0].createdAt);
      } else {
        // // No more data to fetch
        if (startAfterTime) setStartAfterTime(undefined);
        if (startBeforeTime) setStartBeforeTime(undefined);
      }
    },
    [clientId, getInvoices, patientId]
  );

  useEffect(() => {
    fetchInvoices({});
  }, [fetchInvoices, refresh]);

  const getRow = useCallback(
    (invoice: Invoice) => {
      const { id, clientName, createdAt, status, type } = invoice;
      const balanceDue = invoice.balanceDue;
      return (
        <tr key={id}>
          <td>
            <Text>{tu.getDateAndTimeString(createdAt)}</Text>
          </td>
          <td>
            <Text>{clientName}</Text>
          </td>
          <td>
            <Text>{t(type)}</Text>
          </td>
          <td>
            <Text
              color={
                balanceDue === 0 ? "dark" : balanceDue < 0 ? "green" : "red"
              }
            >
              {mu.toD(balanceDue)} {t(currency)}
            </Text>
          </td>
          <td>
            <Text>{t(status)}</Text>
          </td>
          <td>
            <Anchor
              color="dark"
              weight={400}
              lineClamp={1}
              component={Link}
              to={`/invoices/edit/${id}`}
            >
              {<IconExternalLink size={12} />}
            </Anchor>
          </td>
        </tr>
      );
    },
    [currency, t]
  );

  const rows = useMemo(
    () => (invoices ?? []).map((a) => getRow(a)),
    [invoices, getRow]
  );

  return invoices === null ? (
    <Text>{t("ERROR")}</Text>
  ) : (
    <BBox
      header={
        <Group position="apart">
          <Text>{t("INVOICES")}</Text>
          <Group>
            <ActionIcon
              onClick={() => {
                setRefresh(id_util.newId36());
              }}
            >
              {refreshing ? <Loader size="xs" /> : <IconRefresh size={18} />}
            </ActionIcon>
          </Group>
        </Group>
      }
    >
      <Box p="xl">
        <Table>
          <thead>
            <tr>
              <th>{t("DATE")}</th>
              <th>{t("CLIENT")}</th>
              <th>{t("TYPE")}</th>
              <th>{t("BALANCE_DUE")}</th>
              <th>{t("STATUS")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <Group position="right" mt="xl">
          <Button onClick={handlePreviousPage} disabled={!startBeforeTime}>
            {t("PREVIOUS")}
          </Button>
          <Button onClick={handleNextPage} disabled={!startAfterTime}>
            {t("NEXT")}
          </Button>
        </Group>
      </Box>
    </BBox>
  );
};
