import { zodResolver } from "@mantine/form";
import { reminderSnapshotDataSchema } from "beitary-shared";
import { z } from "zod";

const reminderSnapshotFormSchema = reminderSnapshotDataSchema
  // this is just for form to work
  .merge(
    z.object({
      notifications: z
        .array(
          z.object({
            id: z.string(),
            value: z.number().int().positive(),
          })
        )
        .optional(),
    })
  )
  .omit({ organizationId: true });

export type ReminderSnapshotFormValues = z.infer<
  typeof reminderSnapshotFormSchema
>;

export const rules = zodResolver(reminderSnapshotFormSchema);
