import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { PhysicalExamTemplate } from "beitary-shared";
interface TemplatesState {
  physicalExamTemplates: PhysicalExamTemplate[];
}

const initialState: TemplatesState = {
  physicalExamTemplates: [],
};

export const templatesSlice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    setPhysicalExamTemplates: (
      state,
      action: PayloadAction<PhysicalExamTemplate[]>
    ) => {
      state.physicalExamTemplates = action.payload;
    },
  },
});

export const { setPhysicalExamTemplates } = templatesSlice.actions;

export const selectPhysicalExamTemplates = (state: RootState) =>
  state.templates.physicalExamTemplates;

export const selectActivePhysicalExamTemplates = (state: RootState) =>
  state.templates.physicalExamTemplates.filter((t) => t.status === "ACTIVE");

export const templatesReducer = templatesSlice.reducer;
