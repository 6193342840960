import { Button, Modal } from "@mantine/core";
import { PurchaseOrder } from "beitary-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PurchaseOrderForm } from "../PurchaseOrderForm";

export interface EditPurchaseOrderProps {
  callback?: () => void;
  purchaseOrder: PurchaseOrder;
}

export const EditPurchaseOrder = ({
  callback,
  purchaseOrder,
}: EditPurchaseOrderProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={() => setOpened(false)}
        title={t("EDIT")}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <PurchaseOrderForm callback={callback} purchaseOrder={purchaseOrder} />
      </Modal>
      <Button onClick={() => setOpened(true)}>{t("EDIT")}</Button>
    </>
  );
};
