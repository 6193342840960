import { Source } from "beitary-shared";
import { dbInstance } from "services/firebase/FirebaseService";
import { catalog } from "./catalog";
import { clientCommunication } from "./client-communication/client-communication";
import { clients } from "./clients/clients";
import { clinicSettings } from "./clinic-settings";
import { consultations } from "./consultations";
import { diagnoses } from "./diagnoses";
import { imagingDiagnostics } from "./diagnostics";
import { estimates } from "./estimates/estimates";
import { files } from "./files";
import { inventory } from "./inventory";
import { invoices } from "./invoices";
import { members } from "./members/members";
import { organization } from "./organization/organization";
import { patients } from "./patients/patients";
import { prescriptions } from "./prescriptions";
import {
  appointmentRequests,
  appointments,
  boardingAppointments,
} from "./schedule";
import { scheduleSettings } from "./schedule-settings/schedule-settings";
import { tasks } from "./tasks";
import { templates } from "./templates";

export const DBService = (
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => ({
  filesDBService: files({
    db: dbInstance,
    organizationId,
    authorId,
    authorName,
    source,
  }),
  consultationsDBService: consultations(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  prescriptionsDBService: prescriptions({
    db: dbInstance,
    organizationId,
    authorId,
    authorName,
    source,
  }),
  invoicesDBService: invoices(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  appointmentsDBService: appointments(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  boardingAppointmentsDBService: boardingAppointments(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  appointmentRequestsDBService: appointmentRequests(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  scheduleSettingsDBService: scheduleSettings(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  templatesDBService: templates(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  clinicSettingsDBService: clinicSettings(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  inventoryDBService: inventory(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  catalogDBService: catalog(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  diagnosesDBService: diagnoses(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  patientsDBService: patients(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  clientsDBService: clients(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  organizationDBService: organization(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  organizationMembersDBService: members(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  tasksDBService: tasks(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  estimatesDBService: estimates(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),
  clientCommunication: clientCommunication(
    dbInstance,
    organizationId,
    authorId,
    authorName,
    source
  ),

  imagingDiagnosticItemsDBService: imagingDiagnostics({
    db: dbInstance,
    organizationId,
    authorId,
    authorName,
    source,
  }),
});
