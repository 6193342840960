import {
  Client,
  ClientData,
  CLIENT_VERSION,
  errorResult,
  id_util,
  normalizeClient,
  Patient,
  PatientData,
  PATIENT_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import { ClientsMap } from "features/Clients/Clients.slice";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  query,
  Unsubscribe,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

interface GetClient {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Client | null>>;
}

const getClient: GetClient = async ({ db, organizationId, id }) => {
  try {
    const clientDocRef = doc(
      db,
      "organizations",
      organizationId,
      "clients",
      id
    );
    const clientDocSnapshot = await getDoc(clientDocRef);
    if (clientDocSnapshot.exists()) {
      try {
        const data: unknown = clientDocSnapshot.data();
        const client: Client = normalizeClient(data);
        const successMessage = "Client found!";
        return successResult({
          message: successMessage,
          payload: client,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "Client not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface AddNewClientAndPatient {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    clientData,
    patientData,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    clientData: ClientData;
    patientData: PatientData;
  }): Promise<Result<{ clientDocPath: string; patientDocPath: string } | null>>;
}

const addNewClientAndPatient: AddNewClientAndPatient = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  clientData,
  patientData,
}) => {
  try {
    const batch = writeBatch(db);

    const newClientId = id_util.newIdUpperNo016();

    const newClientRef = doc(
      db,
      "organizations",
      organizationId,
      "clients",
      newClientId
    );

    const clientDocPath = newClientRef.path;

    const newClient: Client = normalizeClient({
      ...clientData,
      id: newClientRef.id,
      authorId,
      authorName,
      version: CLIENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    batch.set(newClientRef, newClient);

    const newPaitentId = id_util.newIdUpperNo016();

    const newPatientRef = doc(
      db,
      "organizations",
      organizationId,
      "patients",
      newPaitentId
    );

    const patientDocPath = newPatientRef.path;

    const newPatient: Patient = {
      ...patientData,
      id: newPatientRef.id,
      ownerId: newClientRef.id,
      authorId,
      authorName,
      version: PATIENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    batch.set(newPatientRef, newPatient);

    await batch.commit();

    // t("CLIENT_AND_PATIENT_ADDED")
    const successMessage = "CLIENT_AND_PATIENT_ADDED";

    return successResult({
      message: successMessage,
      payload: { clientDocPath, patientDocPath },
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface UpdateClient {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<ClientData>;
  }): Promise<Result<Partial<string> | null>>;
}

const updateClient: UpdateClient = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "clients", id);

    const docPath = docRef.path;

    const updates: Partial<Client> = {
      ...data,
      authorId,
      authorName,
      version: CLIENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("CLIENT_UPDATED")
    const successMessage = "CLIENT_UPDATED";

    return successResult({
      message: successMessage,
      payload: docPath,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteClient {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteClient: DeleteClient = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "clients", id);

    try {
      await deleteDoc(docRef);
      // t("CLIENT_DELETED")
      const successMessage = "CLIENT_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// get organization clients listener
interface GetClientsListenerCallback {
  (clientsMap: ClientsMap): void;
}
interface GetClientsListener {
  db: Firestore;
  organizationId: string;
  callback: GetClientsListenerCallback;
}

const getClientsListener = ({
  db,
  organizationId,
  callback,
}: GetClientsListener): Unsubscribe => {
  try {
    // console.log("getClientsListener: new listener");
    const clientsQuery = query(
      collection(db, "organizations", organizationId, "clients")
    );
    return onSnapshot(clientsQuery, (querySnapshot) => {
      const clientsMap: ClientsMap = {};
      querySnapshot.forEach((doc) => {
        try {
          const client = normalizeClient(doc.data());
          clientsMap[client.id] = client;
        } catch (error) {
          console.log(error);
        }
      });
      callback(clientsMap);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const clients = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getClientsListener: (callback: GetClientsListenerCallback) =>
      getClientsListener({
        db,
        organizationId,
        callback,
      }),
    getClient: (id: string) =>
      getClient({
        db,
        organizationId,
        id,
      }),
    deleteClient: (id: string) => deleteClient({ db, id, organizationId }),
    addNewClientAndPatient: ({
      clientData,
      patientData,
    }: {
      clientData: ClientData;
      patientData: PatientData;
    }) =>
      addNewClientAndPatient({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        clientData,
        patientData,
      }),
    updateClient: (id: string, data: Partial<ClientData>) =>
      updateClient({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    archiveClient: (id: string) =>
      updateClient({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),

    dearchiveClient: (id: string) =>
      updateClient({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
