import { Button } from "@mantine/core";
import { IconCircleCheck, IconCircleMinus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export interface SaveButtonProps {
  formId?: string;
  state: string;
  canSave: boolean;
  disabled?: boolean;
}

export const SaveButton = ({
  formId,
  state,
  canSave = false,
  disabled,
}: SaveButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      disabled={disabled}
      type="submit"
      form={formId}
      loading={state === "pending-response"}
      color={canSave ? "green" : "orange"}
      leftIcon={
        canSave ? <IconCircleCheck size={18} /> : <IconCircleMinus size={18} />
      }
    >
      {t("SAVE")}
    </Button>
  );
};
