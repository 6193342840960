import { inventoryTransactionTypeEnum } from "beitary-shared";
import { BSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface TransactionTypeSelectProps {
  required?: boolean;
  onChange?: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  clearable?: boolean;
  fullList: boolean;
}

export const TransactionTypeSelect = (props: TransactionTypeSelectProps) => {
  const { t } = useTranslation();

  const unwantedTypes = [
    "INVENTORY_COUNT",
    "INVENTORY_SURPLUS",
    "INVENTORY_DEFICIT",
    "PRODUCT_ADMINISTRED",
    "PRODUCT_DEADMINISTRED",
    "INVOICE",
    "PURCHASE",
    "PURCHASE_CANCELED",
  ];

  const data = inventoryTransactionTypeEnum.options
    .filter((v) => props.fullList || !unwantedTypes.includes(v))
    .map((o) => ({
      value: o,
      label: t(o),
    }));

  return (
    <BSelect
      {...props}
      label={t("TYPE")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};
