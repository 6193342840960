import { z } from "zod";
import { genericStatusEnum } from "../../../types-schemas/common/status/GenericStatus";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors";

/**
 * schema and types versions
 * from first to last
 */

// v0

const inventoryItemSupplierDataV0Schema = z.object({
  status: genericStatusEnum,
  name: z.string().min(1),
  representativeName: z.string().min(1).optional(),
  representativeEmail: z.string().email().optional(),
  representativePhoneNumber: z.string().min(1).optional(),
  note: z.string().min(1).optional(),
  // address
  address_Line1: z.string().optional(),
  address_Line2: z.string().optional(),
  address_city: z.string().optional(),
  address_stateOrProvince: z.string().optional(),
  address_postalCode: z.string().optional(),
  address_countryCode: z.string().optional(),
});

type InventoryItemSupplierDataV0 = z.infer<
  typeof inventoryItemSupplierDataV0Schema
>;

const inventoryItemSupplierV0Schema = baseRecordSchema.merge(
  inventoryItemSupplierDataV0Schema
);

type InventoryItemSupplierV0 = z.infer<typeof inventoryItemSupplierV0Schema>;

const normalizeInventoryItemSupplierV0 = (data: any): InventoryItemSupplier => {
  try {
    const inventoryItemSupplierV0: InventoryItemSupplierV0 =
      inventoryItemSupplierV0Schema.parse(data);

    const { ...rest } = inventoryItemSupplierV0;

    // here it's same object cause current v is 0
    const inventoryItemSupplier: InventoryItemSupplier = {
      ...rest,
    };
    return inventoryItemSupplier;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const INVENTORY_ITEM_SUPPLIER_VERSION = "0";

export type InventoryItemSupplierData = InventoryItemSupplierDataV0;

export type InventoryItemSupplier = BaseRecord & InventoryItemSupplierData;

export const inventoryItemSupplierDataSchema =
  inventoryItemSupplierDataV0Schema;

export const inventoryItemSupplierSchema = inventoryItemSupplierV0Schema;

export const normalizeInventoryItemSupplier = (
  data: any
): InventoryItemSupplier => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeInventoryItemSupplierV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
