import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { z } from "zod";
import { responseSchema } from "../Response";

/**
 * schema and types versions
 * from first to last
 */

// v0

const reviewDataV0Schema = z.object({
  reviewedId: z.string(),
  content: z.string(),
  rating: z.number().positive().int().max(5),
  language: z.string().optional(),
  response: responseSchema.optional(),
});

type ReviewDataV0 = z.infer<typeof reviewDataV0Schema>;

const reviewV0Schema = baseRecordSchema.merge(reviewDataV0Schema);

type ReviewV0 = z.infer<typeof reviewV0Schema>;

const normalizeReviewV0 = (data: any): Review => {
  try {
    const reviewV0: ReviewV0 = reviewV0Schema.parse(data);

    const { ...rest } = reviewV0;

    // here it's same object cause current v is 0
    const review: Review = {
      ...rest,
    };
    return review;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error: error, data: data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const REVIEW_VERSION = "0";

export type ReviewData = ReviewDataV0;

export type Review = BaseRecord & ReviewData;

export const reviewDataSchema = reviewDataV0Schema;

export const reviewSchema = reviewV0Schema;

export const normalizeReview = (data: any): Review => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeReviewV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
