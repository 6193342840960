import { SConsultation, SFile, SPrescription } from "beitary-shared";
import { LifecycleConsultationItem } from "../LifecycleConsultationItem";
import { LifecycleFileItem } from "../LifecycleFileItem";
import { LifecyclePrescriptionItem } from "../LifecyclePrescriptionItem";

export interface LifecycleItemProps {
  sortedItem: SortedItem;
}

type SortedItem = {
  item: SConsultation | SFile | SPrescription;
  type: "consultation" | "file" | "prescription";
};

export const LifecycleItem = ({ sortedItem }: LifecycleItemProps) => {
  return sortedItem.type === "consultation" ? (
    <LifecycleConsultationItem item={sortedItem.item as SConsultation} />
  ) : sortedItem.type === "file" ? (
    <LifecycleFileItem item={sortedItem.item as SFile} />
  ) : sortedItem.type === "prescription" ? (
    <LifecyclePrescriptionItem />
  ) : (
    <></>
  );
};
