import {
  Button,
  Center,
  Container,
  Group,
  Image,
  PasswordInput,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { logoImg2 } from "assets";
import { useTranslation } from "react-i18next";
import { AuthenticationAPI } from "../AuthenticationAPI";
import { rules, SigninFormValues } from "./SigninForm.rules";

export const Signin = () => {
  const signIn = AuthenticationAPI.signIn;

  const { t } = useTranslation();

  const loginForm = useForm<SigninFormValues>({
    validate: rules,
  });

  const handleSubmit = async (values: SigninFormValues) => {
    // console.log(values);
    if (values.email && values.password)
      await signIn({ email: values.email, password: values.password });
    //      await signInWithPinCode({ pin: "TODO" });
  };

  return (
    <Container fluid h="100vh" bg="white">
      <Center h="85%">
        <Stack align="center">
          <Group spacing="xs" position="center" mb="xl" align="flex-end">
            <Image src={logoImg2} width={280} />
            <Title size={28} color="dimmed">
              Cloud Vet
            </Title>
          </Group>
          <form
            onSubmit={loginForm.onSubmit(handleSubmit, (errors) =>
              console.log(errors)
            )}
          >
            <Stack w={320}>
              <TextInput
                type="email"
                placeholder={t("EMAIL")}
                {...loginForm.getInputProps("email")}
                required
              />
              <PasswordInput
                placeholder={t("PASSWORD")}
                {...loginForm.getInputProps("password")}
                required
              />
              <Button data-testid="signin-btn" type="submit">
                {t("LOGIN")}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Center>
    </Container>
  );
};
