import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Group,
  Input,
  NumberInput,
  Stack,
  Switch,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  obju,
  Prescription,
  Result,
} from "beitary-shared";
import {
  PrescriptionType,
  prescriptionTypeEnum,
} from "beitary-shared/src/types-schemas/consultations/Prescription/Prescription.type";
import {
  BAdvancedRadioGroup,
  BBox,
  BDateInput,
  BTextEditor,
  SaveButton,
} from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DoctorSelect } from "../../../Summary/components/SummaryForm/components";
import { ProductSelect } from "./components";
import { PrecriptionFormValues, rules } from "./PrecriptionForm.rules";

export interface PrecriptionFormProps {
  prescription?: Prescription;
  consultationId: string;
  clientId: string;
  clientName: string;
  patientId: string;
  patientName: string;
  disabled: boolean;
}

export const PrecriptionForm = ({
  prescription,
  consultationId,
  clientId,
  clientName,
  patientId,
  patientName,
  disabled,
}: PrecriptionFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { addPrescription, updatePrescription } =
    useDBServices().prescriptionsDBService;

  const [selectedProduct, setSelectedProduct] = useState<
    | {
        id: string;
        name: string;
        directions?: string;
      }
    | undefined
  >();

  const form = useForm<PrecriptionFormValues>({
    initialValues: prescription
      ? { ...prescription, items: prescription.items.map((i) => ({ ...i })) } // to fix TypeError: Cannot assign to read only property 'directions' of object
      : undefined,
    validate: rules,
  });

  if (!form.values.type) form.setFieldValue("type", "IN_HOUSE");
  if (!form.values.consultationId)
    form.setFieldValue("consultationId", consultationId);
  if (!form.values.clientId) form.setFieldValue("clientId", clientId);
  if (!form.values.clientName) form.setFieldValue("clientName", clientName);
  if (!form.values.patientId) form.setFieldValue("patientId", patientId);
  if (!form.values.patientName) form.setFieldValue("patientName", patientName);

  const submit = async (values: PrecriptionFormValues) => {
    if (disabled) return;
    const notificationId = "submit-prescription";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(prescription ? "UPDATE_PRESCRIPTION" : "ADD_PRESCRIPTION"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<Partial<Prescription> | null>;
    if (prescription) {
      result = await updatePrescription(prescription.id, values);
    } else {
      result = await addPrescription(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            prescription ? (
              <Text weight={500}>{t("EDIT_PRESCRIPTION")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_PRESCRIPTION")}</Text>
            )
          }
        >
          <Stack p="xl">
            <Input.Wrapper required label={t("PRESCRIPTION_TYPE")}>
              <Box>
                <BAdvancedRadioGroup
                  disabled={disabled}
                  value={form.values.type}
                  onChange={(v) =>
                    form.setFieldValue("type", v as PrescriptionType)
                  }
                  data={prescriptionTypeEnum.options.map((type) => ({
                    label: t(type),
                    value: type,
                  }))}
                />
              </Box>
            </Input.Wrapper>
            <DoctorSelect
              disabled={disabled}
              required
              label={t("PRESCRIBING_DOCTOR")}
              {...form.getInputProps("prescribingDrId")}
              onChange={({ id, name }) => {
                form.setFieldValue("prescribingDrId", id);
                form.setFieldValue("prescribingDrName", name);
              }}
            />
            <Group grow align="flex-end">
              <ProductSelect
                required={form.values?.items?.length === 0}
                disabled={disabled}
                value={selectedProduct?.id}
                onChange={(v) => {
                  setSelectedProduct(v);
                }}
              />
              <Button
                disabled={disabled || selectedProduct === undefined}
                onClick={() => {
                  if (!selectedProduct) return;
                  const newItem = {
                    id: id_util.newId20(),
                    isChronic: false,
                    pId: selectedProduct.id,
                    pName: selectedProduct.name,
                    qty: 1,
                    directions: selectedProduct.directions,
                  };
                  obju.removeUndefined(newItem);

                  if (form.values.items) {
                    form.setFieldValue("items", [
                      ...form.values.items,
                      newItem,
                    ]);
                  } else {
                    form.setFieldValue("items", [newItem]);
                  }
                }}
              >
                {t("ADD")}
              </Button>
            </Group>

            {form.values.items?.map((i, index) => (
              <Stack key={index} mt="xl">
                <Divider />
                <Group position="apart">
                  <Text color={"cyan.8"} weight={500}>
                    {i.pName}
                  </Text>
                  <ActionIcon
                    onClick={() =>
                      form.setFieldValue(
                        "items",
                        (form.values.items ?? []).filter(
                          (elem) => elem.id !== i.id
                        )
                      )
                    }
                  >
                    <IconTrash size={18} />
                  </ActionIcon>
                </Group>

                <Stack>
                  <Switch
                    disabled={disabled}
                    label={t("CHRONIC_MEDICATION")}
                    {...form.getInputProps(`items.${index}.isChronic`, {
                      type: "checkbox",
                    })}
                  />
                  <Group grow align="flex-end">
                    <NumberInput
                      disabled={disabled}
                      {...form.getInputProps(`items.${index}.qty`)}
                      label={t("QUANTITY_DISPENSED")}
                      min={1}
                    />
                    <NumberInput
                      disabled={disabled}
                      {...form.getInputProps(`items.${index}.nOfRefills`)}
                      label={t("N_OF_REFILL")}
                      min={0}
                    />
                    <BDateInput
                      disabled={disabled}
                      label={t("VALID_UNTIL")}
                      minDateTime={Date.now()}
                      {...form.getInputProps(`items.${index}.validUntil`)}
                    />
                  </Group>
                  <Input.Wrapper label={t("Directions")}>
                    <BTextEditor
                      disabled={disabled}
                      {...form.getInputProps(`items.${index}.directions`)}
                    />
                  </Input.Wrapper>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isValid() && form.values?.items?.length !== 0}
          />
        </Group>
      </Stack>
    </form>
  );
};
