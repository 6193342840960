import { z } from "zod";

// t("STAT")
// t("PRN")
// t("SCHEDULED")
// t("PLANNED")
// t("ORDERED")
// t("ADMINISTRED")
export const treatmentTypeSchema = z.enum(["STAT", "PRN", "SCHEDULED"]);

export type TreatmentType = z.infer<typeof treatmentTypeSchema>;
