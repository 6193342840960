import { z } from "zod";

// t("PREPARED") service added to Tx plan, not sent to integration yet
// t("PENDING") service added to Tx plan and sent to integration waiting for processing
// t("QUEUED")  service has been started
// t("COMPLETED") service fully processed, results available
// t("ORPHANED") service ordered through integration not beitary, ready to be mapped
// t("ORPHANED_MAPPED") service ordered through integration not beitary, and mapped to open consultation
// t("CANCELED") service canceled
export const diagnosticItemStatusEnum = z.enum([
  "PREPARED",
  "PENDING",
  "QUEUED",
  "COMPLETED",
  "ORPHANED",
  "ORPHANED_MAPPED",
  "CANCELED",
]);

export type DiagnosticItemStatus = z.infer<typeof diagnosticItemStatusEnum>;
