import {
  Button,
  Divider,
  Group,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  id_util,
  ImagingIntegrationProduct,
  ImagingIntegrationProductData,
  loadingInfoNotification,
  normalizeImagingIntegrationProduct,
  obju,
  ProductData,
  Result,
} from "beitary-shared";
import { BBox, BMoneyInput, MoveBackButton, SaveButton } from "components";
import { CategorySelect } from "features/admin/catalog/components/Products/components/Products/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mu } from "utils/money_utils";
import { BTRDLProductFormValues, rules } from "./BTRDLProductForm.rules";

export interface BTRDLProductFormProps {
  product?: ImagingIntegrationProduct;
}

export const BTRDLProductForm = ({ product }: BTRDLProductFormProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();
  const navigate = useNavigate();
  const db = useDBServices().catalogDBService;

  const form = useForm<BTRDLProductFormValues>({
    initialValues: product
      ? {
          ...normalizeImagingIntegrationProduct(product),
          unitSellingPrice: 0,
          taxRate: 0,
          categoryId: "",
        }
      : undefined,
    validate: rules,
  });
  if (form.values.taxRate === undefined) form.setFieldValue("taxRate", 0.19);
  if (!form.values.status) form.setFieldValue("status", "ACTIVE");
  if (!form.values.integration)
    form.setFieldValue("integration", "INTEGRATION_BTR_DL");
  const submit = async (values: BTRDLProductFormValues) => {
    obju.removeUndefined(values);
    const notificationId = id_util.newId6();
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(
          product
            ? "UPDATE_INTEGRATION_IMAGING_PRODUCT"
            : "ADD_INTEGRATION_IMAGING_PRODUCT"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<string | null>;
    if (product) {
      obju.removeUndefined(values);
      result =
        await db.imagingIntegrationProducts.updateImagingIntegrationProduct(
          product.id,
          values
        );
    } else {
      const data: ImagingIntegrationProductData = {
        integration: values.integration,
        name: values.name,
        status: values.status,
        description: values.description,
        modality: values.modality,
      };
      obju.removeUndefined(data);
      const mappedProductData: Pick<
        ProductData,
        "categoryId" | "name" | "unitSellingPrice" | "taxRate" | "integration"
      > = {
        unitSellingPrice: values.unitSellingPrice,
        categoryId: values.categoryId,
        name: values.description ?? values.name,
        taxRate: values.taxRate,
        integration: values.integration,
      };
      obju.removeUndefined(mappedProductData);

      result = await db.imagingIntegrationProducts.addImagingIntegrationProduct(
        data,
        mappedProductData
      );
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack spacing="xl">
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            product ? (
              <Text weight={500}>{t("EDIT_PRODUCT")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_PRODUCT")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              required
              label={t("NAME")}
              {...form.getInputProps("name")}
            />

            <TextInput
              label={t("DISPLAYNAME")}
              {...form.getInputProps("description")}
            />

            <TextInput
              label={t("MODALITY")}
              {...form.getInputProps("modality")}
            />
            {!product && (
              <Stack>
                <Text>{t("MAPPED_PRODUCT")}</Text>
                <Divider />
                <CategorySelect
                  required
                  {...form.getInputProps("categoryId")}
                />

                <Group grow spacing="xl" align="flex-end">
                  <BMoneyInput
                    required
                    label={t("SELLING_PRICE_NO_TAXES")}
                    description={t("PER_UNIT")}
                    {...form.getInputProps("unitSellingPrice")}
                    min={0}
                  />
                  <BMoneyInput
                    required
                    label={t("SELLING_PRICE")}
                    description={t("PER_UNIT")}
                    {...form.getInputProps("unitSellingPrice")}
                    min={0}
                    value={mu.multiply({
                      amount: form.values.unitSellingPrice ?? 0,
                      multiplier: 1 * (1 + form.values.taxRate),
                    })}
                    onChange={(v) => {
                      if (v !== "") {
                        form.setFieldValue(
                          "unitSellingPrice",
                          mu.multiply({
                            amount: v,
                            multiplier: 1 / (1 + form.values.taxRate),
                          })
                        );
                      }
                    }}
                  />
                  <NumberInput
                    required
                    label={t("TAXES")}
                    description={t("%")}
                    {...form.getInputProps("taxRate")}
                    value={form.values.taxRate * 100}
                    onChange={(v) => {
                      if (v !== "") {
                        form.setFieldValue(
                          "taxRate",
                          Number(Math.round(parseFloat(v / 100 + "e4")) + "e-4")
                        );
                      }
                    }}
                    min={0}
                    precision={2}
                  />
                </Group>
              </Stack>
            )}
            {product && (
              <Group position="right">
                <Button
                  onClick={() =>
                    navigate(
                      `/admin/catalog/products/products/edit/${product.id}`
                    )
                  }
                >
                  {t("MAPPED_PRODUCT_SETTINGS")}
                </Button>
              </Group>
            )}
          </Stack>
        </BBox>

        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
