import { zodResolver } from "@mantine/form";
import { physicalExamTemplateDataSchema } from "beitary-shared";
import { z } from "zod";

export const physicalExamTemplateFormSchema = physicalExamTemplateDataSchema;

export type PhysicalExamTemplateFormValues = z.infer<
  typeof physicalExamTemplateFormSchema
>;

export const rules = zodResolver(physicalExamTemplateFormSchema);
