import {
  Diagnosis,
  DiagnosisData,
  DIAGNOSIS_VERSION,
  errorResult,
  normalizeDiagnosis,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization Diagnosis

interface AddDiagnosis {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: DiagnosisData;
  }): Promise<Result<Diagnosis | null>>;
}

const addDiagnosis: AddDiagnosis = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new Diagnosis id
    const newDiagnosisRef = doc(
      collection(db, "organizations", organizationId, "diagnoses")
    );

    const newDiagnosis: Diagnosis = normalizeDiagnosis({
      ...data,
      id: newDiagnosisRef.id,
      authorId,
      authorName,
      version: DIAGNOSIS_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newDiagnosisRef, newDiagnosis);

    // t("DIAGNOSIS_CREATED")
    const successMessage = "DIAGNOSIS_CREATED";
    return successResult({
      message: successMessage,
      payload: newDiagnosis,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetDiagnosis {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Diagnosis | null>>;
}

const getDiagnosis: GetDiagnosis = async ({ db, organizationId, id }) => {
  try {
    const diagnosisDocRef = doc(
      db,
      "organizations",
      organizationId,
      "diagnoses",
      id
    );
    const diagnosisDocSnapshot = await getDoc(diagnosisDocRef);
    if (diagnosisDocSnapshot.exists()) {
      try {
        const data: unknown = diagnosisDocSnapshot.data();
        const diagnosis: Diagnosis = normalizeDiagnosis(data);
        // t("DIAGNOSIS_FOUND")
        const successMessage = "DIAGNOSIS_FOUND";
        return successResult({
          message: successMessage,
          payload: diagnosis,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("DIAGNOSIS_NOT_FOUND")
      const errorMessage = "DIAGNOSIS_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditDiagnosis {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: DiagnosisData;
  }): Promise<Result<Diagnosis | null>>;
}

const editDiagnosis: EditDiagnosis = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const diagnosis = (await getDiagnosis({ db, organizationId, id })).payload;

    if (!diagnosis) {
      // t("DIAGNOSIS_NOT_FOUND")
      const errorMessage = "DIAGNOSIS_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = diagnosis;
      const updatedDiagnosis: Diagnosis = normalizeDiagnosis({
        ...data,
        id,
        authorId,
        authorName,
        version: DIAGNOSIS_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      const diagnosisRef = doc(
        db,
        "organizations",
        organizationId,
        "diagnoses",
        id
      );

      await setDoc(diagnosisRef, updatedDiagnosis);

      // t("DIAGNOSIS_UPDATED")
      const successMessage = "DIAGNOSIS_UPDATED";
      // console.log(successMessage, updatedDiagnosis);
      return successResult({
        message: successMessage,
        payload: updatedDiagnosis,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteDiagnosis {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteDiagnosis: DeleteDiagnosis = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "diagnoses", id);

    try {
      await deleteDoc(docRef);
      // t("DIAGNOSIS_DELETED")
      const successMessage = "DIAGNOSIS_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveDiagnosis: EditDiagnosis = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: DiagnosisData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editDiagnosis({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("DIAGNOSIS_ARCHIVED")
    const successMessage = "DIAGNOSIS_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveDiagnosis: EditDiagnosis = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: DiagnosisData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editDiagnosis({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("DIAGNOSIS_DEARCHIVED")
    const successMessage = "DIAGNOSIS_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization Diagnoses listener
interface GetDiagnosesListenerCallback {
  (diagnoses: Diagnosis[]): void;
}
interface GetDiagnosesListener {
  db: Firestore;
  organizationId: string;
  callback: GetDiagnosesListenerCallback;
}

const getDiagnosesListener = ({
  db,
  organizationId,
  callback,
}: GetDiagnosesListener): Unsubscribe => {
  try {
    // console.log("getDiagnosesListener: new listener");
    const diagnosesQuery = query(
      collection(db, "organizations", organizationId, "diagnoses"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(diagnosesQuery, (querySnapshot) => {
      const diagnoses: Diagnosis[] = [];
      querySnapshot.forEach((doc) => {
        try {
          diagnoses.push(normalizeDiagnosis(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(diagnoses);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const diagnoses = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getDiagnosesListener: (callback: GetDiagnosesListenerCallback) =>
      getDiagnosesListener({
        db,
        organizationId,
        callback,
      }),
    getDiagnosis: (id: string) =>
      getDiagnosis({
        db,
        organizationId,
        id,
      }),
    addDiagnosis: (data: DiagnosisData) =>
      addDiagnosis({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editDiagnosis: (id: string, data: DiagnosisData) =>
      editDiagnosis({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteDiagnosis: (id: string) =>
      deleteDiagnosis({
        db,
        organizationId,
        id,
      }),
    archiveDiagnosis: (id: string, data: DiagnosisData) =>
      archiveDiagnosis({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveDiagnosis: (id: string, data: DiagnosisData) =>
      dearchiveDiagnosis({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
