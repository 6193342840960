import {
  Anchor,
  Avatar,
  Badge,
  Group,
  SimpleGrid,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconExclamationMark,
  IconExternalLink,
  IconUser,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Client } from "beitary-shared";
import { AlertTooltip, BBox } from "components";
import { selectClientsMap } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface ClientInfoProps {
  clientId: string;
  clientName: string;
}

export const ClientInfo = ({ clientId, clientName }: ClientInfoProps) => {
  const { t } = useTranslation();

  const client: Client | undefined = useAppSelector(selectClientsMap)[clientId];

  if (!client)
    return (
      <BBox
        header={
          <Group spacing="xs">
            <Tooltip label={t("CLIENT_NOT_FOUND")} position="right-end">
              <ThemeIcon color="red" radius="xl">
                <IconExclamationMark stroke={2.5} size={20} />
              </ThemeIcon>
            </Tooltip>
            <Text weight={500}> {clientName}</Text>
          </Group>
        }
      />
    );

  const clientAddressString = [
    client.address_Line1,
    client.address_Line2,
    client.address_city,
    client.address_stateOrProvince,
    client.address_postalCode,
    client.address_countryCode,
  ]
    .filter((v) => v)
    .join(", ");
  return (
    <BBox
      bgcs={0}
      header={
        <Group position="apart">
          <Group spacing={4}>
            <Avatar src={client.thumbURL} size={40} radius="xl" mr="xs">
              <IconUser size={25} />
            </Avatar>
            <Anchor
              color="dark"
              weight={500}
              lineClamp={1}
              component={Link}
              to={`/clients/${client.id}`}
            >
              {client.name} {<IconExternalLink size={12} />}
            </Anchor>
            {client.alert && <AlertTooltip alert={client.alert} size={20} />}
            <Badge size="lg" radius="xs" ml="xl">
              {t(client.status)}
            </Badge>
          </Group>
        </Group>
      }
    >
      <SimpleGrid p="xl" cols={1}>
        <div>
          <Text color="cyan">{t("EMAIL")}</Text>
          <Text>{client.email}</Text>
        </div>
        <div>
          <Text color="cyan">{t("PHONE_NUMBER")}</Text>
          <Text>{client.phoneNumber ?? "-"}</Text>
        </div>
        <div>
          <Text color="cyan">{t("ADDRESS")}</Text>
          <Text>{clientAddressString ?? "-"}</Text>
        </div>
      </SimpleGrid>
    </BBox>
  );
};
