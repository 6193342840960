import { RemindersSnapshotsTable } from "features/reminders-snapshots/components";

export interface RemindersProps {
  clientId: string;
  clientName: string;
  patientId: string;
  patientName: string;
}

export const Reminders = ({
  clientId,
  clientName,
  patientId,
  patientName,
}: RemindersProps) => {
  return (
    <RemindersSnapshotsTable
      clientId={clientId}
      clientName={clientName}
      patientId={patientId}
      patientName={patientName}
      weekOnly={false}
    />
  );
};
