import {
  ActionIcon,
  Affix,
  Button,
  Center,
  Collapse,
  Group,
  Popover,
  rem,
  Stack,
  Text,
  Transition,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DBNoIdsService } from "services/db-no-ids/db-no-ids";

// sauce kinda: https://stackoverflow.com/questions/45978230/get-version-number-from-package-json-in-react-redux-create-react-app
export const BSettingsListener = () => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [tOpened, { toggle }] = useDisclosure(true);

  const [countdown, setCountdown] = useState(100); // Countdown in seconds

  const refreshApp = () => window.location.reload();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (opened && countdown !== null) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => {
          const updatedCountdown = prevCountdown ? prevCountdown - 1 : 0;

          if (updatedCountdown <= 0) {
            clearInterval(intervalId);
            refreshApp(); // Refresh the page when the countdown reaches 0
          }

          return updatedCountdown;
        });
      }, 1000); // Countdown decrements every second (1000 milliseconds)
    }

    return () => {
      clearInterval(intervalId); // Clear the interval on component unmount or when the popup closes
    };
  }, [opened, countdown]);

  useEffect(() => {
    const getBSettingsListenerUnsubscribe =
      DBNoIdsService().getBSettingsListener((bSettings) => {
        const version = process.env.REACT_APP_VERSION;
        if (!version) throw new Error("COULD_NOT_FIND_VERSION");
        if (bSettings.version !== version) {
          // IMPORTANT!!!
          // otherwise infinite loop cauz dependes on settings
          setOpened(true);
          setCountdown(100);
        }
      });

    return () => {
      getBSettingsListenerUnsubscribe();
    };
  }, []);

  return (
    <Popover opened={opened}>
      <Popover.Target>
        <Affix position={{ bottom: rem(10), right: rem("50%") }}>
          <></>
        </Affix>
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Stack p="xl" w={600}>
          <Group position="apart">
            <Transition
              mounted={!tOpened}
              transition="slide-up"
              duration={800}
              timingFunction="ease"
            >
              {(styles) => (
                <Text style={styles}>
                  {t("RELOADING_AUTOMATICALLY_IN")}{" "}
                  <Text span color="red" weight="bold" size="lg">
                    {countdown}
                  </Text>{" "}
                  {t("SECONDS")}
                </Text>
              )}
            </Transition>
            <div></div>
            <ActionIcon onClick={() => toggle()}>
              <IconChevronDown
                style={{
                  transform: tOpened ? "none" : "rotate(180deg)",
                  transitionDuration: "200ms",
                }}
              />
            </ActionIcon>
          </Group>

          <Collapse in={tOpened} p="xl" transitionDuration={600}>
            <Center>
              <Text weight={600} size="lg" mb="lg">
                {t("NEW_VERSION_AVAILABLE")}
              </Text>
            </Center>
            <Text mb="md" color="dimmed">
              {t("CLICK_RELOAD_FOR_UPDATES")}
            </Text>
            <Transition
              mounted={tOpened}
              transition="slide-down"
              duration={800}
              timingFunction="ease"
            >
              {(styles) => (
                <Text style={styles}>
                  {t("RELOADING_AUTOMATICALLY_IN")}{" "}
                  <Text span color="red" weight="bold" size="lg">
                    {countdown}
                  </Text>{" "}
                  {t("SECONDS")}
                </Text>
              )}
            </Transition>
            <Group position="right" mt="xl">
              <Button onClick={refreshApp}>{t("RELOAD")}</Button>
            </Group>
          </Collapse>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
