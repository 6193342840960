import { OrgPermissions } from "beitary-shared";
import { ProtectedRouteWrapper } from "components";
import { ClinicSettings } from "features/admin/clinic-settings";
import {
  AdvancedSettings,
  GeneralSettings,
  Locations,
  WorkHours,
} from "features/admin/clinic-settings/components";
import { EditGeneralSettings } from "features/admin/clinic-settings/components/GeneralSettings/components";
import {
  AddLocation,
  EditLocation,
} from "features/admin/clinic-settings/components/Locations/components";
import { Navigate, Outlet, Route } from "react-router-dom";

export const ClinicSettingsRoutes = (
  <Route path="clinic-settings" element={<ClinicSettings />}>
    <Route index element={<Navigate to="general" replace />} />
    <Route path="advanced" element={<AdvancedSettings />} />
    <Route path="general" element={<Outlet />}>
      <Route index element={<GeneralSettings />} />
      <Route
        path="edit"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.CLINIC_SETTINGS_CUD]}
            element={<EditGeneralSettings />}
          />
        }
      />
    </Route>
    <Route path="locations" element={<Outlet />}>
      <Route index element={<Locations />} />
      <Route
        path="new"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.CLINIC_SETTINGS_CUD]}
            element={<AddLocation />}
          />
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRouteWrapper
            conditions={["do", OrgPermissions.CLINIC_SETTINGS_CUD]}
            element={<EditLocation />}
          />
        }
      />
    </Route>
    <Route path="hours" element={<WorkHours />} />
  </Route>
);
