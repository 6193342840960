import { Text } from "@mantine/core";
import { Consultation as ConsultationBS } from "beitary-shared";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Wrapper } from "./Consultation.wrapper";

export const Consultation = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [consultation, setConsultation] = useState<ConsultationBS | null>(null);

  const db = useDBServices().consultationsDBService.consultations;

  useEffect(() => {
    const listener = id
      ? db.getConsultationListener(id, setConsultation)
      : () => null;

    return () => {
      listener();
    };
  }, [id, db]);

  if (!consultation || !id) {
    return <Text>{t("NOT_FOUND")}</Text>;
  } else {
    return <Wrapper consultation={consultation} />;
  }
};
