import { errorResult, Result, Source, successResult } from "beitary-shared";
import {
  collection,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  startAt,
} from "firebase/firestore";
import {
  CommunicationHistory,
  EmailCommunicationHistory,
  normalizeEmailCommunicationHistory,
  normalizeSMSCommunicationHistory,
  SMSCommunicationHistory,
} from "schemas-types/CommunicationHistory";

export const getCommunicationHistoryTime = (i: CommunicationHistory) => {
  const seconds = i.delivery?.startTime?.seconds ?? 0;
  const nanoseconds = i.delivery?.startTime?.nanoseconds ?? 0;
  const result = seconds * 1000 + Math.round(nanoseconds / 1000000);
  return result;
};

interface GetEmailCommunicationHistory {
  ({
    db,
    organizationId,
    startAfterId,
    startBeforeId,
    startAtId,
    clientId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    startAfterId?: string;
    startBeforeId?: string;
    startAtId?: string;
    clientId?: string;
    patientId?: string;
  }): Promise<Result<EmailCommunicationHistory[] | null>>;
}

const getEmailCommunicationHistory: GetEmailCommunicationHistory = async ({
  db,
  organizationId,
  startAfterId,
  startBeforeId,
  startAtId,
  clientId,
  patientId,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "mail")
    );

    //   if (clientId) {
    //     newQuery = query(newQuery, where("clientId", "==", clientId));
    //   }

    //   if (patientId) {
    //     newQuery = query(newQuery, where("patientId", "==", patientId));
    //   }

    newQuery = query(
      newQuery,
      orderBy("delivery.startTime", "desc"),
      limit(PAGE_SIZE)
    );

    if (startAtId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "mail",
        startAtId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAt(cursorDoc));
    } else if (startBeforeId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "mail",
        startBeforeId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, endBefore(cursorDoc), limitToLast(PAGE_SIZE));
    } else if (startAfterId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "mail",
        startAfterId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAfter(cursorDoc));
    }
    const querySnapshot = await getDocs(newQuery);

    const communicationHistory: EmailCommunicationHistory[] = [];

    querySnapshot.forEach((doc) => {
      try {
        communicationHistory.push(
          normalizeEmailCommunicationHistory({
            ...doc.data(),
            id: doc.id,
          })
        );
      } catch (error) {
        console.log(error);
      }
    });

    return successResult({
      message: "SUCCESS",
      payload: communicationHistory,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetSMSCommunicationHistory {
  ({
    db,
    organizationId,
    startAfterId,
    startBeforeId,
    startAtId,
    clientId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    startAfterId?: string;
    startBeforeId?: string;
    startAtId?: string;
    clientId?: string;
    patientId?: string;
  }): Promise<Result<SMSCommunicationHistory[] | null>>;
}

const getSMSCommunicationHistory: GetSMSCommunicationHistory = async ({
  db,
  organizationId,
  startAfterId,
  startBeforeId,
  startAtId,
  clientId,
  patientId,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "sms")
    );

    //   if (clientId) {
    //     newQuery = query(newQuery, where("clientId", "==", clientId));
    //   }

    //   if (patientId) {
    //     newQuery = query(newQuery, where("patientId", "==", patientId));
    //   }

    newQuery = query(
      newQuery,
      orderBy("delivery.startTime", "desc"),
      limit(PAGE_SIZE)
    );

    if (startAtId) {
      const docRef = doc(db, "organizations", organizationId, "sms", startAtId);
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAt(cursorDoc));
    } else if (startBeforeId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "sms",
        startBeforeId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, endBefore(cursorDoc), limitToLast(PAGE_SIZE));
    } else if (startAfterId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "sms",
        startAfterId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAfter(cursorDoc));
    }

    const querySnapshot = await getDocs(newQuery);

    const communicationHistory: SMSCommunicationHistory[] = [];

    querySnapshot.forEach((doc) => {
      try {
        communicationHistory.push(
          normalizeSMSCommunicationHistory({
            ...doc.data(),
            id: doc.id,
          })
        );
      } catch (error) {
        console.log(error);
      }
    });

    return successResult({
      message: "SUCCESS",
      payload: communicationHistory,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const communicationHistory = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getEmailCommunicationHistory: (props: {
      startAfterId?: string;
      startBeforeId?: string;
      startAtId?: string;
      clientId?: string;
      patientId?: string;
    }) =>
      getEmailCommunicationHistory({
        db,
        organizationId,
        ...props,
      }),
    getSMSCommunicationHistory: (props: {
      startAfterId?: string;
      startBeforeId?: string;
      startAtId?: string;
      clientId?: string;
      patientId?: string;
    }) =>
      getSMSCommunicationHistory({
        db,
        organizationId,
        ...props,
      }),
    getCommunicationHistoryTime,
  };
};
