import { Items } from "features/admin/inventory/components";
import {
  Items as ChildItems,
  Locations,
  Suppliers,
} from "features/admin/inventory/components/Items/components";
import {
  AddItem,
  EditItem,
} from "features/admin/inventory/components/Items/components/Items/components";
import {
  AddLocation,
  EditLocation,
} from "features/admin/inventory/components/Items/components/Locations/components";
import {
  AddSupplier,
  EditSupplier,
} from "features/admin/inventory/components/Items/components/Suppliers/components";
import { Navigate, Outlet, Route } from "react-router-dom";

export const ItemsRoutes = (
  <Route path="inventory-items" element={<Items />}>
    <Route index element={<Navigate to="items" replace />} />
    <Route path="items" element={<Outlet />}>
      <Route index element={<ChildItems />} />
      <Route path="new" element={<AddItem />} />
      <Route path="edit/:id" element={<EditItem />} />
    </Route>
    <Route path="suppliers" element={<Outlet />}>
      <Route index element={<Suppliers />} />
      <Route path="new" element={<AddSupplier />} />
      <Route path="edit/:id" element={<EditSupplier />} />
    </Route>
    <Route path="locations" element={<Outlet />}>
      <Route index element={<Locations />} />
      <Route path="new" element={<AddLocation />} />
      <Route path="edit/:id" element={<EditLocation />} />
    </Route>
  </Route>
);
