import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../../baseRecord";
import { UnableToNormalizeData } from "../../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { patientSexEnum, patientSpeciesEnum } from "../../../patients";
import { boardingAppointmentStatusEnum } from "./BoardingAppointment.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const boardingAppointmentDataV0Schema = z.object({
  status: boardingAppointmentStatusEnum,
  organizationId: z.string(),
  organizationName: z.string(),
  boardingResourceId: z.string(),
  clientId: z.string(),
  clientName: z.string(),
  patientId: z.string(),
  patientName: z.string(),
  patientSpecies: patientSpeciesEnum,
  patientSex: patientSexEnum,
  patientPictureURL: z.string().url().optional(),
  from: z.number().positive(),
  to: z.number().positive().int(),
  notes: z.string().optional(),
  yearWeeksCovered: z.array(z.string()),
});

type BoardingAppointmentDataV0 = z.infer<
  typeof boardingAppointmentDataV0Schema
>;

const boardingAppointmentV0Schema = baseRecordSchema.merge(
  boardingAppointmentDataV0Schema
);

type BoardingAppointmentV0 = z.infer<typeof boardingAppointmentV0Schema>;

const normalizeBoardingAppointmentV0 = (data: any): BoardingAppointment => {
  try {
    const boardingAppointmentV0: BoardingAppointmentV0 =
      boardingAppointmentV0Schema.parse(data);

    const { ...rest } = boardingAppointmentV0;

    // here it's same object cause current v is 0
    const boardingAppointment: BoardingAppointment = {
      ...rest,
    };
    return boardingAppointment;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const BOARDING_APPOINTMENT_VERSION = "0";

export type BoardingAppointmentData = BoardingAppointmentDataV0;

export type BoardingAppointment = BaseRecord & BoardingAppointmentData;

export const boardingAppointmentDataSchema = boardingAppointmentDataV0Schema;

export const boardingAppointmentSchema = boardingAppointmentV0Schema;

export const normalizeBoardingAppointment = (
  data: any
): BoardingAppointment => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeBoardingAppointmentV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
