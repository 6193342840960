import { Box, Group, Stack, Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { PrescriptionItem, tu } from "beitary-shared";
import { BBox, BButtonPrint, MoveBackButton } from "components";
import { selectActiveConsultationPrescriptions } from "features/consultations/Consultations.slice";
import { useCServices } from "hooks/useCService/useCService";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { file_util } from "utils/file_util";

export const ViewPrescription = () => {
  const { t } = useTranslation();

  const { prescriptionId } = useParams();

  const cservice = useCServices();

  const prescription = useAppSelector(selectActiveConsultationPrescriptions)
    .filter((prescription) => prescription.id === prescriptionId)
    .at(0);

  if (!prescription) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  const printPrescription = async () => {
    const result =
      await cservice.prescriptionsCService.getPrescriptionPDFFileURL(
        prescription.id
      );

    if (result.payload) {
      file_util.printFileFromURL({ url: result.payload.content });
    }
  };

  const prescriptionItem = (i: PrescriptionItem) => {
    const refills =
      i.nOfRefills && i.nOfRefills > 0
        ? `${t("REFILLS")}: ${i.nOfRefills}`
        : undefined;

    const chronic = i.isChronic ? t("CHRONIC_MEDICATION") : undefined;

    const validity = i.validUntil
      ? `${t("VALID_UNTIL")}: ${tu.getLocaleDateStringFromEpoch(i.validUntil)}`
      : undefined;

    const str = [chronic, refills, validity].filter((e) => !!e).join(" - ");

    return (
      <BBox>
        <Box p="xl" key={i.id}>
          <Text weight={500} size="md">
            {i.qty} x {i.pName}
          </Text>
          <Text color="dimmed" size="xs">
            {str}
          </Text>
          <Text size="sm">{HTMLReactParser(i.directions ?? "")}</Text>
        </Box>
      </BBox>
    );
  };
  return (
    <Stack>
      <Group>
        <MoveBackButton />
      </Group>
      <BBox
        header={
          <Group position="apart">
            <Text>
              {t("PRESCRIPTION_FOR")}{" "}
              <Text span color="cyan">
                {prescription.patientName}
              </Text>
              {", "}
              {t("BY_DOCTOR")}{" "}
              <Text span color="cyan">
                {prescription.prescribingDrName}
              </Text>
              {", "}
              {t("ON_DATE")}{" "}
              <Text span color="cyan">
                {tu.getLocaleDateStringFromEpoch(prescription.createdAt)}
              </Text>
            </Text>
            <BButtonPrint f={async () => printPrescription()} />
          </Group>
        }
      ></BBox>
      {prescription.items.map(prescriptionItem)}
    </Stack>
  );
};
