// import { useTranslation } from "react-i18next";

import { Badge, Box, Group, Text } from "@mantine/core";
import {
  IconAlertTriangle,
  IconArrowBack,
  IconPencilCheck,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { id_util, Task, tu } from "beitary-shared";
import {
  AsyncActionIcon,
  BBox,
  BDataTable,
  DeleteConfirmationModal,
} from "components";
import { selectMembers } from "features/admin/members/Members.slice";
import { AddTaskModal, EditTaskModal } from "features/tasks/components";
import { selectCurrentDayTasks } from "features/tasks/tasks.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useTranslation } from "react-i18next";
import { BDataTableColumn } from "schemas-types";

type TasksTableItem = Task & {
  assignedToNames?: (string | undefined)[];
  dueDateString: string;
  actions?: undefined;
};

export interface TodaysTasksListProps {}

export const TodaysTasksList = () => {
  const { t } = useTranslation();
  const members = useAppSelector(selectMembers);
  const { deleteTask, completeTask, uncompleteTask } =
    useDBServices().tasksDBService;

  const tableItems: TasksTableItem[] = useAppSelector(
    selectCurrentDayTasks
  ).map((i) => {
    const newItem: TasksTableItem = {
      ...i,
      dueDateString: tu.getDateAndTimeString(i.dueDate),
    };
    const s = (i.assignedToIds ?? [])
      .map((id) => members.find((m) => m.id === id)?.name)
      .filter((x) => x !== undefined);
    newItem["assignedToNames"] = s;
    return newItem;
  });

  const columns: BDataTableColumn<TasksTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
      render: (item) => <Text>{item.name} </Text>,
    },
    {
      accessor: "clientName",
      title: <Text>{t("CLIENT")}</Text>,
      sortable: true,
    },
    {
      accessor: "patientName",
      title: <Text>{t("PATIENT")}</Text>,
      sortable: true,
    },
    {
      accessor: "assignedToNames",
      title: <Text>{t("ASSIGNED_TO")}</Text>,
      sortable: true,
      render: ({ assignedToNames }) => (
        <Group>
          {assignedToNames?.map((name) => (
            <Badge key={id_util.newId20()}>{name ?? ""}</Badge>
          ))}
        </Group>
      ),
    },
    {
      accessor: "dueDateString",
      title: <Text>{t("DUE")}</Text>,
      sortable: true,
      render: ({ dueDateString, dueDate, status }) => (
        <Group>
          <Text>{dueDateString}</Text>
          {dueDate < tu.getCurrentDateTime() && status === "CREATED" && (
            <IconAlertTriangle size={16} color="red" stroke={1.5} />
          )}
        </Group>
      ),
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "CREATED" ? "dark" : "green"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (item) => (
        <Group position="right">
          {item.status === "CREATED" ? (
            <AsyncActionIcon
              f={async () => completeTask(item.id)}
              icon={<IconPencilCheck size={18} />}
            />
          ) : (
            <AsyncActionIcon
              f={async () => uncompleteTask(item.id)}
              icon={<IconArrowBack size={18} />}
            />
          )}
          <EditTaskModal task={item} />
          <DeleteConfirmationModal
            f={async () => deleteTask(item.id)}
            item={item}
          />
        </Group>
      ),
    },
  ];

  return (
    <BBox
      header={
        <Group position="apart">
          <Text size="sm" weight={500}></Text>
          <AddTaskModal dueDate={tu.getCurrentDateTime()} />
        </Group>
      }
    >
      <Box p="xl">
        <BDataTable data={tableItems} columns={columns} />
      </Box>
    </BBox>
  );
};
