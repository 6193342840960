import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { selectAppointmentTypes } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AppointmentTypeForm } from "../AppointmentTypeForm";

export const EditAppointmentType = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const appointmentType = useAppSelector(selectAppointmentTypes)
    .filter((appointmentType) => appointmentType.id === id)
    .at(0);

  if (!appointmentType) {
    return <Text>{t("NOT_FOUND")}</Text>;
  }

  return <AppointmentTypeForm appointmentType={appointmentType} />;
};
