import { Anchor, Avatar, Badge, Group, Text } from "@mantine/core";
import { IconExternalLink, IconPaw } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Consultation, Treatment, tu } from "beitary-shared";
import { BBox, TableWithSearchAndSort } from "components";
import { selectClinicLocations } from "features/admin/clinic-settings/ClinicSettings.slice";
import { selectConsTxs } from "features/whiteboard/Whiteboard.slice";
import { useIsMobile } from "hooks/useIsMobile";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface AllTreatmentsProps {}

export type AllTreatmentsItem = {
  PRN: Treatment[];
  STAT: Treatment[];
  OVERDUE: Treatment[];
  CURRENT: Treatment[];
  UPCOMING: Treatment[];
  PRN_L: number;
  STAT_L: number;
  OVERDUE_L: number;
  CURRENT_L: number;
  UPCOMING_L: number;
} & Consultation;

export const AllTreatments = () => {
  const { t } = useTranslation();
  const locations = useAppSelector(selectClinicLocations);
  const consTx = useAppSelector(selectConsTxs);
  const isMobile = useIsMobile();

  consTx.at(0)?.SCHEDULED.forEach(({ timeDue }) => {
    console.log(timeDue && (tu.getCurrentDateTime() - timeDue) / (60 * 1000));
  });

  const data: AllTreatmentsItem[] = consTx.map((i) => {
    const overdue = i.SCHEDULED.filter(
      ({ timeDue }) =>
        timeDue && (tu.getCurrentDateTime() - timeDue) / (60 * 1000) > 5
    );
    const current = i.SCHEDULED.filter(
      ({ timeDue }) =>
        timeDue &&
        (tu.getCurrentDateTime() - timeDue) / (60 * 1000) <= 5 &&
        timeDue &&
        (tu.getCurrentDateTime() - timeDue) / (60 * 1000) >= -5
    );
    const upcoming = i.SCHEDULED.filter(
      ({ timeDue }) =>
        timeDue && (tu.getCurrentDateTime() - timeDue) / (60 * 1000) < -5
    );
    return {
      ...i,
      OVERDUE: overdue,
      CURRENT: current,
      UPCOMING: upcoming,
      OVERDUE_L: overdue.length,
      CURRENT_L: current.length,
      UPCOMING_L: upcoming.length,
      PRN_L: i.PRN.length,
      STAT_L: i.STAT.length,
      patientLocation: locations.find((l) => i.patientLocation === l.id)?.name,
    };
  });

  console.log(data);

  const fields: {
    key: keyof AllTreatmentsItem;
    label: ReactNode;
  }[] = [
    { key: "patientName", label: t("PATIENT") },
    { key: "patientLocation", label: t("PATIENT_LOCATION") },
    {
      key: "STAT_L",
      label: (
        <Badge
          color="red"
          size={isMobile ? "xs" : "lg"}
          sx={{ textTransform: "capitalize" }}
        >
          {t("STAT")}
        </Badge>
      ),
    },
    {
      key: "PRN_L",
      label: (
        <Badge
          color="violet"
          size={isMobile ? "xs" : "lg"}
          sx={{ textTransform: "capitalize" }}
        >
          {t("PRN")}
        </Badge>
      ),
    },
    {
      key: "OVERDUE_L",
      label: (
        <Badge
          color="orange"
          size={isMobile ? "xs" : "lg"}
          sx={{ textTransform: "capitalize" }}
        >
          {t("OVERDUE")}
        </Badge>
      ),
    },
    {
      key: "CURRENT_L",
      label: (
        <Badge
          color="green"
          size={isMobile ? "xs" : "lg"}
          sx={{ textTransform: "capitalize" }}
        >
          {t("CURRENT")}
        </Badge>
      ),
    },
    {
      key: "UPCOMING_L",
      label: (
        <Badge
          color="blue"
          size={isMobile ? "xs" : "lg"}
          sx={{ textTransform: "capitalize" }}
        >
          {t("UPCOMING")}
        </Badge>
      ),
    },
  ];

  const getRow = (consultation: AllTreatmentsItem) => (
    <tr key={consultation.id}>
      <td>
        <Group>
          <Avatar
            src={consultation.patientPictureURL}
            size={isMobile ? 36 : 50}
            radius="xl"
          >
            <IconPaw size={isMobile ? 22 : 30} />
          </Avatar>
          {/* <div>
            <Anchor
              color="dark"
              weight={500}
              lineClamp={1}
              component={Link}
              to={`/clients/${consultation.clientId}/patients/${consultation.patientId}`}
            >
              {consultation.patientName} {<IconExternalLink size={12} />}
            </Anchor>
            <Anchor
              color="dark"
              weight={400}
              lineClamp={1}
              component={Link}
              to={`/clients/${consultation.clientId}`}
            >
              {consultation.clientName} {<IconExternalLink size={12} />}
            </Anchor>
          </div> */}
          <Text weight={500}>{consultation.patientName}</Text>
        </Group>
      </td>
      <td>
        <Text>{consultation.patientLocation}</Text>
      </td>
      <td>
        <Text>{consultation.STAT_L}</Text>
      </td>
      <td>
        <Text>{consultation.PRN_L}</Text>
      </td>
      <td>
        <Text>{consultation.OVERDUE_L}</Text>
      </td>
      <td>
        <Text>{consultation.CURRENT_L}</Text>
      </td>
      <td>
        <Text>{consultation.UPCOMING_L}</Text>
      </td>
      <td>
        <Anchor
          color="dark"
          weight={400}
          lineClamp={1}
          component={Link}
          to={`/whiteboard/consultations/${consultation.id}`}
        >
          {<IconExternalLink size={12} />}
        </Anchor>
      </td>
    </tr>
  );
  return (
    <BBox p="xl" bgcs={0}>
      <TableWithSearchAndSort
        data={data}
        fields={fields}
        getRow={getRow}
        hasControlsColumn
        minHeight={"70vh"}
      />
    </BBox>
  );
};
