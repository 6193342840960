import { productAdministrationRouteEnum } from "beitary-shared/src/types-schemas/common/units/ProductAdministrationRoutes/ProductAdministrationRoutes";
import { BMultiSelect } from "components";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface AdministrationRoutesMultiSelectProps {
  required?: boolean;
  onChange: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const AdministrationRoutesMultiSelect = (
  props: AdministrationRoutesMultiSelectProps
) => {
  const { t } = useTranslation();
  const data = productAdministrationRouteEnum.options.map((o) => ({
    value: o,
    label: o,
  }));

  return (
    <BMultiSelect
      {...props}
      label={t("ADMINISTRATION_ROUTES")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};
