import {
  ActionIcon,
  Box,
  Card,
  Collapse,
  Group,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { PatientLifecycleSearch } from "features/Clients/components/Client/components/Patient/components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PatientReminders,
  PatientUpcomingAppointments,
  SearchInput,
} from "../PatientLifecycle/components";

export interface PatientLifecycleSearchContainerProps {
  visible: boolean;
  patientId: string;
  clientId: string;
}

export const PatientLifecycleSearchContainer = ({
  visible,
  clientId,
  patientId,
}: PatientLifecycleSearchContainerProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [searchString, setSearchString] = useState("");
  const [opened, setOpened] = useState(true);

  return visible ? (
    <Box pb="md" pt="md">
      <PatientUpcomingAppointments clientId={clientId} patientId={patientId} />
      <PatientReminders clientId={clientId} patientId={patientId} />
      <Card
        sx={{ border: `1px solid ${theme.colors.gray[3]}` }}
        pb="md"
        mt="md"
      >
        <Group>
          <ActionIcon onClick={() => setOpened(!opened)}>
            <IconChevronDown
              style={{
                transform: opened ? "rotate(180deg)" : "none",
                transitionDuration: "200ms",
              }}
            />
          </ActionIcon>
          <Text weight={600} size="sm">
            {t("LIFECYCLE")}
          </Text>
        </Group>
        <Collapse in={opened} transitionDuration={400}>
          <SearchInput parentSetString={setSearchString} />
          <ScrollArea.Autosize mah={"80vh"} mx="-xl" px="md" my="xs">
            <PatientLifecycleSearch
              patientId={patientId}
              searchString={searchString}
            />
          </ScrollArea.Autosize>
        </Collapse>
      </Card>
    </Box>
  ) : (
    <></>
  );
};
