import { useAbilityContext } from "permissions";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

export interface ProtectedRouteWrapperProps {
  element: ReactElement;
  conditions: string[];
}

export const ProtectedRouteWrapper = ({
  element,
  conditions,
}: ProtectedRouteWrapperProps): ReactElement => {
  const ability = useAbilityContext();

  if (ability.can(...conditions)) {
    return element;
  } else {
    return <Navigate to="/not-authorized" replace />;
  }
};
