import { Group, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  DischargeInstruction,
  getNotificationByResultType,
  loadingInfoNotification,
  Result,
} from "beitary-shared";
import { BBox, BTextEditor, SaveButton } from "components";
import { MoveBackButton } from "components/MoveBackButton";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import {
  DischargeInstructionFormValues,
  rules,
} from "./DischargeInstructionForm.rules";

export interface DischargeInstructionFormProps {
  dischargeInstruction?: DischargeInstruction;
  consultationId: string;
  disabled: boolean;
}

export const DischargeInstructionForm = ({
  dischargeInstruction,
  consultationId,
  disabled,
}: DischargeInstructionFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const { addDischargeInstruction, updateDischargeInstruction } =
    useDBServices().consultationsDBService.dischargeInstructions(
      consultationId
    );

  const form = useForm<DischargeInstructionFormValues>({
    initialValues: dischargeInstruction
      ? { ...dischargeInstruction }
      : undefined,
    validate: rules,
  });

  if (!form.values.consultationId)
    form.setFieldValue("consultationId", consultationId);

  const submit = async (values: DischargeInstructionFormValues) => {
    if (disabled) return;
    const notificationId = "submit-catalog-product-dischargeInstruction";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(
          dischargeInstruction
            ? "UPDATE_DISCHARGE_INSTRUCTION"
            : "ADD_DISCHARGE_INSTRUCTION"
        ),
      })
    );
    setSubmitState("pending-response");
    let result: Result<Partial<DischargeInstruction> | null>;
    if (dischargeInstruction) {
      result = await updateDischargeInstruction(
        dischargeInstruction.id,
        values
      );
    } else {
      result = await addDischargeInstruction(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={
            dischargeInstruction ? (
              <Text weight={500}>{t("EDIT_DISCHARGE_INSTRUCTION")}</Text>
            ) : (
              <Text weight={500}>{t("NEW_DISCHARGE_INSTRUCTION")}</Text>
            )
          }
        >
          <Stack p="xl">
            <TextInput
              disabled={disabled}
              required
              label={t("TITLE")}
              {...form.getInputProps("title")}
            />
            <BTextEditor
              disabled={disabled}
              {...form.getInputProps("content")}
            />
          </Stack>
        </BBox>
        <Group position="right">
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
