import {
  errorResult,
  Estimate,
  EstimateData,
  ESTIMATE_VERSION,
  normalizeEstimate,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";

// add organization estimate

interface AddEstimate {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;

    source: Source;
    data: EstimateData;
  }): Promise<Result<boolean | null>>;
}

const addEstimate: AddEstimate = async ({
  db,
  organizationId,
  authorId,
  authorName,

  source,
  data,
}) => {
  try {
    const newEstimateRef = doc(
      collection(db, "organizations", organizationId, "estimates")
    );

    const newEstimate: Estimate = normalizeEstimate({
      ...data,
      id: newEstimateRef.id,
      authorId,
      authorName,
      version: ESTIMATE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newEstimateRef, newEstimate);
    // t("ESTIMATE_CREATED")
    const successMessage = "ESTIMATE_CREATED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

// interface GetEstimate {
//   ({
//     db,
//     organizationId,
//
//     id,
//   }: {
//     db: Firestore;
//     organizationId: string;
//
//     id: string;
//   }): Promise<Result<Estimate | null>>;
// }

// const getEstimate: GetEstimate = async ({ db, organizationId, id }) => {
//   try {
//     const estimateDocRef = doc(
//       db,
//       "organizations",
//       organizationId,
//       "estimates",
//       id
//     );
//     const estimateDocSnapshot = await getDoc(estimateDocRef);
//     if (estimateDocSnapshot.exists()) {
//       try {
//         const data: unknown = estimateDocSnapshot.data();
//         const estimate: Estimate = normalizeEstimate(data);
//         const successMessage = "Estimate found!";
//         return successResult({
//           message: successMessage,
//           payload: estimate,
//         });
//       } catch (error: any) {
//         console.log(error);
//         return errorResult({ message: error.message });
//       }
//     } else {
//       // doc.data() will be undefined in this case
//       const errorMessage = "Estimate not found!";
//       console.log(errorMessage);
//       return errorResult({ message: errorMessage });
//     }
//   } catch (err: any) {
//     console.log(err);
//     return errorResult({ message: err.message });
//   }
// };

// interface GetEstimates {
//   ({
//     db,
//     organizationId,
//     ownerId,
//     nameStartsWith,
//   }: {
//     db: Firestore;
//     organizationId: string;
//     ownerId?: string;
//     nameStartsWith?: string;
//   }): Promise<Result<Estimate[] | null>>;
// }

// const getEstimates: GetEstimates = async ({
//   db,
//   organizationId,
//   ownerId,
//   nameStartsWith,
// }) => {
//   try {
//     let estimatesQuery = query(
//       collection(db, "organizations", organizationId, "estimates")
//     );
//     if (ownerId) {
//       estimatesQuery = query(estimatesQuery, where("ownerId", "==", ownerId));
//     }
//     if (nameStartsWith) {
//       estimatesQuery = query(estimatesQuery, where("name", ">=", nameStartsWith));
//     }

//     estimatesQuery = query(estimatesQuery, orderBy("name"), limit(5));

//     const querySnapshot = await getDocs(estimatesQuery);

//     const estimates: Estimate[] = [];
//     querySnapshot.forEach((doc) => {
//       estimates.push(normalizeEstimate(doc.data()));
//     });
//     const successMessage = "Estimates found!";
//     return successResult({
//       message: successMessage,
//       payload: estimates,
//     });
//   } catch (err: any) {
//     console.log(err);
//     return errorResult({ message: err.message });
//   }
// };

// interface EditEstimate {
//   ({
//     db,
//     organizationId,
//     authorId,
//     authorName,
//     source,
//     data,
//   }: {
//     db: Firestore;
//     organizationId: string;
//     authorId: string;
//     authorName: string;
//     source: Source;
//     data: Estimate;
//   }): Promise<Result<boolean | null>>;
// }

// const editEstimate: EditEstimate = async ({
//   db,
//   organizationId,
//   authorId,
//   authorName,
//   source,
//   data,
// }) => {
//   try {
//     const updatedEstimate: Estimate = normalizeEstimate({
//       ...data,
//       lastUpdatedAt: tu.getCurrentDateTime(),
//     });

//     const estimateRef = doc(
//       db,
//       "organizations",
//       organizationId,
//       "estimates",
//       data.id
//     );

//     await setDoc(estimateRef, updatedEstimate);

//     const successMessage = "Estimate updated!";
//     // console.log(successMessage, updatedEstimate);
//     return successResult({
//       message: successMessage,
//       payload: true,
//     });
//   } catch (err: any) {
//     console.log(err.message);
//     return errorResult({ message: err.message });
//   }
// };

interface UpdateEstimate {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;

    id: string;
    source: Source;
    data: Partial<EstimateData>;
  }): Promise<Result<boolean | null>>;
}

const updateEstimate: UpdateEstimate = async ({
  db,
  organizationId,
  authorId,
  authorName,

  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "estimates", id);

    const updates: Partial<Estimate> = {
      ...data,
      authorId,
      authorName,
      version: ESTIMATE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("ESTIMATE_UPDATED")
    const successMessage = "ESTIMATE_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization estimates listener
interface GetEstimatesListenerCallback {
  (estimates: Estimate[]): void;
}
interface GetEstimatesListener {
  db: Firestore;
  organizationId: string;
  clientId: string;
  consultationId?: string;
  patientId?: string;

  callback: GetEstimatesListenerCallback;
}

const getEstimatesListener = ({
  db,
  organizationId,
  clientId,
  patientId,
  consultationId,

  callback,
}: GetEstimatesListener): Unsubscribe => {
  try {
    // console.log("getEstimatesListener: new listener");
    let estimatesQuery = query(
      collection(db, "organizations", organizationId, "estimates"),
      where("clientId", "==", clientId)
    );

    if (consultationId !== undefined)
      estimatesQuery = query(
        estimatesQuery,
        where("consultationId", "==", consultationId)
      );

    if (patientId)
      estimatesQuery = query(
        estimatesQuery,
        where("patientId", "==", patientId)
      );

    return onSnapshot(estimatesQuery, (querySnapshot) => {
      const estimates: Estimate[] = [];
      querySnapshot.forEach((doc) => {
        estimates.push(normalizeEstimate(doc.data()));
      });
      callback(estimates);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface DeleteEstimate {
  (props: {
    db: Firestore;
    organizationId: string;

    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteEstimate: DeleteEstimate = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "estimates", id);

    try {
      await deleteDoc(docRef);
      // t("ESTIMATE_DELETED")
      const successMessage = "ESTIMATE_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const estimates = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getEstimatesListener: (props: {
      callback: GetEstimatesListenerCallback;
      clientId: string;
      consultationId?: string;
      patientId?: string;
    }) =>
      getEstimatesListener({
        db,
        organizationId,
        ...props,
      }),
    // getEstimate: (id: string, clientId:string) =>
    //   getEstimate({
    //     db,
    //     organizationId,
    //     id,
    //     clientId
    //   }),
    addEstimate: (data: EstimateData) =>
      addEstimate({
        db,
        organizationId,
        authorId,
        authorName,

        source,
        data,
      }),

    updateEstimate: (id: string, data: Partial<EstimateData>) =>
      updateEstimate({
        db,
        organizationId,
        authorId,
        authorName,

        id,
        source,
        data,
      }),
    deleteEstimate: (id: string) => deleteEstimate({ db, id, organizationId }),

    // editEstimate: (data: Estimate) =>
    //   editEstimate({
    //     db,
    //     organizationId,
    //     authorId,
    //     authorName,
    //     source,
    //     data,
    //   }),
  };
};
