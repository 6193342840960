import { ActionIcon, Affix } from "@mantine/core";
import { IconArrowMoveLeft, IconArrowMoveRight } from "@tabler/icons-react";
import { useIsMobile } from "hooks/useIsMobile";

export interface ResizeButtonProps {
  collapsed: boolean;
  setCollapsed: (b: boolean) => void;
}

export const ResizeButton = ({
  collapsed,
  setCollapsed,
}: ResizeButtonProps) => {
  const isMobie = useIsMobile();
  return (
    <Affix
      position={{
        bottom: isMobie ? 30 : 56,
        left: collapsed ? 26 : isMobie ? 142 : 182,
      }}
    >
      <ActionIcon
        onClick={() => setCollapsed(!collapsed)}
        size="lg"
        radius="xl"
        variant="outline"
        color="gray.4"
        sx={{
          backgroundColor: "white",
          ":hover": {
            backgroundColor: "white",
          },
        }}
      >
        {collapsed ? (
          <IconArrowMoveRight size="1.15rem" />
        ) : (
          <IconArrowMoveLeft size="1.15rem" />
        )}
      </ActionIcon>
    </Affix>
  );
};
