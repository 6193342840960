import { useAppSelector } from "app/hooks";
import { BMultiSelect } from "components";
import { selectActiveInventoryItemLocations } from "features/admin/inventory/Inventory.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InventoryItemLocationsMultiSelectProps {
  required?: boolean;
  onChange?: (value: string[] | undefined) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const InventoryItemLocationsMultiSelect = (
  props: InventoryItemLocationsMultiSelectProps
) => {
  const { t } = useTranslation();
  const locations = useAppSelector(selectActiveInventoryItemLocations);
  const data = locations.map((o) => ({ value: o.id, label: o.name }));

  return (
    <BMultiSelect
      {...props}
      placeholder={t("INVENTORY_ITEM_LOCATIONS_MULTISELECT_PLACEHOLDER")}
      label={t("LOCATIONS")}
      nothingFound={t("NOTHING_FOUND")}
      data={data}
    />
  );
};
