import { UnableToNormalizeData } from "beitary-shared";
import { z } from "zod";
import { communicationHistorySchema } from "./CommunicationHistory";

export const emailCommunicationHistorySchema = communicationHistorySchema.merge(
  z.object({
    message: z
      .object({
        html: z.string(),
        subject: z.string(),
        attachments: z
          .array(
            z.object({
              filename: z.string().optional(),
              path: z.string().optional(),
              content: z.string().optional(),
            })
          )
          .optional(),
      })
      .optional(),
  })
);

export type EmailCommunicationHistory = z.infer<
  typeof emailCommunicationHistorySchema
>;

export const normalizeEmailCommunicationHistory = (
  data: any
): EmailCommunicationHistory => {
  try {
    return emailCommunicationHistorySchema.parse(data);
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};
