import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Collapse,
  Group,
  Image,
  Text,
} from "@mantine/core";
import { IconChevronDown, IconExternalLink } from "@tabler/icons-react";
import { SFile } from "beitary-shared";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface LifecycleFileItemProps {
  item: SFile;
}

export const LifecycleFileItem = ({ item }: LifecycleFileItemProps) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(true);

  return (
    <>
      <Group>
        <ActionIcon onClick={() => setOpened(!opened)}>
          <IconChevronDown
            style={{
              transform: opened ? "rotate(180deg)" : "none",
              transitionDuration: "200ms",
            }}
          />
        </ActionIcon>
        <Text color="cyan" weight={600} size="md">
          {t("BFILE")}
        </Text>
        <Badge size="lg" sx={{ textTransform: "none" }}>
          {t(item.fileCategory)}
        </Badge>
      </Group>

      <Collapse in={opened} transitionDuration={400}>
        <Box mb="xl" p="xs">
          <Group key={item.id} mt="md">
            <Box w={60}>
              {item.contentType.startsWith("image") && (
                <Image radius="md" src={item.thumbURL} />
              )}
            </Box>
            <Text size="xs" weight="bold" color="cyan">
              {HTMLReactParser(item.originalName)}
            </Text>
            <Text size="xs">{`${t("SIZE")} : ${(
              Number(item.fileSize) /
              (1024 * 1024)
            ).toFixed(2)} mb`}</Text>

            <Anchor color="dark" weight={500} href={item.dlURL} target="_blank">
              {<IconExternalLink size={10} />}
            </Anchor>
          </Group>
          {item.comment && (
            <Text size="xs" mt="xs">
              {t("COMMENT")}: <Text>{HTMLReactParser(item.comment ?? "")}</Text>
            </Text>
          )}
        </Box>
      </Collapse>
    </>
  );
};
