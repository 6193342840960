import {
  AbilityBuilder,
  createMongoAbility,
  InferSubjects,
  MongoAbility,
  MongoQuery,
} from "@casl/ability";
import { BFile, OrgPermissions, parseClaims } from "beitary-shared";
import { AuthUser } from "services/auth/authentication/authentication";

//https://stackoverflow.com/questions/76356514/using-casl-v6-with-nestjs
// updated to allow specific type conditions (e.g.: delete file if authorId === file.id)

// TODO check if need custom matcher/pureability
// https://casl.js.org/v6/en/advanced/customize-ability#custom-conditions-matcher-implementation

export enum AbilityAction {
  do = "do",
  am = "am",
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
  manage = "manage",
}

type Subjects = InferSubjects<BFile | any>; // add any specific type here
type PossibleAbilities = [AbilityAction, Subjects];
type Conditions = MongoQuery;

export type AppAbility = MongoAbility<PossibleAbilities, Conditions>;

export const defineAbilityFor = ({
  user,
  orgId,
}: {
  user: AuthUser | null;
  orgId: string | null;
}) => {
  const { can, build } = new AbilityBuilder(
    createMongoAbility<PossibleAbilities, Conditions>
  );
  if (!user || !orgId) return build();
  const parsedClaims = parseClaims(user.claims);

  const permissions =
    Object.keys(parsedClaims.permissions).filter(
      (key) => parsedClaims.permissions[key] === "1"
    ) || [];
  const isDoctor = parsedClaims?.licenseType === "DOCTOR";

  if (
    permissions.includes(OrgPermissions.FINANCIAL_ACCESS) ||
    permissions.includes(OrgPermissions.CLIENTS_PATIENTS_ACCESS) ||
    permissions.includes(OrgPermissions.MEDICATION_ACCESS) ||
    permissions.includes(OrgPermissions.PRACTICE_MANAGEMENT_ACCESS)
  )
    can(AbilityAction.do, "REPORTING");

  if (isDoctor) can(AbilityAction.am, "DOCTOR");
  if (user.claims)
    for (const permission of permissions) {
      can(AbilityAction.do, permission); // can is expecting 2 args so I added "do"
    }

  // other conditions
  can(AbilityAction.delete, "BFile", { authorId: user.uid });

  return build({
    detectSubjectType: (object) => object._typeof,
  });
};
