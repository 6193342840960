import { zodResolver } from "@mantine/form";
import {
  patientExternalIdTypeEnum,
  patientSexEnum,
  patientSpeciesEnum,
  phone_utils,
} from "beitary-shared";
import { z } from "zod";

const newClientFormSchema = z
  .object({
    name: z.string(),
    taxID: z.string().optional(),
    email: z.string().email().optional(),
    phoneNumber: z.string().optional(),
    phoneNumber_countryCode: z.string().optional(),
    thumbURL: z.string().url().optional(),
    discountId: z.string().optional(),
    alert: z.string().optional(),
    additionalInfo: z.string().optional(),
    emailNotif: z.boolean().optional(),
    smsNotif: z.boolean().optional(),
    // address
    address_Line1: z.string().optional(),
    address_Line2: z.string().optional(),
    address_city: z.string().optional(),
    address_stateOrProvince: z.string().optional(),
    address_postalCode: z.string().optional(),
    address_countryCode: z.string().optional(),
    additionalContactInfo: z
      .array(
        z.object({
          id: z.string(),
          type: z.enum(["EMAIL", "PHONE_NUMBER"]),
          description: z.string(),
        })
      )
      .optional(),
    // pet stuff
    petName: z.string(),
    petExternalIDs: z
      .array(
        z.object({
          type: patientExternalIdTypeEnum,
          description: z.string(),
          id: z.string(),
        })
      )
      .optional(),
    petDateOfBirth: z.number().positive().int(),
    petSex: patientSexEnum,
    petSpecies: patientSpeciesEnum,
    petBreed: z.string().optional(),
    petColor: z.string().optional(),
    petPictureURL: z.string().url().optional(),
    petAlert: z.string().optional(),
  })
  .merge(z.object({ hasAgreedToTOSAndPP: z.boolean() }))
  .refine((data) => data.hasAgreedToTOSAndPP, {
    message: "USER_MUST_AGREE_TO_TOS_AND_PP",
  })
  .refine(
    (data) =>
      (!data.phoneNumber && data.phoneNumber_countryCode) ||
      (data.phoneNumber && !data.phoneNumber_countryCode) ||
      (data.phoneNumber &&
        data.phoneNumber_countryCode &&
        phone_utils.parse(data.phoneNumber, data.phoneNumber_countryCode)),
    {
      // t("INVALID_PHONE_NUMBER")
      message: "INVALID_PHONE_NUMBER",
      path: ["phoneNumber"],
    }
  );

export type NewClientFormValues = z.infer<typeof newClientFormSchema>;

export const rules = zodResolver(newClientFormSchema);
