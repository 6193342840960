import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppDispatch } from "app/hooks";
import {
  errorNotification,
  loadingInfoNotification,
  successNotification,
} from "beitary-shared";
import { useCServices } from "hooks/useCService/useCService";
import { useTranslation } from "react-i18next";
import { AuthenticationAPI } from "./AuthenticationAPI";
import { setIsLocked } from "./AuthenticationSlice";

// only use if already authed!
export const useAuthentication = () => {
  const { t } = useTranslation();
  const ath = useCServices().ath;
  const dispatch = useAppDispatch();

  const signOut = async (): Promise<void> => {
    const notificationId = "signout";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: "Signing out",
      })
    );
    var result = await AuthenticationAPI.signOut();
    // console.log("result sign out");
    // console.log(result);
    if (result.type === "success") {
      updateNotification({
        id: notificationId,
        ...successNotification({
          id: notificationId,
          message: "You have been signed out successfully!",
          title: "Signed out!",
        }),
      });
    } else if (result.type === "error") {
      updateNotification({
        id: notificationId,
        ...errorNotification({
          id: notificationId,
          message: result.message,
          title: "Error!",
        }),
      });
    }
  };

  const sendEmailVerifLink = async (): Promise<void> => {
    const notificationId = "email-verif";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        // t("EMAIL_VERIFICATION")
        title: "EMAIL_VERIFICATION",
      })
    );
    var result = await AuthenticationAPI.sendEmailVerifLink();
    if (result.type === "success") {
      updateNotification({
        id: notificationId,
        ...successNotification({
          id: notificationId,
          // t("EMAIL_VERIFIED_MSG")
          message: "EMAIL_VERIFIED_MSG",
        }),
      });
    } else if (result.type === "error") {
      updateNotification({
        id: notificationId,
        ...errorNotification({
          id: notificationId,
          message: result.message,
          // t("ERROR_TITLE")
          title: "ERROR_TITLE",
        }),
      });
    }
  };

  const signInWithPinCode = async (pin: string): Promise<void> => {
    // console.log("sign in");
    // console.log({ email, password });
    const notificationId = "signintoken";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: "Signing in",
      })
    );
    const rToken = await ath.getAuthToken(pin);
    const token = rToken.payload?.token;

    if (!token || rToken.type === "error") {
      updateNotification({
        id: notificationId,
        ...errorNotification({
          id: notificationId,
          message: rToken.message,
          title: "Error!",
        }),
      });
      return;
    }

    var result = await AuthenticationAPI.signInWithToken(token);
    // console.log("result sign in");
    // console.log(result);
    if (result.type === "success" && result.payload) {
      updateNotification({
        id: notificationId,
        ...successNotification({
          id: notificationId,
          message: `Welcome back, ${result.payload.name}!`,
          title: "Signed in!",
        }),
      });

      if (result.payload?.isOrgAcc === "0") {
        dispatch(setIsLocked(false));
      } else {
        dispatch(setIsLocked(true));
      }
    } else if (result.type === "error") {
      updateNotification({
        id: notificationId,
        ...errorNotification({
          id: notificationId,
          message: result.message,
          title: "Error!",
        }),
      });
    }
  };

  return {
    signOut,
    sendEmailVerifLink,
    signInWithPinCode,
  };
};
