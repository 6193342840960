import { conv_utils } from "beitary-shared";

const saveFileFromBase64String = async ({
  content,
  contentType,
  fileName,
}: {
  content: string;
  contentType: string;
  fileName: string;
}) => {
  const blob = new Blob([conv_utils.base64ToBlob(content, contentType)], {
    type: contentType,
  });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url);
};

const saveFileFromURL = async ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading PDF:", error);
    });
};

const printFileFromURL = async ({ url }: { url: string }) => {
  console.log("hi printing");
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobileDevice) {
    // Use window.print() on mobile devices
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      const pdfWindow = window.open(objectURL);

      if (pdfWindow) {
        pdfWindow.onload = () => {
          pdfWindow.print();
          pdfWindow.onafterprint = () => {
            pdfWindow.close();
            URL.revokeObjectURL(objectURL);
          };
        };
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  } else {
    // Use iframe approach on non-mobile devices
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = objectURL;
        document.body.appendChild(iframe);

        iframe.onload = () => {
          iframe.focus();
          const printWindow = iframe.contentWindow;
          if (printWindow) {
            printWindow.onafterprint = () => {
              document.body.removeChild(iframe);
              URL.revokeObjectURL(objectURL);
            };
            printWindow.print();
          }
        };
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  }
};

export const file_util = {
  saveFileFromBase64String,
  saveFileFromURL,
  printFileFromURL,
};
