import { ActionIcon } from "@mantine/core";
import { useSubmitState } from "hooks/useSubmitState";
import { ReactNode } from "react";

export interface AsyncActionIconProps {
  f: Function;
  icon: ReactNode;
  disabled?: boolean;
}

export const AsyncActionIcon = ({
  f,
  icon,
  disabled,
}: AsyncActionIconProps) => {
  const [submitState, setSubmitState] = useSubmitState();

  const submit = async () => {
    setSubmitState("pending-response");
    await f();
    setSubmitState("success");
  };

  return (
    <ActionIcon
      disabled={disabled}
      loading={submitState === "pending-response"}
      onClick={submit}
    >
      {icon}
    </ActionIcon>
  );
};
