import { Group, Table, Text } from "@mantine/core";
import { Patient, PatientWeight, tu } from "beitary-shared";
import { DeleteConfirmationModal } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useTranslation } from "react-i18next";
import { EditPatientWeightModal } from "../../../EditPatientWeightModal";

export interface ListViewProps {
  weights: PatientWeight[];
  patient: Patient;
}

export const ListView = ({ weights, patient }: ListViewProps) => {
  const { t } = useTranslation();
  const { updatePatient } = useDBServices().patientsDBService;

  const getRow = (weight: PatientWeight) => {
    const { id, v: value, t: time, a: authorName } = weight;
    return (
      <tr key={id}>
        <td>
          <Text>{tu.getDateAndTimeString(time)}</Text>
        </td>
        <td>
          <Text>
            {value} {t("kg")}
          </Text>
        </td>
        <td>
          <Text>{authorName}</Text>
        </td>
        <td>
          <Group position="right">
            <EditPatientWeightModal patientWeight={weight} patient={patient} />
            <DeleteConfirmationModal
              f={async () => {
                const newWeights: PatientWeight[] = (
                  patient.weights ?? []
                ).filter((w) => w.id !== id);
                return await updatePatient(patient.id, { weights: newWeights });
              }}
              item={{ id, name: t("WEIGHT") }}
            />
          </Group>
        </td>
      </tr>
    );
  };

  const rows = weights.map((a) => getRow(a));

  return (
    <Table>
      {/* <thead>
    <tr>
      <th>{t("NAME")}</th>
      <th>{t("PATIENT_NAME")}</th>
      <th>{t("DUE_DATE")}</th>
      <th>{t("STATUS")}</th>
      <th></th>
    </tr>
  </thead> */}
      <tbody>{rows}</tbody>
    </Table>
  );
};
