import {
  errorResult,
  normalizeProduct,
  Product,
  ProductData,
  PRODUCT_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

// add organization product

interface AddProduct {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: ProductData;
    id?: string;
  }): Promise<Result<Product | null>>;
}

const addProduct: AddProduct = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
  id,
}) => {
  try {
    // create a new ref to get a new product id
    const newProductRef = id
      ? doc(db, "organizations", organizationId, "products", id)
      : doc(collection(db, "organizations", organizationId, "products"));

    const newProduct: Product = normalizeProduct({
      ...data,
      id: newProductRef.id,
      authorId,
      authorName,
      version: PRODUCT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newProductRef, newProduct);

    // t("PRODUCT_CREATED")
    const successMessage = "PRODUCT_CREATED";
    return successResult({
      message: successMessage,
      payload: newProduct,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetProduct {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<Product | null>>;
}

const getProduct: GetProduct = async ({ db, organizationId, id }) => {
  try {
    const productDocRef = doc(
      db,
      "organizations",
      organizationId,
      "products",
      id
    );
    const productDocSnapshot = await getDoc(productDocRef);
    if (productDocSnapshot.exists()) {
      try {
        const data: unknown = productDocSnapshot.data();
        const product: Product = normalizeProduct(data);
        // t("PRODUCT_FOUND")
        const successMessage = "PRODUCT_FOUND";
        return successResult({
          message: successMessage,
          payload: product,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("PRODUCT_NOT_FOUND")
      const errorMessage = "PRODUCT_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateProduct {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<ProductData>;
  }): Promise<Result<boolean | null>>;
}

const updateProduct: UpdateProduct = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "products", id);

    const updates: Partial<Product> = {
      ...data,
      authorId,
      authorName,
      version: PRODUCT_VERSION,
      source,
      lastUpdatedAt: tu.getCurrentDateTime(),
    };
    await updateDoc(docRef, updates);

    // t("PRODUCT_UPDATED")
    const successMessage = "PRODUCT_UPDATED";
    // console.log(successMessage, updatedProduct);
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteProduct {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteProduct: DeleteProduct = async ({ db, organizationId, id }) => {
  try {
    const docRef = doc(db, "organizations", organizationId, "products", id);

    try {
      await deleteDoc(docRef);
      // t("PRODUCT_DELETED")
      const successMessage = "PRODUCT_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// get organization products listener
interface GetProductsListenerCallback {
  (products: Product[]): void;
}
interface GetProductsListener {
  db: Firestore;
  organizationId: string;
  callback: GetProductsListenerCallback;
}

const getProductsListener = ({
  db,
  organizationId,
  callback,
}: GetProductsListener): Unsubscribe => {
  try {
    // console.log("getProductsListener: new listener");
    const productsQuery = query(
      collection(db, "organizations", organizationId, "products"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(productsQuery, (querySnapshot) => {
      const products: Product[] = [];
      querySnapshot.forEach((doc) => {
        try {
          products.push(normalizeProduct(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(products);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const products = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getProductsListener: (callback: GetProductsListenerCallback) =>
      getProductsListener({
        db,
        organizationId,
        callback,
      }),
    getProduct: (id: string) =>
      getProduct({
        db,
        organizationId,
        id,
      }),
    addProduct: (data: ProductData, id?: string) =>
      addProduct({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
        id,
      }),
    updateProduct: (id: string, data: Partial<ProductData>) =>
      updateProduct({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteProduct: (id: string) =>
      deleteProduct({
        db,
        organizationId,
        id,
      }),
    archiveProduct: (id: string) =>
      updateProduct({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchiveProduct: (id: string) =>
      updateProduct({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
