import {
  Appointment,
  AppointmentData,
  APPOINTMENT_VERSION,
  errorResult,
  normalizeAppointment,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";

// add organization appointment

interface AddAppointment {
  ({
    db,
    authorId,
    authorName,
    source,
    data,
    id,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    source: Source;
    data: AppointmentData;
    id?: string;
  }): Promise<Result<boolean | null>>;
}

const addAppointment: AddAppointment = async ({
  db,
  authorId,
  authorName,
  source,
  data,
  id,
}) => {
  try {
    // create a new ref to get a new appointment id
    const newAppointmentRef = id
      ? doc(db, "appointments", id)
      : doc(collection(db, "appointments"));

    // console.log(newAppointmentRef);

    const newAppointment: Appointment = normalizeAppointment({
      ...data,
      id: newAppointmentRef.id,
      authorId,
      authorName,
      version: APPOINTMENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newAppointmentRef, newAppointment);

    const successMessage = "Appointment created!";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetAppointment {
  ({ db, id }: { db: Firestore; id: string }): Promise<
    Result<Appointment | null>
  >;
}

const getAppointment: GetAppointment = async ({ db, id }) => {
  try {
    const appointmentDocRef = doc(db, "appointments", id);
    const appointmentDocSnapshot = await getDoc(appointmentDocRef);
    if (appointmentDocSnapshot.exists()) {
      try {
        const data: unknown = appointmentDocSnapshot.data();
        const appointment: Appointment = normalizeAppointment(data);
        const successMessage = "Appointment found!";
        return successResult({
          message: successMessage,
          payload: appointment,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      const errorMessage = "Appointment not found!";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface GetClientAppointments {
  ({
    db,
    organizationId,
    clientId,
    patientId,
  }: {
    db: Firestore;
    organizationId: string;
    clientId: string;
    patientId?: string;
  }): Promise<Result<Appointment[] | null>>;
}

const getClientAppointments: GetClientAppointments = async ({
  db,
  clientId,
  patientId,
  organizationId,
}) => {
  try {
    let newQuery = query(
      collection(db, "appointments"),
      where("clientId", "==", clientId),
      where("organizationId", "==", organizationId)
    );

    if (patientId) {
      newQuery = query(newQuery, where("patientId", "==", patientId));
    }

    newQuery = query(newQuery, orderBy("time", "desc"));

    const appointmentsDocSnapshots = await getDocs(newQuery);

    const objs: Appointment[] = [];
    appointmentsDocSnapshots.forEach((doc) => {
      try {
        objs.push(normalizeAppointment(doc.data()));
      } catch (err) {
        console.log(err);
      }
    });

    // t("INVENTORY_TRANSACTION_NOT_FOUND")
    const successMessage = "INVENTORY_TRANSACTION_NOT_FOUND";
    return successResult({
      message: successMessage,
      payload: objs,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdateAppointment {
  ({
    db,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<AppointmentData>;
  }): Promise<Result<boolean | null>>;
}

const updateAppointment: UpdateAppointment = async ({
  db,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const appointmentRef = doc(db, "appointments", id);

    const updates: Partial<Appointment> = {
      ...data,
      authorId,
      authorName,
      version: APPOINTMENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(appointmentRef, updates);

    const successMessage = "Appointment updated!";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization appointments listener
interface GetAppointmentsForWeekListenerCallback {
  (appointments: Appointment[]): void;
}
interface GetAppointmentsListener {
  db: Firestore;
  organizationId: string;
  day: number;
  callback: GetAppointmentsForWeekListenerCallback;
}
//todo make it per day
const getAppointmentsForWeekListener = ({
  db,
  organizationId,
  day,
  callback,
}: GetAppointmentsListener): Unsubscribe => {
  try {
    const weekStart = tu.getStartOfWeek(tu.getDate(day));
    const weekEnd = weekStart + 7 * 24 * 60 * 60 * 1000;
    // console.log("getAppointmentsListener: new listener");

    // console.log(day + 24 * 60 * 60 * 1000);

    const appointmentsQuery = query(
      collection(db, "appointments"),
      where("organizationId", "==", organizationId),
      where("time", ">=", weekStart),
      where("time", "<=", weekEnd),
      orderBy("time", "asc")
    );
    return onSnapshot(appointmentsQuery, (querySnapshot) => {
      const appointments: Appointment[] = [];
      querySnapshot.forEach((doc) => {
        try {
          appointments.push(normalizeAppointment(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(appointments);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// get client upcoming appointments listener
interface GetClientUpcomingAppointmentsListenerCallback {
  (appointments: Appointment[]): void;
}
interface GetClientUpcomingAppointmentsListener {
  db: Firestore;
  organizationId: string;
  clientId: string;
  patientId?: string;
  callback: GetClientUpcomingAppointmentsListenerCallback;
}
//todo make it per day
const getClientUpcomingAppointmentsListener = ({
  db,
  organizationId,
  clientId,
  patientId,
  callback,
}: GetClientUpcomingAppointmentsListener): Unsubscribe => {
  try {
    let appointmentsQuery = query(
      collection(db, "appointments"),
      where("organizationId", "==", organizationId),
      where("clientId", "==", clientId)
    );

    if (patientId) {
      appointmentsQuery = query(
        appointmentsQuery,
        where("patientId", "==", patientId)
      );
    }
    appointmentsQuery = query(
      appointmentsQuery,
      where("time", ">=", tu.getCurrentDateTime()),
      orderBy("time", "asc")
    );
    return onSnapshot(appointmentsQuery, (querySnapshot) => {
      const appointments: Appointment[] = [];
      querySnapshot.forEach((doc) => {
        try {
          appointments.push(normalizeAppointment(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(appointments);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const appointments = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getAppointmentsForWeekListener: ({
      day,
      callback,
    }: {
      day: number;
      callback: GetAppointmentsForWeekListenerCallback;
    }) =>
      getAppointmentsForWeekListener({
        db,
        organizationId,
        day,
        callback,
      }),
    getAppointment: (id: string) =>
      getAppointment({
        db,
        id,
      }),
    getClientUpcomingAppointmentsListener: ({
      clientId,
      patientId,
      callback,
    }: {
      clientId: string;
      patientId?: string;
      callback: GetClientUpcomingAppointmentsListenerCallback;
    }) =>
      getClientUpcomingAppointmentsListener({
        db,
        clientId,
        patientId,
        organizationId,
        callback,
      }),
    getClientAppointments: ({
      clientId,
      patientId,
    }: {
      clientId: string;
      patientId?: string;
    }) => getClientAppointments({ db, clientId, organizationId, patientId }),
    addAppointment: ({ data, id }: { data: AppointmentData; id?: string }) =>
      addAppointment({
        db,
        authorId,
        authorName,
        source,
        data,
        id,
      }),
    editAppointment: (id: string, data: Partial<AppointmentData>) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    checkInAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CHECKED_IN",
        },
      }),
    checkOutAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CHECKED_OUT",
        },
      }),
    cancelAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CANCELED",
        },
      }),
    noShowAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "NO_SHOW",
        },
      }),
    rescheduleAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "BOOKED",
        },
      }),
    undoCheckinAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "BOOKED",
        },
      }),
    undoNoShowAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "BOOKED",
        },
      }),
    undoCheckoutAppointment: (id: string) =>
      updateAppointment({
        db,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "CHECKED_IN",
        },
      }),
  };
};
