import {
  ActionIcon,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Group,
  Input,
  Modal,
  NumberInput,
  Radio,
  Stack,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons-react";
import {
  getNotificationByResultType,
  InventoryItem,
  loadingInfoNotification,
  normalizeProduct,
  obju,
  patientSexEnum,
  Product,
  Result,
  tu,
} from "beitary-shared";
import {
  BBox,
  BDateInput,
  BTextEditor,
  DueTimeOptionsSelect,
  SaveButton,
} from "components";
import { BMoneyInput } from "components/BMoneyInput";
import { MoveBackButton } from "components/MoveBackButton";
import { ItemForm } from "features/admin/inventory/components/Items/components/Items/components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { mu } from "utils/money_utils";
import { AdministrationRoutesMultiSelect } from "../AdministrationRoutesMultiSelect";
import { CategorySelect } from "../CategorySelect";
import { DoseBaseSelect } from "../DoseBaseSelect";
import { InventoryItemSelect } from "../InventoryItemSelect";
import { ProductConcentrationSolventUnitSelect } from "../ProductConcentrationSolventUnitSelect";
import { TypeSelect } from "../TypeSelect";
import { ProductFormValues, rules } from "./ProductForm.rules";

export interface ProductFormProps {
  product?: Product;
  invItem?: InventoryItem;
  nested?: boolean;
}

export const ProductForm = ({ product, invItem, nested }: ProductFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateProduct, addProduct } =
    useDBServices().catalogDBService.products;
  const [newInvItemModalOpened, setNewInvItemModalOpenedOpened] =
    useState(false);

  const form = useForm<ProductFormValues>({
    initialValues: product ? normalizeProduct(product) : undefined,
    validate: rules,
  });

  if (invItem && !form.values.hasLII) {
    const { costPerUnitOfMeasurement, name, id, code, units } = invItem;
    const values: Partial<ProductFormValues> = {
      hasLII: true,
      linkedInventoryItemId: id,
      name,
      code,
      unitSellingPrice: mu.multiply({
        amount: costPerUnitOfMeasurement,
        multiplier: 1 / (units ?? 1),
      }),
    };
    form.setValues(values);
  }
  if (!form.values.status) form.setFieldValue("status", "ACTIVE");
  if (form.values.taxRate === undefined) form.setFieldValue("taxRate", 0.19);
  if (form.values.printOnInvoice === undefined)
    form.setFieldValue("printOnInvoice", true);

  const submit = async (values: ProductFormValues) => {
    obju.removeUndefined(values);
    const notificationId = "submit-catalog-product";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t(product ? "UPDATE_PRODUCT" : "ADD_PRODUCT"),
      })
    );
    setSubmitState("pending-response");
    let result: Result<Product | boolean | null>;
    if (product) {
      result = await updateProduct(product.id, values);
    } else {
      result = await addProduct(values);
    }
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  const shouldHaveAdditionInformation =
    form.values.type === "MEDICATION" || form.values.type === "VACCINE";

  const shouldHaveDoseSection =
    form.values.type === "MEDICATION" || form.values.type === "VACCINE";

  const shouldHaveUpdatePatientSection = form.values.type === "SERVICE";

  return (
    <Stack>
      <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
        <Stack spacing="xl">
          {!nested && (
            <Group>
              <MoveBackButton />
            </Group>
          )}
          <BBox
            header={
              product ? (
                <Text weight={500}>{t("EDIT_PRODUCT")}</Text>
              ) : (
                <Text weight={500}>{t("NEW_PRODUCT")}</Text>
              )
            }
          >
            <Stack p="xl">
              <TypeSelect
                required
                // disabled={!!product} TODO
                {...form.getInputProps("type")}
              />
              <CategorySelect required {...form.getInputProps("categoryId")} />

              <TextInput
                required
                placeholder={t("ENTER_NAME")}
                label={t("NAME")}
                {...form.getInputProps("name")}
              />

              <TextInput
                placeholder={t("ITEM_CODE_PLACEHOLDER")}
                label={t("CODE")}
                {...form.getInputProps("code")}
              />

              <Group grow spacing="xl" align="flex-end">
                <BMoneyInput
                  required
                  label={t("SELLING_PRICE_NO_TAXES")}
                  description={t("PER_UNIT")}
                  {...form.getInputProps("unitSellingPrice")}
                  min={0}
                />
                <BMoneyInput
                  required
                  label={t("SELLING_PRICE")}
                  description={t("PER_UNIT")}
                  {...form.getInputProps("unitSellingPrice")}
                  min={0}
                  value={mu.multiply({
                    amount: form.values.unitSellingPrice ?? 0,
                    multiplier: 1 * (1 + form.values.taxRate),
                  })}
                  onChange={(v) => {
                    if (v !== "") {
                      form.setFieldValue(
                        "unitSellingPrice",
                        mu.multiply({
                          amount: v,
                          multiplier: 1 / (1 + form.values.taxRate),
                        })
                      );
                    }
                  }}
                />
                {/* <BMoneyInput
                  required
                  label={t("DISPENSING_FEES")}
                  description={t("FEES_FOR_ADMINISTRATING_THE_PRODUCT")}
                  {...form.getInputProps("dispFees")}
                  min={0}
                />
                <BMoneyInput
                  required
                  label={t("MIN_PRICE")}
                  description={t("REGARDLESS_OF_OTHER_UNITS_OR_FEES")}
                  {...form.getInputProps("minPrice")}
                  min={0}
                /> */}
                <NumberInput
                  required
                  label={t("TAXES")}
                  description={t("%")}
                  {...form.getInputProps("taxRate")}
                  value={form.values.taxRate * 100}
                  onChange={(v) => {
                    if (v !== "") {
                      form.setFieldValue(
                        "taxRate",
                        Number(Math.round(parseFloat(v / 100 + "e4")) + "e-4")
                      );
                    }
                  }}
                  min={0}
                  precision={2}
                />
              </Group>
              <Textarea
                placeholder={t("ENTER_NOTES")}
                label={t("NOTES")}
                {...form.getInputProps("notes")}
              />
            </Stack>
          </BBox>

          {/* linked item */}
          <BBox
            header={
              <Group position="apart">
                <Text weight={500}>{t("LINK_INVENTORY_ITEM")}</Text>
                {!invItem && (
                  <Switch
                    {...form.getInputProps("hasLII", { type: "checkbox" })}
                  />
                )}
              </Group>
            }
          >
            <Collapse in={form.values.hasLII ?? false} p="xl">
              <Stack>
                <Group align="flex-end">
                  <InventoryItemSelect
                    disabled={!!invItem}
                    required={form.values.hasLII}
                    {...form.getInputProps("linkedInventoryItemId")}
                    onChange={(v) => {
                      form.setFieldValue("linkedInventoryItemId", v?.id);
                    }}
                  />
                  {form.values.linkedInventoryItemId && (
                    <ActionIcon
                      onClick={() =>
                        navigate(
                          `/admin/inventory/inventory-items/items/edit/${form.values.linkedInventoryItemId}`
                        )
                      }
                    >
                      <IconEdit size={18} />
                    </ActionIcon>
                  )}
                  {!nested && (
                    <Button
                      onClick={() => setNewInvItemModalOpenedOpened(true)}
                    >
                      {t("NEW_ITEM")}
                    </Button>
                  )}
                </Group>
                <Group align="flex-end">
                  <Text>{t("ONE_UNIT_OF_THE_PRODUCT_REPRESENTS")}</Text>
                  <NumberInput
                    // disabled={!form.values.linkedInventoryItemId}
                    required={form.values.hasLII}
                    label={t("NUMBER_OF_UNITS")}
                    {...form.getInputProps("unitRatio")}
                    min={1}
                  />
                  <Text>{t("OF_THE_INVENTORY_ITEM")}</Text>
                </Group>
              </Stack>
            </Collapse>
          </BBox>

          {/* additional information */}
          {shouldHaveAdditionInformation && (
            <BBox
              header={<Text weight={500}>{t("ADDITIONAL_INDORMATION")}</Text>}
            >
              <Collapse in={true} p="xl">
                <Stack>
                  {shouldHaveDoseSection && (
                    <>
                      {/* <Group align="flex-end">
                      <NumberInput
                        required={shouldHaveDoseSection}
                        label={t("CONCENTRATION")}
                        {...form.getInputProps("ccAmount")}
                        precision={exponent}
                        min={0}
                      />
                      <ProductConcentrationSoluteUnitSelect
                        required={shouldHaveDoseSection}
                        {...form.getInputProps("ccSoluteUnit")}
                      />
                      <Text>/</Text>
                      <ProductConcentrationSolventUnitSelect
                        required={shouldHaveDoseSection}
                        {...form.getInputProps("ccSolventUnit")}
                      />
                    </Group> */}

                      <Group align="flex-end">
                        <NumberInput
                          required={shouldHaveDoseSection}
                          label={t("DOSE")}
                          {...form.getInputProps("dose")}
                          precision={2}
                          step={0.1}
                          removeTrailingZeros={true}
                          min={0}
                        />
                        <ProductConcentrationSolventUnitSelect
                          required={shouldHaveDoseSection}
                          {...form.getInputProps("ccSolventUnit")}
                        />
                        {/* <Select
                        data={[form.values.ccSolventUnit ?? ""]}
                        readOnly
                        required={shouldHaveDoseSection}
                        value={form.values.ccSolventUnit}
                      /> */}
                        <Text>{"/"}</Text>
                        <DoseBaseSelect
                          required={shouldHaveDoseSection}
                          {...form.getInputProps("doseBase")}
                        />
                      </Group>

                      <Divider />
                    </>
                  )}
                  <AdministrationRoutesMultiSelect
                    {...form.getInputProps("adminRoutes")}
                  />
                  <Textarea
                    label={t("ADMINISTRATION_INSTRUCTIONS")}
                    placeholder={t("Instructions for internal use")}
                    {...form.getInputProps("adminInstr")}
                  />

                  <Divider />
                  <Group grow spacing="xl">
                    <TextInput
                      label={t("SERIAL_NUMBER")}
                      {...form.getInputProps("serialNumber")}
                      min={1}
                    />
                    <TextInput
                      label={t("LOT_NUMBER")}
                      {...form.getInputProps("lotNumber")}
                      min={1}
                    />
                    <BDateInput
                      label={t("EXPIRATION_DATE")}
                      {...form.getInputProps("lotExpirationDate")}
                      minDateTime={tu.getCurrentDateTime()}
                    />
                  </Group>
                </Stack>
              </Collapse>
            </BBox>
          )}

          {/* automatic task */}
          <BBox
            header={
              <Group position="apart">
                <Text weight={500}>{t("AUTOMATIC_TASK")}</Text>
                <Switch
                  {...form.getInputProps("hasTask", { type: "checkbox" })}
                />
              </Group>
            }
          >
            <Collapse in={form.values.hasTask ?? false} p="xl">
              <Stack>
                <TextInput
                  required={form.values.hasTask}
                  label={t("TITLE")}
                  {...form.getInputProps("taskName")}
                  min={1}
                />
                <Group align="flex-end">
                  <NumberInput
                    required={form.values.hasTask}
                    label={t("DUE")}
                    {...form.getInputProps("taskDueDate")}
                    min={1}
                  />
                  <DueTimeOptionsSelect
                    label={t("PERIOD")}
                    required={form.values.hasTask}
                    {...form.getInputProps("taskPeriod")}
                  />
                  <Text>{t("FROM_ADMINISTRATION_TIME")}</Text>
                </Group>
              </Stack>
            </Collapse>
          </BBox>

          {/* other */}
          <BBox header={<Text weight={500}>{t("OTHER")}</Text>}>
            <Stack p="xl">
              <Checkbox
                label={t("CONTROLLED")}
                {...form.getInputProps("controlled", {
                  type: "checkbox",
                })}
              />
              <Checkbox
                label={t("DISCOUNTABLE")}
                {...form.getInputProps("discountable", {
                  type: "checkbox",
                })}
              />
              <Checkbox
                label={t("INCLUDE_IN_MEDICAL_RECORD")}
                {...form.getInputProps("includeInMR", {
                  type: "checkbox",
                })}
              />
              <Checkbox
                label={t("PRINT_ON_INVOICE")}
                {...form.getInputProps("printOnInvoice", {
                  type: "checkbox",
                })}
              />
            </Stack>
          </BBox>

          {/* medical record */}
          <BBox
            header={
              <Group position="apart">
                <Text weight={500}>{t("MEDICAL_RECORD_NOTES")}</Text>
                <Switch
                  {...form.getInputProps("hasMRN", { type: "checkbox" })}
                />
              </Group>
            }
          >
            <Collapse in={form.values.hasMRN ?? false} p="xl">
              <Stack>
                <Input.Wrapper
                  required={form.values.hasMRN}
                  label={t("DEFAULT_MEDICAL_RECORD_NOTES")}
                >
                  <BTextEditor {...form.getInputProps("mrNotes")} />
                </Input.Wrapper>
              </Stack>
            </Collapse>
          </BBox>

          {/* discharge instructions */}
          <BBox
            header={
              <Group position="apart">
                <Text weight={500}>{t("DISCHARGE_INSTRUCTIONS")}</Text>
                <Switch
                  {...form.getInputProps("hasDischInstr", { type: "checkbox" })}
                />
              </Group>
            }
          >
            <Collapse in={form.values.hasDischInstr ?? false} p="xl">
              <Stack>
                <Input.Wrapper
                  required={form.values.hasDischInstr}
                  label={t("DEFAULT_DISCHARGE_INSTRUCTIONS")}
                >
                  <BTextEditor {...form.getInputProps("dischInstr")} />
                </Input.Wrapper>
              </Stack>
            </Collapse>
          </BBox>

          {/* prescription */}
          <BBox
            header={
              <Group position="apart">
                <Text weight={500}>{t("PRESCRIPTION_DIRECTIONS")}</Text>
                <Switch
                  {...form.getInputProps("hasPrespDir", { type: "checkbox" })}
                />
              </Group>
            }
          >
            <Collapse in={form.values.hasPrespDir ?? false} p="xl">
              <Stack>
                <Input.Wrapper
                  required={form.values.hasPrespDir}
                  label={t("DEFAULT_PRESCRIPTION_DIRECTIONS")}
                >
                  <BTextEditor {...form.getInputProps("prespDir")} />
                </Input.Wrapper>
              </Stack>
            </Collapse>
          </BBox>

          {/* update patient status */}
          {shouldHaveUpdatePatientSection && (
            <BBox
              header={
                <Group position="apart">
                  <Text weight={500}>{t("PATIENT_STATUS")}</Text>
                  <Switch
                    {...form.getInputProps("hasPatStat", { type: "checkbox" })}
                  />
                </Group>
              }
            >
              <Collapse in={form.values.hasPatStat ?? false} p="xl">
                <Stack>
                  <Radio.Group
                    label={t("UPDATE_PATIENT_STATUS")}
                    required={form.values.hasPatStat}
                    {...form.getInputProps("patStat")}
                  >
                    <Stack m="sm">
                      <Radio
                        value={patientSexEnum.Values.MALE_NEUTERED}
                        label={t("MALE_NEUTERED")}
                      />
                      <Radio
                        value={patientSexEnum.Values.FEMALE_SPAYED_OV}
                        label={t("FEMALE_SPAYED_OV")}
                      />
                      <Radio
                        value={patientSexEnum.Values.FEMALE_SPAYED_HY}
                        label={t("FEMALE_SPAYED_HY")}
                      />
                      <Radio
                        value={patientSexEnum.Values.FEMALE_SPAYED_OV_HY}
                        label={t("FEMALE_SPAYED_OV_HY")}
                      />
                      <Radio value="DECEASED" label={t("DECEASED")} />
                    </Stack>
                  </Radio.Group>
                </Stack>
              </Collapse>
            </BBox>
          )}

          <Group position="right">
            <SaveButton state={submitState} canSave={form.isValid()} />
          </Group>
        </Stack>
      </form>
      {!nested && (
        <Modal
          size="xl"
          opened={newInvItemModalOpened}
          onClose={() => setNewInvItemModalOpenedOpened(false)}
          closeOnClickOutside={false}
          overlayProps={{
            color: "white",
            opacity: 0.55,
            blur: 3,
          }}
        >
          <ItemForm nested partialProduct={form.values} />
        </Modal>
      )}
    </Stack>
  );
};
