import {
  Button,
  Center,
  Group,
  Modal,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconAlertCircle, IconRubberStamp } from "@tabler/icons-react";
import { useSubmitState } from "hooks/useSubmitState";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface BButtonStampProps {
  f: Function;
  disabled?: boolean;
}

export const BButtonStamp = ({ f, disabled }: BButtonStampProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();

  const submit = async () => {
    setSubmitState("pending-response");
    setOpened(false);
    await f();
    setSubmitState("success");
  };

  return (
    <>
      <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        closeOnClickOutside={false}
        overlayProps={{
          color: "white",
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Stack pb="xl" align="center" spacing={0}>
          <ThemeIcon size={140} color="red.4" radius={70} variant="light">
            <IconAlertCircle size={100} />
          </ThemeIcon>
          <Text weight="bold" mt="xl">
            {t("YOU_ARE_ABOUT_TO_FINALIZE_A_DOCUMENT")}
          </Text>
          <Text mt="xl">{t("THIS_ACTION_CANNOT_BE_UNDONE")}</Text>
          <Text>{t("ARE_YOU_SURE")}</Text>
        </Stack>
        <Group mt="xl" position="right">
          <Button color="red" onClick={submit}>
            {t("CONFIRM")}
          </Button>
          <Button variant="outline" onClick={() => setOpened(false)}>
            {t("CANCEL")}
          </Button>
        </Group>
      </Modal>
      <Button
        variant="outline"
        loading={submitState === "pending-response"}
        onClick={() => setOpened(true)}
        disabled={disabled}
      >
        <Center>
          <IconRubberStamp size={18} />
        </Center>
      </Button>
    </>
  );
};
