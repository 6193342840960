import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { MemberRole, OrgPermissions } from "beitary-shared";
import { BDataTable, DeleteConfirmationModal } from "components";
import { selectMemberRoles } from "features/admin/members/Members.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type MemberRolesTableItem = MemberRole & { actions?: undefined };

export const MemberRolesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const memberRoles = useAppSelector(selectMemberRoles);

  const tableItems: MemberRolesTableItem[] = memberRoles.map((memberRole) => ({
    ...memberRole,
  }));

  const { deleteMemberRole } =
    useDBServices().organizationMembersDBService.memberRoles;

  const columns: BDataTableColumn<MemberRolesTableItem>[] = [
    { accessor: "name", title: <Text>{t("NAME")}</Text>, sortable: true },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (memberRole) => (
        <BCan I="do" a={OrgPermissions.ROLES_CUD}>
          {memberRole.id !== "PRACTICEOWNER" &&
            memberRole.id !== "CLINICLOGIN" && (
              <Group position="right">
                <ActionIcon onClick={() => navigate(`edit/${memberRole.id}`)}>
                  <IconEdit size={18} />
                </ActionIcon>
                <DeleteConfirmationModal
                  f={async () => deleteMemberRole(memberRole.id)}
                  item={memberRole}
                />
              </Group>
            )}
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
