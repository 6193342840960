import {
  Box,
  Container,
  Group,
  SegmentedControl,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconCalendar, IconList } from "@tabler/icons-react";
import { Appointment, tu } from "beitary-shared";
import { BDateInput, MoveBackButton } from "components";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentModal, CalendarView, ListView } from "./components";

interface ViewProps {
  selectedDate: number;
  setSelectedDate: Function;
  appointments: Appointment[]; // weekAppointments
}

export const View = ({
  selectedDate,
  setSelectedDate,
  appointments,
}: ViewProps) => {
  const { t } = useTranslation();

  const [view, setView] = useState<string>("calendar");
  const [viewType, setViewType] = useState<string>("day");

  const startTime = useMemo(() => {
    return Math.min(8, ...appointments.map((a) => tu.getHour(a.time))); // TODO add org start/end time
  }, [appointments]);

  const endTime = useMemo(() => {
    const r = Math.max(
      18,
      ...appointments.map((a) => {
        let r = tu.getHour(a.time) + a.duration / 60;
        if (!Number.isInteger(a.time / (60 * 60 * 1000))) r = r + 1;
        return r;
      })
    );
    return r === 0 ? 24 : r; // TODO add org start/end time
  }, [appointments]);

  const selectedDateAppointments = useMemo(() => {
    return appointments.filter(
      (a) =>
        a.time <= selectedDate + 24 * 60 * 60 * 1000 && a.time >= selectedDate
    );
  }, [appointments, selectedDate]);

  const weekAppointments = useMemo(() => {
    const appointmentsByDayMap: { [x: string]: Appointment[] } = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };

    // Loop through each appointment and assign it to the corresponding day
    appointments.forEach((appointment) => {
      const day = tu.getDayOfWeek(appointment.time);
      if (!appointmentsByDayMap[day]) {
        appointmentsByDayMap[day] = [];
      }
      appointmentsByDayMap[day].push(appointment);
    });

    return Object.values(appointmentsByDayMap);
  }, [appointments]);

  return (
    <Container fluid mt="xl">
      <MoveBackButton />
      <Title mt="xl" weight={500} order={3}>
        {t("APPOINTMENTS")}
      </Title>

      <Stack mt="xl">
        <Group position="apart" align="flex-end">
          <Group align="flex-end" mb="xs">
            <BDateInput
              label={t("SELECT_DATE")}
              onChange={(v) =>
                v &&
                setSelectedDate(
                  tu.getDateValueAtHMSM(tu.getDate(v), 0, 0, 0, 0)
                )
              }
              value={selectedDate}
            />
            <AppointmentModal />
          </Group>
          <Group>
            {" "}
            <SegmentedControl
              color="cyan"
              value={view}
              onChange={setView}
              data={[
                {
                  label: (
                    <Box
                      w={36}
                      h={36}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconList />
                    </Box>
                  ),
                  value: "list",
                },
                {
                  label: (
                    <Box
                      w={36}
                      h={36}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconCalendar />
                    </Box>
                  ),
                  value: "calendar",
                },
              ]}
            />
            <SegmentedControl
              color="cyan"
              value={viewType}
              onChange={setViewType}
              data={[
                {
                  label: (
                    <Box
                      miw={60}
                      h={36}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text>{t("DAY")}</Text>
                    </Box>
                  ),
                  value: "day",
                },
                {
                  label: (
                    <Box
                      miw={60}
                      h={36}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text>{t("WEEK")}</Text>
                    </Box>
                  ),
                  value: "week",
                },
              ]}
            />
          </Group>
        </Group>
        {view === "calendar" ? (
          <CalendarView
            dayAppointments={selectedDateAppointments}
            viewType={viewType}
            weekAppointments={weekAppointments}
            selectedDate={selectedDate}
            startTime={startTime}
            endTime={endTime}
          />
        ) : (
          <ListView
            dayAppointments={selectedDateAppointments}
            viewType={viewType}
            weekAppointments={weekAppointments}
            selectedDate={selectedDate}
          />
        )}
      </Stack>
    </Container>
  );
};
