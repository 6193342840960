import {
  Anchor,
  Avatar,
  Badge,
  Group,
  SimpleGrid,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconExclamationMark,
  IconExternalLink,
  IconPaw,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Patient, tu } from "beitary-shared";
import { AlertTooltip, BBox } from "components";
import { selectPatientsMap } from "features/Clients/Clients.slice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface PatientInfoProps {
  patientId: string;
  patientName: string;
}

export const PatientInfo = ({ patientId, patientName }: PatientInfoProps) => {
  const { t } = useTranslation();

  const patient: Patient | undefined =
    useAppSelector(selectPatientsMap)[patientId];

  if (!patient)
    return (
      <BBox
        header={
          <Group spacing="xs">
            <Tooltip label={t("PATIENT_NOT_FOUND")} position="right-end">
              <ThemeIcon color="red" radius="xl">
                <IconExclamationMark stroke={2.5} size={20} />
              </ThemeIcon>
            </Tooltip>
            <Text weight={500}> {patientName}</Text>
          </Group>
        }
      />
    );

  return (
    <BBox
      bgcs={0}
      header={
        <Group position="apart">
          <Group spacing={4}>
            <Avatar src={patient.thumbURL} size={40} radius="xl" mr="xs">
              <IconPaw size={25} />
            </Avatar>
            <Anchor
              color="dark"
              weight={500}
              lineClamp={1}
              component={Link}
              to={`/clients/${patient.ownerId}/patients/${patient.id}`}
            >
              {patient.name} {<IconExternalLink size={12} />}
            </Anchor>
            {patient.alert && <AlertTooltip alert={patient.alert} size={20} />}
            <Badge size="lg" radius="xs" ml="xl">
              {t(patient.status)}
            </Badge>
          </Group>
        </Group>
      }
    >
      <SimpleGrid p="xl" cols={2}>
        <div>
          <Text color="cyan">{t("SEX")}</Text>
          <Text>{t(patient.sex)}</Text>
        </div>
        <div>
          <Text color="cyan">{t("DATE_OF_BIRTH")}</Text>
          <Text>{tu.getDateString(patient.dateOfBirth) ?? "-"}</Text>
        </div>
        <div>
          <Text color="cyan">{t("SPECIES")}</Text>
          <Text>{t(patient.species)}</Text>
        </div>
        <div>
          <Text color="cyan">{t("BREED")}</Text>
          <Text>{patient.breed ?? "-"}</Text>
        </div>{" "}
        <div>
          <Text color="cyan">{t("COLOR")}</Text>
          <Text>{patient.color ?? "-"}</Text>
        </div>
      </SimpleGrid>
    </BBox>
  );
};
