import { ActionIcon, Box, Group, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { AppointmentType, OrgPermissions } from "beitary-shared";
import {
  ArchiveConfirmationModal,
  BDataTable,
  DearchiveConfirmationModal,
  DeleteConfirmationModal,
} from "components";
import { selectAppointmentTypes } from "features/admin/schedule-settings/ScheduleSettings.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { BCan } from "permissions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BDataTableColumn } from "schemas-types";

type AppointmentTypesTableItem = AppointmentType & { actions?: undefined };

export const AppointmentTypesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const appointmentTypes = useAppSelector(selectAppointmentTypes);

  const tableItems: AppointmentTypesTableItem[] = appointmentTypes.map(
    (appointmentType) => ({
      ...appointmentType,
    })
  );

  const {
    deleteAppointmentType,
    archiveAppointmentType,
    dearchiveAppointmentType,
  } = useDBServices().scheduleSettingsDBService.appointmentTypes;

  const columns: BDataTableColumn<AppointmentTypesTableItem>[] = [
    {
      accessor: "name",
      title: <Text>{t("NAME")}</Text>,
      sortable: true,
    },
    {
      accessor: "color",
      title: <Text>{t("COLOR")}</Text>,
      sortable: true,
      render: ({ color }) => (
        <Box
          sx={{
            backgroundColor: color,
            borderRadius: 10,
            width: 20,
            height: 20,
          }}
        ></Box>
      ),
    },
    {
      accessor: "status",
      title: <Text>{t("STATUS")}</Text>,
      sortable: true,
      render: ({ status }) => (
        <Text color={status === "ACTIVE" ? "dark" : "dimmed"}>{t(status)}</Text>
      ),
    },
    {
      accessor: "actions",
      title: "",
      width: 140,
      render: (appointmentType) => (
        <BCan I="do" a={OrgPermissions.SCHEDULE_CUD}>
          <Group position="right">
            <ActionIcon onClick={() => navigate(`edit/${appointmentType.id}`)}>
              <IconEdit size={18} />
            </ActionIcon>
            {appointmentType.status === "ARCHIVED" ? (
              <DearchiveConfirmationModal
                f={async () =>
                  dearchiveAppointmentType(appointmentType.id, appointmentType)
                }
                item={appointmentType}
              />
            ) : (
              <ArchiveConfirmationModal
                f={async () =>
                  archiveAppointmentType(appointmentType.id, appointmentType)
                }
                item={appointmentType}
              />
            )}
            <DeleteConfirmationModal
              f={async () => deleteAppointmentType(appointmentType.id)}
              item={appointmentType}
            />
          </Group>
        </BCan>
      ),
    },
  ];

  return (
    <BDataTable data={tableItems} columns={columns} defaultSortKey={"name"} />
  );
};
