import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";

export interface EditButtonProps {
  f?: () => void;
}

export const EditButton = ({ f }: EditButtonProps) => {
  const { t } = useTranslation();

  return <Button onClick={f}>{t("EDIT")}</Button>;
};
