import {
  ClinicLocation,
  ClinicLocationData,
  CLINIC_LOCATION_VERSION,
  errorResult,
  normalizeClinicLocation,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
} from "firebase/firestore";

// add organization clinicLocation

interface AddClinicLocation {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: ClinicLocationData;
  }): Promise<Result<ClinicLocation | null>>;
}

const addClinicLocation: AddClinicLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new clinicLocation id
    const newClinicLocationRef = doc(
      collection(db, "organizations", organizationId, "clinic_locations")
    );

    const newClinicLocation: ClinicLocation = normalizeClinicLocation({
      ...data,
      id: newClinicLocationRef.id,
      authorId,
      authorName,
      version: CLINIC_LOCATION_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newClinicLocationRef, newClinicLocation);

    // t("CLINIC_LOCATION_CREATED")
    const successMessage = "CLINIC_LOCATION_CREATED";
    return successResult({
      message: successMessage,
      payload: newClinicLocation,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface GetClinicLocation {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<ClinicLocation | null>>;
}

const getClinicLocation: GetClinicLocation = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const clinicLocationDocRef = doc(
      db,
      "organizations",
      organizationId,
      "clinic_locations",
      id
    );
    const clinicLocationDocSnapshot = await getDoc(clinicLocationDocRef);
    if (clinicLocationDocSnapshot.exists()) {
      try {
        const data: unknown = clinicLocationDocSnapshot.data();
        const clinicLocation: ClinicLocation = normalizeClinicLocation(data);
        // t("CLINIC_LOCATION_FOUND")
        const successMessage = "CLINIC_LOCATION_FOUND";
        return successResult({
          message: successMessage,
          payload: clinicLocation,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } else {
      // doc.data() will be undefined in this case
      // t("CLINIC_LOCATION_NOT_FOUND")
      const errorMessage = "CLINIC_LOCATION_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface EditClinicLocation {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: ClinicLocationData;
  }): Promise<Result<ClinicLocation | null>>;
}

const editClinicLocation: EditClinicLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const clinicLocation = (await getClinicLocation({ db, organizationId, id }))
      .payload;

    if (!clinicLocation) {
      // t("CLINIC_LOCATION_NOT_FOUND")
      const errorMessage = "CLINIC_LOCATION_NOT_FOUND";
      console.log(errorMessage);
      return errorResult({ message: errorMessage });
    } else {
      const { createdAt } = clinicLocation;
      const updatedClinicLocation: ClinicLocation = normalizeClinicLocation({
        ...data,
        id,
        authorId,
        authorName,
        version: CLINIC_LOCATION_VERSION,
        source,
        createdAt,
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      const clinicLocationRef = doc(
        db,
        "organizations",
        organizationId,
        "clinic_locations",
        id
      );

      await setDoc(clinicLocationRef, updatedClinicLocation);

      // t("CLINIC_LOCATION_UPDATED")
      const successMessage = "CLINIC_LOCATION_UPDATED";
      // console.log(successMessage, updatedClinicLocation);
      return successResult({
        message: successMessage,
        payload: updatedClinicLocation,
      });
    }
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeleteClinicLocation {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteClinicLocation: DeleteClinicLocation = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "clinic_locations",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("CLINIC_LOCATION_DELETED")
      const successMessage = "CLINIC_LOCATION_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

const archiveClinicLocation: EditClinicLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: ClinicLocationData = {
      ...data,
      status: "ARCHIVED",
    };

    const result = await editClinicLocation({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("CLINIC_LOCATION_ARCHIVED")
    const successMessage = "CLINIC_LOCATION_ARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

const dearchiveClinicLocation: EditClinicLocation = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const archivedObj: ClinicLocationData = {
      ...data,
      status: "ACTIVE",
    };

    const result = await editClinicLocation({
      authorId,
      authorName,
      db,
      id,
      organizationId,
      source,
      data: archivedObj,
    });

    // t("CLINIC_LOCATION_DEARCHIVED")
    const successMessage = "CLINIC_LOCATION_DEARCHIVED";

    return result.type === "success"
      ? successResult({
          message: successMessage,
          payload: null,
        })
      : errorResult({ message: result.message });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization clinicLocations listener
interface GetClinicLocationsListenerCallback {
  (clinicLocations: ClinicLocation[]): void;
}
interface GetClinicLocationsListener {
  db: Firestore;
  organizationId: string;
  callback: GetClinicLocationsListenerCallback;
}

const getClinicLocationsListener = ({
  db,
  organizationId,
  callback,
}: GetClinicLocationsListener): Unsubscribe => {
  try {
    // console.log("getClinicLocationsListener: new listener");
    const clinicLocationsQuery = query(
      collection(db, "organizations", organizationId, "clinic_locations"),
      orderBy("lastUpdatedAt", "desc")
    );
    return onSnapshot(clinicLocationsQuery, (querySnapshot) => {
      const clinicLocations: ClinicLocation[] = [];
      querySnapshot.forEach((doc) => {
        try {
          clinicLocations.push(normalizeClinicLocation(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(clinicLocations);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const clinicLocations = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getClinicLocationsListener: (
      callback: GetClinicLocationsListenerCallback
    ) =>
      getClinicLocationsListener({
        db,
        organizationId,
        callback,
      }),
    getClinicLocation: (id: string) =>
      getClinicLocation({
        db,
        organizationId,
        id,
      }),
    addClinicLocation: (data: ClinicLocationData) =>
      addClinicLocation({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    editClinicLocation: (id: string, data: ClinicLocationData) =>
      editClinicLocation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteClinicLocation: (id: string) =>
      deleteClinicLocation({
        db,
        organizationId,
        id,
      }),
    archiveClinicLocation: (id: string, data: ClinicLocationData) =>
      archiveClinicLocation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    dearchiveClinicLocation: (id: string, data: ClinicLocationData) =>
      dearchiveClinicLocation({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
  };
};
