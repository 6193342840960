import { dueTimeOptionsEnum } from "beitary-shared";
import { BSelect } from "components/BSelect";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface DueTimeOptionsSelectProps {
  required?: boolean;
  onChange: (value: string | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
  label?: string;
}

export const DueTimeOptionsSelect = (props: DueTimeOptionsSelectProps) => {
  const { t } = useTranslation();
  const data = dueTimeOptionsEnum.options.map((o) => ({ value: o, label: o }));

  return (
    <BSelect
      {...props}
      data={data}
      label={" "}
      nothingFound={t("NOTHING_FOUND")}
    />
  );
};
