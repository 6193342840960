import { useAppDispatch, useAppSelector } from "app/hooks";
import { parseClaims } from "beitary-shared";
import { ReactNode, useEffect } from "react";
import { AuthenticationAPI } from "./AuthenticationAPI";
import {
  selectActiveUser,
  setActiveMember,
  setActiveOrganization,
  setActiveUser,
  setIsLocked,
} from "./AuthenticationSlice";
import { AuthenticationView } from "./AuthenticationView";

interface IAuthentication {
  children: ReactNode;
}

const AuthenticationContainer = ({ children }: IAuthentication) => {
  // console.log("AuthenticationContainer");
  const dispatch = useAppDispatch();
  const activeUser = useAppSelector(selectActiveUser);
  // const getUserOrganizations = () => {
  //   if (!activeUser || !activeUser.claims) return null;

  //   return Object.keys(activeUser.claims.orgs);
  // };

  // const organizationsIds = getUserOrganizations();

  useEffect(() => {
    const listener = AuthenticationAPI.authListener((user) => {
      dispatch(setActiveUser(user));
      if (user === null) {
        dispatch(setActiveOrganization(null)); // logout
        dispatch(setActiveMember(null)); // logout
      } else {
        dispatch(setActiveOrganization(parseClaims(user.claims).orgId));
        if (parseClaims(user.claims).isOrgAcc === "1") {
          dispatch(setIsLocked(true));
        }
      }
    });
    return () => {
      listener();
    };
  }, [dispatch]);

  if (!activeUser) {
    return <AuthenticationView />;
  }

  return <>{children}</>;
};

export default AuthenticationContainer;
