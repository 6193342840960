import { z } from "zod";
import { BaseRecord, baseRecordSchema } from "../../baseRecord";
import { UnableToNormalizeData } from "../../errors/UnableToNormalizeData/UnableToNormalizeData";
import { taskStatusEnum } from "./Task.status";

/**
 * schema and types versions
 * from first to last
 */

// v0

const taskDataV0Schema = z.object({
  status: taskStatusEnum,
  name: z.string(),
  description: z.string().optional(),
  assignedToIds: z.array(z.string()).optional(),
  clientId: z.string().optional(),
  clientName: z.string().optional(),
  patientId: z.string().optional(),
  patientName: z.string().optional(),
  dueDate: z.number().positive().int(),
});

type TaskDataV0 = z.infer<typeof taskDataV0Schema>;

const taskV0Schema = baseRecordSchema.merge(taskDataV0Schema);

type TaskV0 = z.infer<typeof taskV0Schema>;

const normalizeTaskV0 = (data: any): Task => {
  try {
    const taskV0: TaskV0 = taskV0Schema.parse(data);

    const { ...rest } = taskV0;

    // here it's same object cause current v is 0
    const task: Task = {
      ...rest,
    };
    return task;
  } catch (error: any) {
    throw new UnableToNormalizeData({ error, data });
  }
};

/**
 * current types
 * extend latest types
 */

// latest version is 0 so that's what we using

export const TASK_VERSION = "0";

export type TaskData = TaskDataV0;

export type Task = BaseRecord & TaskData;

export const taskDataSchema = taskDataV0Schema;

export const taskSchema = taskV0Schema;

export const normalizeTask = (data: any): Task => {
  if (!data || !data.version) throw new UnableToNormalizeData(data);
  switch (data.version) {
    case "0":
      return normalizeTaskV0(data);
    default:
      throw new UnableToNormalizeData(data);
  }
};
