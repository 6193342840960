import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ImagingDiagnosticItem } from "beitary-shared";

interface DiagnosticsState {
  imagingDiagnosticItems: ImagingDiagnosticItem[];
}

const key = "diagnostics";

const initialState: DiagnosticsState = {
  imagingDiagnosticItems: [],
};

export const diagnosticsSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    setImagingDiagnosticItems: (
      state,
      action: PayloadAction<ImagingDiagnosticItem[]>
    ) => {
      state.imagingDiagnosticItems = action.payload;
    },
  },
});

export const { setImagingDiagnosticItems } = diagnosticsSlice.actions;

export const selectImagingDiagnosticItems = (state: RootState) =>
  state.diagnostics.imagingDiagnosticItems;

export const diagnosticsReducer = diagnosticsSlice.reducer;
