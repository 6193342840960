import { Group, Text } from "@mantine/core";
import { Dropzone, DropzoneProps } from "@mantine/dropzone";
import { IconPhoto, IconUpload, IconX } from "@tabler/icons-react";

export interface BDropzoneProps {
  line1?: string;
  line2?: string;
  dzProps: Omit<DropzoneProps, "children">;
}

export function BDropzone({ line1, line2, dzProps }: BDropzoneProps) {
  return (
    <Dropzone {...dzProps}>
      <Group
        position="center"
        spacing="xl"
        style={{ minHeight: 220, pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <IconUpload size={50} stroke={1.5} />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX size={50} stroke={1.5} color="red" />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto size={50} stroke={1.5} />
        </Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            {line1}
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            {line2}
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
}
