import { Text } from "@mantine/core";
import { useAppSelector } from "app/hooks";
import { BMultiSelect } from "components";
import { selectActiveDiagnoses } from "features/admin/diagnoses/Diagnoses.slice";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ProblemsMultiSelectProps {
  required?: boolean;
  onChange?: (
    value:
      | {
          id: string;
          name: string;
          dischInstr?: string;
        }[]
      | undefined
  ) => void;
  error?: ReactNode;
  value?: string[]; // could be undefined if form has no initial values
  disabled?: boolean;
  label?: string;
}

export const ProblemsMultiSelect = ({
  onChange,
  ...other
}: ProblemsMultiSelectProps) => {
  const { t } = useTranslation();
  const items = useAppSelector(selectActiveDiagnoses);
  const data = items.map((item) => ({
    value: item.id,
    label: item.name,
    code: item.code,
  }));

  const onSelect = (v: string[] | undefined) => {
    const filtered = items.filter((i) => v?.includes(i.id));
    const mapped = filtered.map((i) => {
      let item: { id: string; name: string; dischInstr?: string } = {
        id: i.id,
        name: i.name,
      };
      if (i.hasDischInstr && i.dischargeInstructions)
        item["dischInstr"] = i.dischargeInstructions;
      return item;
    });
    onChange && onChange(mapped);
  };

  return (
    <BMultiSelect
      {...other}
      itemComponent={SelectItem}
      filter={(value, selected, item) =>
        !selected &&
        (item.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.code?.toLowerCase().includes(value.toLowerCase().trim()))
      }
      nothingFound={t("NOTHING_FOUND")}
      data={data}
      onChange={onSelect}
      limit={20}
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  code?: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ code, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text>{label}</Text>
      {code && (
        <Text size="xs" color="dimmed">
          {code}
        </Text>
      )}
    </div>
  )
);
