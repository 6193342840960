import {
  Accordion,
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Collapse,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import {
  IconChevronDown,
  IconEdit,
  IconHandStop,
  IconVaccine,
} from "@tabler/icons-react";
import { useAppSelector } from "app/hooks";
import { Consultation, getNotificationByResultType, tu } from "beitary-shared";
import { AsyncActionIcon, BBox } from "components";
import { selectActiveConsultationTreatments } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useIsMobile } from "hooks/useIsMobile";
import { useSubmitState } from "hooks/useSubmitState";
import HTMLReactParser from "html-react-parser";
import { BCan } from "permissions";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface TxOrderProps {
  consultation: Consultation;
}

export const TxOrder = ({ consultation }: TxOrderProps) => {
  const disabled = consultation.status === "LOCKED";
  const isMobile = useIsMobile();

  const [administerState, setAdministerState] = useSubmitState();
  const [stopState, setStopState] = useSubmitState();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const treatmentsDBService = useDBServices().consultationsDBService.treatments(
    consultation.id
  );
  const theme = useMantineTheme();

  const [selectedTreatmentsIds, setSelectedTreatmentsIds] = useState<string[]>(
    []
  );

  // use useMemo for these expensive calculations
  // https://beta.reactjs.org/learn/you-might-not-need-an-effect
  const treatments = useAppSelector(selectActiveConsultationTreatments);

  const orderedTreatments = useMemo(
    () =>
      treatments
        .filter((t) => t.status === "ORDERED")
        .sort((a, b) => a.createdAt - b.createdAt)
        .sort((a, b) => {
          if (a.id && b.id && a.fixedPriceBundleId === b.fixedPriceBundleId) {
            if (
              a.id === a.fixedPriceBundleId &&
              b.id !== b.fixedPriceBundleId
            ) {
              return -1; // a comes before b
            } else if (
              a.id !== a.fixedPriceBundleId &&
              b.id === b.fixedPriceBundleId
            ) {
              return 1; // b comes before a
            } else {
              return 0; // order is irrelevant
            }
          } else {
            return a.fixedPriceBundleId === undefined
              ? 1
              : b.fixedPriceBundleId === undefined
              ? -1
              : a.fixedPriceBundleId.localeCompare(b.fixedPriceBundleId);
          }
        }),
    [treatments]
  );

  const [opened, setOpened] = useState(orderedTreatments.length !== 0);

  useEffect(() => {
    setOpened(orderedTreatments.length !== 0);
  }, [orderedTreatments]);

  const orderedSTATTreatments = useMemo(
    () => orderedTreatments.filter((t) => t.type === "STAT"),
    [orderedTreatments]
  );

  const orderedPRNTreatments = useMemo(
    () => orderedTreatments.filter((t) => t.type === "PRN"),
    [orderedTreatments]
  );

  const orderedScheduledTreatments = useMemo(
    () => orderedTreatments.filter((t) => t.type === "SCHEDULED"),
    [orderedTreatments]
  );

  const administerTreatment = async (id: string) => {
    if (disabled) return;
    await treatmentsDBService.administerTreatment(id);
  };

  const stopTreatment = async (id: string) => {
    if (disabled) return;
    await treatmentsDBService.stopTreatment(id);
  };

  const stopSelectedTreatments = async () => {
    if (
      disabled ||
      !(orderedTreatments.length > 0 && selectedTreatmentsIds.length > 0)
    )
      return;

    // const treatmentsToKeep: Treatment[] = treatments.filter(
    //   (t) => !selectedTreatmentsIds.includes(t.id)
    // );

    setStopState("pending-response");
    const result = await treatmentsDBService.stopTreatments(
      selectedTreatmentsIds
    );

    if (result && result.type === "success") {
      setStopState("success");
    } else {
      setStopState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
    setSelectedTreatmentsIds([]);
  };

  const administerSelectedTreatments = async () => {
    if (
      disabled ||
      !(orderedTreatments.length > 0 && selectedTreatmentsIds.length > 0)
    )
      return;

    // const treatmentsToKeep: Treatment[] = treatments.filter(
    //   (t) => !selectedTreatmentsIds.includes(t.id)
    // );

    setAdministerState("pending-response");
    const result = await treatmentsDBService.administerTreatments(
      selectedTreatmentsIds
    );

    if (result && result.type === "success") {
      setAdministerState("success");
    } else {
      setAdministerState("error");
      showNotification({
        ...getNotificationByResultType("error")({
          message: t(result?.message ?? "SOMETHING_WENT_WRONG"),
        }),
      });
    }
    setSelectedTreatmentsIds([]);
  };

  const stopFPBTreatments = async (id: string) => {
    if (disabled) return;

    const txIds = orderedTreatments
      .filter((t) => t.fixedPriceBundleId === id)
      .map((t) => t.id);

    await treatmentsDBService.stopTreatments(txIds);
    return;
  };

  const administerFPBTreatments = async (id: string) => {
    if (disabled) return;
    const txIds = orderedTreatments
      .filter((t) => t.fixedPriceBundleId === id)
      .map((t) => t.id);
    await treatmentsDBService.administerTreatments(txIds);
  };

  return (
    <Stack>
      <BBox
        header={
          <Group position="apart">
            <Text weight={500}>{t("ORDERED_TREATMENTS")}</Text>
            <Group>
              {opened && (
                <BCan I="am" a="DOCTOR">
                  <Button
                    disabled={disabled || selectedTreatmentsIds.length === 0}
                    loading={stopState === "pending-response"}
                    color="red"
                    onClick={stopSelectedTreatments}
                  >
                    {t("STOP_SELECTED_TREATMENTS")}
                  </Button>
                  <Button
                    disabled={disabled || selectedTreatmentsIds.length === 0}
                    loading={administerState === "pending-response"}
                    onClick={administerSelectedTreatments}
                  >
                    {t("ADMINISTER_SELECTED_TREATMENTS")}
                  </Button>
                </BCan>
              )}
              <ActionIcon onClick={() => setOpened(!opened)}>
                <IconChevronDown
                  style={{
                    transform: opened ? "rotate(180deg)" : "none",
                    transitionDuration: "200ms",
                  }}
                />
              </ActionIcon>
            </Group>
          </Group>
        }
      >
        <Collapse in={opened} transitionDuration={400}>
          <Stack p="xl">
            <BCan I="am" a="DOCTOR">
              <Checkbox
                disabled={disabled}
                label={t("SELECT_ALL")}
                onChange={(e) => {
                  e.target.checked
                    ? setSelectedTreatmentsIds(
                        orderedTreatments.map((t) => t.id)
                      )
                    : setSelectedTreatmentsIds([]);
                }}
                checked={
                  orderedTreatments.length > 0 &&
                  orderedTreatments.length === selectedTreatmentsIds.length
                }
              />
            </BCan>
            <Checkbox.Group
              onChange={setSelectedTreatmentsIds}
              value={selectedTreatmentsIds}
            >
              <Stack>
                <Text weight={500}>{t("STAT")}</Text>

                <Accordion variant="contained" chevronPosition="left">
                  {orderedSTATTreatments.length === 0 && (
                    <Text color="dimmed" size="sm">
                      {t("NO_TREATMENTS_SCHEDULED_YET")}
                    </Text>
                  )}
                  {orderedSTATTreatments.map((treatment) => (
                    <Accordion.Item value={treatment.id} key={treatment.id}>
                      <Group>
                        <Accordion.Control
                          w={46}
                          h={46}
                          sx={{
                            borderRight: `1px solid ${theme.colors.gray[3]}`,
                          }}
                        />
                        <Group
                          pr="lg"
                          position="apart"
                          sx={{
                            flexGrow: 1, // idk why but it's working
                          }}
                        >
                          <Box w={10}>
                            {(treatment.fixedPriceBundleId === treatment.id ||
                              !treatment.fixedPriceBundleId) && (
                              <BCan I="am" a="DOCTOR">
                                <Checkbox
                                  disabled={disabled}
                                  key={treatment.id}
                                  value={treatment.id}
                                />
                              </BCan>
                            )}
                          </Box>
                          {/* TODO: make text w change with screen size? prolly not needed*/}
                          <Group w={isMobile ? 180 : 400} mr="xl" ml={0} p={0}>
                            <Text>{treatment.productSnapshot.name}</Text>
                            {/* {treatment.bundleId && treatment.bundleName && (
                              <Tooltip label={treatment.bundleName}>
                                <ThemeIcon
                                  color="gray"
                                  size="xs"
                                  variant="outline"
                                >
                                  <IconStack2 />
                                </ThemeIcon>
                              </Tooltip>
                            )} */}
                          </Group>
                          <Tooltip label={t("QUANTITY/DOSE")}>
                            <Text w={isMobile ? 40 : 100}>
                              {treatment.qty}
                              {treatment.unit && `(${t(treatment.unit)})`}
                            </Text>
                          </Tooltip>
                          {!isMobile && <Box w={150}></Box>}

                          <Group w={120}>
                            {(treatment.fixedPriceBundleId === treatment.id ||
                              !treatment.fixedPriceBundleId) && (
                              <BCan I="am" a="DOCTOR">
                                <AsyncActionIcon
                                  disabled={disabled}
                                  icon={<IconVaccine size={18} />}
                                  f={async () =>
                                    treatment.fixedPriceBundleId ===
                                    treatment.id
                                      ? administerFPBTreatments(treatment.id)
                                      : administerTreatment(treatment.id)
                                  }
                                />

                                <ActionIcon
                                  disabled={disabled}
                                  onClick={() =>
                                    navigate(
                                      treatment.fixedPriceBundleId ===
                                        treatment.id
                                        ? `bundle-treatments/${treatment.id}/edit`
                                        : `treatments/${treatment.id}/edit`
                                    )
                                  }
                                >
                                  <IconEdit size={18} />
                                </ActionIcon>

                                <AsyncActionIcon
                                  disabled={disabled}
                                  icon={<IconHandStop size={18} />}
                                  f={async () =>
                                    treatment.fixedPriceBundleId ===
                                    treatment.id
                                      ? stopFPBTreatments(treatment.id)
                                      : stopTreatment(treatment.id)
                                  }
                                />
                              </BCan>
                            )}
                          </Group>
                        </Group>
                      </Group>

                      <Accordion.Panel>
                        <SimpleGrid cols={3}>
                          <div>
                            <Text color="cyan">
                              {t("ADMINISTRATION_INSTRUCTIONS")}
                            </Text>
                            <Text>
                              {HTMLReactParser(
                                treatment.productSnapshot.adminInstr ?? "-"
                              )}
                            </Text>
                          </div>
                          <div>
                            <Text color="cyan">
                              {t("ADMINISTRATION_ROUTES")}
                            </Text>
                            <Text>
                              {treatment.adminRoutes
                                ? treatment.adminRoutes
                                    .map((r) => r)
                                    .join(" - ")
                                : "-"}
                            </Text>
                          </div>
                          {treatment.type === "PRN" && (
                            <div>
                              <Text color="cyan">{t("PRIORITY")}</Text>
                              <Text>{treatment.priority ?? "-"}</Text>
                            </div>
                          )}
                          {treatment.type === "SCHEDULED" && (
                            <div>
                              <Text color="cyan">{t("TIME_DUE")}</Text>
                              <Text>
                                {treatment.timeDue
                                  ? tu.getDateAndTimeString(treatment.timeDue)
                                  : "-"}
                              </Text>
                            </div>
                          )}
                          <div>
                            <Text color="cyan">{t("SERIAL_NUMBER")}</Text>
                            <Text>{treatment.serialNumber ?? "-"}</Text>
                          </div>
                          <div>
                            <Text color="cyan">{t("LOT_NUMBER")}</Text>
                            <Text>{treatment.lotNumber ?? "-"}</Text>
                          </div>
                          <div>
                            <Text color="cyan">{t("LOT_EXPIRATION_DATE")}</Text>
                            <Text>
                              {treatment.lotExpirationDate
                                ? tu.getDateString(treatment.lotExpirationDate)
                                : "-"}
                            </Text>
                          </div>
                        </SimpleGrid>
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                </Accordion>

                <Text weight={500} mt="md">
                  {t("SCHEDULED")}
                </Text>
                <Accordion variant="contained" chevronPosition="left">
                  {orderedScheduledTreatments.length === 0 && (
                    <Text color="dimmed" size="sm">
                      {t("NO_TREATMENTS_SCHEDULED_YET")}
                    </Text>
                  )}
                  {orderedScheduledTreatments.map((treatment) => (
                    <Accordion.Item value={treatment.id} key={treatment.id}>
                      <Group>
                        <Accordion.Control
                          w={46}
                          h={46}
                          sx={{
                            borderRight: `1px solid ${theme.colors.gray[3]}`,
                          }}
                        />
                        <Group
                          pr="lg"
                          position="apart"
                          sx={{
                            flexGrow: 1, // idk why but it's working
                          }}
                        >
                          <Box w={10}>
                            {(treatment.fixedPriceBundleId === treatment.id ||
                              !treatment.fixedPriceBundleId) && (
                              <BCan I="am" a="DOCTOR">
                                <Checkbox
                                  disabled={disabled}
                                  key={treatment.id}
                                  value={treatment.id}
                                />
                              </BCan>
                            )}
                          </Box>
                          <Group
                            w={isMobile ? 130 : 400}
                            mr={isMobile ? undefined : "xl"}
                            ml={0}
                            p={0}
                          >
                            <Text>{treatment.productSnapshot.name}</Text>
                            {/* {treatment.bundleId && treatment.bundleName && (
                              <Tooltip label={treatment.bundleName}>
                                <ThemeIcon
                                  color="gray"
                                  size="xs"
                                  variant="outline"
                                >
                                  <IconStack2 />
                                </ThemeIcon>
                              </Tooltip>
                            )} */}
                          </Group>
                          <Tooltip label={t("QUANTITY/DOSE")}>
                            <Text w={isMobile ? 30 : 100}>
                              {treatment.qty}
                              {treatment.unit && `(${t(treatment.unit)})`}
                            </Text>
                          </Tooltip>
                          <Box w={isMobile ? 60 : 150}>
                            {treatment.timeDue && (
                              <Tooltip label={t("TIME_DUE")}>
                                <Text>
                                  {tu.getDateAndTimeString(treatment.timeDue)}
                                </Text>
                              </Tooltip>
                            )}
                          </Box>

                          <Group w={120}>
                            {(treatment.fixedPriceBundleId === treatment.id ||
                              !treatment.fixedPriceBundleId) && (
                              <BCan I="am" a="DOCTOR">
                                <AsyncActionIcon
                                  disabled={disabled}
                                  icon={<IconVaccine size={18} />}
                                  f={async () =>
                                    treatment.fixedPriceBundleId ===
                                    treatment.id
                                      ? administerFPBTreatments(treatment.id)
                                      : administerTreatment(treatment.id)
                                  }
                                />

                                <ActionIcon
                                  disabled={disabled}
                                  onClick={() =>
                                    navigate(
                                      treatment.fixedPriceBundleId ===
                                        treatment.id
                                        ? `bundle-treatments/${treatment.id}/edit`
                                        : `treatments/${treatment.id}/edit`
                                    )
                                  }
                                >
                                  <IconEdit size={18} />
                                </ActionIcon>

                                <AsyncActionIcon
                                  disabled={disabled}
                                  icon={<IconHandStop size={18} />}
                                  f={async () =>
                                    treatment.fixedPriceBundleId ===
                                    treatment.id
                                      ? stopFPBTreatments(treatment.id)
                                      : stopTreatment(treatment.id)
                                  }
                                />
                              </BCan>
                            )}
                          </Group>
                        </Group>
                      </Group>

                      <Accordion.Panel>
                        <SimpleGrid cols={3}>
                          <div>
                            <Text color="cyan">
                              {t("ADMINISTRATION_INSTRUCTIONS")}
                            </Text>
                            <Text>
                              {HTMLReactParser(
                                treatment.productSnapshot.adminInstr ?? "-"
                              )}
                            </Text>
                          </div>
                          <div>
                            <Text color="cyan">
                              {t("ADMINISTRATION_ROUTES")}
                            </Text>
                            <Text>
                              {treatment.adminRoutes
                                ? treatment.adminRoutes
                                    .map((r) => r)
                                    .join(" - ")
                                : "-"}
                            </Text>
                          </div>
                          {treatment.type === "PRN" && (
                            <div>
                              <Text color="cyan">{t("PRIORITY")}</Text>
                              <Text>{treatment.priority ?? "-"}</Text>
                            </div>
                          )}
                          {treatment.type === "SCHEDULED" && (
                            <div>
                              <Text color="cyan">{t("TIME_DUE")}</Text>
                              <Text>
                                {treatment.timeDue
                                  ? tu.getDateAndTimeString(treatment.timeDue)
                                  : "-"}
                              </Text>
                            </div>
                          )}
                          <div>
                            <Text color="cyan">{t("SERIAL_NUMBER")}</Text>
                            <Text>{treatment.serialNumber ?? "-"}</Text>
                          </div>
                          <div>
                            <Text color="cyan">{t("LOT_NUMBER")}</Text>
                            <Text>{treatment.lotNumber ?? "-"}</Text>
                          </div>
                          <div>
                            <Text color="cyan">{t("LOT_EXPIRATION_DATE")}</Text>
                            <Text>
                              {treatment.lotExpirationDate
                                ? tu.getDateString(treatment.lotExpirationDate)
                                : "-"}
                            </Text>
                          </div>
                        </SimpleGrid>
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                </Accordion>

                <Text weight={500} mt="md">
                  {t("PRN")}
                </Text>
                <Accordion variant="contained" chevronPosition="left">
                  {orderedPRNTreatments.length === 0 && (
                    <Text color="dimmed" size="sm">
                      {t("NO_TREATMENTS_SCHEDULED_YET")}
                    </Text>
                  )}
                  {orderedPRNTreatments.map((treatment) => (
                    <Accordion.Item value={treatment.id} key={treatment.id}>
                      <Group>
                        <Accordion.Control
                          w={46}
                          h={46}
                          sx={{
                            borderRight: `1px solid ${theme.colors.gray[3]}`,
                          }}
                        />
                        <Group
                          pr="lg"
                          position="apart"
                          sx={{
                            flexGrow: 1, // idk why but it's working
                          }}
                        >
                          <Box w={10}>
                            {(treatment.fixedPriceBundleId === treatment.id ||
                              !treatment.fixedPriceBundleId) && (
                              <BCan I="am" a="DOCTOR">
                                <Checkbox
                                  disabled={disabled}
                                  key={treatment.id}
                                  value={treatment.id}
                                />
                              </BCan>
                            )}
                          </Box>
                          <Group
                            w={isMobile ? 160 : 400}
                            mr={isMobile ? undefined : "xl"}
                            ml={0}
                            p={0}
                          >
                            <Text>{treatment.productSnapshot.name}</Text>
                            {/* {treatment.bundleId && treatment.bundleName && (
                              <Tooltip label={treatment.bundleName}>
                                <ThemeIcon
                                  color="gray"
                                  size="xs"
                                  variant="outline"
                                >
                                  <IconStack2 />
                                </ThemeIcon>
                              </Tooltip>
                            )} */}
                          </Group>
                          <Tooltip label={t("QUANTITY/DOSE")}>
                            <Text w={isMobile ? 30 : 100}>
                              {treatment.qty}
                              {treatment.unit && `(${t(treatment.unit)})`}
                            </Text>
                          </Tooltip>
                          <Box w={isMobile ? 30 : 150}>
                            {treatment.priority !== undefined && (
                              <Tooltip label={t("PRIORITY")}>
                                <Text>{treatment.priority}</Text>
                              </Tooltip>
                            )}
                          </Box>

                          <Group w={120}>
                            {(treatment.fixedPriceBundleId === treatment.id ||
                              !treatment.fixedPriceBundleId) && (
                              <BCan I="am" a="DOCTOR">
                                <AsyncActionIcon
                                  disabled={disabled}
                                  icon={<IconVaccine size={18} />}
                                  f={async () =>
                                    treatment.fixedPriceBundleId ===
                                    treatment.id
                                      ? administerFPBTreatments(treatment.id)
                                      : administerTreatment(treatment.id)
                                  }
                                />

                                <ActionIcon
                                  disabled={disabled}
                                  onClick={() =>
                                    navigate(
                                      treatment.fixedPriceBundleId ===
                                        treatment.id
                                        ? `bundle-treatments/${treatment.id}/edit`
                                        : `treatments/${treatment.id}/edit`
                                    )
                                  }
                                >
                                  <IconEdit size={18} />
                                </ActionIcon>

                                <AsyncActionIcon
                                  disabled={disabled}
                                  icon={<IconHandStop size={18} />}
                                  f={async () =>
                                    treatment.fixedPriceBundleId ===
                                    treatment.id
                                      ? stopFPBTreatments(treatment.id)
                                      : stopTreatment(treatment.id)
                                  }
                                />
                              </BCan>
                            )}
                          </Group>
                        </Group>
                      </Group>

                      <Accordion.Panel>
                        <SimpleGrid cols={3}>
                          <div>
                            <Text color="cyan">
                              {t("ADMINISTRATION_INSTRUCTIONS")}
                            </Text>
                            <Text>
                              {HTMLReactParser(
                                treatment.productSnapshot.adminInstr ?? "-"
                              )}
                            </Text>
                          </div>
                          <div>
                            <Text color="cyan">
                              {t("ADMINISTRATION_ROUTES")}
                            </Text>
                            <Text>
                              {treatment.adminRoutes
                                ? treatment.adminRoutes
                                    .map((r) => r)
                                    .join(" - ")
                                : "-"}
                            </Text>
                          </div>
                          {treatment.type === "PRN" && (
                            <div>
                              <Text color="cyan">{t("PRIORITY")}</Text>
                              <Text>{treatment.priority ?? "-"}</Text>
                            </div>
                          )}
                          {treatment.type === "SCHEDULED" && (
                            <div>
                              <Text color="cyan">{t("TIME_DUE")}</Text>
                              <Text>
                                {treatment.timeDue
                                  ? tu.getDateAndTimeString(treatment.timeDue)
                                  : "-"}
                              </Text>
                            </div>
                          )}
                          <div>
                            <Text color="cyan">{t("SERIAL_NUMBER")}</Text>
                            <Text>{treatment.serialNumber ?? "-"}</Text>
                          </div>
                          <div>
                            <Text color="cyan">{t("LOT_NUMBER")}</Text>
                            <Text>{treatment.lotNumber}</Text>
                          </div>
                          <div>
                            <Text color="cyan">{t("LOT_EXPIRATION_DATE")}</Text>
                            <Text>
                              {treatment.lotExpirationDate
                                ? tu.getDateString(treatment.lotExpirationDate)
                                : "-"}
                            </Text>
                          </div>
                        </SimpleGrid>
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Stack>
            </Checkbox.Group>
          </Stack>
        </Collapse>
      </BBox>
    </Stack>
  );
};
