import { useAppSelector } from "app/hooks";
import { InventoryItemSupplier } from "beitary-shared";
import { BSelect } from "components";
import { selectActiveInventoryItemSuppliers } from "features/admin/inventory/Inventory.slice";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface InventoryItemSupplierSelectProps {
  required?: boolean;
  onChange?: (value: InventoryItemSupplier | undefined) => void;
  error?: ReactNode;
  value?: string; // could be undefined if form has no initial values
  disabled?: boolean;
}

export const InventoryItemSupplierSelect = (
  props: InventoryItemSupplierSelectProps
) => {
  const { t } = useTranslation();
  const suppliers = useAppSelector(selectActiveInventoryItemSuppliers);
  const data = suppliers.map((o) => ({ value: o.id, label: o.name }));

  const handleChange = (selectedValue?: string) => {
    if (props.onChange) {
      const supplier = suppliers.find((s) => s.id === selectedValue);
      props.onChange(supplier);
    }
  };

  return (
    <BSelect
      {...props}
      data={data}
      label={t("SUPPLIER")}
      nothingFound={t("NOTHING_FOUND")}
      onChange={handleChange}
    />
  );
};
