import {
  EmailAppointmentNotificationTemplate,
  EmailAppointmentNotificationTemplateData,
  EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_VERSION,
  errorResult,
  normalizeEmailAppointmentNotificationTemplate,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";

interface AddEmailAppointmentNotificationTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: EmailAppointmentNotificationTemplateData;
  }): Promise<Result<boolean | null>>;
}

const addEmailAppointmentNotificationTemplate: AddEmailAppointmentNotificationTemplate =
  async ({ db, organizationId, authorId, authorName, source, data }) => {
    try {
      // create a new ref to get a new emailAppointmentNotificationTemplate id
      const newEmailAppointmentNotificationTemplateRef = doc(
        collection(
          db,
          "organizations",
          organizationId,
          "email_appointment_notification_templates"
        )
      );

      // console.log(newEmailAppointmentNotificationTemplateRef);

      const newEmailAppointmentNotificationTemplate: EmailAppointmentNotificationTemplate =
        normalizeEmailAppointmentNotificationTemplate({
          ...data,
          id: newEmailAppointmentNotificationTemplateRef.id,
          authorId,
          authorName,
          version: EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_VERSION,
          source,
          createdAt: tu.getCurrentDateTime(), // probably have a triggered functions write these fields?
          lastUpdatedAt: tu.getCurrentDateTime(),
        });

      await setDoc(
        newEmailAppointmentNotificationTemplateRef,
        newEmailAppointmentNotificationTemplate
      );

      // t("EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_CREATED")
      const successMessage = "EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_CREATED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (err: any) {
      return errorResult({ message: err.message });
    }
  };

interface DeleteEmailAppointmentNotificationTemplate {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteEmailAppointmentNotificationTemplate: DeleteEmailAppointmentNotificationTemplate =
  async ({ db, organizationId, id }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "email_appointment_notification_templates",
        id
      );

      try {
        await deleteDoc(docRef);
        // t("EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_DELETED")
        const successMessage =
          "EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_DELETED";
        return successResult({
          message: successMessage,
          payload: true,
        });
      } catch (error: any) {
        console.log(error);
        return errorResult({ message: error.message });
      }
    } catch (err: any) {
      console.log(err);
      return errorResult({ message: err.message });
    }
  };

interface UpdateEmailAppointmentNotificationTemplate {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<EmailAppointmentNotificationTemplateData>;
  }): Promise<Result<boolean | null>>;
}

const updateEmailAppointmentNotificationTemplate: UpdateEmailAppointmentNotificationTemplate =
  async ({ db, organizationId, authorId, authorName, id, source, data }) => {
    try {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "email_appointment_notification_templates",
        id
      );

      const updates: Partial<EmailAppointmentNotificationTemplate> = {
        ...data,
        authorId,
        authorName,
        version: EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      };

      await updateDoc(docRef, updates);

      // t("EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_UPDATED")
      const successMessage = "EMAIL_APPOINTMENT_NOTIFICATION_TEMPLATE_UPDATED";

      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (err: any) {
      console.log(err.message);
      return errorResult({ message: err.message });
    }
  };

// get organization emailAppointmentNotificationTemplates listener
interface GetEmailAppointmentNotificationTemplatesListenerCallback {
  (
    emailAppointmentNotificationTemplates: EmailAppointmentNotificationTemplate[]
  ): void;
}
interface GetEmailAppointmentNotificationTemplatesListener {
  db: Firestore;
  organizationId: string;
  callback: GetEmailAppointmentNotificationTemplatesListenerCallback;
}

const getEmailAppointmentNotificationTemplatesListener = ({
  db,
  organizationId,
  callback,
}: GetEmailAppointmentNotificationTemplatesListener): Unsubscribe => {
  try {
    const emailAppointmentNotificationTemplatesQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "email_appointment_notification_templates"
      )
    );
    return onSnapshot(
      emailAppointmentNotificationTemplatesQuery,
      (querySnapshot) => {
        const emailAppointmentNotificationTemplates: EmailAppointmentNotificationTemplate[] =
          [];
        querySnapshot.forEach((doc) => {
          emailAppointmentNotificationTemplates.push(
            normalizeEmailAppointmentNotificationTemplate(doc.data())
          );
        });
        callback(emailAppointmentNotificationTemplates);
      }
    );
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// we inject dependencies to improve testability
export const emailAppointmentNotificationTemplates = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    addEmailAppointmentNotificationTemplate: (
      data: EmailAppointmentNotificationTemplateData
    ) =>
      addEmailAppointmentNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
      }),
    getEmailAppointmentNotificationTemplatesListener: (
      callback: GetEmailAppointmentNotificationTemplatesListenerCallback
    ) =>
      getEmailAppointmentNotificationTemplatesListener({
        db,
        organizationId,
        callback,
      }),
    updateEmailAppointmentNotificationTemplate: (
      id: string,
      data: Partial<EmailAppointmentNotificationTemplateData>
    ) =>
      updateEmailAppointmentNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteEmailAppointmentNotificationTemplate: (id: string) =>
      deleteEmailAppointmentNotificationTemplate({
        db,
        organizationId,
        id,
      }),
    archiveEmailAppointmentNotificationTemplate: (id: string) =>
      updateEmailAppointmentNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ARCHIVED" },
      }),
    dearchiveEmailAppointmentNotificationTemplate: (id: string) =>
      updateEmailAppointmentNotificationTemplate({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ACTIVE" },
      }),
  };
};
