import { ResultType } from "../result/resutl";

export type NotificationType = "error" | "success" | "info" | "loadingInfo";

export interface Notification {
  id?: string;
  type: NotificationType;
  message: string;
  title?: string;
  loading?: boolean;
  autoClose?: boolean | number;
  disallowClose?: boolean;
  color?: string;
}

interface iErrorProps {
  id?: string;
  message: string;
  title?: string;
  autoClose?: boolean | number;
  [key: string]: any;
}
export const errorNotification = ({
  id,
  message,
  title,
  autoClose = false,
  ...other
}: iErrorProps): Notification => {
  return {
    id: id,
    type: "error",
    message: message,
    color: "red",
    title: title ?? "ERROR",
    autoClose,
    ...other,
  };
};

interface iSuccessProps {
  id?: string;
  message: string;
  title?: string;
}
export const successNotification = ({
  id,
  message,
  title,
  ...other
}: iSuccessProps): Notification => {
  return {
    id: id,
    type: "success",
    message: message,
    color: "teal",
    title: title,
    autoClose: Math.min(Math.max(message.length * 70, 2000), 7000),
    ...other,
  };
};

interface iInfoProps {
  id?: string;
  message: string;
  title?: string;
}

export const infoNotification = ({
  id,
  message,
  title,
  ...other
}: iInfoProps): Notification => {
  return {
    id: id,
    type: "info",
    message: message,
    title: title,
    autoClose: Math.min(Math.max(message.length * 70, 2000), 7000),
    ...other,
  };
};

interface iLoadingInfoProps {
  id?: string;
  message: string;
  title?: string;
}

export const loadingInfoNotification = ({
  id,
  message,
  title,
  ...other
}: iLoadingInfoProps): Notification => {
  return {
    id: id,
    type: "loadingInfo",
    message: message,
    loading: true,
    title: title,
    autoClose: false,
    disallowClose: true,
    ...other,
  };
};

export const getNotificationByType = (type: NotificationType) => {
  switch (type) {
    case "success":
      return successNotification;
    case "error":
      return errorNotification;
    case "info":
      return infoNotification;
    case "loadingInfo":
      return loadingInfoNotification;
  }
};

export const getNotificationByResultType = (type: ResultType) => {
  switch (type) {
    case "success":
      return successNotification;
    case "error":
      return errorNotification;
  }
};
