import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  EmailAppointmentNotificationTemplate,
  EmailReminderNotificationTemplate,
  Reminder,
  SMSAppointmentNotificationTemplate,
  SMSReminderNotificationTemplate,
} from "beitary-shared";
interface ClientCommunicationState {
  reminders: Reminder[];
  emailAppointmentNotificationTemplates: EmailAppointmentNotificationTemplate[];
  smsAppointmentNotificationTemplates: SMSAppointmentNotificationTemplate[];
  emailReminderNotificationTemplates: EmailReminderNotificationTemplate[];
  smsReminderNotificationTemplates: SMSReminderNotificationTemplate[];
}

const initialState: ClientCommunicationState = {
  reminders: [],
  emailAppointmentNotificationTemplates: [],
  smsAppointmentNotificationTemplates: [],
  emailReminderNotificationTemplates: [],
  smsReminderNotificationTemplates: [],
};

export const clientCommunicationSlice = createSlice({
  name: "clientCommunication",
  initialState,
  reducers: {
    setReminders: (state, action: PayloadAction<Reminder[]>) => {
      state.reminders = action.payload;
    },
    setEmailAppointmentNotificationTemplates: (
      state,
      action: PayloadAction<EmailAppointmentNotificationTemplate[]>
    ) => {
      state.emailAppointmentNotificationTemplates = action.payload;
    },
    setSMSAppointmentNotificationTemplates: (
      state,
      action: PayloadAction<SMSAppointmentNotificationTemplate[]>
    ) => {
      state.smsAppointmentNotificationTemplates = action.payload;
    },
    setEmailReminderNotificationTemplates: (
      state,
      action: PayloadAction<EmailReminderNotificationTemplate[]>
    ) => {
      state.emailReminderNotificationTemplates = action.payload;
    },
    setSMSReminderNotificationTemplates: (
      state,
      action: PayloadAction<SMSReminderNotificationTemplate[]>
    ) => {
      state.smsReminderNotificationTemplates = action.payload;
    },
  },
});

export const {
  setEmailReminderNotificationTemplates,
  setReminders,
  setSMSReminderNotificationTemplates,
  setEmailAppointmentNotificationTemplates,
  setSMSAppointmentNotificationTemplates,
} = clientCommunicationSlice.actions;

export const selectReminders = (state: RootState) =>
  state.clientCommunication.reminders;

export const selectActiveReminders = (state: RootState) =>
  state.clientCommunication.reminders.filter((o) => o.status === "ACTIVE");

export const selectEmailAppointmentNotificationTemplates = (state: RootState) =>
  state.clientCommunication.emailAppointmentNotificationTemplates;

export const selectActiveEmailAppointmentNotificationTemplates = (
  state: RootState
) =>
  state.clientCommunication.emailAppointmentNotificationTemplates.filter(
    (o) => o.status === "ACTIVE"
  );

export const selectSMSAppointmentNotificationTemplates = (state: RootState) =>
  state.clientCommunication.smsAppointmentNotificationTemplates;

export const selectActiveSMSAppointmentNotificationTemplates = (
  state: RootState
) =>
  state.clientCommunication.smsAppointmentNotificationTemplates.filter(
    (o) => o.status === "ACTIVE"
  );

export const selectEmailReminderNotificationTemplates = (state: RootState) =>
  state.clientCommunication.emailReminderNotificationTemplates;

export const selectActiveEmailReminderNotificationTemplates = (
  state: RootState
) =>
  state.clientCommunication.emailReminderNotificationTemplates.filter(
    (o) => o.status === "ACTIVE"
  );

export const selectSMSReminderNotificationTemplates = (state: RootState) =>
  state.clientCommunication.smsReminderNotificationTemplates;

export const selectActiveSMSReminderNotificationTemplates = (
  state: RootState
) =>
  state.clientCommunication.smsReminderNotificationTemplates.filter(
    (o) => o.status === "ACTIVE"
  );

export const clientCommunicationReducer = clientCommunicationSlice.reducer;
