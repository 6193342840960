import { GlobalErrorFallBackComponent } from "components/GlobalErrorFallBackComponent";
import "i18n/i18nextInit";
import React from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { DBNoIdsService } from "services/db-no-ids/db-no-ids";
import { App } from "./App";
import { store } from "./app/store";
import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
const root = createRoot(container as Element);

const logError = async (error: Error, info: { componentStack: string }) => {
  await DBNoIdsService().addErrorLog({
    error: `{cause:${error.cause}, message:${error.message}, name:${error.name}, stack:${error.stack}}`,
    componentStack: info.componentStack,
  });
};

// remove console log from production
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <GlobalErrorFallBackComponent
          error={`{cause:${error.cause}, message:${error.message}, name:${error.name}, stack:${error.stack}}`}
        />
      )}
      onError={logError}
    >
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
