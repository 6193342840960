import {
  errorResult,
  id_util,
  InventoryItem,
  normalizePurchaseOrder,
  normalizePurchaseOrderItem,
  obju,
  PurchaseOrder,
  PurchaseOrderData,
  PurchaseOrderItem,
  PurchaseOrderItemData,
  PurchaseOrderStatus,
  PURCHASE_ORDER_ITEM_VERSION,
  PURCHASE_ORDER_VERSION,
  Result,
  Source,
  successResult,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  endBefore,
  Firestore,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";

// add organization purchaseOrder

interface AddPurchaseOrder {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: PurchaseOrderData;
    items?: InventoryItem[];
  }): Promise<Result<boolean | null>>;
}

const addPurchaseOrder: AddPurchaseOrder = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  data,
  items,
}) => {
  try {
    const newRef = doc(
      collection(db, "organizations", organizationId, "purchase_orders")
    );

    const newObj: PurchaseOrder = normalizePurchaseOrder({
      ...data,
      id: newRef.id,
      authorId,
      authorName,
      version: PURCHASE_ORDER_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newRef, newObj);

    if (items) {
      const poItems: { [id: string]: PurchaseOrderItem } = (items ?? []).reduce(
        (acc: { [id: string]: PurchaseOrderItem }, item) => {
          if (item.orderQuantity) {
            const newItem: PurchaseOrderItem = {
              authorId,
              authorName,
              version: PURCHASE_ORDER_ITEM_VERSION,
              source,
              createdAt: tu.getCurrentDateTime(),
              lastUpdatedAt: tu.getCurrentDateTime(),
              costPerUnitOfMeasurement: item.costPerUnitOfMeasurement,
              id: id_util.newId20(),
              inventoryItemId: item.id,
              inventoryItemName: item.name,
              inventoryItemUnits: item.units ?? 1,
              inventoryItemUnitOfMesurement: item.unitOfMeasurement,
              inventoryItemUnitType: item.unitType,
              status: "PENDING",
              taxRate: item.taxRate,
              qtyOrdered: item.orderQuantity,
              qtyReceived: 0,
            };
            obju.removeUndefined(newItem);
            acc[newItem.id] = newItem;
          }

          return acc;
        },
        {}
      );

      const updates: Partial<PurchaseOrderData> = {
        items: poItems,
      };
      await updateDoc(newRef, updates);
    }

    // t("PURCHASE_ORDER_CREATED")
    const successMessage = "PURCHASE_ORDER_CREATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface AddPurchaseOrderItems {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    purchaseOrderId: string;
    authorName: string;
    source: Source;
    dataArr: PurchaseOrderItemData[];
  }): Promise<Result<boolean | null>>;
}

const addPurchaseOrderItems: AddPurchaseOrderItems = async ({
  db,
  organizationId,
  authorId,
  authorName,
  purchaseOrderId,
  source,
  dataArr,
}) => {
  try {
    const purchaseOrderRef = doc(
      db,
      "organizations",
      organizationId,
      "purchase_orders",
      purchaseOrderId
    );

    const newItems: { [id: string]: PurchaseOrderItem } = {};

    dataArr.forEach((data) => {
      const newId = id_util.newId20();

      const newObj: PurchaseOrderItem = normalizePurchaseOrderItem({
        ...data,
        id: newId,
        authorId,
        authorName,
        version: PURCHASE_ORDER_ITEM_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      newItems[newId] = newObj;
    });

    const updates: Partial<PurchaseOrder> = {
      items: newItems,
      lastUpdatedAt: tu.getCurrentDateTime(),
      authorId,
      authorName,
    };

    await setDoc(purchaseOrderRef, updates, { merge: true });

    // t("PURCHASE_ORDER_ITEM_ADDED")
    const successMessage = "PURCHASE_ORDER_ITEM_ADDED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

//   interface GetPurchaseOrder {
//     ({
//       db,
//       organizationId,
//       id,
//     }: {
//       db: Firestore;
//       organizationId: string;
//       id: string;
//     }): Promise<Result<PurchaseOrder | null>>;
//   }

//   const getPurchaseOrder: GetPurchaseOrder = async ({ db, organizationId, id }) => {
//     try {
//       const purchaseOrderDocRef = doc(
//         db,
//         "organizations",
//         organizationId,
//         "purchase_orders",
//         id
//       );
//       const purchaseOrderDocSnapshot = await getDoc(purchaseOrderDocRef);
//       if (purchaseOrderDocSnapshot.exists()) {
//         try {
//           const data: unknown = purchaseOrderDocSnapshot.data();
//           const purchaseOrder: PurchaseOrder = normalizePurchaseOrder(data);
//           // t("PURCHASE_ORDER_FOUND")
//           const successMessage = "PURCHASE_ORDER_FOUND";
//           return successResult({
//             message: successMessage,
//             payload: purchaseOrder,
//           });
//         } catch (error: any) {
//           console.log(error);
//           return errorResult({ message: error.message });
//         }
//       } else {
//         // doc.data() will be undefined in this case
//         // t("PURCHASE_ORDER_NOT_FOUND")
//         const errorMessage = "PURCHASE_ORDER_NOT_FOUND";
//         console.log(errorMessage);
//         return errorResult({ message: errorMessage });
//       }
//     } catch (err: any) {
//       console.log(err);
//       return errorResult({ message: err.message });
//     }
//   };

interface UpdatePurchaseOrder {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<PurchaseOrderData>;
  }): Promise<Result<boolean | null>>;
}

const updatePurchaseOrder: UpdatePurchaseOrder = async ({
  db,
  organizationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "purchase_orders",
      id
    );

    const updates: Partial<PurchaseOrder> = {
      ...data,
      authorId,
      authorName,
      version: PURCHASE_ORDER_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("PURCHASE_ORDER_UPDATED")
    const successMessage = "PURCHASE_ORDER_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface GetPurchaseOrderListenerCallback {
  (purchaseOrder: PurchaseOrder | null): void;
}
interface GetPurchaseOrderListener {
  db: Firestore;
  organizationId: string;
  purchaseOrderId: string;
  callback: GetPurchaseOrderListenerCallback;
}

const getPurchaseOrderListener = ({
  db,
  organizationId,
  purchaseOrderId,
  callback,
}: GetPurchaseOrderListener): Unsubscribe => {
  try {
    // console.log("getPurchaseOrdersListener: new listener");
    const purchaseOrderQuery = doc(
      db,
      "organizations",
      organizationId,
      "purchase_orders",
      purchaseOrderId
    );

    return onSnapshot(purchaseOrderQuery, (querySnapshot) => {
      if (!querySnapshot.exists()) return callback(null);
      try {
        callback(normalizePurchaseOrder(querySnapshot.data()));
      } catch (err) {
        console.log(err);
        callback(null);
      }
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

interface DeletePurchaseOrder {
  ({
    db,
    organizationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deletePurchaseOrder: DeletePurchaseOrder = async ({
  db,
  organizationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "purchase_orders",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("PURCHASE_ORDER_DELETED")
      const successMessage = "PURCHASE_ORDER_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface UpdatePurchaseOrderItem {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    purchaseOrderId,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    purchaseOrderId: string;
    id: string;
    source: Source;
    data: Partial<PurchaseOrderItemData>;
  }): Promise<Result<Partial<PurchaseOrderItemData> | null>>;
}

const updatePurchaseOrderItem: UpdatePurchaseOrderItem = async ({
  db,
  organizationId,
  authorId,
  authorName,
  purchaseOrderId,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "purchase_orders",
      purchaseOrderId
    );

    const updates: Partial<PurchaseOrderItem> = {
      ...data,
      authorId,
      authorName,
      version: PURCHASE_ORDER_ITEM_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await setDoc(
      docRef,
      {
        items: {
          [id]: updates,
        },
        lastUpdatedAt: tu.getCurrentDateTime(),
      },
      { merge: true }
    );

    // t("PURCHASE_ORDER_UPDATED")
    const successMessage = "PURCHASE_ORDER_UPDATED";

    return successResult({
      message: successMessage,
      payload: updates,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface DeletePurchaseOrderItems {
  ({
    db,
    organizationId,
    authorId,
    authorName,
    purchaseOrderId,
    ids,
  }: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    authorName: string;
    purchaseOrderId: string;
    ids: string[];
  }): Promise<Result<boolean | null>>;
}

const deletePurchaseOrderItems: DeletePurchaseOrderItems = async ({
  db,
  organizationId,
  authorId,
  authorName,
  purchaseOrderId,
  ids,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "purchase_orders",
      purchaseOrderId
    );

    const fieldsToDelete = ids.map((id) => [id, deleteField()]);

    await setDoc(
      docRef,
      {
        items: {
          ...Object.fromEntries(fieldsToDelete),
        },
        lastUpdatedAt: tu.getCurrentDateTime(),
        authorId,
        authorName,
      },
      { merge: true }
    );

    // t("PURCHASE_ORDER_UPDATED")
    const successMessage = "PURCHASE_ORDER_UPDATED";

    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface GetPurchaseOrders {
  (props: {
    db: Firestore;
    organizationId: string;
    startAfterId?: string;
    startBeforeId?: string;
    startAtId?: string;
    vendorId?: string;
    status?: PurchaseOrderStatus;
  }): Promise<Result<PurchaseOrder[] | null>>;
}

const getPurchaseOrders: GetPurchaseOrders = async ({
  db,
  organizationId,
  startAfterId,
  startBeforeId,
  startAtId,
  vendorId,
  status,
}) => {
  const PAGE_SIZE = 10;

  try {
    let newQuery = query(
      collection(db, "organizations", organizationId, "purchase_orders")
    );

    if (vendorId) {
      newQuery = query(newQuery, where("vendorId", "==", vendorId));
    }

    if (status) {
      newQuery = query(newQuery, where("status", "==", status));
    }

    newQuery = query(newQuery, orderBy("date", "desc"), limit(PAGE_SIZE));

    if (startAtId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "purchase_orders",
        startAtId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAt(cursorDoc));
    } else if (startBeforeId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "purchase_orders",
        startBeforeId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, endBefore(cursorDoc), limitToLast(PAGE_SIZE));
    } else if (startAfterId) {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "purchase_orders",
        startAfterId
      );
      const cursorDoc = await getDoc(docRef);
      newQuery = query(newQuery, startAfter(cursorDoc));
    }

    const querySnapshot = await getDocs(newQuery);

    const purchaseOrders: PurchaseOrder[] = [];

    querySnapshot.forEach((doc) => {
      try {
        purchaseOrders.push(normalizePurchaseOrder(doc.data()));
      } catch (error) {
        console.log(error);
      }
    });

    return successResult({
      message: "SUCCESS",
      payload: purchaseOrders,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const purchaseOrders = (
  db: Firestore,
  organizationId: string,
  authorId: string,
  authorName: string,
  source: Source
) => {
  return {
    getPurchaseOrders: (props: {
      startAfterId?: string;
      startBeforeId?: string;
      startAtId?: string;
      vendorId?: string;
    }) =>
      getPurchaseOrders({
        db,
        organizationId,
        ...props,
      }),
    getPurchaseOrderListener: (
      purchaseOrderId: string,
      callback: GetPurchaseOrderListenerCallback
    ) =>
      getPurchaseOrderListener({
        db,
        organizationId,
        purchaseOrderId,
        callback,
      }),
    //   getPurchaseOrder: (id: string) =>
    //     getPurchaseOrder({
    //       db,
    //       organizationId,
    //       id,
    //     }),

    addPurchaseOrder: (data: PurchaseOrderData, items?: InventoryItem[]) =>
      addPurchaseOrder({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        data,
        items,
      }),
    addPurchaseOrderItems: ({
      purchaseOrderId,
      dataArr,
    }: {
      purchaseOrderId: string;
      dataArr: PurchaseOrderItemData[];
    }) =>
      addPurchaseOrderItems({
        db,
        organizationId,
        authorId,
        authorName,
        purchaseOrderId,
        source,
        dataArr,
      }),
    markPurchaseOrderAsReadyToOrder: (id: string) =>
      updatePurchaseOrder({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "READY_TO_ORDER" },
      }),
    markPurchaseOrderAsOrdered: (id: string) =>
      updatePurchaseOrder({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ORDERED" },
      }),
    markPurchaseOrderAsPending: (id: string) =>
      updatePurchaseOrder({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "PENDING" },
      }),

    updatePurchaseOrder: (id: string, data: Partial<PurchaseOrderData>) =>
      updatePurchaseOrder({
        db,
        organizationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),

    deletePurchaseOrderItems: ({
      ids,
      purchaseOrderId,
    }: {
      ids: string[];
      purchaseOrderId: string;
    }) =>
      deletePurchaseOrderItems({
        authorId,
        authorName,
        db,
        ids,
        purchaseOrderId,
        organizationId,
      }),

    updatePurchaseOrderItem: ({
      id,
      purchaseOrderId,
      data,
    }: {
      id: string;
      purchaseOrderId: string;
      data: Partial<PurchaseOrderItemData>;
    }) =>
      updatePurchaseOrderItem({
        authorId,
        authorName,
        data,
        db,
        id,
        purchaseOrderId,
        organizationId,
        source,
      }),
    deletePurchaseOrder: (id: string) =>
      deletePurchaseOrder({
        db,
        organizationId,
        id,
      }),
  };
};
