import { z } from "zod";

export const providerDataSchema = z.object({
  uid: z.string(),
  displayName: z.string(),
  email: z.string(),
  providerId: z.string(),
});

export type ProviderData = z.infer<typeof providerDataSchema>;
