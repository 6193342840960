import {
  errorResult,
  id_util,
  normalizeTreatment,
  Note,
  NoteData,
  NOTE_VERSION,
  obju,
  Result,
  Source,
  successResult,
  Treatment,
  TreatmentData,
  TreatmentStatus,
  TREATMENT_VERSION,
  tu,
} from "beitary-shared";
import {
  collection,
  deleteDoc,
  deleteField,
  doc,
  FieldValue,
  Firestore,
  onSnapshot,
  query,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

// add organization consultation

interface AddTreatment {
  ({
    db,
    organizationId,
    consultationId,
    authorId,
    authorName,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    data: TreatmentData;
  }): Promise<Result<Treatment | null>>;
}

const addTreatment: AddTreatment = async ({
  db,
  organizationId,
  consultationId,
  authorId,
  authorName,
  source,
  data,
}) => {
  try {
    // create a new ref to get a new consultation id
    const newRef = doc(
      collection(
        db,
        "organizations",
        organizationId,
        "consultations",
        consultationId,
        "treatments"
      )
    );

    const newObj: Treatment = normalizeTreatment({
      ...data,
      id: newRef.id,
      authorId,
      authorName,
      version: TREATMENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    });

    await setDoc(newRef, newObj);

    // t("TREATMENT_CREATED")
    const successMessage = "TREATMENT_CREATED";
    return successResult({
      message: successMessage,
      payload: newObj,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface AddTreatments {
  ({
    db,
    organizationId,
    consultationId,
    authorId,
    authorName,
    source,
    dataArr,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    dataArr: TreatmentData[];
  }): Promise<Result<TreatmentData[] | null>>;
}

const addTreatments: AddTreatments = async ({
  db,
  organizationId,
  consultationId,
  authorId,
  authorName,
  source,
  dataArr,
}) => {
  try {
    const batch = writeBatch(db);

    const newTreatments: TreatmentData[] = [...dataArr];

    const ids: { [originalBundleId: string]: string } = {};

    newTreatments.forEach((t) => {
      if (t.isFixedPriceBundle && t.fixedPriceBundleId) {
        const newId = id_util.newId20();
        ids[t.fixedPriceBundleId] = newId;
      }
    });

    console.log(ids);

    newTreatments.forEach((data) => {
      const newId =
        data.isFixedPriceBundle && data.fixedPriceBundleId
          ? ids[data.fixedPriceBundleId]
          : id_util.newId20();
      const newRef = doc(
        db,
        "organizations",
        organizationId,
        "consultations",
        consultationId,
        "treatments",
        newId
      );

      let newData = { ...data };
      // whether it is a fpbundle item or a product of a fpbundle
      // we give it the id of the bundle tx
      if (data.fixedPriceBundleId) {
        newData["fixedPriceBundleId"] = ids[data.fixedPriceBundleId];
      }

      const newObj: Treatment = normalizeTreatment({
        ...newData,
        id: newRef.id,
        authorId,
        authorName,
        version: TREATMENT_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      });

      obju.removeUndefined(newObj);

      batch.set(newRef, newObj);
    });

    await batch.commit();

    // t("TREATMENT_CREATED")
    const successMessage = "TREATMENT_CREATED";
    return successResult({
      message: successMessage,
      payload: newTreatments,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface UpdateTreatment {
  ({
    db,
    organizationId,
    consultationId,
    authorId,
    authorName,
    id,
    source,
    data,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    authorId: string;
    authorName: string;
    id: string;
    source: Source;
    data: Partial<TreatmentData>;
  }): Promise<Result<Partial<TreatmentData> | null>>;
}

const updateTreatment: UpdateTreatment = async ({
  db,
  organizationId,
  consultationId,
  authorId,
  authorName,
  id,
  source,
  data,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId,
      "treatments",
      id
    );

    const updates: Partial<Treatment> = {
      ...data,
      authorId,
      authorName,
      version: TREATMENT_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    await updateDoc(docRef, updates);

    // t("TREATMENT_UPDATED")
    const successMessage = "TREATMENT_UPDATED";

    return successResult({
      message: successMessage,
      payload: updates,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

interface UpdateTreatments {
  ({
    db,
    organizationId,
    consultationId,
    authorId,
    authorName,
    source,
    dataArr,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    authorId: string;
    authorName: string;
    source: Source;
    dataArr: { id: string; data: Partial<TreatmentData> }[];
  }): Promise<Result<{ id: string; data: Partial<TreatmentData> }[] | null>>;
}

const updateTreatments: UpdateTreatments = async ({
  db,
  organizationId,
  consultationId,
  authorId,
  authorName,
  source,
  dataArr,
}) => {
  try {
    const batch = writeBatch(db);

    dataArr.forEach(({ id, data }) => {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "consultations",
        consultationId,
        "treatments",
        id
      );
      const update: Partial<Treatment> = {
        ...data,
        id: docRef.id,
        authorId,
        authorName,
        version: TREATMENT_VERSION,
        source,
        createdAt: tu.getCurrentDateTime(),
        lastUpdatedAt: tu.getCurrentDateTime(),
      };

      batch.update(docRef, update);
    });

    await batch.commit();

    // t("TREATMENT_CREATED")
    const successMessage = "TREATMENT_CREATED";
    return successResult({
      message: successMessage,
      payload: dataArr,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// get organization treatments listener
interface GetTreatmentsListenerCallback {
  (treatments: Treatment[]): void;
}
interface GetTreatmentsListener {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  status?: TreatmentStatus;
  callback: GetTreatmentsListenerCallback;
}

const getTreatmentsListener = ({
  db,
  organizationId,
  consultationId,
  status,
  callback,
}: GetTreatmentsListener): Unsubscribe => {
  try {
    // console.log("getTreatmentsListener: new listener");
    let newQuery = query(
      collection(
        db,
        "organizations",
        organizationId,
        "consultations",
        consultationId,
        "treatments"
      )
      // orderBy("lastUpdatedAt", "desc")
    );

    if (status) {
      newQuery = query(newQuery, where("status", "==", status));
    }

    return onSnapshot(newQuery, (querySnapshot) => {
      const treatments: Treatment[] = [];
      querySnapshot.forEach((doc) => {
        try {
          treatments.push(normalizeTreatment(doc.data()));
        } catch (err) {
          console.log(err);
        }
      });
      callback(treatments);
    });
  } catch (err: any) {
    console.log(err);
    return () => {};
  }
};

// delete stuff

interface DeleteTreatment {
  ({
    db,
    organizationId,
    consultationId,
    id,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    id: string;
  }): Promise<Result<boolean | null>>;
}

const deleteTreatment: DeleteTreatment = async ({
  db,
  organizationId,
  consultationId,
  id,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId,
      "treatments",
      id
    );

    try {
      await deleteDoc(docRef);
      // t("TREATMNET_DELETED")
      const successMessage = "TREATMNET_DELETED";
      return successResult({
        message: successMessage,
        payload: true,
      });
    } catch (error: any) {
      console.log(error);
      return errorResult({ message: error.message });
    }
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

interface DeleteTreatments {
  ({
    db,
    organizationId,
    consultationId,
    ids,
  }: {
    db: Firestore;
    organizationId: string;
    consultationId: string;
    ids: string[];
  }): Promise<Result<boolean | null>>;
}

const deleteTreatments: DeleteTreatments = async ({
  db,
  organizationId,
  consultationId,
  ids,
}) => {
  try {
    const batch = writeBatch(db);

    ids.forEach((id) => {
      const docRef = doc(
        db,
        "organizations",
        organizationId,
        "consultations",
        consultationId,
        "treatments",
        id
      );

      batch.delete(docRef);
    });

    await batch.commit();

    // t("TREATMENTS_DELETED")
    const successMessage = "TREATMNETS_DELETED";
    return successResult({
      message: successMessage,
      payload: true,
    });
  } catch (err: any) {
    console.log(err);
    return errorResult({ message: err.message });
  }
};

// NOTES
interface AddNote {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    consultationId: string;
    treatmentId: string;
    authorName: string;
    source: Source;
    noteData: NoteData;
  }): Promise<Result<string | null>>;
}

const addNote: AddNote = async ({
  db,
  organizationId,
  authorId,
  authorName,
  consultationId,
  source,
  noteData,
  treatmentId,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId,
      "treatments",
      treatmentId
    );

    const id = id_util.newId20();

    const newNote: Note = {
      ...noteData,
      id,
      authorId,
      authorName,
      version: NOTE_VERSION,
      source,
      createdAt: tu.getCurrentDateTime(),
      lastUpdatedAt: tu.getCurrentDateTime(),
    };

    let updates: Partial<Treatment> = {
      medicalRecordNotes: { [newNote.id]: newNote },
      lastUpdatedAt: tu.getCurrentDateTime(),
      authorId,
      authorName,
      source,
    };

    await setDoc(docRef, updates, { merge: true });

    // t("NOTE_ADDED")
    const successMessage = "NOTE_ADDED";
    return successResult({
      message: successMessage,
      payload: id,
    });
  } catch (err: any) {
    return errorResult({ message: err.message });
  }
};

interface DeleteNote {
  (props: {
    db: Firestore;
    organizationId: string;
    authorId: string;
    consultationId: string;
    authorName: string;
    treatmentId: string;
    source: Source;
    noteId: string;
  }): Promise<Result<string | null>>;
}

const deleteNote: DeleteNote = async ({
  db,
  organizationId,
  authorId,
  authorName,
  source,
  consultationId,
  treatmentId,
  noteId,
}) => {
  try {
    const docRef = doc(
      db,
      "organizations",
      organizationId,
      "consultations",
      consultationId,
      "treatments",
      treatmentId
    );

    let updates: Partial<Treatment> = {
      lastUpdatedAt: tu.getCurrentDateTime(),
      authorId,
      authorName,
      source,
    };

    let toDelete: { [key: string]: { [key: string]: FieldValue } } = {
      medicalRecordNotes: { [noteId]: deleteField() },
    };

    await setDoc(
      docRef,
      {
        ...toDelete,
        ...updates,
      },
      { merge: true }
    );

    // t("INVOICE_UPDATED")
    const successMessage = "INVOICE_UPDATED";

    return successResult({
      message: successMessage,
      payload: noteId,
    });
  } catch (err: any) {
    console.log(err.message);
    return errorResult({ message: err.message });
  }
};

// we inject dependencies to improve testability
export const treatments = ({
  authorId,
  authorName,
  consultationId,
  db,
  organizationId,
  source,
}: {
  db: Firestore;
  organizationId: string;
  consultationId: string;
  authorId: string;
  authorName: string;
  source: Source;
}) => {
  return {
    getTreatmentsListener: (
      callback: GetTreatmentsListenerCallback,
      status?: TreatmentStatus
    ) =>
      getTreatmentsListener({
        db,
        organizationId,
        consultationId,
        status,
        callback,
      }),
    addTreatment: (data: TreatmentData) =>
      addTreatment({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        data,
      }),
    addTreatments: (dataArr: TreatmentData[]) =>
      addTreatments({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        dataArr,
      }),
    updateTreatment: (id: string, data: Partial<TreatmentData>) =>
      updateTreatment({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        id,
        source,
        data,
      }),
    deleteTreatment: (id: string) =>
      deleteTreatment({
        db,
        organizationId,
        consultationId,
        id,
      }),
    administerTreatment: (id: string) =>
      updateTreatment({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        id,
        source,
        data: {
          status: "ADMINISTRED",
          dateAdministred: tu.getCurrentDateTime(),
          adminBy: authorName,
        },
      }),
    deadministerTreatment: (id: string) =>
      updateTreatment({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "ORDERED" },
      }),
    stopTreatment: (id: string) =>
      updateTreatment({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        id,
        source,
        data: { status: "PLANNED" },
      }),
    stopTreatments: (ids: string[]) =>
      updateTreatments({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        dataArr: ids.map((id) => ({ id, data: { status: "PLANNED" } })),
      }),
    administerTreatments: (ids: string[]) =>
      updateTreatments({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        dataArr: ids.map((id) => ({
          id,
          data: {
            status: "ADMINISTRED",
            dateAdministred: tu.getCurrentDateTime(),
            adminBy: authorName,
          },
        })),
      }),
    orderTreatments: (ids: string[]) =>
      updateTreatments({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        dataArr: ids.map((id) => ({ id, data: { status: "ORDERED" } })),
      }),
    deadministerTreatments: (ids: string[]) =>
      updateTreatments({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        dataArr: ids.map((id) => ({ id, data: { status: "ORDERED" } })),
      }),
    deleteTreatments: (ids: string[]) =>
      deleteTreatments({
        db,
        organizationId,
        consultationId,
        ids,
      }),
    updateTreatments: (
      dataArr: { id: string; data: Partial<TreatmentData> }[]
    ) =>
      updateTreatments({
        db,
        organizationId,
        consultationId,
        authorId,
        authorName,
        source,
        dataArr,
      }),
    addNote: ({
      noteData,
      treatmentId,
    }: {
      noteData: NoteData;
      treatmentId: string;
    }) =>
      addNote({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        consultationId,
        noteData,
        treatmentId,
      }),

    deleteNote: ({
      noteId,
      treatmentId,
    }: {
      noteId: string;
      treatmentId: string;
    }) =>
      deleteNote({
        db,
        organizationId,
        authorId,
        authorName,
        source,
        consultationId,
        noteId,
        treatmentId,
      }),
  };
};
