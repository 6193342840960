import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { BSettings } from "beitary-shared";

interface BSettingsState {
  bSettings: BSettings | null;
}

const initialState: BSettingsState = {
  bSettings: null,
};

export const bSettingsSlice = createSlice({
  name: "bSettings",
  initialState,
  reducers: {
    setBSettings: (state, action: PayloadAction<BSettings | null>) => {
      state.bSettings = action.payload;
    },
  },
});

export const { setBSettings } = bSettingsSlice.actions;

export const selectBSettings = (state: RootState) => state.bSettings.bSettings;

export const bSettingsReducer = bSettingsSlice.reducer;
