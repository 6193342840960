import { z } from "zod";

// t("DAY")
// t("WEEK")
// t("MONTH")
// t("YEAR")
// t("BEFORE")
// t("AFTER")
export const reminderNotificationTimeUnitEnum = z.enum([
  "DAY",
  "WEEK",
  "MONTH",
  "YEAR",
]);

export const reminderNotificationSchema = z.object({
  id: z.string(),
  sendIn: z.number(),
  timeUnit: reminderNotificationTimeUnitEnum,
  beforeAfter: z.enum(["BEFORE", "AFTER"]),
});

export type ReminderNotification = z.infer<typeof reminderNotificationSchema>;
