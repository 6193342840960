import { Group, NumberInput, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import {
  getNotificationByResultType,
  id_util,
  loadingInfoNotification,
  obju,
  PurchaseOrderItemData,
} from "beitary-shared";
import { BMoneyInput, SaveButton } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { InventoryItemSelect } from "../InventoryItemSelect";
import {
  PurchaseOrderItemFormValues,
  rules,
} from "./PurchaseOrderItemForm.rules";

export interface PurchaseOrderItemFormProps {
  purchaseOrderId: string;
  vendorId: string;
}

export const PurchaseOrderItemForm = ({
  purchaseOrderId,
  vendorId,
}: PurchaseOrderItemFormProps) => {
  const { t } = useTranslation();
  const [submitState, setSubmitState] = useSubmitState();

  const { addPurchaseOrderItems } =
    useDBServices().inventoryDBService.purchaseOrders;

  const form = useForm<PurchaseOrderItemFormValues>({
    initialValues: undefined,
    validate: rules,
  });

  const submit = async (values: PurchaseOrderItemFormValues) => {
    const data: PurchaseOrderItemData = {
      ...values,
    };

    obju.removeUndefined(data);

    const notificationId = id_util.newId6();
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("ADD_PURCHASE_ORDER_ITEM"),
      })
    );
    setSubmitState("pending-response");
    const result = await addPurchaseOrderItems({
      purchaseOrderId,
      dataArr: [data],
    });

    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack p="xl">
        <Group grow>
          <InventoryItemSelect
            required
            supplierId={vendorId}
            {...form.getInputProps("inventoryItemId")}
            onChange={(v) => {
              if (v) {
                form.setValues({
                  inventoryItemId: v.id,
                  inventoryItemName: v.name,
                  qtyOrdered: v.orderQuantity ?? 1,
                  costPerUnitOfMeasurement: v.costPerUnitOfMeasurement,
                  status: "PENDING",
                  taxRate: v.taxRate,
                  qtyReceived: 0,
                  inventoryItemUnits: v.units ?? 1,
                  inventoryItemUnitOfMesurement: v.unitOfMeasurement,
                  inventoryItemUnitType: v.unitType,
                });
              }
            }}
          />
        </Group>
        <Group grow>
          <NumberInput
            precision={2}
            required
            label={t("ORDER_QUANTITY")}
            {...form.getInputProps("qtyOrdered")}
          />
          <BMoneyInput
            label={t("COST_PER_UNIT_OF_MEASUREMENT_NO_TAXES")}
            {...form.getInputProps("costPerUnitOfMeasurement")}
          />
        </Group>

        <Group position="right">
          <SaveButton state={submitState} canSave={form.isValid()} />
        </Group>
      </Stack>
    </form>
  );
};
