import { Group, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification, updateNotification } from "@mantine/notifications";
import { useAppSelector } from "app/hooks";
import {
  getNotificationByResultType,
  loadingInfoNotification,
} from "beitary-shared";
import {
  BBox,
  BDateAndTimeInput,
  BDateInput,
  CancelButton,
  MoveBackButton,
  SaveButton,
} from "components";
import { selectActiveConsultationTreatments } from "features/consultations/Consultations.slice";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useSubmitState } from "hooks/useSubmitState";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { AdminTxFormValues, rules } from "./AdminTxForm.rules";

interface TxFormProps {
  consultationId: string;
  disabled: boolean;
}

export const AdminTxForm = ({ consultationId, disabled }: TxFormProps) => {
  const [submitState, setSubmitState] = useSubmitState();
  const { t } = useTranslation();
  const txDBService =
    useDBServices().consultationsDBService.treatments(consultationId);

  const { treatmentId } = useParams();
  const treatment = useAppSelector(selectActiveConsultationTreatments).find(
    (t) => t.id === treatmentId
  );

  const form = useForm<AdminTxFormValues>({
    initialValues: treatment ? { ...treatment } : undefined,
    validate: rules,
  });

  if (!treatment) return <Text>{t("NOT_FOUND")}</Text>;

  const submit = async (values: AdminTxFormValues) => {
    if (disabled || !treatment) return;
    const notificationId = "submit-treatment";
    showNotification(
      loadingInfoNotification({
        id: notificationId,
        message: t("Waiting for server response"),
        title: t("UPDATE_TREATMENT"),
      })
    );
    setSubmitState("pending-response");

    const result = await txDBService.updateTreatment(treatment.id, values);
    if (result.type === "success") {
      setSubmitState("success");
    } else {
      setSubmitState("error");
    }
    updateNotification({
      ...getNotificationByResultType(result.type)({
        message: t(result.message),
      }),
      id: notificationId,
    });
  };

  return (
    <form onSubmit={form.onSubmit(submit, (errors) => console.log(errors))}>
      <Stack>
        <Group>
          <MoveBackButton />
        </Group>
        <BBox
          header={<Text weight={500}>{treatment.productSnapshot.name}</Text>}
        >
          <Stack p="xl">
            <Group grow>
              {form.values.dateAdministred && (
                <BDateAndTimeInput
                  disabled={disabled}
                  label={t("DATE_ADMINISTRED")}
                  {...form.getInputProps("dateAdministred")}
                />
              )}
            </Group>

            {(treatment.productSnapshot.type === "MEDICATION" ||
              treatment.productSnapshot.type === "VACCINE") && (
              <Group grow>
                <TextInput
                  label={t("SERIAL_NUMBER")}
                  {...form.getInputProps("serialNumber")}
                  min={1}
                />
                <TextInput
                  disabled={disabled}
                  label={t("LOT_NUMBER")}
                  {...form.getInputProps("lotNumber")}
                />
                <BDateInput
                  disabled={disabled}
                  label={t("LOT_EXPIRATION_DATE")}
                  {...form.getInputProps("lotExpirationDate")}
                />
                <TextInput
                  disabled={disabled}
                  label={t("MANUFACTURER")}
                  {...form.getInputProps("manufacturer")}
                />
              </Group>
            )}
          </Stack>
        </BBox>

        <Group position="right">
          <CancelButton />
          <SaveButton
            disabled={disabled}
            state={submitState}
            canSave={form.isValid()}
          />
        </Group>
      </Stack>
    </form>
  );
};
