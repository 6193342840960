import { Container, Tabs, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const View = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.toLowerCase();
  const activeTab = path.includes("clinic-settings/general")
    ? "general"
    : path.includes("clinic-settings/advanced")
    ? "advanced"
    : path.includes("clinic-settings/hours")
    ? "hours"
    : path.includes("clinic-settings/locations")
    ? "locations"
    : "general";

  return (
    <Container fluid>
      <Title mt="xl" weight={500} order={3}>
        {t("CLINIC_SETTINGS")}
      </Title>
      <Tabs
        mt="xl"
        value={activeTab}
        onTabChange={(value) => {
          navigate(`${value}`);
        }}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value="general">{t("GENERAL")}</Tabs.Tab>
          {/* <Tabs.Tab value="hours">{t("WORK_HOURS")}</Tabs.Tab> */}
          <Tabs.Tab value="locations">{t("LOCATIONS")}</Tabs.Tab>
          {/* <Tabs.Tab value="advanced">{t("ADVANCED_SETTINGS")}</Tabs.Tab> */}
        </Tabs.List>
        <Outlet />
      </Tabs>
    </Container>
  );
};
