import { z } from "zod";

// t("BOOKED_APT")
// t("CHECKED_IN_APT")
// t("CHECKED_OUT_APT")
// t("CANCELED_APT")
// t("NO_SHOW_APT")
export const boardingAppointmentStatusEnum = z.enum([
  "BOOKED",
  "CHECKED_IN",
  "CHECKED_OUT",
  "CANCELED",
  "NO_SHOW",
]);

export type BoardingAppointmentStatus = z.infer<
  typeof boardingAppointmentStatusEnum
>;
