import { Group, Stack, Text } from "@mantine/core";
import { InventoryTransaction, tu } from "beitary-shared";
import { TableWithSearchAndSort } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InventoryItemSelect } from "../InventoryItemSelect";
import { TransactionTypeSelect } from "../TransactionTypeSelect";

type InventoryTransactionsTableItem = InventoryTransaction & {};

export const TransactionsTable = () => {
  const { t } = useTranslation();

  const [transactions, setTransactions] = useState<InventoryTransaction[]>([]);

  const { getInventoryTransactions } =
    useDBServices().inventoryDBService.inventoryTransactions;

  const [invItemId, setInvItemId] = useState<string | undefined>(undefined);
  // const [memberId, setMemberId] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string | undefined>(undefined);

  // TODO add member select
  useEffect(() => {
    const fetchTransactions = async () => {
      const result = await getInventoryTransactions({
        invItemId,
        type,
      });
      setTransactions(result.payload ?? []);
    };
    console.log("fetchTransactions");

    fetchTransactions();
  }, [invItemId, type, getInventoryTransactions]);
  console.log(transactions);

  const fields: {
    key: keyof InventoryTransactionsTableItem;
    label: string;
  }[] = [
    { key: "date", label: t("DATE") },
    { key: "type", label: t("TRANSACTION_TYPE") },
    { key: "notes", label: t("NOTE") },
    { key: "authorName", label: t("BY") },
    { key: "invItemName", label: t("ITEM") },
    { key: "qty", label: t("QUANTITY") },
  ];

  const getRow = (transaction: InventoryTransactionsTableItem) => (
    <tr key={transaction.id}>
      <td>
        <Text>{tu.getDateAndTimeString(transaction.date)}</Text>
      </td>
      <td>
        <Text>{t(transaction.type)}</Text>
      </td>
      <td>
        <Text>{transaction.notes}</Text>
      </td>
      <td>
        <Text>{transaction.authorName}</Text>
      </td>
      <td>
        <Text>{transaction.invItemName}</Text>
      </td>
      <td>
        <Text>{transaction.qty}</Text>
      </td>
    </tr>
  );
  return (
    <Stack>
      <Group grow>
        <TransactionTypeSelect fullList onChange={setType} clearable />
        <InventoryItemSelect onChange={setInvItemId} clearable />
      </Group>
      <TableWithSearchAndSort
        data={transactions}
        fields={fields}
        getRow={getRow}
      />
    </Stack>
  );
};
