import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  Switch,
  Table,
  Text,
} from "@mantine/core";
import {
  IconAlertTriangle,
  IconArrowBack,
  IconPencilCheck,
  IconRefresh,
} from "@tabler/icons-react";
import { id_util, Task, tu } from "beitary-shared";
import { AsyncActionIcon, BBox, DeleteConfirmationModal } from "components";
import { useDBServices } from "hooks/useDBService/useDBService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddTaskModal } from "../AddTaskModal";
import { EditTaskModal } from "../EditTaskModal";

type TasksTableItem = Task & { actions?: undefined };

export interface TasksTableProps {
  clientId?: string;
  clientName?: string;
  patientId?: string;
  patientName?: string;
}

// started chatgpt opt
export const TasksTable = ({
  clientId,
  patientId,
  clientName,
  patientName,
}: TasksTableProps) => {
  const { t } = useTranslation();

  const [startAfterTime, setStartAfterTime] = useState<number | undefined>(
    undefined
  );
  const [startBeforeTime, setStartBeforeTime] = useState<number | undefined>(
    undefined
  );

  const { deleteTask, getTasks, completeTask, uncompleteTask } =
    useDBServices().tasksDBService;

  const [tasks, setTasks] = useState<undefined | null | TasksTableItem[]>(
    undefined
  );

  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [refresh, setRefresh] = useState(id_util.newId36());

  // console.log(tasks);

  const handleNextPage = () => {
    fetchTasks({ startAfterTime, showComplete });
  };

  const handlePreviousPage = () => {
    fetchTasks({ startBeforeTime, showComplete });
  };

  interface fetchTasksProps {
    startAfterTime?: number;
    startBeforeTime?: number;
    startAtTime?: number;
    showComplete?: boolean;
  }
  const fetchTasks = useCallback(
    async ({
      startAfterTime,
      startBeforeTime,
      showComplete,
      startAtTime,
    }: fetchTasksProps) => {
      setRefreshing(true);
      const props = {
        showComplete,
        startAfterTime,
        startBeforeTime,
        startAtTime,
        clientId,
        patientId,
      };
      const result = await getTasks(props);

      setRefreshing(false);
      // ... handle pagination ...
      if (result.payload && result.payload.length > 0) {
        setTasks(result.payload);
        setStartAfterTime(result.payload[result.payload.length - 1].createdAt);
        setStartBeforeTime(result.payload[0].createdAt);
      } else {
        // // No more data to fetch
        if (startAfterTime) setStartAfterTime(undefined);
        if (startBeforeTime) setStartBeforeTime(undefined);
      }
    },
    [clientId, getTasks, patientId]
  );

  useEffect(() => {
    fetchTasks({ showComplete });
  }, [fetchTasks, refresh, showComplete]);

  const getRow = useCallback(
    (snapshot: Task) => {
      const { id, name, patientName, dueDate, status } = snapshot;
      return (
        <tr key={id}>
          <td>
            <Text>{name}</Text>
          </td>
          <td>
            <Text>{patientName}</Text>
          </td>
          <td>
            <Group>
              <Text>{tu.getDateAndTimeString(dueDate)}</Text>
              {dueDate < tu.getCurrentDateTime() && (
                <IconAlertTriangle size={16} color="red" stroke={1.5} />
              )}
            </Group>
          </td>

          <td>
            <Text color={status === "CREATED" ? "dark" : "green"}>
              {t(status)}
            </Text>
          </td>
          <td>
            <Group position="right" w={120}>
              {status === "CREATED" ? (
                <AsyncActionIcon
                  f={async () => {
                    setRefreshing(true);
                    const result = await completeTask(id);
                    fetchTasks({
                      showComplete,
                      startAtTime: tasks?.at(0)?.createdAt,
                    });
                    setRefreshing(false);
                    return result;
                  }}
                  icon={<IconPencilCheck size={18} />}
                />
              ) : (
                <AsyncActionIcon
                  f={async () => {
                    setRefreshing(true);
                    const result = await uncompleteTask(id);
                    fetchTasks({
                      showComplete,
                      startAtTime: tasks?.at(0)?.createdAt,
                    });
                    setRefreshing(false);
                    return result;
                  }}
                  icon={<IconArrowBack size={18} />}
                />
              )}
              <EditTaskModal task={snapshot} />
              <DeleteConfirmationModal
                f={async () => {
                  const props: fetchTasksProps = { showComplete };
                  if (tasks && tasks.length > 0) {
                    // TODO idk how to handle this if
                    // last item on page deleted it'll just go to first page
                    props["startAtTime"] =
                      tasks?.at(0)?.createdAt !== snapshot.createdAt
                        ? tasks?.at(0)?.createdAt
                        : tasks?.at(1)?.createdAt;
                  } else {
                    props["startBeforeTime"] = snapshot.createdAt;
                  }
                  setRefreshing(true);
                  const result = await deleteTask(id);
                  fetchTasks(props);
                  setRefreshing(false);
                  return result;
                }}
                item={{ id, name }}
              />
            </Group>
          </td>
        </tr>
      );
    },
    [
      completeTask,
      deleteTask,
      fetchTasks,
      tasks,
      showComplete,
      t,
      uncompleteTask,
    ]
  );

  const rows = useMemo(
    () => (tasks ?? []).map((a) => getRow(a)),
    [tasks, getRow]
  );

  return tasks === null ? (
    <Text>{t("ERROR")}</Text>
  ) : (
    <BBox
      header={
        <Group position="apart">
          <Text>{t("TASKS")}</Text>

          <Group>
            <ActionIcon
              onClick={() => {
                setRefresh(id_util.newId36());
              }}
            >
              {refreshing ? <Loader size="xs" /> : <IconRefresh size={18} />}
            </ActionIcon>
            <Switch
              label={t("SHOW_COMPLETE")}
              labelPosition="left"
              onChange={(v) => {
                setShowComplete(v.currentTarget.checked);
              }}
              checked={showComplete}
            />
            <AddTaskModal
              clientId={clientId}
              clientName={clientName}
              patientId={patientId}
              patientName={patientName}
            />
          </Group>
        </Group>
      }
    >
      <Box p="xl">
        <Table>
          <thead>
            <tr>
              <th>{t("NAME")}</th>
              <th>{t("PATIENT_NAME")}</th>
              <th>{t("DUE_DATE")}</th>
              <th>{t("STATUS")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
        <Group position="right" mt="xl">
          <Button onClick={handlePreviousPage} disabled={!startBeforeTime}>
            {t("PREVIOUS")}
          </Button>
          <Button onClick={handleNextPage} disabled={!startAfterTime}>
            {t("NEXT")}
          </Button>
        </Group>
      </Box>
    </BBox>
  );
};
